import { useTranslation } from 'react-i18next';
import { VideoTutorialCard } from '../components/VideoTutorialCard';
import { videoTutorialItems } from '../constants/ConstantsVideoTutorials';

export const VideoTutorialIndex = () => {
  const { t } = useTranslation();

  return (
    <div className="my-[50px] mx-5">
      <div className="flex justify-between px-5">
        <p className="text-xxl text-color-medium-grey">
          {t('front.videoTutorial.index.header.title')}
        </p>
      </div>
      <div className="mt-5 grid grid-cols-1 md:grid-cols-3 gap-6">
        {videoTutorialItems().map((video) => (
          <div key={video.title} className="col-span-1">
            <VideoTutorialCard
              title={video.title}
              subtitle={video.subtitle}
              description={video.description}
              url={video.url}
              image={video.image}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
