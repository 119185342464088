import { useTranslation } from 'react-i18next';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { FunctionComponent } from 'react';
import { ContractForm } from '@/modules/invoices/components/InvoiceNewDraft/ContractForm';
import { ConfigForm } from '@/modules/invoices/components/InvoiceNewDraft/ConfigForm';
import { FormWizard } from '@/modules/common/components/wizard';
import { handleSubmit } from '@/modules/invoices/functions/submitNewInvoiceDraft';
import { PriceForm } from '../components/InvoiceNewDraft/PriceForm';
import { invoiceDraftWizardContext } from '../constants';

export const InvoicesNewDraft: FunctionComponent = () => {
  const { t } = useTranslation();

  const initialValues = {
    // Default to today
    date: new Date().toISOString().split('T')[0],
  };

  return (
    <PageLayout Title={t('front.invoices.new.header.title')}>
      <FormWizard
        wizardContext={invoiceDraftWizardContext}
        id="testWizard"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        pages={{
          contract: {
            Component: ContractForm,
            title: t('front.invoices.new.steps.contract.title'),
          },
          price: {
            Component: PriceForm,
            title: t('front.invoices.new.steps.price.title'),
          },
          config: {
            Component: ConfigForm,
            title: t('front.invoices.new.steps.config.title'),
          },
        }}
      />
    </PageLayout>
  );
};
