import { Attr, BelongsTo, Model } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import type RegisteredClient from './RegisteredClient';
import type User from './User';

@Model()
class RegisteredClientAgent extends ApplicationRecord {
  static jsonapiType = 'registered_client_agents';

  @Attr() registeredClientId!: number;

  @BelongsTo('registered_clients') registeredClient!: RegisteredClient;
  @BelongsTo('users') user!: User;
}

export default RegisteredClientAgent;
