import SubcontractorBillsIndex from '@/modules/subcontractor_bills/pages/SubcontractorBillsIndex';
import { RoutesMounter } from '../../../types';

export const mountEmployeeSubcontractorBillsRoutes: RoutesMounter = ({
  path,
}) => [
  {
    path,
    children: [{ index: true, element: <SubcontractorBillsIndex /> }],
  },
];
