import FactorTransferable from '@/app/spraypaint/factor/FactorTransferable';
import MultiSelectTable from '@/modules/common/components/inputs/components/MultiSelectTable';
import { MainCard } from '@/modules/common/components/mainCard';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { useState } from 'react';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import FactorTransfer from '@/app/spraypaint/factor/FactorTransfer';
import FactorTransferItem from '@/app/spraypaint/factor/FactorTransferItem';
import { toast } from 'react-toastify';
import { Toaster } from '@/modules/common/components/toasts';
import { compact } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateInput } from '@/modules/common/components/inputs';
import { dateToString } from '@/app/utils/dateUtils';
import factorTransferableColumns from '../functions/factorTransferableColumns';
import useFactorTransfer from '../hooks/useFactorTransfer';
import useFactorTransferables from '../hooks/useFactorTransferables';

const columns = factorTransferableColumns();

const AddToFactorTransfer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { factorTransfer } = useFactorTransfer({ id: String(id) });

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const factoringContractId = factorTransfer?.factorFactoringContractId;

  const [scope, scopeKey, props] = useBackendTable<FactorTransferable>();
  const queryResult = useFactorTransferables({
    factoringContractId,
    startDate,
    endDate,
    scopeKey,
    scope,
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    const transfer = new FactorTransfer({ id: factorTransfer?.id });
    transfer.isPersisted = true;
    selectedIds.forEach((selectableId) => {
      const newItem = new FactorTransferItem({
        transferableType: selectableId.split('_')[0],
        transferableId: selectableId.split('_')[1],
      });
      transfer.factorTransferItems.push(newItem);
    });

    const success = await transfer.save({
      with: ['factorTransferItems'],
    });
    if (success) {
      toast.success(
        <Toaster
          textMsg={t('views.shared.change_applied')}
          toastType="success"
        />,
      );
      setIsLoading(false);
      navigate(`/v2/admin/factor_transfers/${transfer.id}`);
    } else {
      setIsLoading(false);
      const apiErrors = [];
      if (Object.keys(transfer.errors).length > 0)
        apiErrors.push(transfer.errors);
      compact(apiErrors).forEach((e) => {
        toast.error(<Toaster textMsg={String(e)} toastType="error" />);
      });
    }
  };

  const handleStartDateChange = (arg: string | Date) => {
    if (arg instanceof Date) {
      const date = dateToString(arg);
      setStartDate(date);
    } else {
      setStartDate(arg);
    }
  };
  const handleEndDateChange = (arg: string | Date) => {
    if (arg instanceof Date) {
      const date = dateToString(arg);
      setEndDate(date);
    } else {
      setEndDate(arg);
    }
  };

  return (
    <MainCard header="Ajouter à la remise">
      {factoringContractId && (
        <>
          <div className="flex">
            <DateInput
              value={startDate}
              onChange={handleStartDateChange}
              label={t('front.factor.factor_transferable.start_date')}
            />
            <DateInput
              value={endDate}
              onChange={handleEndDateChange}
              label={t('front.factor.factor_transferable.end_date')}
            />
          </div>

          <MultiSelectTable
            onChange={setSelectedIds}
            value={selectedIds}
            queryResult={queryResult}
            reactTableProps={props}
            columns={columns}
          />
          <ButtonPrimary
            className="mt-[10px]"
            onClick={onSubmit}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Ajouter
          </ButtonPrimary>
        </>
      )}
    </MainCard>
  );
};

export default AddToFactorTransfer;
