import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import EmployeePayslip from '@spraypaint/EmployeePayslip';
import { PAYSLIP_STATUSES_ADMIN_INDEX } from '@/modules/admin/payslip/constants/PayslipStatusAdminIndex';
import { dynT } from '@/app/translation/utils/dynT';

const columnHelper = createColumnHelper<ModelRecord<EmployeePayslip>>();

export const statusStatColumns = [
  columnHelper.accessor('legal_entity_name', {
    header: dynT('views.legal_entity.label'),
  }),
  columnHelper.accessor('total', {
    header: 'Total',
    id: 'total',
  }),
  ...PAYSLIP_STATUSES_ADMIN_INDEX.map((status) =>
    columnHelper.accessor(status, {
      header: dynT(`default.status_enum.${status}`),
      id: status,
    }),
  ),
];

export const requestAgeStatColumns = [
  columnHelper.accessor('legal_entity_name', {
    header: dynT('views.legal_entity.label'),
  }),
  columnHelper.accessor('total', { header: 'Total' }),
  columnHelper.accessor('less_than_24h', { header: '24h' }),
  columnHelper.accessor('between_24_and_48h', { header: '24h - 48h' }),
  columnHelper.accessor('between_48_and_72h', { header: '48h - 72h' }),
  columnHelper.accessor('more_than_72h', { header: '+72h' }),
];

// const managerDataMock = [
//   {
//     legal_entity: 'prium_portage',
//     total: 24235,
//     autovalidated: 0,
//     'Ophelia A.': 1,
//   },
//   {
//     legal_entity: 'prium_formation',
//     total: 2969,
//     autovalidated: 67,
//   },
//   {
//     legal_entity: 'prium_city',
//     total: 2831,
//     autovalidated: 28,
//     'Martin G.': 7,
//   },
//   {
//     legal_entity: 'prium_experts',
//     total: 17,
//     autovalidated: 0,
//   },
//   {
//     legal_entity: 'prium_mt',
//     total: 108,
//     autovalidated: 0,
//   },
//   {
//     legal_entity: 'prium_partners',
//     total: 0,
//     autovalidated: 0,
//   },
// ];

// WIP on Dynamic manager columns
// const managerColumns = managerDataMock.some((m) => m?.['Ophelia A.'])
//   ? [
//       {
//         accessorKey: ['Ophelia A.'],
//         header: 'Manager 1',
//         enableColumnFilter: false,
//         enableSorting: false,
//       },
//     ]
//   : [];

// TODO: Tableau dynamique basé sur les manager
export const managerStatColumns = [
  columnHelper.accessor('legal_entity_name', {
    header: dynT('views.legal_entity.label'),
  }),
  columnHelper.accessor('total', { header: 'Total' }),
  columnHelper.accessor('autovalidated', { header: 'Val. auto' }),
  columnHelper.accessor('manager_name', { header: 'test manager' }),
];
