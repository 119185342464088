import classNames from 'classnames';
import { formatNumber, getNumberColor } from '../../utils/maths.utils';
import type { CurrencyIsoCode } from '../../utils/type.utils';
import useCurrency from '../../hooks/useCurrency';
import { AmountTextType, AmountWithCurrencyType } from '../../types/AmountType';
import { MoneyType } from '../../utils/type.utils';

export const AmountWithCurrency = ({
  amount,
  currencyIsoCode,
  supText,
  currencyClass = 'ml-1',
  spanClass,
}: AmountWithCurrencyType) => {
  const customSpanClass = classNames(getNumberColor(amount), spanClass);

  const { currencySymbol } = useCurrency(
    currencyIsoCode ||
      (typeof amount !== 'number'
        ? (amount?.currency as CurrencyIsoCode)
        : 'EUR'),
  );

  const formattedAmount =
    typeof amount !== 'number'
      ? formatNumber(amount?.amount)
      : formatNumber(amount);

  return (
    <span className={customSpanClass}>
      {formattedAmount}
      <sup className={currencyClass}>
        {supText ? `${currencySymbol} ${supText}` : currencySymbol}
      </sup>
    </span>
  );
};

const DisplayAmount = ({
  amount,
  currency,
  props,
}: {
  amount: number | MoneyType;
  currency: CurrencyIsoCode;
  props: {
    amount: number;
    supText: string;
    currencyClass: string;
    spanClass: string;
  };
}) =>
  typeof amount !== 'number' ? (
    <AmountWithCurrency {...props} />
  ) : (
    <AmountWithCurrency currencyIsoCode={currency} {...props} />
  );

export const AmountText = ({
  label = '',
  amount,
  currencyIsoCode = 'EUR',
  supText = '',
  className = '',
  currencyPositionClass = '',
  inline = false,
}: AmountTextType) => {
  const currencyCustomClass = classNames(
    'ml-[2px] right-[-3px] text-[60%] mr-[3px]',
    {
      'top-[-8px]': !currencyPositionClass,
    },
    currencyPositionClass,
  );

  const customSpanClassName = classNames({
    'flex ml-5': inline,
    'text-center': !inline,
  });

  const customLabelClassName = classNames({
    'w-[158px] text-sm2 text-color-dark-content mb-2': !inline,
  });

  const currencyCode =
    typeof amount !== 'number' ? amount?.currency : currencyIsoCode;

  const amountFormatted = typeof amount !== 'number' ? amount?.amount : amount;

  const commonAmountWithCurrencyProps = {
    amount: amountFormatted,
    supText,
    currencyClass: currencyCustomClass,
    spanClass: className,
  };

  return label ? (
    <span className={customSpanClassName}>
      <div className={customLabelClassName}>{label.toLocaleUpperCase()}</div>
      <DisplayAmount
        amount={amountFormatted}
        currency={currencyCode as CurrencyIsoCode}
        props={commonAmountWithCurrencyProps}
      />
    </span>
  ) : (
    <DisplayAmount
      amount={amountFormatted}
      currency={currencyCode as CurrencyIsoCode}
      props={commonAmountWithCurrencyProps}
    />
  );
};
