import { FunctionComponent, useCallback, useState } from 'react';
import { noDefault } from '@/app/utils/typescriptUtils';
import { EmailForm } from '@/modules/authentification/components/EmailForm';
import { PasswordForm } from '@/modules/authentification/components/PasswordForm';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import URLSearchParams from '@ungap/url-search-params';
import { UnknownEmailForm } from '@/modules/authentification/components/UnknownEmailForm';
import { InnerLoginFormProps } from '@/modules/authentification/components/types/InnerLoginFormProps';

type FormStatus = 'email' | 'password' | 'unknownEmail';

/*
  Behavior
   * Ask the user for its email
   * Attempt an email only authentication (prospect users)
   * If successful, redirect to the email code form
   * If email authentication is disabled, ask for the password
   * If email is unknown, ask the user to register

  Features
   * handle the "next" params with redirectAfterLogin
   * handle the "email" param to pre-fill the email field
 */
export const TwoStepsLoginForm: FunctionComponent<InnerLoginFormProps> = ({
  onForgetPassword,
  redirectAfterLogin,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const emailParam = queryParams.get('email');

  const location = useLocation();
  const navigate = useNavigate();
  const { fromRegister, email: locationEmail } = location.state || {};

  const initState: FormStatus = fromRegister ? 'password' : 'email';
  const initEmail = locationEmail || emailParam || '';
  const [formStatus, setFormStatus] = useState<FormStatus>(initState);
  const [email, setEmail] = useState<string>(initEmail);

  const navigateToPasswordForm = useCallback(
    (e: string) => {
      setEmail(e);
      setFormStatus('password');
    },
    [setEmail, setFormStatus],
  );

  const navigateToUnknownEmailForm = useCallback(
    (e: string) => {
      setEmail(e);
      setFormStatus('unknownEmail');
    },
    [setEmail, setFormStatus],
  );

  const navigateToEmailForm = useCallback(() => {
    setFormStatus('email');
  }, [setFormStatus]);

  const navigateToEmailCodeForm = useCallback(
    (e: string) => {
      navigate({
        pathname: '../email_code',
        search: createSearchParams({ email: e }).toString(),
      });
    },
    [navigate, email],
  );

  const navigateToRegisterForm = useCallback(
    (e: string) => {
      navigate(
        {
          pathname: '../register',
          search: createSearchParams({ email: e }).toString(),
        },
        { state: { email: e } },
      );
    },
    [navigate, email],
  );

  const navigateToVerifyAccount = useCallback(
    (e: string, { emailSent }: { emailSent: boolean }) => {
      const params: Record<string, string> = { email: e };
      if (emailSent) {
        params.emailSent = '1';
      }

      navigate({
        pathname: '../verify_account',
        search: createSearchParams(params).toString(),
      });
    },
    [navigate],
  );

  switch (formStatus) {
    case 'email':
      return (
        <EmailForm
          email={email}
          navigateToPasswordForm={navigateToPasswordForm}
          navigateToUnknownEmailForm={navigateToUnknownEmailForm}
          navigateToEmailCodeForm={navigateToEmailCodeForm}
          navigateToVerifyAccount={navigateToVerifyAccount}
        />
      );
    case 'password':
      return (
        <PasswordForm
          email={email}
          navigateToEmailForm={navigateToEmailForm}
          onForgetPassword={onForgetPassword}
          redirectAfterLogin={redirectAfterLogin}
        />
      );
    case 'unknownEmail':
      return (
        <UnknownEmailForm
          email={email}
          navigateToEmailForm={navigateToEmailForm}
          navigateToRegisterForm={navigateToRegisterForm}
        />
      );
    default:
      return noDefault(formStatus);
  }
};
