export function updateObjectInArray<T extends { id: any }>(
  array: Array<T>,
  newItem: T,
) {
  return array.map((item) => {
    if (item.id !== newItem.id) {
      // This isn't the item we care about - keep it as-is
      return item;
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...newItem,
    };
  });
}

export function hasArrayIntersection(
  array1: Array<string>,
  array2: Array<string>,
): boolean {
  return array1.some((element: string) => array2?.includes(element));
}
