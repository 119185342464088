export const otherType = () => [
  { value: 'family_event', label: 'views.cra.family_event' },
  {
    value: 'work_accident_absence',
    label: 'views.cra.work_accident_absence',
  },
  { value: 'parental_absence', label: 'views.cra.parental_absence' },
  { value: 'sickness', label: 'front.payslip.tdc.buttoncra.sick' },
  {
    value: 'paid_parental_leave',
    label: 'front.payslip.tdc.buttoncra.parentalLeave',
  },
];
