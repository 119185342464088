import { ActivityReportSlot } from '../../../../app/spraypaint';

export const getSlotTypeEquality = ({
  previousAcvitityReportSlot,
  nextAcvitityReportSlot,
  am,
  pm,
}: {
  previousAcvitityReportSlot:
    | {
        date: Date;
        pm: ActivityReportSlot;
        am: ActivityReportSlot;
      }
    | undefined;
  nextAcvitityReportSlot:
    | {
        date: Date;
        pm: ActivityReportSlot;
        am: ActivityReportSlot;
      }
    | undefined;
  pm?: ActivityReportSlot;
  am?: ActivityReportSlot;
}) => {
  const isPreviousAmSlotSameType =
    previousAcvitityReportSlot?.am?.slotType === am?.slotType;

  const isPreviousPmSlotSameType =
    previousAcvitityReportSlot?.pm?.slotType === pm?.slotType;

  const isNextPmSlotSameType =
    nextAcvitityReportSlot?.pm?.slotType === pm?.slotType;

  const isNextAmSlotSameType =
    nextAcvitityReportSlot?.am?.slotType === am?.slotType;

  return {
    isPreviousAmSlotSameType,
    isPreviousPmSlotSameType,
    isNextPmSlotSameType,
    isNextAmSlotSameType,
  };
};
