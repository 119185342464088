import { useMutation } from '@tanstack/react-query';
import { Blob as SparypaintBlob } from '../../../../../app/spraypaint';

export const blobToDataUrl = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

export const uploadBlob = async (payload: {
  filename: string;
  contentType: string;
  content: any;
}): Promise<SparypaintBlob> => {
  const file = new SparypaintBlob(payload);
  await file.saveOrThrow();
  return file;
};

export const useUploadBlob = () => {
  const { mutate, isLoading, error, ...others } = useMutation(uploadBlob);

  return { mutate, isLoading, error, ...others };
};
