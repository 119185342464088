import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { getDateFromPeriod } from '@/app/utils/dateUtils';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../../../../common/components/buttons';
import { useGetNumberedList } from '../../../../common/hooks/useGetNumberedList';
import { TextInput } from '../../../../common/components/inputs';
import Status from '../../../../common/components/statuses/Status';
import { MoneyType } from '../../../../common/utils/type.utils';
import {
  ContribPercoDeposit,
  Invoice,
  Numbered,
  SepaImport,
  SepaTransaction,
  SubcontractorBill,
} from '../../../../../app/spraypaint';
import { CandidateType } from '../../CandidateType';

export const ManualReconciliationForm = ({
  handleAddition,
  className,
  sepaTransaction,
}: {
  handleAddition: any;
  className?: string;
  sepaTransaction: SepaTransaction;
}) => {
  const { t } = useTranslation();
  const [number, setNumber] = useState<string>();
  const [inputValue, setInputValue] = useState('');

  const numberedTypes =
    sepaTransaction.direction === 'credit'
      ? ['Invoice', 'ContribPeeDeposit', 'ContribPercoDeposit']
      : ['SepaImport', 'SubcontractorBill'];
  const { resources, isFetching } = useGetNumberedList(number, numberedTypes);

  const customClassName = classNames(
    className,
    'w-[600px] flex flex-col mt-[40px]',
  );

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSearch = () => {
    setNumber(inputValue);
  };

  const handleOnKeyUp = (event: any) => {
    if (event.charCode === 13) {
      setNumber(event.target.value);
    }
  };

  const hasResult = resources.length > 0;

  const displayInvoice = (result: Invoice) => (
    <div className="flex-col">
      <p className="font-cabin-bold">
        {t(`front.bankAssociation.associationCard.title.${result.jsonapiType}`)}
      </p>
      <p>
        {t('front.manualReconciliation.card.employeeName')}
        <span> {result.employee.fullName} </span>
      </p>
      <p>
        {t('front.manualReconciliation.card.ttc')}
        <span>
          <AmountWithCurrency amount={result.totalAmountWithVat as MoneyType} />
        </span>
      </p>
      {result.status === 'partially_paid' && (
        <p>
          {t('front.manualReconciliation.card.remainingAmount')}
          <span>
            <AmountWithCurrency amount={result.remainingAmount} />
          </span>
        </p>
      )}
      <p>
        {t('front.manualReconciliation.card.dueDate')}
        <span>{dayjs(result.dueDate).format('L')} </span>
      </p>
      <p className="flex">
        {t('front.manualReconciliation.card.status')}
        <Status statusType={result.status} className="ml-2" />
      </p>
      <p>
        {t('front.manualReconciliation.card.clientName')}
        <span>{result.client?.name} </span>
      </p>
      {result.debtorType !== 'Client' && (
        <p>
          {t('front.manualReconciliation.card.debtorName', {
            debtorType: result.debtorType,
          })}
          <span> {result.debtor?.name}</span>
        </p>
      )}
    </div>
  );

  const displaySubcontractorBill = (result: SubcontractorBill) => (
    <div className="flex-col">
      <p className="font-cabin-bold">
        {t(`front.bankAssociation.associationCard.title.${result.jsonapiType}`)}
      </p>
      <p>
        {t('front.manualReconciliation.card.ttc')}
        <span>
          <AmountWithCurrency amount={result.ttcAmount as MoneyType} />
        </span>
      </p>
      <p>
        {t('front.manualReconciliation.card.dueDate')}
        <span>{dayjs(result.dueDate).format('L')} </span>
      </p>
      <p>
        {t('front.manualReconciliation.card.subcontractorName')}
        <span> {result.subcontractor?.contactName} </span>
      </p>
      <p className="flex">
        {t('front.manualReconciliation.card.status')}
        <Status statusType={result.status} className="ml-2" />
      </p>
    </div>
  );

  const displaySepaImport = (result: SepaImport) => (
    <div className="flex-col">
      <p className="font-cabin-bold">
        {t(`front.bankAssociation.associationCard.title.${result.jsonapiType}`)}
      </p>
      <p>
        {t('front.manualReconciliation.card.ttc')}
        <span>
          <AmountWithCurrency amount={result.totalAmount as MoneyType} />
        </span>
      </p>
      <p>
        {t('front.manualReconciliation.card.period')}
        <span>
          {' '}
          {getDateFromPeriod({
            month: result.payslipPeriod.month,
            year: result.payslipPeriod.year,
          })}
        </span>
      </p>
      <p>
        {t('front.manualReconciliation.card.totalEntries')}
        <span> {result.totalEntries} </span>
      </p>
      <p className="flex">
        {t('front.manualReconciliation.card.status')}
        <Status statusType={result.status} className="ml-2" />
      </p>
    </div>
  );

  const displayContribDeposit = (result: ContribPercoDeposit) => (
    <div className="flex-col">
      <p className="font-cabin-bold">
        {t(`front.bankAssociation.associationCard.title.${result.jsonapiType}`)}
      </p>
      <p>
        {t('front.manualReconciliation.card.ttc')}
        <span>
          <AmountWithCurrency amount={result.amount as MoneyType} />
        </span>
      </p>
      <p>
        {t('front.manualReconciliation.card.employeeName')}
        <span> {result.employee.fullName} </span>
      </p>
      <p>
        {t('front.manualReconciliation.card.createdAt')}
        <span> {dayjs(result.createdAt).format('L')} </span>
      </p>
      <p>
        {t('front.manualReconciliation.card.deposited')}
        <span> {t(`default.${result.deposited ? 'yes' : 'no'}`)}</span>
      </p>
      <p className="flex">
        {t('front.manualReconciliation.card.status')}
        <Status statusType={result.status} className="ml-2" />
      </p>
    </div>
  );

  /* eslint-disable-next-line @typescript-eslint/ban-types */ // because of Function usage
  const displayMethodForNumberedMap: Record<string, Function> = {
    Invoice: displayInvoice,
    SubcontractorBill: displaySubcontractorBill,
    ContribPeeDeposit: displayContribDeposit,
    ContribPercoDeposit: displayContribDeposit,
    SepaImport: displaySepaImport,
  };

  const displayAction = (result: CandidateType) => {
    const href =
      result instanceof SepaImport
        ? result.adminPagePath
        : result.employeePagePath;
    return (
      <div className="flex justify-around">
        <ButtonSecondary
          disabled={!hasResult}
          href={href as string}
          openInNewTab
          className="my-[20px] w-[200px]"
        >
          {t('front.manualReconciliation.selectedInvoice.btn.show')}
        </ButtonSecondary>
        <ButtonPrimary
          onClick={handleAddition(result, sepaTransaction)}
          disabled={!hasResult}
          className="my-[20px] w-[200px]"
        >
          {t(
            'front.manualReconciliation.selectedInvoice.btn.label',
          ).toUpperCase()}
        </ButtonPrimary>
      </div>
    );
  };

  const displayResult = (resource: Numbered) => (
    <>
      {displayMethodForNumberedMap[resource.numberedType](resource.numbered)}
      {displayAction(resource.numbered)}
    </>
  );

  return (
    <div className={customClassName}>
      <p className="text-color-medium-grey text-xl">
        {t('front.bankAssociation.show.matchingButton.title')}
      </p>
      <div className="flex items-center mt-[40px]">
        <TextInput
          placeholder={
            t(
              'front.bankAssociation.show.button.findByNumber.placeholder',
            ) as string
          }
          onChange={handleOnInputChange}
          onKeyPress={handleOnKeyUp}
          value={inputValue}
        />
        <ButtonPrimary
          isLoading={isFetching}
          className="ml-[10px]"
          onClick={handleSearch}
        >
          {t('front.bankAssociation.show.button.find.label')}
        </ButtonPrimary>
      </div>
      {!hasResult && <p>{t('front.bankAssociation.show.empty')}</p>}
      {hasResult &&
        resources.map((resource: Numbered) => displayResult(resource))}
    </div>
  );
};
