import { useCurrentEmployeeId } from '../../app/hooks/useCurrentEmployeeId';
import useFeatureFlag from './useFeatureFlag';

/**
 * @param {string} featureName
 * @param {string, number} userId
 * @return  {[boolean, object]}
 */
export default function useEmployeeFeatureFlag(
  featureName: string,
  employeeId: number | string | null = null,
) {
  const currentEmployeeId = useCurrentEmployeeId();

  return useFeatureFlag(
    featureName,
    `Employee/${employeeId || currentEmployeeId}`,
  );
}
