import {
  ModuleRouteObject,
  RoutesMounter,
  RoutesMounterArgs,
  TypedRouteObject,
} from '@/types';
import { Params } from 'react-router-dom';
import { buildFeatureFlagProtectionLoader } from '@/app/navigation/loaders/buildFeatureFlagProtectionLoader';
import { chainLoaders } from '@/app/navigation/loaders/chainLoaders';

// Recursive function to add a loader on each leaf-routes of the routeMounter to check featureFlag.
function addFeatureFlagLoader<
  TData,
  TRoute extends TypedRouteObject<TData> | ModuleRouteObject,
>(
  featureFlag: string,
  defaultRedirect: (params: Params<string>) => string,
  route: TRoute,
  tools: RoutesMounterArgs['tools'],
): TRoute {
  if (route.children) {
    // this is not a leaf-route, crawl down each children
    return {
      ...route,
      children: route.children.map((child) =>
        addFeatureFlagLoader(
          featureFlag,
          defaultRedirect,
          child as TypedRouteObject<unknown>,
          tools,
        ),
      ),
    };
  }

  // this is a leaf-route, add a loader to check featureFlag

  const protectionLoader = buildFeatureFlagProtectionLoader(featureFlag, {
    queryClient: tools.queryClient,
    redirectPathBuilder: route.handle?.v1 || defaultRedirect,
  });

  const loader = route.loader
    ? chainLoaders([protectionLoader, route.loader])
    : protectionLoader;

  // return the route with the new loader
  return {
    ...route,
    loader,
  };
}

// Add a loader on each leaf-routes of the routeMounter to check featureFlag.
// If the featureFlag is not enabled, redirect to the defaultRedirect or the `v1` handler.
export function featureFlagModuleRoutes<TData>(
  featureFlag: string,
  defaultRedirect: (params: Params<string>) => string,
  routeMounter: RoutesMounter<TData>,
): RoutesMounter<TData> {
  return ({ path, tools }) => {
    const initialRoutes: ModuleRouteObject[] = routeMounter({ path, tools });

    return initialRoutes.map((route) =>
      addFeatureFlagLoader(featureFlag, defaultRedirect, route, tools),
    );
  };
}
