import { SpraypaintBase } from 'spraypaint';
import { PaginationState } from '@tanstack/react-table';
import { useCallback } from 'react';
import { GraphitiScoper } from '../../../../../app/spraypaint/types';

export function useBackendPagination<TData extends SpraypaintBase>(
  pagination: PaginationState,
): [scoper: GraphitiScoper<TData>, key: any] {
  const paginationKey = [pagination.pageSize, pagination.pageIndex];

  const applyPagination: GraphitiScoper<TData> = useCallback(
    (baseScope) =>
      baseScope
        .per(pagination.pageSize)
        .page(pagination.pageIndex + 1)
        .stats({ total: 'count' }),
    [paginationKey],
  );

  return [applyPagination, paginationKey];
}
