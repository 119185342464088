import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { AmountText } from '../../../common/components/fragments/AmountText';
import { HelpOutlineIcon } from '../../../../app/assets/icons/inputs';
import { TooltipComponent } from '../../../common/components/fragments/TooltipComponent';
import { CurrencyIsoCode } from '../../../common/utils/type.utils';

interface CommonProps {
  expensesCount: number;
  currencyIsoCode?: CurrencyIsoCode;
  totalAmount: number;
  id?: string;
}

interface RefundProps extends CommonProps {
  status: 'refund';
  refundedAmount: number;
  maxAmount: number;
}

interface RejectProps extends CommonProps {
  status: 'reject';
  refundedAmount?: number;
  maxAmount?: number;
}

export type TotalExpensesCardProps = RefundProps | RejectProps;

export const TotalExpensesCard = ({
  id,
  status,
  refundedAmount,
  totalAmount,
  maxAmount,
  expensesCount,
  currencyIsoCode = 'EUR',
}: TotalExpensesCardProps) => {
  const { t } = useTranslation();
  const rejectStatus = status === 'reject';
  const refundStatus = status === 'refund';

  const customClassName = classNames(
    'flex flex-1 flex-col md:w-[367px] h-[133] p-3 md:pl-[28px] md:pr-[33px] md:py-5 rounded-[12px] fill-white',
    {
      'bg-color-blue border-[1px] border-color-light-blue': refundStatus,
      'bg-color-bg-failure border-[1px] border-color-border-failure':
        rejectStatus,
    },
  );

  const titleCustomClass = classNames(
    'uppercase text-left text-sm2 font-cabin-regular pb-2',
    {
      'text-white': refundStatus,
      'text-color-txt-failure': rejectStatus,
    },
  );

  const amountCustomClass = classNames('text-left text-base4 md:text-xl', {
    'text-white': refundStatus,
    'text-color-txt-failure font-cabin-bold': rejectStatus,
  });

  const totalAmountTextClassName = classNames({
    'color-red': totalAmount > (maxAmount || 0),
  });

  return (
    <div id={id} className={customClassName}>
      <p className={titleCustomClass}>
        {rejectStatus
          ? t('front.payslip.tdc.expense.totalCard.reject.title')
          : t('front.payslip.tdc.expense.totalCard.refund.title')}
      </p>
      <div className="flex items-center pb-2 justify-between">
        <p className={`flex ${amountCustomClass}`}>
          {maxAmount != null ? (
            <span data-testid="multiple-amount">
              <AmountText
                className={totalAmountTextClassName}
                amount={totalAmount}
                currencyIsoCode={currencyIsoCode}
              />
              {' / '}
              <AmountText
                amount={maxAmount}
                currencyIsoCode={currencyIsoCode}
              />
              {' max'}
            </span>
          ) : (
            <AmountText
              amount={totalAmount}
              currencyIsoCode={currencyIsoCode}
            />
          )}
        </p>
        {refundStatus && (
          <TooltipComponent
            tooltipId="totalExpenseCard-refundStatus"
            Icon={HelpOutlineIcon}
            tooltipMsgComp={
              <Trans i18nKey="front.payslip.tdc.expense.card.refund.tooltip" />
            }
            iconClassName="fill-color-white"
          />
        )}
        {rejectStatus && (
          <TooltipComponent
            tooltipId="totalExpenseCard-rejectStatus"
            Icon={HelpOutlineIcon}
            tooltipMsgComp={
              <Trans i18nKey="front.payslip.tdc.expense.card.reject.tooltip" />
            }
            iconClassName="fill-color-txt-failure"
          />
        )}
      </div>
      {rejectStatus ? (
        <div className="bg-white rounded-full px-3 py-1 text-color-blue text-sm2 flex items-baseline relative w-fit justify-center">
          {t('front.payslip.expense.card.exp_number', {
            number: expensesCount,
          })}
        </div>
      ) : (
        <div className="flex items-center justify-between left-[-5px]">
          <p
            className="px-3 py-1 text-white text-base2 flex items-baseline relative right-[12px] "
            data-testid="refundable_amount"
          >
            {t('front.payslip.expense.card.refundable_amount_1')}
            &nbsp;
            <AmountText
              amount={Number(refundedAmount)}
              currencyIsoCode={currencyIsoCode}
            />
            &nbsp;
            {t('front.payslip.expense.card.refundable_amount_2')}
          </p>
        </div>
      )}
    </div>
  );
};
