import { forwardRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import { Error } from './components/Error';

export type CheckBoxProps = {
  disabled?: boolean;
  className?: string;
  label?: string;
  errorText?: FieldError | undefined;
} & InputHTMLAttributes<HTMLInputElement>;
export const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
  (
    { disabled = false, className, label, errorText, ...props }: CheckBoxProps,
    ref,
  ) => {
    const customClassName = classNames(
      'accent-color-pone-orange border-3 border-color-pone-orange cursor-pointer',
      className,
    );
    const renderLabel = () => (
      <label htmlFor={props.id} className="ml-[13px] text-sm2">
        {label}
      </label>
    );
    return (
      <>
        <div className="flex flex-col">
          <div>
            <input
              ref={ref}
              disabled={disabled}
              type="checkbox"
              className={customClassName}
              id={props.id}
              {...props}
            />
            {label && renderLabel()}
          </div>
        </div>
        <div>
          {errorText?.message && !disabled && (
            <Error>{errorText.message}</Error>
          )}
        </div>
      </>
    );
  },
);
