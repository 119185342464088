import type { FunctionComponent } from 'react';
import { TextInput } from '@common/components/inputs';
import { HypertextLink } from '@common/components/buttons';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';

export interface FilledEmailFormProps {
  email: string;
  errorText?: FieldError | undefined;
  onGoBack: () => void;
}

export const FilledEmailForm: FunctionComponent<FilledEmailFormProps> = ({
  email,
  errorText,
  onGoBack,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TextInput
        testID="input-email"
        label={t('front.auth.signIn.form.email.label') as string}
        placeholder={t('front.auth.signIn.form.email.placeholder') as string}
        className="mt-[33px]"
        type="email"
        value={email}
        errorText={errorText}
        required
        disabled
      />
      <HypertextLink
        testID="login-link-not-me"
        onClick={onGoBack}
        className="ml-[10px]"
      >
        {t('front.auth.signIn.button.back_to_email.title')}
      </HypertextLink>
    </>
  );
};
