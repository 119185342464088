// api/Notifications::Message.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type NotificationsInbox from './NotificationsInbox';

export type StatusEnum = 'not_read' | 'read';

@Model()
class NotificationsMessage extends ApplicationRecord {
  static jsonapiType = 'notifications-messages';

  // Attributes
  @Attr({ persist: false }) title!: string;
  @Attr({ persist: false }) content!: string;
  @Attr({ persist: false }) webUrl!: string;
  @Attr({ persist: false }) appUrl!: string;
  @Attr({ persist: false }) important!: boolean;
  @Attr() status!: StatusEnum;
  @Attr({ persist: false }) category!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;

  // Relationships
  @BelongsTo('notifications-inboxes') inbox!: NotificationsInbox;
}

export default NotificationsMessage;
