import { useTranslation } from 'react-i18next';
import { MenuType } from '@/modules/layout/types/MenuType';
import { RoleType } from '../../authentification/types/RoleType';
import {
  OpcoIcon,
  ProspectIcon,
  SimulationIcon,
  TdbIcon,
} from '../../../app/assets/icons/menu';
import { hasArrayIntersection } from '../../common/utils/array.utils';

export const constantCommercialMenu = (
  roles: Array<RoleType>,
): Array<MenuType> | [] => {
  const { t } = useTranslation();
  const display = hasArrayIntersection(
    ['support', 'commercial', 'manager', 'accountant'],
    roles,
  );

  if (!display) return [];

  return [
    {
      type: 'separator' as const,
      title: t('front.menu.separator.commercial'),
      displaySeparator: display,
    },
    {
      title: t('front.menu.prospects'),
      toV1: 'prospects',
      icon: ProspectIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.payslip_simulations'),
      toV1: 'payslip_simulations',
      icon: SimulationIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.portage_offers'),
      toV1: 'portage_offers',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.rivals'),
      toV1: 'rivals',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.opcos'),
      toV1: 'opcos',
      icon: OpcoIcon,
      canBeDisplay: display,
    },
  ];
};
