import { RoutesMounter } from '@/types';
import { member, index } from '@/app/navigation/functions';
import {
  CapGeminiImportShow,
  queryOpts,
} from '@/modules/admin/capgemini/pages/CapGeminiImportShow';
import { buildCapGeminiImportFindQueryLoader } from '@/modules/admin/capgemini/functions/capGeminiImportQuery';
import { CapGeminiImportIndex } from '@/modules/admin/capgemini/pages/CapGeminiImportIndex';

export const mountCapGeminiRoutes: RoutesMounter = ({
  path,
  tools: { queryClient },
}) => [
  member({
    path,
    handle: { crumb: () => 'Imports CapGemini' },
    children: [
      index({ Component: CapGeminiImportIndex }),
      member({
        path: ':capGeminiImportId',
        handle: { crumb: () => 'Import' },
        loader: buildCapGeminiImportFindQueryLoader(queryClient, {
          idParam: 'capGeminiImportId',
          queryOpts,
        }),
        children: [
          index({
            Component: CapGeminiImportShow,
          }),
        ],
      }),
    ],
  }),
];
