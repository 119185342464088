import { useTranslation } from 'react-i18next';

const useOptimizationOptions = () => {
  const { t } = useTranslation();

  const optimizationOptions = [
    {
      label: t('views.payslip_simulation.optimization_names.gross_salary'),
      value: 'gross_salary',
    },
    {
      label: t('views.payslip_simulation.optimization_names.net_salary'),
      value: 'net_salary',
    },
  ];

  return { optimizationOptions };
};
export default useOptimizationOptions;
