import type { FunctionComponent } from 'react';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { FormWizard } from '@/modules/common/components/wizard';
import { ConsolidationFormInvoices } from '@/modules/admin/registered_clients/modules/framework_contracts/components/ConsolidationFormInvoices';
import { ConsolidationFormOptions } from '@/modules/admin/registered_clients/modules/framework_contracts/components/ConsolidationFormOptions';
import { ConsolidationFormState } from '@/modules/admin/registered_clients/modules/framework_contracts/types';

import InvoiceConsolidation from '@spraypaint/invoicing/InvoiceConsolidation';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { consolidationWizardContext } from '../constants';

export const FrameworkContractNewConsolidation: FunctionComponent = () => {
  const { frameworkContractId } = useParams();
  const initialValues: ConsolidationFormState = {
    invoiceIds: [],
    date: new Date(),
  };

  const navigate = useNavigate();
  const { mutateAsync } = useMutation(
    async (data: ConstructorParameters<typeof InvoiceConsolidation>[0]) =>
      new InvoiceConsolidation(data).saveOrThrow(),
  );

  const onSubmit = useCallback(
    async (data: ConsolidationFormState) => {
      const newInvoice = await mutateAsync({ ...data, frameworkContractId });
      await navigate(`/v2/admin/invoices/${newInvoice.invoiceId}`);
      // Add a delay because navigate is not a
      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
    },
    [mutateAsync, navigate, frameworkContractId],
  );

  return (
    <PageLayout Title="Nouvelle consolidation">
      <FormWizard
        wizardContext={consolidationWizardContext}
        id="frameworkContractNewConsolidation"
        initialValues={initialValues}
        pages={{
          invoices: {
            Component: ConsolidationFormInvoices,
            title: 'Invoices',
          },
          options: { Component: ConsolidationFormOptions, title: 'Options' },
        }}
        onSubmit={onSubmit}
      />
    </PageLayout>
  );
};
