import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import { SubcontractorBill } from '@/app/spraypaint';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import Table from '../../common/components/table/Table';
import { useBackendTable } from '../../common/components/table/hooks';
import { onRowClickNavigate } from '../../../app/navigation/functions/onRowClickNavigate';
import useSubcontractorBills from '../hooks/useSubcontractorBills';
import subcontractorBillsIndexColumns from '../functions/subcontractorBillsIndexColumns';

const SubcontractorBillsIndex = () => {
  const employeeId = useCurrentEmployeeId();
  const navigate = useNavigate();
  const [scope, subcontractorScopeKey, props] =
    useBackendTable<SubcontractorBill>({
      initialSorting: [{ id: 'date', desc: true }],
    });

  const { subcontractorBills, isLoading, totalCount } = useSubcontractorBills(
    scope,
    subcontractorScopeKey,
    employeeId,
  );

  const { t } = useTranslation();

  const onRowClick =
    (raw: ModelRecord<SubcontractorBill> | undefined) =>
    (event: React.MouseEvent) => {
      onRowClickNavigate({
        id: `${raw?.id}`,
        event,
        windowNavigationHref: `subcontractor_bills/${raw?.id}`,
        href: `/employees/${raw?.subcontractor?.employeeId}/subcontractor_bills/${raw?.id}`,
        navigate,
      });
    };

  return (
    <div className="mt-[50px]">
      <div className="flex justify-between px-[20px]">
        <p className="big-title">{t('front.menu.subcontractor_bills')}</p>
      </div>
      {employeeId && (
        <div className="flex">
          <ButtonPrimary
            className="mt-[10px] ml-[20px]"
            type="submit"
            href={`/employees/${employeeId}/subcontractor_bills/new`}
          >
            {t('default.status_info.new')}
          </ButtonPrimary>
        </div>
      )}
      <Table
        emptyTableErrorMsg="react_table.no_data_text"
        onRowClick={onRowClick}
        className="mt-[26px]"
        isLoading={isLoading}
        withImageErrorMsg={false}
        totalCount={totalCount}
        data={(subcontractorBills as ModelRecord<SubcontractorBill>[]) || []}
        reactTableProps={props}
        columns={subcontractorBillsIndexColumns()}
      />
    </div>
  );
};

export default SubcontractorBillsIndex;
