import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MouseEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { onRowClickNavigate } from '@/app/navigation/functions/onRowClickNavigate';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import SepaFile from '../../../../app/spraypaint/sepaTransactions/SepaFile';
import Table from '../../../common/components/table/Table';
import { useBackendTable } from '../../../common/components/table/hooks';
import { useGetSepaFiles } from './hooks/useGetSepaFiles';
import { bankTransferSepaFilesColumns } from './columns/BankTransferSepaFilesColumns';
import {
  blobToDataUrl,
  uploadBlob,
} from '../../../common/components/inputs/functions/commonUploadFileFunctions';
import { Blob } from '../../../../app/spraypaint';
import { Toaster } from '../../../common/components/toasts';
import { UploadDocInput } from '../../../common/components/inputs';
import { createSepaFile } from './hooks/useCreateSepaFile';

const BankTransferIndex = () => {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const queryClient = useQueryClient();

  const [scope, scopeKey, props] = useBackendTable<SepaFile>({
    initialSorting: [{ id: 'createdAt', desc: true }],
  });
  const { sepaFiles, isLoading, totalCount } = useGetSepaFiles(scopeKey, scope);
  const sepaFileColumns = bankTransferSepaFilesColumns();
  const { t } = useTranslation();

  const handleOnRowClick =
    (raw: ModelRecord<SepaFile>) => (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminBankTransferShowPagePath,
        event,
        windowNavigationHref: raw.adminBankTransferShowPagePath,
        navigate,
        newTab: true,
      });
    };

  const handleChangeFile = (file: any) => {
    setSelectedFile(file);
  };

  const { mutate: sepaMutation, isLoading: createSepaFileLoader } =
    useMutation(createSepaFile);

  const { mutate, isLoading: mutationLoader } = useMutation(uploadBlob);

  const handleUploadFile = async () => {
    if (!selectedFile) return;
    const bytes = await blobToDataUrl(selectedFile);

    const payload = {
      filename: fileName,
      contentType: 'sepa-file',
      content: { bytes },
    };

    mutate(payload, {
      onSuccess: (data: Blob) => {
        const blobPayload = {
          sourceFileSignedId: data.id,
        };
        sepaMutation(blobPayload, {
          onSuccess: (sepaData: SepaFile) => {
            console.debug('SEPA CREATION CALLBACK SUCCESS', sepaData);
            queryClient.invalidateQueries(['sepa-files']);
          },
          onError: () => {
            const errorText = `Error on Sepa file creation for ${data.filename}`;
            toast.error(<Toaster textMsg={errorText} toastType="error" />);
          },
        });
        setFileName('');
      },
      onError: () => {
        const errorText = `ERROR during import of ${selectedFile.name}`;
        toast.error(<Toaster textMsg={errorText} toastType="error" />);
      },
    });
  };

  return (
    <div className="mt-[50px]">
      <div className="flex justify-between px-[20px]">
        <p className="big-title ">
          {t('front.bankTransfer.index.header.title')}
        </p>
        <UploadDocInput
          // errorText={error}
          isLoading={mutationLoader}
          handleChangeFile={handleChangeFile}
          fileName={fileName}
          setFileName={setFileName}
          handleUploadFile={handleUploadFile}
          label={t('front.upload.button.sepaFileLabel') as string}
          acceptedFile={['.xml', '.EXT']}
        />
        <div />
      </div>
      <Table
        onRowClick={handleOnRowClick}
        className="mt-[26px]"
        isLoading={isLoading || createSepaFileLoader}
        totalCount={totalCount}
        data={sepaFiles || []}
        reactTableProps={props}
        columns={sepaFileColumns}
      />
    </div>
  );
};

export default BankTransferIndex;
