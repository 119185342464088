import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '@/modules/common/components/buttons';
import { CraCategorieType } from '../../../../../app/spraypaint/payslipFunnel/PayslipFunnelCraStep';
import { CRA_TYPE } from '../../types/craType';
import { mapTypeToText } from '../constants/mapTypeToText.constant';

type Props = {
  children?: string;
  className?: string;
  isSelected?: boolean;
  type: CraCategorieType;
  onClick?: (type: CraCategorieType) => void;
  disabled?: boolean;
};

export const ButtonCra = ({
  children,
  isSelected,
  type,
  className,
  onClick,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const customClassName = classNames(
    'w-[202px] flex items-center h-[56px] rounded-2xl border-2',
    {
      'border-color-pone-orange ': isSelected,
      'border-color-medium-light': !isSelected,
      [CRA_TYPE.working_days.bg_light]: type === CRA_TYPE.working_days.type,
      [CRA_TYPE.telework.bg_light]: type === CRA_TYPE.telework.type,
      [CRA_TYPE.paid_holidays.bg_light]: type === CRA_TYPE.paid_holidays.type,
    },
    className,
  );
  const fillerClassName = classNames('w-[40px] h-[52px] rounded-l-[14px]', {
    [CRA_TYPE.working_days.bg]: type === CRA_TYPE.working_days.type,
    [CRA_TYPE.telework.bg]: type === CRA_TYPE.telework.type,
    [CRA_TYPE.paid_holidays.bg]: type === CRA_TYPE.paid_holidays.type,
  });

  const renderText = () => {
    if (type === 'other') return children;
    return t(mapTypeToText[type]);
  };
  return (
    <Button
      disabled={disabled}
      custom
      className={customClassName}
      onClick={onClick ? () => onClick(type) : undefined}
    >
      <div className={fillerClassName} />

      <div className="flex items-center ml-[16px]">
        <p className="text-sm text-color-pone-dark font-bold">
          {renderText()?.toUpperCase()}
        </p>
      </div>
    </Button>
  );
};
