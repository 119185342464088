import type { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { ReactComponent as LaunchIcon } from '../../../../app/assets/icons/SVG/ic_launch_24px.svg';

export interface InternalLinkProps {
  to?: string;
  href?: string;
  disabled?: boolean;
  className?: string;
  children?: ReactNode | ReactNode[];
}

export const InternalLink: FunctionComponent<InternalLinkProps> = ({
  to,
  disabled,
  className,
  children,
  href,
}) => {
  if ((!to && !href) || !children) {
    return null;
  }

  const customClassName = classNames(
    {
      'text-color-dark-grey hover:text-color-pone-orange active:text-color-dark-orange fill-color-medium-grey hover:fill-color-pone-orange active:fill-color-dark-orange':
        !disabled,
      'text-color-medium-grey-transparent fill-color-medium-grey-transparent':
        disabled,
    },
    className,
  );

  if (href) {
    return (
      <a
        href={href}
        rel="noopener noreferrer"
        aria-disabled={disabled}
        className={customClassName}
      >
        {children}{' '}
        <LaunchIcon className="inline-block" width={20} height={20} />
      </a>
    );
  }

  if (!to) return null;

  return (
    <Link to={to} aria-disabled={disabled} className={customClassName}>
      {children} <LaunchIcon className="inline-block" width={20} height={20} />
    </Link>
  );
};
