import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { useInvoice } from '@/modules/invoices/functions/invoice_query';
import { toast } from 'react-toastify';
import { Toaster } from '@/modules/common/components/toasts';
import { Control, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { ControlledDateInput } from '@/modules/common/components/inputs/controlledInput/ControlledDateInput';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { TextInput } from '@/modules/common/components/inputs';
import { InvoiceLine } from '@/app/spraypaint';
import { compact, get, omit } from 'lodash';
import { MainCard } from '@/modules/common/components/mainCard';
import { FormState } from '../constants/wizardContext';
import InvoiceSkeleton from '../components/InvoiceSkeleton';

const invoiceFormKeys = [
  'date',
  'memo',
  'dueDate',
  'externalReference',
] as const;
function isInvoiceFormKey(key: string): key is typeof invoiceFormKeys[number] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return invoiceFormKeys.includes(key as any);
}

const lineFormKeys = ['id', 'description', 'quantity', 'price'] as const;
function isLineFormKey(key: string): key is typeof lineFormKeys[number] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return lineFormKeys.includes(key as any);
}

const sprayPaintInvoiceLineToValue = (
  invoiceLine: InvoiceLine,
): FormState['invoiceLines'][number] => ({
  id: invoiceLine.id,
  description: invoiceLine.description,
  quantity: invoiceLine.quantity,
  price: invoiceLine.price?.amount,
});

const LineTotalCount = ({
  lineIndex,
  control,
}: {
  lineIndex: number;
  control: Control<FormState>;
}) => {
  const price = Number(
    useWatch({
      control,
      name: `invoiceLines.${lineIndex}.price`,
    }),
  );
  const quantity = Number(
    useWatch({
      control,
      name: `invoiceLines.${lineIndex}.quantity`,
    }),
  );

  return <span>{price * quantity}</span>;
};

const LinesTotalCount = ({ control }: { control: Control<FormState> }) => {
  const lines = useWatch({
    control,
    name: 'invoiceLines',
  });

  const sum = lines.reduce((acc, { price, quantity }) => {
    if (!price || !quantity) return acc;
    return acc + price * quantity;
  }, 0);

  return <span>{sum}</span>;
};

const AdminInvoiceEdit = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data, refetch } = useInvoice(id as string);
  const navigate = useNavigate();

  const invoice = data?.data;
  if (!invoice) {
    return <InvoiceSkeleton />;
  }

  const initialValues: FormState = {
    date: invoice.date,
    memo: invoice.memo,
    dueDate: invoice.dueDate,
    externalReference: invoice.externalReference,
    invoiceLines: invoice.invoiceLines.map((l) =>
      sprayPaintInvoiceLineToValue(l),
    ),
  };

  const {
    handleSubmit,
    control,
    register,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormState>({
    defaultValues: initialValues,
  });

  const onSubmit = async (values: FormState) => {
    const invoiceValues = omit(values, 'invoiceLines');

    invoice.isPersisted = true;
    invoice.assignAttributes(invoiceValues);

    // Mark all invoice lines for destruction, we will unmark the ones that are still present
    invoice.invoiceLines.forEach((spraypaintLine) => {
      // Expected object mutation
      // eslint-disable-next-line no-param-reassign
      spraypaintLine.isMarkedForDestruction = true;
    });

    const orderedInvoiceLines = [] as InvoiceLine[];

    // Update or create invoice lines
    values.invoiceLines.forEach((lineValues) => {
      // Search for existing invoice line
      let line = invoice.invoiceLines.find(
        (l) => lineValues.id && l.id === lineValues.id,
      );
      if (line) {
        // This line was existing previously, it will be updated
        line.isPersisted = true;
        line.isMarkedForDestruction = false;
      } else {
        // This line is new, it will be created
        line = new InvoiceLine();
        invoice.invoiceLines.push(line);
      }

      orderedInvoiceLines.push(line);

      // Update line values
      line.quantity = lineValues.quantity === null ? 0 : lineValues.quantity;
      line.description =
        lineValues.description === null ? '' : lineValues.description;

      if (!line.price)
        line.price = { amount: 0, currency: invoice.currencyIsoCode || 'EUR' };
      line.price.amount = lineValues.price === null ? 0 : lineValues.price;
    });

    const success = await invoice.save({ with: 'invoiceLines' });
    if (success) {
      toast.success(
        <Toaster
          textMsg={t('views.shared.change_applied')}
          toastType="success"
        />,
      );
      navigate(`/v2/admin/invoices/${invoice.id}`);
    } else {
      const apiErrors = [];
      if (Object.keys(invoice.errors).length > 0)
        apiErrors.push(invoice.errors);

      Object.keys(invoice.errors).forEach((e) => {
        if (isInvoiceFormKey(e)) {
          setError(e, {
            type: 'backend',
            message: invoice.errors[e]?.fullMessage,
          });
        }
      });

      orderedInvoiceLines.forEach((line, index) => {
        Object.keys(line.errors).forEach((e) => {
          if (isLineFormKey(e)) {
            setError(`invoiceLines.${index}.${e}`, {
              type: 'backend',
              message: line.errors[e]?.fullMessage,
            });
          }
        });
      });
      invoice.invoiceLines.forEach((line) => {
        Object.keys(line.errors).forEach((e) => {
          apiErrors.push(line.errors[e]?.fullMessage);
        });
      });
      compact(apiErrors).forEach((e) => {
        toast.error(<Toaster textMsg={String(e)} toastType="error" />);
      });
      await refetch();
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invoiceLines',
    keyName: 'fieldId',
  });

  const addInvoiceLine = () => {
    append({
      id: null,
      description: null,
      quantity: null,
      price: null,
    });
  };

  const removeInvoiceLine = (lineIndex: number) => {
    remove(lineIndex);
  };

  return (
    <PageLayout
      Title={t('front.invoices.show.header.title', { number: invoice.number })}
    >
      <MainCard header="Edit">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex">
            <div className="flex-col">
              <ControlledDateInput
                control={control}
                label="Date"
                name="date"
                stringMode
              />
              <ControlledDateInput
                control={control}
                label="Date d'échéance"
                name="dueDate"
                stringMode
              />
              <TextInput
                required
                {...register('externalReference')}
                label="Référence externe"
                errorText={get(errors, 'externalReference')}
              />
              <TextInput
                required
                {...register('memo')}
                label="Remarques"
                errorText={get(errors, 'memo')}
              />
            </div>
            <div className="ml-20 flex-col">
              {fields.map((field, index) => (
                <div className="flex pb-3">
                  <div className="pr-3">
                    <TextInput
                      key={field.fieldId}
                      required
                      {...register(`invoiceLines.${index}.description`)}
                      errorText={get(
                        errors,
                        `invoiceLines.${index}.description`,
                      )}
                      label="Description"
                    />
                  </div>
                  <div className="pr-3">
                    <TextInput
                      key={field.fieldId}
                      required
                      {...register(`invoiceLines.${index}.quantity`)}
                      label="Quantité"
                      errorText={get(errors, `invoiceLines.${index}.quantity`)}
                    />
                  </div>
                  <div className="pr-3">
                    <TextInput
                      key={field.fieldId}
                      required
                      {...register(`invoiceLines.${index}.price`)}
                      errorText={get(errors, `invoiceLines.${index}.price`)}
                      label="Montant HT"
                    />
                  </div>
                  {field.price && field.quantity && (
                    <p className="mt-9">
                      <LineTotalCount lineIndex={index} control={control} />{' '}
                      {invoice.currencyIsoCode}
                    </p>
                  )}
                  <div>
                    <ButtonPrimary
                      className="m-2 mt-6"
                      onClick={() => removeInvoiceLine(index)}
                    >
                      Remove line
                    </ButtonPrimary>
                  </div>
                </div>
              ))}
              <LinesTotalCount control={control} /> {invoice.currencyIsoCode}
              <ButtonPrimary className="m-2 mt-2" onClick={addInvoiceLine}>
                Add line
              </ButtonPrimary>
            </div>
          </div>

          <ButtonPrimary
            className="m-2"
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Submit
          </ButtonPrimary>
        </form>
      </MainCard>
    </PageLayout>
  );
};

export default AdminInvoiceEdit;
