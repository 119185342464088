/* eslint-disable import/no-cycle */
// api/PayslipFunnel.ts
import { Model, Attr, HasMany, HasOne } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type { PeriodType } from '../../../modules/common/utils/type.utils';
import Transition from '../Transition';
import PayslipFunnelInvoicesStep from './PayslipFunnelInvoicesStep';
import PayslipFunnelExpensesStep from './PayslipFunnelExpensesStep';
import PayslipFunnelCraStep from './PayslipFunnelCraStep';
import PayslipFunnelIntermediateSummaryStep from './PayslipFunnelIntermediateSummaryStep';
import PayslipFunnelSalaryStep from './PayslipFunnelSalaryStep';
import PayslipFunnelContextChangeMessage from './PayslipFunnelContextChangeMessage';
import PayslipFunnelFinalSummaryStep from './PayslipFunnelFinalSummaryStep';

@Model()
class PayslipFunnel extends ApplicationRecord {
  static jsonapiType = 'payslip_funnels';

  // Attributes
  @Attr() employmentContractId!: number;
  @Attr() period!: PeriodType;
  @Attr({ persist: false }) stc!: boolean;
  @Attr({ persist: false }) status!: string;
  @Attr({ persist: false }) dateRangeLastDate: string;
  @Attr({ persist: false }) dateRangeFirstDate: string;

  // Relationships
  @HasMany(Transition) transitions!: Transition[];
  @HasOne(PayslipFunnelInvoicesStep) invoicesStep!: PayslipFunnelInvoicesStep[];
  @HasOne(PayslipFunnelCraStep) craStep!: PayslipFunnelCraStep;
  @HasOne(PayslipFunnelExpensesStep)
  expensesStep!: PayslipFunnelExpensesStep[];
  @HasOne(PayslipFunnelIntermediateSummaryStep)
  intermediateSummaryStep!: PayslipFunnelIntermediateSummaryStep;
  @HasOne(PayslipFunnelSalaryStep) salaryStep!: PayslipFunnelSalaryStep[];
  @HasMany(PayslipFunnelContextChangeMessage)
  contextChangeMessages!: PayslipFunnelContextChangeMessage[];
  @HasOne(PayslipFunnelFinalSummaryStep)
  finalSummaryStep!: PayslipFunnelFinalSummaryStep;
}

export default PayslipFunnel;
