/* eslint-disable radix */
import { ComponentProps } from 'react';
import { FilterFn, RowData } from '@tanstack/react-table';
import { MaskedTextInput } from '../../inputs/MaskedTextInput';
import { FilterComponentProps, PriumFilterFn } from './types';
import { TextFilter } from './TextFilter';
import { TextInput } from '../../inputs';

export type PeriodFilterOptions = Partial<
  ComponentProps<typeof MaskedTextInput>
>;

export type TextInputOptions = Partial<ComponentProps<typeof TextInput>>;

export function getSupInfFilter<TData extends RowData>(): FilterFn<TData> {
  const filter: PriumFilterFn<TData> = (
    row,
    columnId,
    filterValue: unknown,
  ) => {
    const value = row.getValue(columnId);

    if (value == null) return false;

    return value === filterValue;
  };

  filter.backendFilterFn = (columnId, value: string) => {
    const firstLetter = Array.from(value)[0];

    const splitedColumnsId = columnId.split('_');

    const columnName = splitedColumnsId[0];

    if (firstLetter === '>') {
      const intValue = parseFloat(value.split('>')[1].split(',').join('.'));
      return { [columnName]: { gte: intValue } };
    }

    if (firstLetter === '<') {
      const intValue = parseFloat(value.split('<')[1].split(',').join('.'));
      return { [columnName]: { lte: intValue } };
    }

    if (value.split('-').length > 1) {
      const intValue1 = parseFloat(value.split('-')[0].split(',').join('.'));
      const intValue2 = parseFloat(value.split('-')[1].split(',').join('.'));
      return { [columnName]: { gte: intValue1, lte: intValue2 } };
    }

    const intValue = parseInt(value.replace(/\s/g, ''));
    const [lower, upper] =
      intValue >= 0 ? [intValue, intValue + 1] : [intValue - 1, intValue];

    return {
      [columnName]: { gte: lower, lte: upper },
    };
  };

  filter.resolveFilterValue = (value) => value;

  return filter;
}

const inputProps = {
  placeholder: 'Enter a number',
  inputCustomClass: 'no-arrow w-[133px]',
};

export function SupInfFilter<TData, TValue extends string>({
  options,
  ...props
}: FilterComponentProps<TData, TValue, PeriodFilterOptions>) {
  const optionsWithDefaults = {
    ...inputProps,
    ...options,
  };

  return <TextFilter {...props} options={optionsWithDefaults} />;
}
