import { createContext, FunctionComponent, useContext, useState } from 'react';
import classNames from 'classnames';
import { useAppEnvName } from '@/app/hooks/useAppEnvName';

export type EnvBannerContextType = {
  hidden: boolean;
  hide: () => void;
};

export const EnvBannerContext = createContext<EnvBannerContextType>({
  hidden: false,
  hide: () => {},
});

export function useEnvBannerProvider() {
  const envName = useAppEnvName();
  const [hidden, setHidden] = useState(envName === 'production');

  return {
    hidden,
    hide: () => setHidden(true),
  };
}

export const EnvBanner: FunctionComponent = () => {
  const envName = useAppEnvName();
  const { hidden, hide } = useContext(EnvBannerContext);

  if (hidden) {
    return null;
  }

  const envCustomClass = classNames('w-full fixed top-0 z-[1020] h-10', {
    'bg-color-success': envName === 'staging',
    'bg-color-transparent-pone-orange': envName === 'development',
  });

  return (
    <div className={envCustomClass}>
      <button
        className="absolute right-[20px] top-[3px] py-[2px] px-[9px] text-color-disabled border-solid  "
        type="button"
        onClick={hide}
      >
        X
      </button>
      <p className="text-center text-color-disabled text-xl tracking-[2.5em]">
        {envName.toUpperCase()}
      </p>
    </div>
  );
};
