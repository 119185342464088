import classNames from 'classnames';
import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import OverlayLoader from '../../../../app/components/OverlayLoader';
import CostType from '../types/CostType';
import { ItemTypes } from '../types/dragItems';
import ExpenseCard from './ExpenseCard';

type Props = {
  selectedCosts: CostType[];
  isExpenseStepRefetching: boolean;
  unselectExpense: (expenseId: number) => void;
};

export const ExpensesToReimburse = ({
  unselectExpense,
  selectedCosts,
  isExpenseStepRefetching,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.ADD_EXPENSE,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  const containerClassName = classNames(
    'flex flex-1 flex-col p-[18px] bg-color-bg-expense-card border-color-medium-light border-[1px] mt-[13px] rounded-xl shadow-inner',
    {
      'bg-color-drop-down-blue': isActive,
    },
  );
  drop(ref);

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex justify-between">
        <p className="text-base3 text-color-medium-grey">
          {t('front.payslip.tdc.expense.added.title')}
        </p>
      </div>
      <div ref={ref} className={containerClassName}>
        {selectedCosts.length > 0 && (
          <OverlayLoader loading={isExpenseStepRefetching}>
            {selectedCosts.map((c, index) => (
              <ExpenseCard
                key={c.id || `${index}_${c.label}`}
                type={ItemTypes.REMOVE_EXPENSE}
                isDraggable
                cost={c}
                status="validated"
                expenseStatusFront="added"
                onClick={unselectExpense}
                disabled={c.mandatory}
              />
            ))}
          </OverlayLoader>
        )}
      </div>
    </div>
  );
};
