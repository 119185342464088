import { FunctionComponent } from 'react';
import { useCurrentOnboardingFunnel } from '@/modules/welcome/hooks/useCurrentOnboardingFunnel';
import { toast } from 'react-toastify';
import { Toaster } from '@/modules/common/components/toasts';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@/modules/common/components/PageLayout';
import useBreakpoints from '@/app/responsive/useBreakPoints';
import { Stepper } from '@/modules/navigation/components';
import {
  OnboardingFunnelStepMapping,
  StepNameBackToFront,
  stepsComponents,
} from '@/modules/welcome/constantes/OnboardingFunnelConst';
import { compact } from 'lodash';
import { ValidationErrors } from 'spraypaint/lib-esm/validation-errors';
import { useOnboardingFunnelTransition } from '../hooks/useOnboardingFunnelTransition';
import {
  OnboardingStepsTypes,
  SaveStepType,
  StepStatus,
} from '../types/OnboardingStepsType';

export const OnBoardingFunnel: FunctionComponent = () => {
  const { t } = useTranslation();
  const { isXs } = useBreakpoints();
  const { onboardingFunnel } = useCurrentOnboardingFunnel();

  const { mutate: performTransition, isLoading: transitionLoader } =
    useOnboardingFunnelTransition({
      onboardingFunnelId: Number(onboardingFunnel?.id),
      errorMsg: 'default.funnel.back.error',
    });

  const translatedSteps = Object.values(OnboardingFunnelStepMapping).map((s) =>
    t(s),
  );

  const StepComponent = stepsComponents[onboardingFunnel?.status as StepStatus];

  const saveStep = async ({
    data,
    stepToSave,
  }: SaveStepType): Promise<[boolean, Error | null]> => {
    const s: OnboardingStepsTypes = stepToSave;
    s.isPersisted = true;
    s.assignAttributes(data);
    try {
      await stepToSave.save();
      return [true, null];
    } catch (errors) {
      console.error('On boarding transition failed', errors);
      return [false, errors as Error];
    }
  };

  const saveThenTransition = async ({
    data,
    transitionName,
    stepToSave,
  }: {
    data: Record<string, unknown> | undefined;
    transitionName: string;
    stepToSave: OnboardingStepsTypes;
  }) => {
    const [success, errors] = await saveStep({ data, stepToSave });
    const apiErrors: (ValidationErrors<OnboardingStepsTypes> | Error)[] = [];

    if (success) {
      performTransition({ transitionName });
      toast.success(
        <Toaster
          textMsg={t('front.welcome.onboarding.stepTransition.success')}
          toastType="success"
        />,
      );
    } else {
      apiErrors.push(errors as Error);
      if (Object.keys(stepToSave.errors).length > 0) {
        apiErrors.push(stepToSave.errors);
      }
      const validErrors = compact(apiErrors);
      validErrors.forEach((error) => {
        let msg;
        if (error instanceof Error) {
          if (validErrors[0].message === 'invalid json') {
            msg = 'Veuillez compléter le formulaire';
          } else {
            msg = 'Veuillez contacter le support';
          }
        } else {
          msg = String(
            (error as ValidationErrors<OnboardingStepsTypes>).full_messages,
          );
        }
        toast.error(<Toaster textMsg={`Erreur : ${msg}`} toastType="error" />);
      });
    }
  };

  const handleMutationTransition = (toStep: string) => {
    performTransition({
      transitionName: `back_to_${toStep}`,
      toStep,
      fromStep: onboardingFunnel?.status,
    });
  };

  const currentStepIndex = Object.keys(OnboardingFunnelStepMapping).findIndex(
    (element) => element === onboardingFunnel?.status,
  );

  const handleOnStepperClick =
    (index: number): (() => void) =>
    () => {
      const transitionName = StepNameBackToFront(index as 0 | 1 | 2 | 3 | 4);
      handleMutationTransition(transitionName);
    };

  if (!onboardingFunnel) return null;

  return (
    <PageLayout Title="Onboarding">
      <Stepper
        vertical={isXs}
        isLoading={transitionLoader}
        className="mt-[26px] ml-7"
        steps={translatedSteps}
        currentStep={currentStepIndex}
        onClick={handleOnStepperClick}
      />
      {StepComponent && (
        <StepComponent
          onboardingFunnel={onboardingFunnel}
          performTransition={performTransition}
          saveThenTransition={saveThenTransition}
          saveStep={saveStep}
        />
      )}
    </PageLayout>
  );
};
