import type { FunctionComponent } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { CapGeminiConsolidationImport } from '@/app/spraypaint';
import Table from '@/modules/common/components/table/Table';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '@/modules/common/components/buttons';
import { UploadDocInput } from '@/modules/common/components/inputs';
import { useState } from 'react';
import {
  blobToDataUrl,
  uploadBlob,
} from '@/modules/common/components/inputs/functions/commonUploadFileFunctions';
import { ModelRecord } from 'spraypaint/lib-esm/model';

const ch = createColumnHelper<ModelRecord<CapGeminiConsolidationImport>>();

const columns = [
  ch.accessor('id', { header: 'ID' }),
  ch.accessor('createdAt', { header: 'Création' }),
  ch.display({
    id: 'actions',
    cell: ({ row: { original } }) => {
      const queryClient = useQueryClient();

      if (!original) return null;

      return (
        <div className="flex">
          <ButtonPrimary to={`./${original.id}`} className="px-[35px] mx-2">
            Voir
          </ButtonPrimary>
          <ButtonSecondary
            className="px-[35px] mx-2"
            onClick={async () => {
              if (
                // eslint-disable-next-line no-alert
                window.confirm(
                  'Êtes-vous sûr de vouloir supprimer cet import ?',
                )
              ) {
                await (original as CapGeminiConsolidationImport).destroy();
                await queryClient.invalidateQueries({
                  queryKey: ['capGeminiImports'],
                });
              }
            }}
          >
            Supprimer
          </ButtonSecondary>
        </div>
      );
    },
  }),
];

export const CapGeminiImportIndex: FunctionComponent = () => {
  const [scope, scopeKey, reactTableProps] =
    useBackendTable<CapGeminiConsolidationImport>();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['capGeminiImports', scopeKey],
    queryFn: () =>
      scope(CapGeminiConsolidationImport.order({ createdAt: 'desc' })).all(),
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { mutate, isLoading: isMutationLoading } = useMutation(
    async (file: File | null) => {
      if (!file) {
        console.debug('No file selected');
        return;
      }
      const bytes = await blobToDataUrl(file);

      const blobPayload: Parameters<typeof uploadBlob>[0] = {
        filename: file.name,
        contentType: file.type,
        content: { bytes },
      };

      const blob = await uploadBlob(blobPayload);

      const capGeminiImport = new CapGeminiConsolidationImport({
        fileSignedId: blob.id,
      });

      await capGeminiImport.saveOrThrow();
    },
    {
      onSuccess: () => refetch(),
      onError: (error) => console.error(error),
    },
  );

  return (
    <PageLayout
      Title="Imports Fieldglass"
      Buttons={
        <UploadDocInput
          handleChangeFile={setSelectedFile}
          handleUploadFile={() => mutate(selectedFile)}
          isLoading={isMutationLoading}
          fileName={selectedFile?.name}
          setFileName={() => {}}
          acceptedFile={['.xls', '.xlsx']}
          label="Importer un fichier Fieldglass"
        />
      }
    >
      <Table
        data={data?.data ?? []}
        columns={columns}
        isLoading={isLoading}
        reactTableProps={reactTableProps}
      />
    </PageLayout>
  );
};
