import { ComponentProps } from 'react';
import { FilterFn, RowData } from '@tanstack/react-table';
import { MaskedTextInput } from '../../inputs/MaskedTextInput';
import { FilterComponentProps, PriumFilterFn } from './types';
import { TextFilter } from './TextFilter';

export type PeriodFilterOptions = Partial<
  ComponentProps<typeof MaskedTextInput>
>;

/** UTILITY FUNCTIONS  */

function toDate(input: unknown): Date | null {
  if (input instanceof Date) {
    // If the input is already a Date object, return it
    return input;
  }
  if (typeof input === 'string') {
    // If the input is a string, attempt to parse it as a date
    const date = new Date(input);
    if (!Number.isNaN(date.getTime())) {
      // If the input string is a valid date, return the Date object
      return date;
    }
  }

  // If the input is not a Date object or a parsable string, return null
  return null;
}

// This function takes a string as input and attempts to extract the month
// and year values from the string.
function extractDate(
  dateString: unknown,
): { month: number; year: number } | { month: null; year: null } {
  // If the input is not a string, return null object
  if (typeof dateString !== 'string') {
    return { month: null, year: null };
  }

  // Use a regular expression to extract the month and year from the string
  const dateRegex = /^(\d{1,2})\/(\d{4})$/;
  const [, month, year] = dateString.match(dateRegex) || [];

  // If the regular expression didn't match, return null object
  if (!month || !year) {
    return { month: null, year: null };
  }

  // Parse the month and year values as numbers
  const monthValue = parseInt(month, 10);
  const yearValue = parseInt(year, 10);

  // Return the date object
  return { month: monthValue, year: yearValue };
}

// This function returns a filter function that can be used to filter table data
// by month and year.
export function getPeriodFilterFn<TData extends RowData>(): FilterFn<TData> {
  // The filter function checks whether the value in a given row and column
  // matches the specified month and year.
  // eslint-disable-next-line func-names
  const filter: PriumFilterFn<TData> = function (
    row,
    columnId,
    filterValue: ReturnType<typeof extractDate>,
  ) {
    // Destructure the month and year from the filter value object
    const { month, year } = filterValue;

    // If the month is null, return true (i.e. the value should be included)
    if (month == null) return true;

    // Get the value in the specified row and column
    const value = row.getValue(columnId);

    // Attempt to parse the value as a Date object
    const date = toDate(value);

    // If the value cannot be parsed as a Date, return false
    // (i.e. the value should be excluded)
    if (date == null) return false;

    // Check whether the year and month of the date match the specified values
    // If they do, return true (i.e. the value should be included)
    // If they don't, return false (i.e. the value should be excluded)
    return date.getFullYear() === year && date.getMonth() + 1 === month;
  };
  // This property tells the table component how to extract a filter value
  // from the user-provided filter string (e.g. "10/2022")
  filter.resolveFilterValue = extractDate;

  // This property tells the table component how to determine whether
  // the user-provided filter string should be removed. In this case,
  // the filter string will be removed if it is the default value
  // ("MM/YYYY")
  filter.autoRemove = (value) => value === 'MM/YYYY';

  filter.backendFilterFn = (
    columnId,
    { month, year }: { month: number; year: number },
  ) => {
    if (!month || !year) return {};

    // Get the first day of the month
    const firstDay = new Date(year, month - 1, 2);
    const firstDayString = firstDay.toISOString().split('T')[0];
    console.debug({
      firstDay,
      firstDayString,
    });

    // Get the last day of the month
    const lastDay = new Date(year, month, 1);
    const lastDayString = lastDay.toISOString().split('T')[0];
    console.debug({
      lastDay,
      lastDayString,
    });

    return {
      [columnId]: { gte: firstDayString, lte: lastDayString },
    };
  };

  // Return the filter function
  return filter;
}

const defaultOptions = {
  placeholder: 'MM/YYYY',
  maskPlaceholder: 'MM/YYYY',
  mask: '99/9999',
};

// By setting "TValue extends string | Date", we ensure that this filter component can
// only be used with string or Date columns
export function PeriodFilter<TData, TValue extends string | Date>({
  options,
  ...props
}: FilterComponentProps<TData, TValue, PeriodFilterOptions>) {
  const optionsWithDefaults = {
    ...defaultOptions,
    ...options,
  };

  return <TextFilter {...props} options={optionsWithDefaults} />;
}
