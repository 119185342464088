import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SalaireIcon } from '../../app/assets/icons/menu';
import { WidgetComponent } from './WidgetComponent';

export type Props = {
  status?: 'done' | 'in_progress' | 'validated';
};

const mappedColor = {
  done: {
    text: 'front.payslip.widget.done',
    backgroundColor: 'bg-color-warning',
  },
  in_progress: {
    text: 'front.payslip.widget.in_progress',
    backgroundColor: 'bg-color-light-blue',
  },
  validated: {
    text: 'front.payslip.widget.validated',
    backgroundColor: 'bg-color-warning',
  },
};

export const PayslipFunnelWidget = ({ status = 'in_progress' }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { backgroundColor, text } = mappedColor[status];
  const onClick = () => {
    if (status === 'in_progress') navigate('payslips/create');
    else navigate('payslips');
  };
  return (
    <WidgetComponent
      onClick={onClick}
      spanClassName={backgroundColor}
      Icon={<SalaireIcon className="fill-white" />}
    >
      {t(text)}
    </WidgetComponent>
  );
};
