import URLSearchParams from '@ungap/url-search-params';
import { useOmniNavigate } from '@/app/navigation/hooks/useOmniNavigate';

// The second [^\/] is to avoid redirecting to a local file
const pathWithoutDomainRegex = /^\/[^/]/;

// Protects against Open Redirect attack
export function isValidRedirectPath(path: string | null | undefined): boolean {
  if (!path) return false;

  // Use a regular expression to ensure that the 'next' parameter is a valid absolute path without a domain
  if (!pathWithoutDomainRegex.test(path)) return false;

  // If path starts with /login or /auth, it could create a redirect loop
  if (path.startsWith('/v2/login') || path.startsWith('/v2/auth')) return false;

  return true;
}

export function computeAfterLoginRedirect(
  defaultRedirect: string = DEFAULT_LOGIN_REDIRECT,
): string {
  const queryParams = new URLSearchParams(window.location.search);
  const nextParam = queryParams.get('next');
  if (nextParam && isValidRedirectPath(nextParam)) {
    return nextParam;
  }

  return defaultRedirect;
}

export function useRedirectAfterLogin(): (defaultRedirect?: string) => void {
  const navigate = useOmniNavigate();

  return (defaultRedirect: string = DEFAULT_LOGIN_REDIRECT) => {
    const dest = computeAfterLoginRedirect(defaultRedirect);
    navigate(dest);
  };
}
