import { forwardRef } from 'react';
import classNames from 'classnames';
import { TextInput } from '../TextInput';

type CustomDatePickerInputProps = {
  label?: string;
  value?: string;
  disabled?: boolean;
  onChange?: () => void;
  onFocus?: () => void;
  onClick?: () => void;
};

export const CustomDatePickerInput = forwardRef(
  (props: CustomDatePickerInputProps, ref) => {
    const divClasses = classNames(
      { 'text-color-pone-dark': props.value !== '' },
      'w-36 text-base flex items-center',
    );

    const inputCustomClass = classNames(
      'w-36 px-3 focus:bg-color-light-grey focus:text-color-pone-dark active:text-color-pone-dark ' +
        'bg-calendar-icon-grey focus:bg-calendar-icon-orange ' +
        'bg-no-repeat bg-scroll bg-auto bg-[center_right_0.5rem]',
      {
        'text-color-medium-grey': props.value === '',
        'hover:bg-calendar-icon-orange': !props.disabled,
      },
    );

    return (
      <div className={divClasses}>
        <TextInput
          label={props.label}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onClick={props.onClick}
          ref={ref}
          inputCustomClass={inputCustomClass}
          {...props}
        />
      </div>
    );
  },
);
