import { PayslipFunnelBaseStep } from '@/app/spraypaint';
import { Alert } from '@/modules/common/components/toasts';
import { useTranslation } from 'react-i18next';

const StepMessages = ({
  step,
}: {
  step: PayslipFunnelBaseStep | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {(step?.messagesI18nKeys || []).map((key) => (
        <Alert
          className="my-[10px] ml-[30px]"
          alertType="warn"
          text={t(key, { ns: 'rails' })}
        />
      ))}
    </>
  );
};

export default StepMessages;
