import { useTranslation } from 'react-i18next';

const useSecuritisOptions = () => {
  const { t } = useTranslation();

  const securitisOptions = [
    { label: t('views.payslip_simulation.securitis.totale'), value: 'totale' },
    {
      label: t('views.payslip_simulation.securitis.partielle'),
      value: 'partielle',
    },
  ];

  return { securitisOptions };
};
export default useSecuritisOptions;
