import classNames from 'classnames';
import React, {
  ChangeEventHandler,
  ComponentProps,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { TextInput } from '../../inputs';
import { MaskedTextInput } from '../../inputs/MaskedTextInput';
import { FilterComponentProps } from './types';
import { ClearButton } from '../components/ClearButton';

export type TextFilterOptions = Partial<
  Omit<
    ComponentProps<typeof MaskedTextInput>,
    'value' | 'onChange' | 'onKeyUp' | 'autoFocus'
  >
> & {
  inputCustomClass?: string;
};

type TextFilterProps<TData, TValue> = FilterComponentProps<
  TData,
  TValue,
  TextFilterOptions
>;

export function TextFilter<TData, TValue>({
  header,
  onSubmit = () => {},
  options = {},
}: TextFilterProps<TData, TValue>) {
  const { column } = header;
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    const filterValue = column.getFilterValue();
    const sanitizedValue = filterValue?.toString() || '';
    setInputValue(sanitizedValue);
  }, [column]);

  const onValueChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      column.setFilterValue(newValue);
    },
    [column],
  );

  const handleClearButtonClick = useCallback(() => {
    setInputValue('');
    column.setFilterValue('');
  }, [column]);

  const watchEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') onSubmit();
    },
    [onSubmit],
  );

  const inputClassName = classNames(
    'w-[133px] relative',
    options.inputCustomClass,
  );

  return (
    <div>
      {options.mask ? (
        <div>
          <MaskedTextInput
            {...options}
            className="max-w-full relative"
            value={inputValue}
            onChange={onValueChange}
            onKeyUp={watchEnter}
            autoFocus
          />
          {inputValue !== '' && (
            <ClearButton
              onClick={handleClearButtonClick}
              customClassName="right-[15%] top-[30%]"
            />
          )}
        </div>
      ) : (
        <TextInput
          value={inputValue}
          inputCustomClass={inputClassName}
          onChange={onValueChange}
          onKeyUp={watchEnter}
          autoFocus
          ClearableBtn={
            <ClearButton
              onClick={handleClearButtonClick}
              customClassName="right-[10%]"
            />
          }
          {...options}
        />
      )}
    </div>
  );
}
