import {
  PaginationState,
  RowData,
  SortingState,
  Table,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { useControlledPagination } from './useControlledPagination';
import {
  ContextedColumnFiltersState,
  useControlledColumnFilters,
} from './useControlledColumnFilters';
import { useControlledSorting } from './useControlledSorting';

export type ControlledState<TData> = {
  pagination: PaginationState;
  sorting: SortingState;
  columnFilters: ContextedColumnFiltersState<TData>;
};

export type Tools = {
  resetPagination: () => void;
};

export function useControlledTable<TData extends RowData>({
  initialPageSize = 10,
  initialSorting = [],
}: {
  initialPageSize: number;
  initialSorting: SortingState;
}): [
  ControlledState<TData>,
  Table<TData> | undefined,
  Partial<TableOptions<TData>>,
  Tools,
] {
  const { paginationState, onPaginationChange, resetPagination } =
    useControlledPagination({ initialPageSize });

  const { sortingState, onSortingChange } = useControlledSorting({
    resetPagination,
    initialState: initialSorting,
  });

  const { columnFiltersState, table, onColumnFiltersChange } =
    useControlledColumnFilters<TData>({ resetPagination });

  const state = useMemo(
    () => ({
      pagination: paginationState,
      sorting: sortingState,
      columnFilters: columnFiltersState,
    }),
    [paginationState, sortingState, columnFiltersState],
  );

  const reactTableProps = useMemo(
    () => ({
      state,
      onPaginationChange,
      onSortingChange,
      onColumnFiltersChange,
      manualFiltering: true,
      manualPagination: true,
      manualSorting: true,
    }),
    [state, onPaginationChange, onSortingChange, onColumnFiltersChange],
  );

  return [
    state,
    table,
    reactTableProps,
    {
      resetPagination,
    },
  ];
}
