import { useQuery } from '@tanstack/react-query';
import { GraphitiScoper } from '@spraypaint/types';
import SepaTransaction from '../../../../../app/spraypaint/sepaTransactions/SepaTransaction';
import BankAccount from '../../../../../app/spraypaint/BankAccount';
import { ContribDeposit, Invoice } from '../../../../../app/spraypaint';

export const useGetBankReconciliationInformation = (id: string) => {
  const data = useQuery({
    queryKey: ['get-bank-reconciliation-information', id],
    queryFn: () =>
      SepaTransaction.includes(['legal_entity'])
        .selectExtra([
          'previous_pending_transaction_id',
          'next_pending_transaction_id',
        ])
        .find(id),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
  const bankReconciliationInformation = data?.data?.data;
  const entityName = bankReconciliationInformation?.legalEntity?.name;
  const legalEntityId = bankReconciliationInformation?.legalEntity?.id ?? '';

  return { bankReconciliationInformation, entityName, legalEntityId, ...data };
};

export const useGetBankInformation = (legalEntityId: string | undefined) => {
  const data = useQuery({
    queryKey: ['get-bank-information', legalEntityId],
    queryFn: () => BankAccount.where({ legal_entity_id: legalEntityId }).all(),
    refetchOnWindowFocus: false,
    enabled: !!legalEntityId,
    cacheTime: 0,
  });
  const bankAccount = data?.data?.data[0];

  return { bankAccount, ...data };
};

type InvoiceArgsType = {
  scope: GraphitiScoper<Invoice>;
  scopeKey: Array<string>;
};

export const useGetInvoicesBankTransfert = (
  legalEntityId: string,
  { scope, scopeKey }: InvoiceArgsType,
) => {
  const data = useQuery({
    queryKey: ['get-invoices-bank-transfert', legalEntityId, scopeKey],
    queryFn: () =>
      scope(Invoice.includes(['client']).scope())
        .where({
          legal_entity_id: legalEntityId,
          status: ['validated', 'partially_paid'],
        })
        .all(),
    refetchOnWindowFocus: false,
    enabled: !!legalEntityId,
    cacheTime: 0,
  });
  const totalCount = data?.data?.meta?.stats?.total?.count;
  const invoices = data?.data?.data;

  return { invoices, totalCount, ...data };
};

type ContribDepositArgsType = {
  scope: GraphitiScoper<ContribDeposit>;
  scopeKey: Array<string>;
};

export const useGetContribDeposits = (
  legalEntityId: string,
  { scope, scopeKey }: ContribDepositArgsType,
) => {
  const data = useQuery({
    queryKey: ['get-contrib-deposits-bank-transfert', legalEntityId, scopeKey],
    queryFn: () =>
      scope(ContribDeposit.includes(['contrib.employee']).scope())
        .where({
          legal_entity_id: legalEntityId,
          status: ['submitted'],
        })
        .all(),
    refetchOnWindowFocus: false,
    enabled: !!legalEntityId,
    cacheTime: 0,
  });
  const contribDepositTotalCount = data?.data?.meta?.stats?.total?.count;
  const contribDeposits = data?.data?.data;

  return { contribDeposits, contribDepositTotalCount, ...data };
};
