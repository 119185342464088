/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  getPeriodFilterFn,
  PeriodFilter,
} from '@/modules/common/components/table/filter';
import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import type EmployeePayslip from '@/app/spraypaint/EmployeePayslip';
import {
  AutoCreatedTooltip,
  AutoSubmittedTooltip,
  AutoValidatedTooltip,
  displayDateMonthYear,
} from '@/modules/admin/payslip/components/tooltips';
import { FollowUp } from '@/modules/admin/payslip/components/FollowUp';
import { ActionsCell } from '@/modules/admin/payslip/components/ActionsCell';
import { StatusCell } from '@/modules/admin/payslip/components/StatusCell';
import { dynT } from '../../../../app/translation/utils/dynT';

const columnHelper = createColumnHelper<ModelRecord<EmployeePayslip>>();

const payslipAdminColumns = [
  columnHelper.accessor((original) => original.fullName, {
    header: dynT('default.employee.fullName'),
    id: 'full_name',
  }),
  columnHelper.accessor((original) => original.createdAt, {
    filterFn: getPeriodFilterFn(),
    meta: {
      filter: {
        Component: PeriodFilter,
      },
    },
    header: dynT('front.employeePayslip.dashboard.colHeader.createdAt'),
    id: 'createdAt',
    cell: AutoCreatedTooltip,
  }),
  columnHelper.accessor((original) => original.submittedAt, {
    id: 'submittedAt',
    header: dynT('front.employeePayslip.dashboard.colHeader.submittedAt'),
    filterFn: getPeriodFilterFn(),
    meta: {
      filter: {
        Component: PeriodFilter,
      },
    },
    cell: AutoSubmittedTooltip,
  }),
  columnHelper.accessor((original) => original.validatedAt, {
    id: 'validatedAt',
    header: dynT('front.employeePayslip.dashboard.colHeader.validatedAt'),
    filterFn: getPeriodFilterFn(),
    meta: {
      filter: {
        Component: PeriodFilter,
      },
    },
    cell: AutoValidatedTooltip,
  }),
  columnHelper.accessor((original) => original.paymentDate, {
    id: 'paymentDate',
    header: dynT('front.employeePayslip.dashboard.colHeader.paymentDate'),
    filterFn: getPeriodFilterFn(),
    meta: {
      filter: {
        Component: PeriodFilter,
      },
    },
    cell: (val) => (
      <p className="flex justify-center items-center h-[100%]">
        {displayDateMonthYear(val.getValue() as string)}
      </p>
    ),
  }),
  columnHelper.accessor((original) => original.closedAt, {
    filterFn: getPeriodFilterFn(),
    id: 'closedAt',
    meta: {
      filter: {
        Component: PeriodFilter,
      },
    },
    header: dynT('front.employeePayslip.dashboard.colHeader.closedAt'),
    cell: (val) => (
      <p className="flex justify-center items-center h-[100%]">
        {displayDateMonthYear(val.getValue() as string)}
      </p>
    ),
  }),
  columnHelper.accessor('error', {
    header: dynT('front.employeePayslip.dashboard.colHeader.error'),
  }),
  columnHelper.display({
    id: 'followUp',
    enableSorting: false,
    size: 400,
    header: dynT('front.employeePayslip.dashboard.colHeader.followUp'),
    cell: FollowUp,
  }),
  columnHelper.accessor('remainingAmount', {
    enableSorting: false,
    enableColumnFilter: false,
    header: dynT('front.employeePayslip.dashboard.colHeader.remainingAmount'),
  }),
  columnHelper.accessor((original) => original.arbitration, {
    enableColumnFilter: false,
    id: 'arbitration',
    header: dynT('front.employeePayslip.dashboard.colHeader.arbitration'),
    cell: (val) => (
      <p className="flex justify-center items-center h-[100%]">
        {val.getValue() === true ? 'A' : '-'}
      </p>
    ),
  }),
  columnHelper.display({
    id: 'customStatus',
    header: dynT('views.payslip.status'),
    enableSorting: false,
    enableColumnFilter: false,
    cell: StatusCell,
  }),
  columnHelper.display({
    id: 'actions',
    header: dynT('default.actions'),
    cell: ActionsCell,
  }),
];

export default payslipAdminColumns;
