import { useQuery } from '@tanstack/react-query';
import PayslipFunnel from '../../../../app/spraypaint/payslipFunnel/PayslipFunnel';

export type CraMaxDaysInfoType = {
  paidHolidays?: number;
  workingTime?: number;
  maxHolidays?: number;
  maxWorkingDays?: number;
};

export const useGetCurrentCraDatas = (payslipFunnelId: number) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['craDatas', payslipFunnelId],
    retry: 0,
    queryFn: () =>
      PayslipFunnel.includes([
        { cra_step: { activity_report: 'activity_report_slots' } },
      ])
        .selectExtra({
          'payslip_funnel_steps-cra_steps': [
            'available_slot_types',
            'expenses_dates',
            'messages_i18n_keys',
          ],
        })
        .find(payslipFunnelId),
  });

  const activityReportSlots =
    data?.data.craStep.activityReport.activityReportSlots;

  const availableSlotTypes = data?.data.craStep.availableSlotTypes ?? [];

  const expensesDates = data?.data.craStep.expensesDates ?? [];

  return {
    data,
    isLoading,
    activityReportSlots,
    availableSlotTypes,
    expensesDates,
    refetch,
  };
};

export const useGetCurrentExtraCraDatas = (payslipFunnelId: number) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['extraCraData', payslipFunnelId],
    retry: 0,
    queryFn: () =>
      PayslipFunnel.includes(['cra_step'])
        .selectExtra({
          'payslip_funnel_steps-cra_steps': ['activity_summary'],
        })
        .find(payslipFunnelId),
  });

  const craMaxDaysInfos: CraMaxDaysInfoType = {
    paidHolidays: data?.data.craStep.paidHolidays,
    workingTime: data?.data.craStep.workingTime,
    maxHolidays: data?.data.craStep.maxHolidays,
    maxWorkingDays: data?.data.craStep.maxWorkedDays,
  };

  const completionErrors = data?.data.craStep.completionErrors ?? [];
  const activitySummary = data?.data.craStep.activitySummary ?? {};

  const mayComplete = data?.data.craStep.mayComplete;

  return {
    data,
    isLoading,
    mayComplete,
    craMaxDaysInfos,
    activitySummary,
    completionErrors,
    refetch,
  };
};
