import { useTranslation } from 'react-i18next';
import { CommonTutorial } from './CommonTutorial';
import { IlluPayslip } from '../../../../app/assets/icons/payslip';
import { TutorialText, TutorialTitle } from './components';
import { Line } from '../../../common/components/fragments';

const InvoiceTutorialText = () => {
  const { t } = useTranslation();
  return (
    <>
      <TutorialTitle className="text-color-pone-orange">
        {t('front.payslip.tdc.invoice.tutorial.title1')}
      </TutorialTitle>
      <TutorialText>
        {t('front.payslip.tdc.invoice.tutorial.text1')}
      </TutorialText>
      <Line className="my-[46px]" />
      <TutorialTitle>
        {t('front.payslip.tdc.invoice.tutorial.title2')}
      </TutorialTitle>
      <TutorialText>
        {t('front.payslip.tdc.invoice.tutorial.text2')}
      </TutorialText>
    </>
  );
};

export const InvoiceTutorial = ({ onClick }: { onClick: () => void }) => (
  <CommonTutorial
    onClick={onClick}
    Icon={<IlluPayslip height={213} width={186} />}
    Component={<InvoiceTutorialText />}
  />
);
