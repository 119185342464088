import { useRef } from 'react';
import classNames from 'classnames';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr'; // the locale you want
import { dateToString } from '@/app/utils/dateUtils';
import dayjs from '@/app/config/dayjs';
import { CustomDatePickerInput } from './components/CustomDatePickerInput';
import {
  defaultPlaceholder,
  getCustomHeader,
} from './components/dateInputTools';
import { Label } from './components';

registerLocale('fr', fr); // register it with the name you want

export const formattedWeekDay = (nameOfDay: string) =>
  nameOfDay.substring(0, 1).toUpperCase();

export type DateInputProps = {
  className?: string;
  placeholder?: string;
  label?: string;
  value: Date | string;
  onChange: (arg: Date | string) => void;
  required?: boolean;
  disabled?: boolean;
  stringMode?: boolean;
};

export function DateInput({
  className,
  placeholder,
  value,
  required,
  label,
  onChange,
  disabled,
  stringMode,
}: DateInputProps) {
  const inputRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const customClassName = classNames('flex flex-col w-80', className);

  // Convert to UTC date to avoid timezone issues
  const handleChange = (newDate: Date) => {
    const dateString = dateToString(newDate);
    if (stringMode) {
      onChange(dateString);
    } else {
      onChange(dayjs.utc(dateString).toDate());
    }
  };

  let dateValue: Date | undefined;
  if (typeof value === 'object') {
    dateValue = value;
  } else if (value) {
    dateValue = new Date(value);
  } else {
    dateValue = undefined;
  }

  return (
    <div className={customClassName}>
      {label && <Label required={required}>{label}</Label>}
      <DatePicker
        portalId="root-portal"
        locale="fr"
        formatWeekDay={formattedWeekDay}
        onChange={handleChange}
        selected={dateValue}
        customInput={
          <CustomDatePickerInput
            ref={inputRef}
            // @ts-ignore
            value={dateValue}
            data-testid="input-date"
            disabled={disabled}
          />
        }
        disabled={disabled}
        placeholderText={placeholder}
        showPopperArrow={false}
        calendarStartDay={1}
        popperPlacement="bottom-start"
        dateFormat="dd/MM/yyyy"
        renderCustomHeader={getCustomHeader()}
      />
    </div>
  );
}

DateInput.defaultProps = {
  placeholder: defaultPlaceholder,
};
