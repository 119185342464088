import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import {
  StrictEqualFilter,
  getStrictEqualFilter,
} from '../../common/components/table/filter/StrictEqualFilter';
import Subcontractor from '../../../app/spraypaint/Subcontractor';

const columnHelper = createColumnHelper<ModelRecord<Subcontractor>>();

const subcontractorsIndexColumns = ({
  employeeId,
}: {
  employeeId: string | undefined;
}) => {
  const { t } = useTranslation();

  const employeeFullNameColumn = columnHelper.accessor(
    (original) => original.employee?.user?.fullName,
    {
      header: t('default.employee.fullName'),
      id: 'employee_user_full_name',
    },
  );

  const sharedColumns = [
    columnHelper.accessor('id', {
      header: t('front.subcontractors.index.columnHeader.id'),
      filterFn: getStrictEqualFilter(),
      meta: {
        filter: {
          Component: StrictEqualFilter,
        },
      },
    }),
    columnHelper.accessor('siren', {
      header: t('activerecord.attributes.subcontractor.siren') as string,
    }),
    columnHelper.accessor('name', {
      header: t('activerecord.attributes.subcontractor.name') as string,
    }),
    columnHelper.accessor('contactName', {
      header: t('activerecord.attributes.subcontractor.contact_name') as string,
    }),
    columnHelper.accessor('status', {
      header: t('default.status') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];

  return employeeId
    ? sharedColumns
    : sharedColumns.concat(employeeFullNameColumn);
};

export default subcontractorsIndexColumns;
