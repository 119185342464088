import { TOptions } from 'i18next';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

type KeyFunction<TProps> = (props: TProps) => string;
type KeyType<TProps> = string | KeyFunction<TProps>;

// dynT: A function that returns a React FunctionComponent for dynamic translation.
// It translates and renders content based on the provided translation key.
export function dynT<TProps>(
  key: KeyType<TProps>,
  opts: TOptions = {},
): FunctionComponent<TProps> {
  return (props: TProps) => {
    const { t } = useTranslation();
    const actualKey = typeof key === 'function' ? key(props) : key;

    return <>{t(actualKey, opts)}</>;
  };
}
