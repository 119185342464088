import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PersistedSpraypaintRecord } from 'spraypaint';

import { PayslipFunnelSalaryStep } from '../../../../app/spraypaint';
import { Toaster } from '../../../common/components/toasts';

export function useSalaryStep(
  payslipFunnelId: number,
  {
    onSuccess,
  }: {
    onSuccess?: (
      data: PersistedSpraypaintRecord<PayslipFunnelSalaryStep>,
    ) => void;
  },
) {
  const { data, isFetched, ...other } = useQuery(
    ['payslipFunnel-salaryStep', payslipFunnelId],
    async () => {
      const collection = await PayslipFunnelSalaryStep.where({
        payslip_funnel_id: payslipFunnelId,
      })
        .selectExtra(['messages_i18n_keys'])
        .per(1)
        .all();

      return collection.data[0];
    },
    {
      onSuccess,
    },
  );

  const completionErrors = data?.completionErrors ?? [];
  return { data, isFetched, completionErrors, ...other };
}

export const useSelectSalaryStrategy = (callback: any) => {
  const { t } = useTranslation();
  const { mutate, isLoading, ...others } = useMutation({
    mutationFn: async ({
      salaryStep,
    }: {
      salaryStep: PayslipFunnelSalaryStep;
    }) => {
      try {
        await salaryStep.save();
      } catch {
        throw new Error();
      }
    },
    onSuccess: () => {
      if (callback) callback();
    },
    onError: () =>
      toast.error(
        <Toaster
          textMsg={t('front.payslip.tdc.intermediateSummary.api.errorMsg')}
          toastType="error"
        />,
      ),
  });
  return { mutate, isLoading, ...others };
};
