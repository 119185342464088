import { Model, Attr, BelongsTo, HasOne } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from '../ApplicationRecord';
import type FactorClientPaymentFile from './FactorClientPaymentFile';
import type ClientPayment from '../ClientPayment';

@Model()
class FactorClientPaymentTransaction extends ApplicationRecord {
  static jsonapiType = 'factor-client_payment_transactions';

  @Attr({ persist: false }) amount!: MoneyType;
  @Attr({ persist: false }) amountEuro!: MoneyType;
  @Attr({ persist: false }) label!: string;
  @Attr({ persist: false }) transaction_type!: string;
  @Attr({ persist: false }) status!: string;
  @Attr({ persist: false }) matchingStatus!: string;
  @Attr({ persist: false }) bookingDate!: string;
  @Attr({ persist: false }) dueDate!: string;
  @Attr({ persist: false }) valueDate!: string;
  @Attr({ persist: false }) issuerIban!: string;
  @Attr({ persist: false }) issuerBic!: string;
  @Attr({ persist: false }) issuerName!: string;
  @Attr({ persist: false }) raw!: string;
  @Attr({ persist: false }) reference!: string;
  @Attr({ persist: false }) clientPaymentFileId!: number;
  @Attr({ persist: false }) matchingCandidatesCount!: number;
  @Attr({ persist: false }) previousPendingTransactionId!: number;
  @Attr({ persist: false }) nextPendingTransactionId!: number;

  @BelongsTo('factor-client_payment_file')
  clientPaymentFile!: FactorClientPaymentFile;
  @HasOne('client_payments') clientPayment!: ClientPayment;

  // Methods
  async fetchRefreshMatching() {
    return this.fetchMemberAction('post', 'refresh_matching');
  }

  get adminShowPagePath(): string | undefined {
    const { id, clientPaymentFileId } = this;
    if (!id || !clientPaymentFileId) {
      return undefined;
    }

    return `/v2/admin/factor_reconciliation/transactions/${id}`;
  }

  get adminAssignationPagePath(): string | undefined {
    const { id, clientPaymentFileId } = this;
    if (!id || !clientPaymentFileId) {
      return undefined;
    }

    return `/v2/admin/factor_reconciliation/transactions/${id}/assignation`;
  }
}

export default FactorClientPaymentTransaction;
