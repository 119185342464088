import { useMutation, useQueryClient } from '@tanstack/react-query';
import PayslipFunnelCraStep from '../../../../app/spraypaint/payslipFunnel/PayslipFunnelCraStep';

export const useAutoFill = (callBack?: () => void) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...others } = useMutation({
    mutationFn: async (payslipFunnelId: number) => {
      const craStep = new PayslipFunnelCraStep({
        id: payslipFunnelId,
      });
      craStep.isPersisted = true;
      craStep.autofill = true;
      await craStep.save();
    },
    onSuccess: () => {
      if (callBack) callBack();
      queryClient.invalidateQueries(['craDatas']);
    },
  });
  return { mutate, isLoading, ...others };
};
