import { useTranslation } from 'react-i18next';
import {
  ButtonOnClickHandler,
  ButtonSecondary,
  HypertextLink,
} from '../../common/components/buttons';
import { ChevronRightIcon } from '../../../app/assets/icons/buttons';
import { useDisplaySignUpBtn } from '../../feature_flag/useDisplaySignUpBtn';

type Props =
  | {
      onClick: ButtonOnClickHandler;
      phone?: string;
      email?: string;
      headerTitle: string;
      buttonTitle: string;
    }
  | {
      onClick?: undefined;
      phone?: string;
      email?: string;
      headerTitle?: undefined;
      buttonTitle?: undefined;
    };

const handleMailer = (mail: string) => {
  const win: Window = window;
  win.location = `mailto:${mail}`;
};

export const AuthFooter = ({
  onClick,
  phone = process.env.PRIUM_PHONE as string,
  email = process.env.PRIUM_EMAIL as string,
  headerTitle,
  buttonTitle,
}: Props) => {
  const { t } = useTranslation();
  const { displaySignUpBtn } = useDisplaySignUpBtn();

  return (
    <div className="flex bg-color-light-grey py-[25px] pl-[111px] h-[146px] justify-between flex-end">
      {displaySignUpBtn && onClick ? (
        <div className="flex flex-col">
          <p className="text-color-pone-dark font-cabin-bold text-base2">
            {headerTitle}
          </p>
          <ButtonSecondary
            Icon={ChevronRightIcon}
            className="mt-[24px]"
            onClick={onClick}
          >
            {buttonTitle}
          </ButtonSecondary>
        </div>
      ) : (
        <div />
      )}
      <div className="self-end border-l-[1px] border-color-medium-grey pl-[10px] mr-[56px] flex-col">
        <p className="text-color-pone-dark text-base2">
          {t('front.auth.signIn.footer.contact.title')}
        </p>
        <p className="text-color-dark-grey text-sm2">{phone}</p>
        <HypertextLink onClick={() => handleMailer(email)}>
          {email}
        </HypertextLink>
      </div>
    </div>
  );
};
