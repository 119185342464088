import { ReactNode } from 'react';
import { LoaderIcon } from '../assets/icons/inputs';

const OverlayLoader = ({
  children,
  loading,
}: {
  children: ReactNode;
  loading: boolean;
}) => (
  <div>
    <div className="relative">
      {loading && (
        <div className=" absolute  flex items-center justify-center w-full h-full z-50">
          <LoaderIcon
            className="animate-spin w-[50px] h-[50px] animate-spin fill-color-pone-orange"
            height={40}
            width={40}
          />
        </div>
      )}
      <div className={loading ? 'blur-sm' : ''}>{children}</div>
    </div>
  </div>
);

export default OverlayLoader;
