import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from './components';
import { Error } from './components/Error';
import useCurrencyCode from '../../hooks/useCurrency';
import { CurrencyIsoCode } from '../../utils/type.utils';

export type NumberInputProps = {
  currencyIsoCode?: CurrencyIsoCode;
  errorText?: FieldError;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  colDisplay?: boolean;
  label?: string;
  value?: number;
  onChange: (value: number) => void;
  intervalMin?: number | undefined;
  intervalMinText?: string;
  intervalMax?: number | undefined;
  intervalMaxText?: string;
  handleSetError?: (value: boolean) => void;
  required?: boolean;
  fullWidth?: boolean;
};

export const NumberInput = ({
  label,
  placeholder,
  className,
  errorText,
  disabled,
  intervalMin,
  intervalMinText,
  intervalMax,
  intervalMaxText,
  currencyIsoCode = 'EUR',
  colDisplay,
  value = 0.0,
  onChange,
  handleSetError,
  required,
  fullWidth = false,
}: NumberInputProps) => {
  const { t } = useTranslation();

  const translatedAmount = (
    amount: number | undefined,
    codeStr: string | undefined,
    localStr: string,
  ) =>
    t('front.formattedCurrencyAmount', {
      amountValue: amount,
      formatParams: {
        amountValue: {
          currency: codeStr,
          locale: localStr,
        },
      },
    });

  const { currencySymbol, currencyLocale } = useCurrencyCode(currencyIsoCode);

  const customClassName = classNames(
    { 'flex flex-row w-96': !colDisplay },
    className,
  );

  const intervalClassName = classNames(
    'mx-2 py-2 text-sm2 text-color-medium-grey min-w-fit',
  );
  const intervalMinClassName = classNames(
    { 'text-right': !colDisplay, 'text-center': colDisplay },
    intervalClassName,
  );
  const intervalMaxClassName = classNames(
    { 'text-left': !colDisplay, 'text-center': colDisplay },
    intervalClassName,
  );

  const inputContainerClassName = classNames(
    'flex flex-row items-center relative',
    {
      'w-full': fullWidth,
    },
  );

  const inputContainerWithErrorClassName = classNames({
    'flex flex-col items-start': !colDisplay,
    'flex flex-col items-center': colDisplay,
  });

  const inputClassName = classNames(
    'flex items-center justify-center',
    'h-[36px] py-2.5 pl-5 pr-1',
    'border-2 border-color-medium-light rounded-[5px]',
    'text-sm1 hover:bg-color-light-grey',
    {
      'border-color-failure': errorText?.message && !disabled,
      'bg-color-light-grey text-color-medium-grey': disabled,
      'no-spin': currencyIsoCode,
      'show-arrow': !currencyIsoCode,
      'w-[130px]': colDisplay && !fullWidth,
      'w-full': fullWidth,
    },
  );

  const rightIconClassName = classNames(
    'relative h-[36px] w-[36px] right-1 bg-color-light-grey rounded-r-[5px] flex justify-center items-center border-color-medium-light border-y-2 border-r-2',
    {
      'border-color-failure': errorText?.message && !disabled,
    },
  );

  const currencyContainerClassName = classNames(
    'flex text-base text-center align-middle relative text-color-medium-grey',
  );

  const intervalsDisplayClass = classNames({
    'flex items-start': !colDisplay,
    'items-center': colDisplay,
  });

  const minText = intervalMinText || 'Min.';
  const maxText = intervalMaxText || 'Max.';
  const [showWarning, setShowWarning] = useState(
    (intervalMin && value < intervalMin) ||
      (intervalMax && value > intervalMax),
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    onChange(newValue);
    if (intervalMin !== undefined && intervalMax) {
      if (newValue >= intervalMin && newValue <= intervalMax) {
        setShowWarning(false);
      } else {
        setShowWarning(true);
      }
    }
  };

  useEffect(() => {
    if (
      (intervalMin && value < intervalMin) ||
      (intervalMax && value > intervalMax)
    ) {
      setShowWarning(true);
      if (handleSetError) handleSetError(true);
    } else if (
      intervalMin &&
      intervalMin <= value &&
      intervalMax &&
      value <= intervalMax
    ) {
      setShowWarning(false);
      if (handleSetError) handleSetError(false);
    }
  }, [intervalMin, intervalMax, value]);

  return (
    <div className={customClassName}>
      {label && <Label required={required}>{label}</Label>}
      <div className={intervalsDisplayClass}>
        {intervalMin !== undefined && (
          <div className={intervalMinClassName}>
            <p data-testid="interval-min">
              {minText}{' '}
              {translatedAmount(intervalMin, currencyIsoCode, currencyLocale)}
            </p>
          </div>
        )}
        <div className={inputContainerWithErrorClassName}>
          <div className={inputContainerClassName}>
            <input
              type="number"
              data-testid="input-number-test-id"
              placeholder={placeholder}
              disabled={disabled}
              className={inputClassName}
              value={value}
              onChange={handleChange}
            />
            {currencyIsoCode && (
              <div className={rightIconClassName}>
                <div className={currencyContainerClassName}>
                  {currencySymbol}
                </div>
              </div>
            )}
          </div>
          {(errorText?.message || showWarning) && !disabled && (
            <Error>
              {errorText?.message ||
                t('front.inputNumber.intervalError', {
                  min: translatedAmount(
                    intervalMin,
                    currencyIsoCode,
                    currencyLocale,
                  ),
                  max: translatedAmount(
                    intervalMax,
                    currencyIsoCode,
                    currencyLocale,
                  ),
                })}
            </Error>
          )}
        </div>
        {intervalMax !== undefined && (
          <div className={intervalMaxClassName}>
            <p data-testid="interval-max">
              {maxText}{' '}
              {translatedAmount(intervalMax, currencyIsoCode, currencyLocale)}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
