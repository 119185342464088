import { Cell, CellContext } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import EmployeePayslip from '@spraypaint/EmployeePayslip';
import { useTranslation } from 'react-i18next';
import { TooltipComponent } from '@/modules/common/components/fragments/TooltipComponent';
import dayjs from 'dayjs';

export const displayDateMonthYear = (date: string) => {
  if (dayjs(date).isValid()) return dayjs(date).format('DD/MM');
  return '-';
};

const TooltipCell = ({
  cell,
  autoAction,
  actionBy,
  action,
}: {
  cell: Cell<ModelRecord<EmployeePayslip>, unknown>;
  autoAction: boolean | undefined;
  actionBy: string | undefined;
  action: string;
}) => {
  const { t } = useTranslation();
  const { id } = cell.row.original;
  const tooltipId = `${action}_tooltip_${id}`;
  const showTooltip = actionBy !== null || autoAction;

  return (
    <div className="flex justify-center z-50">
      <p className="text-center">
        {displayDateMonthYear(cell.getValue() as string)}
      </p>

      {showTooltip && (
        <TooltipComponent
          tooltipId={tooltipId}
          iconClassName="ml-[10px]"
          key={`key-${tooltipId}`}
          tooltipMsgComp={
            <div className="flex flex-col text-center">
              {autoAction ? (
                <p>{t('front.employeePayslip.dashboard.tooltipMsg.auto')}</p>
              ) : (
                <p>
                  <span>
                    {t(`front.employeePayslip.dashboard.tooltipMsg.${action}`, {
                      name: actionBy,
                    })}
                  </span>
                </p>
              )}
            </div>
          }
        />
      )}
    </div>
  );
};

export const AutoCreatedTooltip = ({
  cell,
}: CellContext<ModelRecord<EmployeePayslip>, unknown>) => {
  const { autocreated, createdBy } = cell.row.original;
  return (
    <TooltipCell
      cell={cell}
      autoAction={autocreated}
      actionBy={createdBy}
      action="creation"
    />
  );
};

export const AutoSubmittedTooltip = ({
  cell,
}: CellContext<ModelRecord<EmployeePayslip>, unknown>) => {
  const { autosubmitted, submittedBy } = cell.row.original;
  return (
    <TooltipCell
      cell={cell as Cell<ModelRecord<EmployeePayslip>, unknown>}
      autoAction={autosubmitted}
      actionBy={submittedBy}
      action="submission"
    />
  );
};

export const AutoValidatedTooltip = ({
  cell,
}: CellContext<ModelRecord<EmployeePayslip>, unknown>) => {
  const { autovalidated, validatedBy } = cell.row.original;
  return (
    <TooltipCell
      cell={cell as Cell<ModelRecord<EmployeePayslip>, unknown>}
      autoAction={autovalidated}
      actionBy={validatedBy}
      action="validation"
    />
  );
};
