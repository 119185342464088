import { useTranslation } from 'react-i18next';
import Table from '../../../../common/components/table/Table';
import { RevenueDetailsColumns } from '../../table/payslipDetailsColumns';

export type RevenuesDetailsTableProps = {
  invoicesStep: any;
  isLoading: boolean;
};

export const RevenuesDetailsTable = ({
  isLoading,
  invoicesStep,
}: RevenuesDetailsTableProps) => {
  const { t } = useTranslation();
  const revenueColumns = RevenueDetailsColumns();

  return (
    <Table
      emptyTableErrorMsg="front.payslip.tdc.details.emptyTable"
      withImageErrorMsg={false}
      title={t('front.payslip.tdc.invoice.table.revenue.title') as string}
      disabledPagination
      isCollapsed={false}
      isLoading={isLoading}
      data={invoicesStep?.incomes || []}
      columns={revenueColumns}
    />
  );
};
