import { useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export const useToggleTutorial = () => {
  const [isTutorialOn, setTutorialStatus] = useLocalStorage(
    'tutorialStatus',
    true,
  );

  const toggleAllTutorial = (value?: boolean) => () => {
    setTutorialStatus((prev: boolean) => value ?? !prev);
  };

  return {
    isTutorialOn,
    toggleAllTutorial,
  };
};

export const useToggleMiniTutorial = () => {
  const { isTutorialOn } = useToggleTutorial();
  const [isMiniTutorialOn, setTutorialStatus] = useState(isTutorialOn);

  const toggleMiniTutorial = () => {
    setTutorialStatus((prevValue: boolean) => !prevValue);
  };
  return { isMiniTutorialOn, toggleMiniTutorial };
};
