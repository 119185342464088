import { Invoice } from '@/app/spraypaint';
import { GraphitiScoper } from '@/app/spraypaint/types';
import { useQuery } from '@tanstack/react-query';

type InvoiceArgsType = {
  scope: GraphitiScoper<Invoice>;
  scopeKey: Array<string>;
};

export const usePayableInvoices = (
  legalEntityId: string,
  { scope, scopeKey }: InvoiceArgsType,
) => {
  const data = useQuery({
    queryKey: ['get-payable-invoices', legalEntityId, scopeKey],
    queryFn: () =>
      scope(Invoice.includes(['client']).scope())
        .where({
          legal_entity_id: legalEntityId,
          status: ['validated', 'partially_paid'],
        })
        .all(),
    refetchOnWindowFocus: false,
    enabled: !!legalEntityId,
    cacheTime: 0,
  });
  const totalCount = data?.data?.meta?.stats?.total?.count;
  const invoices = data?.data?.data;

  return { invoices, totalCount, ...data };
};
