import { useTranslation } from 'react-i18next';
import { LoaderIcon } from '../../../app/assets/icons/inputs';
import { NotificationsMessage } from '../../../app/spraypaint';
import { NotificationLine } from './NotificationLine';

type Props = {
  unreadNotificationsLoader: boolean;
  notificationsLoader: boolean;
  notifications: Array<NotificationsMessage>;
  unreadNotifications: Array<NotificationsMessage>;
  isFetchingAllNotificationNextPage: boolean;
  showAllNotifications: boolean;
  isFetchingAllUnreadNotificationNextPage: boolean;
};

export const NotificationList = ({
  unreadNotificationsLoader,
  notifications,
  notificationsLoader,
  isFetchingAllNotificationNextPage,
  showAllNotifications,
  isFetchingAllUnreadNotificationNextPage,
  unreadNotifications,
}: Props) => {
  const { t } = useTranslation();

  if (
    (unreadNotificationsLoader || notificationsLoader) &&
    !isFetchingAllNotificationNextPage
  ) {
    return (
      <div className="flex h-full justify-center items-center">
        <LoaderIcon
          data-testid="loader-icon"
          className="w-[77px] h-[80px] animate-spin fill-color-pone-orange"
        />
      </div>
    );
  }

  if (notifications?.length === 0 || !notifications) {
    return (
      <div className="mt-[20px] text-center">
        <p>{t('front.notifications.dropdown.list.emptyNotifications')}</p>
      </div>
    );
  }

  if (
    !showAllNotifications &&
    (unreadNotifications?.length === 0 || !unreadNotifications)
  ) {
    return (
      <div className="mt-[20px] text-center">
        <p>{t('front.notifications.dropdown.list.emptyUnreadNotifications')}</p>
      </div>
    );
  }

  if (showAllNotifications) {
    return (
      <div className="mt-[20px]">
        {notifications.map((notification: NotificationsMessage) => (
          <NotificationLine key={notification.id} notification={notification} />
        ))}
        {isFetchingAllNotificationNextPage && (
          <div className="h-[60px] w-full flex justify-center items-center">
            <LoaderIcon
              className="animate-spin fill-color-pone-orange"
              height={60}
              width={60}
            />
          </div>
        )}
      </div>
    );
  }

  if (!showAllNotifications) {
    return (
      <div className="mt-[20px]">
        {unreadNotifications.map((notification: NotificationsMessage) => (
          <NotificationLine key={notification.id} notification={notification} />
        ))}
        {isFetchingAllUnreadNotificationNextPage && (
          <div className="h-[60px] w-full flex justify-center items-center">
            <LoaderIcon
              className="animate-spin fill-color-pone-orange"
              height={60}
              width={60}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};
