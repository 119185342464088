import { FileType } from '../../../utils/type.utils';

export const handleDownloadFile = ({
  event,
  file,
}: {
  file: FileType;
  event: React.MouseEvent<HTMLElement>;
}) => {
  const a = document.createElement('a');
  a.href = file.url;
  a.download = file.name;
  a.target = '_blank';
  a.click();
  event.stopPropagation();
};
