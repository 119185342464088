import { FormState } from '@/modules/invoices/types';
import { InvoiceDraft } from '@/app/spraypaint';

export function handleSubmit(data: FormState) {
  const invoice = new InvoiceDraft({
    ...data,
  });

  console.log({ data, invoice });

  return invoice.save();
}
