import {
  ButtonPrimary,
  ButtonSecondary,
} from '@/modules/common/components/buttons';
import { Toaster } from '@/modules/common/components/toasts';
import { toast } from 'react-toastify';
import AccountingJournalExport from '@spraypaint/accounting/AccountingJournalExport';
import { LegalEntity, SepaFile } from '@/app/spraypaint';
import useLegalEntities from '@/modules/common/legalEntity/useLegalEntities';
import { Link } from 'react-router-dom';
import { ImportedInput } from '@/modules/common/components/inputs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PersistedSpraypaintRecord } from 'spraypaint';
import { useAccountingJournalExportsByIds } from '../hooks/useAccountingJournalExports';

const ImportedToggle = ({
  journalExport,
  callback,
}: {
  journalExport: PersistedSpraypaintRecord<AccountingJournalExport>;
  callback: () => void;
}) => {
  const { mutate } = useMutation({
    mutationKey: ['accountingJournalExport', journalExport?.id],
    mutationFn: async (newState: boolean) => {
      if (!journalExport) return undefined;
      const exp = journalExport.dup();
      exp.imported = newState;
      return exp.saveOrThrow();
    },
    onSuccess: () => {
      callback();
    },
  });

  return (
    <ImportedInput
      record={journalExport}
      onToggle={(newState) => mutate(newState)}
    />
  );
};

const AccountingJournalExportFromSepaFile = ({
  sepaFile,
}: {
  sepaFile: SepaFile;
}) => {
  const queryClient = useQueryClient();
  const { legalEntities } = useLegalEntities();
  const lastJournalExportIds = sepaFile.lastExportIds;
  const { accountingJournalExports } = useAccountingJournalExportsByIds({
    ids: lastJournalExportIds.length > 0 ? lastJournalExportIds : [0],
  });

  const createAccountingJournalExport = async (legalEntityId: string) => {
    const journalExport = new AccountingJournalExport();
    journalExport.legalEntityId = legalEntityId;
    journalExport.sepaFileId = sepaFile.id;
    journalExport.exportType = 'bank_statements';

    const success = await journalExport.save();
    if (success) {
      toast.success(<Toaster textMsg="Export créé" toastType="success" />);
      window
        .open(
          `/v2/admin/accounting_journal_exports/${journalExport.id}`,
          '_blank',
        )
        ?.focus();
    } else {
      toast.error(<Toaster textMsg="Échec" toastType="error" />);
    }
  };

  const displayExportButtons = (
    legalEntity: LegalEntity,
    journalExports: PersistedSpraypaintRecord<AccountingJournalExport>[],
  ) => {
    const journalExport = journalExports.find(
      (journalExp) =>
        (journalExp.legalEntityId || '').toString() === legalEntity.id,
    );

    if (journalExport) {
      return (
        <div className="flex mr-[10px] flex-col">
          <Link
            to={`/v2/admin/accounting_journal_exports/${journalExport.id}`}
            className="mb-[10px]"
          >
            <ButtonPrimary>{legalEntity.name}</ButtonPrimary>
          </Link>
          <ButtonSecondary
            className=""
            onClick={() => createAccountingJournalExport(legalEntity.id || '')}
          >
            Actualiser
          </ButtonSecondary>
          <ImportedToggle
            journalExport={
              journalExport as PersistedSpraypaintRecord<AccountingJournalExport>
            }
            callback={() =>
              queryClient.invalidateQueries([
                'accounting-journal-exports-ids',
                lastJournalExportIds,
              ])
            }
            key={journalExport.id}
          />
        </div>
      );
    }

    return (
      <ButtonPrimary
        className="mr-[10px]"
        onClick={() => createAccountingJournalExport(legalEntity.id || '')}
      >
        {legalEntity.name}
      </ButtonPrimary>
    );
  };

  return accountingJournalExports ? (
    <div className="flex">
      {legalEntities.map(
        (e) => e && displayExportButtons(e, accountingJournalExports),
      )}
    </div>
  ) : (
    <div />
  );
};

export default AccountingJournalExportFromSepaFile;
