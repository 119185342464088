import classNames from 'classnames';
import { Button, ButtonProps } from './Button';
import { CloseIcon } from '../../../../app/assets/icons/buttons';
import { LoaderIcon } from '../../../../app/assets/icons/inputs';
import { IconType } from '../../utils/type.utils';

export type ButtonRejectProps = {
  Icon?: IconType;
  textStyle?: string;
  className?: string;
  iconClassName?: string;
} & ButtonProps;

export function ButtonReject({
  children,
  className,
  iconClassName,
  disabled,
  textStyle,
  onClick,
  Icon = CloseIcon,
  isLoading,
}: ButtonRejectProps) {
  const customClassName = classNames(
    'flex items-center justify-center pt-2.5 pb-2.5 w-[130px]',
    {
      'bg-color-failure hover:bg-color-txt-failure hover:border-color-txt-failure hover:text-white':
        !disabled,
      'bg-color-disabled': disabled,
    },
    {
      'text-white': !disabled,
      'text-color-disabled-text': disabled,
    },
    {
      'fill-white': !disabled,
      'fill-color-disabled-text': disabled,
    },
    className,
  );
  const customIconClassName = classNames('mr-O.5', iconClassName);
  const customTextStyle = classNames('text-sm uppercase', textStyle);

  return (
    <Button onClick={onClick} className={customClassName} disabled={disabled}>
      {isLoading ? (
        <LoaderIcon
          className="animate-spin fill-white"
          height={20}
          width={20}
        />
      ) : (
        <>
          <Icon className={customIconClassName} height={24} width={24} />
          <p className={customTextStyle}>{children}</p>
        </>
      )}
    </Button>
  );
}
