import { useTranslation } from 'react-i18next';
import { PayslipDetailCard, AmountEntry } from '../../components';

export type FinalSummaryStepDetailsProps = {
  finalSummaryStep: any;
};

export const FinalSummaryStepDetails = ({
  finalSummaryStep,
}: FinalSummaryStepDetailsProps) => {
  const { t } = useTranslation();

  return (
    <PayslipDetailCard
      user={finalSummaryStep?.completed_by_name}
      date={finalSummaryStep?.completed_at}
      title={t('front.payslip.details.salaryChoice.proposedSalary')}
    >
      <div>
        <div className="flex">
          <AmountEntry
            label={
              t('front.payslip.details.salaryChoice.grossSalary') as string
            }
            value={{
              amount: finalSummaryStep?.gross_salary,
              currency: 'EUR',
            }}
          />
          <AmountEntry
            label={
              t(
                'front.payslip.details.salaryChoice.netSalaryBeforeTaxes',
              ) as string
            }
            value={{
              amount: finalSummaryStep?.net_before_tax,
              currency: 'EUR',
            }}
          />
          <AmountEntry
            label={
              t(
                'front.payslip.details.salaryChoice.employeeContribution',
              ) as string
            }
            value={{
              amount: finalSummaryStep?.employee_contribution,
              currency: 'EUR',
            }}
          />
        </div>
        <div className="flex">
          <AmountEntry
            label={
              t(
                'front.payslip.details.salaryChoice.employerContribution',
              ) as string
            }
            value={{
              amount: finalSummaryStep?.employer_contribution,
              currency: 'EUR',
            }}
          />
          <AmountEntry
            label={t('front.payslip.details.salaryChoice.realTaxes') as string}
            value={{
              amount: finalSummaryStep?.real_taxes,
              currency: 'EUR',
            }}
          />
          <AmountEntry
            label={t('front.payslip.details.salaryChoice.leftToPay') as string}
            value={{
              amount: finalSummaryStep?.left_to_pay,
              currency: 'EUR',
            }}
          />
        </div>
      </div>
    </PayslipDetailCard>
  );
};
