import { useMutation } from '@tanstack/react-query';
import { FactorTransactionAssignation } from '../../../../../app/spraypaint';

export type PayloadType = {
  associableIds: Array<number>;
  associableType: string;
  transactionId: number;
  transactionType: string;
};

export const useValidateMatching = () => {
  const data = useMutation({
    mutationFn: async (payload: PayloadType) => {
      const FactorTransactionFile = new FactorTransactionAssignation(payload);
      const result = await FactorTransactionFile.save();
      if (!result) {
        const { errors } = FactorTransactionFile;
        return errors;
      }
      return result;
    },
  });
  return { ...data };
};
