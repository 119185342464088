import { useQuery } from '@tanstack/react-query';
import Payslip from '../../../app/spraypaint/Payslip';

export const usePayslipComputationDetails = (id: string) => {
  const data = useQuery({
    queryKey: ['payslip-computation-details', id],
    queryFn: () => Payslip.selectExtra(['computationDetails']).find(id),
    retry: 0,
    refetchOnWindowFocus: false,
  });
  const payslip = data?.data?.data;
  const payslipJson = payslip?.computationDetails ?? '';

  return {
    payslipJson,
    ...data,
  };
};
