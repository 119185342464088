// api/Sepa::TransactionAssignation.ts
import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class SepaTransactionAssignation extends ApplicationRecord {
  static jsonapiType = 'sepa-transaction_assignations';

  // Attributes
  @Attr() sepaTransactionId!: number;
  @Attr() associableIds!: Array<number>;
  @Attr() associableType!: string;

  // Relationships
}

export default SepaTransactionAssignation;
