import { useTranslation } from 'react-i18next';
import Table from '../../../common/components/table/Table';
import { MainWebInvoicesStepType } from '../types/MainInvoicesStepType';

export const MainWebInvoicesStep = ({
  warningAmountsCard,
  invoiceCardBlock,
  currentDisplayCa,
  displayCurrentTotal,
  revenueSummaryTableData,
  incomeSummaryTableData,
  revenueSummaryLoader,
  revenueSummaryProps,
  caColumns,
  tableIncomeLoading,
  incomeProps,
  revenueColumns,
}: MainWebInvoicesStepType) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between items-center ml-[30px] mt-[20px]">
        <div className="flex flex-row">{invoiceCardBlock}</div>
        {warningAmountsCard}
      </div>

      {currentDisplayCa ? (
        <Table
          emptyTableErrorMsg="front.payslip.tdc.invoice.table.ca.emptyMsgError"
          className="mt-[32px]"
          title={t('front.payslip.tdc.invoice.table.ca.title') as string}
          tableInformations={
            <span>
              {t('front.payslip.tdc.invoice.table.ca.tableInformation')}
              <span className="text-color-pone-orange font-cabin-bold">
                {displayCurrentTotal()}
              </span>
            </span>
          }
          totalCount={
            currentDisplayCa
              ? revenueSummaryTableData?.meta.stats.total.count
              : incomeSummaryTableData?.meta.stats.total.count
          }
          isCollapsed={false}
          isLoading={revenueSummaryLoader}
          data={revenueSummaryTableData?.data || []}
          reactTableProps={revenueSummaryProps}
          columns={caColumns}
        />
      ) : (
        <Table
          emptyTableErrorMsg="front.payslip.tdc.invoice.table.revenue.emptyMsgError"
          className="mt-[32px]"
          title={t('front.payslip.tdc.invoice.table.revenue.title') as string}
          tableInformations={
            <span>
              {t('front.payslip.tdc.invoice.table.revenue.tableInformation')}
              <span className="text-color-pone-orange font-cabin-bold">
                {displayCurrentTotal()}
              </span>
            </span>
          }
          totalCount={incomeSummaryTableData?.meta.stats.total.count}
          isCollapsed={false}
          isLoading={tableIncomeLoading}
          data={incomeSummaryTableData?.data || []}
          reactTableProps={incomeProps}
          columns={revenueColumns}
        />
      )}
    </>
  );
};
