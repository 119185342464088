import { useParams } from 'react-router-dom';
import { LineText } from '@/modules/common/components/fragments/LineText';
import { MainCard } from '@/modules/common/components/mainCard';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { ImportedInput } from '@/modules/common/components/inputs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAccountingJournalExport from '../hooks/useAccountingJournalExport';

const AccountingJournalExportShow = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { accountingJournalExport, isLoading } = useAccountingJournalExport(
    String(id),
  );
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationKey: ['accountingJournalExport', accountingJournalExport?.id],
    mutationFn: async (newState: boolean) => {
      if (!accountingJournalExport) return undefined;
      const exp = accountingJournalExport.dup();
      exp.imported = newState;
      return exp.saveOrThrow();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['accounting-journal-export', id]);
    },
  });

  return (
    <PageLayout
      Title={`${t('front.menu.accounting_journal_exports')} - n°
    ${accountingJournalExport?.id}`}
    >
      <div className="flex">
        <MainCard isLoading={isLoading} className="mt-[20px] mx-5 md:w-[50%]">
          <LineText
            label={t('front.accounting_journal_exports.assignation_csv')}
            value={
              accountingJournalExport?.assignationCsvUrl && (
                <ButtonPrimary
                  href={accountingJournalExport?.assignationCsvUrl}
                  download
                >
                  {t('default.button.download')}
                </ButtonPrimary>
              )
            }
          />
          <LineText
            label={t('activerecord.attributes.accounting/journal_export.date', {
              ns: 'rails',
            })}
            value={accountingJournalExport?.date}
          />
          <LineText
            label={t(
              'activerecord.attributes.accounting/journal_export.export_type',
              {
                ns: 'rails',
              },
            )}
            value={t(
              `activerecord.enums.accounting/journal_export.export_type.${accountingJournalExport?.exportType}`,
              {
                ns: 'rails',
              },
            )}
          />
          <LineText
            label={t(
              'activerecord.attributes.accounting/journal_export.legal_entity',
              {
                ns: 'rails',
              },
            )}
            value={accountingJournalExport?.legalEntity?.name}
          />
          <LineText
            label={t(
              'front.accounting_journal_exports.assignation_csv_lines_count',
            )}
            value={accountingJournalExport?.assignationCsvLinesCount}
          />
          {accountingJournalExport?.sepaFile && (
            <LineText
              label={t('front.accounting_journal_exports.sepa_file')}
              value={
                <a
                  href={
                    accountingJournalExport.sepaFile
                      .adminBankTransferShowPagePath
                  }
                >
                  {accountingJournalExport.sepaFile.filename}
                </a>
              }
            />
          )}
          <LineText
            label={t(
              'activerecord.attributes.accounting/journal_export.created_at',
              {
                ns: 'rails',
              },
            )}
            value={accountingJournalExport?.createdAt}
          />
          <LineText
            label={t(
              'activerecord.attributes.accounting/journal_export.generated_at',
              {
                ns: 'rails',
              },
            )}
            value={accountingJournalExport?.generatedAt}
          />
          <LineText
            label={t(
              'activerecord.attributes.accounting/journal_export.imported',
              {
                ns: 'rails',
              },
            )}
            value={
              <ImportedInput
                record={accountingJournalExport}
                onToggle={mutate}
              />
            }
          />
        </MainCard>
      </div>
    </PageLayout>
  );
};

export default AccountingJournalExportShow;
