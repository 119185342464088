import type { FunctionComponent } from 'react';
import { CheckBox, TextInput } from '@common/components/inputs';
import { ButtonPrimary } from '@common/components/buttons';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useCreateAccount } from '@/modules/authentification/hooks/useCreateAccount';
import { useValidation } from '@/app/form/ValidationForm';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { FilledEmailForm } from '@/modules/authentification/components/FilledEmailForm';

export interface RegisterFormProps {
  email: string;
  navigateToEmailForm: () => void;
}

export type RegisterFormValue = {
  email: string;
  first_name: string;
  last_name: string;
  cgu_agreement: boolean;
  newsletter: boolean;
};

export const RegisterForm: FunctionComponent<RegisterFormProps> = ({
  email,
  navigateToEmailForm,
}) => {
  const { t } = useTranslation();
  const Validation = useValidation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<RegisterFormValue>({
    defaultValues: { email, cgu_agreement: false, newsletter: false },
  });

  const { mutateAsync } = useCreateAccount({
    onSuccess: (_data, variables) => {
      navigate({
        pathname: '../verify_account',
        search: createSearchParams({ email: variables.login }).toString(),
      });
    },
  });

  const onSubmit = async (data: RegisterFormValue) => {
    await mutateAsync({
      last_name: data.last_name,
      first_name: data.first_name,
      login: data.email,
      cgu_agreement: data.cgu_agreement,
      newsletter: data.newsletter,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center md:items-start">
        <FilledEmailForm
          email={email}
          onGoBack={navigateToEmailForm}
          errorText={errors?.email}
        />
        <TextInput
          testID="input-first_name"
          label={t('front.auth.signIn.form.first_name.label') as string}
          placeholder={
            t('front.auth.signIn.form.first_name.placeholder') as string
          }
          className="mt-[33px]"
          errorText={errors?.first_name}
          required
          {...register('first_name', Validation.anyRequired)}
        />
        <TextInput
          testID="input-last_name"
          label={t('front.auth.signIn.form.last_name.label') as string}
          placeholder={
            t('front.auth.signIn.form.last_name.placeholder') as string
          }
          className="mt-[33px]"
          errorText={errors?.last_name}
          required
          {...register('last_name', Validation.anyRequired)}
        />
        <CheckBox
          className="mt-[33px]"
          label={t('front.auth.signIn.form.cgu.label') as string}
          data-testid="input-cgu_agreement"
          {...register('cgu_agreement', {
            required: {
              value: true,
              message: t('front.auth.signIn.form.cgu.required'),
            },
          })}
          errorText={errors?.cgu_agreement}
        />
        <CheckBox
          className="mt-[33px]"
          label={t('front.auth.signUp.form.newsletter.label') as string}
          data-testid="input-newsletter"
          {...register('newsletter')}
        />
        <div className="flex items-center justify-center md:justify-start mt-[33px]">
          <ButtonPrimary
            testID="login-button-submit"
            isLoading={isSubmitting}
            type="submit"
          >
            {t('front.auth.signIn.button.register.title')}
          </ButtonPrimary>
        </div>
      </div>
    </form>
  );
};
