import { RoutesMounter } from '@/types';
import { index, member } from '@/app/navigation/functions';
import AuthorizationsIndex from './pages/AuthorizationsIndex';

export const mountFactorAuthorizationRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { t },
}) => [
  member({
    path,
    handle: { crumb: () => t('front.factor.authorization.index.title') },
    children: [index({ index: true, Component: AuthorizationsIndex })],
  }),
];
