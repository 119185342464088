import classNames from 'classnames';
import dayjs from 'dayjs';

import { NotificationsMessage } from '../../../app/spraypaint';
import { Button } from '../../common/components/buttons/Button';
import { useReadNotification } from '../hooks/useReadNotification';
import { getIconCategory } from '../utils/mapCategoryToIcon';

export const NotificationLine = ({
  notification,
  className,
}: {
  notification: NotificationsMessage;
  className?: string;
}) => {
  const { createdAt, content, status } = notification;
  const { mutate } = useReadNotification();
  const containerClassName = classNames(
    'hover:bg-color-light-grey flex items-center text-left py-[5px] border-b-[1px] border-b-color-medium-grey hover:cursor-pointer',
    {
      'bg-color-light-grey text-color-medium-grey fill-color-medium-grey':
        status === 'read',
      'bg-color-transparent-light-pone-orange':
        notification.important && status !== 'read',
    },
    className,
  );

  const handleReadNotification = () => {
    if (notification.status === 'not_read') mutate(notification?.id as string);
  };
  return (
    <Button
      onClick={handleReadNotification}
      href={notification.webUrl}
      custom
      className={containerClassName}
    >
      <div className="mx-[8px]">
        {status === 'not_read' && (
          <div className="rounded-full h-[8px] w-[8px] bg-color-pone-orange" />
        )}
      </div>
      <div className="mr-[8px]">{getIconCategory(notification.category)}</div>
      <div>
        <p className="w-full pr-[15px]">
          <span className="line-clamp-3 hover:line-clamp-none">{content}</span>
          {notification.category}
        </p>
        <p className="text-color-medium-grey text-sm2">
          {dayjs().to(createdAt)}
        </p>
      </div>
    </Button>
  );
};
