import type { FunctionComponent, ReactElement } from 'react';
import { noDefault } from '@/app/utils/typescriptUtils';
import Skeleton from 'react-loading-skeleton';

export type Step = {
  // eslint-disable-next-line react/no-unused-prop-types
  key: string;
  Icon: FunctionComponent<{ className: string }>;
  title: ReactElement | string;
  description: ReactElement | string;
  status: 'done' | 'current' | 'upcoming';
};

export function buildSkeletonStep(key: string): Step {
  return {
    key,
    title: <Skeleton count={1} />,
    description: <Skeleton count={1} />,
    Icon: () => null,
    status: 'upcoming',
  };
}

function StepItem({ Icon, title, description, status }: Step) {
  let style = {
    icon: '',
    text: '',
  };
  switch (status) {
    case 'done':
      style = { icon: 'bg-green-200 text-green-500', text: '' };
      break;
    case 'current':
      style = { icon: 'bg-blue-200 text-blue-600', text: 'text-blue-600' };
      break;
    case 'upcoming':
      style = { icon: 'bg-gray-200 text-gray-500', text: '' };
      break;
    default:
      noDefault(status);
  }

  const iconStyle = `absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white ${style.icon}`;

  return (
    <li className="mb-10 ml-6">
      <span className={iconStyle}>
        <Icon className="w-3.5 h-3.5" />
      </span>
      <h3 className={`font-medium leading-tight ${style.text}`}>{title}</h3>
      <p className={`text-sm ${style.text}`}>{description}</p>
    </li>
  );
}

export interface TimelineStepperProps {
  steps: Step[];
}

export const TimelineStepper: FunctionComponent<TimelineStepperProps> = ({
  steps,
}) => (
  <ol className="ml-5 relative text-gray-500 border-solid border-gray-200 border-l">
    {steps.map((step) => (
      <StepItem {...step} key={step.key} />
    ))}
  </ol>
);
