import classNames from 'classnames';

import { Button } from '../common/components/buttons';

type WidgetComponentProps = {
  children: string;
  className?: string;
  spanClassName?: string;
  Icon: any;
  onClick: any;
};

export const WidgetComponent = ({
  children,
  Icon,
  spanClassName,
  className,
  onClick,
}: WidgetComponentProps) => {
  const containerClassName = classNames(
    'flex items-center bg-color-light-grey py-[11px] pl-[13px] pr-[22px] rounded-full',
    className,
  );
  const spanCustomClassName = classNames(
    'flex justify-center items-center mr-[9px] h-[24px] w-[24px] rounded-full',
    spanClassName,
  );

  return (
    <Button custom className={containerClassName} onClick={onClick}>
      <>
        <span className={spanCustomClassName}>{Icon}</span>
        <p className="text-sm2 font-cabin-italic">{children}</p>
      </>
    </Button>
  );
};
