// api/Office.ts
import { Model, Attr, HasMany } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import User from './User';

@Model()
class Office extends ApplicationRecord {
  static jsonapiType = 'offices';

  // Attributes
  @Attr({ persist: false }) name!: string;
  @Attr({ persist: false }) addressNumber!: string;
  @Attr({ persist: false }) addressRoad!: string;
  @Attr({ persist: false }) addressCity!: string;
  @Attr({ persist: false }) addressCountry!: string;
  @Attr({ persist: false }) addressCountryCode!: string;
  @Attr({ persist: false }) addressZipcode!: string;
  @Attr({ persist: false }) openingDays!: string;

  // Relationships
  @HasMany(User) users!: User[];
}

export default Office;
