import { ReactNode } from 'react';
import { formatNumber } from '../../common/utils/maths.utils';

export type CraRecapCardSectionProps = {
  label: string;
  value: number | ReactNode;
  subValue?: number;
};

export const CraRecapCardSection = ({
  label,
  value,
  subValue,
}: CraRecapCardSectionProps) => (
  <div className="flex flex-col md:w-1/6 ml-[20px] md:ml-[0px]">
    <p className="h-1/2 items-center flex justify-center font-cabin-medium text-color-medium-grey">
      {label}
    </p>
    <div className="h-1/2 md:bg-color-transparent-medium-grey items-center flex justify-center">
      {typeof value === 'number' ? (
        <>
          <p className="inline-block md:text-base2 text-color-dark-content m-1">
            {formatNumber(value)}
          </p>
          {subValue !== undefined ? (
            <p className="inline-block md:text-base2 text-color-medium-grey">
              ({subValue}h)
            </p>
          ) : null}
        </>
      ) : (
        value
      )}
    </div>
  </div>
);
