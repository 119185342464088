import type { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormWizardButtons,
  useFormWizardContext,
} from '@/modules/common/components/wizard';
import { invoiceDraftWizardContext } from '../../constants';

export const ConfigForm: FunctionComponent = () => {
  const { onSubmit } = useFormWizardContext(invoiceDraftWizardContext);

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWizardButtons wizardContext={invoiceDraftWizardContext} />
    </form>
  );
};
