import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useValidation } from '../../../app/form/ValidationForm';
import { ButtonPrimary } from '../../common/components/buttons';
import { PasswordInput } from '../../common/components/inputs';
import { AuthHeader, AuthTitle, AuthFooter } from '../components';
import { AuthMainContainer } from '../components/cards';
import { EditPasswordSuccess } from '../components/forgotPassword/EditPasswordSuccess';
import { useResetPassword } from '../hooks';

type FormValue = {
  password: string;
  passwordRepeat: string;
};

type ErrorResponse = {
  field_error?: Array<string>;
  error?: string;
};

export const PasswordEdit = () => {
  const { t } = useTranslation();
  const { key } = useParams();
  const Validation = useValidation();
  const {
    handleSubmit,
    formState: { errors },
    setError,
    register,
  } = useForm<FormValue>();
  const onError = (error: AxiosError<ErrorResponse>) => {
    setError('passwordRepeat', {
      type: 'custom',
      message: error.response?.data?.error,
    });
  };

  const { mutate, isLoading, isSuccess } = useResetPassword({ onError });
  const onSubmit = (data: FormValue) =>
    mutate({
      password: data.password,
      password_confirm: data.passwordRepeat,
      key: key as string,
    });

  return (
    <div className="h-screen w-full flex flex-col justify-between">
      <div>
        <AuthHeader />
        {isSuccess ? (
          <EditPasswordSuccess />
        ) : (
          <AuthMainContainer>
            <AuthTitle>{t('front.auth.passwordEdit.header')}</AuthTitle>
            <p className="text-base2 mt-[34px]">
              {t('front.auth.passwordEdit.form.title')}
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <PasswordInput
                {...register('password', Validation.password)}
                label={
                  t('front.auth.passwordEdit.form.password.label') as string
                }
                placeholder={
                  t(
                    'front.auth.passwordEdit.form.password.placeholder',
                  ) as string
                }
                className="mt-[33px]"
                errorText={errors?.password}
              />
              <PasswordInput
                {...register('passwordRepeat', Validation.password)}
                label={
                  t(
                    'front.auth.passwordEdit.form.passwordRepeat.label',
                  ) as string
                }
                placeholder={
                  t(
                    'front.auth.passwordEdit.form.passwordRepeat.placeholder',
                  ) as string
                }
                className="mt-[33px]"
                errorText={errors?.passwordRepeat}
              />
              <ButtonPrimary
                isLoading={isLoading}
                className="mt-[51px]"
                type="submit"
              >
                {t('front.auth.passwordEdit.form.submit')}
              </ButtonPrimary>
            </form>
          </AuthMainContainer>
        )}
      </div>
      <AuthFooter />
    </div>
  );
};
