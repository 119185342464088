import { Attr, BelongsTo, Model } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from '../ApplicationRecord';
import type FactorTransfer from './FactorTransfer';
import Invoice from '../Invoice';
import Credit from '../Credit';

@Model()
class FactorTransferItem extends ApplicationRecord {
  static jsonapiType = 'factor-transfer_items';

  @Attr() transferableType!: string;
  @Attr() transferableId!: string;
  @Attr({ persist: false }) remainingAmount!: MoneyType;

  @BelongsTo('factor-transfers') factorTransfer!: FactorTransfer;
  @BelongsTo('transferable') transferable!: Invoice | Credit;
}

export default FactorTransferItem;
