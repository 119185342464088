import { useTranslation } from 'react-i18next';
import { SummaryTutorialIllustration } from '../../../../app/assets/icons/tutorial';
import { Line } from '../../../common/components/fragments';
import { CommonTutorial } from './CommonTutorial';
import { TutorialText, TutorialTitle } from './components';

const SummaryTutorialText = () => {
  const { t } = useTranslation();
  return (
    <>
      <Line />
      <TutorialTitle className="text-bold text-lg mt-[14px]">
        {t('front.payslip.tdc.summary.tutorial.title')}
      </TutorialTitle>
      <TutorialText>
        {t('front.payslip.tdc.summary.tutorial.text')}
      </TutorialText>
    </>
  );
};

export const SummaryTutorial = ({ onClick }: { onClick: () => void }) => (
  <CommonTutorial
    Icon={<SummaryTutorialIllustration height={255} width={177} />}
    onClick={onClick}
    Component={<SummaryTutorialText />}
  />
);
