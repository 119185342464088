import { FunctionComponent, useState } from 'react';
import URLSearchParams from '@ungap/url-search-params';
import { AuthHeader, AuthTitle } from '@/modules/authentification/components';
import { AuthMainContainer } from '@/modules/authentification/components/cards';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { TextInput } from '@/modules/common/components/inputs';
import {
  ButtonPrimary,
  ButtonTernary,
  HypertextLink,
} from '@common/components/buttons';
import { useVerifyAccountResend } from '@/modules/authentification/hooks/useVerifyAccountResend';
import {
  useVerifyAccount,
  VERIFY_ACCOUNT_REASON_CODES,
} from '@/modules/authentification/hooks/useVerifyAccount';
import { useRedirectAfterLogin } from '@/modules/authentification/hooks/useRedirectAfterLogin';
import { noDefault } from '@/app/utils/typescriptUtils';
import { createSearchParams, useNavigate } from 'react-router-dom';

export type InnerContentProps = {
  email: string | null;
};

// Error message when the email is not found
export const NoEmailContent: FunctionComponent<InnerContentProps> = () => {
  const { t } = useTranslation();

  // Return a div with the error message
  return (
    <div className="flex flex-col items-center">
      <p className="text-center text-red-500 font-bold">
        {t('front.auth.verifyAccount.form.noEmail')}
      </p>
    </div>
  );
};

export type VerifyAccountFormValue = {
  key: string;
  email: string;
};

export const ResendEmailButton: FunctionComponent<{ email: string }> = ({
  email,
}) => {
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { mutate, isLoading } = useVerifyAccountResend({
    onSuccess: (data) => {
      setSuccessMessage(data.data.success);
    },
    onError: (error) => {
      const errorContent = error.response?.data?.error;
      if (errorContent) setErrorMessage(errorContent);
    },
  });

  return (
    <>
      <HypertextLink
        testID="login-link-not-me"
        onClick={() => mutate({ login: email })}
        className="ml-[10px]"
        disabled={isLoading}
      >
        {t('front.auth.verifyAccount.form.resend')}
      </HypertextLink>
      {successMessage && (
        <p className="text-green-500 font-bold">{successMessage}</p>
      )}{' '}
      {errorMessage && <p className="text-red-500 font-bold">{errorMessage}</p>}
    </>
  );
};

export const VerifyAccountForm: FunctionComponent<InnerContentProps> = ({
  email,
}) => {
  const redirectAfterLogin = useRedirectAfterLogin();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const keyParam = queryParams.get('key');

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<VerifyAccountFormValue>({
    defaultValues: { key: keyParam || '', email: email || '' },
  });

  const { mutateAsync } = useVerifyAccount({
    onSuccess: ({ data: { redirect } }) => {
      redirectAfterLogin(redirect);
    },
    onError: (error) => {
      const reasonCode = error.response?.data?.reason_code;
      switch (reasonCode) {
        case VERIFY_ACCOUNT_REASON_CODES.INVALID_KEY:
          setError('key', {
            type: 'custom',
            message: 'front.auth.verifyAccount.form.key.error',
          });
          break;
        case undefined:
          throw error;
        default:
          noDefault(reasonCode);
          throw error;
      }
    },
  });

  if (!email) {
    return null;
  }

  const onSubmit = async (data: VerifyAccountFormValue) => {
    await mutateAsync({ key: data.key, login: email });
  };

  // Return a div with the form
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center md:items-start">
        <p className="mt-[33px]">
          <Trans
            t={t}
            i18nKey="front.auth.verifyAccount.form.explanation"
            values={{ email }}
            components={{ strong: <strong className="font-bold" /> }}
          />
        </p>
        <ResendEmailButton email={email} />
        <TextInput
          testID="verification-key"
          label={t('front.auth.verifyAccount.form.key.label') as string}
          placeholder={
            t('front.auth.verifyAccount.form.key.placeholder') as string
          }
          className="mt-[33px]"
          type="text"
          errorText={errors?.key}
          {...register('key', {
            required: {
              value: true,
              message: 'front.auth.verifyAccount.form.key.required',
            },
          })}
        />
      </div>
      <div className="flex items-center justify-center md:justify-start mt-[33px]">
        <ButtonPrimary
          testID="login-button-submit"
          type="submit"
          isLoading={isSubmitting}
        >
          {t('front.auth.signIn.form.next.title')}
        </ButtonPrimary>
        <ButtonTernary
          onClick={() =>
            navigate({
              pathname: '../login',
              search: createSearchParams({ email }).toString(),
            })
          }
        >
          {t('default.button.cancel')}
        </ButtonTernary>
      </div>
    </form>
  );
};

export const VerifyAccount: FunctionComponent = () => {
  const { t } = useTranslation();

  const queryParams = new URLSearchParams(window.location.search);

  const emailParam = queryParams.get('email');

  const InnerContent = emailParam ? VerifyAccountForm : NoEmailContent;

  return (
    <div
      data-testid="login-index-container"
      className="h-screen w-full flex justify-between"
    >
      <div className="flex flex-col justify-between flex-1">
        <div>
          <AuthHeader />{' '}
          <AuthMainContainer>
            {' '}
            <AuthTitle>{t('front.auth.verifyAccount.form.header')}</AuthTitle>
            <InnerContent email={emailParam} />{' '}
          </AuthMainContainer>
        </div>
      </div>
    </div>
  );
};
