import {
  BreakdownLine,
  FactorClientPaymentTransaction,
} from '@/app/spraypaint';
import { useUserManagerRole } from '@/modules/authentification/hooks';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '@/modules/common/components/buttons';
import { CommentWidget } from '@/modules/common/components/comments/CommentWidget';
import Table from '@/modules/common/components/table/Table';
import { Breadcrumbs } from '@/modules/navigation/components/Breadcrumbs';
import { useQueryClient } from '@tanstack/react-query';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { onRowClickNavigate } from '@/app/navigation/functions';
import { toast } from 'react-toastify';
import { Toaster } from '@/modules/common/components/toasts';
import { useCreateTransition } from '@/modules/common/hooks/useTransition';
import invoicesBankTransactionColumns from '@/modules/admin/accounting/bankAssociationShow/columns/invoicesBankTransactionColumns';
import { useGetBankReconciliationInformationScope } from '../hooks/useGetAssociationInvoices';
import { useGetFactorClientPaymentTransaction } from '../hooks/useGetFactorClientPaymentTransaction';
import { FactorClientPaymentInformationCard } from '../components/FactorClientPaymentInformationCard';

const ReconciliationShow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const isOnlyManager = useUserManagerRole();

  const handleCancelSuccess = () => {
    toast.success(
      <Toaster
        textMsg="Rapprochement bancaire abandonné"
        toastType="success"
      />,
    );
    queryClient.invalidateQueries([
      'pending-factor-client_payment_transactions',
      'completed-factor-client_payment_transactions',
      'factor-client_payment_transactions',
    ]);
    navigate(`/v2/admin/factor_reconciliation/transactions/${id}/assignation`);
  };
  const { mutate, isLoading: cancellationReconciliationLoader } =
    useCreateTransition(handleCancelSuccess);

  const { data: factorClientPaymentTransactionData } =
    useGetFactorClientPaymentTransaction(id as string);
  const transaction = factorClientPaymentTransactionData?.data;
  const legalEntity =
    transaction?.clientPaymentFile?.factoringContract?.legalEntity;

  const handleCancelBankReconciliation = () => {
    mutate({
      resourceType: 'Factor::ClientPaymentTransaction',
      resourceId: id,
      transitionName: 'cancel',
    });
  };

  const handleTransactionRestoration = () => {
    mutate({
      resourceType: 'Factor::ClientPaymentTransaction',
      resourceId: id,
      transitionName: 'back_to_pending_validation',
    });
  };

  const [
    validatedTransactionScope,
    validatedTransactionScopeKey,
    validatedTransactionProps,
  ] = useBackendTable<FactorClientPaymentTransaction>();

  const { breakDownLines, totalCount } =
    useGetBankReconciliationInformationScope(id as string, {
      args: validatedTransactionScopeKey,
      scope: validatedTransactionScope,
    });

  function createRowClickHandler<T extends { employeePagePath?: string }>(
    model: T,
    navigateFn: NavigateFunction,
  ) {
    return (event: MouseEvent) => {
      if (model.employeePagePath) {
        onRowClickNavigate({
          id: model.employeePagePath,
          event,
          windowNavigationHref: model.employeePagePath,
          href: model.employeePagePath,
          navigate: navigateFn,
        });
      }
    };
  }

  const onInvoiceClick = (raw: ModelRecord<BreakdownLine>) =>
    createRowClickHandler(raw, navigate);

  const diplayAssociations = () => (
    <>
      <p className="text-color-medium-grey text-xl mt-[26px] ml-[26px]">
        Factures client
      </p>
      <Table
        emptyTableErrorMsg="react_table.no_data_text"
        className=""
        withImageErrorMsg={false}
        totalCount={totalCount}
        data={breakDownLines || []}
        // @TODO solve type issue with this line
        // @ts-expect-error: TS2322: Type because of data is BreakdownLine[] | undefined
        reactTableProps={validatedTransactionProps}
        columns={invoicesBankTransactionColumns}
        onRowClick={onInvoiceClick}
      />
    </>
  );

  return (
    <div className="mb-[100px]">
      <div className="ml-[20px]">
        <div className="flex justify-between pr-[20px]">
          <p className="big-title ">
            {t('front.bankAssociation.validated.show.title')}
          </p>
          <div className="flex justify-end">
            <ButtonSecondary
              disabled={!transaction?.previousPendingTransactionId}
              className="mr-5"
              to={`/v2/admin/bank_reconciliation/${transaction?.previousPendingTransactionId}/assignation`}
            >
              {t('front.button.label.previousMatching')}
            </ButtonSecondary>
            <ButtonSecondary
              disabled={!transaction?.nextPendingTransactionId}
              className="mr-5"
              to={`/v2/admin/bank_reconciliation/${transaction?.nextPendingTransactionId}/assignation`}
            >
              {t('front.button.label.nextMatching')}
            </ButtonSecondary>
          </div>
        </div>
        <Breadcrumbs />
        <CommentWidget
          commentableType="Factor::ClientPaymentTransaction"
          commentableId={transaction?.id}
        />
        <FactorClientPaymentInformationCard
          factorClientPaymentTransaction={transaction}
          entityName={legalEntity?.name}
        />
      </div>
      <div className="flex ml-[20px] mt-[20px]">
        <ButtonSecondary
          to={`/v2/admin/factor_reconciliation/client_payment_files/${transaction?.clientPaymentFileId}`}
          className="mr-5"
          disabled={isOnlyManager}
        >
          {t('front.button.label.showSepaFile')}
        </ButtonSecondary>
        {transaction?.status === 'validated' && (
          <ButtonPrimary
            onClick={handleCancelBankReconciliation}
            isLoading={cancellationReconciliationLoader}
            className="mr-5"
            disabled={isOnlyManager}
          >
            {t('front.button.label.cancelMatching')}
          </ButtonPrimary>
        )}
        {!isOnlyManager && transaction?.status === 'orphaned' && (
          <ButtonPrimary
            onClick={handleTransactionRestoration}
            className="mr-5"
            isLoading={cancellationReconciliationLoader}
            disabled={isOnlyManager}
          >
            {t('front.button.label.restoreTransaction')}
          </ButtonPrimary>
        )}
      </div>
      {transaction?.status !== 'orphaned' && diplayAssociations()}
    </div>
  );
};

export default ReconciliationShow;
