import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { values } from 'lodash';
import { TextInput } from '@/modules/common/components/inputs';
import { Toaster } from '@/modules/common/components/toasts';
import {
  Button,
  ButtonPrimary,
  ButtonSecondary,
} from '@/modules/common/components/buttons';
import { Line } from '@/modules/common/components/fragments';
import { CustomModal } from '@/modules/common/components/fragments/CustomModal';
import { ApiValidationError } from '@spraypaint/ApiValidationError';
import { updatePayslipFollowUp } from '@/modules/admin/payslip/functions';
import Employee from '@spraypaint/Employee';
import { CloseIcon } from '../../../../app/assets/icons/buttons';

type Props = {
  displayModal: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSetCloseModal: any;
  employeeId: string;
  followUp: string | undefined;
};

type FormValues = {
  followUpToEdit: string;
};

export const PayslipFollowUpModal = ({
  displayModal,
  handleSetCloseModal,
  employeeId,
  followUp,
}: Props) => {
  const { t } = useTranslation();

  const { handleSubmit, register } = useForm<FormValues>({
    defaultValues: { followUpToEdit: followUp },
  });

  const { mutate } = useMutation(
    (updatedFollowUp: string) =>
      updatePayslipFollowUp({ employeeId, submittedFollowUp: updatedFollowUp }),
    {
      onSuccess: () => {
        toast.success(
          <Toaster
            textMsg={t(
              'front.employeePayslip.dashboard.cell.followUp.update.success',
            )}
            toastType="success"
          />,
        );
        handleSetCloseModal();
      },
      onError: (error) => {
        if (ApiValidationError.isInstance(error, Employee)) {
          values(error.errors).forEach((validationError) => {
            if (!validationError) return null;
            return toast.error(
              <Toaster
                textMsg={validationError.fullMessage}
                toastType="error"
              />,
            );
          });
        } else {
          toast.error(
            <Toaster
              textMsg={t(
                'front.employeePayslip.dashboard.cell.followUp.update.error',
              )}
              toastType="error"
            />,
          );
        }
      },
    },
  );

  const onSubmit = async ({ followUpToEdit }: FormValues) =>
    mutate(followUpToEdit);

  return (
    <CustomModal
      bodyOpenClassName="w-50"
      isOpen={displayModal}
      onRequestClose={handleSetCloseModal}
      contentLabel="Follow Up Modal"
    >
      <div className="flex flex-col fill-color-medium-grey w-[300px] md:w-[600px] h-[400px] md:mb-[80px]">
        <Button
          custom
          className="self-end mt-[25px] mr-[25px]"
          onClick={handleSetCloseModal}
        >
          <CloseIcon />
        </Button>
        <p className="text-xl ml-[10px]">
          {t('activerecord.attributes.employee.follow_up')}
        </p>
        <Line rounded className="mt-[20px]" />
        <form onSubmit={handleSubmit(onSubmit)} className="px-[40px] mt-5">
          <div className="flex justify-between">
            <TextInput
              {...register('followUpToEdit')}
              required
              textarea
              label={t('activerecord.attributes.employee.follow_up')}
            />
          </div>
          <div className="absolute bottom-0 right-0 flex justify-end content-end space-x-[10px] mr-[20px] mb-[20px]">
            <ButtonPrimary onClick={handleSubmit(onSubmit)}>
              {t('views.shared.buttons.approve')}
            </ButtonPrimary>
            <ButtonSecondary
              className="ml-[10px]"
              onClick={handleSetCloseModal}
            >
              {t('views.shared.buttons.cancel')}
            </ButtonSecondary>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};
