import { useTranslation } from 'react-i18next';

export const MiniTutorialIntermediateSummary = () => {
  const { t } = useTranslation();
  return (
    <p className="text-color-dark-content">
      {t('front.payslip.tdc.intermediateSummary.miniTutorial')}
    </p>
  );
};
