import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import URLSearchParams from '@ungap/url-search-params';
import { useValidation } from '@/app/form/ValidationForm';
import { ButtonPrimary } from '@common/components/buttons';
import { TextInput } from '@common/components/inputs';
import { AuthHeader, AuthTitle, AuthFooter } from '../components';
import { AuthMainContainer } from '../components/cards';
import { ForgotPasswordSuccess } from '../components/forgotPassword';
import { useResetPasswordRequest } from '../hooks';

type FormValue = {
  email: string;
};

type ErrorResponse = {
  field_error?: Array<string>;
  error?: string;
};

export const ForgotPasswordIndex = () => {
  const Validation = useValidation();

  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get('email');

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    register,
  } = useForm<FormValue>({ defaultValues: { email: email || undefined } });
  const onError = (error: AxiosError<ErrorResponse>) => {
    setError('email', {
      type: 'custom',
      message:
        error.response?.data?.field_error?.[1] || error.response?.data?.error,
    });
  };

  const { mutate, isLoading, isSuccess } = useResetPasswordRequest({ onError });
  const onSubmit = (data: FormValue) => mutate({ login: data.email });

  const { t } = useTranslation();

  return (
    <div className="h-screen w-full flex flex-col justify-between">
      <div>
        <AuthHeader />
        <AuthMainContainer>
          <AuthTitle>{t('front.auth.forgotPassword.header')}</AuthTitle>
          {isSuccess ? (
            <ForgotPasswordSuccess email={getValues('email')} />
          ) : (
            <>
              <p className="text-color-pone-dark text-base2 mt-[34px]">
                {t('front.auth.forgotPassword.header.information.1')}
              </p>
              <p className="text-color-pone-dark text-base2">
                {t('front.auth.forgotPassword.header.information.2')}
              </p>
              <form onSubmit={handleSubmit(onSubmit)} className="mt-[34px]">
                <TextInput
                  {...register('email', Validation.email)}
                  testID="forgotpasswordindex-email-input"
                  label={
                    t('front.auth.forgotPassword.form.email.label') as string
                  }
                  placeholder={
                    t(
                      'front.auth.forgotPassword.form.email.placeholder',
                    ) as string
                  }
                  className="mt-6"
                  errorText={errors?.email}
                />
                <ButtonPrimary
                  testID="forgotpasswordindex-submit"
                  isLoading={isLoading}
                  className="mt-[46px]"
                  type="submit"
                >
                  {t('front.auth.forgotPassword.form.submit.title')}
                </ButtonPrimary>
              </form>
            </>
          )}
        </AuthMainContainer>
      </div>
      <AuthFooter />
    </div>
  );
};
