import type { FunctionComponent } from 'react';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { useParams } from 'react-router-dom';
import { useFrameworkContractFindQuery } from '@/modules/admin/registered_clients/modules/framework_contracts/functions/frameworkContractQuery';
import { FindQueryOpts } from '@/app/utils/findQueryBuilder';
import { MainCard } from '@/modules/common/components/mainCard';
import { LineText } from '@/modules/common/components/fragments/LineText';
import { useTranslation } from 'react-i18next';
import { ProvisionalInvoicesList } from '@/modules/admin/registered_clients/modules/framework_contracts/components/ProvisionalInvoicesList';
import { CorporateInvoicesList } from '../components/CorporateInvoicesList';

export const queryOpts: FindQueryOpts = {
  includes: ['legal_entity', 'registered_client'],
  select: {
    registered_clients: ['id', 'name'],
    legal_entities: ['id', 'name'],
  },
};

export const FrameworkContractShow: FunctionComponent = () => {
  const { frameworkContractId } = useParams();
  const { record } = useFrameworkContractFindQuery(
    frameworkContractId,
    queryOpts,
    {
      staleTime: 30000,
    },
  );

  const { t } = useTranslation();

  if (!record) return null;

  return (
    <PageLayout Title={record.name}>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <MainCard header="Info">
          <LineText
            label={t('activerecord.attributes.framework_contract.name', {
              ns: 'rails',
            })}
            value={record.name}
          />
          <LineText
            label={t(
              'activerecord.attributes.framework_contract.legal_entity',
              {
                ns: 'rails',
              },
            )}
            value={record.legalEntity?.name}
          />
          <LineText
            label={t(
              'activerecord.attributes.framework_contract.operating_unit_name',
              {
                ns: 'rails',
              },
            )}
            value={record.operatingUnitName}
          />
        </MainCard>
        <CorporateInvoicesList frameworkContract={record} />
        <ProvisionalInvoicesList
          frameworkContract={record}
          className="col-span-3"
        />
      </div>
    </PageLayout>
  );
};
