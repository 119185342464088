import { useQuery } from '@tanstack/react-query';
import AccountingJournalExport from '@spraypaint/accounting/AccountingJournalExport';

const useAccountingJournalExport = (id: number | string) => {
  const data = useQuery({
    queryKey: ['accounting-journal-export', id],
    queryFn: () =>
      AccountingJournalExport.selectExtra([
        'assignation_csv_url',
        'assignation_csv_name',
        'assignation_csv_lines_count',
        'reassignation_csv_url',
        'reassignation_csv_name',
      ])
        .includes(['sepa_file', 'legalEntity'])
        .find(id),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const accountingJournalExport = data?.data?.data;

  return { ...data, accountingJournalExport };
};

export default useAccountingJournalExport;
