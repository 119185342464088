import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import FactorAuthorization from '@/app/spraypaint/factor/FactorAuthorization';

export const useGetAuthorizations = (
  args: Array<string>,
  scope: (baseScope: Scope<FactorAuthorization>) => Scope<FactorAuthorization>,
) => {
  const data = useQuery({
    queryKey: ['factor-authorizations', ...args],
    queryFn: () =>
      scope(FactorAuthorization.scope()).includes(['registered_client']).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const authorizations = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, authorizations, totalCount };
};
