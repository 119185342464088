/* eslint-disable import/no-cycle */
// api/Payslip.ts
import { Attr, BelongsTo, HasOne, Model } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import type {
  PeriodType,
  HoursDistribution,
  MoneyType,
  PayslipStatusEnum,
} from '../../modules/common/utils/type.utils';
import type PrintableLine from '../../modules/payslip/types/PrintableLine';
import type { PrintableAlertLine } from '../../modules/payslip/components/DisplayWarning';
import Employee from './Employee';
import ActivityReport from './activityReport/ActivityReport';
import PayslipReview from './payslipFunnel/PayslipReview';

@Model()
class Payslip extends ApplicationRecord {
  static jsonapiType = 'payslips';

  // Attributes
  @Attr({ persist: false }) employeeId!: number;
  @Attr({ persist: false }) employmentContractId!: number;
  @Attr({ persist: false }) period!: PeriodType;
  @Attr() status!: PayslipStatusEnum;
  @Attr() comment!: string;
  @Attr({ persist: false }) grossSalary!: number;
  @Attr({ persist: false }) grossSalaryList!: Array<PrintableLine>;
  @Attr({ persist: false }) leftToPayRealBeforeTax!: number;
  @Attr({ persist: false }) leftToPayRealBeforeTaxList!: Array<PrintableLine>;
  @Attr({ persist: false }) leftToPayReal!: number;
  @Attr({ persist: false }) leftToPayRealList!: Array<PrintableLine>;
  @Attr({ persist: false }) activityFees!: number;
  @Attr({ persist: false }) fromPayslipFunnel!: boolean;
  @Attr({ persist: false }) activityFeesList!: Array<PrintableLine>;
  @Attr({ persist: false }) payslipAlertsList!: Array<PrintableAlertLine>;
  @Attr({ persist: false }) provisionAdjustments!: number;
  @Attr({ persist: false }) provisionAdjustmentsList!: Array<PrintableLine>;
  @Attr({ persist: false }) contributions!: number;
  @Attr({ persist: false }) contributionsListSum!: number;
  @Attr({ persist: false }) contributionsList!: Array<PrintableLine>;
  @Attr({ persist: false }) expenses!: number;
  @Attr({ persist: false }) advance!: number;
  @Attr({ persist: false }) netSalary!: number;
  @Attr({ persist: false }) netSalaryReal!: number;
  @Attr({ persist: false }) leftToPay!: number;
  @Attr({ persist: false }) employerContributions!: number;
  @Attr({ persist: false }) employerContributionsReal!: number;
  @Attr({ persist: false }) employeeContributions!: number;
  @Attr({ persist: false }) employeeContributionsReal!: number;
  @Attr({ persist: false }) taxRetention!: number;
  @Attr({ persist: false }) paymentDate!: string;
  @Attr({ persist: false }) workingHours!: number;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) paidHolidaysList!: Array<PrintableLine>;

  // Admin dashboard
  @Attr({ persist: false }) submittedAt!: string;
  @Attr({ persist: false }) validatedAt!: string;
  @Attr({ persist: false }) submittedBy!: string;
  @Attr({ persist: false }) validatedBy!: string;
  @Attr({ persist: false }) autovalidated!: string;
  @Attr({ persist: false }) closedAt!: string;
  @Attr({ persist: false }) error!: string;
  @Attr({ persist: false }) remainingAmount!: number;
  @Attr({ persist: false }) balance!: MoneyType;
  @Attr({ persist: false }) arbitration!: boolean;
  @Attr({ persist: false }) autosubmitted!: boolean;
  @Attr({ persist: false }) urgentTransfer!: boolean;
  @Attr({ persist: false }) stc!: boolean;
  @Attr({ persist: false }) new!: boolean;

  // Coût du salaire
  @Attr({ persist: false }) previousBalance!: number;
  @Attr({ persist: false }) payslipCost!: number;
  @Attr({ persist: false }) incomingBalance!: number;

  // CRA
  @Attr({ persist: false }) craId!: number;
  @Attr({ persist: false }) hoursDistribution!: HoursDistribution;

  // EXTRA ATTRIBUTES
  @Attr({ persist: false }) fileUrl!: string;
  @Attr({ persist: false }) fileName!: string;
  @Attr({ persist: false }) documentAvailabilityDate!: string;
  @Attr({ persist: false }) expectedPaymentDate!: string;
  @Attr({ persist: false }) computationDetails!: string;
  @Attr({ persist: false }) previousEmployeePayslipId!: number;
  @Attr({ persist: false }) nextEmployeePayslipId!: number;

  // Relationships
  @BelongsTo(Employee) employee!: Employee;
  @HasOne(ActivityReport) activityReport!: ActivityReport;
  @HasOne(PayslipReview) payslipReview!: PayslipReview;
}

export default Payslip;
