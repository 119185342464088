// api/Sepa::Transaction.ts
import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type LegalEntity from '../LegalEntity';
import type SepaFile from './SepaFile';
// import type SepaMatchingScore from './SepaMatchingScore';
import type ClientPayment from '../ClientPayment';
import type Invoice from '../Invoice';
import type Comment from '../Comment';
import type { MoneyType } from '../../../modules/common/utils/type.utils';
import type {
  MatchingStatusEnum,
  TransactionStatusEnum,
} from '../types/matchingStatusTypes';
import type SepaTransactionAssociation from './SepaTransactionAssociation';

@Model()
class SepaTransaction extends ApplicationRecord {
  static jsonapiType = 'sepa-transactions';

  // Attributes
  @Attr({ persist: false }) reference!: string;
  @Attr({ persist: false }) amount!: MoneyType;
  @Attr({ persist: false }) amountEuro!: MoneyType;
  @Attr({ persist: false }) debitorName!: string;
  @Attr({ persist: false }) paymentDate!: string;
  @Attr({ persist: false }) label!: string;
  @Attr({ persist: false }) direction!: string;
  @Attr({ persist: false }) endToEndId!: string;
  @Attr({ persist: false }) additionalInformation!: string;
  @Attr({ persist: false }) sepaFileId!: number;
  @Attr({ persist: false }) remainingAmount!: MoneyType;
  @Attr({ persist: false }) remainingAmountEuro!: MoneyType;
  @Attr({ persist: false }) status!: TransactionStatusEnum;
  @Attr({ persist: false }) matchingStatus!: MatchingStatusEnum;
  @Attr({ persist: false }) matchingCandidatesCount!: number;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) labelFormatReminderSent!: boolean;
  @Attr({ persist: false }) previousPendingTransactionId!: number;
  @Attr({ persist: false }) nextPendingTransactionId!: number;
  @Attr({ persist: false }) autoValidated!: boolean;
  @Attr({ persist: false }) totalEntries!: number;

  // Relationships
  @BelongsTo('legal_entities') legalEntity!: LegalEntity;
  @BelongsTo('sepa-files') sepaFile!: SepaFile;
  @HasOne('client_payments') clientPayment!: ClientPayment;
  @HasOne('invoices') matchingInvoicesCandidates!: Invoice;
  @HasMany('comments') comments!: Array<Comment>;
  @HasMany('transaction_associations')
  transactionAssociations!: Array<SepaTransactionAssociation>;
  // @HasMany('sepa-matching_scores') matchingScores!: Array<SepaMatchingScore>;

  // Methods
  async fetchRefreshMatching() {
    return this.fetchMemberAction('post', 'refresh_matching');
  }

  async fetchSendLabelFormatReminder() {
    return this.fetchMemberAction('post', 'send_label_format_reminder');
  }

  get adminAssignationShowPagePath(): string | undefined {
    const { id } = this;

    if (!id) {
      return undefined;
    }

    return `/v2/admin/bank_reconciliation/${id}/assignation`;
  }

  get adminReconciliationShowPagePath(): string | undefined {
    const { id } = this;

    if (!id) {
      return undefined;
    }

    return `/v2/admin/bank_reconciliation/${id}`;
  }
}

export default SepaTransaction;
