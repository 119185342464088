import { MouseEventHandler, useEffect, useState } from 'react';
import { SalaryStrategy } from '../../../../app/spraypaint/types/salaryStrategyTypes';

/**
 Helper hook to handle the click on a non active strategy card.
  - If the strategy has not be selected yet, set default strategy
  - If the card was already selected in the past, remember the value
     and set it back when the card is clicked again

 Returns the click handler and strategy to use for display
*/
export function useSetDefaultOrLast<StrategyType extends SalaryStrategy>(
  defaultStrategy: StrategyType,
  currentStrategy: SalaryStrategy | undefined,
  onStrategyChange: (strategy: StrategyType) => void,
): {
  handleClick: MouseEventHandler<HTMLButtonElement>;
  defaultOrLast: StrategyType;
} {
  const [last, setLast] = useState<StrategyType | undefined>(undefined);

  useEffect(() => {
    if (currentStrategy?.name === defaultStrategy.name) {
      setLast(currentStrategy as StrategyType); // if names match, we can safely cast
    }
  }, [currentStrategy, defaultStrategy.name]);

  // `last` defaults to 'undefined'. I didn't wanted to make it default to `defaultStrategy` because
  // it would not be changed is defaultStrategy is change by parent component.
  const defaultOrLast = last ?? defaultStrategy;

  return {
    handleClick: () => {
      onStrategyChange(defaultOrLast);
    },
    defaultOrLast,
  };
}
