// api/ActivityAccountLine.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from '../ApplicationRecord';
import type ActivityAccount from './ActivityAccount';
import type ActivityAccountLineGroup from './ActivityAccountLineGroup';

export const categories = [
  'salary',
  'advance_payment',
  'cesu',
  'club_employee',
  'company_vehicles_tax',
  'culture_voucher',
  'service_plus',
  'employer_charges',
  'employee_charges',
  'service_fees',
  'financial_provision',
  'other',
  'subcontractor_intern',
  'invoice_credit',
  'invoice',
  'management_fees',
  'pee',
  'perco',
  'sponsorship',
  'refunded_vat',
  'subcontractor_extern',
  'securitis',
  'contract_end_provision_cdd',
  'contract_end_provision_cdi',
  'gift_voucher',
  'tax',
  'turnover',
  'paid_holidays_provision',
  'withholding_tax',
  'expenses',
  'payment_external_fees',
] as const; // disallow mutation

export type CategoriesEnum = typeof categories[number];

@Model()
class ActivityAccountLine extends ApplicationRecord {
  static jsonapiType = 'activity_account_lines';

  // Attributes
  @Attr() activityAccountId!: number;
  @Attr({ persist: false }) accountableType!: string;
  @Attr({ persist: false }) accountableId!: number;
  @Attr() date!: string;
  @Attr() label!: string;
  @Attr() categories!: Array<CategoriesEnum>;
  @Attr() value!: MoneyType;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) payslipDate!: string;
  @Attr({ persist: false }) canceledLineId!: number;
  @Attr({ persist: false }) payslipId!: number;
  @Attr({ persist: false }) groupId!: string;

  // Relationships
  @BelongsTo('activity_accounts') activityAccount!: ActivityAccount;
  @BelongsTo('activity_account_line_groups')
  activityAccountLineGroup!: ActivityAccountLineGroup;
}

export default ActivityAccountLine;
