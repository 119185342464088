import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { useInvoice } from '@/modules/invoices/functions/invoice_query';
import { toast } from 'react-toastify';
import { Toaster } from '@/modules/common/components/toasts';
import { useForm } from 'react-hook-form';
import { ControlledDateInput } from '@/modules/common/components/inputs/controlledInput/ControlledDateInput';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { TextInput } from '@/modules/common/components/inputs';
import { ClientPayment } from '@/app/spraypaint';
import { MainCard } from '@/modules/common/components/mainCard';
import { dateToString } from '@/app/utils/dateUtils';
import InvoiceSkeleton from '../components/InvoiceSkeleton';

type FormState = {
  date: ClientPayment['date'];
  amount: ClientPayment['amount'];
  exchangeValueEuroAmount: number;
};

const PayInvoice = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data } = useInvoice(id as string);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const invoice = data?.data;
  if (!invoice) {
    return <InvoiceSkeleton />;
  }

  const { handleSubmit, control, register } = useForm<FormState>({
    defaultValues: {
      date: dateToString(new Date()),
      amount: invoice?.remainingAmount || {
        amount: 0,
        currency: 'EUR',
      },
    },
  });

  const onSubmit = async (values: FormState) => {
    setIsLoading(true);
    const clientPayment = new ClientPayment();

    clientPayment.date = values.date;
    clientPayment.amount = values.amount;
    clientPayment.invoiceId = Number(invoice.id);
    clientPayment.clientId = Number(invoice.clientId);
    if (invoice.currencyIsoCode !== 'EUR')
      clientPayment.exchangeValueEuroAmount = {
        amount: values.exchangeValueEuroAmount,
        currency: 'EUR',
      };
    const success = await clientPayment.save();

    if (success) {
      toast.success(
        <Toaster
          textMsg={t('views.shared.change_applied')}
          toastType="success"
        />,
      );
      navigate(`/v2/admin/invoices/${invoice.id}`);
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      Title={t('front.invoices.pay.header.title', { number: invoice?.number })}
    >
      <MainCard header="Payer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex">
            <div className="flex-col">
              <ControlledDateInput
                control={control}
                label="Date de réception"
                name="date"
                stringMode
                required
              />
              <TextInput
                required
                {...register('amount.amount')}
                className="mt-6"
                label={`Montant (montant restant à payer : ${invoice.remainingAmount.amount} ${invoice.remainingAmount.currency})`}
              />
              {invoice.currencyIsoCode !== 'EUR' && (
                <TextInput
                  required
                  {...register('exchangeValueEuroAmount')}
                  className="mt-6"
                  label={`Contre-valeur euro (montant approximatif restant à payer en € : ${invoice.cacheRemainingAmountEuro.amount} EUR)`}
                />
              )}
            </div>
          </div>
          <ButtonPrimary
            className="m-2 mt-10"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Submit
          </ButtonPrimary>
        </form>
      </MainCard>
    </PageLayout>
  );
};

export default PayInvoice;
