import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import PayslipFunnelsActivityAccountLine from '../../../../app/spraypaint/payslipFunnel/PayslipFunnelActivityAccountLine';
import {
  getPeriodFilterFn,
  PeriodFilter,
} from '../../../common/components/table/filter';
import {
  EqualFilter,
  getEqualFilter,
} from '../../../common/components/table/filter/EqualFilter';

const columnHelper =
  createColumnHelper<ModelRecord<PayslipFunnelsActivityAccountLine>>();

export const ActivityAccountLineColumns = () => [
  columnHelper.accessor('date', {
    header: 'Date',
    filterFn: getPeriodFilterFn(),
    meta: {
      filter: {
        Component: PeriodFilter,
      },
    },
  }),
  columnHelper.accessor('label', {
    header: 'libellé',
  }),
  columnHelper.accessor('value.amount', {
    header: 'Montant (signé)',
    filterFn: getEqualFilter(),

    meta: {
      filter: {
        Component: EqualFilter,
      },
    },
  }),
];
