import { QueryClient, useQuery, UseQueryResult } from '@tanstack/react-query';

import axios, { AxiosError, AxiosResponse } from 'axios';
import { LoaderFunctionArgs } from 'react-router-dom';
import { TypedDataFunctionValue } from '@/types';

export type FeatureFlagResponse = {
  data: {
    enabled: boolean;
  };
};

export type FeatureFlagApiParams = {
  actor_identifier?: string;
};

export type ApiV1ErrorResponse = {
  error: string;
  status: number;
  message?: string;
};

export function featureFlagQuery(
  featureName: string,
  actorIdentifier: string | null = null,
) {
  const params: FeatureFlagApiParams = {};

  if (actorIdentifier) {
    params.actor_identifier = actorIdentifier;
  }

  return {
    queryKey: ['feature_flags', featureName, actorIdentifier],
    queryFn: () => axios.get(`/api/feature_flags/${featureName}`, { params }),
  };
}

/**
 * @param {string} featureName
 * @param {string} actorIdentifier
 * @return  {[boolean, object]}
 */
export default function useFeatureFlag(
  featureName: string,
  actorIdentifier: string | null = null,
): [
  boolean | undefined,
  UseQueryResult<
    AxiosResponse<FeatureFlagResponse>,
    AxiosError<ApiV1ErrorResponse>
  >,
] {
  const reactQueryResp = useQuery<
    AxiosResponse<FeatureFlagResponse>,
    AxiosError<ApiV1ErrorResponse>,
    AxiosResponse<FeatureFlagResponse>
  >({
    ...featureFlagQuery(featureName, actorIdentifier),
    refetchOnWindowFocus: false,
  });

  return [reactQueryResp.data?.data?.data?.enabled, reactQueryResp];
}

export function featureFlagLoader(
  queryClient: QueryClient,
  featureName: string,
  actorIdentifier: string | null = null,
): (
  args: LoaderFunctionArgs,
) => Promise<TypedDataFunctionValue<AxiosResponse<FeatureFlagResponse>>> {
  return async () => {
    const query = featureFlagQuery(featureName, actorIdentifier);
    return queryClient.ensureQueryData(query);
  };
}
