import { useQuery } from '@tanstack/react-query';
import Payslip from '../../../app/spraypaint/Payslip';

export const usePayslipDetails = (id: string) => {
  const { data, ...others } = useQuery({
    queryKey: ['payslip-show-details', id],
    queryFn: () => Payslip.select(['']).includes(['payslip_review']).find(id),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const invoicesStep = data?.data?.payslipReview?.invoicesStep ?? {
    activity_account_balance: { cents: 0 },
    total_amount: { cents: 0 },
    paid_amount: { cents: 0 },
  };

  const craStep = data?.data?.payslipReview?.craStep ?? {};
  const expensesStep = data?.data?.payslipReview?.expensesStep ?? {};
  const salaryStep = data?.data?.payslipReview?.salaryStep ?? {};

  const intermediateSummaryStep =
    data?.data?.payslipReview?.intermediateSummaryStep ?? {};

  const finalSummaryStep = data?.data?.payslipReview?.finalSummaryStep ?? {};
  const employmentContractId = data?.data?.payslipReview?.employmentContractId;
  const stc = data?.data?.payslipReview?.stc;

  return {
    others,
    invoicesStep,
    craStep,
    intermediateSummaryStep,
    salaryStep,
    expensesStep,
    finalSummaryStep,
    employmentContractId,
    stc,
    ...others,
  };
};
