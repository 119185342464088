import { Header } from '@tanstack/react-table';
import { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { useOnClickOutside } from '@/modules/layout/hooks';
import { SearchIcon } from '@/app/assets/icons/buttons';
import { FilterComponentType, FilterMeta } from '../filter/types';
import { TextFilter } from '../filter';

type HeaderFilterProps<TData, TValue> = {
  header: Header<TData, TValue>;
};

const defaultFilterValues: FilterMeta<any, any, typeof TextFilter> = {
  Component: TextFilter,
  options: {
    placeholder: 'Filtrer ...',
  },
};

export function HeaderFilter<TData, TValue>({
  header,
}: HeaderFilterProps<TData, TValue>) {
  const { column } = header;
  const ref = useRef(null);

  // Don't display the filter button and field if column is not filterable
  if (!column.getCanFilter()) return null;

  // Prepare Open/Close callbacks
  const [isOpened, setIsOpened] = useState(false);
  const openCallback = useCallback(() => setIsOpened(true), [setIsOpened]);
  const closeCallback = useCallback(() => setIsOpened(false), [setIsOpened]);
  useOnClickOutside(ref, () => setIsOpened(false));

  // Prepare button classNames
  const isFiltered = column.getIsFiltered();
  const buttonClassName = classNames(
    'fill-white flex justify-center items-center h-10 w-10 relative',
    // use the following flags instead of a hover: modifier so background
    //  appears exactly at the same time the filter box appears
    {
      'bg-color-medium-grey': !isOpened && !isFiltered,
      'bg-color-pone-dark': isOpened && !isFiltered,
      'bg-color-pone-orange': isFiltered,
    },
  );

  const filterMeta: FilterMeta<
    TData,
    TValue,
    FilterComponentType<TData, TValue, object>
  > = column.columnDef.meta?.filter ?? defaultFilterValues;

  const FilterComponent = filterMeta.Component;

  // @ToDo this method is broken for now, see Table component

  return (
    <button
      key={`search-button-${column.id}`}
      onClick={openCallback}
      type="button"
      className={buttonClassName}
    >
      <SearchIcon data-testid="search-icon" />
      {isOpened && (
        <div
          ref={ref}
          className="absolute top-[40px] right-[0px] bg-color-pone-dark px-[12px] py-[4px] z-30"
        >
          <FilterComponent
            header={header}
            onSubmit={closeCallback}
            options={filterMeta.options}
          />
        </div>
      )}
    </button>
  );
}
