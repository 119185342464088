import { useInterpret, useSelector } from '@xstate/react';
import { LowInfer } from 'xstate';
import { Context, PropsWithChildren, useMemo, useRef } from 'react';
import { FormWizardSteps } from '@/modules/common/components/wizard/components/FormWizardSteps';
import {
  initWizardMachine,
  pageCollectiontoPages,
} from '@/modules/common/components/wizard/functions/initWizardMachine';
import { FieldValues } from 'react-hook-form';
import { FormWizardContextType } from '../functions/useFormWizardContext';
import { CustomStateMachine, WizardPageCollection } from '../types';
import { FormWizardDebugger } from './FormWizardDebugger';

export type FormWizardProps<
  TFormData extends FieldValues,
  TKeys extends string,
> = {
  wizardContext: Context<FormWizardContextType<TFormData, TKeys>>;
  pages: WizardPageCollection<TKeys>;
  id: string;
  initialValues: LowInfer<TFormData>;
  onSubmit: (data: TFormData) => void;
};

function WizardContainer<TFormData extends FieldValues, TKeys extends string>({
  children,
  wizardContext,
  wizardContextContent,
}: PropsWithChildren<{
  wizardContext: Context<FormWizardContextType<TFormData, TKeys>>;
  wizardContextContent: FormWizardContextType<TFormData, TKeys>;
}>) {
  return (
    <div>
      <div className="px-[30px]">
        <wizardContext.Provider value={wizardContextContent}>
          {children}
        </wizardContext.Provider>
      </div>
    </div>
  );
}

export function FormWizard<
  TFormData extends FieldValues,
  TKeys extends string,
>({
  pages,
  id,
  initialValues,
  onSubmit,
  wizardContext,
}: FormWizardProps<TFormData, TKeys>) {
  // Use a ref to keep the machine between renders
  const machine = useRef<CustomStateMachine<TFormData, TKeys> | null>(null);
  // Initialize the machine if it's not initialized yet
  // This avoid having to compute initial state on every render
  if (machine.current === null) {
    machine.current = initWizardMachine<TFormData, TKeys>(
      id,
      initialValues,
      pages,
      onSubmit,
    );
  }

  // This should not happen since it's initialized just above but
  // Typescript doesn't know that
  if (!machine.current) throw new Error('Machine not initialized');

  // An interpret is a hook that will keep the machine in sync with the component
  // and can be shared and attached to by other components using a React Context
  const interpret = useInterpret(machine.current);

  // We memoize the context to avoid re-rendering the whole wizard
  const wizardContextContent = useMemo(
    () => ({ pages: pageCollectiontoPages(pages), interpret }),
    [pages, interpret],
  );

  const currentPage = useSelector(
    interpret,
    (state) => pages[state.value as TKeys],
  );

  const isLoading = useSelector(
    interpret,
    (state) => state.value === 'sending',
  );

  if (isLoading) {
    return (
      <WizardContainer
        wizardContext={wizardContext}
        wizardContextContent={wizardContextContent}
      >
        LOADING
        <FormWizardDebugger wizardContext={wizardContext} />
      </WizardContainer>
    );
  }

  if (!currentPage) {
    // This means we are in `done` state
    // TODO: Add a proper done page
    return (
      <WizardContainer
        wizardContext={wizardContext}
        wizardContextContent={wizardContextContent}
      >
        DONE
        <FormWizardDebugger wizardContext={wizardContext} />
      </WizardContainer>
    );
  }

  const InnerComponent = currentPage.Component;

  return (
    <WizardContainer
      wizardContext={wizardContext}
      wizardContextContent={wizardContextContent}
    >
      <FormWizardSteps wizardContext={wizardContext} />
      <InnerComponent />
      <FormWizardDebugger wizardContext={wizardContext} />
    </WizardContainer>
  );
}
