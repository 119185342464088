// api/Sepa::TransactionAssignation.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type SepaTransaction from './SepaTransaction';
import type SubcontractorBill from '../SubcontractorBill';
import type SepaImport from '../SepaImport';
import type ContribPeeDeposit from '../ContribPeeDeposit';
import type ContribPercoDeposit from '../ContribPercoDeposit';

@Model()
class SepaTransactionAssociation extends ApplicationRecord {
  static jsonapiType = 'sepa-transaction_associations';

  // Attributes
  @Attr() sepaTransactionId!: number;
  @Attr() associableId!: number;
  @Attr() associableType!: string;

  // Relationships
  @BelongsTo('sepa-transactions') sepaTransactions!: SepaTransaction;
  @BelongsTo({ name: 'associable' }) associable!:
    | SubcontractorBill
    | SepaImport
    | ContribPeeDeposit
    | ContribPercoDeposit;
}

export default SepaTransactionAssociation;
