import { ModelRecord } from 'spraypaint/lib-esm/model';
import RegisteredClient from '@/app/spraypaint/RegisteredClient';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { RegisteredClientAgent } from '@/app/spraypaint';
import Table from '@/modules/common/components/table/Table';
import useRegisteredClientAgents from '../hooks/useRegisteredClientAgents';
import registeredClientAgentsColumns from '../functions/registeredClientAgentsColumns';

const RegisteredClientAgents = ({
  registeredClient,
}: {
  registeredClient: RegisteredClient;
}) => {
  const [scope, , props] = useBackendTable<RegisteredClientAgent>();
  const { agents, isLoading, totalCount } = useRegisteredClientAgents(
    Number(registeredClient.id),
    scope,
    props,
  );

  return (
    <Table
      className="mt-[20px]"
      isLoading={isLoading}
      totalCount={totalCount}
      data={agents || []}
      reactTableProps={props}
      onRowClick={{
        navigate: (agent: ModelRecord<RegisteredClientAgent>) =>
          `/v2/admin/users/${agent.user?.id}/edit`,
      }}
      withImageErrorMsg={false}
      emptyTableErrorMsg=""
      columns={registeredClientAgentsColumns()}
    />
  );
};

export default RegisteredClientAgents;
