/* eslint-disable no-param-reassign */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PersistedSpraypaintRecord } from 'spraypaint';
import { PayslipFunnelExpensesStep } from '../../../../app/spraypaint';

export const useSelectExpense = (
  expensesStep: PersistedSpraypaintRecord<PayslipFunnelExpensesStep>,
  expensesStepId: string | undefined,
) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...others } = useMutation({
    mutationFn: async ({
      expenseId,
      toSelect,
    }: {
      expenseId: number;
      toSelect?: boolean;
    }) => {
      expensesStep.isPersisted = true;
      if (toSelect) expensesStep.selectedExpenseToAdd = expenseId;
      else if (!toSelect) expensesStep.selectedExpenseToRemove = expenseId;
      await expensesStep.save();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['expenses-step', expensesStepId]);
    },
  });
  return { mutate, isLoading, ...others };
};
