import {
  StrategyDescription,
  SalaryStrategy,
  StcSalaryStrategy,
  findDescription,
  StcSalaryStrategyDescription,
} from '../../../../app/spraypaint/types/salaryStrategyTypes';
import { PayslipChoiceCard } from './PayslipChoiceCard';
import { useSetDefaultOrLast } from '../hooks/useSetDefaultOrLast';
import { containerChoiceCard } from '../../constantes/tailwindStyle';

interface StcChoiceCardProps {
  onStrategyChange: (strategy: StcSalaryStrategy) => void;
  currentStrategy: SalaryStrategy | undefined;
  availableStrategiesDescription: StrategyDescription[];
}

export const StcChoiceCard = ({
  onStrategyChange,
  currentStrategy,
  availableStrategiesDescription,
}: StcChoiceCardProps) => {
  const isActive = currentStrategy?.name === 'stc_salary';

  const { handleClick } = useSetDefaultOrLast(
    {
      name: 'stc_salary',
      options: {},
    },
    currentStrategy,
    onStrategyChange,
  );

  const stcSalaryDescription = findDescription<StcSalaryStrategyDescription>(
    'stc_salary',
    availableStrategiesDescription,
  );

  if (!stcSalaryDescription) return null;

  const stcValue = stcSalaryDescription.variables.value.gross;

  return (
    <div className={containerChoiceCard}>
      <PayslipChoiceCard
        strategyName="stc_salary"
        value={stcValue}
        isActive={isActive}
        onClick={handleClick}
        tooltip
      />
    </div>
  );
};
