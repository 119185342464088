import type { FunctionComponent } from 'react';
import { CapGeminiConsolidationInvoiceImport } from '@/app/spraypaint';
import { MainCard } from '@/modules/common/components/mainCard';
import {
  FaCheck,
  FaPen,
  FaTriangleExclamation,
  FaXmark,
} from 'react-icons/fa6';
import classNames from 'classnames';
import { useFormatMoney } from '@/app/utils/moneyUtils';
import { ConsolidationInvoiceCardContent } from '@/modules/admin/capgemini/components/ConsolidationInvoiceCardContent';
import { noDefault } from '@/app/utils/typescriptUtils';

export const StatusIcon = ({
  status,
}: {
  status: undefined | CapGeminiConsolidationInvoiceImport['importStatus'];
}) => {
  switch (status) {
    case 'ok':
      return <FaCheck className="text-color-success inline-block" />;
    case 'warning':
      return (
        <FaTriangleExclamation className="text-color-warning inline-block" />
      );
    case 'error':
      return <FaXmark className="text-color-failure inline-block" />;
    case 'pending':
      return <FaPen className="text-color-blue inline-block" />;
    case undefined:
      return null;
    default:
      return noDefault(status);
  }
};

export interface ConsolidationInvoiceCardProps {
  consolidationInvoiceImport: CapGeminiConsolidationInvoiceImport;
}

export const ConsolidationInvoiceCard: FunctionComponent<
  ConsolidationInvoiceCardProps
> = ({ consolidationInvoiceImport }) => {
  const {
    externalReference,
    importStatus,
    totalAmount,
    lastDate,
    operatingUnitName,
  } = consolidationInvoiceImport;
  const formatMoney = useFormatMoney();

  const ttcAmount = {
    amount: totalAmount.amount * 1.2,
    currency: totalAmount.currency,
  };
  const title = (
    <>
      <StatusIcon status={importStatus} />{' '}
      <span className="ml-2">
        {externalReference} - {lastDate} - {formatMoney(totalAmount)} (
        {formatMoney(ttcAmount)} TTC) - {operatingUnitName}
      </span>
    </>
  );

  const headerClassNames = classNames({
    'bg-color-bg-success': importStatus === 'ok',
    'bg-color-bg-warning': importStatus === 'warning',
    'bg-color-bg-failure': importStatus === 'error',
    'bg-color-bg-info': importStatus === 'pending',
  });

  const textClassNames = classNames({
    'text-color-success': importStatus === 'ok',
    'text-color-warning': importStatus === 'warning',
    'text-color-failure': importStatus === 'error',
    'text-color-info': importStatus === 'pending',
  });

  return (
    <MainCard
      header={{ title, headerClassNames, textClassNames }}
      className="mb-1 pt-0"
      collapsable
      mountCollapsed
      Content={ConsolidationInvoiceCardContent}
      contentProps={{ consolidationInvoiceImport }}
    />
  );
};
