import { ModelRecord } from 'spraypaint/lib-esm/model';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { dynT } from '@/app/translation/utils/dynT';
import FactorTransferred from '@/app/spraypaint/factor/FactorTransferred';

const columnHelper = createColumnHelper<ModelRecord<FactorTransferred>>();

const factorTransferredColumns = () => [
  columnHelper.accessor('id', {
    header: 'ID',
  }),
  columnHelper.accessor('number', {
    header: dynT('activerecord.attributes.invoice.number'),
  }),
  columnHelper.accessor((original) => dayjs(original.date).format('L'), {
    header: dynT('default.date'),
    id: 'date',
  }),
  columnHelper.accessor((original) => original.clientName, {
    header: dynT('activerecord.attributes.client.name'),
    id: 'client_name',
  }),
  columnHelper.accessor('euroHtAmount', {
    header: dynT('activerecord.attributes.invoice.total_amount'),
  }),
  columnHelper.accessor('euroTtcAmount', {
    header: dynT('activerecord.attributes.invoice.total_amount_with_vat'),
  }),
];

export default factorTransferredColumns;
