import { useEffect } from 'react';

// const ENABLED_PROFILER_RAILS_ENV = ['development', 'production'];
// const { RAILS_ENV } = import.meta.env;
// const ENABLE_PROFILER =
//   RAILS_ENV && ENABLED_PROFILER_RAILS_ENV.includes(RAILS_ENV);

// Fetch the version of the assets from the environment variables
const MINI_PROFILER_ASSETS_VERSION =
  import.meta.env.VITE_MINI_PROFILER_ASSETS_VERSION ??
  '90a68676a0c0d704b4438ca3f27d46c4';

export function useProfiler(): void {
  // We are allowed to use hooks inside this conditional statement because it only depends on the environment variables
  // so it will not change during the lifecycle of the application
  if (ENABLE_PROFILER) {
    useEffect(() => {
      // if the script is already loaded, we don't need to load it again
      if (document.getElementById('mini-profiler')) {
        return;
      }

      const script = document.createElement('script');
      script.async = true;
      script.src = `/mini-profiler-resources/includes.js?v=${MINI_PROFILER_ASSETS_VERSION}`;
      script.type = 'text/javascript';
      script.id = 'mini-profiler';
      script.setAttribute(
        'data-css-url',
        `/mini-profiler-resources/includes.css?v=${MINI_PROFILER_ASSETS_VERSION}`,
      );
      script.setAttribute('data-version', MINI_PROFILER_ASSETS_VERSION);
      script.setAttribute('data-path', '/mini-profiler-resources/');
      script.setAttribute('data-horizontal-position', 'left');
      script.setAttribute('data-vertical-position', 'top');
      script.setAttribute('data-ids', '');
      script.setAttribute('data-trivial', 'false');
      script.setAttribute('data-children', 'false');
      script.setAttribute('data-max-traces', '20');
      script.setAttribute('data-controls', 'false');
      script.setAttribute('data-total-sql-count', 'false');
      script.setAttribute('data-authorized', 'true');
      script.setAttribute('data-toggle-shortcut', 'Alt+P');
      script.setAttribute('data-start-hidden', 'true');
      script.setAttribute('data-collapse-results', 'true');
      script.setAttribute('data-hidden-custom-fields', '');
      script.setAttribute('data-html-container', 'body');
      document.head.appendChild(script);
    });
  }
}

// <script
//   async="true"
//   src="/../mini-profiler-resources/includes.js?v=35a79b300ab5afa978cb59af0b05e059"
//   type="text/javascript"
//   id="mini-profiler"
//   data-css-url="/../mini-profiler-resources/includes.css?v=35a79b300ab5afa978cb59af0b05e059"
//   data-version="35a79b300ab5afa978cb59af0b05e059"
//   data-path="/../mini-profiler-resources/"
//   data-horizontal-position="left"
//   data-vertical-position="top"
//   data-ids=""
//   data-trivial="true"
//   data-children="true"
//   data-max-traces="20"
//   data-controls="false"
//   data-total-sql-count="false"
//   data-authorized="true"
//   data-toggle-shortcut="Alt+P"
//   data-start-hidden="true"
//   data-collapse-results="true"
//   data-hidden-custom-fields=""
//   data-html-container="body"
// ></script>
