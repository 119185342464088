// api/PayslipFunnelSteps::IntermediateSummaryStep.ts
import { Attr, Model } from 'spraypaint';
import type { MoneyType } from '../../../modules/common/utils/type.utils';
import PayslipFunnelBaseStep from './PayslipFunnelBaseStep';

@Model()
class PayslipFunnelIntermediateSummaryStep extends PayslipFunnelBaseStep {
  static jsonapiType = 'payslip_funnel_steps-intermediate_summary_steps';

  // Attributes
  @Attr({ persist: false }) mayComplete!: boolean;
  @Attr({ persist: false }) completed!: boolean;
  @Attr({ persist: false }) periodTotalRevenue!: MoneyType;
  @Attr({ persist: false }) totalExpensesRefund!: MoneyType;
  @Attr({ persist: false }) workingDays!: number;
  @Attr({ persist: false }) paidHolidays!: number;
  @Attr({ persist: false }) activityAccountBalanceOnFirstDay!: MoneyType;
  @Attr({ persist: false }) turnoverAmount!: MoneyType;
  @Attr({ persist: false }) totalExpensesCost!: MoneyType;
  @Attr({ persist: false }) totalExpensesCostWithoutContributions!: MoneyType;
  @Attr({ persist: false }) managementFeesAmount!: MoneyType;
  @Attr({ persist: false }) subcontractorAmount!: MoneyType;
  @Attr({ persist: false }) servicePlusAmount!: MoneyType;
  @Attr({ persist: false }) advanceAmount!: MoneyType;
  @Attr({ persist: false }) otherAmount!: MoneyType;
  @Attr({ persist: false }) balanceBeforePayslip!: MoneyType;

  // Relationships
}

export default PayslipFunnelIntermediateSummaryStep;
