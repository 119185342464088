import classNames from 'classnames';
import { flexRender, Header } from '@tanstack/react-table';
import { HeaderSortButtons } from './HeaderSortButtons';
import { HeaderFilter } from './HeaderFilter';

export type HeaderCellProps<TData, TValue> = {
  header: Header<TData, TValue>;
  className?: string;
};

export function HeaderCell<TData, TValue>({
  header,
  className,
}: HeaderCellProps<TData, TValue>) {
  const isFilterable = header.column.getCanFilter();
  const customClassName = classNames(
    'text-color-pone-dark2 uppercase text-sm flex items-center justify-center',
    {
      'justify-between pl-[45px]': isFilterable,
    },
    className,
  );

  if (header.isPlaceholder) {
    return <div className={customClassName} />;
  }

  const ColumnHeaderComponent = header.column.columnDef.header;

  return (
    <div className={customClassName}>
      <div className="flex">
        {flexRender(ColumnHeaderComponent, {
          ...header.getContext(),
        })}
        <HeaderSortButtons header={header} />
      </div>
      <HeaderFilter header={header} />
    </div>
  );
}
