import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { MoneyType } from '@/modules/common/utils/type.utils';
import {
  EqualFilter,
  getEqualFilter,
} from '../../../../common/components/table/filter/EqualFilter';
import {
  SupInfFilter,
  getSupInfFilter,
} from '../../../../common/components/table/filter/SupInfFilter';
import { SepaImport } from '../../../../../app/spraypaint';

const columnHelper = createColumnHelper<ModelRecord<SepaImport>>();

const sepaImportColumns = [
  columnHelper.accessor('id', {
    header: 'ID',
    filterFn: getEqualFilter(),
    meta: {
      filter: {
        Component: EqualFilter,
      },
    },
  }),
  columnHelper.accessor('reference', {
    header: 'Référence',
    filterFn: getEqualFilter(),
    meta: {
      filter: {
        Component: EqualFilter,
      },
    },
  }),
  columnHelper.accessor('totalAmount', {
    header: 'Montant total',
    filterFn: getSupInfFilter(),
    meta: {
      filter: {
        Component: SupInfFilter,
      },
    },
    cell: (info) => (
      <div className="text-center">
        <AmountWithCurrency amount={info.getValue() as MoneyType} />
      </div>
    ),
  }),
  columnHelper.accessor('totalEntries', {
    header: 'Nombre de fiche de paies',
    filterFn: getSupInfFilter(),
    meta: {
      filter: {
        Component: SupInfFilter,
      },
    },
  }),
  columnHelper.accessor('status', {
    header: 'Status' as string,
    enableColumnFilter: false,
  }),
];

export default sepaImportColumns;
