import type { FunctionComponent } from 'react';
import { useQuery } from '@tanstack/react-query';
import PayslipSimulation from '@spraypaint/PayslipSimulation';
import {
  ButtonSecondary,
  ButtonSecondaryMinus,
  ButtonTernary,
} from '@common/components/buttons';
import Skeleton from 'react-loading-skeleton';
import { RelativeTime } from '@common/components/RelativeTime';

const SimulationItem: FunctionComponent<{ simulation: PayslipSimulation }> = ({
  simulation,
}) => (
  <li className="py-4">
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <div className="ml-2">
          <p className="font-medium text-gray-900">{simulation.title}</p>
          <p className="text-sm text-gray-500">
            <RelativeTime date={simulation.createdAt} />
          </p>
        </div>
      </div>
      <div>
        <span className="text-green-600">{simulation.netSalary} €</span>{' '}
        <span className="text-gray-500">NET / mois</span>
      </div>
      <div className="ml-4 flex-shrink-0">
        <ButtonTernary to={`./simulations/${simulation.id}`}>
          Voir
        </ButtonTernary>
      </div>
    </div>
  </li>
);

const SkeletonSimulationList: FunctionComponent = () => (
  <div className="mt-5">
    <ul className="divide-y divide-gray-200">
      <li className="py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="ml-2">
              <p className="text-sm font-medium text-gray-900">
                <Skeleton width={200} />
              </p>
              <p className="text-sm text-gray-500">
                <Skeleton width={150} />
              </p>
            </div>
          </div>
          <div className="ml-4 flex-shrink-0">
            <Skeleton width={100} height={32} />
          </div>
        </div>
      </li>
    </ul>
  </div>
);

const SimulationList: FunctionComponent<{
  simulations: PayslipSimulation[] | undefined;
}> = ({ simulations }) => {
  if (simulations === undefined) {
    return null;
  }
  if (simulations.length === 0) {
    return (
      <ButtonSecondary to="./simulations/new" className="w-fit m-auto mt-5">
        Simuler un salaire
      </ButtonSecondary>
    );
  }

  return (
    <div className="mt-5">
      <ul className="divide-y divide-gray-200">
        {simulations.map((simulation) => (
          <SimulationItem key={simulation.id} simulation={simulation} />
        ))}
      </ul>
    </div>
  );
};

export const SimulationsWidget: FunctionComponent = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['payslip-simulations-widget'],
    queryFn: () =>
      PayslipSimulation.includes(['portage_offer'])
        .selectExtra(['payslip_computation'])
        .all(),
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const isEmpty = data?.data?.length === 0;

  return (
    <div className="lg:shadow-card lg:w-[1024px] pt-[19px] mt-12 p-8  mx-auto bg-white">
      <div className="flex flex-row justify-between">
        <h5 className="text-xl font-medium  leading-none text-gray-900">
          Simulations
        </h5>
        {!isLoading && !isEmpty && (
          <ButtonSecondaryMinus to="./simulations/new">
            Nouvelle simulation
          </ButtonSecondaryMinus>
        )}
      </div>
      {isLoading && <SkeletonSimulationList />}
      <SimulationList simulations={data?.data} />
    </div>
  );
};
