import { Scope, SpraypaintBase, WhereClause } from 'spraypaint';
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { eq, identity, isEqual } from 'lodash';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useAdvancedDebounce } from '../../../../../app/hooks/useAdvancedDebounce';
import { BackendFilterFn, PriumFilterFn } from '../filter/types';
import { GraphitiScoper } from '../../../../../app/spraypaint/types';

export function useBackendFiltering<TData extends SpraypaintBase>(
  columnFilters: ColumnFiltersState,
  table: Table<ModelRecord<TData>> | undefined,
  debounceDelay: number,
): [scoper: GraphitiScoper<TData>, key: any] {
  const whereClauses: WhereClause[] = useMemo(
    () =>
      columnFilters.flatMap((filter) => {
        if (!table) return [];

        const column = table.getColumn(filter.id);
        const filterFn: PriumFilterFn<ModelRecord<TData>> | undefined =
          column?.getFilterFn();

        const resolver = filterFn?.resolveFilterValue ?? identity;
        const filterValue = resolver(filter.value);

        const backendFilterFn: BackendFilterFn =
          filterFn?.backendFilterFn ??
          ((columnId, value) => ({ [columnId]: { match: value } }));

        const whereClause = backendFilterFn(filter.id, filterValue);

        return !whereClause || eq(whereClause, {}) ? [] : whereClause;
      }),
    [columnFilters],
  );

  const applyFiltering: GraphitiScoper<TData> = useCallback(
    (baseScope) =>
      whereClauses.reduce<Scope<TData>>(
        (currentScope, whereClause) => currentScope.where(whereClause),
        baseScope,
      ),
    [whereClauses],
  );

  const result: [scoper: GraphitiScoper<TData>, key: any] = [
    applyFiltering,
    whereClauses,
  ];

  return useAdvancedDebounce(result, {
    delay: debounceDelay,
    isEqual: (a, b) => isEqual(a && a[1], b && b[1]),
  });
}
