import { FunctionComponent, memo } from 'react';
import IdentityStep from '@spraypaint/onBoarding/IdentityStep';
import { TextInput } from '@common/components/inputs';
import { MainCard } from '@common/components/mainCard';
import { useForm } from 'react-hook-form';
import { ControlledSelect } from '@common/components/inputs/controlledInput/ControlledSelect';
import { ControlledDateInput } from '@common/components/inputs/controlledInput/ControlledDateInput';
import { ButtonPrimary } from '@common/components/buttons';
import { useCountries } from '@common/country/useCountries';
import useCivilities from '@common/hooks/useCivilities';
import { useTranslation } from 'react-i18next';
import {
  IdentityStepFormState,
  IdentityStepType,
} from '@/modules/welcome/types/OBIdentityStepType';
import { useValidation } from '@/app/form/ValidationForm';

// The OBIdentityStep component is wrapped with React.memo to optimize performance.
// React.memo will prevent re-renders of the component unless the props change.
// This is beneficial when the props are complex or change infrequently.
const OBIdentityStep: FunctionComponent<IdentityStepType> = memo(
  ({ onboardingFunnel, saveThenTransition }) => {
    const { t } = useTranslation();
    const { identityStep } = onboardingFunnel;

    const initialValues: IdentityStepFormState = {
      firstName: identityStep.firstName,
      lastName: identityStep.lastName,
      civility: identityStep.civility,
      birthdate: identityStep.birthdate,
      birthCountryId: String(identityStep.birthCountryId),
    };

    const Validation = useValidation();

    const {
      register,
      formState: { isSubmitting, errors },
      handleSubmit,
      control,
    } = useForm<IdentityStepFormState>({
      defaultValues: initialValues,
    });

    const { countriesOptions } = useCountries();
    const { civilities } = useCivilities();

    const onSubmit = (data: IdentityStepFormState) => {
      saveThenTransition({
        data,
        transitionName: 'complete_identity_step',
        stepToSave: new IdentityStep({
          id: identityStep.id,
          waiting_for_social_security_number: true,
        }),
      });
    };

    return (
      <MainCard
        header={t('front.welcome.onboarding.steps.identityStep')}
        className="mt-[20px] mx-5 md:w-[800px]"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-1">
            <div className="flex-col">
              <TextInput
                className="m-3"
                required
                {...register('firstName', Validation.anyRequired)}
                label={t('activerecord.attributes.user.first_name')}
                errorText={{
                  type: 'error',
                  message: errors.firstName
                    ? errors.firstName.message ||
                      t('views.shared.field_required_error')
                    : undefined,
                }}
              />
              <TextInput
                className="m-3"
                required
                {...register('lastName', Validation.anyRequired)}
                label={t('activerecord.attributes.user.last_name')}
                errorText={{
                  type: 'error',
                  message: errors.lastName
                    ? errors.lastName.message ||
                      t('views.shared.field_required_error')
                    : undefined,
                }}
              />
              <ControlledSelect
                name="civility"
                labelClassName="flex-1"
                control={control}
                displayCol
                className="m-3"
                label={t('activerecord.attributes.user.civility')}
                options={civilities}
                errorText={
                  errors?.civility ? errors.civility.message : undefined
                }
              />
              <ControlledDateInput
                required
                control={control}
                className="m-3"
                {...register('birthdate')}
                label={t('activerecord.attributes.user.birth_date')}
              />
              <ControlledSelect
                name="birthCountryId"
                required
                displayCol
                className="m-3"
                labelClassName="flex-1"
                control={control}
                label={t('activerecord.attributes.user.birth_country')}
                options={countriesOptions}
                isSearchable
                isClearable
                errorText={
                  errors?.birthCountryId
                    ? errors?.birthCountryId?.message ||
                      t('views.shared.field_required_error')
                    : undefined
                }
              />
            </div>
          </div>
          <div className="flex justify-end ml-[20px] mt-[20px]">
            <ButtonPrimary
              className="mr-5"
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              {t('default.submit')}
            </ButtonPrimary>
          </div>
        </form>
      </MainCard>
    );
  },
);

export { OBIdentityStep };
