// api/Sepa::TransactionAssignation.ts
import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class FactorTransactionAssignation extends ApplicationRecord {
  static jsonapiType = 'factor-transaction_assignations';

  // Attributes
  @Attr() transactionId!: number;
  @Attr() transactionType!: string;
  @Attr() associableIds!: Array<number>;
  @Attr() associableType!: string;

  // Relationships
}

export default FactorTransactionAssignation;
