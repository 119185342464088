import { useParams } from 'react-router-dom';

import { MainCard } from '@/modules/common/components/mainCard';
import { RegisteredClientAgent, User } from '@/app/spraypaint';
import UserForm, { UserFormValues } from '@/modules/users/components/UserForm';
import { toast } from 'react-toastify';
import { Toaster } from '@/modules/common/components/toasts';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '@/modules/common/components/PageLayout';

const RegisteredClientAgentNew = () => {
  const { registeredClientId } = useParams();
  const { t } = useTranslation();

  const registeredClientAgent = new RegisteredClientAgent({
    user: new User(),
    registeredClientId,
  });

  const onSubmit = async (data: UserFormValues) => {
    registeredClientAgent.user.firstName = data.firstName;
    registeredClientAgent.user.lastName = data.lastName;
    registeredClientAgent.user.email = data.email;
    const success = await registeredClientAgent.save({ with: 'user' });
    if (success) {
      toast.success(
        <Toaster
          textMsg={t('views.shared.change_applied')}
          toastType="success"
        />,
      );
    }
  };

  return (
    <PageLayout Title={"Création d'un accès pour client grand compte"}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <MainCard header="Créer un accès">
          <UserForm user={registeredClientAgent.user} onSubmit={onSubmit} />
        </MainCard>
      </div>
    </PageLayout>
  );
};
export default RegisteredClientAgentNew;
