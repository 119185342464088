import { useQuery } from '@tanstack/react-query';
import { User } from '@/app/spraypaint';

const useUser = (id: number | string) => {
  const data = useQuery({
    queryKey: ['user', id],
    queryFn: () => User.find(id),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const user = data?.data?.data;

  return { ...data, user };
};

export default useUser;
