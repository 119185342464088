import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { onRowClickNavigate } from '@/app/navigation/functions/onRowClickNavigate';
import RegisteredClient from '@/app/spraypaint/RegisteredClient';
import Table from '@/modules/common/components/table/Table';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import useRegisteredClients from '../hooks/useRegisteredClients';
import registeredClientsIndexColumns from '../functions/registeredClientsIndexColumns';

const RegisteredClientsIndex = () => {
  const navigate = useNavigate();
  const [scope, scopeKey, props] = useBackendTable<RegisteredClient>();
  const { registeredClients, isLoading, totalCount } = useRegisteredClients(
    scope,
    scopeKey,
  );
  const { t } = useTranslation();

  const onRowClick =
    (raw: ModelRecord<RegisteredClient> | undefined) =>
    (event: React.MouseEvent) => {
      onRowClickNavigate({
        id: `${raw?.id}`,
        event,
        windowNavigationHref: `registered_clients/${raw?.id}`,
        navigate,
      });
    };

  return (
    <div className="mt-[50px]">
      <div className="flex justify-between px-[20px]">
        <p className="big-title">{t('front.menu.registered_clients')}</p>
      </div>
      <Table
        emptyTableErrorMsg="react_table.no_data_text"
        onRowClick={onRowClick}
        className="mt-[26px]"
        isLoading={isLoading}
        withImageErrorMsg={false}
        totalCount={totalCount}
        data={registeredClients || []}
        reactTableProps={props}
        columns={registeredClientsIndexColumns()}
      />
    </div>
  );
};

export default RegisteredClientsIndex;
