// api/Invoice.ts
import { Attr, BelongsTo, HasOne, Model } from 'spraypaint';
import type { MoneyType, PeriodType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';
import type Employee from './Employee';
import type ContribPercoDeposit from './ContribPercoDeposit';
import type ContribPeeDeposit from './ContribPeeDeposit';

export type StatusEnum =
  | 'in_progress'
  | 'submitted'
  | 'validated'
  | 'rejected'
  | 'signature_procedure_started';
export type MethodOfPaymentEnum = 'wire' | 'cheque';

@Model()
class ContribDeposit extends ApplicationRecord {
  static jsonapiType = 'contrib_deposits';

  // Attributes
  @Attr() employeeId!: number;
  @Attr() remainingAmount!: MoneyType;
  @Attr() amount!: MoneyType;
  @Attr() deposited!: boolean;
  @Attr() comment!: string;
  @Attr() methodOfPayment!: MethodOfPaymentEnum;
  @Attr() status!: StatusEnum;
  @Attr() createdAt!: string;
  @Attr() updatedAt!: string;
  @Attr() period!: PeriodType;
  @Attr() number!: string;
  @Attr() contribType!: string;
  @Attr() contribId!: number;

  // Relationships
  @BelongsTo('employee') employee!: Employee;
  @HasOne({ name: 'contrib' }) contrib!:
    | ContribPeeDeposit
    | ContribPercoDeposit;

  get employeePagePath(): string | undefined {
    const { contribId, employeeId } = this;
    const subPath =
      this.contribType === 'ContribPeeDeposit'
        ? 'contrib_pee_deposits'
        : 'contrib_perco_deposits';

    if (!employeeId || !contribId) {
      return undefined;
    }

    return `/employees/${employeeId}/${subPath}/${contribId}`;
  }

  // Use a method to comply with the expected interface
  // eslint-disable-next-line class-methods-use-this
  get associableType(): string {
    return 'ContribDeposit';
  }
}

export default ContribDeposit;
