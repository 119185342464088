import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { MainCard } from '../../common/components/mainCard';
import { ButtonPrimary } from '../../common/components/buttons';
import { PerkType } from '../types/PerkType';

export const PerkCard = ({ title, description, url, image }: PerkType) => {
  const { t } = useTranslation();

  const trackEvent = (eventName: string) => {
    console.debug(`${eventName} for perk ${title}`);

    mixpanel.track(eventName, {
      perkImage: title,
    });
  };

  const handleButtonClick = () => {
    trackEvent('Perks: Button clicked');
  };

  return (
    <MainCard header={title}>
      <div className="flex-col justify-between">
        <p className="mx-3 mb-3">{description}</p>
        <div className="flex justify-center my-5">
          <img src={image} alt={title} />
        </div>
        <div className="mt-5 flex justify-end">
          <ButtonPrimary href={url} onClick={handleButtonClick} openInNewTab>
            {t('front.perk.card.button.label')}
          </ButtonPrimary>
        </div>
      </div>
    </MainCard>
  );
};
