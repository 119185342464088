import { useTranslation } from 'react-i18next';
import { Button } from '@/modules/common/components/buttons';
import { AutoFillIcon } from '../../../app/assets/icons/payslipFunnel';

type Props = {
  onClick: () => void;
};

export const AutofillButton = ({ onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Button
      custom
      onClick={onClick}
      className="flex fill-color-pone-orange items-center"
    >
      <>
        <AutoFillIcon height={24} width={30} />
        <p className="text-sm2 text-color-pone-dark font-bold ml-[8px]">
          {t('front.payslip.tdc.cra.autofill')}
        </p>
      </>
    </Button>
  );
};
