import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from '../ApplicationRecord';
import type FactorTransferItem from './FactorTransferItem';
import type FactoringContract from './FactoringContract';
import type FactorTransferred from './FactorTransferred';

@Model()
class FactorTransfer extends ApplicationRecord {
  static jsonapiType = 'factor-transfers';

  @Attr() factorFactoringContractId!: number;
  @Attr() csv!: string;
  @Attr({ persist: false }) createdAt!: Date;
  @Attr({ persist: false }) updatedAt!: Date;
  @Attr() totalInvoiceAmount!: MoneyType;
  @Attr() totalCreditAmount!: MoneyType;
  @Attr() transferredsCount!: number;

  @HasMany('factor-transfer_items')
  factorTransferItems!: Array<FactorTransferItem>;
  @HasMany('factor-transferreds')
  transferreds!: Array<FactorTransferred>;
  @BelongsTo('factor-factoring_contracts')
  factoringContract!: FactoringContract;
}

export default FactorTransfer;
