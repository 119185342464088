// api/CapGeminiConsolidationInvoice.ts
import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import type { Invoice, FrameworkContract } from '@/app/spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type CapGeminiConsolidationImport from './CapGeminiConsolidationImport';
import type CapGeminiConsolidationInvoiceItemImport from './CapGeminiConsolidationInvoiceItemImport';

export const IMPORT_STATUSES = ['ok', 'warning', 'error', 'pending'] as const;
export type ImportStatusEnum = typeof IMPORT_STATUSES[number];

@Model()
class CapGeminiConsolidationInvoiceImport extends ApplicationRecord {
  static jsonapiType = 'capgemini-consolidation_invoice_imports';

  // Attributes
  @Attr() importId!: string;
  @Attr() externalReference!: string;
  @Attr() status!: string;
  @Attr() operatingUnitName!: string;
  @Attr() totalAmount!: MoneyType;
  @Attr() lastDate: string;
  @Attr() tundraCommercialContractId: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;

  // Extra-attributes
  @Attr() importStatus!: ImportStatusEnum;
  @Attr() importErrors!: string[];
  @Attr() importWarnings!: string[];
  @Attr() tundraInvoiceTotalAmount!: MoneyType | null;

  // Relationships
  @BelongsTo('capgemini-consolidation_imports')
  import!: CapGeminiConsolidationImport;

  @BelongsTo('invoices') invoice!: Invoice;
  @BelongsTo('invoices') tundraInvoice!: Invoice;

  @BelongsTo('framework_contract') frameworkContract!: FrameworkContract;

  @HasMany('capgemini-consolidation_invoice_item_imports')
  invoiceItemImports!: Array<CapGeminiConsolidationInvoiceItemImport>;
}

export default CapGeminiConsolidationInvoiceImport;
