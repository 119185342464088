import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useTranslation } from 'react-i18next';
import { MultipartOperationOperation } from '../../../../app/spraypaint';
import Table from '../../../common/components/table/Table';
import { useBackendTable } from '../../../common/components/table/hooks';
import { IconButtonSecondary } from '../../../common/components/buttons';
import { IcnVisibility } from '../../../../app/assets/icons/payslip';

const columnHelper =
  createColumnHelper<ModelRecord<MultipartOperationOperation>>();
const columns = [
  columnHelper.accessor('createdAt', {
    header: () => {
      const { t } = useTranslation();
      return t('front.multipart_operations-operation.fields.createdAt');
    },
  }),
  columnHelper.accessor('name', {
    header: () => {
      const { t } = useTranslation();
      return t('front.multipart_operations-operation.fields.name');
    },
  }),
  columnHelper.display({
    header: 'Actions',
    id: 'actions',
    cell: ({ row: { original } }) => (
      <IconButtonSecondary
        className="mx-4"
        Icon={IcnVisibility}
        to={original.id as string}
      />
    ),
  }),
];

function MultipartOperationIndex(): JSX.Element {
  const [scope, scopeKey, props] = useBackendTable<MultipartOperationOperation>(
    { initialSorting: [{ id: 'createdAt', desc: true }] },
  );

  const { data, isLoading } = useQuery(['operations', scopeKey], () =>
    scope(MultipartOperationOperation.scope()).all(),
  );

  return (
    <Table
      title={"Rapports d'operations"}
      isLoading={isLoading}
      totalCount={data?.meta?.stats?.total?.count}
      data={data?.data || []}
      reactTableProps={props}
      onRowClick="navigate"
      columns={columns}
    />
  );
}

export default MultipartOperationIndex;
