import { useTranslation } from 'react-i18next';

const useWorkingTimeUnitOptions = () => {
  const { t } = useTranslation();

  const workingTimeUnitsOptions = [
    {
      label: t('views.payslip_simulation.working_time_unit.day'),
      value: 'day',
    },
    {
      label: t('views.payslip_simulation.working_time_unit.hour'),
      value: 'hour',
    },
  ];

  return { workingTimeUnitsOptions };
};
export default useWorkingTimeUnitOptions;
