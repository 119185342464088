import { FlagFR, FlagUK } from '../../assets/icons/flags';
import { LanguagesType } from '../components';

export const supportedLanguages: LanguagesType = [
  {
    key: 'fr',
    Flag: <FlagFR data-testid="fr-flag" className="w-[26px] h-[18px]" />,
  },
  {
    key: 'en',
    Flag: <FlagUK data-testid="uk-flag" className="w-[26px] h-[18px]" />,
  },
];
