import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import { FactorClientPaymentFile } from '../../../../../app/spraypaint';

export const useGetFactorClientPaymentFiles = (
  scopeKey: Array<unknown>,
  scope: (
    baseScope: Scope<FactorClientPaymentFile>,
  ) => Scope<FactorClientPaymentFile>,
) => {
  const data = useQuery({
    queryKey: ['factor-client_payment_files', ...scopeKey],
    queryFn: () => scope(FactorClientPaymentFile.scope()).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const factorClientPaymentFiles = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, factorClientPaymentFiles, totalCount };
};
