import FactorOutstandingInvoice from '@/app/spraypaint/factor/FactorOutstandingInvoice';
import { dynT } from '@/modules/common/components/dynT';
import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';

const columnHelper =
  createColumnHelper<ModelRecord<FactorOutstandingInvoice>>();

export const outstandingInvoiceColumns = [
  columnHelper.accessor('authorization.registeredClient.name', {
    header: dynT(
      'front.factor.authorization.index.columns.registered_client_name',
    ),
  }),
  columnHelper.accessor('buyerCode', {
    header: dynT('front.factor.outstanding_invoice.index.columns.buyer_code'),
  }),
  columnHelper.accessor('clientCode', {
    header: dynT('front.factor.outstanding_invoice.index.columns.client_code'),
  }),
  columnHelper.accessor('invoiceNumber', {
    header: dynT(
      'front.factor.outstanding_invoice.index.columns.invoice_number',
    ),
  }),
  columnHelper.accessor('invoiceDate', {
    header: dynT('front.factor.outstanding_invoice.index.columns.invoice_date'),
  }),
  columnHelper.accessor('invoiceAmount', {
    header: dynT(
      'front.factor.outstanding_invoice.index.columns.invoice_amount',
    ),
  }),
  columnHelper.accessor('paidAmount', {
    header: dynT('front.factor.outstanding_invoice.index.columns.paid_amount'),
  }),
  columnHelper.accessor('remainingAmount', {
    header: dynT(
      'front.factor.outstanding_invoice.index.columns.remaining_amount',
    ),
  }),
  columnHelper.accessor('outstandingReceivable', {
    header: dynT(
      'front.factor.outstanding_invoice.index.columns.outstanding_receivable',
    ),
  }),
];
