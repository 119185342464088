import { LoaderPage } from '../../../app/navigation/components/LoaderPage';
import { CraCategorieType } from '../../../app/spraypaint/payslipFunnel/PayslipFunnelCraStep';
import { CalendarCra } from '../../payslip_funnel/CRA/components';
import { CalendarCraInfo } from '../../payslip_funnel/CRA/components/CalendarCraInfo';
import { LeftSectionCra } from '../../payslip_funnel/CRA/components/LeftSectionCra';
import { CraMaxDaysInfoType } from '../../payslip_funnel/CRA/hooks';
import { DateWithActivityReportType } from '../../payslip_funnel/CRA/utils/getActivityReportSlots';

type Props =
  | {
      setSelectedType: (type: CraCategorieType | undefined) => void;
      selectedType: CraCategorieType | undefined;
      availableSlotTypes: string[] | undefined;
      expensesDates: string[];
      craMaxDaysInfos?: CraMaxDaysInfoType;
      firstDayOfTheMonth: Date;
      updateActivityReportSlot: ((date: Date) => () => void) | undefined;
      dateWithActivityReportSlots: DateWithActivityReportType;
      isLoading?: boolean;
      rangeDates?: [string, string];
    }
  | {
      setSelectedType?: undefined;
      selectedType: CraCategorieType | undefined;
      availableSlotTypes: string[] | undefined;

      expensesDates: string[];
      craMaxDaysInfos?: CraMaxDaysInfoType;
      firstDayOfTheMonth: Date;
      updateActivityReportSlot?: undefined;
      dateWithActivityReportSlots: DateWithActivityReportType;
      isLoading?: boolean;
      rangeDates?: [string, string];
    };

export const CraCalendar = ({
  setSelectedType,
  selectedType,
  availableSlotTypes,
  expensesDates,
  dateWithActivityReportSlots,
  updateActivityReportSlot,
  firstDayOfTheMonth,
  craMaxDaysInfos,
  rangeDates,
  isLoading,
}: Props) => {
  if (isLoading) return <LoaderPage className="h-[500px]" />;
  return (
    <div className="w-full flex bg-color-transparent-medium-grey py-[15px] mt-[2px]">
      <LeftSectionCra
        onSelectedTypeChange={setSelectedType}
        selectedType={selectedType}
        availableSlotTypes={availableSlotTypes}
        expensesDates={expensesDates}
      />
      <CalendarCra
        rangeDates={rangeDates}
        expensesDates={expensesDates}
        dateWithActivityReportSlots={dateWithActivityReportSlots}
        updateActivityReportSlot={updateActivityReportSlot}
        month={firstDayOfTheMonth.getMonth()}
        className="ml-[65px]"
      />
      <CalendarCraInfo craMaxDaysInfos={craMaxDaysInfos} />
    </div>
  );
};
