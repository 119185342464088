import { BelongsTo, Model, Attr, HasMany } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type LegalEntity from '../LegalEntity';
import type FactorClientPaymentTransaction from './FactorClientPaymentTransaction';
import type FactoringContract from './FactoringContract';

@Model()
class FactorClientPaymentFile extends ApplicationRecord {
  static jsonapiType = 'factor-client_payment_files';

  @Attr() sourceFileSignedId!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) filename!: string;
  @Attr({ persist: false }) link!: string;
  @Attr({ persist: false }) matchingStatus!: string;
  @Attr({ persist: false }) fileGeneratedAt!: string;

  @HasMany('factor-client_payment_transactions')
  clientPaymentTransactions!: Array<FactorClientPaymentTransaction>;
  @BelongsTo('legal_entities') legalEntity!: LegalEntity;
  @BelongsTo('factoring_contract') factoringContract!: FactoringContract;

  // Methods
  async fetchRefreshMatching() {
    return this.fetchMemberAction('post', 'refresh_matching');
  }

  get adminShowPagePath(): string | undefined {
    const { id } = this;

    if (!id) {
      return undefined;
    }

    return `/v2/admin/factor_reconciliation/client_payment_files/${id}`;
  }
}

export default FactorClientPaymentFile;
