import { useTranslation } from 'react-i18next';

export const dateFilterOptions = [
  { label: 'Toutes', value: 'all' },
  { label: 'Moins de 24h', value: 'less_than_24h' },
  { label: 'Entre 24h et 48h', value: 'between_24_and_48h' },
  { label: 'Entre 48h et 72h', value: 'between_48_and_72h' },
  { label: 'Au delà de 72h', value: 'more_than_72h' },
];

export const monthsFilterOptions = (): Array<{
  label: string;
  value: string;
}> => {
  const { t } = useTranslation();

  return Array.from({ length: 12 }, (_, i) => ({
    label: t(`months.${i}`),
    value: (i + 1).toString(),
  }));
};

export const statusFilterOptions = () => {
  const { t } = useTranslation();
  return [
    { label: t('default.status_enum.in_progress'), value: 'in_progress' },
    { label: t('default.status_enum.submitted'), value: 'submitted' },
    { label: t('default.status_enum.validated'), value: 'validated' },
    { label: t('default.status_enum.closed'), value: 'closed' },
  ];
};
