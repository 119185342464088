import { FunctionComponent, useState } from 'react';
import { CapGeminiConsolidationInvoiceImport } from '@/app/spraypaint';
import { ConsolidationInvoiceItems } from '@/modules/admin/capgemini/components/ConsolidationInvoiceItems';
import { CorporateInvoiceCreationModal } from '@/modules/admin/capgemini/components/CoporateInvoiceCreationModal';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { buildCapGeminiImportFindQueryKeyPrefix } from '@/modules/admin/capgemini/functions/capGeminiImportQuery';
import { ConsolidationInvoiceAttributes } from '@/modules/admin/capgemini/components/ConsolidationInvoiceAttributes';
import { TundraInvoiceCreationModal } from './TundraInvoiceCreationModal';

export interface ConsolidationInvoiceCardContentProps {
  consolidationInvoiceImport: CapGeminiConsolidationInvoiceImport;
}

export const ConsolidationInvoiceCardContent: FunctionComponent<
  ConsolidationInvoiceCardContentProps
> = ({ consolidationInvoiceImport }) => {
  const [capPriumInvModalOpened, setPriumInvModalOpened] = useState(false);
  const [capTundraInvModalOpened, setTundraInvModalOpened] = useState(false);

  const { capGeminiImportId } = useParams();
  const queryClient = useQueryClient();

  const queryKey =
    capGeminiImportId &&
    buildCapGeminiImportFindQueryKeyPrefix(capGeminiImportId);

  return (
    <>
      <CorporateInvoiceCreationModal
        consolidationInvoiceImport={consolidationInvoiceImport}
        modalProps={{
          isOpen: capPriumInvModalOpened,
          onRequestClose: () => setPriumInvModalOpened(false),
        }}
        afterSubmit={async () => {
          setPriumInvModalOpened(false);
          if (queryKey) {
            await queryClient.invalidateQueries({ queryKey });
          }
        }}
      />
      <TundraInvoiceCreationModal
        consolidationInvoiceImport={consolidationInvoiceImport}
        modalProps={{
          isOpen: capTundraInvModalOpened,
          onRequestClose: () => setTundraInvModalOpened(false),
        }}
        afterSubmit={async () => {
          setTundraInvModalOpened(false);
          if (queryKey) {
            await queryClient.invalidateQueries({ queryKey });
          }
        }}
      />
      <ConsolidationInvoiceAttributes
        consolidationInvoiceImport={consolidationInvoiceImport}
        openPriumInvoiceModal={() => setPriumInvModalOpened(true)}
        openTundraInvoiceModal={() => setTundraInvModalOpened(true)}
      />
      <ConsolidationInvoiceItems
        consolidationInvoiceImport={consolidationInvoiceImport}
      />
    </>
  );
};
