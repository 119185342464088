import { Attr, Model } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';

@Model()
class ActivityDomain extends ApplicationRecord {
  static jsonapiType = 'activity_domains';

  // Attributes
  @Attr() name!: string;
}

export default ActivityDomain;
