import { forwardRef, useState } from 'react';
import classNames from 'classnames';
import { TextInputProps, TextInput } from './TextInput';
import {
  LockOutlineIcon,
  VisibilityIconOff,
  VisibilityIconOn,
} from '../../../../app/assets/icons/inputs';

export type PasswordInputProps = TextInputProps;

export const PasswordInput = forwardRef(
  (
    {
      placeholder,
      className,
      errorText,
      testID = 'password-input',
      inputContainerClassName,
      ...props
    }: PasswordInputProps,
    ref,
  ) => {
    const [showPassowrd, setShowPassowrd] = useState(false);
    const type = showPassowrd ? 'text' : 'password';

    const RightIcon = showPassowrd ? VisibilityIconOn : VisibilityIconOff;

    const handleOnClick = () => setShowPassowrd(!showPassowrd);

    const customClassName = classNames('flex items-center', className);

    const customInputContainerClassName = classNames(
      'w-[310px]',
      inputContainerClassName,
    );

    return (
      <div className={customClassName}>
        <TextInput
          ref={ref}
          placeholder={placeholder}
          errorText={errorText}
          testID={testID}
          type={type}
          inputContainerClassName={customInputContainerClassName}
          LeftIcon={<LockOutlineIcon className="absolute left-3.5 top-2.5" />}
          RightInsideIcon={
            <button
              type="button"
              onClick={handleOnClick}
              className="fill-color-medium-grey"
            >
              <RightIcon
                width={16}
                height={16}
                className="absolute right-4 top-2.5"
                data-testid="right-icon-test-id"
              />
            </button>
          }
          {...props}
        />
      </div>
    );
  },
);

PasswordInput.defaultProps = {};
