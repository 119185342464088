import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import mixpanel, { Dict } from 'mixpanel-browser';
import { ButtonPrimary } from '../../common/components/buttons';
import {
  useToggleMiniTutorial,
  useToggleTutorial,
} from '../../widgets/hooks/ToggleTutorial';
import { SummaryTutorial } from '../components/tutorials/SummaryTutorial';
import { usePayslipFunnelTransition } from '../hooks/usePayslipFunnelTransition';
import { CommonMiniTutorial } from '../components/tutorials/miniTutorials/CommonMiniTutorial';
import { MinitutorialOff } from '../components/tutorials/components/MinitutorialOff';
import { MiniTutorialIntermediateSummary } from '../components/tutorials/miniTutorials/MiniTutorialIntermediateSummary';
import { IntermediateSummaryCard } from './components/IntermediateSummaryCard';
import { BalanceDisplay } from './components/BalanceDisplay';
import { useGetIntermediateSummaryData } from './hooks/useGetIntermediateSummaryData';
import { ClassificationType } from './types/ClassificationType';
import { BalanceDisplayModal } from './components/BalanceDisplayModal';
import { getMonthName } from '../../common/utils/date.utils';
import { getTranslationKey } from '../../common/utils/translation.utils';
import { PeriodType } from '../../common/utils/type.utils';
import { useCurrentEmployeeId } from '../../../app/hooks/useCurrentEmployeeId';
import { tutorialContainerCustomClass } from '../constantes/tailwindStyle';
import StepMessages from '../components/StepMessages';

type Props = {
  payslipFunnelId: number;
  currentPayslipFunnelPeriod: PeriodType;
};

export const IntermediateSummaryStep = ({
  payslipFunnelId,
  currentPayslipFunnelPeriod,
}: Props) => {
  const { t } = useTranslation();

  const [displayModal, setDisplayModal] = useState(false);

  const [classification, setClassification] = useState<
    ClassificationType | undefined
  >(undefined);
  const handleSetDisplayModal = () => setDisplayModal(true);
  const handleSetCloseModal = () => setDisplayModal(false);
  const employeeId = useCurrentEmployeeId();

  const craMonth = getMonthName(
    currentPayslipFunnelPeriod.year,
    currentPayslipFunnelPeriod.month,
  );

  const { isTutorialOn, toggleAllTutorial } = useToggleTutorial();
  const { intermediateSummaryStepData, isLoading } =
    useGetIntermediateSummaryData(payslipFunnelId);

  const { toggleMiniTutorial, isMiniTutorialOn } = useToggleMiniTutorial();

  const [didUserValidateTutorial, setUserValidateTutorial] =
    useState(isTutorialOn);

  const handleOnClick = () => {
    setUserValidateTutorial(false);
    toggleAllTutorial();
  };

  const { mutate: validateIntermediateSummaryStep, isLoading: mutationLoader } =
    usePayslipFunnelTransition({ payslipFunnelId });

  const handleValidateIntermediateSummaryStep = () =>
    validateIntermediateSummaryStep({
      transitionName: 'complete_intermediate_summary_step',
    });

  if (didUserValidateTutorial) {
    return <SummaryTutorial onClick={handleOnClick} />;
  }

  if (isLoading) return null;

  const workedDaysLabelKey = getTranslationKey(
    'front.payslip.tdc.intermediateSummary.summaryCard.workedDaysLabel',
    intermediateSummaryStepData?.workingDays ?? 0,
  );

  const daysOffLabelKey = getTranslationKey(
    'front.payslip.tdc.intermediateSummary.summaryCard.daysOffLabel',
    intermediateSummaryStepData?.paidHolidays ?? 0,
  );

  const handleMixpanelIntermediateSummaryTracking = (
    name: string,
    extraParams?: Dict,
  ) => {
    mixpanel.track(name, {
      employee_id: employeeId,
      payslip_funnel_id: payslipFunnelId,
      period_total_revenue: intermediateSummaryStepData?.periodTotalRevenue,
      total_expenses_refund: intermediateSummaryStepData?.totalExpensesRefund,
      working_days: intermediateSummaryStepData?.workingDays ?? 0,
      paid_holidays: intermediateSummaryStepData?.paidHolidays ?? 0,
      balance_before_payslip:
        intermediateSummaryStepData?.activityAccountBalanceOnFirstDay,
      ...extraParams,
    });
  };

  const handleSetClassification =
    (newClassification: ClassificationType) => () => {
      handleMixpanelIntermediateSummaryTracking(
        'TDS: Summary Details Level 3 Deployed',
        {
          summary_details_section: newClassification,
        },
      );
      setClassification(newClassification);
      handleSetDisplayModal();
    };

  return (
    <div className="flex background-color-transparent-medium-grey px-[30px] pb-[60px]">
      <div className="w-full">
        <div className={tutorialContainerCustomClass}>
          {isMiniTutorialOn ? (
            <CommonMiniTutorial
              Component={<MiniTutorialIntermediateSummary />}
              onClick={toggleMiniTutorial}
            />
          ) : (
            <MinitutorialOff onClick={toggleMiniTutorial} />
          )}
          <StepMessages step={intermediateSummaryStepData} />
          <ButtonPrimary
            className="w-[210px] ml-3 md:ml-0 mt-2 md:mt-0"
            onClick={handleValidateIntermediateSummaryStep}
            isLoading={mutationLoader}
          >
            {t('front.payslip.tdc.intermediateSummary.button.title')}
          </ButtonPrimary>
        </div>
        <div className="flex flex-col md:flex-row justify-between mt-[41px] md:w-full">
          <IntermediateSummaryCard
            craMonth={craMonth}
            craTitle={t(
              'front.payslip.tdc.intermediateSummary.summaryCard.craTitle',
            )}
            workedDays={intermediateSummaryStepData?.workingDays as number}
            workedDaysLabel={t(workedDaysLabelKey)}
            daysOff={intermediateSummaryStepData?.paidHolidays}
            daysOffLabel={t(daysOffLabelKey) as string}
            invoiceTitle={
              t(
                'front.payslip.tdc.intermediateSummary.summaryCard.invoiceTitle',
              ) as string
            }
            invoicesTotalAmount={
              intermediateSummaryStepData?.periodTotalRevenue || {
                amount: 0,
                currency: 'EUR',
              }
            }
            expenseTitle={
              t(
                'front.payslip.tdc.intermediateSummary.summaryCard.expenseTitle',
              ) as string
            }
            totalExpensesToReimburse={
              intermediateSummaryStepData?.totalExpensesRefund || {
                amount: 0,
                currency: 'EUR',
              }
            }
            supText="HT"
          />
          <BalanceDisplay
            handleMixpanelIntermediateSummaryTracking={
              handleMixpanelIntermediateSummaryTracking
            }
            currentPayslipFunnelPeriod={currentPayslipFunnelPeriod}
            handleSetClassification={handleSetClassification}
            intermediateSummaryStepData={intermediateSummaryStepData}
          />
          <BalanceDisplayModal
            payslipFunnelId={payslipFunnelId}
            classification={classification as ClassificationType}
            displayModal={displayModal}
            handleSetCloseModal={handleSetCloseModal}
          />
        </div>
      </div>
    </div>
  );
};
