import type { FunctionComponent } from 'react';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { pick } from 'lodash';
import { TextInput } from '@/modules/common/components/inputs';
import {
  FormWizardButtons,
  useFormWizardContext,
} from '@/modules/common/components/wizard';
import { MainCard } from '@/modules/common/components/mainCard';
import { Button } from '@/modules/common/components/buttons';
import { ControlledSelect } from '@/modules/common/components/inputs/controlledInput/ControlledSelect';
import { CURRENCY_ISO_CODES } from '@/modules/common/utils/type.utils';
import { invoiceDraftWizardContext } from '../../constants';
import { FormState } from '../../types';

type PriceFormValues = Pick<
  FormState,
  'currencyIsoCode' | 'externalReference' | 'invoiceDraftLines'
>;

const currencyIsoCodeOptions = CURRENCY_ISO_CODES.map((code) => ({
  label: code,
  value: code,
}));

export const PriceForm: FunctionComponent = () => {
  const { currentFormValues, onSubmit, currentPage } = useFormWizardContext(
    invoiceDraftWizardContext,
  );

  const { handleSubmit, register, setValue, getValues, watch, control } =
    useForm({
      defaultValues: pick(currentFormValues, [
        'currencyIsoCode',
        'externalReference',
        'invoiceDraftLines',
      ]) as PriceFormValues,
    });

  const invoiceDraftLines = watch('invoiceDraftLines') || [];

  const addLine = useCallback(() => {
    setValue('invoiceDraftLines', [
      ...invoiceDraftLines,
      {
        _tempId: String(Date.now()),
        label: 'test',
        unitPrice: { amount: 0 },
        quantity: 0,
      },
    ]);
  }, [getValues, setValue, invoiceDraftLines]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainCard header={currentPage.title}>
        <ControlledSelect
          required
          control={control}
          name="currencyIsoCode"
          options={currencyIsoCodeOptions}
          defaultValue="EUR"
          label="currencyIsoCode"
          placeholder="currencyIsoCode"
          className="mt-6"
        />
        <TextInput
          required
          {...register('externalReference')}
          label="externalReference"
          placeholder="externalReference"
          className="mt-6"
        />

        <div>
          <h1> Lignes </h1>

          {invoiceDraftLines.map((line, index) => (
            // eslint-disable-next-line no-underscore-dangle
            <div key={line._tempId}>
              <TextInput
                required
                {...register(`invoiceDraftLines.${index}.label`)}
                label="label"
                placeholder="label"
                className="mt-6"
              />
              <TextInput
                required
                {...register(`invoiceDraftLines.${index}.quantity`)}
                label="quantity"
                placeholder="quantity"
                className="mt-6"
              />
              <TextInput
                required
                {...register(`invoiceDraftLines.${index}.unitPrice.amount`)}
                label="unitPrice"
                placeholder="unitPrice"
                className="mt-6"
              />
            </div>
          ))}

          <Button type="button" onClick={addLine}>
            Ajouter une ligne
          </Button>
        </div>
      </MainCard>
      <FormWizardButtons wizardContext={invoiceDraftWizardContext} />
    </form>
  );
};
