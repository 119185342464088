import { NotificationIcon } from '../../../app/assets/icons/menu';
import { selectUserId } from '../../../app/store/selectors/selectUserId';
import { RoleType } from '../../authentification/types/RoleType';
import { NotificationDropDown } from '../../notifications/widget/NotificationDropDown';
import { useGetNotificationsInbox } from '../../notifications/hooks/useGetNotificationsInbox';
import { useDisplayNotificationButton } from '../hooks/useDisplayNotificationButton';
import { HeaderIconButton } from './HeaderIconButton';

export const HeaderIconNotification = () => {
  const userId = selectUserId() as RoleType;

  const { hasWebNotifications } = useDisplayNotificationButton();
  const { notificationsInboxData } = useGetNotificationsInbox({
    userId,
    enabled: !!hasWebNotifications,
  });

  if (!hasWebNotifications || !notificationsInboxData) return null;

  return (
    <HeaderIconButton
      itemCount={notificationsInboxData.unreadMessagesCount}
      Icon={NotificationIcon}
      style={{
        buttonClassname: 'relative ml-[60px]',
      }}
      DropDownComponent={NotificationDropDown}
      dropDownComponentProps={{
        notificationsInboxData,
      }}
    />
  );
};
