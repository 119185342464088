import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { MainCard } from '../../../../common/components/mainCard';
import { LineText } from '../../../../common/components/fragments/LineText';
import { SepaTransaction } from '../../../../../app/spraypaint';
import BankAccount from '../../../../../app/spraypaint/BankAccount';
import Status, {
  StatusType,
} from '../../../../common/components/statuses/Status';
import { MoneyType } from '../../../../common/utils/type.utils';

type Props = {
  bankReconciliationInformation: SepaTransaction | undefined;
  bankAccount: BankAccount | undefined;
  entityName: string | undefined;
};

export const BankTransfertInformationCard = ({
  bankReconciliationInformation,
  bankAccount,
  entityName,
}: Props) => {
  const { t } = useTranslation();
  return (
    <MainCard
      header={t('front.mainCard.header.defaultTitle')}
      className="mr-5 md:w-[800px]"
    >
      <LineText
        label={t('front.bankAssociation.show.card.label.direction')}
        value={bankReconciliationInformation?.direction.toLocaleUpperCase()}
      />
      <LineText
        label={t('front.manualReconciliation.selectedInvoice.label.dueDate')}
        value={
          dayjs(bankReconciliationInformation?.paymentDate as string).format(
            'L',
          ) || '-'
        }
      />
      <LineText
        label={t('front.bankAssociation.show.card.debtorName')}
        value={bankReconciliationInformation?.debitorName || '-'}
      />
      <LineText
        label={t('front.bankAssociation.show.card.label.label')}
        value={bankReconciliationInformation?.label || '-'}
      />
      <LineText
        label={t('front.bankAssociation.show.card.label.endToEndId')}
        value={bankReconciliationInformation?.endToEndId || '-'}
      />
      {bankReconciliationInformation?.direction === 'debit' && (
        <LineText
          label={t(
            'front.bankAssociation.show.card.label.additionalInformation',
          )}
          value={bankReconciliationInformation?.additionalInformation || '-'}
        />
      )}
      <LineText
        label={t('views.legal_entity.label')}
        value={entityName || '-'}
      />
      <LineText
        label={t('front.bankAssociation.show.card.label.amount')}
        value={
          <AmountWithCurrency
            amount={bankReconciliationInformation?.amount as MoneyType}
          />
        }
      />
      <LineText
        label={t('front.bankAssociation.show.card.label.amountEuro')}
        value={
          <AmountWithCurrency
            amount={bankReconciliationInformation?.amountEuro as MoneyType}
          />
        }
      />
      {(bankReconciliationInformation?.totalEntries ?? 0) > 1 && (
        <LineText
          label={t('front.bankAssociation.show.card.label.totalEntries')}
          value={bankReconciliationInformation?.totalEntries}
        />
      )}
      <LineText
        label={t('front.bankAssociation.show.card.label.accountHolder')}
        value={bankAccount?.accountHolder || '-'}
      />
      <LineText label="IBAN :" value={bankAccount?.iban || '-'} />
      {bankReconciliationInformation?.status === 'pending_validation' && (
        <LineText
          label={t('front.sepa.show.card.label.status')}
          value={
            <Status
              statusType={
                bankReconciliationInformation?.matchingStatus as StatusType
              }
            />
          }
        />
      )}
      {bankReconciliationInformation?.status !== 'pending_validation' && (
        <LineText
          label={t('front.sepa.show.card.label.autoValidated')}
          value={
            bankReconciliationInformation?.autoValidated
              ? (t('default.yes') as string)
              : (t('default.no') as string)
          }
        />
      )}
    </MainCard>
  );
};
