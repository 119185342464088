import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import ActivityReportSlot from '../../../../app/spraypaint/activityReport/ActivityReportSlot';
import { CraCategorieType } from '../../../../app/spraypaint/payslipFunnel/PayslipFunnelCraStep';
import { Toaster } from '../../../common/components/toasts';
import { updateObjectInArray } from '../../../common/utils/array.utils';
import { IndexedSlotsType } from '../utils/getActivityReportSlots';

export const useUpdateActivityReportSlots = (payslipFunnelId: number) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...others } = useMutation({
    mutationFn: async (data: {
      pmId?: string;
      amId?: string;
      slotType: CraCategorieType;
    }) => {
      if (!data.amId) {
        const updatedActivityReportSlotPm = new ActivityReportSlot({
          id: data.pmId,
        });
        updatedActivityReportSlotPm.isPersisted = true;
        updatedActivityReportSlotPm.slotType = data.slotType;
        await updatedActivityReportSlotPm.save();
        return updatedActivityReportSlotPm;
      }
      if (!data.pmId) {
        const updatedActivityReportSlotAm = new ActivityReportSlot({
          id: data.amId,
        });
        updatedActivityReportSlotAm.isPersisted = true;
        updatedActivityReportSlotAm.slotType = data.slotType;
        await updatedActivityReportSlotAm.save();
        return updatedActivityReportSlotAm;
      }
      const updatedActivityReportSlotAm = new ActivityReportSlot({
        id: data.amId,
      });
      const updatedActivityReportSlotPm = new ActivityReportSlot({
        id: data.pmId,
      });
      updatedActivityReportSlotAm.isPersisted = true;
      updatedActivityReportSlotPm.isPersisted = true;
      updatedActivityReportSlotAm.slotType = data.slotType;
      updatedActivityReportSlotPm.slotType = data.slotType;
      await Promise.all([
        updatedActivityReportSlotAm.save(),
        updatedActivityReportSlotPm.save(),
      ]);

      return [updatedActivityReportSlotAm, updatedActivityReportSlotPm];
    },
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(['extraCraData', payslipFunnelId]);
      if (Array.isArray(data)) {
        data.map((newData) =>
          queryClient.setQueryData(
            ['craDatas', payslipFunnelId],
            (oldquerydata: any) => ({
              ...oldquerydata,
              data: {
                ...oldquerydata.data,
                craStep: {
                  ...oldquerydata.data.craStep,
                  activityReport: {
                    ...oldquerydata.data.craStep.activityReport,
                    activityReportSlots: updateObjectInArray(
                      oldquerydata.data.craStep.activityReport
                        .activityReportSlots,
                      newData,
                    ),
                  },
                },
              },
            }),
          ),
        );
      } else {
        queryClient.setQueryData(
          ['craDatas', payslipFunnelId],
          (oldquerydata: any) => ({
            ...oldquerydata,
            data: {
              ...oldquerydata.data,
              craStep: {
                ...oldquerydata.data.craStep,
                activityReport: {
                  ...oldquerydata.data.craStep.activityReport,
                  activityReportSlots: updateObjectInArray(
                    oldquerydata.data.craStep.activityReport
                      .activityReportSlots,
                    data,
                  ),
                },
              },
            },
          }),
        );
      }
    },
  });
  return { mutate, isLoading, ...others };
};

export const useUpdateSlot = (
  indexedSlots: IndexedSlotsType,
  payslipFunnelId: number,
  selectedType?: CraCategorieType,
) => {
  const { mutate: mutateActivityReportSlots } =
    useUpdateActivityReportSlots(payslipFunnelId);
  const updateActivityReportSlot = (date: Date) => () => {
    if (selectedType !== undefined && indexedSlots) {
      const data = indexedSlots[dayjs(date).format('YYYY-MM-DD')];
      const { am, pm } = data;
      // 1 to 0,5
      if (selectedType === am.slotType && selectedType === pm.slotType) {
        mutateActivityReportSlots({
          pmId: pm.id as string,
          slotType: 'blank',
        });
        // 0 to 1
      } else if (am.slotType === 'blank' && pm.slotType === 'blank')
        mutateActivityReportSlots({
          pmId: pm.id as string,
          amId: am.id as string,
          slotType: selectedType as CraCategorieType,
        });
      // 0,5 TO 0
      else if (
        am.slotType !== 'blank' &&
        pm.slotType === 'blank' &&
        am.slotType === selectedType
      )
        mutateActivityReportSlots({
          amId: am.id as string,
          slotType: 'blank' as CraCategorieType,
        });
      // 0,5 TO 1
      else if (pm.slotType === 'blank' && am.slotType !== selectedType)
        mutateActivityReportSlots({
          pmId: pm.id as string,
          slotType: selectedType as CraCategorieType,
        });
      // changement of PM type when both pm and am are already existing
      else if (selectedType !== pm.slotType && am.slotType !== pm.slotType)
        mutateActivityReportSlots({
          pmId: pm.id as string,
          slotType: selectedType as CraCategorieType,
        });
      else if (selectedType === pm.slotType && am.slotType !== pm.slotType)
        mutateActivityReportSlots({
          amId: am.id as string,
          slotType: selectedType as CraCategorieType,
        });
      else if (selectedType !== pm.slotType && pm.slotType === am.slotType)
        mutateActivityReportSlots({
          amId: am.id as string,
          pmId: pm.id as string,
          slotType: selectedType as CraCategorieType,
        });
    } else {
      toast.error(
        <Toaster
          textMsg="Please select a type to modify your CRA"
          toastType="error"
        />,
      );
    }
  };
  return { updateActivityReportSlot };
};
