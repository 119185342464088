import { useQuery } from '@tanstack/react-query';
import Expense from '../../../../app/spraypaint/Expense';

const useSubmittedExpenses = (employeeId: number | string | undefined) =>
  useQuery<any>({
    queryKey: [`submitted-expenses-${employeeId}`],
    queryFn: () =>
      Expense.where({ employee_id: employeeId, status: 'submitted' })
        .select(['id'])
        .stats({ total: ['ht_amount', 'count'] })
        .all(),
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
export default useSubmittedExpenses;
