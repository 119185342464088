// api/Notifications::Inbox.ts
import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type User from '../User';
import type NotificationsMessage from './NotificationsMessage';

@Model()
class NotificationsInbox extends ApplicationRecord {
  static jsonapiType = 'notifications-inboxes';

  // Attributes
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;

  @Attr() markAsRead!: boolean;

  @Attr({ persist: false }) oneSignalExternalId!: string;
  @Attr({ persist: false }) oneSignalExternalIdAuthHash!: string;
  @Attr({ persist: false }) unreadMessagesCount!: number;

  // Relationships
  @BelongsTo('users') user!: User;
  @HasMany('notifications-messages') messages!: Array<NotificationsMessage>;
}

export default NotificationsInbox;
