import { useMutation } from '@tanstack/react-query';
import Comment, { CommentableType } from '@spraypaint/Comment';

export const useAddComment = (callBack?: () => void) =>
  useMutation({
    mutationFn: async ({
      commentableId,
      content,
      commentableType,
    }: {
      commentableId?: string;
      content: string;
      commentableType: CommentableType;
    }) => {
      const newSepaTransactionComment = new Comment({
        commentable_id: commentableId,
        commentable_type: commentableType,
        content,
      });
      await newSepaTransactionComment.save();
    },
    onSuccess: () => {
      if (callBack) callBack();
    },
  });
