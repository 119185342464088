import {
  FetchQueryOptions,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { hasArrayIntersection } from '@common/utils/array.utils';
import { getLanguageFromStorage } from '@/app/translation/utils/local.utils';
import { isEmpty } from 'lodash';
import { i18n as I18N } from 'i18next';
import { RoutesMounterArgs } from '@/types';
import User from '../../../app/spraypaint/User';

type UserResponse = {
  data: User;
};

export interface UseCurrentUserQueryOptionsParams {
  opts?: UseQueryOptions<UserResponse> | undefined;
  tools: { i18n: I18N };
}

export function currentUserQueryOptions({
  opts,
  tools,
}: UseCurrentUserQueryOptionsParams): WithRequired<
  FetchQueryOptions<UserResponse>,
  'queryKey'
> {
  return {
    queryKey: ['user-id'],
    queryFn: () =>
      User.includes([
        'employees',
        'employees.legal_entity',
        'onboarding_funnel',
      ]).find('current'),
    staleTime: 1000 * 60 * 2, // 2 minutes
    onSuccess: (response: UserResponse) => {
      const user = response?.data;
      if (!user) return;

      const languageFromStorage = getLanguageFromStorage();

      if (
        user.preferredLanguage &&
        user.primaryRole === 'employee' &&
        !languageFromStorage &&
        tools.i18n.language !== user.preferredLanguage
      ) {
        tools.i18n.changeLanguage(user.preferredLanguage);
      }

      // Don't worry, this only fire a request to mixpanel
      // if the user id changed since last call
      mixpanel.identify(user.id);
    },
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    ...opts,
  };
}

export const useCurrentUser = (opts: UseQueryOptions<UserResponse> = {}) => {
  const { i18n } = useTranslation();

  const queryOptions = currentUserQueryOptions({ opts, tools: { i18n } });

  const {
    data: userData,
    isLoading,
    isError,
    isSuccess,
    isFetching: isFetchingUser,
    refetch: refetchUser,
  } = useQuery(queryOptions);

  const user = userData?.data;
  const employees = user?.employees || [];
  const onboardingFunnel = user?.onboardingFunnel || undefined;
  const onboardingFunnelId = user?.onboardingFunnel?.id || 0;
  const onboardingFunnelStatus = user?.onboardingFunnel?.status;
  const isProspect =
    !isEmpty(user?.onboardingFunnel) ||
    (onboardingFunnelStatus && onboardingFunnelStatus !== 'completed');

  return {
    userData,
    user,
    employees,
    isProspect,
    refetchUser,
    isFetchingUser,
    onboardingFunnel,
    onboardingFunnelId,
    onboardingFunnelStatus,
    isLoading,
    isError,
    isSuccess,
  };
};

export function ensureCurrentUserQueryData({
  queryClient,
  i18n,
}: Pick<RoutesMounterArgs['tools'], 'queryClient' | 'i18n'>) {
  return queryClient.ensureQueryData(
    currentUserQueryOptions({ tools: { i18n } }),
  );
}

export const useUserManagerRole = (): boolean => {
  const { user } = useCurrentUser();
  const roles = user?.roles || [];
  const [isOnlyManager, setIsOnlyManager] = useState<boolean>(false);

  useEffect(() => {
    const hasManagerRole = hasArrayIntersection(['manager', 'admin'], roles);
    const hasOtherRoles =
      roles.filter((role: string) => role !== 'manager' && role !== 'admin')
        .length > 0;

    setIsOnlyManager(hasManagerRole && !hasOtherRoles);
  }, [roles]);

  return isOnlyManager;
};
