import { FactorClientPaymentFile } from '@/app/spraypaint';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { TypedLoaderFunction } from '@/types';
import { RecordProxy } from 'spraypaint/lib-esm/proxies';

export function factorClientPaymentFileQuery(id: string) {
  return {
    queryKey: ['factor-client_payment_files', id],
    queryFn: async () =>
      FactorClientPaymentFile
        // .selectExtra(['last_exports'])
        .find(id),
  };
}

export function loader(
  queryClient: QueryClient,
): TypedLoaderFunction<RecordProxy<FactorClientPaymentFile>> {
  return async ({ params }) => {
    const { id } = params;

    // if id is undefined, or is not a number-string
    if (!id || Number.isNaN(Number(id)))
      // Allow Response to be thrown here because this is an expected
      // behavior from React Router
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new Response('Not found', { status: 404, statusText: 'Not Found' });

    const query = factorClientPaymentFileQuery(id as string);

    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
}

export function usefactorClientPaymentFile(id: string) {
  return useQuery(factorClientPaymentFileQuery(id));
}
