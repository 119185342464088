import { useTranslation } from 'react-i18next';
import Table from '../../../../common/components/table/Table';
import { ExpensesToReimburseColumns } from '../../table/payslipDetailsColumns';

export type RefundableExpensesTableProps = {
  expensesStep: any;
  isLoading: boolean;
};

export const RefundableExpensesTable = ({
  isLoading,
  expensesStep,
}: RefundableExpensesTableProps) => {
  const { t } = useTranslation();
  const expensesToReimburseColumns = ExpensesToReimburseColumns();

  return (
    <Table
      emptyTableErrorMsg="front.payslip.tdc.details.emptyTable"
      withImageErrorMsg={false}
      title={
        t(
          'front.payslip.details.expense.refundableExpenses.table.title',
        ) as string
      }
      disabledPagination
      isCollapsed={false}
      isLoading={isLoading}
      data={expensesStep?.refundable_expenses}
      columns={expensesToReimburseColumns}
    />
  );
};
