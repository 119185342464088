import { useTranslation } from 'react-i18next';
import Table from '../../../../common/components/table/Table';
import { InvoiceDetailsColumns } from '../../table/payslipDetailsColumns';

export type InvoicesDetailsTableProps = {
  invoicesStep: any;
  isLoading: boolean;
};

export const InvoicesDetailsTable = ({
  isLoading,
  invoicesStep,
}: InvoicesDetailsTableProps) => {
  const { t } = useTranslation();
  const invoiceColumns = InvoiceDetailsColumns();

  return (
    <Table
      emptyTableErrorMsg="front.payslip.tdc.details.emptyTable"
      withImageErrorMsg={false}
      title={t('front.payslip.tdc.invoice.table.ca.title') as string}
      disabledPagination
      isCollapsed={false}
      isLoading={isLoading}
      data={invoicesStep?.revenues || []}
      columns={invoiceColumns}
    />
  );
};
