import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from 'react-router-dom';
import { LayoutContainer } from '@/modules/layout/Layout';
import { PageNotFound } from '@/app/components';
import { ReportProblemIc } from '@/app/assets/icons/others';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { useAppEnvName } from '@/app/hooks/useAppEnvName';

export const ErrorPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const appEnvName = useAppEnvName();
  const [showDetails, setShowDetails] = useState(appEnvName !== 'production');
  const error = useRouteError();
  const navigate = useNavigate();

  const isError = error instanceof Error;

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <LayoutContainer>
        <PageNotFound />
      </LayoutContainer>
    );
  }

  return (
    <LayoutContainer>
      <div className="min-h-screen flex items-center justify-center bg-color-transparent-medium-grey">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-4xl w-full">
          <div className="flex items-center justify-center mb-4">
            <ReportProblemIc className="text-color-pone-orange w-16 h-16" />
          </div>
          <h1 className="text-2xl font-bold mb-2 text-color-pone-dark">
            {t('front.error_page.error_occurred')}
          </h1>
          <p className="mb-4 text-color-dark-content">
            {t('front.error_page.something_wrong')}
          </p>
          <ButtonPrimary
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('front.error_page.back')}
          </ButtonPrimary>
          {isError && (
            <>
              <button
                type="button"
                onClick={() => setShowDetails(!showDetails)}
                className="mt-5 text-color-pone-orange hover:underline mb-4"
              >
                {t('front.error_page.details')}
              </button>
              {showDetails && (
                <div className="border-t pt-2 text-color-dark-grey2">
                  <p>
                    <strong>Message:</strong> {error.message}
                  </p>
                  <p>
                    <strong>Stack Trace:</strong>
                  </p>
                  <pre className="h-64 overflow-y-auto">{error.stack}</pre>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </LayoutContainer>
  );
};
