import { isEqual as lodashIsEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { usePrevious } from './usePrevious';

type UseDebounceOpts<T> = {
  delay?: number;
  isEqual?: (a: T | undefined, b: T) => boolean;
};

// Debouncer that works with object instead of primitives
export function useAdvancedDebounce<T>(
  value: T,
  { delay = 500, isEqual = lodashIsEqual }: UseDebounceOpts<T> = {},
): T {
  const prevValue = usePrevious(value);

  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (!isEqual(prevValue, value)) {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [value, delay]);

  return debouncedValue;
}
