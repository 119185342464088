/* eslint-disable no-unsafe-optional-chaining */
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { MouseEvent, useEffect } from 'react';
import { toast } from 'react-toastify';
import { CommentWidget } from '@/modules/common/components/comments/CommentWidget';
import { onRowClickNavigate } from '@/app/navigation/functions';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useUserManagerRole } from '@/modules/authentification/hooks';
import { Breadcrumbs } from '../../../navigation/components/Breadcrumbs';
import { useGetBankReconciliationInformationScope } from './hooks/useGetAssociationInvoices';
import Table from '../../../common/components/table/Table';
import { useBackendTable } from '../../../common/components/table/hooks';
import {
  BreakdownLine,
  SepaTransaction,
  SubcontractorBill,
  SepaImport,
  ContribPeeDeposit,
  ContribPercoDeposit,
} from '../../../../app/spraypaint';
import invoicesBankTransactionColumns from './columns/invoicesBankTransactionColumns';
import { useBankReconciliationTransition } from '../hooks/useBankReconciliationTransition';
import { useSendLabelFormatReminder } from './hooks/useSendLabelFormatReminder';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../../../common/components/buttons';
import { BankTransfertInformationCard } from '../bankAssociation/components/BankTransfertInformationCard';
import { useGetBankInformation } from '../bankAssociation/hooks/useGetBankReconcilationInformation';
import { useSepaTransaction } from '../bankReconciliation/functions/sepa_transaction_query';
import { Toaster } from '../../../common/components/toasts';
import subcontractorBillColumns from './columns/subcontractorBillColumns';
import sepaImportColumns from './columns/sepaImportColumns';
import { useGetTransactionAssociations } from '../bankReconciliation/hooks/useGetTransactionAssociations';
import contribDepositColumns from './columns/ContribDepositColumns';

export const BankAssociationShow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const isOnlyManager = useUserManagerRole();

  const {
    mutate,
    isSuccess,
    isLoading: cancellationReconciliationLoader,
  } = useBankReconciliationTransition();

  const { data: SepaTransactionData } = useSepaTransaction(id as string);
  const sepaTransaction = SepaTransactionData?.data;
  const legalEntity = sepaTransaction?.legalEntity;

  const { bankAccount } = useGetBankInformation(legalEntity?.id);

  const {
    mutate: sendLabelFormatReminderMutation,
    isLoading: sendLabelFormatReminderLoader,
  } = useMutation(useSendLabelFormatReminder);

  useEffect(() => {
    if (isSuccess) navigate('/v2/admin/bank_reconciliation');
  }, [isSuccess]);

  const handleCancelBankReconciliation = () => {
    mutate({
      resourceId: id,
      transitionName: 'cancel',
    });
  };

  const handleTransactionRestoration = () => {
    mutate({
      resourceId: id,
      transitionName: 'back_to_pending_validation',
    });
  };

  const handleSendLabelFormatReminder = async () => {
    sendLabelFormatReminderMutation(sepaTransaction, {
      onSuccess: () => {
        const successMsg = t(
          'front.bankAssociation.success.label_format_reminder',
        );
        toast.success(<Toaster textMsg={successMsg} toastType="success" />);
        queryClient.invalidateQueries([
          'get-bank-reconciliation-information',
          id,
        ]);
      },
      onError: () => {
        const errorText = t(
          'front.bankAssociation.errors.label_format_reminder',
          {
            transactionId: id,
          },
        );
        toast.error(<Toaster textMsg={errorText} toastType="error" />);
      },
    });
  };

  const [
    validatedSepaTransactionScope,
    validatedSepaTransactionScopeKey,
    validatedSepaTransactionProps,
  ] = useBackendTable<SepaTransaction>();

  const { breakDownLines, totalCount } =
    useGetBankReconciliationInformationScope(id as string, {
      args: validatedSepaTransactionScopeKey,
      scope: validatedSepaTransactionScope,
    });

  function createRowClickHandler<T extends { employeePagePath?: string }>(
    model: T,
    navigateFn: NavigateFunction,
  ) {
    return (event: MouseEvent) => {
      if (model.employeePagePath) {
        onRowClickNavigate({
          id: model.employeePagePath,
          event,
          windowNavigationHref: model.employeePagePath,
          href: model.employeePagePath,
          navigate: navigateFn,
        });
      }
    };
  }

  const onInvoiceClick = (raw: ModelRecord<BreakdownLine>) =>
    createRowClickHandler(raw, navigate);
  const onSubcontractorBillClick = (raw: ModelRecord<SubcontractorBill>) =>
    createRowClickHandler(raw, navigate);
  const onContribDepositClick = (
    raw: ModelRecord<ContribPeeDeposit | ContribPercoDeposit>,
  ) => createRowClickHandler(raw, navigate);
  const onSepaImportClick =
    (raw: ModelRecord<SepaImport>) => (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminPagePath,
        event,
        windowNavigationHref: raw.adminPagePath,
        href: raw.adminPagePath,
        navigate,
      });
    };

  const { transactionAssociations, isFetching } =
    useGetTransactionAssociations(id);

  function filterAssociations<T>(
    transactionAssociationsArray: Array<{
      associableType: string;
      associable: unknown;
    }>,
    associableTypes: Array<string>,
  ): T[] {
    return transactionAssociations
      .filter((transactionAssociation) =>
        associableTypes.includes(transactionAssociation.associableType),
      )
      .map((transactionAssociation) => transactionAssociation.associable as T);
  }

  const subcontractorBills = filterAssociations<SubcontractorBill>(
    transactionAssociations,
    ['SubcontractorBill'],
  );
  const sepaImports = filterAssociations<SepaImport>(transactionAssociations, [
    'SepaImport',
  ]);
  const contribDeposits = filterAssociations<
    ContribPeeDeposit | ContribPercoDeposit
  >(transactionAssociations, ['ContribPeeDeposit', 'ContribPercoDeposit']);

  const diplayAssociations = () => {
    if (sepaTransaction?.direction === 'credit') {
      return (
        <>
          <p className="text-color-medium-grey text-xl mt-[26px] ml-[26px]">
            Factures client
          </p>
          <Table
            emptyTableErrorMsg="react_table.no_data_text"
            className=""
            withImageErrorMsg={false}
            totalCount={totalCount}
            data={breakDownLines || []}
            // @TODO solve type issue with this line
            // @ts-expect-error: TS2322: Type because of data is BreakdownLine[] | undefined
            reactTableProps={validatedSepaTransactionProps}
            columns={invoicesBankTransactionColumns}
            onRowClick={onInvoiceClick}
          />
          <p className="text-color-medium-grey text-xl mt-[26px] ml-[26px]">
            Contributions
          </p>
          <Table
            emptyTableErrorMsg="react_table.no_data_text"
            className=""
            withImageErrorMsg={false}
            isLoading={isFetching}
            data={contribDeposits}
            columns={contribDepositColumns}
            onRowClick={onContribDepositClick}
          />
        </>
      );
    }

    return (
      <>
        <p className="text-color-medium-grey text-xl mt-[26px] ml-[26px]">
          Factures sous-traitant
        </p>
        <Table
          emptyTableErrorMsg="react_table.no_data_text"
          className=""
          withImageErrorMsg={false}
          isLoading={isFetching}
          data={subcontractorBills}
          columns={subcontractorBillColumns}
          onRowClick={onSubcontractorBillClick}
        />
        <p className="text-color-medium-grey text-xl mt-[26px] ml-[26px]">
          Salaires
        </p>
        <Table
          emptyTableErrorMsg="react_table.no_data_text"
          className=""
          withImageErrorMsg={false}
          isLoading={isFetching}
          data={sepaImports}
          columns={sepaImportColumns}
          onRowClick={onSepaImportClick}
        />
      </>
    );
  };

  return (
    <div className="mb-[100px]">
      <div className="ml-[20px]">
        <div className="flex justify-between pr-[20px]">
          <p className="big-title ">
            {t('front.bankAssociation.validated.show.title')}
          </p>
          <div className="flex justify-end">
            <ButtonSecondary
              disabled={!sepaTransaction?.previousPendingTransactionId}
              className="mr-5"
              to={`/v2/admin/bank_reconciliation/${sepaTransaction?.previousPendingTransactionId}/assignation`}
            >
              {t('front.button.label.previousMatching')}
            </ButtonSecondary>
            <ButtonSecondary
              disabled={!sepaTransaction?.nextPendingTransactionId}
              className="mr-5"
              to={`/v2/admin/bank_reconciliation/${sepaTransaction?.nextPendingTransactionId}/assignation`}
            >
              {t('front.button.label.nextMatching')}
            </ButtonSecondary>
          </div>
        </div>
        <Breadcrumbs />
        <CommentWidget
          commentableType="Sepa::Transaction"
          commentableId={sepaTransaction?.id}
        />
        <BankTransfertInformationCard
          bankAccount={bankAccount}
          bankReconciliationInformation={sepaTransaction}
          entityName={legalEntity?.name}
        />
      </div>
      <div className="flex ml-[20px] mt-[20px]">
        <ButtonSecondary
          to={`/v2/admin/bank_transfer/${sepaTransaction?.sepaFileId}`}
          className="mr-5"
          disabled={isOnlyManager}
        >
          {t('front.button.label.showSepaFile')}
        </ButtonSecondary>
        {sepaTransaction?.status === 'validated' && (
          <ButtonPrimary
            onClick={handleCancelBankReconciliation}
            isLoading={cancellationReconciliationLoader}
            className="mr-5"
            disabled={isOnlyManager}
          >
            {t('front.button.label.cancelMatching')}
          </ButtonPrimary>
        )}
        {!isOnlyManager && sepaTransaction?.status === 'orphaned' && (
          <ButtonPrimary
            onClick={handleTransactionRestoration}
            className="mr-5"
            isLoading={cancellationReconciliationLoader}
            disabled={isOnlyManager}
          >
            {t('front.button.label.restoreTransaction')}
          </ButtonPrimary>
        )}
        {!isOnlyManager &&
          sepaTransaction?.status === 'validated' &&
          sepaTransaction?.direction === 'credit' &&
          (breakDownLines?.length || 0) > 0 && (
            <ButtonPrimary
              onClick={handleSendLabelFormatReminder}
              isLoading={sendLabelFormatReminderLoader}
              className="mr-5"
              disabled={
                sepaTransaction?.labelFormatReminderSent || isOnlyManager
              }
            >
              {t('front.button.label.sendLabelFormatReminder')}
            </ButtonPrimary>
          )}
      </div>
      {sepaTransaction?.status !== 'orphaned' && diplayAssociations()}
    </div>
  );
};
