import classNames from 'classnames';
import { AmountText } from '../../../common/components/fragments/AmountText';
import { Button } from '../../../common/components/buttons';
import { IconSearch } from '../../../../app/assets/icons/payslipFunnel';
import { VerticalLine } from './VerticalLine';
import useBreakpoints from '../../../../app/responsive/useBreakPoints';

type Props = {
  blue?: boolean;
  isLast?: boolean;
  amount: number;
  supText?: string;
  display?: boolean;
  onLineClick?: any;
  onIconClick?: any;
  label: string;
};

export const BalanceDisplayCard = ({
  blue,
  amount,
  supText,
  label,
  isLast,
  display,
  onLineClick,
  onIconClick,
}: Props) => {
  const { isXs } = useBreakpoints();
  const customClassName = classNames(
    'h-[80px] flex flex-col items-center justify-center bg-color-transparent-medium-grey' +
      ' radius-lg border-[1px] border-color-medium-light rounded-xl relative w-[340px] md:w-[540px]',
    {
      'bg-color-blue text-white': blue,
    },
  );

  const amountClassName = classNames('font-cabin-bold text-lg md:text-xl', {
    'text-color-success': amount > 0 && !blue,
  });

  return (
    <>
      <div className={customClassName}>
        <p className={isXs ? 'text-sm3 w-[80%] text-center' : ''}>
          {label.toUpperCase()}
        </p>
        <AmountText
          amount={amount}
          className={amountClassName}
          supText={supText}
        />
        {onIconClick && (
          <Button onClick={onIconClick} custom>
            <IconSearch className="absolute right-[20px] top-[38%]" />
          </Button>
        )}
      </div>
      {!blue && (
        <VerticalLine isLast={isLast} onClick={onLineClick} display={display} />
      )}
    </>
  );
};
