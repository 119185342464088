import { FactorClientPaymentTransaction } from '@/app/spraypaint';
import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';

export const useGetValidatedFactorClientPaymentTransactions = (
  args: unknown[],
  scope: (
    baseScope: Scope<FactorClientPaymentTransaction>,
  ) => Scope<FactorClientPaymentTransaction>,
  id: string,
) => {
  const data = useQuery({
    queryKey: ['completed-factor-client_payment_transactions', ...args],
    queryFn: () =>
      scope(FactorClientPaymentTransaction.scope())
        .where({
          status: ['validated', 'orphaned'],
          client_payment_file_id: id,
          reconciliable: true,
        })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const validatedFactorClientPaymentTransactions = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, validatedFactorClientPaymentTransactions, totalCount };
};

export const useGetPendingFactorClientPaymentTransactions = (
  args: unknown[],
  scope: (
    baseScope: Scope<FactorClientPaymentTransaction>,
  ) => Scope<FactorClientPaymentTransaction>,
  id: string,
) => {
  const data = useQuery({
    queryKey: ['pending-factor-client_payment_transactions', ...args],
    queryFn: () =>
      scope(FactorClientPaymentTransaction.scope())
        .where({
          status: 'pending_validation',
          client_payment_file_id: id,
          reconciliable: true,
        })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const pendingFactorClientPaymentTransactions = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, pendingFactorClientPaymentTransactions, totalCount };
};

export const useGetMiscFactorClientPaymentTransactions = (
  args: unknown[],
  scope: (
    baseScope: Scope<FactorClientPaymentTransaction>,
  ) => Scope<FactorClientPaymentTransaction>,
  id: string,
) => {
  const data = useQuery({
    queryKey: ['misc-factor-client_payment_transactions', ...args],
    queryFn: () =>
      scope(FactorClientPaymentTransaction.scope())
        .where({
          client_payment_file_id: id,
          reconciliable: false,
        })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const pendingFactorClientPaymentTransactions = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, pendingFactorClientPaymentTransactions, totalCount };
};

export const useGetFactorClientPaymentTransaction = (id: string) => {
  const data = useQuery({
    queryKey: ['factor-client_payment_transactions', id],
    queryFn: () =>
      FactorClientPaymentTransaction.includes([
        'client_payment_file.factoring_contract.legal_entity',
      ])
        .selectExtra([
          'previous_pending_transaction_id',
          'next_pending_transaction_id',
        ])
        .find(id),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
  const factorClientPaymentTransaction = data?.data?.data;
  const legalEntity =
    factorClientPaymentTransaction?.clientPaymentFile?.factoringContract
      .legalEntity;

  return { factorClientPaymentTransaction, legalEntity, ...data };
};
