/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActivityReportSlot from '../../../../app/spraypaint/activityReport/ActivityReportSlot';
import { CRA_TYPE } from '../types/craType';
import { otherType } from '../types/otherTypes';
import { getSlotTypeEquality } from '../utils/getSlotTypesEquality';
import { ExpensePastille } from './fragments/ExpensePastille';

type SquareCraContainerProps = {
  children?: ReactNode;
  className?: string;
  small?: boolean;
};

export const SquareCraContainer = ({
  children,
  className,
  small,
}: SquareCraContainerProps) => {
  const customClassName = classNames(
    'flex justify-center items-center font-cabin-bold relative',
    { 'h-[52px] w-[52px]': small, 'w-[79px] h-[79px]': !small },
    className,
  );
  return <div className={customClassName}>{children}</div>;
};

export const SquareCra = ({
  className,
  onClick,
  pm,
  am,
  date,
  expensesDates,
  previousAcvitityReportSlot,
  nextAcvitityReportSlot,
}: {
  onClick?: any;
  className?: string;
  date: Date;
  expensesDates?: Array<string>;
  previousAcvitityReportSlot:
    | {
        date: Date;
        pm: ActivityReportSlot;
        am: ActivityReportSlot;
      }
    | undefined;
  nextAcvitityReportSlot:
    | {
        date: Date;
        pm: ActivityReportSlot;
        am: ActivityReportSlot;
      }
    | undefined;

  pm?: ActivityReportSlot;
  am?: ActivityReportSlot;
}) => {
  const { t } = useTranslation();
  const [isAmHovered, setIsAmHovered] = useState(false);
  const [isPmHovered, setIsPmHovered] = useState(false);
  const handlePmHover = () => setIsPmHovered((prev) => !prev);
  const handleAmHover = () => setIsAmHovered((prev) => !prev);

  const {
    isNextAmSlotSameType,
    isNextPmSlotSameType,
    isPreviousAmSlotSameType,
    isPreviousPmSlotSameType,
  } = getSlotTypeEquality({
    previousAcvitityReportSlot,
    nextAcvitityReportSlot,
    am,
    pm,
  });

  const customClassName = classNames(className);
  const buttonClassName = classNames('w-full h-full relative', {
    'cursor-pointer': onClick,
    'cursor-default': !onClick,
  });
  const amButtonClassName = classNames('h-[38px] w-full top-0 absolute', {
    'rounded-t-lg': !isPreviousAmSlotSameType && !isNextAmSlotSameType,
    'rounded-tl-lg': !isPreviousAmSlotSameType && isNextAmSlotSameType,
    'rounded-tr-lg': !isNextAmSlotSameType,
    [CRA_TYPE.working_days.bg]: am?.slotType === CRA_TYPE.working_days.type,
    [CRA_TYPE.telework.bg]: am?.slotType === CRA_TYPE.telework.type,
    [CRA_TYPE.paid_holidays.bg]: am?.slotType === CRA_TYPE.paid_holidays.type,
    [CRA_TYPE.other.bg]: otherType().some(
      (type) => type.value === am?.slotType,
    ),
  });
  const pmButtonClassName = classNames('h-[39px] w-full bottom-0 absolute', {
    'rounded-b-lg': !isPreviousPmSlotSameType && !isNextPmSlotSameType,
    'rounded-bl-lg': !isPreviousPmSlotSameType && isNextPmSlotSameType,
    'rounded-br-lg': isPreviousPmSlotSameType && !isNextPmSlotSameType,
    'h-[41px]': pm?.slotType === am?.slotType,
    [CRA_TYPE.working_days.bg]: pm?.slotType === CRA_TYPE.working_days.type,
    [CRA_TYPE.telework.bg]: pm?.slotType === CRA_TYPE.telework.type,
    [CRA_TYPE.paid_holidays.bg]: pm?.slotType === CRA_TYPE.paid_holidays.type,
    [CRA_TYPE.other.bg]: otherType().some(
      (type) => type.value === pm?.slotType,
    ),
  });

  if (!pm && !am) {
    return (
      <SquareCraContainer className={className}>
        <p>{date.getDate().toString()}</p>
      </SquareCraContainer>
    );
  }

  return (
    <SquareCraContainer className={customClassName}>
      <button
        id="id"
        className={buttonClassName}
        type="button"
        onClick={onClick ? onClick(date) : undefined}
      >
        <p className="z-20 pointer-events-none relative">
          {date.getDate().toString()}
        </p>
        {expensesDates?.includes(dayjs(date).format('YYYY-MM-DD')) && (
          <ExpensePastille />
        )}
        <div
          onMouseEnter={handleAmHover}
          onMouseLeave={handleAmHover}
          className={amButtonClassName}
        >
          {isAmHovered &&
            otherType().some((type) => type.value === am?.slotType) && (
              <div className="z-50 p-[10px] w-[125px] font-cabin-regular relative bottom-[-45px] right-[20px] bg-color-transparent-medium-grey text-sm">
                {t(`views.cra.${am?.slotType}`)}
              </div>
            )}
        </div>
        <div
          onMouseEnter={handlePmHover}
          onMouseLeave={handlePmHover}
          className={pmButtonClassName}
        >
          {isPmHovered &&
            otherType().some((type) => type.value === pm?.slotType) && (
              <div className="z-50 p-[10px] w-[125px] font-cabin-regular relative bottom-[-45px] right-[20px] bg-color-transparent-medium-grey text-sm">
                {t(`views.cra.${pm?.slotType}`)}
              </div>
            )}
        </div>
      </button>
    </SquareCraContainer>
  );
};
