import { useCurrentEmployee } from '@/modules/authentification/hooks';
import { useToggleTutorial } from '@/modules/widgets/hooks/ToggleTutorial';
import { selectUserRole } from '@/app/store/selectors/selectUserId';
import { RoleType } from '@/modules/authentification/types/RoleType';
import { useMixpanelContextWatcher } from '@/app/mixpanel/useMixpanelContextWatcher';
import Layout from '@/modules/layout/Layout';
import { menuEmployeeItems } from '@/modules/layout/constants/ConstantsEmployeeMenu';

// When entering a page under /v2/employees/:current_employee, we want to
// register the current employee_id and language in mixpanel context.
export function EmployeeLayout() {
  const { employee } = useCurrentEmployee();

  const { isTutorialOn } = useToggleTutorial();
  const primaryRole = selectUserRole() as RoleType;

  useMixpanelContextWatcher('employee_id', employee?.id);
  useMixpanelContextWatcher('tutorial_on', isTutorialOn);
  useMixpanelContextWatcher(
    'employee_preferred_language',
    employee?.user?.preferredLanguage,
  );

  return (
    <Layout
      itemsMenu={menuEmployeeItems()}
      logAsAdmin={primaryRole !== 'employee'}
    />
  );
}
