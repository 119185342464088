import { FactorClientPaymentTransaction } from '@/app/spraypaint';
import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';

export const useGetAllCompletedBankReconciliationTransaction = (
  args: Array<string>,
  scope: (
    baseScope: Scope<FactorClientPaymentTransaction>,
  ) => Scope<FactorClientPaymentTransaction>,
) => {
  const data = useQuery({
    queryKey: ['validated-factor-client_payment_transactions', ...args],
    queryFn: () =>
      scope(FactorClientPaymentTransaction.scope())
        .where({
          status: ['validated', 'orphaned'],
          reconciliable: true,
        })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const completedFactorClientPaymentTransactions = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, completedFactorClientPaymentTransactions, totalCount };
};

export const useGetAllPendingBankReconciliationTransaction = (
  args: Array<string>,
  scope: (
    baseScope: Scope<FactorClientPaymentTransaction>,
  ) => Scope<FactorClientPaymentTransaction>,
) => {
  const data = useQuery({
    queryKey: ['pending-actor-client_payment_transactions', ...args],
    queryFn: () =>
      scope(FactorClientPaymentTransaction.scope())
        .where({
          status: 'pending_validation',
          reconciliable: true,
        })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const pendingFactorClientPaymentTransactions = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, pendingFactorClientPaymentTransactions, totalCount };
};
