import { useTranslation } from 'react-i18next';
import { PayslipDetailCard, AmountEntry } from '../../components';

export type IntermediateStepDetailsProps = {
  intermediateSummaryStep: any;
};

export const IntermediateSummaryStepDetails = ({
  intermediateSummaryStep,
}: IntermediateStepDetailsProps) => {
  const { t } = useTranslation();

  return (
    <PayslipDetailCard
      user={intermediateSummaryStep?.completed_by_name}
      date={intermediateSummaryStep?.completed_at}
      title={t('views.payslip_funnel.syntheses_step.summary')}
    >
      <AmountEntry
        label={t('front.payslip.tdc.expense.balanceCard.balance') as string}
        value={{
          amount: intermediateSummaryStep?.balance_before_payslip,
        }}
      />
    </PayslipDetailCard>
  );
};
