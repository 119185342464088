import classNames from 'classnames';
import { ReactNode, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { ConseillerIcon } from '../../../app/assets/icons/buttons';
import { ArrowRightIcon } from '../../../app/assets/icons/menu';
import { useOnClickOutside } from '../hooks';
import { Button, ButtonOnClickHandler } from '../../common/components/buttons';

export type ButtonAdvisorProps = {
  name: string;
  duty: string;
  className?: string;
  disabled?: boolean;
  DropDownComponent: ReactNode;
  onClick?: ButtonOnClickHandler;
  isLoading?: boolean;
};

export function ButtonAdvisor({
  name,
  duty,
  className,
  disabled,
  onClick,
  DropDownComponent,
  isLoading,
}: ButtonAdvisorProps) {
  const ref = useRef(null);
  const [isToggle, setIsToggle] = useState(false);
  const handleDropDownComponent = (value: boolean) => () => {
    setIsToggle(value);
  };
  useOnClickOutside(ref, () => {
    handleDropDownComponent(false)();
  });

  const customClassName = classNames(
    'flex items-center w-48 hover:bg-color-light-grey active:bg-color-medium-light px-[21px] rounded-[5px] py-[7px] br-[4px] mr-9',
    {
      'fill-color-pone-orange ': !disabled,
      'fill-color-medium-grey text-color-medium-grey': disabled,
    },
    className,
  );

  const arrowRightIconClassName = classNames({
    'stroke-black ': !disabled,
    'stroke-color-medium-grey': disabled,
    'rotate-90': isToggle,
  });
  return (
    <div ref={ref}>
      <Button
        isLoading={isLoading}
        className={customClassName}
        onClick={onClick || handleDropDownComponent(!isToggle)}
      >
        <ConseillerIcon className="w-6 h-6" />
        <div className="flex flex-col ml-[12px] mr-[20px]">
          {isLoading ? (
            <Skeleton count={1} height={14} width={72} />
          ) : (
            <p className="text-base">{name}</p>
          )}
          {isLoading ? (
            <Skeleton count={1} height={12} width={72} />
          ) : (
            <p className="text-sm italic">{duty}</p>
          )}
        </div>
        <ArrowRightIcon className={arrowRightIconClassName} />
        {DropDownComponent && isToggle && DropDownComponent}
      </Button>
    </div>
  );
}
