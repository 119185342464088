import { MouseEventHandler } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from './Button';
import { LoaderIcon } from '../../../../app/assets/icons/inputs';

export type ButtonFunnelValidationProps = {
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  type?: 'submit' | 'button';
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & ButtonProps;

export function ButtonFunnelValidation({
  isLoading = false,
  className,
  disabled = false,
  type,
  onClick,
}: ButtonFunnelValidationProps) {
  const { t } = useTranslation();
  const customClassName = classNames(
    'flex items-center justify-center h-[50px]',
    {
      'shadow-md bg-color-success hover:bg-color-light-green hover:border-color-dark-orange shadow-btn':
        !disabled,
      'bg-color-disabled': disabled,
    },
    {
      'fill-color-medium-grey': disabled,
      'fill-white': !disabled,
    },
    {
      'text-white': !disabled,
      'text-color-disabled-text': disabled,
    },
    className,
  );

  return (
    <Button
      type={type}
      onClick={onClick}
      className={customClassName}
      disabled={disabled}
      isLoading={isLoading}
    >
      {isLoading ? (
        <LoaderIcon
          className="fill-white animate-spin"
          width={20}
          height={20}
        />
      ) : (
        <p className="uppercase text-sm3">
          {t('front.payslip.tdc.finalSummary.btn.validate')}
        </p>
      )}
    </Button>
  );
}
