import { useTranslation } from 'react-i18next';
import {
  ClientIcon,
  ContratIcon,
  TdbIcon,
} from '../../../app/assets/icons/menu';
import { MenuType } from '../types/MenuType';
import { RoleType } from '../../authentification/types/RoleType';
import { constantSupportMenu } from './ConstantSupportMenu';
import { constantAccountantMenu } from './ConstantAccountantMenu';
import { constantCommercialMenu } from './ConstantCommercialMenu';
import { hasArrayIntersection } from '../../common/utils/array.utils';

export const menuAdminItems = (roles: Array<RoleType>): Array<MenuType> => {
  const { t } = useTranslation();

  const forAdmin: boolean = hasArrayIntersection(['support', 'admin'], roles);
  const forAdminAndFactor = hasArrayIntersection(
    ['support', 'admin', 'factor_agent'],
    roles,
  );

  const adminItems: Array<MenuType> = [
    {
      type: 'separator' as const,
      title: t('front.menu.separator.management'),
      displaySeparator: hasArrayIntersection(
        ['support', 'commercial', 'manager', 'accountant', 'factor_agent'],
        roles,
      ),
    },
    {
      title: t('front.menu.dashboard'),
      toV1: 'to_do_list',
      icon: TdbIcon,
      canBeDisplay: forAdmin,
    },
    {
      title: t('front.menu.employees'),
      toV1: 'employees',
      icon: TdbIcon,
      canBeDisplay: forAdmin,
    },
    {
      title: t('front.menu.clients'),
      toV1: 'clients',
      icon: ClientIcon,
      canBeDisplay: forAdmin,
    },
    {
      title: t('front.menu.activity_accounts'),
      toV1: 'activity_accounts',
      icon: TdbIcon,
      canBeDisplay: forAdmin,
    },
    {
      title: t('front.menu.commercial_contracts'),
      toV1: 'commercial_contracts',
      icon: ContratIcon,
      canBeDisplay: forAdminAndFactor,
    },
    {
      title: t('front.menu.invoices'),
      toV1: 'invoices',
      icon: TdbIcon,
      canBeDisplay: forAdminAndFactor,
    },
    {
      title: t('front.menu.registered_clients'),
      toV1: 'registered_clients',
      icon: TdbIcon,
      canBeDisplay: forAdmin,
    },
    {
      title: t('front.menu.portage_offers'),
      toV1: 'portage_offers',
      icon: TdbIcon,
      canBeDisplay: forAdmin,
    },
    {
      title: t('front.menu.factoring'),
      to: 'factor_transfers',
      icon: TdbIcon,
      canBeDisplay: hasArrayIntersection(
        ['support', 'manager', 'accountant'],
        roles,
      ),
    },
    {
      title: t('front.menu.subcontractors'),
      to: 'subcontractors',
      icon: TdbIcon,
      canBeDisplay: hasArrayIntersection(
        ['support', 'manager', 'accountant'],
        roles,
      ),
    },
    {
      title: t('front.menu.subcontractor_bills'),
      to: 'subcontractor_bills',
      icon: TdbIcon,
      canBeDisplay: hasArrayIntersection(
        ['support', 'manager', 'accountant'],
        roles,
      ),
    },
  ];

  return adminItems.concat(
    constantCommercialMenu(roles),
    constantAccountantMenu(roles),
    constantSupportMenu(roles),
  );
};
