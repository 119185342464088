import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { EnvBannerContext } from '@/app/components/EnvBanner';

export const AuthLayout = () => {
  const { hidden: hiddenEnvHeader } = useContext(EnvBannerContext);
  const screenCustomClassName = classNames('h-screen w-full', {
    'pt-10': !hiddenEnvHeader,
  });

  return (
    <div className={screenCustomClassName}>
      <Outlet />
    </div>
  );
};
