import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dict } from 'mixpanel-browser';
import { getCorrectMonthNumber } from '../../../../app/utils/dateUtils';
import { ClassificationType } from '../types/ClassificationType';
import { BalanceDisplayCard } from './BalanceDisplayCard';
import { PeriodType } from '../../../common/utils/type.utils';

export const BalanceDisplay = ({
  intermediateSummaryStepData,
  currentPayslipFunnelPeriod,
  handleSetClassification,
  handleMixpanelIntermediateSummaryTracking,
}: {
  intermediateSummaryStepData: any;
  currentPayslipFunnelPeriod: PeriodType;
  handleSetClassification: (classification: ClassificationType) => () => void;
  handleMixpanelIntermediateSummaryTracking: (
    name: string,
    params?: Dict,
  ) => void;
}) => {
  const { t } = useTranslation();
  const [displayDetails, setDisplayDetails] = useState(false);

  const handleSetDisplayDetails = () => {
    if (!displayDetails)
      handleMixpanelIntermediateSummaryTracking(
        'TDS: Summary Details Level 2 Deployed',
      );
    setDisplayDetails((prev) => !prev);
  };

  const totalExpensesCostAmount =
    intermediateSummaryStepData?.totalExpensesCostWithoutContributions
      ?.amount ?? 0;

  return (
    <div className="p-[20px] bg-color-bg-expense-card rounded-xl flex flex-col items-center">
      <BalanceDisplayCard
        amount={
          intermediateSummaryStepData?.activityAccountBalanceOnFirstDay
            ?.amount ?? 0
        }
        onLineClick={handleSetDisplayDetails}
        label={t('front.payslip.tdc.expense.balanceCard.title', {
          date: `01/${getCorrectMonthNumber(currentPayslipFunnelPeriod.month)}`,
        })}
        display={displayDetails}
      />
      {displayDetails && (
        <>
          <BalanceDisplayCard
            supText="HT"
            amount={intermediateSummaryStepData?.turnoverAmount?.amount ?? 0}
            label={t('front.payslip.tdc.expense.balanceCard.activity')}
            onIconClick={handleSetClassification('turnover')}
          />

          <BalanceDisplayCard
            amount={
              intermediateSummaryStepData?.managementFeesAmount?.amount ?? 0
            }
            onIconClick={handleSetClassification('management_fees')}
            label={t('front.payslip.tdc.expense.balanceCard.management')}
          />
          <BalanceDisplayCard
            amount={
              intermediateSummaryStepData?.subcontractorAmount?.amount ?? 0
            }
            onIconClick={handleSetClassification('subcontractor')}
            label={t('front.payslip.tdc.expense.balanceCard.subcontracting')}
          />
          <BalanceDisplayCard
            amount={intermediateSummaryStepData?.servicePlusAmount?.amount ?? 0}
            onIconClick={handleSetClassification('service_plus')}
            label={t('front.payslip.tdc.expense.balanceCard.services')}
          />
          <BalanceDisplayCard
            amount={intermediateSummaryStepData.advanceAmount?.amount ?? 0}
            onIconClick={handleSetClassification('advance')}
            label={t('front.payslip.tdc.expense.balanceCard.deposits')}
          />
          <BalanceDisplayCard
            amount={-totalExpensesCostAmount}
            label={t(
              'front.payslip.tdc.expense.balanceCard.totalExpensesCostWithoutContributions',
            )}
            isLast={intermediateSummaryStepData?.otherAmount.amount === 0}
          />
          {intermediateSummaryStepData?.otherAmount?.amount !== 0 && (
            <BalanceDisplayCard
              amount={intermediateSummaryStepData?.otherAmount?.amount ?? 0}
              label={t('front.payslip.tdc.expense.balanceCard.others')}
              isLast
            />
          )}
        </>
      )}

      <BalanceDisplayCard
        blue
        amount={intermediateSummaryStepData?.balanceBeforePayslip?.amount ?? 0}
        label={t('front.payslip.tdc.expense.balanceCard.balance')}
      />
    </div>
  );
};
