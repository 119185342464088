import classNames from 'classnames';
import { Button, ButtonProps } from './Button';
import { LoaderIcon } from '../../../../app/assets/icons/inputs';
import { IconType } from '../../utils/type.utils';

export type ButtonSecondaryProps = {
  Icon?: IconType;
  iconClassName?: string;
  textStyle?: string;
  className?: string;
  disabled?: boolean;
} & ButtonProps;

export function ButtonSecondary({
  children,
  Icon,
  iconClassName,
  className = '',
  disabled,
  textStyle,
  isLoading,
  ...props
}: ButtonSecondaryProps) {
  const customClassName = classNames(
    'flex items-center h-[50px] px-[35px]',
    {
      'border-2 border-color-pone-orange hover:border-color-pone-orange hover:bg-color-pone-orange active:bg-color-dark-orange active:border-color-dark-orange hover:text-white shadow-btn':
        !disabled,
      'border-2 border-color-disabled bg-color-disabled': disabled,
    },
    {
      'text-color-disabled-text': disabled,
    },
    {
      'fill-black hover:fill-white': !disabled,
      'fill-color-disabled-text': disabled,
      'justify-between': !isLoading && Icon,
      'justify-center': isLoading || !Icon,
    },
    className,
  );
  const customTextStyle = classNames('uppercase text-sm3', textStyle);

  return (
    <Button className={customClassName} disabled={disabled} {...props}>
      {isLoading ? (
        <LoaderIcon className="animate-spin" height={20} width={20} />
      ) : (
        <>
          <p className={customTextStyle}>{children}</p>
          {Icon && (
            <span>
              <Icon className={iconClassName} />
            </span>
          )}
        </>
      )}
    </Button>
  );
}
