import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { ConstValues } from '@/types';
import useAuthClient, { AuthErrorResponseWithReason } from './useAuthClient';

export type EmailAuthVariables = {
  // The user's email address
  login: string;
  key: string;
};

export type EmailAuthData = {
  success: string;
  redirect: string;
};

export const EMAIL_AUTH_REASON_CODES = {
  INVALID_KEY: 'invalid_email_auth_key',
} as const;

export type EmailAuthReasonCode = ConstValues<typeof EMAIL_AUTH_REASON_CODES>;

type EmailAuthError = AuthErrorResponseWithReason<EmailAuthReasonCode>;

export const useEmailAuth = (
  opts: UseMutationOptions<
    AxiosResponse<EmailAuthData>,
    AxiosError<EmailAuthError>,
    EmailAuthVariables
  > = {},
) => {
  const authClient = useAuthClient();
  return useMutation({
    ...opts,
    mutationFn: (data) => authClient.post<EmailAuthData>('/email-auth', data),
  });
};
