import { useTranslation } from 'react-i18next';
import { Button } from '@/modules/common/components/buttons';
import { CloseIcon } from '../../../app/assets/icons/buttons';
import { ActivityReportSlot } from '../../../app/spraypaint';
import { CustomModal } from '../../common/components/fragments/CustomModal';
import {
  CalendarCra,
  SquareCraContainer,
} from '../../payslip_funnel/CRA/components';
import { getActivityReportSlots } from '../../payslip_funnel/CRA/utils/getActivityReportSlots';
import { getDatesRange } from '../../payslip_funnel/CRA/utils/getDatesRange';

type Props = {
  isOpen: boolean;
  isLoading?: boolean;
  handleSetCloseModal: () => void;
  activityReportSlots: Array<ActivityReportSlot> | undefined;
};

type CraShowProps = {
  activityReportSlots: any;
};

export const CraShow = ({ activityReportSlots }: CraShowProps) => {
  const { t } = useTranslation();
  const craDate = new Date(activityReportSlots[0]?.date);
  const { dateWithActivityReportSlots } = getActivityReportSlots(
    getDatesRange(craDate),
    activityReportSlots,
  );
  return (
    <div className="flex">
      <CalendarCra
        dateWithActivityReportSlots={dateWithActivityReportSlots}
        month={craDate.getMonth()}
        className="ml-[65px]"
      />
      <div className="flex flex-col mt-[40px] ml-[50px]">
        <div className="flex items-center">
          <SquareCraContainer className="bg-color-green rounded-lg" small />
          <p className="ml-[10px]">
            {t('front.payslip.show.cra.working_days')}
          </p>
        </div>

        <div className="mt-[20px] flex items-center">
          <SquareCraContainer className="bg-color-pink rounded-lg" small />
          <p className="ml-[10px]">{t('front.payslip.show.cra.teleworking')}</p>
        </div>
        <div className="mt-[20px] flex items-center">
          <SquareCraContainer className="bg-color-cyan rounded-lg" small />
          <p className="ml-[10px]">
            {t('front.payslip.show.cra.paid_holidays')}
          </p>
        </div>
        <div className="mt-[20px] flex items-center">
          <SquareCraContainer className="bg-color-cra-grey rounded-lg" small />
          <p className="ml-[10px]">{t('front.payslip.show.cra.others')}</p>
        </div>
      </div>
    </div>
  );
};

export const CraModal = ({
  isOpen,
  isLoading,
  handleSetCloseModal,
  activityReportSlots,
}: Props) => {
  const { t } = useTranslation();
  if (!activityReportSlots || isLoading || activityReportSlots.length === 0)
    return (
      <CustomModal isOpen={isOpen} onRequestClose={handleSetCloseModal}>
        <div className="h-[150px] w-[300px] flex items-center jusfity-center flex flex-col py-[25px]">
          <Button
            onClick={handleSetCloseModal}
            custom
            className="self-end mr-[25px]"
          >
            <CloseIcon />
          </Button>
          <p className="text-xl2 text-center mt-[10px]">
            {t('front.payslip.tdc.finalSummary.modal.emptyModal')}
          </p>
        </div>
      </CustomModal>
    );

  return (
    <CustomModal isOpen={isOpen} onRequestClose={handleSetCloseModal}>
      <div className="flex flex-col w-[950px] bg-color-light-grey py-[25px]">
        <Button
          onClick={handleSetCloseModal}
          custom
          className="self-end mr-[25px]"
        >
          <CloseIcon />
        </Button>
        <p className="text-xl4 ml-[80px]">
          {t('front.payslip.show.cra.title')}
        </p>
        <CraShow activityReportSlots={activityReportSlots} />
      </div>
    </CustomModal>
  );
};
