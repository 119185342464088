import { Attr, BelongsTo, Model } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from '../ApplicationRecord';
import type FactoringContract from './FactoringContract';
import type RegisteredClient from '../RegisteredClient';

@Model()
class FactorAuthorization extends ApplicationRecord {
  static jsonapiType = 'factor-authorizations';

  @Attr({ persist: false }) factoringContractId!: number;
  @Attr({ persist: false }) registeredClientId!: number;
  @Attr({ persist: false }) clientName!: string;
  @Attr({ persist: false }) approvedAmount!: MoneyType;
  @Attr({ persist: false }) totalOutstanding!: MoneyType;
  @Attr({ persist: false }) unguaranteedOutstanding!: MoneyType;
  @Attr({ persist: false }) unfinancedOutstanding!: MoneyType;
  @Attr({ persist: false }) outstandingReceivable!: MoneyType;

  @BelongsTo('factor-factoring_contracts')
  factoringContract!: FactoringContract;
  @BelongsTo('registered_clients') registeredClient!: RegisteredClient;
  // @hasMany('factor-outstanding_invoices') outstandingInvoices!: Array<O>;
}

export default FactorAuthorization;
