import { SepaFile, SepaTransaction } from '../../../../../app/spraypaint';

export const refreshFileMatching = async (payload: {
  sepaFileId: string | undefined;
}): Promise<void> => {
  const file = await SepaFile.find(payload?.sepaFileId as string);
  try {
    await file.data.fetchRefreshMatching();
  } catch {
    throw new Error('Sepa File refresh matching error');
  }
};

export const refreshTransactionMatching = async (payload: {
  sepaTransactionId: string | undefined;
}): Promise<void> => {
  const transaction = await SepaTransaction.find(
    payload?.sepaTransactionId as string,
  );

  try {
    await transaction.data.fetchRefreshMatching();
  } catch {
    throw new Error('Sepa Transaction refresh matching error');
  }
};
