import FactorAuthorizationFile from '@/app/spraypaint/factor/FactorAuthorizationFile';
import { LineText } from '@/modules/common/components/fragments/LineText';
import { MainCard } from '@/modules/common/components/mainCard';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const AuthorizationsFileCard = ({
  authorizationFile,
}: {
  authorizationFile: FactorAuthorizationFile;
}) => {
  const { t } = useTranslation();

  return (
    <MainCard
      header={t('front.factor.authorization.authorization_file_card.title')}
      className="mr-5 md:w-[800px]"
    >
      <LineText
        label={t('front.factor.authorization_file.filename')}
        value={authorizationFile?.filename}
      />
      <LineText
        label={t('front.factor.authorization_file.generated_at')}
        value={
          dayjs(authorizationFile?.generatedAt as string).format('L') || '-'
        }
      />
      <LineText
        label={t('front.factor.authorization_file.created_at')}
        value={
          dayjs(authorizationFile?.createdAt as string).format('L HH:mm') || '-'
        }
      />
    </MainCard>
  );
};

export default AuthorizationsFileCard;
