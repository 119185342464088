import { useTranslation } from 'react-i18next';
import { CraIllustration } from '../../../../app/assets/icons/tutorial';
import { Line } from '../../../common/components/fragments';
import { CommonTutorial } from './CommonTutorial';
import { TutorialText, TutorialTitle } from './components';

const CraTutorialText = () => {
  const { t } = useTranslation();
  return (
    <>
      <Line />
      <TutorialTitle className="mt-[14px]">
        {t('front.payslip.tdc.cra.tutorial.title')}
      </TutorialTitle>
      <TutorialText>{t('front.payslip.tdc.cra.tutorial.text')}</TutorialText>
    </>
  );
};

export const CraTutorial = ({ onClick }: { onClick: () => void }) => (
  <CommonTutorial
    Icon={<CraIllustration height={200} width={179} />}
    onClick={onClick}
    Component={<CraTutorialText />}
  />
);
