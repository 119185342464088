import { useTranslation } from 'react-i18next';
import {
  StrategyDescription,
  SalaryStrategy,
  CustomStcSalaryStrategy,
  findDescription,
  CustomStcSalaryStrategyDescription,
  toggleTarget,
} from '../../../../app/spraypaint/types/salaryStrategyTypes';
import { MoneyType } from '../../../common/utils/type.utils';
import { PayslipChoiceCard } from './PayslipChoiceCard';
import { PayslipCustomChoiceMinusCard } from '../../components/PayslipCustomChoiceMinusCard';
import { useSetDefaultOrLast } from '../hooks/useSetDefaultOrLast';
import { containerChoiceCard } from '../../constantes/tailwindStyle';

interface CustomStcChoiceCardProps {
  onStrategyChange: (strategy: CustomStcSalaryStrategy) => void;
  currentStrategy: SalaryStrategy | undefined;
  availableStrategiesDescription: StrategyDescription[];
  handleSetError: (value: boolean) => void;
}

export const CustomStcChoiceCard = ({
  onStrategyChange,
  currentStrategy,
  availableStrategiesDescription,
  handleSetError,
}: CustomStcChoiceCardProps) => {
  const { t } = useTranslation();
  const isActive = currentStrategy?.name === 'custom_stc_salary';

  const customStcSalaryDescription =
    findDescription<CustomStcSalaryStrategyDescription>(
      'custom_stc_salary',
      availableStrategiesDescription,
    );

  if (!customStcSalaryDescription) return null;
  const maxGrossSalary = customStcSalaryDescription.variables.maximum.gross;
  const minGrossSalary = customStcSalaryDescription.variables.minimum.gross;
  const maxNetSalary = customStcSalaryDescription.variables.maximum.net;
  const minNetSalary = customStcSalaryDescription.variables.minimum.net;

  const handleSwitchChange = () => {
    if (!isActive) return;

    onStrategyChange({
      name: 'custom_stc_salary',
      options: {
        ...currentStrategy.options,
        target: toggleTarget(currentStrategy.options.target),
      },
    });
  };

  const { handleClick, defaultOrLast } = useSetDefaultOrLast(
    {
      name: 'custom_stc_salary',
      options: {
        value: maxGrossSalary,
        target: 'gross',
      },
    },
    currentStrategy,
    onStrategyChange,
  );

  if (!customStcSalaryDescription) return null;

  const handleInputChange = (value: number) => {
    if (!isActive) return;

    onStrategyChange({
      name: 'custom_stc_salary',
      options: {
        ...currentStrategy.options,
        value: {
          ...currentStrategy.options.value,
          amount: value,
        },
      },
    });
  };

  const displayValue: MoneyType = isActive
    ? currentStrategy.options.value
    : defaultOrLast.options.value;

  const switchOptions = [
    {
      label: t(
        'front.payslip.tdc.salaryChoice.custom_salary.gross',
      ).toUpperCase(),
      value: 'gross',
    },
    {
      label: t(
        'front.payslip.tdc.salaryChoice.custom_salary.net',
      ).toUpperCase(),
      value: 'net',
    },
  ];

  return (
    <div className={containerChoiceCard}>
      <PayslipChoiceCard
        strategyName="custom_stc_salary"
        value={displayValue}
        isActive={isActive}
        onClick={handleClick}
        tooltip
      />
      {isActive && (
        <PayslipCustomChoiceMinusCard
          maxGrossSalary={maxGrossSalary}
          minGrossSalary={minGrossSalary}
          maxNetSalary={maxNetSalary}
          minNetSalary={minNetSalary}
          handleSetError={handleSetError}
          intervalMinText={t('front.inputNumber.intervalMinText') as string}
          inputValue={currentStrategy.options.value.amount}
          onChangeInput={handleInputChange}
          handleSwitchChange={handleSwitchChange}
          switchOptions={switchOptions}
          selectedOption={currentStrategy.options.target}
        />
      )}
    </div>
  );
};
