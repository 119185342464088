import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { MainCard } from '@/modules/common/components/mainCard';
import { LineText } from '@/modules/common/components/fragments/LineText';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { addressDisplay } from '@/modules/common/utils/address.utils';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { Breadcrumbs } from '@/modules/navigation/components/Breadcrumbs';
import { RegisteredClientFrameworkContracts } from '../modules/framework_contracts/components/RegisteredClientFrameworkContracts';
import useRegisteredClient from '../hooks/useRegisteredClient';
import RegisteredClientInvoices from '../components/RegisteredClientInvoices';
import RegisteredClientAgents from '../components/RegisteredClientAgents';
import RegisteredClientClients from '../components/RegisteredClientClients';

const RegisteredClientShow = () => {
  const { id } = useParams();

  const { registeredClient, isLoading } = useRegisteredClient(String(id));

  return (
    <>
      <div className="mt-[50px] flex px-[20px]">
        <p className="big-title">
          {t('views.client.show_title.registered_client')}
          {' - '}
          {registeredClient?.name}
        </p>
      </div>
      <Breadcrumbs />
      <div className="flex">
        <MainCard
          header={t('views.client.show_title.legal_informations')}
          isLoading={isLoading}
          className="mt-[20px] mx-5 md:w-[50%]"
        >
          <LineText
            label={t('activerecord.attributes.registered_client.siren', {
              ns: 'rails',
            })}
            value={registeredClient?.siren}
          />
          <LineText
            label={t('activerecord.attributes.registered_client.nic', {
              ns: 'rails',
            })}
            value={registeredClient?.nic}
          />
          <LineText
            label={t('activerecord.attributes.registered_client.vat', {
              ns: 'rails',
            })}
            value={registeredClient?.vat}
          />
          <LineText
            label={t('activerecord.attributes.registered_client.legal_form', {
              ns: 'rails',
            })}
            value={registeredClient?.legalForm}
          />
          <LineText
            label={t(
              'activerecord.attributes.registered_client.activity_domain',
              { ns: 'rails' },
            )}
            value={registeredClient?.activityDomain?.name}
          />
          <LineText
            label={t('activerecord.attributes.client.ape_code', {
              ns: 'rails',
            })}
            value={registeredClient?.apeCode}
          />
          <LineText
            label={t('activerecord.attributes.client.registered_capital', {
              ns: 'rails',
            })}
            value={registeredClient?.registeredCapital}
          />
          <LineText
            label={t('activerecord.attributes.client.represented_by', {
              ns: 'rails',
            })}
            value={registeredClient?.representedBy}
          />
          <LineText
            label={t('activerecord.attributes.registered_client.partner', {
              ns: 'rails',
            })}
            value={t(`default.${registeredClient?.partner}`)}
          />
          <LineText
            label={t('activerecord.attributes.registered_client.address', {
              ns: 'rails',
            })}
            value={addressDisplay({
              record: registeredClient,
              prefix: 'address',
            })}
          />
        </MainCard>
        <MainCard
          header={t('views.client.show_title.contact')}
          isLoading={isLoading}
          className="mt-[20px] mx-5 md:w-[50%]"
        >
          <LineText
            label={t(
              'activerecord.attributes.registered_client.contact_full_name',
              { ns: 'rails' },
            )}
            value={registeredClient?.contactFullName}
          />
          <LineText
            label={t(
              'activerecord.attributes.registered_client.contact_function',
              { ns: 'rails' },
            )}
            value={registeredClient?.contactFunction}
          />
          <LineText
            label={t(
              'activerecord.attributes.registered_client.contract_signature_email',
              { ns: 'rails' },
            )}
            value={registeredClient?.contractSignatureEmail}
          />
          <LineText
            label={t(
              'activerecord.attributes.registered_client.purchase_order_signature_email',
              { ns: 'rails' },
            )}
            value={registeredClient?.purchaseOrderSignatureEmail}
          />
          <LineText
            label={t(
              'activerecord.attributes.registered_client.invoice_emails',
              { ns: 'rails' },
            )}
            value={(registeredClient?.invoiceEmails || []).join(', ')}
          />
          <LineText
            label={t(
              'activerecord.attributes.registered_client.invoice_address',
              { ns: 'rails' },
            )}
            value={addressDisplay({
              record: registeredClient,
              prefix: 'invoiceAddress',
            })}
          />
          <LineText
            label={t(
              'activerecord.attributes.registered_client.invoice_phone',
              { ns: 'rails' },
            )}
            value={registeredClient?.invoicePhone}
          />
          <LineText
            label={t(
              'activerecord.attributes.registered_client.contact_phone',
              { ns: 'rails' },
            )}
            value={registeredClient?.contactPhone}
          />
        </MainCard>
      </div>
      <div className="flex">
        <MainCard
          header={t('views.registered_client.admin_title')}
          className="mt-[20px] mx-5 md:w-[50%]"
        >
          <LineText
            label={t(
              'activerecord.attributes.registered_client.account_number',
              {
                ns: 'rails',
              },
            )}
            value={registeredClient?.accountNumber}
          />
        </MainCard>
        <MainCard
          header={{
            title: t('activerecord.models.framework_contracts', {
              ns: 'rails',
            }),
            actionButtons: (
              <ButtonPrimary to="framework_contracts/new">
                {t('front.framework_contract.index.header.buttons.new')}
              </ButtonPrimary>
            ),
          }}
          className="mt-[20px] mx-5 md:w-[50%]"
        >
          {registeredClient && (
            <RegisteredClientFrameworkContracts
              registeredClient={registeredClient}
            />
          )}
        </MainCard>
      </div>
      <div className="flex">
        <MainCard
          header={{
            title: t('views.registered_client.users_title'),
            actionButtons: (
              <ButtonPrimary className="w-[196px]" to="agents/new">
                Créer un accès
              </ButtonPrimary>
            ),
          }}
          className="mt-[20px] mx-5 md:w-[50%]"
        >
          {registeredClient && (
            <RegisteredClientAgents registeredClient={registeredClient} />
          )}
        </MainCard>
        <MainCard
          header={t('activerecord.models.registered_client_links')}
          className="mt-[20px] mx-5 md:w-[50%]"
        >
          {registeredClient && (
            <RegisteredClientClients registeredClient={registeredClient} />
          )}
        </MainCard>
      </div>
      <div className="flex">
        <MainCard
          header={t('views.registered_client.stats')}
          className="mt-[20px] mx-5 md:w-[50%]"
        >
          {registeredClient && (
            <>
              <LineText
                label={t('views.registered_client.due_payments')}
                value={
                  <AmountWithCurrency amount={registeredClient.dueAmountEuro} />
                }
              />
              <LineText
                label={t('views.registered_client.overdue_payments')}
                value={
                  <AmountWithCurrency
                    amount={registeredClient.overdueAmountEuro}
                  />
                }
              />
              <LineText
                label={t('views.registered_client.financed_amount')}
                value={
                  <AmountWithCurrency
                    amount={registeredClient.financedAmountEuro}
                  />
                }
              />
            </>
          )}
        </MainCard>
        <MainCard
          header={t('views.invoice.index_admin')}
          className="mt-[20px] mx-5 md:w-[50%]"
        >
          {registeredClient && (
            <RegisteredClientInvoices registeredClient={registeredClient} />
          )}
        </MainCard>
      </div>
    </>
  );
};

export default RegisteredClientShow;
