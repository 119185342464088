import { SepaTransaction } from '@/app/spraypaint';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { TypedLoaderFunction } from '@/types';
import { RecordProxy } from 'spraypaint/lib-esm/proxies';
import { redirectTo } from '@/app/navigation/functions';
import { fetchFromQueryClient } from '@/app/utils/fetchFromQueryClient';

export function sepaTransactionQuery(id: string) {
  // params a envoyer a usequery
  return {
    queryKey: ['get-bank-reconciliation-information', id],
    queryFn: async () =>
      SepaTransaction.includes('legal_entity')
        .selectExtra([
          'previous_pending_transaction_id',
          'next_pending_transaction_id',
        ])
        .find(id),
  };
}

export function loader(
  queryClient: QueryClient,
): TypedLoaderFunction<RecordProxy<SepaTransaction>> {
  return async ({ params }) => {
    const { id } = params;

    // if id is undefined, or is not a number-string
    if (!id || Number.isNaN(Number(id)))
      // Allow Response to be thrown here because this is an expected
      // behavior from React Router
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new Response('Not found', { status: 404, statusText: 'Not Found' });

    const query = sepaTransactionQuery(id as string);

    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
}

const redirectToShow = redirectTo(
  (params) => `/v2/admin/bank_reconciliation/${params.id}`,
);

export function sepaTransactionAssignationLoader(
  queryClient: QueryClient,
): TypedLoaderFunction<RecordProxy<SepaTransaction>> {
  return async (args) => {
    const { id } = args.params;

    // if id is undefined, or is not a number-string
    if (!id || Number.isNaN(Number(id)))
      // Allow Response to be thrown here because this is an expected
      // behavior from React Router
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new Response('Not found', { status: 404, statusText: 'Not Found' });

    const query = sepaTransactionQuery(id as string);

    const sepaStransactionResult = await fetchFromQueryClient(
      queryClient,
      query,
    );

    if (sepaStransactionResult.data.status !== 'pending_validation') {
      return redirectToShow(args);
    }

    return sepaStransactionResult;
  };
}

export function useSepaTransaction(id: string) {
  return useQuery(sepaTransactionQuery(id));
}
