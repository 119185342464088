/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import { FactorCandidate } from '../../../../../app/spraypaint';

export const useGetMatchingCandidates = ({
  scopeKey,
  scope,
  transactionId,
  onSuccess,
}: {
  scopeKey?: readonly unknown[];
  transactionId: unknown;
  onSuccess?: (candidates: Array<FactorCandidate['candidate']>) => void;
  scope: (baseScope: Scope<FactorCandidate>) => Scope<FactorCandidate>;
}) => {
  const data = useQuery({
    queryKey: ['factor-candidates', scopeKey],
    queryFn: () =>
      scope(
        FactorCandidate.includes('candidate.client,candidate.debtor')
          .where({
            transactionableId: transactionId,
            transactionableType: 'Factor::ClientPaymentTransaction',
            upToDate: true,
          })
          .order('reason')
          .scope(),
      ).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onSuccess: (response) => {
      if (onSuccess) {
        onSuccess(
          response?.data?.map(
            (candidate: FactorCandidate) => candidate.candidate,
          ) || [],
        );
      }
    },
  });

  const totalCount = data?.data?.meta.stats.total.count;
  const candidates =
    data?.data?.data?.map(
      (candidate: FactorCandidate) => candidate.candidate,
    ) || [];

  return { ...data, candidates, totalCount };
};
