import { useBackendTable } from '@common/components/table/hooks';
import { Invoice } from '@/app/spraypaint';
import { useQuery } from '@tanstack/react-query';

export const useProvisionalInvoices = ({
  invoiceId,
}: {
  invoiceId: string | undefined;
}) => {
  const [scope, scopeKey, reactTableProps] = useBackendTable<Invoice>();
  const { data, refetch } = useQuery({
    queryKey: ['provisional-invoices', invoiceId, scopeKey],
    queryFn: async () =>
      scope(
        Invoice.where({
          invoiceType: 'provisional',
          corporateInvoiceId: invoiceId,
        })
          .selectExtra(['assignedCorporateHtAmount'])
          .includes(['employee', 'breakdownLines.clientPayment'])
          .order('id')
          .scope(),
      ).all(),
  });

  return { data, reactTableProps, refetch };
};
