import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import RegisteredClientAgent from '@/app/spraypaint/RegisteredClientAgent';

const useRegisteredClientAgents = (
  registeredClientId: number,
  scope: (
    baseScope: Scope<RegisteredClientAgent>,
  ) => Scope<RegisteredClientAgent>,
  randomProps: unknown,
) => {
  const data = useQuery({
    queryKey: ['registered-client-agents', randomProps],
    queryFn: () =>
      scope(
        RegisteredClientAgent.where({ registeredClientId })
          .includes(['user'])
          .scope(),
      ).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const agents = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, agents, totalCount };
};

export default useRegisteredClientAgents;
