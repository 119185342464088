import { Model, Attr, BelongsTo } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';
import type Invoice from './Invoice';

@Model()
class InvoiceLine extends ApplicationRecord {
  static jsonapiType = 'invoice_lines';

  // Attributes
  @Attr() invoiceId!: number;
  @Attr() description!: string;
  @Attr() quantity!: number;
  @Attr() price!: MoneyType;
  @Attr({ persist: false }) amount!: MoneyType;
  @Attr() billableType!: string;
  @Attr() billableId!: number;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;

  // Extra-attributes

  // Relationships
  @BelongsTo('invoices') invoice!: Invoice;
}

export default InvoiceLine;
