import type { FunctionComponent } from 'react';
import RegisteredClient from '@spraypaint/RegisteredClient';
import { useQuery } from '@tanstack/react-query';
import FrameworkContract from '@spraypaint/FrameworkContract';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import Table from '@/modules/common/components/table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';

export interface RegisteredClientFrameworkContractsProps {
  registeredClient: RegisteredClient;
}

const columnHelper = createColumnHelper<ModelRecord<FrameworkContract>>();

export const RegisteredClientFrameworkContracts: FunctionComponent<
  RegisteredClientFrameworkContractsProps
> = ({ registeredClient }) => {
  const [scope, key, reactTableProps] = useBackendTable<FrameworkContract>();

  const { data, isLoading } = useQuery({
    queryKey: [
      'registered_client_framework_contracts',
      registeredClient.id,
      key,
    ],
    queryFn: async () =>
      scope(
        FrameworkContract.where({
          registered_client_id: registeredClient.id,
        }).scope(),
      ).all(),
    refetchOnWindowFocus: false,
  });

  const totalCount = data?.meta?.stats?.total.count;

  const frameworkContracts: ModelRecord<FrameworkContract>[] = data?.data ?? [];

  return (
    <Table
      className="mt-[20px]"
      isLoading={isLoading}
      totalCount={totalCount}
      data={frameworkContracts}
      reactTableProps={reactTableProps}
      columns={[
        columnHelper.accessor('id', { header: 'Id' }),
        columnHelper.accessor('name', { header: 'Name' }),
      ]}
      onRowClick={{
        navigate: (rowData) => `framework_contracts/${rowData.id}`,
      }}
      withImageErrorMsg={false}
      emptyTableErrorMsg=""
    />
  );
};
