import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CraMaxDaysInfoType } from '../hooks';
import { CraInfoText } from './fragments/CraInfoText';

type Props = {
  craMaxDaysInfos?: CraMaxDaysInfoType;
};

export const CalendarCraInfo = ({ craMaxDaysInfos }: Props) => {
  const { t } = useTranslation();

  const paidHolidays = craMaxDaysInfos?.paidHolidays ?? 0;
  const maxHolidays = craMaxDaysInfos?.maxHolidays ?? 0;
  const workingTime = craMaxDaysInfos?.workingTime ?? 0;
  const maxWorkingDays = craMaxDaysInfos?.maxWorkingDays ?? 0;

  const holidaysSpanClassName = classNames({
    'text-color-failure': Number(paidHolidays) > Number(maxHolidays),
  });

  const workingDaysSpanClassName = classNames({
    'text-color-failure': Number(workingTime) > Number(maxWorkingDays),
  });
  return (
    <div className="mt-[68px] ml-[30px]">
      <CraInfoText
        title={t('front.payslip.tdc.cra.daysWorked')}
        innerComponent={
          <span>
            <span className={workingDaysSpanClassName}>{workingTime}</span>

            <span className="text-color-medium-grey">/{maxWorkingDays}</span>
          </span>
        }
      />
      <CraInfoText
        clasName="mt-[32px]"
        title={t('front.payslip.tdc.cra.expensecard.holidays')}
        innerComponent={
          <span>
            <span className={holidaysSpanClassName}>{paidHolidays}</span>
            <span className="text-color-medium-grey">/{maxHolidays}</span>
          </span>
        }
      />
    </div>
  );
};
