import { useQuery } from '@tanstack/react-query';
import User from '@spraypaint/User';

export const useCurrentOnboardingFunnel = () => {
  const {
    data: userData,
    isLoading,
    isError,
    isSuccess,
    isFetching: isFetchingUser,
    refetch,
  } = useQuery({
    queryKey: ['current-onboarding-funnel'],
    queryFn: () =>
      User.includes({
        onboarding_funnel: [
          'identity_step',
          'citizenship_step',
          'portage_offer_step',
          'review_step',
          'final_step',
        ],
      })
        .select(['none'])
        .find('current'),
    staleTime: 1000 * 60 * 2, // 2 minutes
    onSuccess: (response) => response?.data,
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
  const user = userData?.data;
  const onboardingFunnel = user?.onboardingFunnel || undefined;

  return {
    userData,
    user,
    refetch,
    isFetchingUser,
    onboardingFunnel,
    isLoading,
    isError,
    isSuccess,
  };
};
