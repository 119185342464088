import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';
import { LinkPropsBuilder } from '@/types';
import ApplicationRecord from './ApplicationRecord';
import type ActivityDomain from './ActivityDomain';
import type Client from './Client';
import type RegisteredClientAgent from './RegisteredClientAgent';
import type FrameworkContract from './FrameworkContract';

@Model()
class RegisteredClient extends ApplicationRecord {
  static jsonapiType = 'registered_clients';

  constructor(...args: ConstructorParameters<typeof ApplicationRecord>) {
    super(...args);
    this.linkProps = this.linkProps.bind(this);
  }

  // Attributes
  @Attr() name!: string;
  @Attr() activity!: string;
  @Attr() partner!: boolean;
  @Attr() activityDomainId!: number;
  @Attr() siren!: string;
  @Attr() nic!: string;
  @Attr() vat!: string;
  @Attr() legalForm!: string;
  @Attr() apeCode!: string;
  @Attr() registeredCapital!: number;
  @Attr() representedBy!: string;
  @Attr() accountNumber!: string;
  @Attr() address!: string;
  @Attr() addressNumber!: string;
  @Attr() addressRoad!: string;
  @Attr() addressCity!: string;
  @Attr() addressDetail!: string;
  @Attr() addressZipcode!: string;
  @Attr() addressCountry!: string;
  @Attr() addressCountryCode!: string;
  @Attr() invoiceAddress!: string;
  @Attr() invoiceAddressDetail!: string;
  @Attr() invoiceAddressNumber!: string;
  @Attr() invoiceAddressRoad!: string;
  @Attr() invoiceAddressCity!: string;
  @Attr() invoiceAddressZipcode!: string;
  @Attr() invoiceAddressCountry!: string;
  @Attr() invoiceAddressCountryCode!: string;
  @Attr() contactFullName!: string;
  @Attr() contactFunction!: string;
  @Attr() contractSignatureEmail!: string;
  @Attr() purchaseOrderSignatureEmail!: string;
  @Attr() invoiceEmails!: string[];
  @Attr() invoicePhone!: string;
  @Attr() contactPhone: string;
  @Attr() dueAmountEuro: number;
  @Attr() overdueAmountEuro: number;
  @Attr() financedAmountEuro: number;

  @BelongsTo('activity_domains') activityDomain!: ActivityDomain;
  @HasMany('clients') clients!: Client[];
  @HasMany('agents') agents!: RegisteredClientAgent[];
  @HasMany('framework_contracts') frameworkContracts!: Array<FrameworkContract>;

  linkProps: LinkPropsBuilder = ({ pathBuilder }) => {
    if (!this?.id) return undefined;
    return {
      children: this.name,
      to: pathBuilder(`/registered_clients/${this.id}`),
    };
  };
}

export default RegisteredClient;
