// api/Invoice.ts
import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';
import type {
  CurrencyIsoCode,
  MoneyType,
  PeriodType,
} from '@/modules/common/utils/type.utils';
import type InvoiceLine from '@spraypaint/InvoiceLine';
import type RegisteredClient from '@spraypaint/RegisteredClient';
import { LinkPropsBuilder } from '@/types';
import ApplicationRecord from './ApplicationRecord';
import Employee from './Employee';
import type Client from './Client';
import type Opco from './Opco';
import CommercialContract from './CommercialContract';
// import type BreakdownLine from './BreakdownLine';
// import type Credit from './Credit';
import Transition from './Transition';
import SepaTransaction from './sepaTransactions/SepaTransaction';
import BreakdownLine from './BreakdownLine';
import { Credit } from './index';
import BankAccount from './BankAccount';

export const INVOICE_TYPES = [
  'professional_client',
  'individual_client',
  'subcontractor',
  'credit',
  'expense',
  'intern_subcontractor',
  'corporate',
  'provisional',
] as const;
export type InvoiceTypeEnum = typeof INVOICE_TYPES[number];

export const INVOICE_STATUSES = [
  'in_progress',
  'submitted',
  'paid',
  'rejected',
  'irrecoverable',
  'partially_paid',
  'validated',
] as const;
export type StatusEnum = typeof INVOICE_STATUSES[number];

@Model()
class Invoice extends ApplicationRecord {
  static jsonapiType = 'invoices';

  constructor(...args: ConstructorParameters<typeof ApplicationRecord>) {
    super(...args);
    this.linkProps = this.linkProps.bind(this);
  }

  // Attributes
  @Attr({ persist: false }) number!: string;
  @Attr() date!: string | Date;
  @Attr() dueDate!: string;
  @Attr({ persist: false }) invoiceType!: InvoiceTypeEnum;
  @Attr() memo!: string;
  @Attr({ persist: false }) label!: string;
  @Attr({ persist: false }) paidAmount!: MoneyType;
  @Attr({ persist: false }) totalAmount!: MoneyType;
  @Attr({ persist: false }) totalAmountWithVat!: MoneyType;
  @Attr({ persist: false }) totalVatAmount!: MoneyType;
  @Attr({ persist: false }) vatRate!: number;
  @Attr({ persist: false }) remainingAmount!: MoneyType;
  @Attr({ persist: false }) vatCgiSlug!: string;
  @Attr({ persist: false }) documentLanguage!: string;
  @Attr({ persist: false }) currencyIsoCode!: CurrencyIsoCode;
  @Attr({ persist: false }) sendDate!: string;
  @Attr({ persist: false }) payslipDate!: PeriodType;
  @Attr({ persist: false }) status!: StatusEnum;
  @Attr({ persist: false }) cacheRemainingAmountEuro!: MoneyType;
  @Attr({ persist: false })
  cacheExchangeValueEuroTotalAmountWithVat!: MoneyType;
  @Attr({ persist: false }) cacheExchangeValueEuroHtRemainingAmount!: MoneyType;
  @Attr({ persist: false }) cacheExchangeValueEuroTotalAmount!: MoneyType;
  @Attr({ persist: false }) dateOfPayment!: string;
  @Attr() externalReference!: string;
  @Attr({ persist: false }) securitis!: boolean;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) clientId!: number;
  @Attr({ persist: false }) employeeId!: number;
  @Attr({ persist: false }) commercialContractId!: number;
  @Attr({ persist: false }) debtorId!: number;
  @Attr({ persist: false }) debtorType!: string;
  @Attr() provisionalInvoicesIds!: Array<string>;
  @Attr() corporateInvoiceId!: string | null;

  // Extra attributes
  @Attr({ persist: false }) generatedDocumentUrl!: string | undefined;
  @Attr({ persist: false }) assignedCorporateHtAmount!: MoneyType | null;

  // Relationships
  @BelongsTo('employees') employee!: Employee;
  @BelongsTo('commercial_contracts') commercialContract!: CommercialContract;
  @BelongsTo('banks_accounts') bankAccount!: BankAccount;

  @HasOne('clients') client!: Client;
  @HasMany('invoice_lines') invoiceLines!: Array<InvoiceLine>;
  @HasMany('breakdown_lines') breakdownLines!: Array<BreakdownLine>;
  @HasMany('credits') credits!: Array<Credit>;
  @HasMany('transitions') transitions!: Array<Transition>;
  @HasMany('sepa-transactions')
  matchingSepaTransactionsCandidates!: SepaTransaction[];
  @HasMany('provisional_invoices') provisionalInvoices!: Array<Invoice>;

  @HasOne({ name: 'debtor' }) debtor!: Client | Opco | RegisteredClient;

  get clientName(): string | undefined {
    return this.client?.name;
  }

  get employeePagePath(): string | undefined {
    const { employeeId, id } = this;

    if (!employeeId || !id) {
      return undefined;
    }

    return `/employees/${employeeId}/invoices/${id}`;
  }

  get adminPagePath(): string | undefined {
    const { id } = this;

    if (!id) {
      return undefined;
    }

    return `/v2/admin/invoices/${id}`;
  }

  // Use a method to comply with the expected interface
  // eslint-disable-next-line class-methods-use-this
  get associableType(): string {
    return 'Invoice';
  }

  linkProps: LinkPropsBuilder = ({ pathBuilder }) => ({
    children: this.number,
    to: pathBuilder(`/invoices/${this.id}`),
  });
}

export default Invoice;
