import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button } from '../../../common/components/buttons';
import { CloseIcon } from '../../../../app/assets/icons/buttons';
import PayslipFunnelsActivityAccountLine from '../../../../app/spraypaint/payslipFunnel/PayslipFunnelActivityAccountLine';
import { Line } from '../../../common/components/fragments';
import { CustomModal } from '../../../common/components/fragments/CustomModal';
import { useBackendTable } from '../../../common/components/table/hooks';
import Table from '../../../common/components/table/Table';
import { useGetActivityAccountLine } from '../hooks/useGetActivityAccountLine';
import { ActivityAccountLineColumns } from '../table/ActivityAccountLineColumns';
import { ClassificationType } from '../types/ClassificationType';
import useBreakpoints from '../../../../app/responsive/useBreakPoints';
import { EmptyDataMobileLayout } from '../../../common/components/table/components/mobile/EmptyDataMobileLayout';
import { MobileTableRow } from '../../../common/components/table/mobile/MobileTableRow';

type Props = {
  displayModal: boolean;
  handleSetCloseModal: any;
  classification: ClassificationType;
  payslipFunnelId: number;
};

const mapClassificationToTitle = {
  turnover: 'front.payslip.tdc.expense.balanceCard.activity',
  management_fees: 'front.payslip.tdc.expense.balanceCard.management',
  subcontractor: 'front.payslip.tdc.expense.balanceCard.subcontracting',
  service_plus: 'front.payslip.tdc.expense.balanceCard.services',
  advance: 'front.payslip.tdc.expense.balanceCard.deposits',
};

const mapClassificationToErrorTableMsg = {
  turnover: 'front.payslip.tdc.expense.balanceCard.activity',
  management_fees: 'front.payslip.tdc.expense.balanceCard.management',
  subcontractor: 'front.payslip.tdc.expense.balanceCard.subcontracting',
  service_plus: 'front.payslip.tdc.expense.balanceCard.services',
  advance: 'front.payslip.tdc.expense.balanceCard.deposits',
};

export const BalanceDisplayModal = ({
  displayModal,
  payslipFunnelId,
  handleSetCloseModal,
  classification,
}: Props) => {
  const [scope, scopeKey, revenueSummaryProps] =
    useBackendTable<PayslipFunnelsActivityAccountLine>();
  const { data, isLoading: activityLineLoader } = useGetActivityAccountLine(
    payslipFunnelId,
    scope,
    scopeKey,
    classification,
  );
  const { isXs } = useBreakpoints();
  const { t } = useTranslation();

  return (
    <CustomModal
      isOpen={displayModal}
      onRequestClose={handleSetCloseModal}
      contentLabel="Example Modal"
    >
      <div className="flex flex-col fill-color-medium-grey w-[300px] md:w-[1200px] max-h-[650px] md:mb-[80px]">
        <Button
          custom
          className="self-end mt-[25px] mr-[25px]"
          onClick={handleSetCloseModal}
        >
          <CloseIcon />
        </Button>
        <p className="text-base3 md:text-xl text-color-medium-grey ml-[20px]">
          {t(mapClassificationToTitle[classification])}
        </p>
        <Line rounded className="mt-2.5" />
        {isXs ? (
          <div className="shadow-table">
            {!data?.data?.length ? (
              <EmptyDataMobileLayout
                emptyDataErrorMsg={t(
                  mapClassificationToErrorTableMsg[classification],
                )}
              />
            ) : (
              data?.data.map((line, index) => (
                <MobileTableRow
                  index={index}
                  date={dayjs(line.date).format('L')}
                  label={<p className="text-sm1 text-left">{line.label}</p>}
                  amount={line.value.amount}
                />
              ))
            )}
          </div>
        ) : (
          <Table
            className="mt-[32px]"
            emptyTableErrorMsg={
              mapClassificationToErrorTableMsg[classification]
            }
            totalCount={data?.meta.stats.total.count}
            isCollapsed={false}
            isLoading={activityLineLoader}
            data={data?.data || []}
            reactTableProps={revenueSummaryProps}
            columns={ActivityAccountLineColumns()}
          />
        )}
      </div>
    </CustomModal>
  );
};
