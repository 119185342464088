import { RoutesMounter } from '@/types';
import { index, member, redirectTo } from '@/app/navigation/functions';
import { buildCreditFindQueryLoader } from '@/modules/admin/credits/functions/creditQuery';
import { ToDo } from '@/modules/common/components/ToDo';

export const mountAdminCreditsRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { queryClient, t },
}) => {
  const showLoader = buildCreditFindQueryLoader(queryClient);

  return [
    member({
      path,
      handle: { crumb: () => 'Avoir' },
      children: [
        index({ loader: redirectTo('/invoices') }),
        member({
          path: ':id',
          handle: {
            crumb: ({ data }) =>
              t('front.invoices.crumbs.show', { number: data?.data?.number }),
          },
          loader: async (...args) => {
            const data = await showLoader(...args);

            // return if data is a Response
            if (data instanceof Response) {
              return data;
            }

            const creditData = data?.data;
            const employeeId = creditData?.employeeId;
            if (employeeId) {
              return redirectTo(
                `/employees/${employeeId}/credits/${creditData?.id}`,
              )(...args);
            }

            return data;
          },
          children: [index({ Component: ToDo })],
        }),
      ],
    }),
  ];
};
