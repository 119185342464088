import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { PriumLogo } from '../../../app/assets/icons/priumLogo';
import { LanguageSwitch } from '../../../app/translation/components';
import { supportedLanguages } from '../../../app/translation/constants/supportedLanguages';

export const AuthHeader = () => {
  const logoClassName = classNames('h-[52px] w-[172px]');
  const headerLineClassName = classNames(
    'h-[3px] w-[172px] bg-color-pone-orange rounded-r-lg',
  );
  return (
    <div>
      <div className="mt-[54px] mb-[67px] pl-[54px] flex w-full justify-between items-center">
        <Link to="/v2/login">
          <PriumLogo data-testid="prium-logo" className={logoClassName} />
        </Link>
        <LanguageSwitch className="mr-[72px]" languages={supportedLanguages} />
      </div>
      <div className={headerLineClassName} />
    </div>
  );
};
