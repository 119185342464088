import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useTranslation } from 'react-i18next';
import { MoneyType } from '@/modules/common/utils/type.utils';
import { MouseEvent, KeyboardEvent } from 'react';
import {
  DateFilter,
  getDateFilterFn,
} from '@/modules/common/components/table/filter/DateFilter';
import dayjs from 'dayjs';
import { CandidateType } from '@/modules/admin/accounting/CandidateType';
import {
  SupInfFilter,
  getSupInfFilter,
} from '../../../../common/components/table/filter/SupInfFilter';
import {
  ContribDeposit,
  ContribPeeDeposit,
  ContribPercoDeposit,
  Invoice,
  SepaImport,
  SepaTransaction,
  SubcontractorBill,
} from '../../../../../app/spraypaint';
import { CheckBox } from '../../../../common/components/inputs';

const columnHelper = createColumnHelper<ModelRecord<ContribDeposit>>();

export const contribDepositListColumns = ({
  handleAddCandidate,
  handleRemoveCandidate,
  sepaMatchingScoreList,
  sepaTransaction,
  disabledForManager,
}: {
  handleAddCandidate: (
    candidateToMove: CandidateType,
    sepaTransaction: SepaTransaction,
  ) => () => void;
  handleRemoveCandidate: (candidate: CandidateType) => () => void;
  sepaMatchingScoreList: Array<
    | Invoice
    | SubcontractorBill
    | SepaImport
    | ContribPeeDeposit
    | ContribPercoDeposit
  >;
  sepaTransaction: SepaTransaction;
  disabledForManager: boolean;
}) => {
  const { t } = useTranslation();

  const ActionsCell = ({
    cell,
  }: CellContext<ModelRecord<ContribDeposit>, unknown>) => {
    const contrib = cell.row.original;
    const isChecked = !!sepaMatchingScoreList.find(
      (storedContrib) =>
        ((storedContrib instanceof ContribPeeDeposit &&
          contrib.contribType === 'ContribPeeDeposit') ||
          (storedContrib instanceof ContribPercoDeposit &&
            contrib.contribType === 'ContribPercoDeposit')) &&
        storedContrib.id === contrib.contribId,
    );

    const onChange = isChecked
      ? handleRemoveCandidate(contrib.contrib as CandidateType)
      : handleAddCandidate(contrib.contrib as CandidateType, sepaTransaction);

    const handleClick = (
      e: MouseEvent<HTMLInputElement, globalThis.MouseEvent>,
    ) => {
      e.stopPropagation();
      onChange();
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onChange();
      }
    };

    return disabledForManager ? null : (
      <div
        className="flex justify-center h-[100%]"
        style={{ alignItems: 'center' }}
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        role="button"
        tabIndex={0}
      >
        <CheckBox
          id={contrib.id}
          checked={isChecked}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    );
  };

  return [
    columnHelper.accessor('contribType', {
      header: "Plan d'épargne",
      id: 'contrib_type',
      cell: (info) => (
        <div className="text-center">
          <p>
            {t(`front.bankAssociation.table.contribDeposit.${info.getValue()}`)}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor('number', {
      header: 'Numéro de Contribution',
    }),
    columnHelper.accessor((original) => original.contrib?.employee?.fullName, {
      header: 'Employé',
      id: 'full_name',
    }),
    columnHelper.accessor('amount', {
      // filter working
      header: 'Montant',
      filterFn: getSupInfFilter(),
      meta: {
        filter: {
          Component: SupInfFilter,
        },
      },
      cell: (info) => (
        <div className="text-left">
          <AmountWithCurrency amount={info.getValue() as MoneyType} />
        </div>
      ),
    }),
    columnHelper.accessor((original) => dayjs(original.createdAt).format('L'), {
      header: t('default.created_at'),
      id: 'created_at',
      filterFn: getDateFilterFn(),
      meta: {
        filter: {
          Component: DateFilter,
        },
      },
    }),
    columnHelper.accessor('status', {
      header: t('default.status'),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('actions', {
      header: t('default.actions'),
      enableColumnFilter: false,
      enableSorting: false,
      cell: ActionsCell,
    }),
  ];
};
