import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import { IconButton } from '../../buttons';
import {
  BtnRadio,
  BtnRadioSelected,
} from '../../../../../app/assets/icons/inputs';

function SelectionCell<TData, TValue>({
  row,
  isHoveringRow,
}: CellContext<TData, TValue> & { isHoveringRow?: boolean }) {
  const isSelected = row.getIsSelected();
  if (!isHoveringRow && !isSelected) return null;

  return (
    <IconButton
      className="fill-color-pone-orange w-[20px] h-[20px] ml-[10px]"
      Icon={isSelected ? BtnRadioSelected : BtnRadio}
      onClick={row.getToggleSelectedHandler()}
    />
  );
}

export function prependSelectionColumn<TData>(
  initialColumns: ColumnDef<TData, any>[],
  enableRowSelection: boolean,
): ColumnDef<TData, any>[] {
  if (!enableRowSelection) return initialColumns;

  const columnHelper = createColumnHelper<TData>();
  const selectionColumn = columnHelper.display({
    id: 'selectionColumn',
    cell: SelectionCell,
    size: 38,
    meta: {
      fixedWidth: true,
      noBorder: true,
    },
  });

  return [selectionColumn, ...initialColumns];
}
