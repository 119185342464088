import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import LegalEntity from './LegalEntity';
import Manager from './Manager';
import PortageOffer from './PortageOffer';

type PayslipComputationType = {
  worked_hours_count: number;
  monthly_revenue: { value: number; name: string };
  management_fee: { value: number; name: string };
  amount_total_insurance_fiscal: { value: number; name: string };
  business_expenses: { value: number; name: string };
  deductible_vat: { value: number; name: string };
  available_balance_without_payslip_guarantee: { value: number; name: string };
  employer_contributions: { value: number; name: string };
  paid_vacation_provision: { value: number; name: string };
  gross_salary: { value: number; name: string };
  base_salary: { value: number; name: string };
  allowance_provision: { value: number; name: string };
  additional_allowance: { value: number; name: string };
  business_finder_allowance: { value: number; name: string };
  financial_provision: { value: number; name: string };
  employee_contributions: { value: number; name: string };
  net_salary_basis: { value: number; name: string };
  paid_vacation_provision_in_gross_salary: boolean;
  indemnite_fin_contrat: { value: number; name: string };
};

@Model()
class PayslipSimulation extends ApplicationRecord {
  static jsonapiType = 'payslip_simulations';

  // Attributes
  @Attr() civility: string;
  @Attr() firstName!: string;
  @Attr() lastName!: string;
  @Attr() email!: string;
  @Attr() zipCode: string;
  @Attr() phone: string;
  @Attr() comment: string;

  @Attr() documentLanguage: string;
  @Attr() rateOfPay: number;
  @Attr() workingTimeUnit: string;
  @Attr() workingTimeCount: number;
  @Attr() monthDuration: number;
  @Attr() monthlyExpenses: number;
  @Attr() securitis: string;
  @Attr() employmentType: string;
  @Attr() employmentStatus: string;
  @Attr() expensesNonBillable: number;
  @Attr() grosSalary: number;
  @Attr() employerContribution: number;
  @Attr() netSalary: number;
  @Attr() employeeContribution: number;
  @Attr() rateStandard: number;
  @Attr() rateSecuritis: number;
  @Attr() rateBusinessExpense: number;
  @Attr() workingHoursInADay: number;
  @Attr() hourlyRate: number;
  @Attr() forSource: string;
  @Attr() healthInsurancePackage: string;
  @Attr() mealVoucher: boolean;
  @Attr() paidVacationProvisionInGrossSalary: boolean;
  @Attr() optimizationName: string;
  @Attr() optimizationValue: number;

  @Attr({ persist: false }) status: string;
  @Attr({ persist: false }) documentGeneratedAt: string;
  @Attr({ persist: false }) documentGeneratedId: string;
  @Attr({ persist: false }) documentGenerationId: string;
  @Attr({ persist: false }) documentGeneratioStatus: number;
  @Attr({ persist: false }) generatedDocumentUrl: string;
  @Attr({ persist: false }) generatedDocumentName: string;
  @Attr({ persist: false }) emailed: boolean;
  @Attr({ persist: false }) emailedAt: string;

  @Attr({ persist: false }) createdAt: string;
  @Attr({ persist: false }) updatedAt: string;

  @Attr() legalEntityId: number;
  @Attr() managerId: number;
  @Attr() prospectId: number;
  @Attr() portageOfferId: number;

  @Attr({ persist: false }) payslipComputation: PayslipComputationType;

  // Relationships
  @BelongsTo(LegalEntity) legalEntity!: LegalEntity;
  @BelongsTo(Manager) manager!: Manager;
  @BelongsTo(PortageOffer) portageOffer!: PortageOffer;

  get welcomePath(): string | undefined {
    const { id } = this;

    if (!id) {
      return undefined;
    }

    return `/v2/welcome/simulations/${id}`;
  }

  get title(): string {
    return `${this.employmentType} - ${this.employmentStatus} - Chiffre d'affaire ${this.payslipComputation?.monthly_revenue?.value} €`;
  }
}

export default PayslipSimulation;
