// api/PayslipFunnelSteps::ExpensesStep.ts
import { Model, Attr } from 'spraypaint';
import CostType from '@/modules/payslip_funnel/expenses/types/CostType';
import type { MoneyType } from '@common/utils/type.utils';
import CompletionErrorType from '../../../modules/payslip_funnel/types/CompletionErrorType';
import PayslipFunnelBaseStep from './PayslipFunnelBaseStep';

@Model()
class PayslipFunnelExpensesStep extends PayslipFunnelBaseStep {
  static jsonapiType = 'payslip_funnel_steps-expenses_steps';

  // Attributes
  @Attr({ persist: false }) mayComplete!: boolean;
  @Attr({ persist: false }) completed!: boolean;
  @Attr({ persist: false }) maxAmount!: MoneyType;
  @Attr({ persist: false }) refundableAmount!: MoneyType;
  @Attr({ persist: false }) costsTotalAmount!: MoneyType;

  // attribute mealVouchersCount is not used in the codebase
  @Attr({ persist: false }) mealVouchersCount!: number;
  @Attr({ persist: false }) toRejectCostsTotalAmount!: MoneyType;
  @Attr() selectedExpenseToAdd!: number | undefined;
  @Attr() selectedExpenseToRemove!: number | undefined;
  @Attr({ persist: false }) selectedExpensesIds!: Array<number>;
  @Attr({ persist: false }) completionErrors!: Array<CompletionErrorType>;

  // Extra-attributes
  @Attr({ persist: false }) expensesTables!: {
    available: Array<CostType>;
    selected: Array<CostType>;
    to_reject: Array<CostType>;
    future: Array<CostType>;
  };
}

export default PayslipFunnelExpensesStep;
