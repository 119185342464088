import { index, member, redirectTo } from '@/app/navigation/functions';
import { loader } from '@/modules/admin/registered_clients/hooks/useRegisteredClient';
import { FrameworkContractNew } from '@/modules/admin/registered_clients/modules/framework_contracts/pages/FrameworkContractNew';
import {
  FrameworkContractShow,
  queryOpts as frameworkContractqueryOpts,
} from '@/modules/admin/registered_clients/modules/framework_contracts/pages/FrameworkContractShow';
import { buildFrameworkContractFindQueryLoader } from './modules/framework_contracts/functions/frameworkContractQuery';
import { RoutesMounter } from '../../../types';
import RegisteredClientAgentNew from '../registered_client_agents/pages/RegisteredClientAgentNew';
import RegisteredClientShow from './pages/RegisteredClientShow';
import RegisteredClientsIndex from './pages/RegisteredClientsIndex';
import { FrameworkContractNewConsolidation } from './modules/framework_contracts/pages/FrameworkContractNewConsolidation';

export const mountRegisteredClientsRoutes: RoutesMounter = ({
  path,
  tools: { queryClient, t },
}) => [
  member({
    path,
    handle: { crumb: () => 'Registered Clients' },
    children: [
      index({ index: true, Component: RegisteredClientsIndex }),
      member({
        path: ':id',
        handle: { crumb: ({ data }) => data.data.name },
        loader: loader(queryClient),
        id: 'registered_client_root',
        children: [
          index({
            Component: RegisteredClientShow,
          }),
          member({
            path: 'agents/new',
            handle: { crumb: () => 'New Agent' },
            Component: RegisteredClientAgentNew,
          }),
          member({
            path: 'framework_contracts',
            handle: {
              crumb: () =>
                t('activerecord.models.framework_contracts', { ns: 'rails' }),
            },
            children: [
              index({
                loader: redirectTo('..'),
              }),
              member({
                path: 'new',
                handle: { crumb: () => 'New' },
                Component: FrameworkContractNew,
              }),
              member({
                path: ':frameworkContractId',
                handle: { crumb: ({ data }) => data.data.name },
                id: 'framework_contract_root',
                loader: buildFrameworkContractFindQueryLoader(queryClient, {
                  idParam: 'frameworkContractId',
                  queryOpts: frameworkContractqueryOpts,
                }),
                children: [
                  index({
                    Component: FrameworkContractShow,
                  }),
                  member({
                    path: 'new_consolidation',
                    handle: { crumb: () => 'Consolidation' },
                    Component: FrameworkContractNewConsolidation,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ],
  }),
];
