import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PathBuilder } from '@/types';
import { RecordProxy } from 'spraypaint/lib-esm/proxies';
import { useCurrentEmployeeId } from '../../../app/hooks/useCurrentEmployeeId';
import Employee from '../../../app/spraypaint/Employee';

export const useCurrentEmployee = (
  opts: UseQueryOptions<RecordProxy<Employee>> = {},
) => {
  const employeeId = useCurrentEmployeeId();

  const data = useQuery({
    queryKey: ['employee-id'],
    queryFn: () =>
      Employee.includes([
        'legal_entity',
        'user',
        { manager: ['office'] },
        { commercialAgent: ['office'] },
      ])
        .selectExtra(['enabled_profiles', 'id'])
        .find(employeeId as string),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: !!employeeId, // disable unexpected employee index fetch on non-employee admin pages
    ...opts,
  });

  const employee = data?.data?.data;
  const options = employee?.enabledProfiles || [];

  const employeePath: PathBuilder = (path: string) =>
    `/v2/employees/${employee?.id}${path}`;

  return { ...data, options, employee, employeePath };
};

export const useCurrentEmployeeManager = (
  opts: UseQueryOptions<RecordProxy<Employee>> = {},
) => {
  const employeeId = useCurrentEmployeeId();
  const data = useQuery({
    queryKey: ['employee-id'],
    queryFn: () =>
      Employee.includes({ manager: ['office'] })
        .select(['id'])
        .find(employeeId as string),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...opts,
  });

  return { ...data };
};

export const useCurrentEmployeeCommercialAgent = (
  opts: UseQueryOptions<RecordProxy<Employee>> = {},
) => {
  const employeeId = useCurrentEmployeeId();
  const data = useQuery({
    queryKey: ['employee-id'],
    queryFn: () =>
      Employee.includes({ commercialAgent: ['office'] })
        .select(['id'])
        .find(employeeId as string),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...opts,
  });

  return { ...data };
};

export const useCurrentEmployeeWithPayslipFunnel = (
  opts: UseQueryOptions<RecordProxy<Employee>> = {},
) => {
  const employeeId = useCurrentEmployeeId();
  const { data, ...other } = useQuery({
    queryKey: ['employee-payslip-funnel', employeeId],
    queryFn: () =>
      Employee.includes([
        {
          current_payslip_funnel: ['contextChangeMessages'],
        },
      ])
        .selectExtra([
          'next_funnel_employment_contract_id',
          'next_funnel_auto_start_date',
          'next_funnel_period',
          'enabled_profiles',
        ])
        .find(employeeId as string),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...opts,
  });

  const currentPayslipFunnel = data?.data?.currentPayslipFunnel;
  const nextPayslipFunnelPeriod = data?.data?.nextFunnelPeriod;
  const nextFunnelEmploymentContractId =
    data?.data?.nextFunnelEmploymentContractId;
  const nextFunnelAutoStartDate = data?.data?.nextFunnelAutoStartDate;
  const options = data?.data?.enabledProfiles;
  const payslipFunnelId = parseInt(
    data?.data?.currentPayslipFunnel?.id ?? '',
    10,
  );
  const stc = data?.data?.currentPayslipFunnel?.stc;
  const contextChangeMessages =
    data?.data?.currentPayslipFunnel?.contextChangeMessages ?? [];

  return {
    ...other,
    options,
    currentPayslipFunnel,
    nextPayslipFunnelPeriod,
    nextFunnelEmploymentContractId,
    nextFunnelAutoStartDate,
    payslipFunnelId,
    contextChangeMessages,
    stc,
  };
};

export const useCurrentEmployeePayslipFunnel = (
  opts: UseQueryOptions<RecordProxy<Employee>> = {},
) => {
  const employeeId = useCurrentEmployeeId();
  const { data, ...other } = useQuery({
    queryKey: ['employee-payslip-funnel', employeeId],
    queryFn: () =>
      Employee.includes(['current_payslip_funnel'])
        .selectExtra([
          'next_funnel_employment_contract_id',
          'next_funnel_auto_start_date',
          'next_funnel_period',
        ])
        .find(employeeId as string),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...opts,
  });

  const currentPayslipFunnel = data?.data?.currentPayslipFunnel;
  const nextPayslipFunnelPeriod = data?.data?.nextFunnelPeriod;
  const nextFunnelEmploymentContractId =
    data?.data?.nextFunnelEmploymentContractId;
  const nextFunnelAutoStartDate = data?.data?.nextFunnelAutoStartDate;

  return {
    ...other,
    currentPayslipFunnel,
    nextPayslipFunnelPeriod,
    nextFunnelEmploymentContractId,
    nextFunnelAutoStartDate,
  };
};

export const useEmployeeFreelanceStackUrl = (
  opts: UseQueryOptions<RecordProxy<Employee>> = {},
) => {
  const employeeId = useCurrentEmployeeId();
  const data = useQuery({
    queryKey: ['freelance-stack-url', employeeId],
    queryFn: () =>
      Employee.selectExtra(['freelance_stack_url']).find(employeeId as string),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...opts,
  });
  const freelanceStackUrl = data?.data?.data?.freelanceStackUrl ?? '';
  return { freelanceStackUrl };
};
