import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  InputHTMLAttributes,
  MouseEventHandler,
  ReactNode,
  useMemo,
  useRef,
} from 'react';
import { ChangeHandler, FieldError } from 'react-hook-form';
import classNames from 'classnames';
import { ErrorIcon } from '../../../../app/assets/icons/inputs';
import { Error } from './components/Error';
import { Label } from './components';
import useAutosizeTextArea from './hooks/useAutosizeTextArea';

type InnerHTMLElement = HTMLInputElement | HTMLTextAreaElement;

export type TextInputProps = Omit<
  InputHTMLAttributes<InnerHTMLElement>,
  'value'
> & {
  errorText?: FieldError | undefined;
  placeholder?: string;
  className?: string;
  inputCustomClass?: string;
  inputContainerClassName?: string;
  labelClassName?: string;
  value?: string;
  isValidate?: boolean;
  disabled?: boolean;
  required?: boolean;
  textarea?: boolean;
  id?: string;
  label?: string;
  displayErrorIcon?: boolean;
  type?: string;
  testID?: string;
  LeftIcon?: ReactNode;
  RightInsideIcon?: ReactNode;
  ClearableBtn?: ReactNode;
  RightIcon?: ReactNode;
  onChange?: ChangeEventHandler<InnerHTMLElement> | ChangeHandler;
  onFocus?: FocusEventHandler<InnerHTMLElement>;
  onClick?: MouseEventHandler<InnerHTMLElement>;
  fullWidth?: boolean;
};

// Can't find a way to have a polymorphic ref with forwardRef
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TextInput = forwardRef<any, TextInputProps>(
  (
    {
      className,
      errorText,
      placeholder,
      isValidate,
      disabled,
      displayErrorIcon,
      label,
      type,
      LeftIcon,
      RightInsideIcon,
      ClearableBtn,
      RightIcon,
      value,
      inputCustomClass,
      labelClassName,
      id,
      testID = 'test-input-text',
      onClick,
      onFocus,
      onChange,
      textarea,
      required,
      inputContainerClassName,
      fullWidth,
      ...props
    },
    ref,
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useAutosizeTextArea(textAreaRef.current, value || '');
    const containerClassName = classNames('flex flex-col', className);
    const customClassName = classNames(
      'rounded-[5px] h-[36px] border-2 py-2 border-color-medium-light hover:bg-color-light-grey px-5 w-full',
      {
        'border-color-success': isValidate && !disabled,
        'border-color-failure': errorText?.message && !disabled,
        'bg-color-light-grey': disabled,
        'pl-11': LeftIcon,
        'pr-11': RightInsideIcon,
        'pr-5': ClearableBtn,
        'w-[310px]': !textarea && !fullWidth,
        'w-full max-w-[450px] h-[200px]': textarea,
        'w-full': fullWidth,
      },
      inputCustomClass,
    );

    const iconClassName = classNames(
      'absolute -right-1 w-7 h-7 ml-2 fill-color-success',
    );

    const customInputContainerClassName = classNames(
      'flex items-center relative',
      inputContainerClassName,
    );

    const randomId = useMemo(
      () => `input-text-${Math.random().toString(36).substr(2, 9)}`,
      [onChange],
    );

    const inputProps = {
      ref,
      id: id || randomId,
      'data-testid': testID,
      onClick,
      value,
      onFocus,
      type,
      placeholder,
      onChange,
      disabled,
      className: customClassName,
      ...props,
    };

    const displayRightInsideIcon = () =>
      ClearableBtn && inputProps.value !== '' ? ClearableBtn : RightInsideIcon;

    return (
      <div className={containerClassName}>
        {label && (
          <Label
            htmlFor={inputProps.id}
            required={required}
            className={labelClassName}
          >
            {label}
          </Label>
        )}
        <div className={customInputContainerClassName}>
          {LeftIcon}
          {textarea ? (
            <textarea {...inputProps} rows={1} required={required} />
          ) : (
            <input {...inputProps} required={required} />
          )}
          {displayRightInsideIcon()}
          {/* {isValidate && !disabled && <CheckIcon className={iconClassName} />} */}
          {errorText?.message && !disabled && displayErrorIcon && (
            <ErrorIcon className={iconClassName} />
          )}
          {RightIcon && RightIcon}
        </div>

        {errorText?.message && !disabled && <Error>{errorText.message}</Error>}
      </div>
    );
  },
);

TextInput.defaultProps = {
  type: 'text',
};
