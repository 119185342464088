// api/CapGemini::ConsolidationImport.ts
import { Model, Attr, HasMany } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type CapGeminiConsolidationInvoiceImport from './CapGeminiConsolidationInvoiceImport';

@Model()
class CapGeminiConsolidationImport extends ApplicationRecord {
  static jsonapiType = 'capgemini-consolidation_imports';

  // Attributes
  @Attr() parsed!: boolean;
  @Attr() parsedAt!: string;
  @Attr() fileSignedId!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr() fileUrl!: string;

  // Extra-attributes

  // Relationships
  @HasMany('capgemini-consolidation_invoice_imports')
  consolidationInvoiceImports!: Array<CapGeminiConsolidationInvoiceImport>;
}

export default CapGeminiConsolidationImport;
