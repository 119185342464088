import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PersistedSpraypaintRecord } from 'spraypaint';
import { useQuery } from '@tanstack/react-query';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import { ButtonPrimary } from '@common/components/buttons';
import { Line } from '@common/components/fragments';
import { Alert } from '@common/components/toasts';
import { scrollToId } from '@common/utils/navigation.utils';
import { PeriodType } from '@common/utils/type.utils';
import { MoveLeftRightIcon } from '../../../app/assets/icons/payslipFunnel';
import {
  useToggleMiniTutorial,
  useToggleTutorial,
} from '../../widgets/hooks/ToggleTutorial';
import { MinitutorialOff } from '../components/tutorials/components/MinitutorialOff';
import { ExpenseTutorial } from '../components/tutorials/ExpenseTutorial';
import { CommonMiniTutorial } from '../components/tutorials/miniTutorials/CommonMiniTutorial';
import { usePayslipFunnelTransition } from '../hooks/usePayslipFunnelTransition';
import { WarningAmountsCard } from '../components/WarningAmountsCard';
import ExpenseCard from './components/ExpenseCard';
import { MiniTutorialExpense } from './components/MiniTutorialExpense';
import { TotalExpensesCard } from './components/TotalExpensesCard';
import useExpensesStep from './hooks/useExpensesStep';
import CostType from './types/CostType';
import useSubmittedExpenses from './hooks/useSubmittedExpenses';
import OverlayLoader from '../../../app/components/OverlayLoader';
import CompletionErrorType from '../types/CompletionErrorType';
import { EmptyData } from '../../payslip/components';
import { ExpensesToReimburse } from './components/ExpensesToReimburse';
import { useSelectExpense } from './hooks/useUpdateExpenseStatus';
import { PayslipFunnelExpensesStep } from '../../../app/spraypaint';
import { AvailableExpenses } from './components/AvailableExpenses';
import useRejectedExpenses from './hooks/useRejectedExpenses';
import { tutorialContainerCustomClass } from '../constantes/tailwindStyle';
import useBreakpoints from '../../../app/responsive/useBreakPoints';
import StepMessages from '../components/StepMessages';

type Props = {
  payslipFunnelId: number;
  currentPayslipFunnelPeriod: PeriodType;
};

export const ExpensesStep = ({
  payslipFunnelId,
  currentPayslipFunnelPeriod,
}: Props) => {
  const { isXs } = useBreakpoints();
  const { t } = useTranslation();

  const { data: expensesStepId } = useQuery({
    queryKey: ['invoiceStepId', payslipFunnelId],
    cacheTime: 0,
    retry: 0,
    queryFn: () =>
      PayslipFunnelExpensesStep.where({
        payslip_funnel_id: payslipFunnelId,
      })
        .all()
        .then((res) => {
          const invoiceStep = res.data[0];
          return invoiceStep?.id;
        }),
  });

  const { isTutorialOn, toggleAllTutorial } = useToggleTutorial();
  const employeeId = useCurrentEmployeeId();
  const { toggleMiniTutorial, isMiniTutorialOn } = useToggleMiniTutorial();

  const [didUserValidateTutorial, setUserValidateTutorial] =
    useState(isTutorialOn);

  const handleOnClick = () => {
    setUserValidateTutorial(false);
    toggleAllTutorial();
  };

  const { data: expensesStepData, isFetching: expensesStepIsFetching } =
    useExpensesStep(expensesStepId);

  const { data: submittedExpensesData } = useSubmittedExpenses(employeeId);

  const { data: rejectedExpensesData } = useRejectedExpenses({
    employeeId,
    payslipDate: currentPayslipFunnelPeriod,
  });

  const { mutate: validateExpensesStep, isLoading: mutationLoader } =
    usePayslipFunnelTransition({ payslipFunnelId });

  const handleValidateExpensesStep = () =>
    validateExpensesStep({
      transitionName: 'complete_expenses_step',
    });

  const handleNavigateToExpensePage = () => {
    window.location.href = `/employees/${employeeId}/expenses`;
  };

  const expensesStep = expensesStepData?.data;

  const { mutate, isLoading: isUpdateSelectExpenseLoading } = useSelectExpense(
    expensesStep as PersistedSpraypaintRecord<PayslipFunnelExpensesStep>,
    expensesStepId,
  );

  if (didUserValidateTutorial) {
    return <ExpenseTutorial onClick={handleOnClick} />;
  }

  if (!expensesStep) return null;

  const dateRangeLastDate = expensesStep.payslipFunnel?.dateRangeLastDate;
  const isStc = expensesStep.payslipFunnel?.stc;
  const availableCosts: CostType[] = expensesStep.expensesTables.available;
  const selectedCosts: CostType[] = expensesStep.expensesTables.selected;
  const toRejectCosts: CostType[] = expensesStep.expensesTables.to_reject;
  const futureCosts: CostType[] = expensesStep.expensesTables.future;

  const submittedExpensesTotalHt =
    submittedExpensesData?.meta?.stats?.total?.ht_amount || 0;
  const submittedExpensesCount =
    submittedExpensesData?.meta?.stats?.total?.count || 0;

  const rejectedExpensesTotalHt =
    rejectedExpensesData?.meta?.stats?.total?.ht_amount || 0;
  const rejectedExpensesCount =
    rejectedExpensesData?.meta?.stats?.total?.count || 0;
  const rejectedExpenses = rejectedExpensesData?.data || [];

  const selectExpense = (expenseId: number) => {
    mutate({
      expenseId,
      toSelect: true,
    });
  };

  const unselectExpense = (expenseId: number) => {
    mutate({
      expenseId,
      toSelect: false,
    });
  };

  const isEmpty = [availableCosts, selectedCosts, toRejectCosts].every(
    (arr) => arr.length === 0,
  );
  const isExpenseStepRefetching =
    isUpdateSelectExpenseLoading || expensesStepIsFetching;

  const warningAmountsCard = (
    <WarningAmountsCard
      href={`/employees/${employeeId}/expenses`}
      className="mb-2 md:mr-[50px]"
      amount={submittedExpensesTotalHt}
      number={submittedExpensesCount}
      title="front.payslip.tdc.expense.toreject.title"
      information={
        toRejectCosts.length + rejectedExpenses.length > 0
          ? 'front.payslip.tdc.expense.toreject.information'
          : undefined
      }
      onClick={() => scrollToId('rejected-expenses-card')}
      numberText="front.payslip.tdc.expense.toreject.numberText"
    />
  );

  const renderMain = () => {
    if (isEmpty)
      return (
        <div className="flex justify-between items-center ml-[30px] my-5">
          <EmptyData
            titleKey="front.payslip.tdc.expense.emptyExpense.title"
            buttonSecondaryKey="front.payslip.tdc.expense.emptyExpense.buttonSecondary"
            buttonPrimaryKey="front.payslip.tdc.expense.emptyExpense.buttonPrimary"
            handleValidateStep={handleValidateExpensesStep}
            amount={0}
            isLoading={mutationLoader}
            redirectToUrl={`/employees/${employeeId}/expenses`}
          />
        </div>
      );

    return (
      <div className="w-full background-color-transparent-medium-grey md:px-[30px] mb-[70px]">
        <div className="mt-[50px] flex flex-1 flex-col md:flex-row md:justify-between md:items-center">
          {isXs && warningAmountsCard}
          <OverlayLoader loading={expensesStepIsFetching}>
            <TotalExpensesCard
              status="refund"
              maxAmount={expensesStep.maxAmount.amount}
              refundedAmount={expensesStep.refundableAmount.amount}
              totalAmount={expensesStep.costsTotalAmount.amount}
              expensesCount={availableCosts.length}
            />
          </OverlayLoader>
          {!isXs && warningAmountsCard}
        </div>
        <div className="flex flex-1 flex-col md:flex-row justify-between mt-[32px]">
          <AvailableExpenses
            availableCosts={availableCosts}
            futureCosts={futureCosts}
            isExpenseStepRefetching={isExpenseStepRefetching}
            selectExpense={selectExpense}
            isStc={isStc}
            dateRangeLastDate={dateRangeLastDate}
          />
          <MoveLeftRightIcon className="self-center flex-1 rotate-90 my-7 md:my-0 md:rotate-0" />

          <ExpensesToReimburse
            unselectExpense={unselectExpense}
            selectedCosts={selectedCosts}
            isExpenseStepRefetching={isExpenseStepRefetching}
          />
        </div>
        <Line className="mt-[60px]" />
        <p className="text-base3 text-color-medium-grey my-8">
          {t('front.payslip.tdc.expense.rejected.title')}
        </p>
        <div className="flex flex-1 flex-col md:flex-row md:justify-between md:items-center">
          <OverlayLoader loading={expensesStepIsFetching}>
            <TotalExpensesCard
              id="rejected-expenses-card"
              status="reject"
              totalAmount={Number(
                expensesStep.toRejectCostsTotalAmount.amount +
                  rejectedExpensesTotalHt,
              )}
              expensesCount={toRejectCosts.length + rejectedExpensesCount}
            />
          </OverlayLoader>
        </div>

        {toRejectCosts.length + rejectedExpenses.length > 0 && (
          <OverlayLoader loading={expensesStepIsFetching}>
            <div className="flex flex-col mt-[41px]">
              {toRejectCosts.map((c, index) => (
                <ExpenseCard
                  key={c.id || `${index}_${c.label}`}
                  cost={c}
                  status="rejected"
                  expenseStatusFront="added"
                />
              ))}
              {/* That's stupid but that's what they want */}
              {/* Already rejected expenses as payslip funnel costs 🥴 */}
              {rejectedExpenses.map((e: any, index) => (
                <ExpenseCard
                  key={e.id || `${index}_${e.label}`}
                  cost={
                    {
                      id: e.id,
                      date: e.date,
                      amount: e.htAmount.amount,
                      label: e.translatedNatureOrType,
                      refunded: true,
                      auto_reject_reasons: [e.refusalReason],
                    } as CostType
                  }
                  status="rejected"
                  expenseStatusFront="added"
                />
              ))}
            </div>
          </OverlayLoader>
        )}
      </div>
    );
  };

  return (
    <div className="w-full px-[30px] pb-[60px]">
      <div className={tutorialContainerCustomClass}>
        {isMiniTutorialOn ? (
          <CommonMiniTutorial
            Component={
              <MiniTutorialExpense onClick={handleNavigateToExpensePage} />
            }
            onClick={toggleMiniTutorial}
          />
        ) : (
          <MinitutorialOff onClick={toggleMiniTutorial} />
        )}
        <StepMessages step={expensesStep} />
        {!isEmpty && (
          <ButtonPrimary
            className="w-[210px] ml-5 md:ml-0 mt-2 md:mt-0"
            onClick={handleValidateExpensesStep}
            isLoading={mutationLoader}
          >
            {t('front.payslip.tdc.expense.header.title')}
          </ButtonPrimary>
        )}
      </div>

      {expensesStep.completionErrors.map((error: CompletionErrorType) => (
        <Alert
          className="my-[10px] ml-[30px]"
          alertType="error"
          text={error.localized_message}
        />
      ))}
      {renderMain()}
    </div>
  );
};
