import { useTranslation } from 'react-i18next';
import { PayslipDetailCard, AmountEntry } from '../../components';
import { formatNumber } from '../../../common/utils/maths.utils';
import { salaryStrategyToText } from '../../constants/MapSalaryStrategyToText';

const mapSalaryStrategyToText = salaryStrategyToText;

export type SalaryStepDetailsProps = {
  salaryStep: any;
  displayAll: boolean;
};

export const SalaryStepDetails = ({
  salaryStep,
  displayAll,
}: SalaryStepDetailsProps) => {
  const { t } = useTranslation();

  const details = (
    <div className="mt-16 mb-10 flex flex-col text-color-medium-grey ml-[110px]">
      <span>
        {t('front.payslip.details.salaryChoice.minGrossSalary')}{' '}
        {formatNumber(salaryStep?.min_gross_salary)}{' '}
        {salaryStep?.min_gross_salary?.currency_iso || '  €'}
      </span>
      <span>
        {t('front.payslip.details.salaryChoice.minNetSalary')}{' '}
        {formatNumber(salaryStep?.min_net_salary)}{' '}
        {salaryStep?.min_net_salary?.currency_iso || '  €'}
      </span>
      <span>
        {t('front.payslip.details.salaryChoice.maxNetSalary')}{' '}
        {formatNumber(salaryStep?.max_net_salary)}{' '}
        {salaryStep?.max_net_salary?.currency_iso || '  €'}
      </span>
      <span>
        {t('front.payslip.details.salaryChoice.maxGrossSalary')}{' '}
        {formatNumber(salaryStep?.max_gross_salary)}{' '}
        {salaryStep?.max_gross_salary?.currency_iso || '  €'}
      </span>
    </div>
  );

  return (
    <PayslipDetailCard
      user={salaryStep?.completed_by_name}
      date={salaryStep?.completed_at}
      title={t('front.payslip.tdc.salaryChoice')}
      displayAll={displayAll}
      allDetail={details}
    >
      <div className="flex">
        {salaryStep?.salary_target !== null && (
          <div className="my-2 pr-5 flex w-[250px] flex-col items-left mr-20">
            <p className="text-color-dark-content text-sm2">
              {t('front.payslip.details.salaryChoice.chosenOption')}
            </p>
            <p className="text-color-dark-content text-xl1">
              {salaryStep?.salary_target === 'gross'
                ? t('front.payslip.tdc.salaryChoice.custom_salary.gross')
                : 'net'}
            </p>
          </div>
        )}
        <div className="flex flex-col text-xl1 my-2 pr-5 flex w-[250px]">
          <p className="text-color-dark-content text-sm2">
            {t('front.payslip.details.salaryChoice.payslipCustomChoice')}
          </p>
          <p>
            {t(
              mapSalaryStrategyToText[
                salaryStep?.salary_strategy as keyof typeof mapSalaryStrategyToText
              ],
            )}
          </p>
        </div>

        {salaryStep?.salary_value !== null && (
          <AmountEntry
            value={{ amount: salaryStep?.salary_value }}
            label={
              t('front.payslip.details.salaryChoice.askedSalary') as string
            }
          />
        )}
      </div>
    </PayslipDetailCard>
  );
};
