import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import Table from '../../common/components/table/Table';
import { useBackendTable } from '../../common/components/table/hooks';
import { onRowClickNavigate } from '../../../app/navigation/functions/onRowClickNavigate';
import Subcontractor from '../../../app/spraypaint/Subcontractor';
import useSubcontractors from '../hooks/useSubcontractors';
import subcontractorsIndexColumns from '../functions/subcontractorsIndexColumns';

const SubcontractorsIndex = () => {
  const employeeId = useCurrentEmployeeId();
  const navigate = useNavigate();
  const [scope, subcontractorScopeKey, props] = useBackendTable<Subcontractor>({
    initialSorting: [{ id: 'name', desc: false }],
  });

  const { subcontractors, isLoading, totalCount } = useSubcontractors(
    scope,
    subcontractorScopeKey,
    employeeId,
  );

  const { t } = useTranslation();

  const onRowClick =
    (raw: ModelRecord<Subcontractor> | undefined) =>
    (event: React.MouseEvent) => {
      onRowClickNavigate({
        id: `${raw?.id}`,
        event,
        windowNavigationHref: `subcontractors/${raw?.id}`,
        href: `/employees/${raw?.employeeId}/subcontractors/${raw?.id}`,
        navigate,
      });
    };

  return (
    <div className="mt-[50px]">
      <div className="flex justify-between px-[20px]">
        <p className="big-title">{t('front.menu.subcontractors')}</p>
      </div>
      {employeeId && (
        <div className="flex">
          <ButtonPrimary
            className="mt-[10px] ml-[20px]"
            type="submit"
            href={`/employees/${employeeId}/subcontractors/new`}
          >
            {t('default.status_info.new')}
          </ButtonPrimary>
        </div>
      )}
      <Table
        emptyTableErrorMsg="react_table.no_data_text"
        onRowClick={onRowClick}
        className="mt-[26px]"
        isLoading={isLoading}
        withImageErrorMsg={false}
        totalCount={totalCount}
        data={(subcontractors as ModelRecord<Subcontractor>[]) || []}
        reactTableProps={props}
        columns={subcontractorsIndexColumns({ employeeId })}
      />
    </div>
  );
};

export default SubcontractorsIndex;
