import { FunctionComponent } from 'react';
import {
  EmailLoginCallbacks,
  EmailLoginForm,
} from '@/modules/authentification/components/EmailLoginForm';

export type EmailFormProps = {
  email: string;
  navigateToPasswordForm: (email: string) => void;
  navigateToUnknownEmailForm: (email: string) => void;
  navigateToEmailCodeForm: (email: string) => void;
  navigateToVerifyAccount: EmailLoginCallbacks['onVerifyAccount'];
};

export const EmailForm: FunctionComponent<EmailFormProps> = ({
  navigateToEmailCodeForm,
  navigateToUnknownEmailForm,
  navigateToPasswordForm,
  navigateToVerifyAccount,
  email,
}) => (
  <EmailLoginForm
    initialEmail={email}
    onEmailAuth={navigateToEmailCodeForm}
    onPasswordAuth={navigateToPasswordForm}
    onVerifyAccount={navigateToVerifyAccount}
    onRegister={navigateToUnknownEmailForm}
  />
);
