import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { MoneyType } from '@/modules/common/utils/type.utils';
import { dynT } from '@/modules/common/components/dynT';
import {
  EqualFilter,
  getEqualFilter,
} from '../../../../common/components/table/filter/EqualFilter';
import {
  getSupInfFilter,
  SupInfFilter,
} from '../../../../common/components/table/filter/SupInfFilter';
import { SubcontractorBill } from '../../../../../app/spraypaint';

const columnHelper = createColumnHelper<ModelRecord<SubcontractorBill>>();

const subcontractorBillColumns = [
  columnHelper.accessor('number', {
    header: dynT('activerecord.attributes.subcontractor_bill.number'),
    filterFn: getEqualFilter(),
    meta: {
      filter: {
        Component: EqualFilter,
      },
    },
  }),
  columnHelper.accessor('ttcAmount', {
    header: dynT('activerecord.attributes.subcontractor_bill.ttc_amount'),
    filterFn: getSupInfFilter(),
    meta: {
      filter: {
        Component: SupInfFilter,
      },
    },
    cell: (info) => (
      <div className="text-center">
        <AmountWithCurrency amount={info.getValue() as MoneyType} />
      </div>
    ),
  }),
  columnHelper.accessor('subcontractor.name', {
    header: dynT('activerecord.attributes.subcontractor_bill.subcontractor'),
    filterFn: getEqualFilter(),
  }),
  columnHelper.accessor('status', {
    header: dynT('default.status'),
    enableColumnFilter: false,
  }),
];

export default subcontractorBillColumns;
