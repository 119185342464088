import { useEffect, useState } from 'react';

export const useIsAPayslipFunnel = () => {
  const [isPayslipFunnel, setIsPayslipFunnel] = useState(false);

  useEffect(() => {
    const pathArray = window.location.pathname.split('/');
    const lastString = pathArray[pathArray.length - 1];
    const penultimateString = pathArray[pathArray.length - 2];
    const newIsPayslipFunnel =
      lastString === 'create' && penultimateString === 'payslips';

    setIsPayslipFunnel(newIsPayslipFunnel);
  }, []);

  return isPayslipFunnel;
};
