import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { MoneyType } from '@/modules/common/utils/type.utils';
import dayjs from 'dayjs';
import { t } from 'i18next';
import {
  DateFilter,
  getDateFilterFn,
} from '@/modules/common/components/table/filter/DateFilter';
import {
  ContribPeeDeposit,
  ContribPercoDeposit,
} from '../../../../../app/spraypaint';
import {
  SupInfFilter,
  getSupInfFilter,
} from '../../../../common/components/table/filter/SupInfFilter';

const columnHelper =
  createColumnHelper<ModelRecord<ContribPeeDeposit | ContribPercoDeposit>>();

const contribDepositColumns = [
  columnHelper.accessor('associableType', {
    header: "Plan d'épargne",
    cell: (info) => (
      <div className="text-center">
        <p>
          {t(`front.bankAssociation.table.contribDeposit.${info.getValue()}`)}
        </p>
      </div>
    ),
  }),
  columnHelper.accessor('number', {
    header: 'Numéro de Contribution',
  }),
  columnHelper.accessor((original) => original?.employee?.fullName, {
    header: 'Employé',
    id: 'full_name',
  }),
  columnHelper.accessor('amount', {
    // filter working
    header: 'Montant',
    filterFn: getSupInfFilter(),
    meta: {
      filter: {
        Component: SupInfFilter,
      },
    },
    cell: (info) => (
      <div className="text-left">
        <AmountWithCurrency amount={info.getValue() as MoneyType} />
      </div>
    ),
  }),
  columnHelper.accessor((original) => dayjs(original.createdAt).format('L'), {
    header: 'Créé le',
    id: 'created_at',
    filterFn: getDateFilterFn(),
    meta: {
      filter: {
        Component: DateFilter,
      },
    },
  }),
  columnHelper.accessor('status', {
    header: t('default.status'),
    enableColumnFilter: false,
    enableSorting: false,
  }),
];

export default contribDepositColumns;
