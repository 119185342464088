import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from '../../navigation/components/Breadcrumbs';
import { usePayslipComputationDetails } from '../hooks/usePayslipComputationJson';
import { LoaderPage } from '../../../app/navigation/components/LoaderPage';
import { useCurrentEmployeeId } from '../../../app/hooks/useCurrentEmployeeId';
import { ButtonPrimary } from '../../common/components/buttons';

export const PayslipJson = () => {
  const { id } = useParams();
  const { payslipJson, isLoading, isError, error } =
    usePayslipComputationDetails(id as string);
  const employeeId = useCurrentEmployeeId();
  const { t } = useTranslation();

  if (isLoading) return <LoaderPage className="h-[900px]" />;

  const downloadSettings = () => {
    const filename = `calculs_fiche_de_paie_id_${id}_pour_salarie_porte_${employeeId}.json`;
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:application/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(payslipJson),
      )}`,
    );
    element.setAttribute('download', filename);
    element.setAttribute('action', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <div className="mt-[44px]">
      <div className="ml-5 mr-10">
        <div className="flex justify-between">
          <p className="text-xxl text-color-medium-grey">
            {t('front.breadcrumbs.payslips.download_json')}
          </p>
        </div>
        <Breadcrumbs />
      </div>

      <div className="flex flex-col">
        <div className="mt-[30px]">
          <div className="flex justify-between p-5">
            {t('front.breadcrumbs.payslips.download_json_info_0')}
            <br />
            <br />
            {t('front.breadcrumbs.payslips.download_json_info_1')}
            <br />
            {t('front.breadcrumbs.payslips.download_json_info_2')}
            <br />
            {t('front.breadcrumbs.payslips.download_json_info_3')}
          </div>
        </div>
        <div className="mt-[30px]">
          <div className="flex justify-between p-5">
            {!isError && (
              <ButtonPrimary onClick={downloadSettings}>
                {t('default.button.download')}
              </ButtonPrimary>
            )}
            {isError && (
              <div>
                <p>{t('front.breadcrumbs.payslips.download_json_error')}</p>
                <p>{`${error}`}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
