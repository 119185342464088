import { Scope } from 'spraypaint';
import { ApplicationRecord } from '@/app/spraypaint';
import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { TypedLoaderFunction } from '@/types';
import { RecordProxy } from 'spraypaint/lib-esm/proxies';

export type FindQueryOpts = {
  includes?: Parameters<Scope['includes']>[0];
  select?: Parameters<Scope['select']>[0];
  selectExtra?: Parameters<Scope['selectExtra']>[0];
  stats?: Parameters<Scope['stats']>[0];
  order?: Parameters<Scope['order']>[0];
  where?: Parameters<Scope['where']>[0];
};

export type FindQueryLoaderOpts = {
  idParam?: string;
  isValidId?: (id: string) => boolean;
  queryOpts?: FindQueryOpts;
};

const ALLOWED_OPTIONS: (keyof FindQueryOpts)[] = [
  'includes',
  'select',
  'selectExtra',
  'stats',
  'order',
  'where',
];

function buildQueryBuilder<T extends typeof ApplicationRecord>(
  spraypaintModel: T,
) {
  return (id: number | string, opts: FindQueryOpts = {}) => ({
    queryKey: ['findQuery', spraypaintModel.jsonapiType, id, opts],
    queryFn: () =>
      // Only apply the method is the opts is present
      Object.entries(opts)
        .reduce((scope, [method, args]) => {
          const methodName = method as keyof FindQueryOpts;
          if (args && ALLOWED_OPTIONS.includes(methodName)) {
            return scope[methodName](args);
          }
          return scope;
        }, spraypaintModel.scope())
        .find(id),
  });
}

type QueryBuilder<T extends typeof ApplicationRecord> = ReturnType<
  typeof buildQueryBuilder<T>
>;

function buildLoaderBuilder<T extends typeof ApplicationRecord>(
  buildQuery: QueryBuilder<T>,
) {
  return (
      queryClient: QueryClient,
      {
        idParam = 'id',
        isValidId = (id) => !Number.isNaN(Number(id)),
        queryOpts = {},
      }: FindQueryLoaderOpts = {},
    ): TypedLoaderFunction<RecordProxy<T['prototype']>> =>
    async ({ params }) => {
      const id = params[idParam];

      // if id is undefined, or is not a number-string
      if (!id || !isValidId(id))
        // Allow Response to be thrown here because this is an expected
        // behavior from React Router
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new Response('Not found', {
          status: 404,
          statusText: 'Not Found',
        });

      const query = buildQuery(id, queryOpts);
      return queryClient.ensureQueryData(query);
    };
}

type CustomUseQueryOptions<T extends typeof ApplicationRecord> = Omit<
  Omit<
    UseQueryOptions<
      RecordProxy<T['prototype']>,
      unknown,
      RecordProxy<T['prototype']>,
      (string | number | FindQueryOpts | undefined)[]
    >,
    'initialData'
  > & {
    initialData?: () => undefined;
  },
  'queryKey' | 'queryFn'
>;

function buildUseFindQuery<T extends typeof ApplicationRecord>(
  buildQuery: QueryBuilder<T>,
) {
  return (
    id: number | string | undefined,
    queryOpts: FindQueryOpts = {},
    useQueryOpts: CustomUseQueryOptions<T> = {},
  ) => {
    const { data, ...other } = useQuery({
      enabled: !!id,
      ...useQueryOpts,
      ...buildQuery(id ?? '', queryOpts),
    });

    const record = data?.data;

    return { data, record, ...other };
  };
}

export function findQueryBuilder<T extends typeof ApplicationRecord>(
  spraypaintModel: T,
) {
  const buildQuery = buildQueryBuilder(spraypaintModel);
  const buildLoader = buildLoaderBuilder(buildQuery);
  const useFindQuery = buildUseFindQuery(buildQuery);
  const buildQueryKeyPrefix = (id: number | string) => [
    'findQuery',
    spraypaintModel.jsonapiType,
    id,
  ];

  return { buildQuery, buildLoader, useFindQuery, buildQueryKeyPrefix };
}
