import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDrag } from 'react-dnd';
import { AmountText } from '@common/components/fragments/AmountText';
import { Button } from '@common/components/buttons';
import { TooltipComponent } from '@common/components/fragments/TooltipComponent';
import {
  ExpenseAddIcon,
  ExpenseRemoveIcon,
} from '../../../../app/assets/icons/payslipFunnel';
import { HelpOutlineIcon as HelpOutlineIconInput } from '../../../../app/assets/icons/inputs';
import Status from '../../../common/components/statuses/Status';
import CostType from '../types/CostType';
import useBreakpoints from '../../../../app/responsive/useBreakPoints';

export type Props = {
  cost: CostType;
  status: 'validated' | 'rejected' | 'cancellationExpense';
  onClick?: (expenseId: number) => void;
  disabled?: boolean;
  expenseStatusFront?: 'added' | 'toAdd';
  type?: string;
  isDraggable?: boolean;
  isStc?: boolean;
  dateRangeLastDate?: string;
};

const ExpenseCard = ({
  cost,
  status,
  onClick,
  disabled,
  expenseStatusFront,
  type,
  isDraggable,
  isStc,
  dateRangeLastDate,
}: Props) => {
  const { isXs } = useBreakpoints();
  const { t } = useTranslation();

  const isRejected = status === 'rejected';
  const isCancellation = cost.cancellation;
  const displayStcAlert =
    cost.to_reject_unless_selected && isStc && dateRangeLastDate;
  const displayAlert = displayStcAlert;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: type ?? '',
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<any>();
        if (item && dropResult && onClick) {
          onClick(Number(cost?.id));
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [cost?.id],
  );

  const containerClassName = classNames(
    'shadow-expenseFunnelCard w-full md:w-[486px] mb-1 md:mb-0 md:bg-no-repeat',
    cost.mandatory || ['rejected', 'cancellationExpense'].includes(status)
      ? 'cursor-auto'
      : 'cursor-pointer',
    {
      'text-color-medium-grey': disabled,
      'bg-funnel-expense-card': !displayAlert && !isCancellation,
      'hover:bg-funnel-expense-card-hover':
        !disabled && !isCancellation && !isRejected && !displayAlert,
      'md:h-[99px] bg-funnel-expense-card-alert':
        displayAlert && !isCancellation,
      'hover:bg-funnel-expense-card-alert-hover':
        !disabled && !isCancellation && displayAlert,
      'bg-funnel-expense-card-greyed': isCancellation,
      'bg-funnel-expense-card-rejected': isRejected && !isCancellation,
      'bg-no-repeat': isRejected && !isCancellation && isXs,
      'cursor-grab': isDraggable && !disabled && !isDragging,
      'cursor-grabbing': isDragging,
    },
  );

  const textLeftContainerClassName = classNames(
    'flex flex-col justify-start',
    isCancellation ? 'md:w-[140px]' : 'w-[40%] md:w-[186px]',
  );

  const secondContainerClassName = classNames(
    'flex flex-start h-full md:h-[76px]',
    {
      'text-color-dark-content':
        (!disabled && !isCancellation) || cost.mandatory,
    },
  );

  const iconRightClassName = classNames({
    'ml-2 md:ml-[25px] fill-color-dark-content': !isCancellation,
    'ml-2 md:ml-[10px] fill-color-medium-grey': isCancellation,
  });

  const renderIconCard = () => {
    if (cost.future) {
      return (
        <TooltipComponent
          Icon={HelpOutlineIconInput}
          tooltipMsgComp={String(
            t('front.payslip.tdc.expense.card.alert_future'),
          )}
          tooltipId={String(`future-cost-${cost.id}`)}
          iconClassName={iconRightClassName}
        />
      );
    }

    if (disabled && !isCancellation) {
      return null;
    }

    const iconSize = isXs ? 15 : 20;
    if (isRejected && cost.auto_reject_reasons) {
      return (
        <TooltipComponent
          Icon={HelpOutlineIconInput}
          tooltipMsgComp={cost.auto_reject_reasons}
          tooltipId={String(`rejected-expense-${cost.id}`)}
          iconClassName={iconRightClassName}
          iconWidth={iconSize}
          iconHeight={iconSize}
        />
      );
    }

    if (isCancellation && cost.cancellation_reason) {
      return (
        <TooltipComponent
          Icon={HelpOutlineIconInput}
          tooltipMsgComp={cost.cancellation_reason}
          tooltipId={String(`canceled-expense-${cost.id}`)}
          iconClassName={iconRightClassName}
          iconWidth={iconSize}
          iconHeight={iconSize}
        />
      );
    }

    return expenseStatusFront === 'added' ? (
      <ExpenseRemoveIcon
        height={iconSize}
        width={iconSize}
        className="ml-2 md:ml-[30px]"
      />
    ) : (
      <ExpenseAddIcon
        height={iconSize}
        width={iconSize}
        className="ml-2 md:ml-[30px]"
      />
    );
  };

  const getTitle = (expense: CostType, mobile: boolean) => {
    const mobileSuffix = mobile ? '_mobile' : '';

    if (expense.refunded) {
      return t(`front.payslip.tdc.expense.card.refunded_title${mobileSuffix}`);
    }

    return t(
      `front.payslip.tdc.expense.card.non_refunded_title${mobileSuffix}`,
    );
  };

  return (
    <Button
      custom
      ref={isDraggable && !cost.mandatory ? drag : undefined}
      disabled={disabled || isRejected}
      onClick={onClick ? () => onClick(Number(cost.id)) : undefined}
      draggable={isDraggable && !disabled && !isRejected}
      className={containerClassName}
    >
      <div className={secondContainerClassName}>
        <div className={textLeftContainerClassName}>
          <div className="flex flex-col text-left mr-2 pl-3 pt-1 md:mr-0 md:pl-[21px] md:pt-[9px]">
            <p className="text-sm2 font-cabin-bold">
              {dayjs(cost.date).format('L')}
            </p>
            <p className="md:mt-[2px] text-sm1 md:text-sm2">{cost.label}</p>
          </div>
        </div>
        <div className="flex items-center md:w-auto">
          <div className="md:flex md:flex-col md:ml-[10px]">
            <p className="text-sm2">{getTitle(cost, isXs)}</p>
            <AmountText
              amount={cost.amount}
              currencyIsoCode="EUR"
              supText="TTC"
              className="text-base md:mt-[2px] md:text-xl"
            />
          </div>
          <div className="ml-5">
            <Status
              statusType={isCancellation ? 'cancellationExpense' : status}
            />
          </div>
          {cost.id && renderIconCard()}
        </div>
      </div>
      {displayAlert && (
        <div
          data-testid="alert-date-container"
          className="h-[23px] flex justify-center items-center"
        >
          <p className="text-color-warning">
            {displayStcAlert && (
              <>
                {t('front.payslip.tdc.expense.card.alert_stc')}{' '}
                <span>{dayjs(dateRangeLastDate).format('L')}</span>
              </>
            )}
          </p>
        </div>
      )}
    </Button>
  );
};

export default ExpenseCard;
