// api/PayslipFunnelSteps::Base.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type PayslipFunnel from './PayslipFunnel';

@Model()
class PayslipFunnelBaseStep extends ApplicationRecord {
  static jsonapiType = 'payslip_funnel_steps-bases';

  // Attributes
  @Attr({ persist: false }) completedAt!: string;
  @Attr() completedByName!: string;
  @Attr({ persist: false }) completedById!: number;
  @Attr({ persist: false }) validForCompletion!: boolean;
  @Attr({ persist: false }) payslipFunnelId!: number;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) messagesI18nKeys!: string[];

  // Relationships
  @BelongsTo('payslip_funnels') payslipFunnel!: PayslipFunnel;
}

export default PayslipFunnelBaseStep;
