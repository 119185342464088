import type { FunctionComponent } from 'react';
import { CapGeminiConsolidationInvoiceImport } from '@/app/spraypaint';
import { AttributeLine } from '@/modules/common/components/AttributeLine';
import { StatusWidget } from '@/modules/admin/capgemini/components/StatusWidget';
import { CopyButton } from '@/modules/common/components/buttons/utils/CopyButton';
import {
  ButtonTernary,
  InternalLink,
} from '@/modules/common/components/buttons';
import { adminPathBuilder } from '@/modules/common/components/LinkLine';
import { ReportProblemIc } from '@/app/assets/icons/others';
import Status from '@/modules/common/components/statuses/Status';
import { PlusIcon } from '@/app/assets/icons/buttons';
import { useTranslation } from 'react-i18next';
import { useFormatMoney } from '@/app/utils/moneyUtils';

export interface ConsolidationInvoiceAttributesProps {
  consolidationInvoiceImport: CapGeminiConsolidationInvoiceImport;
  openPriumInvoiceModal: () => void;
  openTundraInvoiceModal: () => void;
}

export const TundraInvoiceContent: FunctionComponent<
  Pick<
    ConsolidationInvoiceAttributesProps,
    'consolidationInvoiceImport' | 'openTundraInvoiceModal'
  >
> = ({ consolidationInvoiceImport, openTundraInvoiceModal }) => {
  const {
    frameworkContract,
    tundraInvoice,
    tundraCommercialContractId,
    tundraInvoiceTotalAmount,
  } = consolidationInvoiceImport;

  const formatMoney = useFormatMoney();

  const { t } = useTranslation();

  if (tundraInvoice) {
    return (
      <div className="flex">
        <InternalLink
          {...tundraInvoice.linkProps({
            pathBuilder: adminPathBuilder,
          })}
        />
        <Status statusType={tundraInvoice.status} className=" my-1 mx-3" />
      </div>
    );
  }
  if (!tundraCommercialContractId) {
    return (
      <div className="fill-color-failure text-color-failure">
        <ReportProblemIc className="inline-block" /> Pas de contrat commercial
        Tundra pour ce contrat cadre
      </div>
    );
  }

  if (!tundraInvoiceTotalAmount) {
    return (
      <div className="fill-color-failure text-color-failure">
        <ReportProblemIc className="inline-block" /> Impossible de calculer le
        montant total de la facture Tundra (TJM manquants)
      </div>
    );
  }

  return (
    <ButtonTernary
      Icon={PlusIcon}
      className="!pr-0 !pt-0 !pb-0 !pl-0"
      disabled={!frameworkContract}
      onClick={openTundraInvoiceModal}
    >
      {t('front.capgemini.imports.button.create_tundra_invoice', {
        amount: formatMoney(tundraInvoiceTotalAmount),
      })}
    </ButtonTernary>
  );
};

export const ConsolidationInvoiceAttributes: FunctionComponent<
  ConsolidationInvoiceAttributesProps
> = ({
  consolidationInvoiceImport,
  openTundraInvoiceModal,
  openPriumInvoiceModal,
}) => {
  const {
    externalReference,
    frameworkContract,
    invoice,
    operatingUnitName,
    totalAmount,
    importStatus,
    importWarnings,
    importErrors,
  } = consolidationInvoiceImport;

  const { t } = useTranslation();
  const formatMoney = useFormatMoney();

  return (
    <div className="w-[50%]">
      <AttributeLine
        id="importStatus"
        label={t(
          'activerecord.attributes.capgemini/consolidation_invoice_import.import_status',
          { ns: 'rails' },
        )}
        value={
          <StatusWidget
            status={importStatus}
            errors={importErrors}
            warnings={importWarnings}
          />
        }
      />
      <AttributeLine
        id="operating_unit_name"
        label={t(
          'activerecord.attributes.capgemini/consolidation_invoice_import.operating_unit_name',
          { ns: 'rails' },
        )}
        value={
          <>
            {operatingUnitName}
            <CopyButton value={operatingUnitName} />
          </>
        }
      />
      <AttributeLine
        id="framework_contract"
        label={t(
          'activerecord.attributes.capgemini/consolidation_invoice_import.framework_contract',
          { ns: 'rails' },
        )}
        value={
          frameworkContract ? (
            <InternalLink
              {...frameworkContract.linkProps({
                pathBuilder: adminPathBuilder,
              })}
            />
          ) : (
            <div className="fill-color-failure text-color-failure">
              <ReportProblemIc className="inline-block" /> Pas de contrat cadre
              pour cette unité opérationnelle
            </div>
          )
        }
      />
      <AttributeLine
        id="externalReference"
        label={t(
          'activerecord.attributes.capgemini/consolidation_invoice_import.external_reference',
          { ns: 'rails' },
        )}
        value={
          <>
            {externalReference}
            <CopyButton value={externalReference} />{' '}
          </>
        }
      />
      <AttributeLine
        id="totalAmount"
        label={t(
          'activerecord.attributes.capgemini/consolidation_invoice_import.total_amount',
          { ns: 'rails' },
        )}
        value={formatMoney(totalAmount)}
      />
      <AttributeLine
        id="invoice"
        label={t(
          'activerecord.attributes.capgemini/consolidation_invoice_import.invoice',
          { ns: 'rails' },
        )}
        value={
          invoice ? (
            <div className="flex">
              <InternalLink
                {...invoice.linkProps({
                  pathBuilder: adminPathBuilder,
                })}
              />
              <Status statusType={invoice.status} className=" my-1 mx-3" />
            </div>
          ) : (
            <ButtonTernary
              Icon={PlusIcon}
              className="!pr-0 !pt-0 !pb-0 !pl-0"
              disabled={!frameworkContract}
              onClick={openPriumInvoiceModal}
            >
              {t('front.capgemini.imports.button.create_consolidated_invoice')}
            </ButtonTernary>
          )
        }
      />
      <AttributeLine
        id="tundra_invoice"
        label={t(
          'activerecord.attributes.capgemini/consolidation_invoice_import.tundra_invoice',
          { ns: 'rails' },
        )}
        value={
          <TundraInvoiceContent
            consolidationInvoiceImport={consolidationInvoiceImport}
            openTundraInvoiceModal={openTundraInvoiceModal}
          />
        }
      />
    </div>
  );
};
