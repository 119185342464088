import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import NotificationsInbox from '../../../app/spraypaint/notification/NotificationsInbox';
import { NotificationsMessage } from '../../../app/spraypaint';
import { registerWithOneSignal } from '../functions/registerOneSignalExternalId';
import { NotificationMessageFilterStateType } from '../types/NotificationsType';

type ArgsType = {
  userId: string;
  enabled?: boolean;
};

const NOTIFICATION_QUERYKEY_PREFIX = 'notifications';

export const useGetNotificationsInbox = ({
  userId,
  enabled = true,
}: ArgsType) => {
  const data = useQuery({
    queryKey: [NOTIFICATION_QUERYKEY_PREFIX, 'inbox', userId],
    queryFn: () => {
      console.log('Fetch inbox');
      return NotificationsInbox.includes('messages')
        .selectExtra([
          'oneSignalExternalId',
          'oneSignalExternalIdAuthHash',
          'unreadMessagesCount',
        ])
        .where({ user_id: userId })
        .all();
    },
    refetchOnWindowFocus: true,
    staleTime: 0,
    enabled,
    onSuccess: (sucessData) => {
      registerWithOneSignal({ inbox: sucessData.data[0] });
    },
  });

  const notificationsInboxData = data?.data?.data[0];
  const totalCount = data?.data?.meta?.stats?.total?.count;

  return { ...data, notificationsInboxData, totalCount };
};

export const usePatchAllReadNotifications = ({
  inboxId,
}: {
  inboxId?: string;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const newNotificationInbox = new NotificationsInbox({
        id: inboxId,
      });
      newNotificationInbox.isPersisted = true;
      newNotificationInbox.markAsRead = true;
      await newNotificationInbox.saveOrThrow();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATION_QUERYKEY_PREFIX],
      });
    },
  });
};

export const useGetNotificationsMessages = ({
  inboxId,
  filter = {},
  per = 8,
}: {
  inboxId?: string;
  filter?: NotificationMessageFilterStateType;
  per?: number;
}) => {
  const useQueryReponse = useInfiniteQuery({
    queryKey: [
      NOTIFICATION_QUERYKEY_PREFIX,
      'get-notifications-messages',
      inboxId,
      filter,
      per,
    ],
    queryFn: ({ pageParam = 1 }) =>
      NotificationsMessage.where({ inbox_id: inboxId, ...filter })
        .order('status')
        .order({ important: 'desc', createdAt: 'desc' })
        .per(per)
        .page(pageParam)
        .all(),
    refetchOnWindowFocus: true,
    getNextPageParam: (lastPage, pages) => {
      // @ts-expect-error spraypaint raw is not typed properly
      if (lastPage?.raw?.links?.next) {
        return pages.length + 1;
      }
      return undefined;
    },
    select: (response) => ({
      pages: response.pages.flatMap((CP) => CP.data),
      pageParams: response.pageParams,
    }),
    enabled: !!inboxId,
    staleTime: 0,
  });

  const data = useQueryReponse?.data?.pages;

  return { ...useQueryReponse, data };
};

export const useGetUnreadNotificationsMessages = ({
  inboxId,
  per = 8,
}: {
  inboxId: string;
  per?: number;
}) =>
  useGetNotificationsMessages({ inboxId, per, filter: { status: 'not_read' } });
