import { useTranslation } from 'react-i18next';
import { PayslipDetailCard, AmountEntry } from '../../components';
import { InvoicesDetailsTable } from './details_tables/InvoicesDetailsTable';
import { RevenuesDetailsTable } from './details_tables/RevenuesDetailsTable';

export type InvoicesStepDetailsProps = {
  invoicesStep: any;
  displayAll: boolean;
  isLoading: boolean;
};

export const InvoicesStepDetails = ({
  invoicesStep,
  displayAll,
  isLoading,
}: InvoicesStepDetailsProps) => {
  const { t } = useTranslation();
  const details = (
    <div className="mt-[30px]">
      <InvoicesDetailsTable invoicesStep={invoicesStep} isLoading={isLoading} />
      <RevenuesDetailsTable invoicesStep={invoicesStep} isLoading={isLoading} />
    </div>
  );

  return (
    <PayslipDetailCard
      user={invoicesStep?.completed_by_name}
      date={invoicesStep?.completed_at}
      title={t('views.invoice.index_admin')}
      displayAll={displayAll}
      allDetail={details}
    >
      <div className="flex">
        <AmountEntry
          label={
            t('front.payslip.details.invoice.balanceBeforePayslip') as string
          }
          value={{
            amount: invoicesStep.activity_account_balance,
            currency: 'EUR',
          }}
        />
        <AmountEntry
          label={t('front.payslip.details.invoice.caTotal') as string}
          value={{
            amount: invoicesStep.total_amount,
            currency: 'EUR',
          }}
        />
        <AmountEntry
          label={t('front.payslip.details.invoice.receivedRevenues') as string}
          value={{
            amount: invoicesStep.paid_amount,
            currency: 'EUR',
          }}
        />
      </div>
    </PayslipDetailCard>
  );
};
