import { RoutesMounter } from '@/types';
import { index, member } from '@/app/navigation/functions';
import AccountingJournalExportsIndex from './pages/AccountingJournalExportsIndex';
import AccountingJournalExportShow from './pages/AccountingJournalExportShow';

export const mountAccountingJournalExportsRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { t },
}) => [
  member({
    path,
    handle: { crumb: () => t('front.accounting.journalExports.title') },
    children: [
      index({ index: true, Component: AccountingJournalExportsIndex }),
      {
        path: ':id',
        element: <AccountingJournalExportShow />,
        handle: { crumb: () => 'Show' },
      },
    ],
  }),
];
