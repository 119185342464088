import RegisteredClient from '@/app/spraypaint/RegisteredClient';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { Invoice } from '@/app/spraypaint';
import Table from '@/modules/common/components/table/Table';
import useRegisteredClientInvoices from '../hooks/useRegisteredClientInvoices';
import registeredClientInvoicesColumns from '../functions/registeredClientInvoicesColumns';

const RegisteredClientInvoices = ({
  registeredClient,
}: {
  registeredClient: RegisteredClient;
}) => {
  const [scope, , props] = useBackendTable<Invoice>();
  const { invoices, isLoading, totalCount } = useRegisteredClientInvoices(
    Number(registeredClient.id),
    scope,
    props,
  );

  return (
    <Table
      className="mt-[20px]"
      isLoading={isLoading}
      totalCount={totalCount}
      data={invoices || []}
      reactTableProps={props}
      columns={registeredClientInvoicesColumns()}
    />
  );
};

export default RegisteredClientInvoices;
