/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from 'classnames';

export type HypertextLinkProps = {
  disabled?: boolean;
  onClick: () => void;
  children: string;
  className?: string;
  testID?: string;
};

export const HypertextLink = ({
  children,
  onClick,
  disabled,
  className,
  testID = 'button-hyperlink',
}: HypertextLinkProps) => {
  const customTextStyle = classNames(
    'text-sm2',
    {
      'hover:underline text-color-blue active:text-color-light-blue': !disabled,
      'text-color-medium-grey': disabled,
    },
    className,
  );
  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <span
      className={customTextStyle}
      onClick={onClick}
      onKeyDown={onClick}
      data-testid={testID}
      role="button"
    >
      {children}
    </span>
  );
};
