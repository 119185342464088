import { ContribPeeDeposit, ContribPercoDeposit } from '@/app/spraypaint';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import Status from '@/modules/common/components/statuses/Status';
import { getDateFromPeriod } from '@/app/utils/dateUtils';

export const ContribDepositCard = ({
  candidate,
}: {
  candidate: ContribPercoDeposit | ContribPeeDeposit;
}) => (
  <>
    <div className="flex flex-col">
      <div>
        <p>
          <span className="font-cabin-bold">Numéro : </span>
          {candidate.number}
        </p>
      </div>
      <p>
        <span className="font-cabin-bold">Employee : </span>
        {candidate.employeeId} - {candidate.employee?.fullName}
      </p>
      <p>
        <span className="font-cabin-bold">Période : </span>
        {candidate.period
          ? getDateFromPeriod({
              month: candidate.period?.month,
              year: candidate.period?.year,
            })
          : '-'}
      </p>
    </div>
    <div className="flex flex-col">
      <p className="flex justify-center">
        <span className="font-cabin-bold">Statut : </span>
        <Status statusType={candidate.status} className="ml-2" />
      </p>
      <p>
        <span className="font-cabin-bold">Montant TTC : </span>
        <AmountWithCurrency amount={candidate.amount} />
      </p>
    </div>
  </>
);
