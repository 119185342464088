import classNames from 'classnames';
import {
  ComponentProps,
  ComponentType,
  useCallback,
  useRef,
  useState,
} from 'react';
import { AbsoluteShowNumber } from '../../common/components/fragments/AbsoluteShowNumber';
import {
  Button,
  ButtonOnClickHandler,
  CommonButtonProps,
} from '../../common/components/buttons';
import { useOnClickOutside } from '../hooks';
import { IconType } from '../../common/utils/type.utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyComponent = ComponentType<any>;

type DropDownableProps<TComponent extends AnyComponent> = {
  DropDownComponent: TComponent;
  dropDownComponentProps?: ComponentProps<TComponent>;
  onClick?: never;
};

type ClickableProps = {
  DropDownComponent?: never;
  onClick: ButtonOnClickHandler;
  dropDownComponentProps?: never;
};

export type HeaderIconButtonProps<
  TComponent extends AnyComponent = ComponentType,
> = {
  testID?: CommonButtonProps['testID'];
  Icon: IconType;
  itemCount?: number;
  style?: {
    buttonClassname?: string;
    iconClassname?: string;
  };
} & (DropDownableProps<TComponent> | ClickableProps);

const defaultButtonClassname =
  'bg-color-light-grey hover:bg-color-medium-light rounded-full w-12 h-12 relative flex justify-center items-center' +
  ' fill-color-pone-orange ml-3 md:ml-[30px]';

const NullInner = () => null;

export function HeaderIconButton<TComponent extends AnyComponent>({
  Icon,
  itemCount,
  DropDownComponent,
  dropDownComponentProps,
  onClick,
  style = {},
  testID,
}: HeaderIconButtonProps<TComponent>) {
  const { buttonClassname, iconClassname } = style;
  const ref = useRef(null);
  const [isToggle, setIsToggle] = useState(false);
  const handleDropDownComponent = (value: boolean) => () => {
    setIsToggle(value);
  };

  const customClassName = classNames(defaultButtonClassname, buttonClassname);
  useOnClickOutside(ref, () => {
    handleDropDownComponent(false)();
  });

  const Inner = useCallback(
    DropDownComponent && isToggle ? DropDownComponent : NullInner,
    [DropDownComponent, isToggle],
  );

  return (
    <div ref={ref}>
      <Button
        testID={testID}
        custom
        className={customClassName}
        onClick={onClick || handleDropDownComponent(!isToggle)}
      >
        <AbsoluteShowNumber itemCount={itemCount} />
        <Icon className={iconClassname} />
      </Button>
      <Inner {...dropDownComponentProps} />
    </div>
  );
}
