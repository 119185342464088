import { Attr, BelongsTo, Model } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from '../ApplicationRecord';
import type FactorTransfer from './FactorTransfer';
import Invoice from '../Invoice';
import Credit from '../Credit';

@Model()
class FactorTransferred extends ApplicationRecord {
  static jsonapiType = 'factor-transferreds';

  // Attributes
  @Attr() number!: string;
  @Attr() clientName!: string;
  @Attr() date!: Date;
  @Attr() euroHtAmount!: MoneyType;
  @Attr() euroTtcAmount!: MoneyType;
  @Attr() factoringContractId!: number;
  @Attr() factorTransferId!: number;
  @Attr() transferableType!: string;
  @Attr() transferableId!: number;
  @Attr() status!: string;

  @BelongsTo('factor-transfers') factorTransfer!: FactorTransfer;
  @BelongsTo({ name: 'transferable' }) transferable!: Invoice | Credit;
}

export default FactorTransferred;
