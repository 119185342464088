import { PersistedSpraypaintRecord } from 'spraypaint';
import FrameworkContract from '@spraypaint/FrameworkContract';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { Invoice } from '@/app/spraypaint';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { MainCard } from '@/modules/common/components/mainCard';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import Table from '@/modules/common/components/table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { FunctionComponent } from 'react';
import { dynT } from '@/modules/common/components/dynT';

const columnHelper = createColumnHelper<ModelRecord<Invoice>>();

const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
  }),
  columnHelper.accessor('number', {
    header: dynT('activerecord.attributes.invoice.number', { ns: 'rails' }),
  }),
  columnHelper.accessor('date', {
    header: dynT('activerecord.attributes.invoice.date', { ns: 'rails' }),
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => {
      if (!row.original?.adminPagePath) return null;
      return (
        <div className="flex justify-end">
          <ButtonPrimary className="mr-[10px]" to={row.original.adminPagePath}>
            Voir
          </ButtonPrimary>
        </div>
      );
    },
  }),
];

type CorporateInvoicesListProps = {
  frameworkContract: PersistedSpraypaintRecord<FrameworkContract>;
  className?: string;
};
export const CorporateInvoicesList: FunctionComponent<
  CorporateInvoicesListProps
> = ({ frameworkContract, className }) => {
  const [scope, scopeKey, props] = useBackendTable<Invoice>();
  const { data, isLoading } = useQuery({
    queryKey: ['corporate_invoices', scopeKey],
    queryFn: () =>
      scope(
        Invoice.where({
          frameworkContractId: frameworkContract.id,
          invoiceType: 'corporate',
        }),
      ).all(),
    enabled: !!frameworkContract,
  });
  const { t } = useTranslation();
  const totalCount = data?.meta?.stats?.total?.count;

  const records = data?.data ?? ([] as ModelRecord<Invoice>[]);

  return (
    <MainCard
      header={{
        title: t('activerecord.models.corporate_invoices', {
          ns: 'rails',
        }),
      }}
      className={className}
    >
      <Table
        className="mt-[20px]"
        emptyTableErrorMsg="react_table.no_data_text"
        isLoading={isLoading}
        withImageErrorMsg={false}
        totalCount={totalCount}
        data={records}
        reactTableProps={props}
        columns={columns}
      />
    </MainCard>
  );
};
