import type { MoneyType } from '@/modules/common/utils/type.utils';
import ActivityAccountLine from '@spraypaint/activityAccount/ActivityAccountLine';

export type ActivityAccountLinePayloadType = {
  date: string;
  label: string;
  categories: string[];
  value: MoneyType;
  activity_account_id: string;
};
export const createActivityAccountLine = async (
  payload: ActivityAccountLinePayloadType,
): Promise<ActivityAccountLine> => {
  const activityAccountLine = new ActivityAccountLine(payload);

  return activityAccountLine.saveOrThrow();
};
