import React from 'react';
import useLegalEntitiesSelectOptions from '@/modules/common/legalEntity/useLegalEntitiesSelectOptions';
import { FilterComponentProps } from '@/modules/common/components/table/filter/types';

type Option = {
  value: string;
  label: string;
};

type SelectOptions = {
  options: Option[];
};

type Props<TData, TValue> = FilterComponentProps<
  TData,
  TValue,
  SelectOptions
> & { options?: SelectOptions };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withLegalEntitiesOptions<P extends Props<any, any>>(
  InnerComponent: (props: P) => JSX.Element,
): React.ComponentType<P> {
  return function LegalEntitiesOptionsWrapper(props: P) {
    const legalEntitiesSelectOptions: Option[] =
      useLegalEntitiesSelectOptions();

    const { options } = props;
    const newOptions = {
      ...options,
      options: legalEntitiesSelectOptions,
    };

    return <InnerComponent {...props} options={newOptions} />;
  };
}
