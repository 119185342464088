import mixpanel, { Config } from 'mixpanel-browser';

let token: string;
const options: Partial<Config> = {};

const PRODUCTION_TOKEN = '49bdac3be82a5a87a954e80d6f19c50c';
const DEVELOPMENT_TOKEN = '9e7a905addd3614b79089c66b63209c7';
export const PRIUM_CLIENT = 'priumone_web';
export const CLIENT_VERSION = process.env.PRIUM_ONE_VERSION || 'undefined';

switch (window.location.hostname) {
  case 'extranet.prium-portage.com':
    // Production
    token = PRODUCTION_TOKEN;
    options.debug = false;
    options.ignore_dnt = false;
    break;
  case 'localhost':
    // development
    token = DEVELOPMENT_TOKEN;
    options.debug = true;
    options.ignore_dnt = true;
    break;
  default:
    // stagings
    token = DEVELOPMENT_TOKEN;
    options.debug = true;
    options.ignore_dnt = false;
}

mixpanel.init(token, options);
mixpanel.register({
  prium_client: PRIUM_CLIENT,
  prium_client_version: CLIENT_VERSION,
});

export default mixpanel;
