import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { values } from 'lodash';
import ActivityAccountLine, {
  categories,
} from '@spraypaint/activityAccount/ActivityAccountLine';
import { ControlledSelect } from '@/modules/common/components/inputs/controlledInput/ControlledSelect';
import { ControlledDateInput } from '@/modules/common/components/inputs/controlledInput/ControlledDateInput';
import { ControlledNumberInput } from '@/modules/common/components/inputs/controlledInput/ControlledNumberInput';
import { TextInput } from '@/modules/common/components/inputs';
import { Toaster } from '@/modules/common/components/toasts';
import {
  Button,
  ButtonPrimary,
  ButtonSecondary,
} from '@/modules/common/components/buttons';
import { Line } from '@/modules/common/components/fragments';
import { CustomModal } from '@/modules/common/components/fragments/CustomModal';
import { ApiValidationError } from '@spraypaint/ApiValidationError';
import { CloseIcon } from '../../../../app/assets/icons/buttons';
import {
  ActivityAccountLinePayloadType,
  createActivityAccountLine,
} from '../functions/createActivityAccountLine';

type Props = {
  displayModal: boolean;
  handleSetCloseModal: () => void;
  activityAccountId?: string;
  refreshData: () => void;
};

type FormState = {
  date?: Date;
  label?: string;
  value?: { amount: number };
  cat1?: string;
  cat2?: string;
  cat3?: string;
};

type ModalFieldValues = Pick<
  FormState,
  'date' | 'value' | 'label' | 'cat1' | 'cat2' | 'cat3'
>;

export const ActivityAccountModal = ({
  displayModal,
  handleSetCloseModal,
  activityAccountId,
  refreshData,
}: Props) => {
  const { t } = useTranslation();

  const { handleSubmit, control, register } = useForm<ModalFieldValues>();

  const { mutate } = useMutation(
    (payload: ActivityAccountLinePayloadType) =>
      createActivityAccountLine(payload),
    {
      onSuccess: () => {
        toast.success(<Toaster textMsg="success" toastType="success" />);
        handleSetCloseModal();
        refreshData();
      },
      onError: (error) => {
        if (ApiValidationError.isInstance(error, ActivityAccountLine)) {
          values(error.errors).forEach((validationError) => {
            if (!validationError) return null;
            return toast.error(
              <Toaster
                textMsg={validationError.fullMessage}
                toastType="error"
              />,
            );
          });
        } else {
          toast.error(
            <Toaster
              textMsg="An unexpected error occurred during line creation."
              toastType="error"
            />,
          );
        }
      },
    },
  );

  const onSubmit = async ({
    date,
    label,
    value,
    cat1,
    cat2,
    cat3,
  }: ModalFieldValues) => {
    const chosenCategories = [cat1, cat2, cat3].filter(
      (cat) => cat !== undefined,
    );

    const categoriesToSave =
      chosenCategories.length > 0 ? chosenCategories : [];

    const payload = {
      date: date?.toISOString().split('T')[0],
      label,
      value: { amount: value?.amount, currency: 'EUR' },
      categories: categoriesToSave,
      activity_account_id: activityAccountId,
    };

    mutate(payload as ActivityAccountLinePayloadType);
  };

  const options = categories.map((value) => ({
    label: value,
    value,
  }));

  return (
    <CustomModal
      isOpen={displayModal}
      onRequestClose={handleSetCloseModal}
      contentLabel="Activity Account Modal"
    >
      <div className="flex flex-col fill-color-medium-grey w-[300px] md:w-[1200px] h-[400px] md:mb-[80px]">
        <Button
          custom
          className="self-end mt-[25px] mr-[25px]"
          onClick={handleSetCloseModal}
        >
          <CloseIcon />
        </Button>
        <p className="text-xl ml-[10px]">{t('front.activityAccount.charge')}</p>
        <Line rounded className="mt-[20px]" />
        <form onSubmit={handleSubmit(onSubmit)} className="px-[40px] mt-5">
          <div className="flex justify-between">
            <ControlledDateInput
              label={t('front.activityAccount.table.date')}
              control={control}
              defaultValue={undefined}
              required
              name="date"
            />
            <TextInput
              {...register('label')}
              required
              label={t('front.activityAccount.table.label')}
            />
            <ControlledNumberInput
              rules={{ required: true }}
              colDisplay
              label={t('front.activityAccount.table.amount')}
              control={control}
              name="value.amount"
              defaultValue={0}
            />
          </div>
          <div className="flex justify-between mt-[30px]">
            <ControlledSelect
              required
              label={t('front.activityAccount.modal.category')}
              className="flex-col"
              control={control}
              name="cat1"
              options={options}
              defaultValue={undefined}
            />
            <ControlledSelect
              label={t('front.activityAccount.modal.category')}
              className="flex-col"
              control={control}
              name="cat2"
              options={options}
              defaultValue={undefined}
            />
            <ControlledSelect
              label={t('front.activityAccount.modal.category')}
              className="flex-col"
              control={control}
              name="cat3"
              options={options}
              defaultValue={undefined}
            />
          </div>
          <div className="absolute bottom-0 right-0 flex justify-end content-end space-x-[10px] mr-[20px] mb-[20px]">
            <ButtonPrimary onClick={handleSubmit(onSubmit)}>
              {t('views.shared.buttons.approve')}
            </ButtonPrimary>
            <ButtonSecondary
              className="ml-[10px]"
              onClick={handleSetCloseModal}
            >
              {t('views.shared.buttons.cancel')}
            </ButtonSecondary>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};
