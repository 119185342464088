import { PeriodType } from '../../common/utils/type.utils';
import { getMonthName } from '../../common/utils/date.utils';

export type PayslipAgendaProps = {
  period?: PeriodType;
};

export const PayslipAgenda = ({ period }: PayslipAgendaProps) => (
  <div className="w-[100px] h-[87px] bg-illus-agenda bg-no-repeat relative ">
    <div className="relative text-center text-white text-sm2">
      {period?.year}
    </div>
    <div className="relative text-center top-4 text-color-medium-grey text-xl1">
      {getMonthName(period?.year as number, period?.month as number, 'short')}
    </div>
  </div>
);
