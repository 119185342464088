import { LoaderFunction } from 'react-router-dom';

/*
  This function is used to chain multiple loaders together.
  Chain is broken if any loader throws an error or return a Response object with a non 2xx status code.

  Loaders are called sequentially (no parallelization)
 */
export function chainLoaders(loaders: LoaderFunction[]): LoaderFunction {
  return async (args) => {
    let lastResult = null;

    // eslint-disable-next-line no-restricted-syntax -- forEach does not allow to return early
    for (const loader of loaders) {
      // eslint-disable-next-line no-await-in-loop -- We want this to be sequential
      const result = await loader(args);
      // check if the result is an instance of Response with a non 2xx status code
      if (result instanceof Response && !result.ok) {
        return result;
      }
      lastResult = result;
    }
    return lastResult;
  };
}
