import { RoutesMounter } from '@/types';
import { index, member, redirectTo } from '@/app/navigation/functions';
import { AdminInvoiceShow } from '@/modules/admin/invoices/pages/AdminInvoiceShow';
import { loader } from '@/modules/invoices/functions/invoice_query';
import { CreateCredit } from '@/modules/admin/invoices/pages/CreateCredit';
import AdminInvoiceEdit from './pages/AdminInvoiceEdit';
import AddProvisionalInvoice from './pages/AddProvisionalInvoice';
import PayInvoice from './pages/PayInvoice';

export const mountAdminInvoicesRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { queryClient, t },
}) => [
  member({
    path,
    handle: { crumb: () => 'Factures' },
    children: [
      index({ loader: redirectTo('/v2/admin') }),
      member({
        path: ':id',
        handle: {
          crumb: ({ data }) =>
            t('front.invoices.crumbs.show', { number: data?.data?.number }),
        },
        loader: loader(queryClient),
        children: [
          index({ Component: AdminInvoiceShow }),
          {
            path: 'edit',
            Component: AdminInvoiceEdit,
            handle: { crumb: () => t('front.invoices.crumbs.edit') },
          },
          {
            path: 'pay',
            Component: PayInvoice,
            handle: { crumb: () => t('front.invoices.crumbs.pay') },
          },
          {
            path: 'create_credit',
            Component: CreateCredit,
            handle: { crumb: () => t('front.invoices.crumbs.create_credit') },
          },
          {
            path: 'add_provisional_invoice',
            Component: AddProvisionalInvoice,
            handle: {
              crumb: () => t('front.invoices.crumbs.add_provisional_invoice'),
            },
          },
        ],
      }),
    ],
  }),
];
