import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useValidation } from '@/app/form/ValidationForm';
import { useForm } from 'react-hook-form';
import { TextInput } from '@common/components/inputs';
import { ButtonPrimary } from '@common/components/buttons';
import {
  EMAIL_AUTH_REASON_CODES,
  EmailAuthRequestError,
  useEmailAuthRequest,
} from '@/modules/authentification/hooks/useEmailAuthRequest';
import { noDefault } from '@/app/utils/typescriptUtils';
import {
  useVerifyAccountResend,
  VERIFY_ACCOUNT_RESEND_REASON_CODES,
  VerifyAccountResendRequestError,
} from '@/modules/authentification/hooks/useVerifyAccountResend';
import { AxiosError } from 'axios';

export type EmailLoginCallbacks = {
  onPasswordAuth: (email: string) => void;
  onEmailAuth: (email: string) => void;
  onVerifyAccount: (email: string, context: { emailSent: boolean }) => void;
  onRegister: (email: string) => void;
};

export type EmailLoginFormProps = {
  initialEmail?: string;
  autoSubmit?: boolean;
} & EmailLoginCallbacks;

export type EmailFormValue = {
  email: string;
};

function dataToParams(data: EmailFormValue): { login: string } {
  return {
    login: data.email,
  };
}

function checkAxiosError<TError>(error: unknown): error is AxiosError<TError> {
  // @ts-expect-error Duck typing
  return 'isAxiosError' in error;
}

/*
  Call emailAuthRequest and verifyAccountResend to define the next step of the login process
*/
function useEmailLoginCallback({
  onEmailAuth,
  onPasswordAuth,
  onVerifyAccount,
  onRegister,
}: EmailLoginCallbacks): (data: EmailFormValue) => Promise<void> {
  const { mutateAsync: callEmailAuth } = useEmailAuthRequest();
  const { mutateAsync: callVerifyAccountResend } = useVerifyAccountResend();

  return useCallback(
    async (data: EmailFormValue) => {
      const { email } = data;
      try {
        await callEmailAuth(dataToParams(data));
        return onEmailAuth(email);
      } catch (error) {
        // Ignore if this is an Axios error
        if (!checkAxiosError<EmailAuthRequestError>(error)) {
          throw error;
        }
        const reasonCode = error.response?.data?.reason_code;
        switch (reasonCode) {
          case EMAIL_AUTH_REASON_CODES.EMAIL_AUTH_DISABLED:
            // This is a standard user, show the password form
            return onPasswordAuth(email);
          case EMAIL_AUTH_REASON_CODES.NO_MATCHING_LOGIN:
            // Unknown user, ask to retry or register
            try {
              await callVerifyAccountResend(dataToParams(data));
              return onVerifyAccount(email, { emailSent: true });
            } catch (resendError) {
              if (
                !checkAxiosError<VerifyAccountResendRequestError>(resendError)
              ) {
                throw resendError;
              }
              const resendReasonCode = resendError.response?.data?.reason_code;
              switch (resendReasonCode) {
                case VERIFY_ACCOUNT_RESEND_REASON_CODES.NO_MATCHING_LOGIN:
                  return onRegister(email);
                case undefined:
                  return onVerifyAccount(email, { emailSent: false });
                default:
                  noDefault(resendReasonCode);
                  throw error;
              }
            }
          case undefined:
            throw error;
          default:
            noDefault(reasonCode);
            throw error;
        }
      }
    },
    [
      onEmailAuth,
      onPasswordAuth,
      onVerifyAccount,
      onRegister,
      callEmailAuth,
      callVerifyAccountResend,
    ],
  );
}

/*
  Display the email input and submit button for a login or register form

  Once the form is submitted,
 */
export const EmailLoginForm: FunctionComponent<EmailLoginFormProps> = ({
  initialEmail,
  onEmailAuth,
  onPasswordAuth,
  onVerifyAccount,
  onRegister,
}) => {
  const { t } = useTranslation();
  const Validation = useValidation();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm<EmailFormValue>({ defaultValues: { email: initialEmail ?? '' } });

  const onSubmit = useEmailLoginCallback({
    onEmailAuth,
    onPasswordAuth,
    onVerifyAccount,
    onRegister,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center md:items-start">
        <TextInput
          testID="login-input-email"
          label={t('front.auth.signIn.form.email.label') as string}
          placeholder={t('front.auth.signIn.form.email.placeholder') as string}
          className="mt-[33px]"
          {...register('email', Validation.email)}
          type="email"
          required
          errorText={errors?.email}
        />
      </div>
      <div className="flex items-center justify-center md:justify-start mt-[33px]">
        <ButtonPrimary
          testID="login-button-submit"
          type="submit"
          isLoading={isSubmitting}
        >
          {t('front.auth.signIn.form.next.title')}
        </ButtonPrimary>{' '}
      </div>
    </form>
  );
};
