/* eslint-disable import/no-cycle */
// api/Blob.ts
import { Model, Attr } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';

@Model()
class Blob extends ApplicationRecord {
  static jsonapiType = 'blobs';

  // Attributes
  @Attr() filename!: string;
  @Attr() content!: unknown;
  @Attr() contentType!: string;
  @Attr() metadata!: unknown;
  @Attr({ persist: false }) download!: unknown;
  @Attr({ persist: false }) byteSize!: number;
  @Attr({ persist: false }) checksum!: string;
  @Attr({ persist: false }) createdAt!: string;

  // Relationships
}

export default Blob;
