import { FunctionComponent, useState } from 'react';
import { AuthHeader, AuthTitle } from '@/modules/authentification/components';
import { useTranslation } from 'react-i18next';
import { EmailLoginForm } from '@/modules/authentification/components/EmailLoginForm';
import URLSearchParams from '@ungap/url-search-params';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { RegisterForm } from '@/modules/authentification/components/RegisterForm';
import { AuthMainContainer } from '../components/cards/AuthMainContainer';

export const RegisterEmailForm: FunctionComponent<{
  initialEmail: string | null;
  onNext: (email: string) => void;
}> = ({ initialEmail, onNext }) => {
  const navigate = useNavigate();

  return (
    <EmailLoginForm
      autoSubmit
      initialEmail={initialEmail || ''}
      onVerifyAccount={(email, { emailSent }) => {
        const params: Record<string, string> = { email };
        if (emailSent) {
          params.emailSent = '1';
        }

        navigate({
          pathname: '../verify_account',
          search: createSearchParams(params).toString(),
        });
      }}
      onEmailAuth={(email) =>
        navigate({
          pathname: '../email_code',
          search: createSearchParams({ email }).toString(),
        })
      }
      onPasswordAuth={(email) =>
        navigate(
          {
            pathname: '../login',
          },
          { state: { fromRegister: true, email } },
        )
      }
      onRegister={onNext}
    />
  );
};

export const Register: FunctionComponent = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const emailParam = queryParams.get('email');
  const { t } = useTranslation();

  const location = useLocation();
  const { email: locationEmail } = location.state || {};

  const [formEmail, setFormEmail] = useState<string | undefined>(locationEmail);

  const displayEmailForm = !formEmail;

  return (
    <div
      data-testid="login-index-container"
      className="h-screen w-full flex justify-between"
    >
      <div className="flex flex-col justify-between flex-1">
        <div>
          <AuthHeader />
          <AuthMainContainer>
            <AuthTitle>{t('front.auth.register.title')}</AuthTitle>
            {displayEmailForm ? (
              <RegisterEmailForm
                initialEmail={emailParam}
                onNext={(e) => setFormEmail(e)}
              />
            ) : (
              <RegisterForm
                email={formEmail}
                navigateToEmailForm={() => setFormEmail(undefined)}
              />
            )}
          </AuthMainContainer>
        </div>
      </div>
    </div>
  );
};
