import {
  JsonapiResourceRequest,
  Model,
  Request,
  SpraypaintBase,
} from 'spraypaint';

import { underscore } from 'inflected';
import { Item, serializeDictionary } from 'structured-headers';
import { SaveOptions } from 'spraypaint/lib-esm/model';
import { ApiValidationError } from './ApiValidationError';
import mixpanel from '../mixpanel/mixpanel';
import { noDefault } from '../utils/typescriptUtils';

export type RequestVerbs = 'get' | 'post' | 'patch' | 'delete';
export type UrlParams =
  | string
  | URLSearchParams
  | string[][]
  | Record<string, string>
  | undefined;

@Model()
class ApplicationRecord extends SpraypaintBase {
  static baseUrl = '';

  static apiNamespace = '/api/v2';

  static clientApplication = 'web-v1';

  static priumEnv: string | null = null;

  static language?: string;

  static serializeKey(key: string): string {
    return underscore(key.replace('-', ' ')).replace(' ', '-');
  }
  static endpointOverride: string;

  static get endpoint(): string {
    return (
      this.endpointOverride ?? `/${this.jsonapiType?.replaceAll('-', '/')}`
    );
  }

  static set endpoint(value) {
    this.endpointOverride = value;
  }

  async saveOrThrow(options?: SaveOptions<this> | undefined): Promise<this> {
    const success = await this.save(options);
    if (success) {
      return this;
    }
    throw new ApiValidationError(this);
  }

  get jsonapiType(): typeof ApplicationRecord.jsonapiType {
    const constructor = <typeof ApplicationRecord>this.constructor;
    return constructor.jsonapiType;
  }

  // Make a custom request to the API
  protected async fetchMemberAction<TResponse = unknown>(
    action: RequestVerbs,
    path: string, // Relative path from resource path
    options: {
      urlParams?: UrlParams;
      body?: JsonapiResourceRequest;
      fetchOptions?: RequestInit;
    } = {},
  ): Promise<TResponse> {
    const { urlParams, body, fetchOptions } = options;

    const request = new Request(this.klass.middlewareStack, this.klass.logger, {
      patchAsPost: this.klass.patchAsPost,
    });

    const requestFetchOptions: RequestInit = {
      ...this.klass.fetchOptions(),
      ...fetchOptions,
    };

    // get and delete expect 2 arguments, post and patch expect 3
    switch (action) {
      case 'get':
      case 'delete':
        return request[action](
          this.memberActionUrl(path, urlParams),
          requestFetchOptions,
        );
      case 'post':
      case 'patch':
        return request[action](
          this.memberActionUrl(path, urlParams),
          body as JsonapiResourceRequest,
          requestFetchOptions,
        );
      default:
        return noDefault(action);
    }
  }

  private memberActionUrl(path: string, urlParams: UrlParams): string {
    const url = this.klass.url(this.id) + `/${path}`.replace(/\/\//g, '/');
    const params = new URLSearchParams(urlParams).toString();
    return params ? `${url}?${params}` : url;
  }
}

const { middlewareStack } = ApplicationRecord;
middlewareStack.afterFilters.push((response) => {
  ApplicationRecord.priumEnv = response.headers.get('x-prium-env');
});

const PRIUM_CLIENT = 'priumone_web';
const CLIENT_VERSION = process.env.PRIUM_ONE_VERSION || 'undefined';
// Set X-Client-Application header
middlewareStack.beforeFilters.push((requestUrl, options) => {
  // no-param-reassign is disabled because this is the way Spraypaint is documented to work

  // @ts-expect-error headers type is not correct in Spraypaint
  // eslint-disable-next-line no-param-reassign
  options.headers['X-PriumApiClient'] = PRIUM_CLIENT;

  // @ts-expect-error headers type is not correct in Spraypaint
  // eslint-disable-next-line no-param-reassign
  options.headers['X-PriumApiClientVersion'] = CLIENT_VERSION;
});

// Set AcceptLanguage header
middlewareStack.beforeFilters.push((requestUrl, options) => {
  // no-param-reassign is disabled because this is the way Spraypaint is documented to work
  // @ts-expect-error headers type is not correct in Spraypaint
  // eslint-disable-next-line no-param-reassign
  options.headers['Accept-Language'] = ApplicationRecord.language || 'fr en';
});

const WATCHED_PROPERTIES = ['tutorial_on', 'menu_retracted'];

// Set X_TELEMETRY_CONTEXT header
middlewareStack.beforeFilters.push((requestUrl, options) => {
  const dictItems: Array<[string, Item]> = WATCHED_PROPERTIES.reduce(
    (acc, prop) => {
      const value = mixpanel.get_property(prop);
      // Skip if the value is undefined
      if (value !== undefined) {
        acc.push([prop, [value, new Map()]]);
      }
      return acc;
    },
    [] as Array<[string, Item]>,
  );

  // Add "Do not track" status
  dictItems.push(['dnt', [mixpanel.has_opted_out_tracking(), new Map()]]);

  // no-param-reassign is disabled because this is the way Spraypaint is documented to work
  // @ts-expect-error headers type is not correct in Spraypaint
  // eslint-disable-next-line no-param-reassign
  options.headers['X-PriumTelemetryContext'] = serializeDictionary(
    new Map(dictItems),
  );
});

export default ApplicationRecord;
