import { Attr, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class ReviewStep extends ApplicationRecord {
  // Attributes
  static jsonapiType = 'onboarding_funnel_steps-review_steps';
  static endpoint = '/onboarding_funnel_steps/review_steps';

  // Attributes
  @Attr() birthdate: string;
  @Attr() civility: string;
  @Attr() firstName: string;
  @Attr() lastName: string;
  @Attr() birthCountryId: number;
  @Attr() countryId: number;
  @Attr() socialSecurityNumber: string;
  @Attr() addressNumber: string;
  @Attr() addressRoad: string;
  @Attr() addressCity: string;
  @Attr() addressZipcode: string;
  @Attr() addressCountry: string;
  @Attr() addressCountryCode: string;
  @Attr() expertiseId: number;
  @Attr() portageOfferId: number;
}
export default ReviewStep;
