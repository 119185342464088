import { useTranslation } from 'react-i18next';
import { useDisplayNotificationIndex } from '@/modules/notifications/hooks/useDisplayNotificationIndex';
import { AvatarIcon } from '../../../app/assets/icons/buttons';
import {
  AcompteIcon,
  Advantage,
  CaIcon,
  ClientIcon,
  CommandeIcon,
  ContratIcon,
  Contributions,
  FraisIcon,
  NotificationIcon,
  SalaireIcon,
  SubcontractorsIcon,
  TdbIcon,
  VideoTuto,
} from '../../../app/assets/icons/menu';
import { useCurrentEmployee } from '../../authentification/hooks';
import { MenuType } from '../types/MenuType';
import { useCurrentEmployeeId } from '../../../app/hooks/useCurrentEmployeeId';

export const menuEmployeeItems = (): Array<MenuType> => {
  const { employee, isLoading } = useCurrentEmployee();
  const currentEmployeeId = useCurrentEmployeeId();
  const { displayNotificationsIndex } = useDisplayNotificationIndex();

  const { t } = useTranslation();

  const employeeValidatedOrArchived = ['validated', 'archived'].includes(
    employee?.status || '',
  );
  const employeePorteCommercial = !!employee?.porteCommercial;
  const employeeOptions = employee?.enabledProfiles ?? [];

  const employeeV1BasePath = `employees/${currentEmployeeId}`;

  return [
    {
      type: 'separator' as const,
      title: isLoading ? '' : employee?.user?.fullName || '',
      displaySeparator: true,
    },
    {
      title: t('front.menu.dashboard'),
      toV1: `${employeeV1BasePath}/dashboard`,
      icon: TdbIcon,
      canBeDisplay: true,
    },
    {
      title: t('front.menu.activity_account'),
      toV1: `${employeeV1BasePath}/activity_account`,
      to: 'activity_account',
      moduleName: 'activity_account',
      icon: CaIcon,
      canBeDisplay: true,
    },
    {
      title: t('front.menu.clients'),
      toV1: `${employeeV1BasePath}/clients`,
      icon: ClientIcon,
      canBeDisplay: true,
    },
    {
      title: t('front.menu.invoices'),
      toV1: `${employeeV1BasePath}/invoices`,
      icon: CommandeIcon,
      canBeDisplay: employeeValidatedOrArchived,
    },
    {
      title: t('front.menu.expenses'),
      toV1: `${employeeV1BasePath}/expenses`,
      icon: FraisIcon,
      canBeDisplay: employeeValidatedOrArchived && !employeePorteCommercial,
    },
    {
      title: t('front.menu.advance_payments'),
      toV1: `${employeeV1BasePath}/advance_payments`,
      icon: AcompteIcon,
      canBeDisplay: employeeValidatedOrArchived && !employeePorteCommercial,
    },
    {
      title: t('front.menu.payslips'),
      toV1: `${employeeV1BasePath}/payslips`,
      to: 'payslips',
      moduleName: 'payslips',
      icon: SalaireIcon,
      canBeDisplay: employeeValidatedOrArchived && !employeePorteCommercial,
    },
    {
      title: t('front.menu.my_documents'),
      toV1: `${employeeV1BasePath}/my_documents`,
      icon: ContratIcon,
      canBeDisplay: true,
    },
    {
      title: t('front.menu.subcontractors'),
      to: 'subcontractors',
      icon: SubcontractorsIcon,
      canBeDisplay:
        employeeValidatedOrArchived &&
        employeeOptions.includes('subcontractor'),
    },
    {
      title: t('front.menu.subcontractor_bills'),
      to: 'subcontractor_bills',
      icon: CommandeIcon,
      canBeDisplay:
        employeeValidatedOrArchived &&
        employeeOptions.includes('subcontractor'),
    },
    {
      title: t('front.menu.intern_subcontractors'),
      toV1: `${employeeV1BasePath}/intern_subcontractors`,
      icon: SubcontractorsIcon,
      canBeDisplay:
        employeeValidatedOrArchived &&
        employeeOptions.includes('intern_subcontractor'),
    },
    {
      title: t('front.menu.contributions'),
      toV1: `${employeeV1BasePath}/contributions`,
      icon: Contributions,
      canBeDisplay:
        employeeValidatedOrArchived &&
        employeeOptions.includes('contrib_plans') &&
        !employeePorteCommercial,
    },
    {
      title: t('front.menu.my_perks'),
      to: 'my_perks',
      icon: Advantage,
      canBeDisplay: true,
    },
    {
      title: t('front.menu.video_tutorials'),
      to: 'video_tutorials',
      icon: VideoTuto,
      canBeDisplay: true,
    },
    {
      title: 'Notifications',
      to: 'notifications',
      icon: NotificationIcon,
      canBeDisplay: displayNotificationsIndex,
    },
    {
      title: t('front.menu.profile'),
      toV1: `${employeeV1BasePath}`,
      noUnderline: true,
      icon: AvatarIcon,
      canBeDisplay: true,
    },
  ];
};
