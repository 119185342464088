/* eslint-disable no-param-reassign */
import { useQuery } from '@tanstack/react-query';
import { SepaTransactionAssociation } from '../../../../../app/spraypaint';

export const useGetTransactionAssociations = (sepaTransactionId?: string) => {
  const data = useQuery({
    queryKey: ['get-transaction-associations', sepaTransactionId],
    enabled: !!sepaTransactionId,
    queryFn: () =>
      SepaTransactionAssociation.includes([
        'associable.subcontractor',
        'associable.employee',
      ])
        .where({ sepaTransactionId })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const transactionAssociations = data?.data?.data || [];

  return { ...data, transactionAssociations };
};
