import { Invoice } from '@/app/spraypaint';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from '@/modules/common/components/buttons';

export function InvoiceDownLoadButton({
  invoice,
}: {
  invoice: Invoice | undefined;
}) {
  // Download URL are valid for 10 minutes
  // Refresh every 5 minutes
  const { data, isLoading } = useQuery({
    queryKey: ['invoice-pdf', invoice?.id],
    enabled: !!invoice?.id,
    queryFn: async () => {
      if (!invoice?.id) {
        return undefined;
      }
      const inv = await Invoice.selectExtra(['generatedDocumentUrl'])
        .select(['id'])
        .find(invoice.id);
      return inv?.data?.generatedDocumentUrl;
    },
    refetchInterval: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });

  const { t } = useTranslation();

  return (
    <ButtonPrimary href={data ?? '#'} disabled={!data} isLoading={isLoading}>
      {t('default.button.download')}
    </ButtonPrimary>
  );
}

export const InvoiceEditButton = () => {
  const { t } = useTranslation();

  return (
    <ButtonPrimary to="./edit" className="ml-3">
      {t('front.invoices.show.header.buttons.edit')}
    </ButtonPrimary>
  );
};
export const InvoiceEmployeeViewButton = ({
  invoice,
}: {
  invoice: Invoice;
}) => {
  const { t } = useTranslation();
  if (invoice.invoiceType === 'corporate') return null;
  return (
    <ButtonPrimary href={invoice.employeePagePath ?? '#'} className="ml-3">
      {t('front.invoices.show.header.buttons.employee_view')}
    </ButtonPrimary>
  );
};

export const PayInvoiceButton = ({ invoice }: { invoice: Invoice }) => {
  const { t } = useTranslation();
  if (!['validated', 'partially_paid'].includes(invoice.status)) return null;
  return (
    <ButtonPrimary to="./pay" className="ml-3">
      {t('front.invoices.show.header.buttons.pay')}
    </ButtonPrimary>
  );
};

export const CreateCreditButton = ({ invoice }: { invoice: Invoice }) => {
  const { t } = useTranslation();
  if (!['validated', 'partially_paid'].includes(invoice.status)) return null;
  return (
    <ButtonPrimary to="./create_credit" className="ml-3">
      {t('front.invoices.show.header.buttons.create_credit')}
    </ButtonPrimary>
  );
};
