import { MouseEvent } from 'react';
import { useUserManagerRole } from '@/modules/authentification/hooks';
import {
  ButtonOnClickHandler,
  ButtonPrimary,
} from '@/modules/common/components/buttons';
import { CommentWidget } from '@/modules/common/components/comments/CommentWidget';
import { Breadcrumbs } from '@/modules/navigation/components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { MainCard } from '@/modules/common/components/mainCard';
import { LineText } from '@/modules/common/components/fragments/LineText';
import Status, {
  StatusType,
} from '@/modules/common/components/statuses/Status';
import dayjs from 'dayjs';
import { handleDownloadFile } from '@/modules/common/components/buttons/utils/DownloadFunction';
import { Toaster } from '@/modules/common/components/toasts';
import { FactorClientPaymentTransaction, ModelRecord } from '@/app/spraypaint';
import { onRowClickNavigate } from '@/app/navigation/functions';
import Table from '@/modules/common/components/table/Table';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { refreshFileMatching } from '../hooks/useRefreshMatching';
import { usefactorClientPaymentFile } from '../functions/factorClientPaymentFileQuery';
import {
  useGetPendingFactorClientPaymentTransactions,
  useGetValidatedFactorClientPaymentTransactions,
} from '../hooks/useGetFactorClientPaymentTransaction';
import {
  pendingFactorClientPaymentTransactionColumns,
  validatedFactorClientPaymentTransactionColumns,
} from '../columns/factorClientPaymentTransactionsColumns';

const ClientPaymentFileShow = () => {
  const { t } = useTranslation();
  const isOnlyManager = useUserManagerRole();
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: clientPaymentFileData } = usefactorClientPaymentFile(
    id as string,
  );
  // const stats = clientPaymentFileData?.meta.stats;
  const clientPaymentFile = clientPaymentFileData?.data;

  const [
    validatedFactorClientPaymentTransactionsScope,
    validatedFactorClientPaymentTransactionsScopeKey,
    validatedFactorClientPaymentTransactionsProps,
  ] = useBackendTable<FactorClientPaymentTransaction>({
    initialSorting: [{ id: 'updated_at', desc: true }],
  });
  const [
    pendingFactorClientPaymentTransactionsScope,
    pendingFactorClientPaymentTransactionsScopeKey,
    pendingFactorClientPaymentTransactionsProps,
  ] = useBackendTable<FactorClientPaymentTransaction>({
    initialPageSize: 30,
    initialSorting: [{ id: 'id', desc: false }],
  });
  const {
    validatedFactorClientPaymentTransactions,
    isLoading: validatedFactorClientPaymentTransactionsLoader,
    totalCount: validatedFactorClientPaymentTransactionsTotalCount,
  } = useGetValidatedFactorClientPaymentTransactions(
    validatedFactorClientPaymentTransactionsScopeKey,
    validatedFactorClientPaymentTransactionsScope,
    id as string,
  );
  const {
    pendingFactorClientPaymentTransactions,
    isLoading: pendingFactorClientPaymentTransactionsLoader,
    totalCount: pendingFactorClientPaymentTransactionsTotalCount,
  } = useGetPendingFactorClientPaymentTransactions(
    pendingFactorClientPaymentTransactionsScopeKey,
    pendingFactorClientPaymentTransactionsScope,
    id as string,
  );

  const { mutate } = useMutation(refreshFileMatching);

  const handleRefreshFileMatching = async () => {
    const payload = { clientPaymentFileId: clientPaymentFile?.id };

    mutate(payload, {
      onSuccess: () => {
        const successMsg = t('front.refreshMatching.toastMsg.success');
        toast.success(<Toaster textMsg={successMsg} toastType="success" />);
        queryClient.invalidateQueries([
          'factor-client_payment_files',
          clientPaymentFile?.id,
        ]);
      },
      onError: () => {
        const errorText = t('front.refreshMatching.toastMsg.error.file', {
          fileName: clientPaymentFile?.filename,
        });
        toast.error(<Toaster textMsg={errorText} toastType="error" />);
      },
    });
  };

  const handleDownload: ButtonOnClickHandler = (event) => {
    handleDownloadFile({
      event,
      file: {
        name: clientPaymentFile?.filename as string,
        url: clientPaymentFile?.link as string,
      },
    });
  };

  const handleNavigateAssociationShowPage =
    (raw: ModelRecord<FactorClientPaymentTransaction>) =>
    (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminShowPagePath,
        event,
        windowNavigationHref: raw.adminShowPagePath,
        navigate,
      });
    };

  const handleNavigateAssociationPage =
    (raw: ModelRecord<FactorClientPaymentTransaction>) =>
    (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminAssignationPagePath,
        event,
        windowNavigationHref: raw.adminAssignationPagePath,
        navigate,
      });
    };

  return (
    <div className="mb-[50px]">
      <p className="big-title ml-5">{t('front.bankTransfer.show.title')}</p>
      <div className="ml-5">
        <Breadcrumbs />
        <div className="flex justify-end mr-5">
          <ButtonPrimary
            disabled={
              clientPaymentFile?.matchingStatus === 'running' || isOnlyManager
            }
            onClick={handleRefreshFileMatching}
          >
            {t('front.button.label.refreshMatching')}
          </ButtonPrimary>
        </div>
      </div>
      <CommentWidget
        commentableType="Factor::ClientPaymentFile"
        commentableId={clientPaymentFile?.id}
      />

      <div className="flex justify-between">
        <MainCard
          header={t('front.mainCard.header.defaultTitle')}
          className="mt-[20px] mx-5 md:w-[800px]"
        >
          <LineText
            label={t(
              'front.factor.reconciliation.clientPaymentFile.matchingStatus',
            )}
            value={
              <Status
                statusType={clientPaymentFile?.matchingStatus as StatusType}
              />
            }
          />
          <LineText
            label={t(
              'front.factor.reconciliation.clientPaymentFile.fileGeneratedAt',
            )}
            value={dayjs(clientPaymentFile?.fileGeneratedAt as string).format(
              'L',
            )}
          />
          <LineText
            label={t('front.factor.reconciliation.clientPaymentFile.createdAt')}
            value={dayjs(clientPaymentFile?.createdAt as string).format('L')}
          />
          <LineText
            onClick={handleDownload}
            label={t('front.factor.reconciliation.clientPaymentFile.filename')}
            value={clientPaymentFile?.filename || '-'}
          />
        </MainCard>
      </div>
      <Table
        title={
          t(
            'front.factor.reconciliation.clientPaymentFile.show.table.pending.title',
          ) as string
        }
        onRowClick={handleNavigateAssociationPage}
        className="mt-[26px]"
        withImageErrorMsg={false}
        emptyTableErrorMsg="react_table.no_data_text"
        isLoading={pendingFactorClientPaymentTransactionsLoader}
        totalCount={pendingFactorClientPaymentTransactionsTotalCount}
        data={pendingFactorClientPaymentTransactions || []}
        reactTableProps={pendingFactorClientPaymentTransactionsProps}
        columns={pendingFactorClientPaymentTransactionColumns(id as string)}
      />
      <Table
        onRowClick={handleNavigateAssociationShowPage}
        title={
          t(
            'front.factor.reconciliation.clientPaymentFile.show.table.validated.title',
          ) as string
        }
        withImageErrorMsg={false}
        emptyTableErrorMsg="react_table.no_data_text"
        className="mt-[26px]"
        isLoading={validatedFactorClientPaymentTransactionsLoader}
        totalCount={validatedFactorClientPaymentTransactionsTotalCount}
        data={validatedFactorClientPaymentTransactions || []}
        reactTableProps={validatedFactorClientPaymentTransactionsProps}
        columns={validatedFactorClientPaymentTransactionColumns(id as string)}
      />
    </div>
  );
};

export default ClientPaymentFileShow;
