import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { MouseEvent } from 'react';
import Table from '../../../common/components/table/Table';
import { useBackendTable } from '../../../common/components/table/hooks';
import {
  useGetAllPendingBankReconciliationTransaction,
  useGetAllCompletedBankReconciliationTransaction,
} from './hooks/useGetAllValidatedTransaction';
import SepaTransaction from '../../../../app/spraypaint/sepaTransactions/SepaTransaction';
import {
  awaitingBankReconciliationColumn,
  completedBankReconciliationColumn,
} from './columns/BankReconciliationColumns';
import { onRowClickNavigate } from '../../../../app/navigation/functions/onRowClickNavigate';

const BankReconciliationIndex = () => {
  const navigate = useNavigate();
  const [scope, scopeKey, props] = useBackendTable<SepaTransaction>({
    initialPageSize: 30,
    initialSorting: [{ id: 'id', desc: false }],
  });
  const [
    completedSepaTransactionScope,
    completedSepaTransactionScopeKey,
    completedSepaTransactionProps,
  ] = useBackendTable<SepaTransaction>();
  const {
    completedSepaTransactions,
    isLoading: getAllCompletedSepaTransactionLoader,
    totalCount: completedSepaTransactionTotalCount,
  } = useGetAllCompletedBankReconciliationTransaction(
    completedSepaTransactionScopeKey,
    completedSepaTransactionScope,
  );
  const {
    pendingSepaTransactions,
    isLoading: getAllPendingSepaTransactionsLoader,
    totalCount: pendingSepaTransactionsTotalCount,
  } = useGetAllPendingBankReconciliationTransaction(scopeKey, scope);
  const { t } = useTranslation();

  const handleNavigateAssociationPage =
    (raw: ModelRecord<SepaTransaction>) => (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminAssignationShowPagePath,
        event,
        windowNavigationHref: raw.adminAssignationShowPagePath,
        navigate,
      });
    };

  const handleNavigateAssociationShowPage =
    (raw: ModelRecord<SepaTransaction>) => (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminReconciliationShowPagePath,
        event,
        windowNavigationHref: raw?.adminReconciliationShowPagePath,
        navigate,
      });
    };

  return (
    <div className="mt-[50px]">
      <div className="flex justify-between px-[20px]">
        <p className="big-title">
          {t('front.bankReconciliation.index.header.title')}
        </p>
      </div>
      <Table
        title={
          t(
            'front.bankReconciliation.table.header.pendingTransaction',
          ) as string
        }
        emptyTableErrorMsg="react_table.no_data_text"
        onRowClick={handleNavigateAssociationPage}
        className="mt-[26px]"
        isLoading={getAllPendingSepaTransactionsLoader}
        withImageErrorMsg={false}
        totalCount={pendingSepaTransactionsTotalCount}
        data={pendingSepaTransactions || []}
        reactTableProps={props}
        columns={awaitingBankReconciliationColumn()}
      />
      <Table
        title={
          t(
            'front.bankReconciliation.table.header.completedTransaction',
          ) as string
        }
        onRowClick={handleNavigateAssociationShowPage}
        emptyTableErrorMsg="react_table.no_data_text"
        className="mt-[26px]"
        withImageErrorMsg={false}
        isLoading={getAllCompletedSepaTransactionLoader}
        totalCount={completedSepaTransactionTotalCount}
        data={completedSepaTransactions || []}
        reactTableProps={completedSepaTransactionProps}
        columns={completedBankReconciliationColumn()}
      />
    </div>
  );
};

export default BankReconciliationIndex;
