import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MainCard } from '@/modules/common/components/mainCard';
import { Invoice } from '@/app/spraypaint';
import { useFormatMoney } from '@/app/utils/moneyUtils';

export type InvoiceLinesCardProps = {
  invoice: Invoice;
};

export const InvoiceLinesCard: FunctionComponent<InvoiceLinesCardProps> = ({
  invoice,
}) => {
  const { t } = useTranslation();
  const formatMoney = useFormatMoney();
  return (
    <MainCard header={t('front.invoices.cards.lines.title')} collapsable>
      {invoice.invoiceLines?.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t('activerecord.attributes.invoice_line.description', {
                    ns: 'rails',
                  })}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t('activerecord.attributes.invoice_line.quantity', {
                    ns: 'rails',
                  })}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t('activerecord.attributes.invoice_line.price', {
                    ns: 'rails',
                  })}
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  {t('activerecord.attributes.invoice_line.amount', {
                    ns: 'rails',
                  })}
                </th>
              </tr>
            </thead>
            <tbody>
              {invoice.invoiceLines.map((line) => (
                <tr key={line.id}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {line.description}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {line.quantity}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {formatMoney(line.price)}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {formatMoney(line.amount)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td
                  colSpan={3}
                  className="text-right px-5 py-3 border-t border-gray-200 bg-gray-100 text-sm font-semibold"
                >
                  {t('activerecord.attributes.invoice.total_amount', {
                    ns: 'rails',
                  })}
                </td>
                <td className="px-5 py-3 border-t border-gray-200 bg-gray-100 text-sm">
                  {formatMoney(invoice.totalAmount)}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  className="text-right px-5 py-3 border-t border-gray-200 bg-gray-100 text-sm font-semibold"
                >
                  {t('activerecord.attributes.invoice.vat', {
                    ns: 'rails',
                  })}{' '}
                  ({invoice.vatRate}%)
                </td>
                <td className="px-5 py-3 border-t border-gray-200 bg-gray-100 text-sm">
                  {formatMoney(invoice.totalVatAmount)}
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  className="text-right px-5 py-3 border-t border-gray-200 bg-gray-100 text-sm font-semibold"
                >
                  {t('activerecord.attributes.invoice.total_amount_with_vat', {
                    ns: 'rails',
                  })}
                </td>
                <td className="px-5 py-3 border-t border-gray-200 bg-gray-100 text-sm">
                  {formatMoney(invoice.totalAmountWithVat)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      ) : (
        <p>No invoice lines available</p>
      )}
    </MainCard>
  );
};
