// api/PayslipFunnels::PayslipPreview.ts
import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type PrintableLine from '../../../modules/payslip/types/PrintableLine';
import type { PrintableAlertLine } from '../../../modules/payslip/components/DisplayWarning';

@Model()
class PayslipFunnelPayslipPreview extends ApplicationRecord {
  static jsonapiType = 'payslip_funnels-payslip_previews';

  // Attributes
  @Attr({ persist: false }) expenses!: number;
  @Attr({ persist: false }) advance!: number;
  @Attr({ persist: false }) netSalary!: number;
  @Attr({ persist: false }) netSalaryReal!: number;
  @Attr({ persist: false }) leftToPay!: number;
  @Attr({ persist: false }) employerContributions!: number;
  @Attr({ persist: false }) employerContributionsReal!: number;
  @Attr({ persist: false }) employeeContributions!: number;
  @Attr({ persist: false }) employeeContributionsReal!: number;
  @Attr({ persist: false }) workingHours!: number;
  @Attr({ persist: false }) previousBalance: number;
  @Attr({ persist: false }) payslipCost!: number;
  @Attr({ persist: false }) incomingBalance!: number;
  @Attr({ persist: false }) craId!: number;
  @Attr({ persist: false }) activityFees!: number;
  @Attr({ persist: false }) activityFeesList!: Array<PrintableLine>;
  @Attr({ persist: false }) provisionAdjustments!: number;
  @Attr({ persist: false }) provisionAdjustmentsList!: Array<PrintableLine>;
  @Attr({ persist: false }) contributions!: number;
  @Attr({ persist: false }) contributionsListSum!: number;
  @Attr({ persist: false }) contributionsList!: Array<PrintableLine>;
  @Attr({ persist: false }) grossSalary!: number;
  @Attr({ persist: false }) grossSalaryList!: Array<PrintableLine>;
  @Attr({ persist: false }) leftToPayRealBeforeTax!: number;
  @Attr({ persist: false }) leftToPayRealBeforeTaxList!: Array<PrintableLine>;
  @Attr({ persist: false }) leftToPayReal!: number;
  @Attr({ persist: false }) leftToPayRealList!: Array<PrintableLine>;
  @Attr({ persist: false }) paidHolidaysList!: Array<PrintableLine>;
  @Attr({ persist: false }) payslipAlertsList!: Array<PrintableAlertLine>;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) expectedPaymentDate!: string;
  @Attr({ persist: false }) bonus!: number;
  @Attr({ persist: false }) financialReserve!: number;

  // Relationships
}

export default PayslipFunnelPayslipPreview;
