import { useQuery } from '@tanstack/react-query';
import type { BreakdownLine } from '@/app/spraypaint';
import FactorClientPaymentTransaction from '@/app/spraypaint/factor/FactorClientPaymentTransaction';
import { GraphitiScoper } from '@spraypaint/types';

export const useGetBankReconciliationInformationScope = (
  id: string,
  {
    scope,
    args,
  }: {
    scope: GraphitiScoper<FactorClientPaymentTransaction>;
    args: Array<string>;
  },
) => {
  const data = useQuery({
    queryKey: ['factor-client_payment_transactions_invoices', id, ...args],
    queryFn: () =>
      scope(FactorClientPaymentTransaction.scope())
        .includes(['client_payment.breakdown_lines.invoice.client'])
        .find(id),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
  const breakDownLines: BreakdownLine[] | undefined =
    data?.data?.data?.clientPayment?.breakdownLines;
  const totalCount = data?.data?.meta.stats.total.count;

  return { breakDownLines, totalCount, ...data };
};
