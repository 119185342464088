import { slide as MobileMenu } from 'react-burger-menu';
import classNames from 'classnames';
import useBreakpoints from '../../app/responsive/useBreakPoints';
import { MenuContainerProps } from './types/MenuType';

const styles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '0px',
    height: '0px',
    left: '36px',
    top: '36px',
  },
};

export const MenuContainer = ({
  children,
  isMenuRetracted,
  isMobileMenuOpen,
  handleOnMobileMenuClick,
}: MenuContainerProps) => {
  const { isXs } = useBreakpoints();

  const customWidth = classNames(
    'flex flex-col h-full fixed bg-color-pone-dark pt-[33px] z-50 overflow-auto ',
    {
      'w-[240px]': !isMenuRetracted,
    },
  );

  if (!isXs) return <div className={customWidth}>{children}</div>;

  return (
    <MobileMenu
      styles={styles}
      className="bg-color-pone-dark"
      isOpen={isMobileMenuOpen}
      onClose={handleOnMobileMenuClick}
    >
      {children}
    </MobileMenu>
  );
};
