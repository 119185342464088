/* eslint-disable react/button-has-type */
import classNames from 'classnames';
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
  LegacyRef,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { Link } from 'react-router-dom';

export type ButtonOnClickHandler = MouseEventHandler<
  HTMLButtonElement | HTMLAnchorElement
>;

// to: for Link redirection
// href: for v1 redirection
// onClick: for onClick button
export type OptionalProps =
  | ({
      to?: never;
      href?: never;
      download?: never;
    } & (BtnTypeProps['type'] extends 'submit'
      ? { onClick?: never }
      : { onClick?: ButtonOnClickHandler }))
  | {
      to: string;
      href?: never;
      onClick?: never;
      download?: never;
    }
  | {
      to?: never;
      href: string;
      onClick?: () => void;
      download?: AnchorHTMLAttributes<HTMLAnchorElement>['download'];
    };

export type BtnTypeProps = {
  type?: 'submit' | 'button';
};

export type CommonButtonProps = {
  children?: ReactNode | undefined;
  className?: string;
  testID?: string;
  disabled?: boolean;
  isLoading?: boolean;
  custom?: boolean;
  openInNewTab?: boolean;
} & OptionalProps;

export type ButtonProps = CommonButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLElement, ButtonProps>(
  (
    {
      children,
      className,
      custom,
      disabled,
      onClick,
      to,
      href,
      openInNewTab = false,
      type = 'button',
      isLoading,
      testID = 'button',
      download,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const customClassName = classNames(
      {
        'rounded-[5px] pr-5 pl-5 pt-2 pb-2 inline-block': !custom,
      },
      className,
    );

    const aTagCustomClass = classNames(customClassName, {
      'cursor-default pointer-events-auto': isLoading || disabled,
      'border-2 border-color-disabled bg-color-disabled': disabled,
    });

    if (to && !disabled) {
      return (
        <Link
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          className={customClassName}
          to={to}
          data-testid={testID}
        >
          {children}
        </Link>
      );
    }

    if (href && !disabled) {
      return (
        <a
          onClick={onClick}
          ref={ref as LegacyRef<HTMLAnchorElement>}
          className={href ? aTagCustomClass : customClassName}
          href={href}
          target={openInNewTab ? '_blank' : undefined}
          data-testid={testID}
          rel="noreferrer"
          download={download}
        >
          {children}
        </a>
      );
    }

    if (type === 'submit') {
      return (
        <button
          ref={ref as LegacyRef<HTMLButtonElement>}
          data-testid={testID}
          type={type}
          disabled={isLoading || disabled}
          className={customClassName}
          {...props}
        >
          {children}
        </button>
      );
    }

    return (
      <button
        ref={ref as LegacyRef<HTMLButtonElement>}
        data-testid={testID}
        type={type}
        onClick={isLoading || disabled ? undefined : onClick}
        disabled={isLoading || disabled}
        className={customClassName}
        {...props}
      >
        {children}
      </button>
    );
  },
);

Button.defaultProps = {
  disabled: false,
  className: '',
  custom: false,
};
