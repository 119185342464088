import classNames from 'classnames';
import { LoaderIcon } from '../../assets/icons/inputs';

type Props = {
  className?: string;
};

export const LoaderPage = ({ className }: Props) => {
  const customClassName = classNames(
    'w-full h-screen flex items-center justify-center',
    className,
  );
  return (
    <div className={customClassName}>
      <LoaderIcon
        data-testid="loader-icon"
        className="w-[77px] h-[80px] animate-spin fill-color-pone-orange"
      />
    </div>
  );
};
