import { useQuery } from '@tanstack/react-query';
import PayslipFunnelExpensesStep from '../../../../app/spraypaint/payslipFunnel/PayslipFunnelExpensesStep';

const useExpensesStep = (expensesStepId: string | undefined) =>
  useQuery({
    queryKey: ['expenses-step', expensesStepId],
    enabled: !!expensesStepId,
    queryFn: () =>
      PayslipFunnelExpensesStep.selectExtra({
        'payslip_funnel_steps-expenses_steps': [
          'expenses_tables',
          'messages_i18n_keys',
        ],
        payslip_funnels: ['date_range_last_date'],
      })
        .select({ payslip_funnels: ['stc'] })
        .includes('payslip_funnel')
        .find(expensesStepId as string), // it can't be undefined because of the enabled prop
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
export default useExpensesStep;
