import type { FunctionComponent } from 'react';

export interface ToDoProps {
  name?: string;
}

export const ToDo: FunctionComponent<ToDoProps> = ({ name }) => (
  <div className="bg-color-light-orange p-5">
    This is a placeholder for {name ? <b>{name}</b> : 'a component'} that is not
    yet implemented.
  </div>
);
