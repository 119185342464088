import { CellContext, createColumnHelper } from '@tanstack/react-table';
import type { ColumnDefs } from '@/modules/common/components/table/Table';
import { ExpandToggleIcon } from '@/modules/common/components/table/components/ExpandToggleIcon';
import { ButtonOnClickHandler } from '@/modules/common/components/buttons';

function ExpendingCell<TData, TValue>({ row }: CellContext<TData, TValue>) {
  if (!row.getCanExpand()) return null;

  const handleClick: ButtonOnClickHandler = (event) => {
    event.stopPropagation();
    row.toggleExpanded();
  };

  return (
    <button onClick={handleClick} type="button" className="pl-2">
      <ExpandToggleIcon row={row} />
    </button>
  );
}

export function prependExpendingColumn<TData>(
  initialColumns: ColumnDefs<TData>,
  enableExpanding: boolean,
): ColumnDefs<TData> {
  if (!enableExpanding) return initialColumns;

  const columnHelper = createColumnHelper<TData>();
  const expendingColumn = columnHelper.display({
    id: 'expendingColumn',
    cell: ExpendingCell,
    size: 10,
    meta: {
      fixedWidth: true,
      noBorder: true,
    },
  });

  return [expendingColumn, ...initialColumns];
}
