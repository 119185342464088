import classNames from 'classnames';
import { Pastille } from '../../fragments';

type Props = {
  required?: boolean;
  children: string;
  htmlFor?: string;
  className?: string;
};

export const Label = ({ required, children, htmlFor, className }: Props) => {
  const containerClassName = classNames('flex items-center mb-4', className);

  return (
    <div className={containerClassName}>
      <label htmlFor={htmlFor} className="text-base font-bold">
        {children}
      </label>
      {required && <Pastille className="ml-[4px]" />}
    </div>
  );
};
