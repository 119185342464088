import { Invoice } from '@/app/spraypaint';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { TypedLoaderFunction } from '@/types';
import { RecordProxy } from 'spraypaint/lib-esm/proxies';

export function invoiceQuery(id: string) {
  return {
    queryKey: ['invoice', String(id)],
    queryFn: async () =>
      Invoice.includes([
        'debtor',
        'bank_account',
        'invoice_lines',
        'transitions',
        { commercial_contract: 'client' },
      ])
        .find(id)
        .then((invoice) => {
          console.log({ invoice: invoice.data });
          return invoice;
        }),
    refetchOnMount: false,
  };
}

export function loader(
  queryClient: QueryClient,
): TypedLoaderFunction<RecordProxy<Invoice>> {
  return async ({ params }) => {
    const { id } = params;

    // if id is undefined, or is not a number-string
    if (!id || Number.isNaN(Number(id)))
      // Allow Response to be thrown here because this is an expected
      // behavior from React Router
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new Response('Not found', { status: 404, statusText: 'Not Found' });

    const query = invoiceQuery(id as string);

    return queryClient.ensureQueryData(query);
  };
}

export function useInvoice(id: string) {
  return useQuery(invoiceQuery(id));
}
