import { ModelRecord } from 'spraypaint/lib-esm/model';
import { RegisteredClientAgent } from '@/app/spraypaint';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  StrictEqualFilter,
  getStrictEqualFilter,
} from '@/modules/common/components/table/filter/StrictEqualFilter';

const columnHelper = createColumnHelper<ModelRecord<RegisteredClientAgent>>();

const registeredClientAgentsColumns = () => {
  const { t } = useTranslation();

  return [
    columnHelper.accessor('user.id', {
      header: 'ID',
      filterFn: getStrictEqualFilter(),
      meta: {
        filter: {
          Component: StrictEqualFilter,
        },
      },
    }),
    columnHelper.accessor((original) => original.user?.fullName, {
      header: t('activerecord.attributes.user.full_name') as string,
    }),
    columnHelper.accessor((original) => original.user?.email, {
      header: t('activerecord.attributes.user.email') as string,
    }),
  ];
};

export default registeredClientAgentsColumns;
