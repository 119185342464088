import { SepaImport } from '@/app/spraypaint';
import { getDateFromPeriod } from '@/app/utils/dateUtils';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import Status from '@/modules/common/components/statuses/Status';

export const SepaImportCard = ({ candidate }: { candidate: SepaImport }) => (
  <>
    <div className="flex flex-col">
      <p>
        <span className="font-cabin-bold">Période : </span>
        {getDateFromPeriod({
          month: candidate.payslipPeriod.month,
          year: candidate.payslipPeriod.year,
        })}
      </p>
      <p>
        <span className="font-cabin-bold">Référence : </span>
        {candidate.reference}
      </p>
    </div>
    <div className="flex flex-col">
      <p>
        <span className="font-cabin-bold">Total : </span>
        <AmountWithCurrency amount={candidate.totalAmount} />
      </p>
      <p>
        <span className="font-cabin-bold">Nombre de salaires : </span>
        {candidate.totalEntries}
      </p>
      <p className="flex justify-center">
        <span className="font-cabin-bold">Statut : </span>
        <Status statusType={candidate.status} className="ml-2" />
      </p>
    </div>
  </>
);
