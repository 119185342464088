import FactorAuthorizationFile from '@/app/spraypaint/factor/FactorAuthorizationFile';

export const createAuthorizationFile = async (payload: {
  fileSignedId: string | undefined;
}): Promise<FactorAuthorizationFile> => {
  const file = new FactorAuthorizationFile(payload);
  try {
    await file.save();
  } catch {
    throw new Error('Factor authorization file creation error');
  }
  return file;
};
