import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckBox, TextInput } from '@/modules/common/components/inputs';
import { useTranslation } from 'react-i18next';
import { AuthFooter, AuthHeader } from '@/modules/authentification/components';
import { AuthMainContainer } from '@/modules/authentification/components/cards';
import { MainCard } from '@/modules/common/components/mainCard';
import { Label } from '@/modules/common/components/inputs/components';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { toast } from 'react-toastify';

type DeleteDataFormValue = {
  fullname: string;
  email: string;
  reason_withdraw_consent: boolean;
  reason_objection_processing: boolean;
  reason_illegal_processing: boolean;
  reason_legal_obligation: boolean;
  reason_other: string;
  data_to_delete: string;
  agreement: boolean;
};

export type GdprDeleteDataResponse = {
  message: string;
};

export type GdprDeleteDataErrors = {
  errors: Array<string>;
};

const useGdprDeleteData = (
  locale: string,
  opts: UseMutationOptions<
    AxiosResponse<GdprDeleteDataResponse>,
    AxiosError<GdprDeleteDataErrors>,
    DeleteDataFormValue
  > = {},
) =>
  useMutation({
    ...opts,
    mutationKey: ['gdpr_delete_data', locale],
    mutationFn: (data) => {
      const gdprClient = axios.create({
        baseURL: '/api/public',
        withCredentials: true,
        headers: {
          'Accept-Language': locale,
        },
      });

      return gdprClient.post<GdprDeleteDataResponse>(
        'gdpr_deletion_requests',
        data,
      );
    },
  });

export const SuccessMessage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-green-100 text-green-600 p-4 rounded">
      <p>{t('front.gdpr.delete_data.success')}</p>
    </div>
  );
};

export const DeleteAccountData: FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const { register, handleSubmit } = useForm<DeleteDataFormValue>();

  const [errors, setErrors] = useState<Array<string>>([]);
  const [success, setSuccess] = useState<boolean>(false);

  const { mutate } = useGdprDeleteData(i18n.language, {
    onSuccess: () => {
      setSuccess(true);
    },
    onError: (error) => {
      if (error.response?.status === 422) {
        setErrors(error.response.data.errors);
      } else {
        toast.error(t('front.gdpr.delete_data.error'));
      }
    },
  });

  const content = success ? (
    <SuccessMessage />
  ) : (
    <MainCard
      header={t('front.gdpr.delete_data.header')}
      className="max-w-screen-xl"
    >
      <p className="mb-4">{t('front.gdpr.delete_data.aknowledgment')}</p>
      <p className="mb-4">{t('front.gdpr.delete_data.delay')}</p>
      <ul className="list-disc pl-6">
        <li>{t('front.gdpr.delete_data.delay.item1')}</li>
        <li>{t('front.gdpr.delete_data.delay.item2')}</li>
      </ul>
      <form
        className="space-y-4"
        onSubmit={handleSubmit((data) => mutate(data))}
      >
        <h2 className="text-lg font-semibold !mt-10">
          {t('front.gdpr.delete_data.authentication.title')}
        </h2>
        <div className="p-4 text-sm text-gray-600 bg-gray-50 rounded">
          <p className="mb-4">{t('front.gdpr.delete_data.data_usage')}</p>
          <p>{t('front.gdpr.delete_data.authentication.subtitle')}</p>
        </div>
        <TextInput
          required
          {...register('fullname')}
          label={t('front.gdpr.delete_data.form.fullname.label')}
          placeholder={t('front.gdpr.delete_data.form.fullname.placeholder')}
          className="mt-6"
        />{' '}
        <TextInput
          required
          {...register('email')}
          label={t('front.gdpr.delete_data.form.email.label')}
          placeholder={t('front.gdpr.delete_data.form.email.placeholder')}
          className="mt-6"
          type="email"
        />
        <h2 className="text-lg font-semibold !mt-10">
          {t('front.gdpr.delete_data.subject.title')}
        </h2>
        <div>
          <Label required>
            {t('front.gdpr.delete_data.form.reason.label')}
          </Label>
          <div className="p-4 text-sm text-gray-600 bg-gray-50 rounded mb-4">
            <p>{t('front.gdpr.delete_data.subject.subtitle')}</p>
          </div>
          <CheckBox
            {...register('reason_withdraw_consent')}
            label={t('front.gdpr.delete_data.form.reason.withdraw_consent')}
          />{' '}
          <CheckBox
            {...register('reason_objection_processing')}
            label={t('front.gdpr.delete_data.form.reason.objection_processing')}
          />{' '}
          <CheckBox
            {...register('reason_illegal_processing')}
            label={t('front.gdpr.delete_data.form.reason.illegal_processing')}
          />{' '}
          <CheckBox
            {...register('reason_legal_obligation')}
            label={t('front.gdpr.delete_data.form.reason.legal_obligation')}
          />
          <div className="flex flex-col ml-6">
            <p className="text-sm2  mt-2">
              {t('front.gdpr.delete_data.form.reason.other')}
            </p>
            <TextInput
              {...register('reason_other')}
              placeholder={t('front.gdpr.delete_data.form.reason.other')}
              textarea
            />
          </div>
        </div>
        <div>
          <Label required>
            {t('front.gdpr.delete_data.form.data_to_delete.label')}
          </Label>
          <p>{t('front.gdpr.delete_data.form.data_to_delete.description')}</p>
          <TextInput
            {...register('data_to_delete')}
            placeholder={t('front.gdpr.delete_data.form.data_to_delete.label')}
            textarea
            required
          />
          <div className="p-4 text-sm text-gray-600 bg-gray-50 rounded mb-4">
            <p>{t('front.gdpr.delete_data.form.data_to_delete.limitations')}</p>
            <p className="mt-4">
              {t(
                'front.gdpr.delete_data.form.data_to_delete.limitations.details',
              )}
            </p>
          </div>
        </div>
        <h2 className="text-lg font-semibold !mt-10">
          {t('front.gdpr.delete_data.declaration.title')}
        </h2>
        <div>
          <p className="mb-6">
            {t('front.gdpr.delete_data.declaration.subtitle')}
          </p>
          <CheckBox
            {...register('agreement')}
            label={t('front.gdpr.delete_data.form.agree.label')}
            required
          />
        </div>
        {errors.length > 0 && (
          <div className="bg-red-100 text-red-600 p-4 rounded">
            <p className="mb-4">
              {t('front.gdpr.delete_data.form.errors_notice')}
            </p>
            <ul className="list-disc pl-6">
              {errors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex justify-end">
          <ButtonPrimary type="submit">
            {t('front.gdpr.delete_data.form.submit.title')}
          </ButtonPrimary>
        </div>
      </form>
    </MainCard>
  );

  return (
    <div className="h-screen w-full flex">
      <div className="flex flex-col flex-1 h-screen justify-between">
        <AuthHeader /> <AuthMainContainer>{content}</AuthMainContainer>{' '}
        <AuthFooter
          phone="+33 (0)1 47 03 15 90"
          email="contact@prium-portage.com"
        />
      </div>
    </div>
  );
};
