import classNames from 'classnames';
import { toast } from 'react-toastify';
import { MouseEventHandler } from 'react';

type ToastType = 'success' | 'error' | 'warn';
type ActionType = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  actionText: string;
};

type ToasterProps = {
  action?: ActionType;
  textMsg: string;
  toastType: ToastType;
};

export const Toaster = ({ action, textMsg, toastType }: ToasterProps) => {
  const textClassName = classNames('uppercase text-base ml-[17px]', {
    'text-color-txt-failure': toastType === 'error',
    'text-color-txt-success': toastType === 'success',
    'text-color-toast-success-and-warning': toastType !== 'error',
  });

  return (
    <div className="flex text-sm1 pl-[30px] pr-[10px]">
      <p className="text-color-pone-dark mt-[1px]">{textMsg}</p>
      {action && (
        <button
          type="button"
          onClick={action.onClick}
          className={textClassName}
        >
          <p className="text-sm2 font-cabin-bold">{action.actionText}</p>
        </button>
      )}
    </div>
  );
};

type HandleToastParams = {
  toastType: ToastType;
  action?: ActionType;
  textMsg: string;
};

export const handleToast =
  ({ toastType, action, textMsg }: HandleToastParams) =>
  () =>
    toast[toastType](
      <Toaster action={action} textMsg={textMsg} toastType={toastType} />,
    );
