import { useQuery } from '@tanstack/react-query';
import { Payslip } from '../../../app/spraypaint';

export const useCurrentPayslip = (employeeId: number) =>
  useQuery({
    queryKey: ['currentPayslip', Number(employeeId)],
    queryFn: () =>
      Promise.resolve(
        Payslip.where({ employeeId })
          .where({ status: ['in_progress', 'submitted', 'validated'] })
          .select(['period', 'created_at', 'status', 'payment_date'])
          .selectExtra(['document_availability_date', 'expected_payment_date'])
          .first(),
      ),
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: !!employeeId,
  });
