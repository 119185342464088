// api/EmployeePayslip.ts
import { Model, Attr } from 'spraypaint';
import type {
  MoneyType,
  PeriodType,
  PayslipStatusEnum,
} from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';

@Model()
class EmployeePayslip extends ApplicationRecord {
  static jsonapiType = 'employee_payslips';

  // Attributes
  @Attr({ persist: false }) employeeId!: number;
  @Attr({ persist: false }) payslipId!: number;
  @Attr({ persist: false }) craId!: number;
  @Attr({ persist: false }) employmentContractId!: number;
  @Attr({ persist: false }) period!: PeriodType;
  @Attr({ persist: false }) missing!: boolean;
  @Attr({ persist: false }) error!: string;
  @Attr({ persist: false }) followUp!: string;
  @Attr({ persist: false }) fullName!: string;
  @Attr({ persist: false }) legalEntityId!: number;
  @Attr({ persist: false }) status!: PayslipStatusEnum;
  @Attr({ persist: false }) urgentTransfer!: boolean;
  @Attr({ persist: false }) createdBy!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) autocreated!: boolean;
  @Attr({ persist: false }) submittedAt!: string;
  @Attr({ persist: false }) submittedBy!: string;
  @Attr({ persist: false }) autosubmitted!: boolean;
  @Attr({ persist: false }) validatedAt!: string;
  @Attr({ persist: false }) validatedBy!: string;
  @Attr({ persist: false }) autovalidated!: boolean;
  @Attr({ persist: false }) paymentDate!: string;
  @Attr({ persist: false }) closedAt!: string;
  @Attr({ persist: false }) arbitration!: boolean;
  @Attr({ persist: false }) remainingAmount!: MoneyType;
  @Attr({ persist: false }) stc!: boolean;
  @Attr({ persist: false }) new!: boolean;

  // Extra-attributes

  // Relationships

  get employeePayslipShowPagePath(): string | undefined {
    const { employeeId, payslipId } = this;
    if (!employeeId || !payslipId) return undefined;

    return `/v2/employees/${employeeId}/payslips/${payslipId}`;
  }
}

export default EmployeePayslip;
