export const salaryStrategyToText = {
  'PayslipFunnelSteps::SalaryStepStrategies::MinimumSalary':
    'front.payslip.details.salaryChoice.minSalary',
  'PayslipFunnelSteps::SalaryStepStrategies::MaximumSalary':
    'front.payslip.details.salaryChoice.maxSalary',
  'PayslipFunnelSteps::SalaryStepStrategies::CustomSalary':
    'front.payslip.details.salaryChoice.customStrategy',
  'PayslipFunnelSteps::SalaryStepStrategies::ForceSalary':
    'front.payslip.details.salaryChoice.forcedSalary',
  'PayslipFunnelSteps::SalaryStepStrategies::StcSalary':
    'front.payslip.details.salaryChoice.stcSalary',
  'PayslipFunnelSteps::SalaryStepStrategies::CustomStcSalary':
    'front.payslip.details.salaryChoice.stcSalaryCustom',
};
