import type { FunctionComponent } from 'react';
import { ButtonPrimary } from '@common/components/buttons';
import { useTranslation } from 'react-i18next';
import { FilledEmailForm } from '@/modules/authentification/components/FilledEmailForm';

export interface UnknownEmailFormProps {
  email: string;
  navigateToEmailForm: () => void;
  navigateToRegisterForm: (email: string) => void;
}

export const UnknownEmailForm: FunctionComponent<UnknownEmailFormProps> = ({
  email,
  navigateToEmailForm,
  navigateToRegisterForm,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center md:items-start">
      <FilledEmailForm email={email} onGoBack={navigateToEmailForm} />
      <p className="mt-[33px]">{t('front.auth.signIn.register.explanation')}</p>
      <div className="flex items-center justify-center md:justify-start mt-[33px] gap-3">
        <ButtonPrimary
          testID="login-button-register"
          onClick={() => navigateToRegisterForm(email)}
        >
          {t('front.auth.signIn.button.register.title')}
        </ButtonPrimary>
      </div>
    </div>
  );
};
