import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import mixpanel from 'mixpanel-browser';
import OneSignal from 'react-onesignal';
import { CLIENT_VERSION, PRIUM_CLIENT } from '@/app/mixpanel/mixpanel';
import useAuthClient, { AuthErrorResponse } from './useAuthClient';

export type LogoutData = {
  success: string;
};

export const useLogout = (
  opts: UseMutationOptions<
    AxiosResponse<LogoutData>,
    AxiosError<AuthErrorResponse>
  > = {},
) => {
  const authClient = useAuthClient();

  return useMutation({
    ...opts,
    mutationFn: () =>
      authClient.post<LogoutData>('/logout', {}, { withCredentials: true }),

    onSuccess: async (...args) => {
      mixpanel.track('Sign Out');
      mixpanel.reset();
      mixpanel.register({
        prium_client: PRIUM_CLIENT,
        prium_client_version: CLIENT_VERSION,
      });
      OneSignal.logout();

      if (opts.onSuccess) {
        await opts.onSuccess(...args);
      }

      window.location.href = '/v2/auth/login';
    },
  });
};
