import { useQuery } from '@tanstack/react-query';
import PayslipFunnelsActivityAccountLine from '../../../../app/spraypaint/payslipFunnel/PayslipFunnelActivityAccountLine';
import { GraphitiScoper } from '../../../../app/spraypaint/types';

export const useGetActivityAccountLine = (
  payslipFunnelId: number,
  scope: GraphitiScoper<PayslipFunnelsActivityAccountLine>,
  scopeKey: string[],
  classification?: string,
) => {
  const { data, isLoading } = useQuery({
    queryKey: [
      'activityAccountLine',
      scopeKey,
      classification,
      payslipFunnelId,
    ],
    cacheTime: 0,
    retry: 0,
    enabled: !!classification,
    queryFn: () =>
      scope(
        PayslipFunnelsActivityAccountLine.select([
          'date',
          'label',
          'value',
        ]).scope(),
      )
        .where({
          classification,
          payslip_funnel_id: payslipFunnelId,
        })
        .all(),
  });
  return { data, isLoading };
};
