/*
  returns a function that will navigate to the given path using the correct method :
  - useNavigate if the path starts with /v2
  - window.location.href otherwise

  Also handles relative paths by

*/
import { useNavigate } from 'react-router-dom';

export function useOmniNavigate(): (path: string) => void {
  const navigate = useNavigate();

  return (path: string) => {
    if (path.startsWith('/')) {
      if (path.startsWith('/v2')) {
        navigate(path);
      } else {
        window.location.href = path;
      }
    } else {
      navigate(path);
    }
  };
}
