// api/Transition.ts
import { Model, Attr } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';

export const DISPLAY_VALUES = ['primary', 'secondary', 'danger'] as const;
export type DisplayValue = typeof DISPLAY_VALUES[number];

@Model()
class Transition extends ApplicationRecord {
  static jsonapiType = 'transitions';

  // Attributes
  @Attr() resourceId!: number;
  @Attr() resourceType!: string;
  @Attr() transitionName!: string;
  @Attr({ persist: false }) display!: DisplayValue;
  @Attr({ persist: false }) from!: string;
  @Attr({ persist: false }) to!: string;
  @Attr() transitionParams!: any;

  // Relationships
  // Polymorphic relation resource : TBD

  triggerTransition({
    params,
  }: {
    params?: Transition['transitionParams'];
  } = {}): Promise<Transition> {
    const newTransition = new Transition({
      id: null,
      resourceType: this.resourceType,
      resourceId: this.resourceId,
      transitionName: this.transitionName,
      transitionParams: params,
    });

    newTransition.isPersisted = false;

    return newTransition.saveOrThrow();
  }
}

export default Transition;
