export const CRA_TYPE = {
  working_days: {
    bg_light: 'bg-color-transparent-green',
    bg: 'bg-color-green',
    type: 'working_days',
  },
  paid_holidays: {
    bg_light: 'bg-color-transparent-cyan',
    bg: 'bg-color-cyan',
    type: 'paid_holidays',
  },
  blank: 'blank',
  telework: {
    bg: 'bg-color-pink',
    bg_light: 'bg-color-transparent-pink',
    type: 'telework',
  },
  other: {
    bg: 'bg-color-cra-grey',
    bg_light: 'bg-color-transparent-medium-grey',
    type: 'other',
  },
};
