/* eslint-disable import/no-cycle */
// api/Employee.ts
import { Attr, Model } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';

@Model()
class PortageOffer extends ApplicationRecord {
  static jsonapiType = 'portage_offers';

  // Attributes
  @Attr() name!: string;
  @Attr() standardRate!: number;
  @Attr() professionalFeesRate!: number;
  @Attr() subcontractorsRate!: number;
  @Attr() mediumNotationTotalCashAdvanceRate!: number;
  @Attr() totalCashAdvanceRateNotTransferDbt!: number;
  @Attr() sponsorshipBonus!: number;
  @Attr() managementFeesCeiling!: number;
}

export default PortageOffer;
