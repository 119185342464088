import { Trans } from 'react-i18next';
import { escape } from 'lodash';
import { TooltipComponent } from '../fragments/TooltipComponent';
import { HelpOutlineIcon as HelpOutlineIconInput } from '../../../../app/assets/icons/inputs';

export const TableHeaderLabelTooltiped = ({
  label,
  msg,
}: {
  label: string;
  msg: string;
}) => (
  <>
    <TooltipComponent
      Icon={HelpOutlineIconInput}
      tooltipMsgComp={
        <Trans
          i18nKey={msg}
          shouldUnescape
          values={{ characters: escape('<, >, &') }}
        />
      }
      tooltipId="amount-header-tooltip"
      iconClassName="mr-2 fill-color-medium-grey"
      iconHeight={15}
      iconWidth={15}
      tooltipPosition="top"
      tooltipTxtCustomClass="lowercase"
    />
    {label}
  </>
);
