import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

// Add a mixpanel watcher to a context value.
// This will register the value in mixpanel once and unregister it when the component is unmounted
export function useMixpanelContextWatcher(name: string, value: unknown) {
  useEffect(() => {
    mixpanel.register_once({ [name]: value });
    return () => {
      mixpanel.unregister(name);
    };
  }, [value]);
}
