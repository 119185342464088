import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  FilterDownIcon,
  FilterUpIcon,
} from '../../../app/assets/icons/buttons';
import { TextAuditLine } from '../../common/components/fragments';

export type PayslipDetailCardProps = {
  title: string;
  displayAll?: boolean;
  children: ReactNode;
  allDetail?: ReactNode;
  user: string;
  date: string;
};

export const PayslipDetailCard = ({
  title,
  displayAll,
  children,
  allDetail,
  user,
  date,
}: PayslipDetailCardProps) => {
  const [display, setDisplay] = useState<boolean>(false);
  useEffect(() => {
    if (displayAll !== undefined && displayAll !== display) {
      setDisplay(displayAll);
    }
  }, [displayAll]);

  const titleColorClass = classNames('text-color-medium-grey text-xl1', {
    'text-color-pone-dark': display,
    'text-color-medium-grey': !display,
    'ml-[22px]': allDetail,
    'ml-[91px]': !allDetail,
  });

  return (
    <div className="border-b-[1px] border-color-pone-orange pb-[27px] pt-[44px]">
      <span className="flex items-center mb-[25px]">
        {allDetail ? (
          <span
            className="ml-[62px] flex items-center hover:cursor-pointer"
            onClick={() => setDisplay(!display)}
            role="none"
          >
            {display ? (
              <FilterDownIcon height={5} width={10} />
            ) : (
              <FilterUpIcon height={5} width={10} className="rotate-90" />
            )}
            <p className={titleColorClass}>{title}</p>
          </span>
        ) : (
          <p className={titleColorClass}>{title}</p>
        )}
      </span>
      <div className="ml-[94px]">{children}</div>
      {display ? allDetail : null}
      <TextAuditLine className="ml-[91px] my-4" date={date} user={user} />
    </div>
  );
};
