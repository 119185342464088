// api/ClientPayment.ts
import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';
import type Client from './Client';
import type SepaTransaction from './sepaTransactions/SepaTransaction';
import type BreakdownLine from './BreakdownLine';
import type FactorClientPaymentTransaction from './factor/FactorClientPaymentTransaction';
// import type Invoice from './Invoice';
// import type Credit from './Credit';

@Model()
class ClientPayment extends ApplicationRecord {
  static jsonapiType = 'client_payments';

  // Attributes
  @Attr() clientId!: number;
  @Attr() date!: string;
  @Attr() amount!: MoneyType;
  @Attr() exchangeValueEuroAmount!: MoneyType;
  @Attr() sourceableType!: string;
  @Attr() sourceableId!: string;
  @Attr() invoiceId!: number;
  @Attr() canceledId!: number;
  @Attr() sepaTransactionId!: number;
  @Attr() FactorClientPaymentTransactionId!: number;
  @Attr({ persist: false }) createdAt!: string;

  // Relationships
  @BelongsTo('clients') client!: Client;
  @BelongsTo('sepa-transactions') sepaTransaction!: SepaTransaction;
  @BelongsTo('factor-client_payment_transaction')
  factorClientPaymentTransaction!: FactorClientPaymentTransaction;
  @HasMany('breakdown_lines') breakdownLines!: Array<BreakdownLine>;
  // Polymorphic relation sourceable : TBD

  get createdAtDate(): Date | undefined {
    if (!this.createdAt) return undefined;
    return new Date(this.createdAt);
  }
}

export default ClientPayment;
