import { useQuery } from '@tanstack/react-query';
import Payslip from '../../../app/spraypaint/Payslip';

export const usePayslip = (id: string) => {
  // TODO no any
  const data = useQuery({
    queryKey: ['payslip-show', id],
    queryFn: () =>
      Payslip.includes(['activity_report.activity_report_slots'])
        .selectExtra([
          'fileName',
          'fileUrl',
          'previousEmployeePayslipId',
          'nextEmployeePayslipId',
        ])
        .find(id),
    retry: 0,
    refetchOnWindowFocus: false,
  });
  const payslip = data?.data?.data;
  const period = payslip?.period;
  const grossSalaryList = payslip ? payslip?.grossSalaryList : [];
  const leftToPayRealBeforeTaxList = payslip
    ? payslip?.leftToPayRealBeforeTaxList
    : [];
  const leftToPayRealBeforeTax = payslip?.leftToPayRealBeforeTax;
  const leftToPayRealList = payslip ? payslip?.leftToPayRealList : [];
  const fromPayslipFunnel = payslip ? payslip?.fromPayslipFunnel : false;
  const activityFeesList = payslip ? payslip?.activityFeesList : [];
  const payslipAlertsList = payslip ? payslip?.payslipAlertsList : [];
  const contributionsList = payslip ? payslip?.contributionsList : [];
  const provisionAdjustmentsList = payslip
    ? payslip?.provisionAdjustmentsList
    : [];
  const grossSalary = payslip ? payslip?.grossSalary : 0;
  const leftToPayReal = payslip ? payslip?.leftToPayReal : 0;
  const file = payslip
    ? { name: payslip?.fileName, url: payslip?.fileUrl }
    : { name: '', url: '' };

  const previousEmployeePayslipId = payslip?.previousEmployeePayslipId;
  const nextEmployeePayslipId = payslip?.nextEmployeePayslipId;

  return {
    payslip,
    period,
    grossSalary,
    grossSalaryList,
    leftToPayRealBeforeTaxList,
    leftToPayRealBeforeTax,
    leftToPayRealList,
    activityFeesList,
    provisionAdjustmentsList,
    contributionsList,
    leftToPayReal,
    fromPayslipFunnel,
    payslipAlertsList,
    file,
    previousEmployeePayslipId,
    nextEmployeePayslipId,
    activityReport: payslip?.activityReport,
    ...data,
  };
};
