import { ReportProblemIc } from '../../../app/assets/icons/others';

export type PrintableAlertLine = {
  slug: string;
  value: boolean;
  view_name: string;
};

export type DisplayWarningProps = {
  list: PrintableAlertLine[];
};

export const DisplayWarning = ({ list }: DisplayWarningProps) => {
  const activeAlerts = list.filter((alert: PrintableAlertLine) => alert.value);

  if (activeAlerts.length > 0) {
    return (
      <div className="bg-color-bg-warning flex items-center justify-center py-3">
        <ReportProblemIc className="fill-color-warning mr-5" />
        <div className="text-center text-color-warning text-base-2 flex align-middle">
          <div className="flex flex-col">
            {activeAlerts.map((alert: PrintableAlertLine) => (
              <div key={alert.slug}>{alert.view_name}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};
