/* eslint-disable no-unsafe-optional-chaining */
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, MouseEvent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CommentWidget } from '@/modules/common/components/comments/CommentWidget';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { onRowClickNavigate } from '@/app/navigation/functions';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useUserManagerRole } from '@/modules/authentification/hooks';
import { CandidateType } from '@/modules/admin/accounting/CandidateType';
import { AssociationCard } from '@/modules/common/components/matchingCandidatesCards/AssociationCard';
import { useBackendTable } from '../../../common/components/table/hooks';
import { useGetMatchingScore } from '../bankReconciliation/hooks/useGetMatchingScore';
import {
  useGetBankInformation,
  useGetBankReconciliationInformation,
  useGetInvoicesBankTransfert,
  useGetContribDeposits,
} from './hooks/useGetBankReconcilationInformation';
import { ManualReconciliationForm } from './components/ManualReconciliationForm';
import {
  ContribDeposit,
  Invoice,
  SepaMatchingCandidate,
  SepaTransaction,
} from '../../../../app/spraypaint';
import { PlusIcon } from '../../../../app/assets/icons/buttons';
import { MoveLeftRightIcon } from '../../../../app/assets/icons/payslipFunnel';
import {
  ButtonPrimary,
  ButtonSecondary,
  IconButton,
} from '../../../common/components/buttons';
import { Breadcrumbs } from '../../../navigation/components/Breadcrumbs';
import { LoaderPage } from '../../../../app/navigation/components/LoaderPage';
import { PayloadType, useValidateMatching } from './hooks/useValidateMatching';
import { Alert, Toaster } from '../../../common/components/toasts';
import { refreshTransactionMatching } from '../bankTransfer/hooks/useRefreshMatching';
import { BankTransfertInformationCard } from './components/BankTransfertInformationCard';
import Table from '../../../common/components/table/Table';
import { invoicesListColumns as invoicesListColumnsBuilder } from './columns/InvoicesListColumns';
import { contribDepositListColumns as contribListBuilder } from './columns/ContribDepositListColumns';
import { CompletedValidationModal } from './components/CompletedValidationModal';
import { useBankReconciliationTransition } from '../hooks/useBankReconciliationTransition';
import { MoneyType } from '../../../common/utils/type.utils';

export const BankAssociation = () => {
  const { t } = useTranslation();
  const isOnlyManager = useUserManagerRole();

  const navigate = useNavigate();
  const { id: idTransaction } = useParams();
  const [candidateList, setCandidateList] = useState<Array<CandidateType>>([]);

  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState<Array<string>>([]);
  const [sepaMatchingScoreList, setSepaMatchingScoreList] = useState<
    Array<CandidateType>
  >([]);
  const {
    bankReconciliationInformation,
    entityName,
    legalEntityId,
    isLoading: bankReconciliationInformationLoader,
  } = useGetBankReconciliationInformation(idTransaction as string);

  const { bankAccount } = useGetBankInformation(legalEntityId);
  const handleOrphaningSuccess = () => {
    toast.success(
      <Toaster
        textMsg="Rapprochement bancaire abandonné"
        toastType="success"
      />,
    );
    navigate(`/v2/admin/bank_reconciliation/${idTransaction}`);
  };

  // Tables
  const [invoiceScope, invoiceScopeKey, invoiceTransactionProps] =
    useBackendTable<Invoice>({ initialPageSize: 5 });
  const [contribScope, contribScopeKey, contribProps] =
    useBackendTable<ContribDeposit>({ initialPageSize: 5 });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: mutateOrphaned, isLoading: isOrphanActionLoading } =
    useBankReconciliationTransition(handleOrphaningSuccess);

  const {
    invoices,
    totalCount,
    isLoading: fetchInvoicesLoader,
  } = useGetInvoicesBankTransfert(legalEntityId, {
    scope: invoiceScope,
    scopeKey: invoiceScopeKey,
  });

  const {
    contribDeposits,
    contribDepositTotalCount,
    isLoading: fetchContribDepositsLoader,
  } = useGetContribDeposits(legalEntityId, {
    scope: contribScope,
    scopeKey: contribScopeKey,
  });

  const { mutate, isLoading, data } = useValidateMatching();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (data === true) {
      toast.success(
        <Toaster
          textMsg="Rapprochement bancaire correctement effectué"
          toastType="success"
        />,
      );
      navigate(`/v2/admin/bank_reconciliation/${idTransaction}`);
    } else if (typeof data === 'object') {
      const errorsBack: string[] = Object.values(data).reduce<string[]>(
        (acc, error) => {
          const message = error?.fullMessage;
          return message ? [...acc, message] : acc;
        },
        [],
      );
      setErrors(errorsBack);
    }
  }, [data]);

  const handleValidateMatching = () => {
    if (
      sepaMatchingScoreList.every(
        (item) =>
          item.associableType === sepaMatchingScoreList[0].associableType,
      )
    ) {
      const payload: PayloadType = {
        associableIds: sepaMatchingScoreList.map((imatch) => Number(imatch.id)),
        associableType: sepaMatchingScoreList[0].associableType,
        sepaTransactionId: Number(idTransaction),
      };
      mutate(payload);
    } else {
      toast.error(
        <Toaster
          textMsg={t('front.bankAssociation.errors.differentType')}
          toastType="error"
        />,
      );
    }
  };

  const [scope, scopeKey] = useBackendTable<SepaMatchingCandidate>({
    initialPageSize: 40,
  });
  const { candidates: bestMatchingCandidates } = useGetMatchingScore({
    scope,
    args: { scopeKey, idTransaction },
    idTransaction,
    onSuccess: setCandidateList,
  });

  const handleAddCandidate =
    (
      candidateToMove: CandidateType,
      sepaTransaction: SepaTransaction | undefined,
    ) =>
    () => {
      // All added items should have the same currency
      if (candidateToMove.currencyIsoCode !== sepaTransaction?.amount?.currency)
        return toast.error(
          <Toaster
            textMsg={t('front.bankAssociation.errors.differentCurrency')}
            toastType="error"
          />,
        );

      // Do not add the same item many times with manual form
      if (
        sepaMatchingScoreList.filter(
          (sepaMatchingScore) => sepaMatchingScore.id === candidateToMove.id,
        ).length > 0
      )
        return sepaMatchingScoreList;

      const newArray = candidateList.filter(
        (candidate) => candidateToMove.id !== candidate.id,
      );
      setCandidateList(newArray);
      return setSepaMatchingScoreList([
        candidateToMove,
        ...sepaMatchingScoreList,
      ]);
    };

  const handleRemoveCandidate = (candidate: CandidateType) => () => {
    const newSelectedCandidateList = sepaMatchingScoreList.filter(
      (sepaMatchingScore) => candidate.id !== sepaMatchingScore.id,
    );
    const existOnBestCandidateList = !!bestMatchingCandidates.find(
      (bestCandidate: CandidateType) => bestCandidate.id === candidate.id,
    );
    if (existOnBestCandidateList) {
      setCandidateList([candidate, ...candidateList]);
    }
    setSepaMatchingScoreList(newSelectedCandidateList);
  };

  const invoicesListColumns = invoicesListColumnsBuilder({
    handleAddCandidate,
    handleRemoveCandidate,
    sepaMatchingScoreList,
    sepaTransaction: bankReconciliationInformation as SepaTransaction,
    disabledForManager: isOnlyManager,
  });

  const contribDepositListColumns = contribListBuilder({
    handleAddCandidate,
    handleRemoveCandidate,
    sepaMatchingScoreList,
    sepaTransaction: bankReconciliationInformation as SepaTransaction,
    disabledForManager: isOnlyManager,
  });

  const totalAddedInvoices = Number(
    sepaMatchingScoreList
      .reduce((acc, obj) => acc + (obj?.remainingAmount?.amount ?? 0), 0)
      .toFixed(2),
  );

  const {
    mutate: refreshTransactionMatchingMutation,
    isLoading: refreshTransactionMatchingLoader,
  } = useMutation(refreshTransactionMatching);

  const handleRefreshTransactionMatching = async () => {
    const payload = { sepaTransactionId: idTransaction };

    refreshTransactionMatchingMutation(payload, {
      onSuccess: () => {
        const successMsg = t('front.refreshMatching.toastMsg.success');
        toast.success(<Toaster textMsg={successMsg} toastType="success" />);
        queryClient.invalidateQueries([
          'get-bank-reconciliation-information',
          idTransaction,
        ]);
      },
      onError: () => {
        const errorText = t(
          'front.refreshMatching.toastMsg.error.transaction',
          {
            transactionId: idTransaction,
          },
        );
        toast.error(<Toaster textMsg={errorText} toastType="error" />);
      },
    });
  };

  if (bankReconciliationInformationLoader) return <LoaderPage />;

  const totalAddedInvoicesTextClassName = classNames(
    {
      'text-color-txt-failure':
        totalAddedInvoices <
        (bankReconciliationInformation?.amount?.amount ?? 0),
      'text-color-success':
        totalAddedInvoices === bankReconciliationInformation?.amount?.amount,
      'text-color-warning':
        totalAddedInvoices >
        (bankReconciliationInformation?.amount?.amount ?? 0),
    },
    'font-bold',
  );

  const handleSetIsModalOpen = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOnClick = () => {
    mutateOrphaned({
      resourceId: bankReconciliationInformation?.id,
      transitionName: 'orphan',
      transitionParams: { comment },
    });
  };

  const handleFill = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleNavigate =
    (raw: ModelRecord<Invoice | ContribDeposit>) => (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.employeePagePath,
        event,
        windowNavigationHref: raw.employeePagePath,
        href: raw.employeePagePath,
        navigate,
      });
    };

  const disabledReconciliationBtn =
    isOnlyManager ||
    sepaMatchingScoreList.length === 0 ||
    bankReconciliationInformation?.status !== 'pending_validation' ||
    totalAddedInvoices < (bankReconciliationInformation?.amount?.amount ?? 0);

  return (
    <div className="mb-[100px]">
      <div className="ml-[20px]">
        <div className="flex justify-between pr-[20px]">
          <p className="big-title">
            {t('front.bankAssociation.show.header.title')}
          </p>
          <div className="flex justify-end">
            <ButtonSecondary
              disabled={
                !bankReconciliationInformation?.previousPendingTransactionId
              }
              className="mr-5"
              to={`/v2/admin/bank_reconciliation/${bankReconciliationInformation?.previousPendingTransactionId}/assignation`}
            >
              {t('front.button.label.previousMatching')}
            </ButtonSecondary>
            <ButtonSecondary
              disabled={
                !bankReconciliationInformation?.nextPendingTransactionId
              }
              className="mr-5"
              to={`/v2/admin/bank_reconciliation/${bankReconciliationInformation?.nextPendingTransactionId}/assignation`}
            >
              {t('front.button.label.nextMatching')}
            </ButtonSecondary>
          </div>
        </div>

        <Breadcrumbs />
      </div>
      <CommentWidget
        commentableType="Sepa::Transaction"
        commentableId={bankReconciliationInformation?.id}
      />
      <div className="flex justify-end mr-[20px] mt-[20px]">
        <div className="flex  mr-[20px]">
          <ButtonSecondary
            className="mr-5"
            isLoading={refreshTransactionMatchingLoader}
            disabled={
              bankReconciliationInformation?.matchingStatus === 'running' ||
              isOnlyManager
            }
            onClick={handleRefreshTransactionMatching}
          >
            {t('front.button.label.refreshMatching')}
          </ButtonSecondary>
          <CompletedValidationModal
            handleSetCloseModal={handleSetIsModalOpen}
            isOpen={isModalOpen}
            handleOnClick={handleOnClick}
            isLoading={isOrphanActionLoading}
            handleFill={handleFill}
          />
          <ButtonSecondary
            className="mr-5"
            onClick={handleSetIsModalOpen}
            disabled={isOnlyManager}
          >
            {t('front.button.label.orphanTransaction')}
          </ButtonSecondary>
          <ButtonPrimary
            isLoading={isLoading}
            disabled={disabledReconciliationBtn}
            onClick={handleValidateMatching}
          >
            {t('front.bankAssociation.show.button.reconciliation.label')}
          </ButtonPrimary>
        </div>
      </div>
      {errors.length > 0 && (
        <div className="mt-[20px] flex-col">
          {errors.map((error) => (
            <Alert alertType="error" text={error} />
          ))}
        </div>
      )}
      {!bankReconciliationInformationLoader && (
        <div className="flex justify-between ml-[20px] mt-[20px]">
          <BankTransfertInformationCard
            bankAccount={bankAccount}
            bankReconciliationInformation={bankReconciliationInformation}
            entityName={entityName as string}
          />
          {isOnlyManager ? null : (
            <ManualReconciliationForm
              className="mr-[20px]"
              handleAddition={handleAddCandidate}
              sepaTransaction={bankReconciliationInformation as SepaTransaction}
            />
          )}
        </div>
      )}

      <div className="flex flex-col mt-[20px] mr-[40px] text-xl text-right">
        <p>
          {t('front.bankAssociation.show.totalToPoint')}
          <AmountWithCurrency
            amount={bankReconciliationInformation?.amount as MoneyType}
          />
        </p>
        <p className={totalAddedInvoicesTextClassName}>
          {t('front.bankAssociation.show.totalInvoicesAdded')}
          <AmountWithCurrency
            amount={totalAddedInvoices}
            currencyIsoCode={bankReconciliationInformation?.amount.currency}
          />
        </p>
      </div>

      <div className="flex">
        <div className="flex flex-col  w-[45%] rounded shadow-table p-[10px] mt-[20px]">
          <p className="text-center text-xxl">
            {t('front.bankAssociation.show.bestMatches').toUpperCase()}
          </p>
          {candidateList.map((candidate: CandidateType) => (
            <AssociationCard
              key={candidate.id}
              onClick={handleAddCandidate(
                candidate,
                bankReconciliationInformation,
              )}
              RightComponent={
                <IconButton Icon={PlusIcon} className="ml-[30x] flex" />
              }
              candidate={candidate}
            />
          ))}
        </div>
        <MoveLeftRightIcon className="self-center mx-[20px]" />
        <div className="flex flex-col w-[45%] rounded shadow-table px-[50px] py-[10px] mt-[20px]">
          <p className="text-center text-xxl">
            {t('front.bankAssociation.show.pointedInvoices').toUpperCase()}
          </p>
          {sepaMatchingScoreList?.map((sepaMatchingScore) => (
            <AssociationCard
              key={sepaMatchingScore.id}
              onClick={handleRemoveCandidate(sepaMatchingScore)}
              RightComponent={
                <p className="font-cabin-bold text-xl w-[40px]">-</p>
              }
              candidate={sepaMatchingScore}
            />
          ))}
        </div>
      </div>
      {bankReconciliationInformation?.direction === 'credit' && (
        <>
          <Table
            collapsable
            title="Liste des factures"
            withImageErrorMsg={false}
            emptyTableErrorMsg="react_table.no_data_text"
            className="mt-[26px]"
            isLoading={fetchInvoicesLoader}
            totalCount={totalCount}
            data={invoices || []}
            reactTableProps={invoiceTransactionProps}
            columns={invoicesListColumns}
            onRowClick={handleNavigate}
          />
          <Table
            collapsable
            title="Liste des contributions"
            withImageErrorMsg={false}
            emptyTableErrorMsg="react_table.no_data_text"
            className="mt-[26px]"
            isLoading={fetchContribDepositsLoader}
            totalCount={contribDepositTotalCount}
            data={contribDeposits || []}
            reactTableProps={contribProps}
            columns={contribDepositListColumns}
            onRowClick={handleNavigate}
          />
        </>
      )}
    </div>
  );
};
