// api/Sepa::File.ts
import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type User from '../User';
import type SepaTransaction from './SepaTransaction';
import type { MatchingStatusEnum } from '../types/matchingStatusTypes';

@Model()
class SepaFile extends ApplicationRecord {
  static jsonapiType = 'sepa-files';

  // Attributes
  @Attr({ persist: false }) messageId!: string;
  @Attr({ persist: false }) generatedAt!: string;
  @Attr({ persist: false }) fileType!: string;
  @Attr({ persist: false }) filename!: string;
  @Attr({ persist: false }) totalTransactions!: number;
  @Attr({ persist: false }) validatedTransactions!: number;
  @Attr({ persist: false }) totalCredits!: number;
  @Attr({ persist: false }) validatedCredits!: number;
  @Attr({ persist: false }) creditsCompletionRate!: number;
  @Attr({ persist: false }) completionRate!: number;
  @Attr({ persist: false }) link!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) createdById!: number;
  @Attr() sourceFileSignedId!: string;
  @Attr({ persist: false }) matchingStatus!: MatchingStatusEnum;
  @Attr() imported!: boolean | undefined;
  @Attr({ persist: false }) lastExportIds!: number[];

  // Relationships
  @BelongsTo('users') user!: User;
  @HasMany('sepa-transactions') sepaTransactions!: Array<SepaTransaction>;

  // Methods
  async fetchRefreshMatching() {
    return this.fetchMemberAction('post', 'refresh_matching');
  }

  get adminBankTransferShowPagePath(): string | undefined {
    const { id } = this;

    if (!id) {
      return undefined;
    }

    return `/v2/admin/bank_transfer/${id}`;
  }
}

export default SepaFile;
