import { Attr, BelongsTo, Model } from 'spraypaint';
import type { User } from '@spraypaint/index';
import ApplicationRecord from './ApplicationRecord';

@Model()
class Prospect extends ApplicationRecord {
  static jsonapiType = 'prospects';

  @Attr() userId!: number;

  @BelongsTo('usersπph') user: User;
}

export default Prospect;
