import { useQuery } from '@tanstack/react-query';
import EmployeePayslip from '@/app/spraypaint/EmployeePayslip';

export const usePayslipStats = ({
  year,
  month,
}: {
  year: number;
  month: number;
}) => {
  const { data, isLoading, ...others } = useQuery(
    ['payslipsAdminIndexStats', year, month],
    () =>
      EmployeePayslip.stats({
        total: 'status,request_age,manager',
      })
        .where({
          period: {
            eq: { year, month },
          },
        })
        .page(0)
        .per(0)
        .all(),

    {
      cacheTime: 0,
    },
  );
  const stats = data?.meta?.stats?.total;
  const managerStats = stats?.manager;
  const requestAgeStats = stats?.request_age;
  const statusStats = stats?.status;

  return {
    data,
    isLoading,
    managerStats,
    requestAgeStats,
    statusStats,
    ...others,
  };
};
