import { useTranslation } from 'react-i18next';
import { ChoixSalaireIllustration } from '../../../../app/assets/icons/tutorial';
import { Line } from '../../../common/components/fragments';
import { CommonTutorial } from './CommonTutorial';
import { TutorialText, TutorialTitle } from './components';

const PayslipChoiceTutorialText = () => {
  const { t } = useTranslation();
  return (
    <>
      <Line />
      <TutorialTitle className="text-bold text-lg mt-[14px]">
        {t('front.payslip.tdc.choiceSalary.tutorial.title')}
      </TutorialTitle>
      <TutorialText>
        {t('front.payslip.tdc.choiceSalary.tutorial.text')}
      </TutorialText>
    </>
  );
};

export const PayslipChoiceTutorial = ({ onClick }: { onClick: () => void }) => (
  <CommonTutorial
    Icon={<ChoixSalaireIllustration height={255} width={177} />}
    onClick={onClick}
    Component={<PayslipChoiceTutorialText />}
  />
);
