import { useQuery } from '@tanstack/react-query';
import FactoringContract from '@/app/spraypaint/factor/FactoringContract';

const useFactoringContracts = () => {
  const data = useQuery({
    queryKey: ['factoring-contracts'],
    queryFn: () => FactoringContract.includes(['legal_entity']).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const factoringContracts = data?.data?.data;
  return { ...data, factoringContracts };
};

export default useFactoringContracts;
