import {
  ChevronRightIcon,
  FilterDownIcon,
} from '../../../app/assets/icons/buttons';

export interface BtnTextProps {
  visible: boolean;
  title: string;
  customBtnTextStyle?: string;
  customActiveIconStyle?: string;
}

export const BtnText = ({
  visible,
  title,
  customBtnTextStyle,
  customActiveIconStyle,
}: BtnTextProps) =>
  visible ? (
    <FilterDownIcon
      data-testid="filter-down-icon"
      height={10}
      width={20}
      className="fill-color-medium-grey hover:fill-color-medium-grey"
    />
  ) : (
    <div className="flex items-center justify-between">
      <p className={customBtnTextStyle}>{title}</p>
      <span>
        <ChevronRightIcon className={customActiveIconStyle} />
      </span>
    </div>
  );
