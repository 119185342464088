import { LoaderFunction, Params } from 'react-router-dom';
import { featureFlagLoader as buildFeatureFlagLoader } from '@/modules/feature_flag/useFeatureFlag';
import redirectTo from '@/app/navigation/loaders/redirectTo';
import { QueryClient } from '@tanstack/react-query';

type BuilderOpts = {
  redirectPathBuilder?: Parameters<typeof redirectTo>[0];
  employeeIdFetcher?: (params: Params<string>) => string | undefined;
  queryClient: QueryClient;
};

// Build a loader that will check if the featureFlag is enabled and redirect if not
export function buildFeatureFlagProtectionLoader(
  featureFlag: string,
  {
    redirectPathBuilder = '/',
    employeeIdFetcher = (params) => params.current_employee,
    queryClient,
  }: BuilderOpts,
): LoaderFunction {
  return async (args) => {
    // build loader to check featureFlag
    const employeeId = employeeIdFetcher(args.params);
    const featureFlagLoader = buildFeatureFlagLoader(
      queryClient,
      featureFlag,
      employeeId && `Employee/${employeeId}`,
    );

    const rep = await featureFlagLoader(args);

    if (rep.data.data.enabled) {
      return true;
    }

    // featureFlag is not enabled, redirect to defaultRedirect or the `v1` handler
    return redirectTo(redirectPathBuilder)(args);
  };
}
