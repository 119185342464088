import { RoutesMounter } from '@/types';
import { member } from '@/app/navigation/functions';
import { DeleteAccountData } from '@/modules/gdpr/pages/DeleteAccountData';

export const mountGdprRoutes: RoutesMounter<unknown> = ({ path }) => [
  member({
    path,
    children: [
      member({
        path: 'delete_data',
        Component: DeleteAccountData,
      }),
    ],
  }),
];
