import { TypedRouteObject } from '@/types';
import { Overwrite } from '@tanstack/react-table';

export function member<TData = unknown>(
  routeEntry: TypedRouteObject<TData>,
): TypedRouteObject<TData> {
  return routeEntry;
}

export function index<TData = unknown>(
  routeEntry: Overwrite<TypedRouteObject<TData>, { path?: never }>,
): Overwrite<TypedRouteObject<TData>, { index: true }> {
  return { ...routeEntry, index: true };
}
