const HOST_PRODUCTION = 'extranet.prium-portage.com';
const HOST_STAGING = 'extranet-staging.prium-portage.com';

type AppEnvName = 'development' | 'staging' | 'production';

export function useAppEnvName(): AppEnvName {
  const { host } = window.location;
  switch (host) {
    case HOST_STAGING:
      return 'staging';
    case HOST_PRODUCTION:
      return 'production';
    default:
      return 'development';
  }
}
