import { useTranslation } from 'react-i18next';
import { AmountText } from '@/modules/common/components/fragments/AmountText';
import { Line } from '../../../common/components/fragments';
import { CraInfoText } from './fragments/CraInfoText';
import { ActivitySummary } from '../../../../app/spraypaint/payslipFunnel/PayslipFunnelCraStep';

export const FooterCra = ({
  activitySummary,
}: {
  activitySummary: ActivitySummary | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col mt-[43px]">
      <Line className="rounded-lg" />
      <p className="text-base3 text-color-medium-grey mt-[13px]">
        {t('front.payslip.tdc.cra.footer.title')}
      </p>
      <div className="flex mt-[57px] ml-[31px] w-[838px] justify-between">
        <CraInfoText
          clasName="flex-1"
          title={t('front.payslip.tdc.cra.footer.pay')}
          innerComponent={
            <AmountText
              amount={
                activitySummary?.activity_account_balance?.amount as number
              }
            />
          }
        />
        <CraInfoText
          clasName="flex-1"
          title={t('front.payslip.tdc.cra.footer.cainvoice')}
          innerComponent={
            <AmountText
              amount={activitySummary?.period_total_revenue?.amount as number}
            />
          }
        />
      </div>
    </div>
  );
};
