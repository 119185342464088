import classNames from 'classnames';
import { Button, ButtonProps } from './Button';

export type ButtonSecondaryMinusProps = {
  children: string;
  textStyle?: string;
  className?: string;
  disabled?: boolean;
} & ButtonProps;

export function ButtonSecondaryMinus({
  children,
  className = '',
  disabled,
  textStyle,
  ...props
}: ButtonSecondaryMinusProps) {
  const customClassName = classNames(
    'flex items-center',
    {
      'border-2 border-color-pone-orange hover:border-color-pone-orange hover:bg-color-pone-orange active:bg-color-dark-orange active:border-color-dark-orange':
        !disabled,
      'border-2 border-color-disabled bg-color-disabled': disabled,
    },
    {
      'text-black hover:text-white': !disabled,
      'text-color-disabled-text': disabled,
    },
    {
      'fill-black hover:fill-white': !disabled,
      'fill-color-disabled-text': disabled,
    },
    className,
  );
  const customTextStyle = classNames('uppercase text-[12px]', textStyle);

  return (
    <Button className={customClassName} disabled={disabled} {...props}>
      <p className={customTextStyle}>{children}</p>
    </Button>
  );
}
