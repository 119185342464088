import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import OverlayLoader from '../../../../app/components/OverlayLoader';
import CostType from '../types/CostType';
import { ItemTypes } from '../types/dragItems';
import ExpenseCard from './ExpenseCard';

type Props = {
  availableCosts: CostType[];
  futureCosts: CostType[];
  isExpenseStepRefetching: boolean;
  selectExpense: (expenseId: number) => void;
  dateRangeLastDate: string;
  isStc: boolean;
};

export const AvailableExpenses = ({
  availableCosts,
  futureCosts,
  isExpenseStepRefetching,
  selectExpense,
  dateRangeLastDate,
  isStc,
}: Props) => {
  const { t } = useTranslation();
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.REMOVE_EXPENSE,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;
  const customClassName = classNames(
    'flex flex-1 flex-col p-[18px] bg-color-bg-expense-card border-color-medium-light border-[1px] mt-[13px] rounded-xl shadow-inner',
    {
      'bg-color-drop-down-blue': isActive,
    },
  );

  return (
    <div ref={drop} className="flex flex-1 flex-col">
      <p className="text-base3 text-color-medium-grey">
        {t('front.payslip.tdc.expense.toadd.title')}
      </p>
      <div className={customClassName}>
        {availableCosts.length > 0 && (
          <OverlayLoader loading={isExpenseStepRefetching}>
            {availableCosts.map((c, index) => (
              <ExpenseCard
                key={c.id || `${index}_${c.label}`}
                type={ItemTypes.ADD_EXPENSE}
                isDraggable
                cost={c}
                isStc={isStc}
                dateRangeLastDate={dateRangeLastDate}
                status="validated"
                onClick={selectExpense}
              />
            ))}
          </OverlayLoader>
        )}
        {futureCosts.map((c, index) => (
          <ExpenseCard
            key={c.id || `${index}_${c.label}`}
            type={ItemTypes.ADD_EXPENSE}
            cost={c}
            isStc={isStc}
            dateRangeLastDate={dateRangeLastDate}
            status="validated"
            disabled
          />
        ))}
      </div>
    </div>
  );
};
