import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import SubcontractorBill from '@/app/spraypaint/SubcontractorBill';

const useSubcontractorBills = (
  scope: (baseScope: Scope<SubcontractorBill>) => Scope<SubcontractorBill>,
  scopeKey: string[],
  employeeId?: string | undefined,
) => {
  const data = useQuery({
    queryKey: ['subcontractorBills', scopeKey],
    queryFn: () =>
      scope(
        SubcontractorBill.select({
          subcontractors: ['name', 'employee_id', 'account_number'],
        })
          .includes(['subcontractor'])
          .scope(),
      )
        .where({ ...(employeeId ? { employeeId } : {}) })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const totalCount = data?.data?.meta.stats.total.count;
  const subcontractorBills = data?.data?.data || [];
  return { ...data, subcontractorBills, totalCount };
};

export default useSubcontractorBills;
