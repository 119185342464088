import type { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { useFrameworkContractFindQuery } from '@/modules/admin/registered_clients/modules/framework_contracts/functions/frameworkContractQuery';
import { queryOpts } from '@/modules/admin/registered_clients/modules/framework_contracts/pages/FrameworkContractShow';
import {
  FormWizardButtons,
  useFormWizardContext,
} from '@/modules/common/components/wizard';
import { consolidationWizardContext } from '@/modules/admin/registered_clients/modules/framework_contracts/constants';
import { useForm } from 'react-hook-form';
import { ConsolidationFormState } from '@/modules/admin/registered_clients/modules/framework_contracts/types';
import { pick } from 'lodash';
import { MainCard } from '@/modules/common/components/mainCard';
import { ControlledDateInput } from '@/modules/common/components/inputs/controlledInput/ControlledDateInput';

type OptionsFormValues = Pick<ConsolidationFormState, 'date'>;

function extractDefaultValues(
  currentFormValues: ConsolidationFormState,
): OptionsFormValues {
  return pick(currentFormValues, ['date']);
}

export const ConsolidationFormOptions: FunctionComponent = () => {
  const { frameworkContractId } = useParams();
  const { record } = useFrameworkContractFindQuery(
    frameworkContractId,
    queryOpts,
    {
      staleTime: 30000,
    },
  );

  const { currentFormValues, onSubmit } = useFormWizardContext(
    consolidationWizardContext,
  );

  const { handleSubmit, control } = useForm<OptionsFormValues>({
    defaultValues: extractDefaultValues(currentFormValues),
  });

  if (!record) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainCard header="Selection des options">
        <ControlledDateInput control={control} label="Date" name="date" />
      </MainCard>
      <FormWizardButtons wizardContext={consolidationWizardContext} />
    </form>
  );
};
