import { Attr, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class IdentityStep extends ApplicationRecord {
  // Attributes
  static jsonapiType = 'onboarding_funnel_steps-identity_steps';
  static endpoint = '/onboarding_funnel_steps/identity_steps';

  // Attributes
  @Attr() completedAt: string;
  @Attr() completedByName: string;
  @Attr() completedById: number;
  @Attr() onboardingFunnelId: number;
  @Attr() birthdate: string;
  @Attr() civility: string;
  @Attr() firstName: string;
  @Attr() lastName: string;
  @Attr() birthCountryId: number;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
}
export default IdentityStep;
