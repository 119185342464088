import Comment from '@spraypaint/Comment';
import dayjs from 'dayjs';
import { useValidation } from '@/app/form/ValidationForm';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { TextInput } from '../inputs';
import { ButtonPrimary } from '../buttons';

export type CommentFormValue = {
  content: string;
};

type Props = {
  comments?: Array<Comment>;
  isLoading: boolean;
  isSuccess: boolean;
  onSubmit: (data: CommentFormValue) => void;
};

export const CommentList = ({
  comments,
  onSubmit,
  isLoading,
  isSuccess,
}: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<CommentFormValue>();
  const Validation = useValidation();

  useEffect(() => {
    if (isSuccess) setValue('content', '');
  }, [isSuccess]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[800px] z-[200] bg-white right-[100px] rounded-lg border-[1px] shadow-btn mr-[20px] p-[20px] max-h-[400px] overflow-y-scroll"
    >
      <Controller
        control={control}
        rules={{ ...Validation.anyRequired }}
        defaultValue={undefined}
        name="content"
        render={({ field: { onChange, value } }) => (
          <TextInput
            textarea
            onChange={onChange}
            value={value}
            placeholder="Ajouter un commentaire"
            errorText={errors?.content}
            inputCustomClass="w-[700px] h-auto"
          />
        )}
      />
      <ButtonPrimary
        className="my-[10px]"
        testID="login-button-submit"
        isLoading={isLoading}
        type="submit"
      >
        Ajouter le commentaire
      </ButtonPrimary>
      <div className="flex flex-col">
        {comments?.length === 0 || !comments
          ? "Il n'y a pas encore de commentaire ajoutés"
          : comments.map((t) => (
              <div className="border-b-[1px] border-b-black p-[10px]">
                <p>
                  <span className="font-cabin-semi-bold">
                    {t.user
                      ? `${t.user.firstName.charAt(0)}. ${t.user.lastName}`
                      : 'Prium ONE'}
                  </span>{' '}
                  <span className="ml-[10px] text-color-medium-grey font-cabin-italic">
                    {dayjs(t.createdAt).format('L')}{' '}
                    {dayjs(t.createdAt).format('H:mm')}
                  </span>
                </p>
                <span className="whitespace-pre-line">{t.content}</span>
              </div>
            ))}
      </div>
    </form>
  );
};
