/* eslint-disable import/no-cycle */
// api/Employee.ts
import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';
import type { PeriodType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';
import PayslipFunnel from './payslipFunnel/PayslipFunnel';
import User from './User';
import Manager from './Manager';
import LegalEntity from './LegalEntity';
import Client from './Client';
import CommercialAgent from './CommercialAgent';
import PortageOffer from './PortageOffer';

@Model()
class Employee extends ApplicationRecord {
  static jsonapiType = 'employees';

  // Attributes
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) firstName!: string;
  @Attr({ persist: false }) lastName!: string;
  @Attr({ persist: false }) fullName!: string;
  @Attr() status!: string;
  @Attr() poleEmploi!: boolean;
  @Attr() porteCommercial!: boolean;

  // Extra attributes
  @Attr({ persist: false }) nextFunnelEmploymentContractId!: string;
  @Attr({ persist: false }) nextFunnelAutoStartDate!: string;
  @Attr({ persist: false }) nextFunnelPeriod!: PeriodType;
  @Attr() enabledProfiles!: Array<string>;
  @Attr() freelanceStackUrl!: string;
  @Attr() followUp!: string;

  // Relationships
  @HasMany('clients') clients!: Client[];
  @HasOne(PayslipFunnel) currentPayslipFunnel!: PayslipFunnel;
  @HasOne(CommercialAgent) commercialAgent!: CommercialAgent;
  @HasOne('portage_offers') portageOffer!: PortageOffer;
  @BelongsTo(User) user!: User;
  @BelongsTo(LegalEntity) legalEntity!: LegalEntity;
  @BelongsTo(Manager) manager!: Manager;

  get employeeV1BasePath(): string | undefined {
    return `/employees/${this.id}`;
  }

  get employeeV2BasePath(): string | undefined {
    return `/v2/employees/${this.id}`;
  }
}

export default Employee;
