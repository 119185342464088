import {
  MinimumSalaryStrategy,
  MinimumSalaryStrategyDescription,
  SalaryStrategy,
  StrategyDescription,
} from '../../../../app/spraypaint/types/salaryStrategyTypes';
import { PayslipChoiceCard } from './PayslipChoiceCard';
import { useSetDefaultOrLast } from '../hooks/useSetDefaultOrLast';
import { containerChoiceCard } from '../../constantes/tailwindStyle';

interface ConventionalMinimumCardProps {
  onStrategyChange: (strategy: MinimumSalaryStrategy) => void;
  currentStrategy: SalaryStrategy | undefined;
  availableStrategiesDescription: StrategyDescription[];
}

export const ConventionalMinimumCard = ({
  onStrategyChange,
  currentStrategy,
  availableStrategiesDescription,
}: ConventionalMinimumCardProps) => {
  const { handleClick } = useSetDefaultOrLast(
    {
      name: 'minimum_salary',
      options: {},
    },
    currentStrategy,
    onStrategyChange,
  );

  const minGrossSalaryDescription = availableStrategiesDescription.find(
    (description) => description.name === 'minimum_salary',
  ) as MinimumSalaryStrategyDescription | undefined;

  if (!minGrossSalaryDescription) return null;

  const minGrossSalary = minGrossSalaryDescription.variables.value.gross;
  const isActive = currentStrategy?.name === 'minimum_salary';

  return (
    <div className={containerChoiceCard}>
      <PayslipChoiceCard
        strategyName="minimum_salary"
        value={minGrossSalary}
        isActive={isActive}
        onClick={handleClick}
        tooltip
      />
    </div>
  );
};
