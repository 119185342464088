import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json';

import 'react-phone-input-2/lib/material.css';
import { Label } from './components';
import { Error } from './components/Error';
// import 'react-phone-input-2/lib/style.css';

export type Props = {
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  className?: string;
  required?: boolean;
  label?: string;
  errorText?: FieldError | undefined;
} & PhoneInputProps;

export const PhoneNumberInput = ({
  value,
  inputProps,
  onChange,
  className,
  required,
  label,
  errorText,
}: Props) => {
  const containerClassName = classNames('flex flex-col', className);
  return (
    <div className={containerClassName}>
      {label && <Label required={required}>{label}</Label>}
      <ReactPhoneInput
        placeholder="+33 -(0)0-00-00-00-00"
        localization={fr}
        country="fr"
        dropdownClass="bg-color-white"
        inputClass="h-[33px] w-[310px] bg-color-white"
        specialLabel=""
        value={value}
        onChange={(inputValue, data, event, formattedValue) => {
          const newValue = inputValue.startsWith('+')
            ? inputValue
            : `+${inputValue}`;
          if (onChange !== undefined)
            onChange(newValue, data, event, formattedValue);
        }}
        inputProps={inputProps}
        enableAreaCodeStretch
      />
      {errorText?.message && <Error>{errorText.message}</Error>}
    </div>
  );
};
