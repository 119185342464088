import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useQuery } from '@tanstack/react-query';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import { Invoice } from '@/app/spraypaint';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { useTranslation } from 'react-i18next';
import Table from '../../common/components/table/Table';
import { PageLayout } from '../../common/components/PageLayout';

const cHelp = createColumnHelper<ModelRecord<Invoice>>();

export function InvoicesIndex(): JSX.Element {
  const employeeId = useCurrentEmployeeId();
  const columns = [
    cHelp.accessor('date', {}),
    cHelp.accessor('number', {}),
    cHelp.accessor('status', {}),
    cHelp.accessor('externalReference', {}),
    cHelp.accessor('clientName', {}),
    cHelp.accessor('totalAmount', {}),
    cHelp.accessor('totalAmountWithVat', {}),
    cHelp.accessor('dueDate', {}),
    cHelp.accessor('sendDate', {}),
  ];

  const [scope, scopeKey, props] = useBackendTable<Invoice>({
    initialSorting: [{ id: 'date', desc: true }],
  });
  const { data, isLoading } = useQuery(['invoicesIndex', scopeKey], () =>
    scope(Invoice.where({ employeeId }).includes('client').scope()).all(),
  );

  const { t } = useTranslation();

  return (
    <PageLayout
      Title={t('front.invoices.index.header.title')}
      Buttons={
        <ButtonPrimary to="drafts/new">
          {t('front.invoices.index.header.buttons.new')}
        </ButtonPrimary>
      }
    >
      <Table
        isLoading={isLoading}
        totalCount={data?.meta?.stats?.total?.count}
        data={data?.data || []}
        reactTableProps={props}
        onRowClick="navigate"
        columns={columns}
      />
    </PageLayout>
  );
}
