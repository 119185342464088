import { useTranslation } from 'react-i18next';
import AccountingJournalExport from '@spraypaint/accounting/AccountingJournalExport';
import { PageLayout } from '@/modules/common/components/PageLayout';
import Table from '../../../common/components/table/Table';
import { useBackendTable } from '../../../common/components/table/hooks';
import useAccountingJournalExports from '../hooks/useAccountingJournalExports';
import { accountingJournalExportsColumns } from '../functions/accountingJournalExportsColumns';
import AccountingJournalExportNew from '../components/AccountingJournalExportNew';

const AccountingJournalExportsIndex = () => {
  const [scope, scopeKey, props] = useBackendTable<AccountingJournalExport>();
  const { accountingJournalExport, isLoading, totalCount, refetch } =
    useAccountingJournalExports(scope, scopeKey);
  const { t } = useTranslation();

  return (
    <PageLayout Title={t('front.accounting.journalExports.title')}>
      <div className="flex justify-between px-[20px]">
        <AccountingJournalExportNew refetchIndex={refetch} />
      </div>
      <Table
        emptyTableErrorMsg="react_table.no_data_text"
        className="mt-[26px]"
        isLoading={isLoading}
        withImageErrorMsg={false}
        totalCount={totalCount}
        data={accountingJournalExport || []}
        reactTableProps={props}
        columns={accountingJournalExportsColumns}
        onRowClick="navigate"
      />
    </PageLayout>
  );
};

export default AccountingJournalExportsIndex;
