import classNames from 'classnames';

type Props = {
  children: string;
  className?: string;
};

export const TutorialTitle = ({ children, className }: Props) => {
  const customClassName = classNames('text-bold text-lg', className);
  return <p className={customClassName}>{children}</p>;
};
