import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import SepaTransaction from '../../../../../app/spraypaint/sepaTransactions/SepaTransaction';

export const useGetAllCompletedBankReconciliationTransaction = (
  args: Array<string>,
  scope: (baseScope: Scope<SepaTransaction>) => Scope<SepaTransaction>,
) => {
  const data = useQuery({
    queryKey: ['completed-bank-reconciliation-transactions', ...args],
    queryFn: () =>
      scope(SepaTransaction.scope())
        .includes(['legal_entity'])
        .where({
          status: ['validated', 'orphaned'],
          reconciliable: true,
        })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const completedSepaTransactions = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, completedSepaTransactions, totalCount };
};

export const useGetAllPendingBankReconciliationTransaction = (
  args: Array<string>,
  scope: (baseScope: Scope<SepaTransaction>) => Scope<SepaTransaction>,
) => {
  const data = useQuery({
    queryKey: ['pending-bank-reconciliation-transactions', ...args],
    queryFn: () =>
      scope(SepaTransaction.scope())
        .includes(['legal_entity'])
        .where({
          status: 'pending_validation',
          reconciliable: true,
        })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const pendingSepaTransactions = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, pendingSepaTransactions, totalCount };
};
