import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import SepaFile from '../../../../../app/spraypaint/sepaTransactions/SepaFile';

export const useGetSepaFiles = (
  args: any,
  scope: (baseScope: Scope<SepaFile>) => Scope<SepaFile>,
) => {
  const data = useQuery({
    queryKey: ['sepa-files', ...args],
    queryFn: () => scope(SepaFile.scope()).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const sepaFiles = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, sepaFiles, totalCount };
};
