import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SepaFile } from '@/app/spraypaint';

type Params = {
  callBack?: () => void;
  errorCallback?: () => void;
  // sepaFileId: number;
};

export const useDeleteSepaFile = ({
  callBack,
  errorCallback,
}: // sepaFileId
Params) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...others } = useMutation({
    mutationFn: async (id: string) => {
      const sepaFile = new SepaFile({
        id,
      });
      const success = await sepaFile.destroy();
      if (!success) {
        throw new Error('Deletion of sepa file failed');
      }
    },
    onSuccess: () => {
      if (callBack) callBack();
      queryClient.invalidateQueries(['sepa-files']);
    },
    onError: () => {
      if (errorCallback) errorCallback();
    },
  });
  return { mutate, isLoading, ...others };
};
