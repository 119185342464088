import classNames from 'classnames';
import { ElementType } from 'react';
import { HelpOutlineIcon } from '../../../../app/assets/icons/inputs';
import { CustomTooltip, CustomTooltipType } from '../tooltip/CustomTooltip';

type Props = {
  Icon?: ElementType;
  iconWidth?: number;
  iconHeight?: number;
  tooltipMsgComp: CustomTooltipType['tooltipMsgComp'];
  tooltipId: string;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  tooltipTxtCustomClass?: string;
  iconClassName?: string;
};

export const TooltipComponent = ({
  Icon = HelpOutlineIcon,
  iconHeight = 20,
  iconWidth = 20,
  tooltipMsgComp,
  tooltipId = '',
  tooltipPosition = 'bottom',
  iconClassName = '',
  tooltipTxtCustomClass,
}: Props) => {
  const customIconClassName = classNames(
    'hover:fill-color-pone-orange',
    {
      'fill-color-medium-grey': !iconClassName,
    },
    iconClassName,
  );

  return (
    <>
      <span id={tooltipId}>
        <Icon
          height={iconHeight}
          width={iconWidth}
          className={customIconClassName}
        />
      </span>
      <CustomTooltip
        tooltipId={tooltipId}
        tooltipMsgComp={tooltipMsgComp}
        position={tooltipPosition}
        tooltipTxtCustomClass={tooltipTxtCustomClass}
      />
    </>
  );
};
