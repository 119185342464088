import FactorAuthorization from '@/app/spraypaint/factor/FactorAuthorization';
import { dynT } from '@/modules/common/components/dynT';
import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';

const columnHelper = createColumnHelper<ModelRecord<FactorAuthorization>>();

export const authorizationColumns = [
  columnHelper.accessor('registeredClient.name', {
    header: dynT(
      'front.factor.authorization.index.columns.registered_client_name',
    ),
  }),
  columnHelper.accessor('ongoingInvoiceCount', {
    header: dynT(
      'front.factor.authorization.index.columns.ongoing_invoice_count',
    ),
  }),
  columnHelper.accessor('approvedAmount', {
    header: dynT('front.factor.authorization.index.columns.approved_amount'),
  }),
  columnHelper.accessor('totalOutstanding', {
    header: dynT('front.factor.authorization.index.columns.total_outstanding'),
  }),
  columnHelper.accessor('unguaranteedOutstanding', {
    header: dynT(
      'front.factor.authorization.index.columns.unguaranteed_outstanding',
    ),
  }),
  columnHelper.accessor('unfinancedOutstanding', {
    header: dynT(
      'front.factor.authorization.index.columns.unfinanced_outstanding',
    ),
  }),
  columnHelper.accessor('outstandingReceivable', {
    header: dynT(
      'front.factor.authorization.index.columns.outstanding_receivable',
    ),
  }),
];
