import { Attr, BelongsTo, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type LegalEntity from '../LegalEntity';
import type SepaFile from '../sepaTransactions/SepaFile';

export const EXPORT_TYPES = [
  'sales',
  'bank_statements',
  'subcontractor_bills',
] as const;
export type ExportTypeEnum = typeof EXPORT_TYPES[number];

@Model()
class AccountingJournalExport extends ApplicationRecord {
  static jsonapiType = 'accounting-journal_exports';

  // Attributes
  @Attr() createdAt!: string;
  @Attr() updatedAt!: string;
  @Attr() generatedAt!: string;
  @Attr() legalEntityId!: string;
  @Attr() sepaFileId!: string | undefined;
  @Attr() date!: string | undefined;
  @Attr() assignationCsvLinesCount!: number | null;
  @Attr() exportType!: ExportTypeEnum;
  @Attr() imported!: boolean | undefined;

  // Extra attributes
  @Attr() assignationCsvUrl!: string;
  @Attr() assignationCsvName!: string;
  @Attr() reassignationCsvUrl!: string;
  @Attr() reassignationCsvName!: string;

  @BelongsTo('legal_entities') legalEntity!: LegalEntity;
  @BelongsTo('sepa_files') sepaFile!: SepaFile;
}

export default AccountingJournalExport;
