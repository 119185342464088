import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import RegisteredClient from '@/app/spraypaint/RegisteredClient';

const useRegisteredClients = (
  scope: (baseScope: Scope<RegisteredClient>) => Scope<RegisteredClient>,
  randomProps: unknown,
) => {
  const data = useQuery({
    queryKey: ['registered-clients', randomProps],
    queryFn: () =>
      scope(RegisteredClient.scope())
        .includes(['activity_domain', 'clients', 'agents'])
        .select({ clients: ['id'] })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const registeredClients = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, registeredClients, totalCount };
};

export default useRegisteredClients;
