import classNames from 'classnames';

export const Error = ({
  children,
  className,
  uploadImage = false,
}: {
  children: string;
  className?: string;
  uploadImage?: boolean;
}) => {
  const customClassName = classNames(
    'text-color-failure text-sm italic pt-2.5',
    { 'px-4': !uploadImage },
    className,
  );

  return <p className={customClassName}>{children}</p>;
};
