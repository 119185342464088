import { useCallback, useState } from 'react';
import { OnChangeFn, SortingState } from '@tanstack/react-table';

export function useControlledSorting({
  resetPagination,
  initialState,
}: {
  resetPagination: () => void;
  initialState: SortingState;
}) {
  const [sortingState, setSortingState] = useState<SortingState>(initialState);

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    (stateUpdater) => {
      setSortingState(stateUpdater);
      resetPagination();
    },
    [setSortingState],
  );

  return { sortingState, onSortingChange };
}
