import classNames from 'classnames';

export const Line = ({
  className,
  rounded,
}: {
  className?: string;
  rounded?: boolean;
}) => {
  const customClassName = classNames(
    'w-11 h-[3px] bg-color-pone-orange',
    {
      'rounded-r-lg': rounded,
    },
    className,
  );
  return <div className={customClassName} />;
};
