import { Attr, Model } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';

@Model()
class Country extends ApplicationRecord {
  static jsonapiType = 'countries';

  // Attributes
  @Attr({ persist: false }) createdAt: string;
  @Attr({ persist: false }) updatedAt: string;
  @Attr({ persist: false }) slug: string;
  @Attr({ persist: false }) code: string;
}

export default Country;
