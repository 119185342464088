import { MouseEventHandler } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuIcon } from '../../app/assets/icons/menu';
import { ButtonMenu, IconButton } from '../common/components/buttons';
import { MenuSeparator } from './components';
import useBreakpoints from '../../app/responsive/useBreakPoints';
import { MenuContainer } from './MenuContainer';
import { MenuType } from './types/MenuType';
import { randomNum } from '../common/utils/maths.utils';

type Props = {
  isMenuRetracted: boolean;
  handleOnClick: MouseEventHandler<HTMLButtonElement>;
  items: Array<MenuType>;
  handleOnMobileMenuClick: () => void;
  isMobileMenuOpen: boolean;
  logAsAdmin?: boolean;
};

function Menu({
  handleOnClick,
  isMenuRetracted,
  items,
  handleOnMobileMenuClick,
  isMobileMenuOpen,
  logAsAdmin,
}: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const { isXs } = useBreakpoints();

  return (
    <MenuContainer
      isMobileMenuOpen={isMobileMenuOpen}
      handleOnMobileMenuClick={handleOnMobileMenuClick}
      isMenuRetracted={isMenuRetracted}
    >
      {!isXs && (
        <IconButton
          onClick={handleOnClick}
          className="flex justify-center items-center w-[60px] self-end mb-[34px]"
          Icon={MenuIcon}
        />
      )}
      {logAsAdmin && (
        <a
          href="/employees"
          className="width-full flex items-center justify-center bg-color-pone-orange hover:bg-color-dark-orange h-[45px] text-white"
        >
          {isMenuRetracted
            ? t('front.menu.adminButton.short').toUpperCase()
            : t('front.menu.adminButton.long').toUpperCase()}
        </a>
      )}
      {items.map((item: MenuType) => {
        const randomKey = `${item.title}_${randomNum()}`;
        if (item.type === 'separator') {
          return (
            item.displaySeparator && (
              <MenuSeparator
                key={`separator_${randomKey}`}
                displayText={!isMenuRetracted}
              >
                {item.title}
              </MenuSeparator>
            )
          );
        }
        if (item?.canBeDisplay) {
          const { to = '', toV1 = '', moduleName = '' } = item;
          return (
            <ButtonMenu
              key={`btn_menu_${randomKey}`}
              notificationsNumber={item.notificationsNumber}
              isSelected={
                location.pathname.includes(to || toV1 || '') &&
                !item.noUnderline
              }
              to={to}
              toV1={toV1}
              moduleName={moduleName}
              displayText={!isMenuRetracted || isXs}
              Icon={item.icon}
              className={item.customClass}
            >
              {item.title}
            </ButtonMenu>
          );
        }
        return null;
      })}
    </MenuContainer>
  );
}

export default Menu;
