import { Table } from '@tanstack/react-table';
import { IconButton } from '../../buttons';
import {
  BtnRadio,
  BtnRadioSelected,
} from '../../../../../app/assets/icons/inputs';

type Props<TData> = {
  table: Table<TData>;
};

export function SelectionRow<TData>({ table }: Props<TData>) {
  // Do not display selection row if selection is disabled
  if (!table.options.enableRowSelection) return null;

  return (
    <tr className="h-[50px] bg-color-light-grey">
      <td colSpan={table.getAllColumns().length}>
        <div className="flex items-center">
          <IconButton
            className="fill-color-pone-orange w-[20px] h-[20px] ml-[10px]"
            Icon={
              table.getIsAllPageRowsSelected() ? BtnRadioSelected : BtnRadio
            }
            onClick={table.getToggleAllPageRowsSelectedHandler()}
          />
          <span className="text-color-medium-grey text-bold text-sm2 ml-[10px]">
            {table.getIsAllPageRowsSelected()
              ? 'TOUT DESELECTIONNER'
              : 'TOUT SÉLECTIONNER'}
          </span>
        </div>
      </td>
    </tr>
  );
}
