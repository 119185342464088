// api/CommercialContract.ts
import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';
import { LinkPropsBuilder } from '@/types';
import type { CurrencyIsoCode } from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';
import Client from './Client';
import Transition from './Transition';

@Model()
class CommercialContract extends ApplicationRecord {
  static jsonapiType = 'commercial_contracts';

  constructor(...args: ConstructorParameters<typeof ApplicationRecord>) {
    super(...args);
    this.linkProps = this.linkProps.bind(this);
  }

  // Attributes
  @Attr() clientId!: number;
  @Attr({ persist: false }) employeeId!: number;
  @Attr({ persist: false }) bankAccountId!: number;
  @Attr() servicePlace!: string;
  @Attr() paymentTime!: string;
  @Attr({ persist: false }) status!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr() memo!: string;
  @Attr() modelType!: string;
  @Attr() codeName!: string;
  @Attr() billingProcedure!: string;
  @Attr() securitis!: boolean;
  @Attr() referenceContractId!: number;
  @Attr() servicePlaceNumber!: string;
  @Attr() servicePlaceRoad!: string;
  @Attr() servicePlaceCity!: string;
  @Attr() servicePlaceCountry!: string;
  @Attr() servicePlaceCountryCode!: string;
  @Attr() servicePlaceZipcode!: string;
  @Attr() contractNatureId!: number;
  @Attr() contractNatureType!: string;
  @Attr() startDate!: string;
  @Attr() endDate!: string;
  @Attr() signatureDate!: string;
  @Attr() intermediaryBusinessId!: number;
  @Attr() sendingType!: string;
  @Attr() clientReference!: string;
  @Attr() documentLanguage!: string;
  @Attr() sendingCredentials!: string;
  @Attr() sendingPlatformLink!: string;
  @Attr() currencyIsoCode!: CurrencyIsoCode;
  @Attr() paymentMethod!: string;
  @Attr() title!: string;

  // Relationships
  @BelongsTo(Client) client!: Client;
  @HasMany(Transition) transitions!: Transition[];

  linkProps: LinkPropsBuilder = ({ pathBuilder }) => ({
    children: this.title,
    to: pathBuilder(`/commercial_contracts/${this.id}`),
  });

  get employeePagePath(): string | undefined {
    const { employeeId, id } = this;

    if (!employeeId || !id) {
      return undefined;
    }

    return `/employees/${employeeId}/commercial_contracts/${id}`;
  }

  get adminPagePath(): string | undefined {
    const { id } = this;

    if (!id) {
      return undefined;
    }

    return `/v2/admin/commercial_contracts/${id}`;
  }
}

export default CommercialContract;
