import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { PeriodType } from '@/modules/common/utils/type.utils';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import { useCurrentEmployeeWithPayslipFunnel } from '../authentification/hooks';
import { Stepper } from '../navigation/components';
import {
  PayslipFunnelStepMapping,
  StepNameBackToFront,
  mapStepNameToMixPanelEventName,
} from '../payslip/types/PayslipIndex';
import { CraStep } from './CRA/CraStep';
import { ExpensesStep } from './expenses/ExpensesStep';
import { FinalSummaryStep } from './finalSummary/FinalSummaryStep';
import {
  OnSuccessTransitionType,
  usePayslipFunnelTransition,
} from './hooks/usePayslipFunnelTransition';
import { IntermediateSummaryStep } from './intermediateSummary/IntermediateSummaryStep';
import { SalaryStep } from './salary/SalaryStep';
import { InvoicesStep } from './invoices/InvoicesStep';
import { SkeletonTutorial } from './components/tutorials/SkeletonTutorial';
import {
  getMonthName,
  monthsNumberToInclude,
} from '../common/utils/date.utils';
import { EventModal } from './expenses/components/EventModal';
import { useDeleteEventContextMessage } from './hooks/useDeleteEventContextMessage';
import useBreakpoints from '../../app/responsive/useBreakPoints';

export const PayslipFunnel = () => {
  const {
    currentPayslipFunnel,
    payslipFunnelId,
    isFetching,
    contextChangeMessages,
  } = useCurrentEmployeeWithPayslipFunnel();
  const { t } = useTranslation();
  const { isXs } = useBreakpoints();

  const employeeId = useCurrentEmployeeId();

  const navigate = useNavigate();

  const currentPayslipFunnelStatus = currentPayslipFunnel?.status;
  const currentPayslipFunnelPeriod = currentPayslipFunnel?.period as PeriodType;
  const currentPayslipFunnelMonth = getMonthName(
    currentPayslipFunnelPeriod?.year,
    currentPayslipFunnelPeriod?.month,
  );

  useEffect(() => {
    if (currentPayslipFunnelStatus === undefined && !isFetching)
      navigate(`/v2/employees/${employeeId}/payslips`);
  }, [currentPayslipFunnelStatus, isFetching]);

  const handleMixpanelTracking = (info: OnSuccessTransitionType) => {
    mixpanel.track(
      mapStepNameToMixPanelEventName[
        info.toStep as unknown as keyof typeof mapStepNameToMixPanelEventName
      ],
      {
        employee_id: employeeId,
        payslipFunnelId,
        from_step: info.fromStep,
        to_step: info.toStep,
      },
    );
  };

  const { mutate, isLoading: transitionLoader } = usePayslipFunnelTransition({
    payslipFunnelId,
    errorMsg: 'front.payslip.tdc.back.error',
    callBack: handleMixpanelTracking,
  });

  const {
    mutate: deleteEventContextMessage,
    isLoading: deleteEventContextMessageLoader,
  } = useDeleteEventContextMessage({});

  const handleMutationTransition = (toStep: string) => {
    mutate({
      transitionName: `back_to_${toStep}`,
      toStep,
      fromStep: currentPayslipFunnelStatus,
    });
  };

  const currentStepIndex = Object.keys(PayslipFunnelStepMapping).findIndex(
    (element) => element === currentPayslipFunnelStatus,
  );

  const handleOnStepperClick = (index: number) => () => {
    const transitionName = StepNameBackToFront(
      index as 0 | 4 | 1 | 3 | 2 | 5 | 6,
    );

    handleMutationTransition(transitionName);
  };

  const translatedSteps = Object.values(PayslipFunnelStepMapping).map((s) =>
    t(s),
  );

  return (
    <div>
      <div className="px-[30px]">
        {!currentPayslipFunnelPeriod ? (
          <div className="h-[44px]" />
        ) : (
          <p className="text-xxl text-color-medium-grey">
            {monthsNumberToInclude(
              currentPayslipFunnelPeriod,
              currentPayslipFunnelMonth,
              t,
            )}
          </p>
        )}

        <EventModal
          isOpen={contextChangeMessages.length > 0 && !isFetching}
          contextChangeMessages={contextChangeMessages[0]}
          onGoBackClick={handleMutationTransition}
          isGoBackLoading={transitionLoader}
          onCancelClick={deleteEventContextMessage}
          isCancelLoading={deleteEventContextMessageLoader}
        />

        <Stepper
          vertical={isXs}
          isLoading={transitionLoader}
          className="mt-[26px]"
          steps={translatedSteps}
          currentStep={currentStepIndex}
          onClick={handleOnStepperClick}
        />
      </div>

      {transitionLoader || isFetching ? (
        <SkeletonTutorial />
      ) : (
        <>
          {currentPayslipFunnelStatus === 'invoices_step' && (
            <InvoicesStep
              payslipFunnelId={payslipFunnelId}
              currentPayslipFunnelPeriod={currentPayslipFunnelPeriod}
            />
          )}
          {currentPayslipFunnelStatus === 'cra_step' && (
            <CraStep
              currentPayslipFunnelPeriod={currentPayslipFunnelPeriod}
              payslipFunnelId={payslipFunnelId}
            />
          )}
          {currentPayslipFunnelStatus === 'expenses_step' && (
            <ExpensesStep
              currentPayslipFunnelPeriod={currentPayslipFunnelPeriod}
              payslipFunnelId={payslipFunnelId}
            />
          )}
          {currentPayslipFunnelStatus === 'intermediate_summary_step' && (
            <IntermediateSummaryStep
              currentPayslipFunnelPeriod={currentPayslipFunnelPeriod}
              payslipFunnelId={payslipFunnelId}
            />
          )}
          {currentPayslipFunnelStatus === 'salary_step' && (
            <SalaryStep payslipFunnelId={payslipFunnelId} />
          )}

          {currentPayslipFunnelStatus === 'final_summary_step' && (
            <FinalSummaryStep
              currentPayslipFunnelMonth={currentPayslipFunnelMonth}
              payslipFunnelId={payslipFunnelId}
            />
          )}
        </>
      )}
    </div>
  );
};
