import {
  blobToDataUrl,
  uploadBlob,
} from '@/modules/common/components/inputs/functions/commonUploadFileFunctions';
import { Toaster } from '@/modules/common/components/toasts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState, MouseEvent } from 'react';
import { toast } from 'react-toastify';
import { UploadDocInput } from '@/modules/common/components/inputs';
import { onRowClickNavigate } from '@/app/navigation/functions';
import { useNavigate } from 'react-router-dom';
import Table from '@/modules/common/components/table/Table';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { useTranslation } from 'react-i18next';
import { useGetFactorClientPaymentFiles } from '../hooks/useGetFactorClientPaymentFiles';
import { createFactorClientPaymentFile } from '../hooks/useCreateFactorClientPaymentFile';
import {
  Blob,
  FactorClientPaymentFile,
  ModelRecord,
} from '../../../../../app/spraypaint';
import { factorClientPaymentFileColmuns } from '../columns/factorClientPaymentFileColmuns';

const ClientPaymentFileIndex = () => {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const queryClient = useQueryClient();
  const [scope, scopeKey, props] = useBackendTable<FactorClientPaymentFile>({
    initialSorting: [{ id: 'createdAt', desc: true }],
  });
  const { factorClientPaymentFiles, isLoading, totalCount } =
    useGetFactorClientPaymentFiles(scopeKey, scope);
  const { mutate: uploadMutate, isLoading: uploadLoader } =
    useMutation(uploadBlob);
  const {
    mutate: factorClientPaymentFileMutate,
    isLoading: factorClientPaymentFileLoader,
  } = useMutation(createFactorClientPaymentFile);

  const clientPaymentFileColumns = factorClientPaymentFileColmuns();
  const { t } = useTranslation();

  const handleUploadFile = async () => {
    if (!selectedFile) return;
    const bytes = await blobToDataUrl(selectedFile);

    const payload = {
      filename: fileName,
      contentType: 'factor-client_payment_files',
      content: { bytes },
    };

    uploadMutate(payload, {
      onSuccess: (data: Blob) => {
        const blobPayload = {
          sourceFileSignedId: data.id,
        };
        factorClientPaymentFileMutate(blobPayload, {
          onSuccess: () => {
            queryClient.invalidateQueries(['factor-client_payment_files']);
          },
          onError: () => {
            const errorText = `Error on file creation for ${data.filename}`;
            toast.error(<Toaster textMsg={errorText} toastType="error" />);
          },
        });
        setFileName('');
      },
      onError: () => {
        const errorText = `ERROR during import of ${selectedFile.name}`;
        toast.error(<Toaster textMsg={errorText} toastType="error" />);
      },
    });
  };

  const handleChangeFile = (file: File) => {
    setSelectedFile(file);
  };

  const handleOnRowClick =
    (raw: ModelRecord<FactorClientPaymentFile>) => (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminShowPagePath,
        event,
        windowNavigationHref: raw.adminShowPagePath,
        navigate,
      });
    };

  return (
    <div className="mt-[50px]">
      <div className="flex justify-between px-[20px]">
        <p className="big-title ">
          {t('front.factor.reconciliation.clientPaymentFile.index.title')}
        </p>
        <UploadDocInput
          // errorText={error}
          isLoading={uploadLoader}
          handleChangeFile={handleChangeFile}
          fileName={fileName}
          setFileName={setFileName}
          handleUploadFile={handleUploadFile}
          label={
            t(
              'front.factor.reconciliation.clientPaymentFile.button.import',
            ) as string
          }
          acceptedFile=".txt"
        />
      </div>
      <Table
        onRowClick={handleOnRowClick}
        className="mt-[26px]"
        isLoading={isLoading || factorClientPaymentFileLoader}
        totalCount={totalCount}
        data={factorClientPaymentFiles || []}
        reactTableProps={props}
        columns={clientPaymentFileColumns}
      />
    </div>
  );
};

export default ClientPaymentFileIndex;
