import FactorAuthorizationFile from '@/app/spraypaint/factor/FactorAuthorizationFile';
import { useQuery } from '@tanstack/react-query';

export const useGetLatestAuthorizationFile = () => {
  const data = useQuery({
    queryKey: ['factor-latest_authorization_file'],
    queryFn: () =>
      FactorAuthorizationFile.order({ generated_at: 'asc' })
        .per(1)
        .page(1)
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const latestAuthorizationFile = data?.data?.data[0];

  return { ...data, latestAuthorizationFile };
};
