import type { FunctionComponent, HTMLAttributes } from 'react';
import { formatDistanceToNow, format, Locale } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { enGB, fr } from 'date-fns/locale';

export interface RelativeTimeProps extends HTMLAttributes<HTMLSpanElement> {
  date: Date | string | number;
}

const availableLocales: Record<string, Locale> = {
  en: enGB,
  fr,
};

export const RelativeTime: FunctionComponent<RelativeTimeProps> = ({
  date,
  ...props
}) => {
  const { i18n } = useTranslation();
  const locale = availableLocales[i18n.language] || fr;

  // Convert the date prop to a Date object
  const dateObj = new Date(date);
  // Format the date for the tooltip
  const absoluteDate = format(dateObj, 'PPpp', { locale });
  // Calculate the relative time
  const relativeTime = formatDistanceToNow(dateObj, {
    addSuffix: true,
    locale,
  });

  return (
    <span {...props} title={absoluteDate}>
      {relativeTime}
    </span>
  );
};
