import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import Subcontractor from '../../../app/spraypaint/Subcontractor';

const useSubcontractors = (
  scope: (baseScope: Scope<Subcontractor>) => Scope<Subcontractor>,
  scopeKey: string[],
  employeeId?: string | undefined,
) => {
  const data = useQuery({
    queryKey: ['subcontractors', scopeKey],
    queryFn: () =>
      scope(
        Subcontractor.select([
          'id',
          'siren',
          'contactName',
          'name',
          'employeeId',
          'status',
        ])
          .includes([{ employee: ['user'] }])
          .scope(),
      )
        .where({ ...(employeeId ? { employeeId } : {}) })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const totalCount = data?.data?.meta.stats.total.count;
  const subcontractors = data?.data?.data || [];
  return { ...data, subcontractors, totalCount };
};

export default useSubcontractors;
