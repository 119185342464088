import { CellContext } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import EmployeePayslip from '@spraypaint/EmployeePayslip';
import Status, {
  StatusType,
} from '@/modules/common/components/statuses/Status';

export const StatusCell = ({
  cell,
}: CellContext<ModelRecord<EmployeePayslip>, unknown>) => {
  const { stc, new: payslipNew, status } = cell.row.original;

  const renderStatus = (statusType: string) => (
    <Status statusType={statusType as StatusType} className="my-1" />
  );

  return (
    <span className="grid justify-items-center">
      {status ? renderStatus(status) : renderStatus('missing' as StatusType)}
      {stc ? renderStatus('stc' as StatusType) : null}
      {payslipNew ? renderStatus('new' as StatusType) : null}
    </span>
  );
};
