import { FunctionComponent, memo } from 'react';
import { MainCard } from '@common/components/mainCard';
import { useForm } from 'react-hook-form';
import { ControlledSelect } from '@common/components/inputs/controlledInput/ControlledSelect';
import { ButtonPrimary, ButtonSecondary } from '@common/components/buttons';
import CitizenshipStep from '@spraypaint/onBoarding/CitizenshipStep';
import { useCountries } from '@common/country/useCountries';
import { useTranslation } from 'react-i18next';
import {
  CitizenshipStepFormState,
  CitizenshipStepType,
} from '@/modules/welcome/types/OBCitizenshipStepType';
import {
  AddressFields,
  defaultFieldsNameFor,
} from '@/modules/common/components/inputs/AddressFields';

// The OBCitizenshipStep component is wrapped with React.memo to optimize performance.
// React.memo will prevent re-renders of the component unless the props change.
// This is beneficial when the props are complex or change infrequently.
const OBCitizenshipStep: FunctionComponent<CitizenshipStepType> = memo(
  ({ onboardingFunnel, performTransition, saveThenTransition }) => {
    const { t } = useTranslation();
    const { citizenshipStep } = onboardingFunnel;

    const initialValues: CitizenshipStepFormState = {
      countryId: String(citizenshipStep.countryId),
      address: citizenshipStep.address,
      addressNumber: citizenshipStep.addressNumber,
      addressRoad: citizenshipStep.addressRoad,
      addressCity: citizenshipStep.addressCity,
      addressZipcode: citizenshipStep.addressZipcode,
      addressCountry: citizenshipStep.addressCountry,
      addressCountryCode: citizenshipStep.addressCountryCode,
    };

    const form = useForm<CitizenshipStepFormState>({
      defaultValues: initialValues,
    });

    const {
      formState: { isSubmitting, errors },
      handleSubmit,
      control,
    } = form;

    const { nationalitiesOptions } = useCountries();

    const onSubmit = (data: CitizenshipStepFormState) =>
      saveThenTransition({
        data,
        transitionName: 'complete_citizenship_step',
        stepToSave: new CitizenshipStep({
          id: citizenshipStep.id,
        }),
      });

    return (
      <MainCard
        header={t('front.welcome.onboarding.steps.citizenshipStep')}
        className="mt-[20px] mx-5 md:w-[800px]"
      >
        <form onSubmit={handleSubmit(onSubmit, (e) => console.error(e))}>
          <div className="flex-1">
            <div className="flex-col">
              <ControlledSelect
                name="countryId"
                labelClassName="flex-1"
                displayCol
                className="m-3"
                required
                control={control}
                label={t('activerecord.attributes.user.nationality')}
                options={nationalitiesOptions}
                isSearchable
                isClearable
                errorText={
                  errors.countryId
                    ? errors.countryId.message ||
                      t('views.shared.field_required_error')
                    : undefined
                }
              />
              <AddressFields
                control={control}
                fieldsName={defaultFieldsNameFor('address')}
                label={t('activerecord.attributes.contact.address')}
              />
            </div>
          </div>
          <div className="flex justify-end ml-[20px] mt-[20px]">
            <ButtonSecondary
              className="m-2"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={() =>
                performTransition({ transitionName: 'back_to_identity_step' })
              }
            >
              {t('default.button.back')}
            </ButtonSecondary>
            <ButtonPrimary
              className="m-2"
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              {t('default.submit')}
            </ButtonPrimary>
          </div>
        </form>
      </MainCard>
    );
  },
);

export { OBCitizenshipStep };
