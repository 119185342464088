// api/PayslipFunnelSteps::CraStep.ts
import { Model, Attr, HasOne } from 'spraypaint';
import { MoneyType } from '../../../modules/common/utils/type.utils';
import type ActivityReport from '../activityReport/ActivityReport';
import { CompletionErrorType } from '../../../modules/common/types/CompletionError';
import PayslipFunnelBaseStep from './PayslipFunnelBaseStep';

export type CraCategorieType =
  | 'blank'
  | 'working_days'
  | 'telework'
  | 'paid_holidays'
  | 'sickness'
  | 'family_event'
  | 'paid_parental_leave'
  | 'parental_absence'
  | 'work_accident_absence'
  | 'other';

export type ActivitySummary = {
  activity_account_balance: MoneyType;
  period_total_revenue: MoneyType;
  conventionalMinimum: MoneyType;
  workedDays: number;
  maxWorkedDays: number;
  workedHours: number;
  paidHolidaysDays: number;
  maxPaidHolidaysDays: number;
  paidHolidaysHours: number;
  absenceDays: number;
  absenceHours: number;
};

@Model()
class PayslipFunnelCraStep extends PayslipFunnelBaseStep {
  // Attributes
  static jsonapiType = 'payslip_funnel_steps-cra_steps';
  static endpoint = '/payslip_funnel_steps/cra_steps';

  // Attributes
  @Attr({ persist: false }) expensesDates!: Array<string>;
  @Attr({ persist: false }) mayComplete!: boolean;
  @Attr({ persist: false }) completed!: boolean;
  @Attr() autofill!: boolean;
  @Attr({ persist: false }) completionErrors!: Array<CompletionErrorType>;
  @Attr({ persist: false }) availableSlotTypes!: Array<CraCategorieType>;
  @Attr({ persist: false }) maxWorkedDays!: number;
  @Attr({ persist: false }) paidHolidays!: number;
  @Attr({ persist: false }) maxHolidays!: number;
  @Attr({ persist: false }) workingTime!: number;
  @Attr({ persist: false }) absenceDays!: number;

  // Relationships
  @HasOne('activity_reports') activityReport!: ActivityReport;

  // Extra attributes
  @Attr({ persist: false }) activitySummary!: ActivitySummary;
}
export default PayslipFunnelCraStep;
