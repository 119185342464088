import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { FactorClientPaymentFile } from '../../../../../app/spraypaint';
import {
  PeriodFilter,
  getPeriodFilterFn,
  TextFilter,
} from '../../../../common/components/table/filter';

const columnHelper = createColumnHelper<ModelRecord<FactorClientPaymentFile>>();

export const factorClientPaymentFileColmuns = () => {
  const { t } = useTranslation();

  return [
    columnHelper.accessor('createdAt', {
      header: t('front.bankTransfer.index.table.importDate') as string,
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
      cell: (info) => (
        <div className="text-left">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    columnHelper.accessor('filename', {
      header: t('front.bankTransfer.index.table.filename') as string,
      meta: {
        filter: {
          Component: TextFilter,
        },
      },
      cell: (info) => (
        <div className="text-left">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
  ];
};
