export const TimeEntry = ({
  label,
  days,
  hours,
}: {
  label: string;
  days: number;
  hours: number;
}) => (
  <div className="my-2 pr-5 flex w-[250px] flex-col items-left mr-20">
    <p className="text-color-dark-content text-sm2">{label}</p>
    <p className="text-color-dark-content text-xl1">
      {days} ({hours} h)
    </p>
  </div>
);
