/* eslint-disable radix */
import { useCallback, useEffect, useState } from 'react';
import { FilterFn, RowData } from '@tanstack/react-table';
import { SingleValue } from 'react-select';
import { findValueOption } from '@/modules/common/components/inputs/controlledInput/ControlledSelect';
import { FilterComponentProps, PriumFilterFn } from './types';

import { Select, SelectProps, Option } from '../../inputs/Select';

export type SelectFilterOptions<TValue extends string> = SelectProps<{
  label: string;
  value: TValue;
}>;

export function getSelectFilterFn<TData extends RowData>(): FilterFn<TData> {
  const filter: PriumFilterFn<TData> = (
    row,
    columnId,
    filterValue: unknown,
  ) => {
    const value = row.getValue(columnId);

    if (value == null) return false;

    return value === filterValue;
  };

  filter.backendFilterFn = (columnId, value: string) => ({
    [columnId]: value,
  });

  filter.resolveFilterValue = (value) => value;

  return filter;
}

export function SelectFilter<TData, TValue extends string>({
  options,
  header,
  ...props
}: FilterComponentProps<TData, TValue, SelectFilterOptions<TValue>>) {
  const { options: selectOptions } = options ?? {};
  const [selectValue, setSelectValue] = useState<Option | undefined>(undefined);
  const { column } = header;

  useEffect(() => {
    const filterValue = column.getFilterValue();
    const sanitizedValue = filterValue?.toString() || '';
    const option = findValueOption(selectOptions, sanitizedValue);
    setSelectValue(option);
  }, [column]);

  const onValueChange = useCallback(
    (value: SingleValue<Option>) => {
      setSelectValue(value || undefined);
      column.setFilterValue(value?.value);
    },
    [column],
  );

  return (
    <Select
      {...props}
      value={selectValue}
      onChange={onValueChange}
      options={selectOptions}
      selectClassName="bg-white"
    />
  );
}
