import { Header } from '@tanstack/react-table';
import { useCallback } from 'react';
import classNames from 'classnames';
import { FilterUpIcon } from '../../../../../app/assets/icons/buttons';

type HeaderSortButtonProps<TData, TValue> = {
  header: Header<TData, TValue>;
};

export function HeaderSortButtons<TData, TValue>({
  header: { column },
}: HeaderSortButtonProps<TData, TValue>) {
  if (!column.getCanSort()) return null;

  const isSortUp = column.getIsSorted() === 'desc';
  const isSortDown = column.getIsSorted() === 'asc';

  const commonClassName = 'h-[5px] w-[10px]';
  const sortUpClassName = classNames(commonClassName, 'mb-[2px]', {
    'fill-black': isSortUp,
  });
  const sortDownClassName = classNames(commonClassName, 'rotate-180 mt-[2px]', {
    'fill-black': isSortDown,
  });

  const onSortClick = useCallback(() => {
    const isSortedDesc = column.getIsSorted() === 'desc';
    const nextSortingOrder = isSortedDesc
      ? column.getNextSortingOrder()
      : 'desc';
    column.toggleSorting(isSortedDesc ? nextSortingOrder === 'desc' : true);
  }, [column]);

  return (
    <button
      className="flex flex-col fill-color-medium-grey ml-[10px] cursor-pointer"
      type="button"
      onClick={onSortClick}
      data-testid="filter"
    >
      <FilterUpIcon className={sortUpClassName} height={5} width={10} />
      <FilterUpIcon className={sortDownClassName} height={5} width={10} />
    </button>
  );
}
