import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { ButtonPrimary } from '../../common/components/buttons';
import {
  useToggleMiniTutorial,
  useToggleTutorial,
} from '../../widgets/hooks/ToggleTutorial';
import { AutofillButton } from '../components/AutofillButton';
import { MinitutorialOff } from '../components/tutorials/components/MinitutorialOff';
import { CraTutorial } from '../components/tutorials/CraTutorial';
import { CommonMiniTutorial } from '../components/tutorials/miniTutorials/CommonMiniTutorial';
import { usePayslipFunnelTransition } from '../hooks/usePayslipFunnelTransition';
import { FooterCra } from './components';

import { getDatesRange } from './utils/getDatesRange';
import { getActivityReportSlots } from './utils/getActivityReportSlots';
import { Alert } from '../../common/components/toasts';
import {
  useAutoFill,
  useGetCurrentCraDatas,
  useGetCurrentExtraCraDatas,
  useUpdateSlot,
} from './hooks';
import {
  getFirstDayOfMonth,
  getMonthName,
} from '../../common/utils/date.utils';
import { CompletionErrorType } from '../../common/types/CompletionError';
import {
  ActivitySummary,
  CraCategorieType,
} from '../../../app/spraypaint/payslipFunnel/PayslipFunnelCraStep';
import { CraCalendar } from '../../payslip/components';
import { useGetRangeDates } from './hooks/useGetRangeDates';
import { PeriodType } from '../../common/utils/type.utils';
import { useCurrentEmployeeId } from '../../../app/hooks/useCurrentEmployeeId';
import { tutorialContainerCustomClass } from '../constantes/tailwindStyle';
import StepMessages from '../components/StepMessages';

type Props = {
  payslipFunnelId: number;
  currentPayslipFunnelPeriod: PeriodType;
};

export const CraStep = ({
  payslipFunnelId,
  currentPayslipFunnelPeriod,
}: Props) => {
  const { t } = useTranslation();
  const employeeId = useCurrentEmployeeId();
  const callback = () => {
    mixpanel.track('TDS: Cra Step Autofilled', {
      employee_id: employeeId,
      payslip_funnel_id: payslipFunnelId,
    });
  };

  const [selectedType, setSelectedType] = useState<
    CraCategorieType | undefined
  >('working_days');

  const { dateRangeFirstDate, dateRangeLastDate } =
    useGetRangeDates(payslipFunnelId);

  const {
    activityReportSlots,
    isLoading,
    availableSlotTypes,
    expensesDates,
    data,
  } = useGetCurrentCraDatas(payslipFunnelId);

  const {
    mayComplete,
    craMaxDaysInfos,
    completionErrors,
    activitySummary,
    refetch: refetchCurrentExtraCraData,
  } = useGetCurrentExtraCraDatas(payslipFunnelId);

  const { mutate } = useAutoFill(callback);
  const handleAutofill = () => {
    mutate(payslipFunnelId);
    refetchCurrentExtraCraData();
  };

  const firstDayOfTheMonth = getFirstDayOfMonth(
    new Date(
      currentPayslipFunnelPeriod?.year,
      currentPayslipFunnelPeriod.month - 1,
    ),
  );
  const currentPayslipFunnelMonth = getMonthName(
    currentPayslipFunnelPeriod?.year,
    currentPayslipFunnelPeriod?.month,
  );

  const dates = getDatesRange(firstDayOfTheMonth as Date);
  const { dateWithActivityReportSlots, indexedSlots } = getActivityReportSlots(
    dates,
    activityReportSlots,
  );

  const { updateActivityReportSlot } = useUpdateSlot(
    indexedSlots,
    payslipFunnelId,
    selectedType,
  );

  const { toggleMiniTutorial, isMiniTutorialOn } = useToggleMiniTutorial();

  const { isTutorialOn, toggleAllTutorial } = useToggleTutorial();

  const [didUserValidateTutorial, setUserValidateTutorial] =
    useState(isTutorialOn);

  const handleOnClick = () => {
    setUserValidateTutorial(false);
    toggleAllTutorial();
  };

  const { mutate: validateCraStep, isLoading: mutationLoader } =
    usePayslipFunnelTransition({ payslipFunnelId });

  const handleValidateCraStep = useCallback(
    () =>
      validateCraStep({
        transitionName: 'complete_cra_step',
      }),
    [validateCraStep],
  );

  if (didUserValidateTutorial) {
    return <CraTutorial onClick={handleOnClick} />;
  }

  return (
    <div>
      <div className={tutorialContainerCustomClass}>
        {isMiniTutorialOn ? (
          <CommonMiniTutorial
            Component={
              <p className="text-color-dark-content">
                {t('front.payslip.tdc.buttoncra.miniTutorial')}
              </p>
            }
            onClick={toggleMiniTutorial}
          />
        ) : (
          <MinitutorialOff onClick={toggleMiniTutorial} />
        )}
        <StepMessages step={data?.data?.craStep} />
        <ButtonPrimary
          disabled={!mayComplete}
          className="w-[210px] ml-5 md:ml-0 mt-2 md:mt-0"
          onClick={handleValidateCraStep}
          isLoading={mutationLoader}
        >
          {t('front.payslip.tdc.cra.button.title')}
        </ButtonPrimary>
      </div>
      {completionErrors?.map((error: CompletionErrorType) => (
        <Alert
          className="my-[10px] ml-[30px]"
          alertType="error"
          text={error.localized_message}
        />
      ))}
      <div className="w-full flex bg-color-transparent-medium-grey py-[15px] px-[55px] justify-between mt-[20px]">
        <p className="text-xxl text-color-medium-grey">
          {`${currentPayslipFunnelMonth} ${currentPayslipFunnelPeriod?.year}`}
        </p>
        <AutofillButton onClick={handleAutofill} />
      </div>
      <CraCalendar
        rangeDates={[dateRangeFirstDate, dateRangeLastDate] as [string, string]}
        isLoading={isLoading}
        setSelectedType={setSelectedType}
        updateActivityReportSlot={updateActivityReportSlot}
        selectedType={selectedType}
        availableSlotTypes={availableSlotTypes}
        expensesDates={expensesDates}
        dateWithActivityReportSlots={dateWithActivityReportSlots}
        firstDayOfTheMonth={firstDayOfTheMonth}
        craMaxDaysInfos={craMaxDaysInfos}
      />
      <div className="ml-[30px] mb-[82px]">
        <FooterCra activitySummary={activitySummary as ActivitySummary} />
      </div>
    </div>
  );
};
