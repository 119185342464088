import { ComponentProps } from 'react';
import classNames from 'classnames';
import { Button, ButtonProps } from './Button';
import { IconType } from '../../utils/type.utils';

type Props = {
  Icon: IconType;
  imageClassName?: string;
  className?: string;
  dataTestId?: string;
  disabled?: boolean;
  iconProps?: ComponentProps<IconType>;
} & ButtonProps;

export function IconButton({
  Icon,
  className = '',
  disabled,
  imageClassName,
  dataTestId = 'icon-button',
  iconProps = {},
  ...props
}: Props) {
  const customClassName = classNames(className);
  const customImageClassName = classNames(imageClassName);
  return (
    <Button
      testID={dataTestId}
      custom
      className={customClassName}
      disabled={disabled}
      {...props}
    >
      <Icon className={customImageClassName} {...iconProps} />
    </Button>
  );
}
