// api/Invoice.ts
import { Attr, HasOne, Model } from 'spraypaint';
import type { CandidateType } from '@/modules/admin/accounting/CandidateType';
import ApplicationRecord from './ApplicationRecord';

export type StatusEnum = 'in_progress' | 'submitted' | 'paid';

@Model()
class Numbered extends ApplicationRecord {
  static jsonapiType = 'numbereds';

  // Attributes
  @Attr() number!: string;
  @Attr() numberedType!: string;
  @Attr() numberedId!: number;

  // Relationships
  @HasOne({ name: 'numbered' }) numbered!: CandidateType;
}

export default Numbered;
