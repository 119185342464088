import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useTranslation } from 'react-i18next';
import { MoneyType } from '@/modules/common/utils/type.utils';
import { MouseEvent, KeyboardEvent } from 'react';
import { CandidateType } from '@/modules/admin/accounting/CandidateType';
import {
  getStrictEqualFilter,
  StrictEqualFilter,
} from '../../../../common/components/table/filter/StrictEqualFilter';
import {
  SupInfFilter,
  getSupInfFilter,
} from '../../../../common/components/table/filter/SupInfFilter';
import {
  ContribPeeDeposit,
  ContribPercoDeposit,
  Invoice,
  SepaImport,
  SepaTransaction,
  SubcontractorBill,
} from '../../../../../app/spraypaint';
import { CheckBox } from '../../../../common/components/inputs';
import {
  getPeriodFilterFn,
  PeriodFilter,
} from '../../../../common/components/table/filter';

const columnHelper = createColumnHelper<ModelRecord<Invoice>>();

export const invoicesListColumns = ({
  handleAddCandidate,
  handleRemoveCandidate,
  sepaMatchingScoreList,
  sepaTransaction,
  disabledForManager,
}: {
  handleAddCandidate: (
    candidateToMove: CandidateType,
    sepaTransaction: SepaTransaction,
  ) => () => void;
  handleRemoveCandidate: (candidateToMove: CandidateType) => () => void;
  sepaMatchingScoreList: Array<
    | Invoice
    | SubcontractorBill
    | SepaImport
    | ContribPeeDeposit
    | ContribPercoDeposit
  >;
  sepaTransaction: SepaTransaction;
  disabledForManager: boolean;
}) => {
  const { t } = useTranslation();

  const ActionsCell = ({
    cell,
  }: CellContext<ModelRecord<Invoice>, unknown>) => {
    const invoice = cell.row.original;
    const isChecked = !!sepaMatchingScoreList.find(
      (storedInvoice) =>
        storedInvoice instanceof Invoice && storedInvoice.id === invoice.id,
    );

    const onChange = isChecked
      ? handleRemoveCandidate(invoice as CandidateType)
      : handleAddCandidate(invoice as CandidateType, sepaTransaction);

    const handleClick = (
      e: MouseEvent<HTMLInputElement, globalThis.MouseEvent>,
    ) => {
      e.stopPropagation();
      onChange();
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onChange();
      }
    };

    return disabledForManager ? null : (
      <div
        className="flex justify-center h-[100%]"
        style={{ alignItems: 'center' }}
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        role="button"
        tabIndex={0}
      >
        <CheckBox
          id={invoice.id}
          checked={isChecked}
          onChange={onChange}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    );
  };

  return [
    columnHelper.accessor('number', {
      // filter working but return two lines: the chosen id and the next one
      header: 'Numéro de facture',
      filterFn: getStrictEqualFilter(),
      meta: {
        filter: {
          Component: StrictEqualFilter,
        },
      },
      cell: (info) => (
        <div className="text-center">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    columnHelper.accessor('date', {
      // filter working
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
      header: 'Date',
      cell: (info) => (
        <div className="text-left">
          <p>{info.getValue()?.toString()}</p>
        </div>
      ),
    }),
    columnHelper.accessor('client.name', {
      // filter working
      header: 'Client',
      cell: (info) => (
        <div className="text-left">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    columnHelper.accessor('totalAmount', {
      // filter working
      header: 'HT',
      filterFn: getSupInfFilter(),
      meta: {
        filter: {
          Component: SupInfFilter,
        },
      },
      cell: (info) => (
        <div className="text-left">
          <AmountWithCurrency amount={info.getValue() as MoneyType} />
        </div>
      ),
    }),
    columnHelper.accessor('totalAmountWithVat', {
      // filter working
      header: 'TTC',
      filterFn: getSupInfFilter(),
      meta: {
        filter: {
          Component: SupInfFilter,
        },
      },
      cell: (info) => (
        <div className="text-left">
          <AmountWithCurrency amount={info.getValue() as MoneyType} />
        </div>
      ),
    }),
    columnHelper.accessor('status', {
      header: t('default.status'),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('actions', {
      header: t('default.actions'),
      enableColumnFilter: false,
      enableSorting: false,
      cell: ActionsCell,
    }),
  ];
};
