import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import useAuthClient, { AuthErrorResponse } from './useAuthClient';

export type CreateAccountVariables = {
  // The user's email address
  login: string;
  first_name: string;
  last_name: string;
  cgu_agreement: boolean;
  newsletter: boolean;
};

// No data is returned from this endpoint
export type CreateAccountData = unknown;

const ALREADY_EXIST_UNVERIFIED =
  'already_an_unverified_account_with_this_login';

export type CreateAccountError = AuthErrorResponse & {
  reason_code?: typeof ALREADY_EXIST_UNVERIFIED;
  reason?: string;
};

export const useCreateAccount = (
  opts: UseMutationOptions<
    AxiosResponse<CreateAccountData>,
    AxiosError<CreateAccountError>,
    CreateAccountVariables
  > = {},
) => {
  const authClient = useAuthClient();
  return useMutation({
    ...opts,
    mutationFn: (data) =>
      authClient.post<CreateAccountData>('/create_account', data),
  });
};
