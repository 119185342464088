import { MouseEventHandler } from 'react';
import classNames from 'classnames';

export const useStepData = (
  steps: Array<string>,
  currentStep: number,
  vertical: boolean | undefined,
  onClick:
    | ((index: number) => MouseEventHandler<HTMLButtonElement>)
    | undefined,
  isXs: boolean | undefined,
  setDisplayAllSteps: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void,
) =>
  steps.map((stepLabel, index) => {
    const isFirst = index === 0;
    const isLast = index === steps.length - 1;
    const hasBeenDone = currentStep > index;
    const isCurrent = index === currentStep;
    const stepIndex = index;
    const stepContainerClassName = classNames('flex text-base2', {
      'text-color-medium-grey border-color-medium-grey': !hasBeenDone,
      'flex-col': vertical,
      'items-center': !vertical || isXs,
      'flex-1': !isLast,
      shrink: isLast,
    });
    const iconStepClassName = classNames({
      'border-color-medium-grey': !hasBeenDone && !isCurrent,
      'bg-color-pone-orange text-white border-color-pone-orange': isCurrent,
    });
    const labelClassName = classNames({ 'text-color-pone-orange': isCurrent });

    const onCurrentStepClick = (i: number) => () => {
      if (onClick) {
        if (isXs && isCurrent) {
          setDisplayAllSteps((prevState) => !prevState);
        } else {
          onClick(i);
        }
      }
    };

    return {
      stepIndex,
      isFirst,
      isLast,
      hasBeenDone,
      isCurrent,
      stepContainerClassName,
      iconStepClassName,
      labelClassName,
      onCurrentStepClick,
    };
  });
