import { ClientPayment, Invoice } from '@/app/spraypaint';
import { useFormatMoney } from '@/app/utils/moneyUtils';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { MoneyType } from '@/modules/common/utils/type.utils';
import { MainCard } from '@/modules/common/components/mainCard';
import { AttributeLine } from '@/modules/common/components/AttributeLine';

export function AssignedAmountsCard(props: { invoice: Invoice }) {
  const { invoice } = props;
  const formatMoney = useFormatMoney();
  const { t } = useTranslation();

  const { data } = useQuery({
    queryKey: ['assigned-amounts', invoice.id],
    queryFn: async () =>
      ClientPayment.where({
        sourceableType: 'Invoice',
        sourceableId: invoice.id,
      })
        .stats({ amount: 'sum' })
        .per(1)
        .page(1)
        .all(),
    enabled: invoice.invoiceType === 'corporate',
  });

  if (invoice.invoiceType !== 'corporate') {
    return null;
  }

  const { totalAmountWithVat, paidAmount } = invoice;
  const paidAmountPercent = (
    (paidAmount.amount / totalAmountWithVat.amount) *
    100
  ).toFixed(1);

  const clientPaymentAmounts = data?.meta?.stats?.amount?.sum;
  const clientPaymentAmount: MoneyType | undefined = clientPaymentAmounts
    ? clientPaymentAmounts[invoice.currencyIsoCode]
    : undefined;
  const clientPaymentAmountPercent =
    clientPaymentAmount &&
    ((clientPaymentAmount.amount / totalAmountWithVat.amount) * 100).toFixed(1);

  const remainingAmount =
    clientPaymentAmount &&
    totalAmountWithVat.amount - clientPaymentAmount.amount;
  const remainingAmountMoney: undefined | MoneyType =
    remainingAmount === undefined
      ? undefined
      : {
          amount: remainingAmount,
          currency: invoice.currencyIsoCode,
        };

  const remainingAmountPercent =
    remainingAmount &&
    clientPaymentAmount &&
    ((remainingAmount / clientPaymentAmount.amount) * 100).toFixed(1);

  return (
    <MainCard header="Montants imputés">
      <AttributeLine
        id="total_amount"
        label={t('activerecord.attributes.invoice.total_amount_with_vat', {
          ns: 'rails',
        })}
        value={formatMoney(totalAmountWithVat)}
      />
      <AttributeLine
        id="total_amount"
        label="Montant Reçu"
        value={`${formatMoney(paidAmount)} (${paidAmountPercent}%)`}
      />
      <AttributeLine
        id="assigned_amount"
        label="Montant distribué"
        value={
          clientPaymentAmount &&
          `${formatMoney(clientPaymentAmount)} (${clientPaymentAmountPercent}%)`
        }
      />
      <AttributeLine
        id="remaining_amount"
        label="Montant restant"
        value={
          remainingAmountMoney &&
          `${formatMoney(remainingAmountMoney)} (${remainingAmountPercent}%)`
        }
      />
    </MainCard>
  );
}
