import FactorTransferable from '@/app/spraypaint/factor/FactorTransferable';
import MultiSelectTable from '@/modules/common/components/inputs/components/MultiSelectTable';
import { MainCard } from '@/modules/common/components/mainCard';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import FactorTransfer from '@/app/spraypaint/factor/FactorTransfer';
import FactorTransferItem from '@/app/spraypaint/factor/FactorTransferItem';
import { toast } from 'react-toastify';
import { Toaster } from '@/modules/common/components/toasts';
import { compact } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select } from '@/modules/common/components/inputs/Select';
import factorTransferableColumns from '../functions/factorTransferableColumns';
import useFactoringContracts from '../../factoring_contracts/hooks/useFactoringContracts';

const columns = factorTransferableColumns();

const NewFactorTransfer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { factoringContracts } = useFactoringContracts();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [factoringContractOption, setFactoringContractOption] = useState<{
    label: string;
    value: string;
  } | null>();
  const factoringContractId = factoringContractOption?.value;

  const [scope, scopeKey, props] = useBackendTable<FactorTransferable>();
  const queryResult = useQuery({
    queryKey: ['factor_transferables', scopeKey, factoringContractId],
    queryFn: () =>
      scope(FactorTransferable.per(10)).where({ factoringContractId }).all(),
    enabled: !!factoringContractId,
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    const transfer = new FactorTransfer();
    transfer.factorFactoringContractId = Number(factoringContractId);
    selectedIds.forEach((selectableId) => {
      const newItem = new FactorTransferItem({
        transferableType: selectableId.split('_')[0],
        transferableId: selectableId.split('_')[1],
      });
      transfer.factorTransferItems.push(newItem);
    });

    const success = await transfer.save({
      with: ['factorTransferItems'],
    });
    if (success) {
      toast.success(
        <Toaster
          textMsg={t('views.shared.change_applied')}
          toastType="success"
        />,
      );
      setIsLoading(false);
      navigate(`/v2/admin/factor_transfers/${transfer.id}`);
    } else {
      setIsLoading(false);
      const apiErrors = [];
      if (Object.keys(transfer.errors).length > 0)
        apiErrors.push(transfer.errors);
      compact(apiErrors).forEach((e) => {
        toast.error(<Toaster textMsg={String(e)} toastType="error" />);
      });
    }
  };

  return (
    <MainCard header={t('front.menu.factor_transfers.new_rebate')}>
      <Select
        placeholder="Factoring contract"
        options={factoringContracts?.map((c) => ({
          value: c.id,
          label: c.legalEntity.name,
        }))}
        value={factoringContractOption}
        onChange={setFactoringContractOption}
        {...props}
      />
      {factoringContractId && (
        <>
          <MultiSelectTable
            onChange={setSelectedIds}
            value={selectedIds}
            queryResult={queryResult}
            reactTableProps={props}
            columns={columns}
          />
          <ButtonPrimary
            className="mt-[10px]"
            onClick={onSubmit}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Créer
          </ButtonPrimary>
        </>
      )}
    </MainCard>
  );
};

export default NewFactorTransfer;
