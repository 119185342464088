import RegisteredClient from '@/app/spraypaint/RegisteredClient';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { Client } from '@/app/spraypaint';
import Table from '@/modules/common/components/table/Table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import registeredClientClientsColumns from '../functions/registeredClientClientsColumns';
import useRegisteredClientClients from '../hooks/useRegisteredClientClients';

const RegisteredClientClients = ({
  registeredClient,
}: {
  registeredClient: RegisteredClient;
}) => {
  const [scope, , props] = useBackendTable<Client>();
  const { clients, isLoading, totalCount } = useRegisteredClientClients(
    Number(registeredClient.id),
    scope,
    props,
  );

  return (
    <Table
      className="mt-[20px]"
      isLoading={isLoading}
      totalCount={totalCount}
      data={clients || []}
      reactTableProps={props}
      onRowClick={{
        navigate: (client: ModelRecord<Client>) =>
          `/employees/${client.owner?.id}`,
      }}
      withImageErrorMsg={false}
      emptyTableErrorMsg=""
      columns={registeredClientClientsColumns()}
    />
  );
};

export default RegisteredClientClients;
