import { Transition } from '@/app/spraypaint';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Toaster } from '../components/toasts';

export const useCreateTransition = (onSuccess?: () => void) => {
  const { mutate, isLoading, ...others } = useMutation({
    mutationFn: async ({
      transitionName,
      resourceId,
      resourceType,
      transitionParams,
    }: {
      resourceId?: string;
      resourceType: string;
      toStep?: string;
      transitionName: string;
      transitionParams?: { comment: string };
    }) => {
      const transition = new Transition({
        resourceId,
        resourceType,
        transition_name: transitionName,
        transition_params: transitionParams,
      });
      const success = await transition.save();
      if (!success) {
        throw new Error('Transition failed');
      }
      return { transitionName };
    },
    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
    onError: () =>
      toast.error(
        <Toaster
          textMsg="Une erreur inattendu est apparu, contactez le support"
          toastType="error"
        />,
      ),
  });
  return { mutate, isLoading, ...others };
};
