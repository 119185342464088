import { useTranslation } from 'react-i18next';
import { MainCard } from '../../../common/components/mainCard';
import { HoursDistribution } from '../../../common/utils/type.utils';
import { IconButton } from '../../../common/components/buttons';
import { CraRecapCardSection } from '../CraRecapCardSection';
import { BtnAgenda } from '../../../../app/assets/icons/inputs';

export type CraRecapCardProps = {
  hoursDistribution?: HoursDistribution;
  handleModal?: any;
};

export const CraRecapCard = ({
  hoursDistribution = {
    worked_days: 0,
    worked_hours: 0,
    holidays_days: 0,
    holidays_hours: 0,
    absence_days: 0,
    absence_hours: 0,
    overtime_hours: 0,
    holidays_days_remaining: 0,
  },
  handleModal,
}: CraRecapCardProps) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-console

  return (
    <MainCard
      className="md:h-[182px]"
      header={t('front.payslip.show.CraRecapCard.title')}
      noPadding
    >
      <div className="flex-raw md:flex md:h-[99px] mb-[5px]">
        <CraRecapCardSection
          label={t('front.payslip.show.CraRecapCard.worked_days')}
          value={hoursDistribution?.worked_days}
          subValue={hoursDistribution?.worked_hours}
        />

        <CraRecapCardSection
          label={t('front.payslip.show.CraRecapCard.holidays_days')}
          value={hoursDistribution?.holidays_days}
          subValue={hoursDistribution?.holidays_hours}
        />

        <CraRecapCardSection
          label={t('front.payslip.show.CraRecapCard.absence_days')}
          value={hoursDistribution?.absence_days}
          subValue={hoursDistribution?.absence_hours}
        />

        <CraRecapCardSection
          label={t('front.payslip.show.CraRecapCard.overtime_hours')}
          value={hoursDistribution?.overtime_hours}
        />

        <CraRecapCardSection
          label={t('front.payslip.show.CraRecapCard.holidays_days_remaining')}
          value={hoursDistribution?.holidays_days_remaining}
        />
        <CraRecapCardSection
          label={t('front.payslip.show.CraRecapCard.detailed_cra')}
          value={
            <IconButton
              Icon={BtnAgenda}
              onClick={handleModal}
              className="w-[25px] h-[28px] fill-color-medium-grey hover:fill-color-pone-orange"
            />
          }
        />
      </div>
    </MainCard>
  );
};
