import { ApplicationRecord } from '@/app/spraypaint';
import Table, { ColumnDefs } from '@/modules/common/components/table/Table';
import { UseQueryResult } from '@tanstack/react-query';
import {
  ColumnFiltersState,
  OnChangeFn,
  RowData,
  RowSelectionState,
  TableOptions,
} from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { CollectionProxy } from 'spraypaint/lib-esm/proxies';

type MultiSelectTableProps<TData extends RowData & ApplicationRecord> = {
  onChange: (value: Array<NonNullable<TData['id']>>) => void;
  value: Array<TData['id']>;
  queryResult: UseQueryResult<CollectionProxy<TData>>;
  reactTableProps: Partial<TableOptions<ModelRecord<TData>>>;
  columns: ColumnDefs<ModelRecord<TData>>;
};

function MultiSelectTable<TData extends RowData & ApplicationRecord>({
  onChange,
  value,
  queryResult,
  reactTableProps,
  columns,
}: MultiSelectTableProps<TData>) {
  const resetSelection = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const { data, isLoading } = queryResult;

  const totalCount = data?.meta?.stats?.total?.count;
  const records = data?.data ?? [];

  // Convert value to row selection state for Table
  const rowSelectionState = useMemo(
    () =>
      (value ?? []).reduce((acc, id) => {
        if (!id) return acc;
        acc[id] = true;
        return acc;
      }, {} as RowSelectionState),
    [value],
  );

  // Convert row selection state from Table to onChange callback
  const onRowSelectionChange: OnChangeFn<RowSelectionState> = useCallback(
    (stateUpdater) => {
      let newState: RowSelectionState;
      if (typeof stateUpdater !== 'function') {
        newState = stateUpdater;
      } else {
        newState = stateUpdater(rowSelectionState);
      }
      const newIds = Object.entries(newState)
        .filter(([, isSelected]) => isSelected)
        .map(([id]) => id);
      onChange(newIds);
    },
    [onChange, rowSelectionState],
  );

  // Reset selection on column filters change and trigger initial callback if defined
  const onColumnFiltersChange: OnChangeFn<ColumnFiltersState> = useCallback(
    (...args) => {
      resetSelection();
      if (!reactTableProps.onColumnFiltersChange) return;
      reactTableProps.onColumnFiltersChange(...args);
    },
    [reactTableProps.onColumnFiltersChange, resetSelection],
  );

  return (
    <Table
      className="mt-[20px]"
      emptyTableErrorMsg="react_table.no_data_text"
      isLoading={isLoading}
      withImageErrorMsg={false}
      totalCount={totalCount}
      data={records}
      reactTableProps={{
        ...reactTableProps,
        state: {
          ...reactTableProps.state,
          rowSelection: rowSelectionState,
        },
        onRowSelectionChange,
        onColumnFiltersChange,
      }}
      columns={columns}
      enableRowSelection
    />
  );
}

export default MultiSelectTable;
