import { RoutesMounter } from '@/types';
import { member, index, redirectTo } from '@/app/navigation/functions';
import { AuthLayout } from '@/modules/layout/AuthLayout';
import { buildUnauthenticatedLoader } from '@/app/navigation/loaders/buildUnauthenticatedLoader';
import {
  ForgotPasswordIndex,
  InnerLoginFormName,
  LoginIndex,
  PasswordEdit,
} from '@/modules/authentification/pages';
import { featureFlagLoader as buildFeatureFlagLoader } from '@/modules/feature_flag/useFeatureFlag';
import { defer } from 'react-router-dom';
import { VerifyAccount } from '@/modules/authentification/pages/VerifyAccount';
import { Register } from '@/modules/authentification/pages/Register';
import { EmailCode } from '@/modules/authentification/pages/EmailCode';
import { useLogout } from '@/modules/authentification/hooks';
import { FC, useEffect } from 'react';
import { buildFeatureFlagProtectionLoader } from '@/app/navigation/loaders/buildFeatureFlagProtectionLoader';

const LogoutComponent: FC = () => {
  const { mutate } = useLogout();

  useEffect(() => {
    mutate();
  }, []);

  return null;
};

export const mountAuthenticationRoutes: RoutesMounter<unknown> = ({
  path,
  tools,
}) => {
  const twoStepAuthProtectionLoader = buildFeatureFlagProtectionLoader(
    'two_steps_auth',
    {
      queryClient: tools.queryClient,
      redirectPathBuilder: `/v2/${path}/login`,
    },
  );

  return [
    member({ path: `${path}/logout`, Component: LogoutComponent }),
    member({
      path,
      element: <AuthLayout />,
      loader: buildUnauthenticatedLoader(tools),
      handle: { crumb: () => 'Auth' },
      children: [
        index({ loader: redirectTo('./login') }),
        member({
          path: 'login',
          Component: LoginIndex,
          // Load the feature flag to determine which login form to display
          loader: (args) => {
            const featureFlagLoader = buildFeatureFlagLoader(
              tools.queryClient,
              'two_steps_auth',
              null,
            );

            const innerComponentPromise: Promise<InnerLoginFormName> =
              featureFlagLoader(args).then((rep) =>
                rep.data.data.enabled
                  ? 'TwoStepsLoginForm'
                  : 'OneStepLoginForm',
              );

            return defer({ innerComponentName: innerComponentPromise });
          },
        }),
        member({ path: 'logout', Component: LogoutComponent }),
        member({
          path: 'register',
          Component: Register,
          loader: twoStepAuthProtectionLoader,
        }),
        member({
          path: 'verify_account',
          Component: VerifyAccount,
          loader: twoStepAuthProtectionLoader,
        }),
        member({
          path: 'email_code',
          Component: EmailCode,
          loader: twoStepAuthProtectionLoader,
        }),
        member({ path: 'forgot_password', Component: ForgotPasswordIndex }),
        member({ path: 'reset_password/:key', Component: PasswordEdit }),
      ],
    }),
  ];
};
