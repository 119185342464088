import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MainCard } from '@/modules/common/components/mainCard';
import { LineText } from '@/modules/common/components/fragments/LineText';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { DownloadIcon } from '@/app/assets/icons/buttons';
import { handleDownloadFile } from '@/modules/common/components/buttons/utils/DownloadFunction';
import dayjs from 'dayjs';
import { useGetPayslipSimulation } from '../hooks/useGetPayslipSimulation';

export const SimulationShow: FunctionComponent = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { payslipSimulation } = useGetPayslipSimulation(id as string);

  const isOptimizationValue = ['net_salary', 'gross_salary'].includes(
    payslipSimulation?.optimizationName || '',
  );

  return (
    <PageLayout
      Title={t('front.welcome.simulations.show.title', {
        employmentType: payslipSimulation?.employmentType,
      })}
    >
      <MainCard
        header={t('front.welcome.simulations.show.parameters')}
        className="mr-5"
      >
        <LineText
          label={t('front.welcome.simulations.show.date')}
          value={
            dayjs(payslipSimulation?.createdAt as string).format('L - HH:mm') ||
            '-'
          }
        />
        <LineText
          label={t(
            'activerecord.attributes.payslip_simulation.employment_type',
          )}
          value={payslipSimulation?.employmentType}
        />
        {isOptimizationValue && (
          <LineText
            label={t(
              'activerecord.attributes.payslip_simulation.optimization_name',
            )}
            value={payslipSimulation?.optimizationName}
          />
        )}
        {isOptimizationValue && (
          <LineText
            label={t(
              'activerecord.attributes.payslip_simulation.optimization_value',
            )}
            value={
              <AmountWithCurrency
                amount={payslipSimulation?.optimizationValue || 0}
                currencyIsoCode="EUR"
              />
            }
          />
        )}
        {!isOptimizationValue && (
          <LineText
            label={t('activerecord.attributes.payslip_simulation.rate_of_pay')}
            value={
              <AmountWithCurrency
                amount={payslipSimulation?.rateOfPay || 0}
                currencyIsoCode="EUR"
              />
            }
          />
        )}
        <LineText
          label={t(
            'activerecord.attributes.payslip_simulation.monthly_expenses',
          )}
          value={
            <AmountWithCurrency
              amount={payslipSimulation?.monthlyExpenses || 0}
              currencyIsoCode="EUR"
            />
          }
        />
        <LineText
          label={t(
            'activerecord.attributes.payslip_simulation.health_insurance_package',
          )}
          value={t(
            `views.payslip_simulation.health_insurance_package.${payslipSimulation?.healthInsurancePackage}`,
          )}
        />
        <LineText
          label={t('activerecord.attributes.payslip_simulation.meal_voucher')}
          value={t(
            `choices.${payslipSimulation?.mealVoucher ? 'true' : 'false'}`,
          )}
        />
        <LineText
          label={t(
            'activerecord.attributes.payslip_simulation.paid_vacation_provision_in_gross_salary',
          )}
          value={t(
            `choices.${
              payslipSimulation?.paidVacationProvisionInGrossSalary
                ? 'true'
                : 'false'
            }`,
          )}
        />

        {!isOptimizationValue && (
          <LineText
            label={t(
              'activerecord.attributes.payslip_simulation.working_time_unit',
            )}
            value={t(
              `views.payslip_simulation.working_time_unit.${payslipSimulation?.workingTimeUnit}`,
            )}
          />
        )}
        <LineText
          label={t('activerecord.attributes.payslip_simulation.securitis')}
          value={t(
            `activerecord.attributes.payslip_simulation.securitis_enum.${payslipSimulation?.securitis}`,
          )}
        />
        <LineText
          label={t(
            `activerecord.attributes.payslip_simulation.working_time_count${
              isOptimizationValue ? '_optimization' : ''
            }`,
          )}
          value={payslipSimulation?.workingTimeCount}
        />
        <LineText
          label={t('activerecord.attributes.payslip_simulation.month_duration')}
          value={payslipSimulation?.monthDuration}
        />
        <LineText
          label={t('activerecord.attributes.payslip_simulation.comment')}
          value={payslipSimulation?.comment}
        />
      </MainCard>

      <MainCard
        header={t('front.welcome.simulations.show.results')}
        className="mr-5"
      >
        {payslipSimulation?.generatedDocumentUrl && (
          <ButtonPrimary
            Icon={DownloadIcon}
            onClick={(event: React.MouseEvent<HTMLElement>): void => {
              const file = {
                name: payslipSimulation?.generatedDocumentName || '',
                url: payslipSimulation?.generatedDocumentUrl || '',
              };

              handleDownloadFile({ event, file });
            }}
          >
            {t('front.welcome.simulations.show.button.download')}
          </ButtonPrimary>
        )}

        <LineText
          label={t(
            'activerecord.attributes.payslip_computation.worked_hours_count',
          )}
          value={payslipSimulation?.payslipComputation?.worked_hours_count}
        />
        <LineText
          label={t(
            'activerecord.attributes.payslip_computation.monthly_revenue',
          )}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.monthly_revenue?.value ||
                0
              }
            />
          }
          style={{
            labelClassName: 'text-green-600',
            valueClassName: 'text-green-600',
          }}
        />
        <LineText
          label={t(
            'activerecord.attributes.payslip_computation.management_fee',
          )}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.management_fee.value || 0
              }
            />
          }
        />
        <LineText
          label={t(
            'activerecord.attributes.payslip_computation.amount_total_insurance_fiscal',
          )}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation
                  ?.amount_total_insurance_fiscal.value || 0
              }
            />
          }
        />
        <LineText
          label={t(
            'activerecord.attributes.payslip_computation.business_expenses',
          )}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.business_expenses
                  .value || 0
              }
            />
          }
        />
        {(payslipSimulation?.payslipComputation?.deductible_vat.value || 0) >
          0 && (
          <LineText
            label={t('views.payslip_simulation.deductible_vat')}
            value={
              <AmountWithCurrency
                amount={
                  payslipSimulation?.payslipComputation?.deductible_vat.value ||
                  0
                }
              />
            }
          />
        )}
        <LineText
          label={t(
            'activerecord.attributes.payslip_computation.available_balance',
          )}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation
                  ?.available_balance_without_payslip_guarantee.value || 0
              }
            />
          }
          style={{ labelClassName: 'font-bold', valueClassName: 'font-bold' }}
        />
        <LineText
          label={t(
            'activerecord.attributes.payslip_computation.employer_contributions',
          )}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.employer_contributions
                  .value || 0
              }
            />
          }
          style={{ labelClassName: 'font-bold', valueClassName: 'font-bold' }}
        />
        {!payslipSimulation?.payslipComputation
          ?.paid_vacation_provision_in_gross_salary && (
          <LineText
            label={t(
              'views.payslip.gross_salary_composition_small_case.paid_holidays_compensation_dixiemes_provision',
            )}
            value={
              <AmountWithCurrency
                amount={
                  payslipSimulation?.payslipComputation?.paid_vacation_provision
                    .value || 0
                }
              />
            }
            style={{ labelClassName: 'font-bold', valueClassName: 'font-bold' }}
          />
        )}
        <LineText
          label={t('activerecord.attributes.payslip_computation.gross_salary')}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.gross_salary.value || 0
              }
            />
          }
          style={{
            labelClassName: 'text-green-600',
            valueClassName: 'text-green-600',
          }}
        />
        <LineText
          label={t('activerecord.attributes.payslip_computation.base_salary')}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.base_salary.value || 0
              }
            />
          }
          style={{ labelClassName: 'pl-[12px]' }}
        />
        <LineText
          label={t('views.payslip_computation.available_allowance')}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.allowance_provision
                  .value || 0
              }
            />
          }
          style={{ labelClassName: 'pl-[12px]' }}
        />
        <LineText
          label={t(
            'activerecord.attributes.payslip_computation.additional_allowance',
          )}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.additional_allowance
                  .value || 0
              }
            />
          }
          style={{
            labelClassName: 'pl-[24px] text-gray-400',
            valueClassName: 'text-gray-400',
          }}
        />
        <LineText
          label={t(
            'activerecord.attributes.payslip_computation.business_finder_allowance',
          )}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.business_finder_allowance
                  .value || 0
              }
            />
          }
          style={{
            labelClassName: 'pl-[24px] text-gray-400',
            valueClassName: 'text-gray-400',
          }}
        />
        {payslipSimulation?.employmentType === 'CDI' && (
          <LineText
            label={t(
              'views.payslip.gross_salary_composition_small_case.financial_reserve',
            )}
            value={
              <AmountWithCurrency
                amount={
                  payslipSimulation?.payslipComputation?.financial_provision
                    .value || 0
                }
              />
            }
            style={{
              labelClassName: 'pl-[24px] text-gray-400',
              valueClassName: 'text-gray-400',
            }}
          />
        )}
        {payslipSimulation?.paidVacationProvisionInGrossSalary && (
          <LineText
            label={t(
              'views.payslip.gross_salary_composition_small_case.paid_holidays_compensation_dixiemes',
            )}
            value={
              <AmountWithCurrency
                amount={
                  payslipSimulation?.payslipComputation?.paid_vacation_provision
                    .value || 0
                }
              />
            }
            style={{
              labelClassName: 'pl-[24px] text-gray-400',
              valueClassName: 'text-gray-400',
            }}
          />
        )}
        {payslipSimulation?.employmentType === 'CDD' && (
          <LineText
            label={t(
              'views.payslip.gross_salary_composition_small_case.contract_end_provision_cdd',
            )}
            value={
              <AmountWithCurrency
                amount={
                  payslipSimulation?.payslipComputation?.indemnite_fin_contrat
                    .value || 0
                }
              />
            }
            style={{ labelClassName: 'pl-[12px]' }}
          />
        )}
        <LineText
          label={t(
            'activerecord.attributes.payslip_computation.employee_contributions',
          )}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.employee_contributions
                  .value || 0
              }
            />
          }
          style={{ labelClassName: 'font-bold', valueClassName: 'font-bold' }}
        />
        <LineText
          label={t('activerecord.attributes.payslip_computation.net_salary')}
          value={
            <AmountWithCurrency
              amount={
                payslipSimulation?.payslipComputation?.net_salary_basis.value ||
                0
              }
            />
          }
          style={{
            labelClassName: 'text-green-600',
            valueClassName: 'text-green-600',
          }}
        />
      </MainCard>
    </PageLayout>
  );
};
