import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PeriodType } from '@/modules/common/utils/type.utils';
import { LoaderPage } from '@/app/navigation/components/LoaderPage';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import { Breadcrumbs } from '../../navigation/components/Breadcrumbs';
import { usePayslip } from '../hooks/usePayslip';
import { usePayslipDetails } from '../hooks/usePayslipDetails';
import { getMonthName, getPrepMonthName } from '../../common/utils/date.utils';
import { ButtonPrimary } from '../../common/components/buttons';
import {
  InvoicesStepDetails,
  CraStepDetails,
  ExpensesStepDetails,
  IntermediateSummaryStepDetails,
  SalaryStepDetails,
  FinalSummaryStepDetails,
} from './details_cards';

export type PayslipDetailsProps = {
  type: 'show' | 'funnel';
};

export const PayslipDetails = ({ type }: PayslipDetailsProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const employeeId = useCurrentEmployeeId();
  const { period, activityReport } = usePayslip(id as string);
  const {
    invoicesStep,
    craStep,
    isLoading,
    expensesStep,
    intermediateSummaryStep,
    salaryStep,
    finalSummaryStep,
    employmentContractId,
    stc,
  } = usePayslipDetails(id as string);

  const monthName = getMonthName(
    period?.year as number,
    period?.month as number,
  );
  const prepMonthName = getPrepMonthName(period as PeriodType);

  const [displayAll, setDisplayAll] = useState<boolean>(false);
  const contractRedirection = `/employees/${employeeId}/employment_contracts/${employmentContractId}`;

  const customClassNames =
    'hover:cursor-pointer text-sm3 text-color-pone-dark font-cabin-bold text-right';

  if (isLoading) return <LoaderPage className="h-[900px]" />;

  return (
    <div className="mt-[44px]">
      <div className="ml-5 mr-10">
        <div className="flex justify-between">
          <p className="text-xxl text-color-medium-grey">
            {type === 'funnel'
              ? (t('front.payslip.details.title', {
                  period: `${monthName} ${period?.year}`,
                  option: stc ? '(STC)' : '',
                }) as string)
              : `${t('views.payslip.model_name')} ${prepMonthName} ${
                  period?.year
                }`}
          </p>
          {employmentContractId && (
            <ButtonPrimary href={contractRedirection}>
              {t('front.payslip.details.header.button.title')}
            </ButtonPrimary>
          )}
        </div>
        <Breadcrumbs />
        {displayAll ? (
          <div className="flex justify-end">
            <span
              role="none"
              className={customClassNames}
              onClick={() => setDisplayAll(false)}
            >
              {t('front.mainCard.header.closeAll').toUpperCase()}
            </span>
          </div>
        ) : (
          <div className="flex justify-end">
            <span
              role="none"
              className={customClassNames}
              onClick={() => setDisplayAll(true)}
            >
              {t('front.mainCard.header.openAll').toUpperCase()}
            </span>
          </div>
        )}
      </div>

      <div className="flex flex-col">
        <InvoicesStepDetails
          invoicesStep={invoicesStep}
          displayAll={displayAll}
          isLoading={isLoading}
        />
        <CraStepDetails
          craStep={craStep}
          activityReport={activityReport}
          displayAll={displayAll}
        />
        <ExpensesStepDetails
          expensesStep={expensesStep}
          displayAll={displayAll}
          isLoading={isLoading}
        />
        <IntermediateSummaryStepDetails
          intermediateSummaryStep={intermediateSummaryStep}
        />
        <SalaryStepDetails salaryStep={salaryStep} displayAll={displayAll} />
        <FinalSummaryStepDetails finalSummaryStep={finalSummaryStep} />
      </div>
    </div>
  );
};
