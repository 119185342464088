// api/PayslipReview::Funnel.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
// eslint-disable-next-line import/no-cycle
import Payslip from '../Payslip';
import PayslipFunnelInvoicesStep from './PayslipFunnelInvoicesStep';
import PayslipFunnelCraStep from './PayslipFunnelCraStep';
import PayslipFunnelExpensesStep from './PayslipFunnelExpensesStep';
import PayslipFunnelIntermediateSummaryStep from './PayslipFunnelIntermediateSummaryStep';
import PayslipFunnelSalaryStep from './PayslipFunnelSalaryStep';
import PayslipFunnelFinalSummaryStep from './PayslipFunnelFinalSummaryStep';

@Model()
class PayslipReview extends ApplicationRecord {
  static jsonapiType = 'payslip_review-funnels';

  // Attributes
  @Attr({ persist: false }) payslipId!: number;
  @Attr({ persist: false }) employmentContractId!: number;
  @Attr({ persist: false }) stc!: boolean;
  @Attr({ persist: false }) invoicesStep!: PayslipFunnelInvoicesStep;
  @Attr({ persist: false }) craStep!: PayslipFunnelCraStep;
  @Attr({ persist: false }) expensesStep!: PayslipFunnelExpensesStep;
  @Attr({ persist: false })
  intermediateSummaryStep!: PayslipFunnelIntermediateSummaryStep;
  @Attr({ persist: false }) salaryStep!: PayslipFunnelSalaryStep;
  @Attr({ persist: false }) finalSummaryStep!: PayslipFunnelFinalSummaryStep;

  // Relationships
  @BelongsTo('payslips') payslip!: Payslip[];
}

export default PayslipReview;
