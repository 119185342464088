import type { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInvoice } from '@/modules/invoices/functions/invoice_query';
import InvoiceSkeleton from '@/modules/admin/invoices/components/InvoiceSkeleton';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { MainCard } from '@/modules/common/components/mainCard';

export interface CreateCreditProps {}

export const CreateCredit: FunctionComponent<CreateCreditProps> = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data } = useInvoice(id as string);

  const invoice = data?.data;
  if (!invoice) {
    return <InvoiceSkeleton />;
  }

  return (
    <PageLayout
      Title={t('front.invoices.create_credit.header.title', {
        number: invoice?.number,
      })}
    >
      <MainCard header="Créer un avoir">WIP</MainCard>
    </PageLayout>
  );
};
