import { useEffect } from 'react';
import { Mixin } from '@rails/actioncable';
import consumer from './consumer';

export function useChannelSubscription(
  channelName: string,
  params: { [key: string]: number | string | boolean | undefined },
  mixin: Mixin,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: Array<any>,
) {
  useEffect(() => {
    const subscription = consumer.subscriptions.create(
      { channel: channelName, ...params },
      {
        ...mixin,
      },
    );

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      consumer.disconnect();
    };
  }, [channelName, ...deps]);
}
