import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { ConstValues } from '@/types';
import useAuthClient, { AuthErrorResponseWithReason } from './useAuthClient';

export type VerifyAccountVariables = {
  // The user's email address
  login: string;
  key: string;
};

export type VerifyAccountData = {
  success: string;
  redirect: string;
};

export const VERIFY_ACCOUNT_REASON_CODES = {
  INVALID_KEY: 'invalid_verify_account_key',
} as const;

export type VerifyAccountReasonCode = ConstValues<
  typeof VERIFY_ACCOUNT_REASON_CODES
>;

type VerifyAccountError = AuthErrorResponseWithReason<VerifyAccountReasonCode>;

export const useVerifyAccount = (
  opts: UseMutationOptions<
    AxiosResponse<VerifyAccountData>,
    AxiosError<VerifyAccountError>,
    VerifyAccountVariables
  > = {},
) => {
  const authClient = useAuthClient();
  return useMutation({
    ...opts,
    mutationFn: (data) =>
      authClient.post<VerifyAccountData>('/verify-account', data),
  });
};
