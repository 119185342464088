import { Link, useMatches } from 'react-router-dom';
import { ChevronRightIcon } from '@/app/assets/icons/buttons';
import { TypedRouteObject } from '@/types';
import { Fragment } from 'react';

export type Crumb = {
  name: string;
  path: string;
};

export const Breadcrumbs = () => {
  const matches = useMatches();

  const crumbs: Crumb[] = [];

  matches.forEach((match) => {
    const handle = match.handle as
      | TypedRouteObject<unknown>['handle']
      | undefined;
    if (handle?.crumb && typeof handle.crumb === 'function') {
      const { params, data, pathname } = match;

      crumbs.push({
        name: handle.crumb({ params, data }),
        path: pathname,
      });
    }
  });

  const currentCrumb = crumbs.pop();

  if (!currentCrumb) return null;
  if (crumbs.length === 0) return null;

  return (
    <div className="flex items-center mb-3">
      <ChevronRightIcon key="chevron-right" className="rotate-180" />
      {crumbs.map(({ path, name }) => (
        <Fragment key={path}>
          <Link to={path}>{name}</Link>
          &nbsp;/&nbsp;
        </Fragment>
      ))}
      <p className="text-color-medium-grey ml-[5px]">{currentCrumb.name}</p>
    </div>
  );
};
