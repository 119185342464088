import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { HeaderMainCardText } from '../../../common/components/mainCard/components';
import PrintableLine from '../../types/PrintableLine';
import { PayslipElementLine } from '../PayslipElementLine';
import { LineMainCardText } from '../LineMainCardText';

export type SalaryCardProps = {
  grossSalary?: number;
  netBeforeTax?: number;
  leftToPayReal?: number;
  grossSalaryList: Array<PrintableLine>;
  netBeforeTaxList: Array<PrintableLine>;
  leftToPayRealList: Array<PrintableLine>;
};

export const SalaryCard = ({
  grossSalary = 0,
  netBeforeTax = 0,
  leftToPayReal = 0,
  grossSalaryList = [],
  netBeforeTaxList = [],
  leftToPayRealList = [],
}: SalaryCardProps) => {
  const { t } = useTranslation();
  const [displayAll, setDisplayAll] = useState(false);

  return (
    <>
      <HeaderMainCardText
        title={t('front.payslip.show.salaryCard.title')}
        handleOnClick={setDisplayAll}
        displayAll={displayAll}
        displayCollapseButton
      />
      <div className="pl-3 md:pl-5">
        <LineMainCardText
          label={t('front.payslip.show.salaryCard.grossSalary')}
          value={{ amount: grossSalary, currency: 'EUR' }}
          displayAll={displayAll}
          lineToDisplay
        >
          {grossSalaryList.map((line) => (
            <PayslipElementLine key={line.slug} printableLine={line} />
          ))}
        </LineMainCardText>
        <LineMainCardText
          label={t('front.payslip.show.salaryCard.netBeforeTaxes')}
          value={{ amount: netBeforeTax, currency: 'EUR' }}
          displayAll={displayAll}
          lineToDisplay
        >
          {netBeforeTaxList.map((line) => (
            <PayslipElementLine key={line.slug} printableLine={line} />
          ))}
        </LineMainCardText>
        <LineMainCardText
          label={t('front.payslip.show.salaryCard.leftToPay')}
          value={{ amount: leftToPayReal, currency: 'EUR' }}
          displayAll={displayAll}
          lineToDisplay
        >
          {leftToPayRealList.map((line) => (
            <PayslipElementLine key={line.slug} printableLine={line} />
          ))}
        </LineMainCardText>
      </div>
    </>
  );
};
