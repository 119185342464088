import { useQuery } from '@tanstack/react-query';
import { Country } from '@/app/spraypaint';
import { useTranslation } from 'react-i18next';

export const useCountries = () => {
  const { t } = useTranslation();
  const { data } = useQuery(['countries'], () => Country.per(500).all(), {
    staleTime: 1000 * 60 * 30,
    cacheTime: 0,
  });

  const countries = data?.data || [];

  const countriesOptions = countries.map((country: Country) => ({
    label: t(`views.countries.${country.slug}`),
    value: String(country.id),
  }));

  const nationalitiesOptions = countries.map((country: Country) => ({
    label: t(`views.nationalities.${country.slug}`),
    value: String(country.id),
  }));

  return {
    countries,
    countriesOptions,
    nationalitiesOptions,
  };
};

export const useCountry = (id: string) => {
  const { t } = useTranslation();
  const { countries } = useCountries();

  const country = countries.find((c) => String(c.id) === id);

  const countryName = country
    ? t(`views.countries.${country.slug}`)
    : 'Unknown';

  const nationality = country
    ? t(`views.nationalities.${country.slug}`)
    : 'Unknown';

  const countryCode = country?.code;

  return { countryName, nationality, countryCode };
};
