import { useQuery } from '@tanstack/react-query';
import { GraphitiScoper } from '@/app/spraypaint/types';
import EmployeePayslip from '@/app/spraypaint/EmployeePayslip';
import { FilterFormInputs } from '../types/FormTypes';

type Props = {
  watchAllField: FilterFormInputs;
  scope: GraphitiScoper<EmployeePayslip>;
  scopeKey: string[];
};

export const usePayslips = ({ scope, scopeKey, watchAllField }: Props) => {
  const whereClose = {
    status: watchAllField.status,
    period: {
      eq: { year: watchAllField.year, month: watchAllField.month },
    },
    request_age: watchAllField.requestAge,
    stc: watchAllField.stc,
    new: watchAllField.newPayslip,
    missing: watchAllField.missing,
    arbitration: watchAllField.arbitration,
    legal_entity_id: watchAllField.legalEntity,
  };

  const { data, isLoading, ...others } = useQuery(
    ['payslipsAdminIndex', scopeKey, ...Object.entries(watchAllField)],
    () => scope(EmployeePayslip.where(whereClose).scope()).all(),
    {
      cacheTime: 0,
    },
  );

  const totalCount = data?.meta.stats.total.count;
  const payslips = data?.data;

  return { ...data, payslips, isLoading, totalCount, ...others };
};
