import { Stepper } from '@/modules/navigation/components';
import { FieldValues } from 'react-hook-form';
import { Context, FunctionComponent } from 'react';
import {
  FormWizardContextType,
  useFormWizardContext,
} from '../functions/useFormWizardContext';

type FormWizardStepsProps<
  TFormData extends FieldValues,
  TKeys extends string,
> = {
  wizardContext: Context<FormWizardContextType<TFormData, TKeys>>;
};

export function FormWizardSteps<
  TFormData extends FieldValues,
  TKeys extends string,
>({
  wizardContext,
}: FormWizardStepsProps<TFormData, TKeys>): ReturnType<FunctionComponent> {
  const { currentPage, pages, goToPage } = useFormWizardContext(wizardContext);

  if (!currentPage) return null;

  const currentStepIndex = pages.findIndex((p) => p.key === currentPage.key);

  return (
    <Stepper
      currentStep={currentStepIndex}
      steps={pages.map((p) => p.title)}
      className="mb-10"
      onClick={(index) => () => {
        if (index < currentStepIndex) {
          const targetPage = pages[index];
          goToPage(targetPage);
        }
      }}
    />
  );
}
