import classNames from 'classnames';
import { DayOfTheWeek } from '../constants/constantsCra';
import { DateWithActivityReportType } from '../utils/getActivityReportSlots';
import { getIsDateInRange } from '../utils/getDatesRange';
import { SquareCra, SquareCraContainer } from './SquareCra';

export type Props = {
  month: number;
  dateWithActivityReportSlots: DateWithActivityReportType;
  className?: string;
  updateActivityReportSlot?: (date: Date) => () => void;
  expensesDates?: Array<string>;
  readonly?: boolean;
  rangeDates?: [string, string];
};

export const CalendarCra = ({
  month,
  className,
  updateActivityReportSlot,
  dateWithActivityReportSlots,
  expensesDates,
  readonly,
  rangeDates,
}: Props) => {
  const containerClassName = classNames(
    'flex flex-col max-w-[553px] ',
    className,
  );
  if (!dateWithActivityReportSlots) return null;
  return (
    <div className={containerClassName}>
      <div className="flex">
        {DayOfTheWeek().map((day) => (
          <SquareCraContainer
            key={day}
            className="text-base4 bg-color-transparent-medium-grey"
          >
            {day}
          </SquareCraContainer>
        ))}
      </div>
      <div className="flex flex-wrap">
        {dateWithActivityReportSlots.map((activityReport, index) => {
          const nextActivityReportSlot = dateWithActivityReportSlots[index + 1];

          const previousAcvitityReportSlot =
            dateWithActivityReportSlots[index - 1] !== undefined
              ? dateWithActivityReportSlots[index - 1]
              : undefined;

          const { date } = activityReport;
          const isDateCraDuringThisMonth = date?.getMonth() === month;
          const isDateInRange = getIsDateInRange(date, rangeDates);
          const isWeekEndDay = date.getDay() === 6 || date.getDay() === 0;
          const squareClassName = classNames('text-xl', {
            'bg-color-transparent-medium-grey': isWeekEndDay,
            'bg-white': !isWeekEndDay,
            'text-color-medium-grey':
              !isDateInRange || !isDateCraDuringThisMonth,
          });

          const onClick =
            readonly || !isDateCraDuringThisMonth || !isDateInRange
              ? undefined
              : updateActivityReportSlot;

          const squareCraKey = `${date.getDate()}_${activityReport.pm?.id}`;

          return (
            <SquareCra
              nextAcvitityReportSlot={nextActivityReportSlot}
              previousAcvitityReportSlot={previousAcvitityReportSlot}
              expensesDates={expensesDates}
              key={squareCraKey}
              onClick={onClick}
              pm={activityReport.pm}
              am={activityReport.am}
              date={date}
              className={squareClassName}
            />
          );
        })}
      </div>
    </div>
  );
};
