export const mapTypeToText: { [index: string]: string } = {
  paid_holidays: 'front.payslip.tdc.buttoncra.holiday',
  sickness: 'front.payslip.tdc.buttoncra.sick',
  working_days: 'front.payslip.tdc.buttoncra.workingDays',
  telework: 'front.payslip.tdc.buttoncra.telework',
  paid_parental_leave: 'front.payslip.tdc.buttoncra.parentalLeave',
  family_event: 'front.payslip.tdc.buttoncra.familyEvent',
  work_accident_absence: 'front.payslip.tdc.buttoncra.workAccident',
  parental_absence: 'front.payslip.tdc.buttoncra.parentalAbsence',
};
