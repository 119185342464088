import MultiSelectTable from '@/modules/common/components/inputs/components/MultiSelectTable';
import { MainCard } from '@/modules/common/components/mainCard';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { toast } from 'react-toastify';
import { Toaster } from '@/modules/common/components/toasts';
import { compact } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FactorTransferred from '@/app/spraypaint/factor/FactorTransferred';
import FactorTransferItem from '@spraypaint/factor/FactorTransferItem';
import useFactorTransfer from '../hooks/useFactorTransfer';
import factorTransferredColumns from '../functions/factorTransferredColumns';

const columns = factorTransferredColumns();

const RemoveFromFactorTransfer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const { factorTransfer } = useFactorTransfer({
    id: String(id),
    includes: ['factor_transfer_items'],
  });

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [scope, scopeKey, props] = useBackendTable<FactorTransferred>();
  const queryResult = useQuery({
    queryKey: ['factor_transferreds', scopeKey, id],
    queryFn: () =>
      scope(FactorTransferred.per(10)).where({ factorTransferId: id }).all(),
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    if (!factorTransfer) return;
    setIsLoading(true);
    factorTransfer.factorTransferItems = factorTransfer.factorTransferItems.map(
      (item) => {
        const itemCopy = new FactorTransferItem({ ...item });
        if (
          selectedIds.includes(
            `${item.transferableType.toLowerCase()}_${item.transferableId}`,
          )
        ) {
          itemCopy.isPersisted = true;
          itemCopy.isMarkedForDestruction = true;
        }
        return itemCopy;
      },
    );

    const success = await factorTransfer.save({
      with: ['factorTransferItems'],
    });

    if (success) {
      toast.success(
        <Toaster
          textMsg={t('views.shared.change_applied')}
          toastType="success"
        />,
      );
      setIsLoading(false);
      navigate(`/v2/admin/factor_transfers/${factorTransfer.id}`);
    } else {
      setIsLoading(false);
      const apiErrors = [];
      if (Object.keys(factorTransfer.errors).length > 0)
        apiErrors.push(factorTransfer.errors);
      compact(apiErrors).forEach((e) => {
        toast.error(<Toaster textMsg={String(e)} toastType="error" />);
      });
    }
  };

  return (
    <MainCard header="Retirer de la remise">
      {id && (
        <>
          <MultiSelectTable
            onChange={setSelectedIds}
            value={selectedIds}
            queryResult={queryResult}
            reactTableProps={props}
            columns={columns}
          />
          <ButtonPrimary
            className="mt-[10px]"
            onClick={onSubmit}
            disabled={isLoading}
            isLoading={isLoading}
          >
            Retirer
          </ButtonPrimary>
        </>
      )}
    </MainCard>
  );
};

export default RemoveFromFactorTransfer;
