import { useTranslation } from 'react-i18next';
import { PayslipDetailCard, AmountEntry } from '../../components';
import { RefundableExpensesTable } from './details_tables/RefundableExpensesTable';
import { RejectedExpensesTable } from './details_tables/RejectedExpensesTable';

export type ExpensesStepDetailsProps = {
  expensesStep: any;
  displayAll: boolean;
  isLoading: boolean;
};

export const ExpensesStepDetails = ({
  expensesStep,
  displayAll,
  isLoading,
}: ExpensesStepDetailsProps) => {
  const { t } = useTranslation();

  const details = (
    <div className="mt-[30px]">
      <RefundableExpensesTable
        expensesStep={expensesStep}
        isLoading={isLoading}
      />
      <RejectedExpensesTable
        expensesStep={expensesStep}
        isLoading={isLoading}
      />
    </div>
  );

  return (
    <PayslipDetailCard
      user={expensesStep?.completed_by_name}
      date={expensesStep?.completed_at}
      title={t('views.payslip_funnel.syntheses_step.expenses')}
      displayAll={displayAll}
      allDetail={details}
    >
      <div className="flex">
        <AmountEntry
          label={t('front.payslip.details.expense.totalToReimburse') as string}
          value={{
            amount: expensesStep?.refundable_amount,
          }}
        />
        <AmountEntry
          label={t('front.payslip.details.expense.totalToReject') as string}
          value={{
            amount: expensesStep?.to_reject_costs_total_amount,
          }}
        />
        <AmountEntry
          label={t('front.payslip.details.expense.totalCost') as string}
          value={{
            amount: expensesStep?.costs_total_amount,
          }}
        />
      </div>{' '}
    </PayslipDetailCard>
  );
};
