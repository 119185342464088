import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AmountText } from '../../common/components/fragments/AmountText';
import { EmptyCardSmall } from '../../../app/assets/icons/empty';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../../common/components/buttons';

export type EmptyDataProps = {
  className?: string;
  titleKey: string;
  buttonSecondaryKey: string;
  buttonPrimaryKey: string;
  handleValidateStep: () => void;
  amount?: number;
  isLoading?: boolean;
  redirectToUrl?: string;
  onClick?: any;
};

export const EmptyData = ({
  className = '',
  titleKey,
  buttonSecondaryKey,
  buttonPrimaryKey,
  handleValidateStep,
  amount,
  isLoading,
  onClick,
  redirectToUrl,
}: EmptyDataProps) => {
  const { t } = useTranslation();

  const customClassName = classNames('flex flex-col', className);

  return (
    <div className={customClassName}>
      <div className="flex">
        <EmptyCardSmall width={102} height={124} />
        <div className="flex flex-col text-sm2 mt-[50px] ml-[14px]">
          <p>{t(titleKey)}</p>
          {amount ||
            (amount === 0 && (
              <AmountText
                className="text-xl"
                amount={amount}
                currencyIsoCode="EUR"
              />
            ))}
        </div>
      </div>
      <div className="flex flex-col md:flex-row mt-8">
        <ButtonSecondary
          className="w-[196px]"
          onClick={onClick}
          href={redirectToUrl as string}
        >
          {t(buttonSecondaryKey)}
        </ButtonSecondary>
        <ButtonPrimary
          className="w-[252px] mt-3 md:mt-0 md:ml-4"
          onClick={handleValidateStep}
          isLoading={isLoading}
        >
          {t(buttonPrimaryKey)}
        </ButtonPrimary>
      </div>
    </div>
  );
};
