import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { CurrencyIsoCode, MoneyType } from '../../common/utils/type.utils';

export type DisplayedLineValueType = {
  value: MoneyType | number;
  customTextClass?: string;
  customCurrencyClass?: string;
  isSpecificCard?: boolean;
};

export const DisplayedLineValue = ({
  value,
  customTextClass,
  customCurrencyClass,
  isSpecificCard,
}: DisplayedLineValueType) => {
  const renderAmountWithCurrency = (
    amount: number,
    currency?: CurrencyIsoCode,
  ) => (
    <AmountWithCurrency
      amount={amount}
      currencyIsoCode={currency}
      spanClass={customTextClass}
      currencyClass={customCurrencyClass}
    />
  );

  const renderValue = (val: number | MoneyType | undefined) => {
    if (typeof val === 'object' && 'amount' in val && 'currency' in val) {
      return renderAmountWithCurrency(val.amount, val.currency);
    }

    if (typeof val === 'number' || (isSpecificCard && val !== undefined)) {
      return renderAmountWithCurrency(val as number);
    }

    return <div className={customTextClass}>{val as unknown as number}</div>;
  };

  return renderValue(value);
};
