import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import ActivityAccountLineGroup from '@/app/spraypaint/activityAccount/ActivityAccountLineGroup';

import ActivityAccountLine from '@/app/spraypaint/activityAccount/ActivityAccountLine';
import {
  PeriodFilter,
  getPeriodFilterFn,
} from '../../../common/components/table/filter';
import {
  SupInfFilter,
  getSupInfFilter,
} from '../../../common/components/table/filter/SupInfFilter';

const columnHelper =
  createColumnHelper<
    ModelRecord<ActivityAccountLineGroup | ActivityAccountLine>
  >();

export const activityAccountColumns = () => {
  const { t } = useTranslation();

  return [
    columnHelper.accessor('date', {
      header: t('front.activityAccount.table.date'),
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    columnHelper.accessor('label', {
      header: t('front.activityAccount.table.label'),
    }),
    columnHelper.accessor('value', {
      header: t('front.activityAccount.table.amount'),
      filterFn: getSupInfFilter(),
      meta: {
        filter: {
          Component: SupInfFilter,
        },
      },
    }),
  ];
};
