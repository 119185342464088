import classNames from 'classnames';
import { FunctionComponent, ReactNode, useState } from 'react';
import { HeaderMainCardText, HeaderMainCardTextProps } from './components';
import { LoaderPage } from '../../../../app/navigation/components/LoaderPage';

export type HeaderProps = Pick<
  HeaderMainCardTextProps,
  'title' | 'Icon' | 'actionButtons' | 'headerClassNames' | 'textClassNames'
>;

function extractHeaderProps(
  object: ReactNode | HeaderProps | undefined,
): HeaderProps | undefined {
  if (!object) return undefined;
  if (typeof object !== 'object') return { title: object };
  if (Object.hasOwn(object, 'title')) return object as HeaderProps;
  return { title: object as ReactNode };
}

export type Props<ContentProps extends object = object> = {
  header?: ReactNode | HeaderProps;
  children?: ReactNode;
  className?: string;
  childrenClassName?: string;
  collapsable?: boolean;
  noPadding?: boolean;
  isLoading?: boolean;
  mountCollapsed?: boolean;
  Content?: FunctionComponent<ContentProps>;
  contentProps?: ContentProps;
};

export const MainCard = <ContentProps extends object = object>({
  header,
  children,
  className,
  childrenClassName = '',
  collapsable = false,
  noPadding = false,
  mountCollapsed = false,
  isLoading,
  Content,
  contentProps,
}: Props<ContentProps>) => {
  const headerProps = extractHeaderProps(header);

  const [displayContent, setDisplayContent] = useState(!mountCollapsed);

  const customClassName = classNames(
    'shadow-[0_0_20px_0_rgba(0,0,0,0.11)] rounded flex-col mb-5',
    noPadding || !displayContent ? '' : 'pb-10',
    className,
  );

  const customChildrenClassName = classNames(
    noPadding ? '' : 'px-3',
    childrenClassName,
  );

  if (isLoading) return <LoaderPage />;

  const ActualContent: FunctionComponent<ContentProps> =
    // eslint-disable-next-line react/jsx-no-useless-fragment
    Content || (() => <>{children}</>);

  return (
    <div className={customClassName}>
      {headerProps && (
        <HeaderMainCardText
          title={headerProps.title}
          Icon={headerProps.Icon}
          actionButtons={headerProps.actionButtons}
          headerClassNames={headerProps.headerClassNames}
          textClassNames={headerProps.textClassNames}
          displayCollapseButton={collapsable}
          displayAll={displayContent}
          handleOnClick={setDisplayContent}
        />
      )}
      {displayContent ? (
        <div className={customChildrenClassName}>
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <ActualContent {...contentProps} />
          }
        </div>
      ) : null}
    </div>
  );
};
