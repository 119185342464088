import { RoutesMounter } from '../../types';
import ApiConsole from './pages/apiConsole';

export const mountDebugRoutes: RoutesMounter = ({ path }) => {
  if (process.env.NODE_ENV !== 'development') {
    return [];
  }

  return [{ path, children: [{ path: 'api-console', Component: ApiConsole }] }];
};
