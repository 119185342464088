import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PeriodType } from '@/modules/common/utils/type.utils';
import { RoutesMounter } from '@/types';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import {
  PayslipDetails,
  PayslipIndex,
  PayslipJson,
  PayslipShow,
} from './pages';
import { usePayslip } from './hooks/usePayslip';
import { periodToMonthYear } from '../common/utils/date.utils';
import { ChevronRightIcon } from '../../app/assets/icons/buttons';
import redirectTo from '../../app/navigation/loaders/redirectTo';
import { PayslipFunnel } from '../payslip_funnel/PayslipFunnel';

export const mountPayslipsRoutes: RoutesMounter = ({ path }) => [
  {
    path,
    children: [
      {
        path: ':id',
        Component: PayslipShow,
        handle: {
          crumb: () => {
            const employeeId = useCurrentEmployeeId();
            const { t } = useTranslation();
            const { id } = useParams();
            const { period, isLoading } = usePayslip(id as string);
            const monthYearString = `${periodToMonthYear(
              period as PeriodType,
            )} ${period?.year}`;
            if (isLoading) return <div className="h-[24px]" />;
            return (
              <div className="flex items-center">
                <ChevronRightIcon className="rotate-180" />
                <Link to={`/v2/employees/${employeeId}/payslips`}>
                  {t('front.breadcrumbs.payslips')} /
                </Link>
                <p className="text-color-medium-grey ml-[5px]">
                  {monthYearString}
                </p>
              </div>
            );
          },
        },
      },
      {
        path: ':id/details',
        element: <PayslipDetails type="funnel" />,
        handle: {
          crumb: () => {
            const employeeId = useCurrentEmployeeId();
            const { id } = useParams();
            const { t } = useTranslation();
            const { period, isLoading } = usePayslip(id as string);
            const monthYearString = `${periodToMonthYear(
              period as PeriodType,
            )} ${period?.year}`;
            if (isLoading) return <div className="h-[24px]" />;
            return (
              <div className="flex items-center">
                <ChevronRightIcon className="rotate-180" />
                <Link to={`/v2/employees/${employeeId}/payslips`}>
                  {t('front.breadcrumbs.payslips')} /
                </Link>
                <Link
                  className="ml-[5px]"
                  to={`/v2/employees/${employeeId}/payslips/${id}`}
                >
                  {monthYearString} /
                </Link>
                <p className="text-color-medium-grey ml-[5px]">
                  {t('front.breadcrumbs.payslips.details')}
                </p>
              </div>
            );
          },
        },
      },
      {
        path: ':id/download_json',
        Component: PayslipJson,
        handle: {
          crumb: () => {
            const employeeId = useCurrentEmployeeId();
            const { id } = useParams();
            const { t } = useTranslation();
            const { period, isLoading } = usePayslip(id as string);
            const monthYearString = `${periodToMonthYear(
              period as PeriodType,
            )} ${period?.year}`;
            if (isLoading) return <div className="h-[24px]" />;
            return (
              <div className="flex items-center">
                <ChevronRightIcon className="rotate-180" />
                <Link to={`/v2/employees/${employeeId}/payslips`}>
                  {t('front.breadcrumbs.payslips')} /
                </Link>
                <Link
                  className="ml-[5px]"
                  to={`/v2/employees/${employeeId}/payslips/${id}`}
                >
                  {monthYearString} /
                </Link>
                <p className="text-color-medium-grey ml-[5px]">
                  {t('front.breadcrumbs.payslips.download_json')}
                </p>
              </div>
            );
          },
        },
      },
      {
        path: ':id/admin',
        loader: redirectTo(
          (params) =>
            `/employees/${params.current_employee}/payslips/${params.id}/admin`,
        ),
      },
      { path: 'create', Component: PayslipFunnel },
      { index: true, Component: PayslipIndex },
    ],
  },
];
