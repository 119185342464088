import { RoutesMounter } from '@/types';
import { index, member } from '@/app/navigation/functions';
import FactorTransfers from './pages/FactorTransfers';
import FactorTransfer from './pages/FactorTransfer';
import NewFactorTransfer from './pages/NewFactorTransfer';
import AddToFactorTransfer from './pages/AddToFactorTransfer';
import RemoveFromFactorTransfer from './pages/RemoveFromFactorTransfer';

export const mountFactorTransfersRoutes: RoutesMounter<unknown> = ({
  path,
}) => [
  member({
    path,
    handle: { crumb: () => 'Affacturage' },
    children: [
      index({ index: true, Component: FactorTransfers }),
      { path: ':id', Component: FactorTransfer },
      { path: ':id/add', Component: AddToFactorTransfer },
      { path: ':id/remove', Component: RemoveFromFactorTransfer },
      { path: 'new', Component: NewFactorTransfer },
    ],
  }),
];
