// api/ActivityReportSlot.ts
/* eslint-disable import/no-cycle */
import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import ActivityReport from './ActivityReport';

@Model()
class ActivityReportSlot extends ApplicationRecord {
  static jsonapiType = 'activity_report_slots';

  // Attributes
  @Attr({ persist: false }) dayPart!: string;
  @Attr({ persist: false }) date!: string;
  @Attr() slotType!: string;
  @Attr({ persist: false }) activityReportId!: number;

  // Relationships
  @BelongsTo('activity_reports') activityReport!: ActivityReport[];
}

export default ActivityReportSlot;
