import { createColumnHelper } from '@tanstack/react-table';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { MoneyType } from '@/modules/common/utils/type.utils';
import FactorTransferred from '@/app/spraypaint/factor/FactorTransferred';
import {
  SupInfFilter,
  getSupInfFilter,
} from '../../../../common/components/table/filter/SupInfFilter';
import {
  getPeriodFilterFn,
  PeriodFilter,
} from '../../../../common/components/table/filter';

const columnHelper = createColumnHelper<ModelRecord<FactorTransferred>>();

export const factorTransferredColumns = [
  columnHelper.accessor('transferableType', {
    // filter working but return two lines: the chosen id and the next one
    header: 'Type',
    cell: (info) => (
      <div className="justify-center">
        {info.getValue() === 'Invoice' ? 'Facture' : 'Credit'}
      </div>
    ),
    enableSorting: false,
  }),
  columnHelper.accessor('number', {
    header: 'Numéro',
  }),
  columnHelper.accessor('date', {
    // filter working
    filterFn: getPeriodFilterFn(),
    meta: {
      filter: {
        Component: PeriodFilter,
      },
    },
    header: 'Date',
    cell: (info) => (
      <div className="text-left">
        <p>{info.getValue()?.toString()}</p>
      </div>
    ),
  }),
  columnHelper.accessor('clientName', {
    // filter working
    header: 'Client',
    cell: (info) => (
      <div className="text-left">
        <p>{info.getValue()}</p>
      </div>
    ),
  }),
  columnHelper.accessor('euroHtAmount', {
    // filter working
    header: 'HT',
    filterFn: getSupInfFilter(),
    meta: {
      filter: {
        Component: SupInfFilter,
      },
    },
    cell: (info) => (
      <div className="text-left">
        <AmountWithCurrency amount={info.getValue() as MoneyType} />
      </div>
    ),
  }),
  columnHelper.accessor('euroTtcAmount', {
    // filter working
    header: 'TTC',
    filterFn: getSupInfFilter(),
    meta: {
      filter: {
        Component: SupInfFilter,
      },
    },
    cell: (info) => (
      <div className="text-left">
        <AmountWithCurrency amount={info.getValue() as MoneyType} />
      </div>
    ),
  }),
  columnHelper.accessor('status', {
    header: 'Statut',
  }),
];
