import { useTranslation } from 'react-i18next';
import { PerkCard } from '../components/PerkCard';
import { employeePerksItems } from '../constants/ConstantsPerks';

export const PerkIndex = () => {
  const { t } = useTranslation();

  return (
    <div className="my-[50px] mx-5">
      <div className="flex justify-between px-5">
        <p className="text-xxl text-color-medium-grey">
          {t('front.perk.index.header.title')}
        </p>
      </div>
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-6">
        {employeePerksItems().map((perk) => (
          <div key={perk.title} className="col-span-1">
            <PerkCard
              title={perk.title}
              description={perk.description}
              url={perk?.url}
              image={perk.image}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
