import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import type MultipartOperationOperation from './MultipartOperationOperation';

export type StatusEnum =
  | 'created'
  | 'scheduled'
  | 'running'
  | 'success'
  | 'error';

@Model()
class MultipartOperationPart extends ApplicationRecord {
  static jsonapiType = 'multipart_operation-parts';

  // Attributes
  @Attr({ persist: false }) status!: StatusEnum;
  @Attr({ persist: false }) name!: string;
  @Attr({ persist: false }) operationId!: number;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) logFileUrl!: string;

  // Relationships
  @BelongsTo('multipart_operation-operations')
  operation!: MultipartOperationOperation;
}

export default MultipartOperationPart;
