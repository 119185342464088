import { Table } from '@tanstack/react-table';
import { CSSProperties } from 'react';

export function ColgroupRow<TData>({ table }: { table: Table<TData> }) {
  return (
    <colgroup>
      {table.getHeaderGroups().map((headerGroup) =>
        headerGroup.headers.map((header) => {
          const style: CSSProperties = {};
          style.minWidth = header.getSize();

          if (header.column.columnDef.meta?.fixedWidth) {
            style.width = header.getSize();
          }

          return (
            <col span={header.colSpan} key={`col-${header.id}`} style={style} />
          );
        }),
      )}
    </colgroup>
  );
}
