import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { ConstValues } from '@/types';
import useAuthClient, { AuthErrorResponseWithReason } from './useAuthClient';

export type EmailAuthRequestVariables = {
  // The user's email address
  login: string;
};

export type EmailAuthRequestData = {
  success: string;
};

export const EMAIL_AUTH_REASON_CODES = {
  EMAIL_AUTH_DISABLED: 'email_auth_disabled',
  NO_MATCHING_LOGIN: 'no_matching_login',
} as const;

export type EmailAuthReasonCode = ConstValues<typeof EMAIL_AUTH_REASON_CODES>;

export type EmailAuthRequestError =
  AuthErrorResponseWithReason<EmailAuthReasonCode>;

export const useEmailAuthRequest = (
  opts: UseMutationOptions<
    AxiosResponse<EmailAuthRequestData>,
    AxiosError<EmailAuthRequestError>,
    EmailAuthRequestVariables
  > = {},
) => {
  const authClient = useAuthClient();
  return useMutation({
    ...opts,
    mutationFn: (data) =>
      authClient.post<EmailAuthRequestData>('/email-auth-request', data),
  });
};
