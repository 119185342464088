// api/Comment.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import type User from './User';

export type CommentableType =
  | 'Sepa::Transaction'
  | 'Invoice'
  | 'Sepa::File'
  | 'Factor::ClientPaymentFile'
  | 'Factor::ClientPaymentTransaction';

@Model()
class Comment extends ApplicationRecord {
  static jsonapiType = 'comments';

  // Attributes
  @Attr({ persist: false }) userId!: number;
  @Attr() commentableId!: number;
  @Attr() commentableType!: string;
  @Attr() content!: string;
  @Attr({ persist: false }) createdAt!: string;

  // Extra-attributes

  // Relationships
  @BelongsTo('users') user!: User;
  // Polymorphic relation commentable : TBD
}

export default Comment;
