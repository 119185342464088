import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import en from '../../../locales/translation/en.json';
import fr from '../../../locales/translation/fr.json';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import graphitiLanguageObserver from './graphitiLanguageObserver';
import { getLanguageFromStorage } from './utils/local.utils';

const LANGUAGES = ['fr', 'en'];
const BACKEND_NAMESPACES = ['rails'];

const bundledResources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
};

const getLanguage = () => {
  dayjs.extend(localizedFormat);
  const languageFromStorage = getLanguageFromStorage();
  if (languageFromStorage === null || languageFromStorage === undefined) {
    return 'fr';
  }

  return languageFromStorage;
};

function handleI18ChangeLanguage(newLanguage: string) {
  graphitiLanguageObserver(newLanguage);
  dayjs.locale(newLanguage);
}

const i18nInit = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    lng: getLanguage(),
    debug: false,
    returnNull: false,
    ns: ['translation', 'rails'],
    defaultNS: 'translation',
    react: {
      bindI18n: 'languageChanged',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false, //   <---- this will do the magic
    },
    interpolation: { escapeValue: false },
    backend: {
      backends: [
        HttpBackend, // retrieve from rails public folder
        resourcesToBackend(bundledResources),
      ],
      backendOptions: [
        {
          loadPath: (lngs: string[], namespaces: string[]) => {
            const lng = lngs[0];
            const ns = namespaces[0];

            if (!LANGUAGES.includes(lng)) return false;
            if (!BACKEND_NAMESPACES.includes(ns)) return false;

            return '/i18next-locales/{{ns}}/{{lng}}.json';
          },
          parse: (data: string, lng: string, ns: string) => {
            if (ns !== 'rails') return {};
            try {
              const parsedData = JSON.parse(data);
              return parsedData[lng];
            } catch (e) {
              return {};
            }
          },
        },
      ],
    },
  })
  .then(() => {
    handleI18ChangeLanguage(i18next.language);
    i18next.on('languageChanged', handleI18ChangeLanguage);
  });

export default i18nInit;
