import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FC, KeyboardEventHandler } from 'react';
import { selectUserState } from '../../../app/store/selectors/selectUserId';
import { Button, ButtonOnClickHandler } from '../../common/components/buttons';

export type DropDownProfileProps = {
  handleSignOut: ButtonOnClickHandler & KeyboardEventHandler<HTMLButtonElement>;
};
export const DropDownProfile: FC<DropDownProfileProps> = ({
  handleSignOut,
}) => {
  const { t } = useTranslation();
  const userState = selectUserState();
  const aCustomClass = classNames(
    'cursor-pointer hover:bg-color-light-grey px-[24px] py-[2px] hover:no-underline text-black hover:text-color-dark-content align-middle justify-center',
  );
  /* const iCustomClass = classNames(
      'inline-block mr-3.5 w-6 h-6 bg-[25px] color-dark-content',
    ); */
  return (
    <div className="absolute top-[50px] right-0">
      <div className="z-10 bg-white w-[278px] flex-row shadow-md">
        <div className="bg-color-light-grey border-b-8 border-color-dark-content py-[3px] px-[24px] align-middle text-center text-color-medium-grey">
          <strong>{`${userState.firstName} ${userState.lastName}`}</strong>
        </div>
        <div className="flex flex-col">
          <a href="/user" className={aCustomClass}>
            {/* <i className={`fa fa-user ${iCustomClass}`} /> */}
            {t('views.user.change_parameters')}
          </a>
          <a href="/select_app" className={aCustomClass}>
            {/* <i className={`fa fa-window-restore ${iCustomClass}`} /> */}
            {t('views.user.switch_entity')}
          </a>
          <Button
            testID="header-logout-button"
            custom
            className={aCustomClass}
            onClick={handleSignOut}
            type="button"
            onKeyDown={handleSignOut}
          >
            {/* <i className={`fa fa-arrow-circle-right ${iCustomClass}`} /> */}
            {t('devise.buttons.sign_out')}
          </Button>
        </div>
      </div>
    </div>
  );
};
