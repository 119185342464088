import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { Invoice } from '@/app/spraypaint';
import IncomeSummary from '../../../../app/spraypaint/payslipFunnel/IncomeSummary';
import RevenueSummary from '../../../../app/spraypaint/RevenueSummary';
import {
  EqualFilter,
  getEqualFilter,
} from '../../../common/components/table/filter/EqualFilter';
import {
  getPeriodFilterFn,
  PeriodFilter,
} from '../../../common/components/table/filter';

const caColumnHelper = createColumnHelper<ModelRecord<RevenueSummary>>();
const incomeColumnHelper = createColumnHelper<ModelRecord<IncomeSummary>>();
const toDefundColumnHelper = createColumnHelper<ModelRecord<Invoice>>();

export const CAColumns = () => {
  const { t } = useTranslation();
  return [
    caColumnHelper.accessor('date', {
      header: t('front.payslip.tdc.invoice.table.columns.date') as string,
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    caColumnHelper.accessor('number', {
      header: t('front.payslip.tdc.invoice.table.columns.number') as string,
    }),
    caColumnHelper.accessor('externalReference', {
      header: t(
        'front.payslip.tdc.invoice.table.columns.externalReference',
      ) as string,
    }),
    caColumnHelper.accessor('clientName', {
      header: t('front.payslip.tdc.invoice.table.columns.clientName') as string,
    }),
    caColumnHelper.accessor('totalAmount', {
      header: t(
        'front.payslip.tdc.invoice.table.columns.totalAmount',
      ) as string,
      filterFn: getEqualFilter(),

      meta: {
        filter: {
          Component: EqualFilter,
        },
      },
    }),
    caColumnHelper.accessor('sendDate', {
      header: t('front.payslip.tdc.invoice.table.columns.sendDate') as string,
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    caColumnHelper.accessor('status', {
      header: t('default.status'),
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];
};

export const RevenueColumns = () => {
  const { t } = useTranslation();

  return [
    incomeColumnHelper.accessor('date', {
      header: t('front.payslip.tdc.invoice.table.columns.date') as string,
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    incomeColumnHelper.accessor('number', {
      header: t('front.payslip.tdc.invoice.table.columns.number') as string,
    }),
    incomeColumnHelper.accessor('externalReference', {
      header: t(
        'front.payslip.tdc.invoice.table.columns.externalReference',
      ) as string,
    }),
    incomeColumnHelper.accessor('clientName', {
      header: t('front.payslip.tdc.invoice.table.columns.clientName') as string,
    }),
    incomeColumnHelper.accessor('paidAmount', {
      header: t(
        'front.payslip.tdc.invoice.table.columns.totalAmount',
      ) as string,
      filterFn: getEqualFilter(),

      meta: {
        filter: {
          Component: EqualFilter,
        },
      },
    }),
    incomeColumnHelper.accessor('sendDate', {
      header: t('front.payslip.tdc.invoice.table.columns.sendDate') as string,
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    incomeColumnHelper.accessor('dueDate', {
      header: t('front.payslip.tdc.invoice.table.columns.dueDate') as string,
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    incomeColumnHelper.accessor('lastPaymentDate', {
      header: t(
        'front.payslip.tdc.invoice.table.columns.dateOfPayment',
      ) as string,
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    incomeColumnHelper.accessor('status', {
      header: t('default.status'),
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];
};

export const ToDefundColumns = () => {
  const { t } = useTranslation();

  return [
    toDefundColumnHelper.accessor('date', {
      header: t('front.payslip.tdc.invoice.table.columns.date') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    toDefundColumnHelper.accessor('number', {
      header: t('front.payslip.tdc.invoice.table.columns.number') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    toDefundColumnHelper.accessor('externalReference', {
      header: t(
        'front.payslip.tdc.invoice.table.columns.externalReference',
      ) as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    toDefundColumnHelper.accessor('totalAmount', {
      header: t(
        'front.payslip.tdc.invoice.table.columns.totalAmount',
      ) as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    toDefundColumnHelper.accessor('sendDate', {
      header: t('front.payslip.tdc.invoice.table.columns.sendDate') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    toDefundColumnHelper.accessor('dueDate', {
      header: t('front.payslip.tdc.invoice.table.columns.dueDate') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    toDefundColumnHelper.accessor('status', {
      header: t('default.status'),
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];
};
