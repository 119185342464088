import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { EnvBannerContext } from '@/app/components/EnvBanner';
import Menu from './Menu';
import Header from './Header';
import useBreakpoints from '../../app/responsive/useBreakPoints';
import { MobileIconMenu } from '../../app/assets/icons/mobile/menu';
import { Button } from '../common/components/buttons';
import { useMixpanelContextWatcher } from '../../app/mixpanel/useMixpanelContextWatcher';
import { MenuType } from './types/MenuType';

type LayoutProps = {
  itemsMenu: Array<MenuType>;
  logAsAdmin?: boolean;
};

type LayoutContainerProps = {
  children: React.ReactNode | React.ReactNode[];
};

export function LayoutContainer({ children }: LayoutContainerProps) {
  const { hidden: hiddenEnvHeader } = useContext(EnvBannerContext);

  const screenCustomClassName = classNames('flex flex-col h-full', {
    'pt-10': !hiddenEnvHeader,
  });

  return (
    <div id="outer-container" className={screenCustomClassName}>
      {children}
    </div>
  );
}

function Layout({ itemsMenu, logAsAdmin }: LayoutProps) {
  const { isXs } = useBreakpoints();
  const [isMenuRetracted, retractMenu] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  useMixpanelContextWatcher('menu_retracted', isMenuRetracted);

  const handleOnClick = () => {
    retractMenu(!isMenuRetracted);
  };

  const handleOnMobileMenuClick = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const location = useLocation();

  useEffect(() => {
    if (isXs && isMobileMenuOpen) handleOnMobileMenuClick();
  }, [location]);

  const customClassName = classNames('grow', {
    'md:ml-[62px]': isMenuRetracted,
    'md:ml-[240px]': !isMenuRetracted,
  });

  return (
    <LayoutContainer>
      <Menu
        items={itemsMenu}
        handleOnClick={handleOnClick}
        handleOnMobileMenuClick={handleOnMobileMenuClick}
        isMenuRetracted={isMenuRetracted}
        isMobileMenuOpen={isMobileMenuOpen}
        logAsAdmin={logAsAdmin}
      />
      <div id="page-wrap">
        <div id="detail" className={customClassName}>
          {!isXs ? (
            <Header logAsAdmin={logAsAdmin} />
          ) : (
            <Button
              custom
              className="mt-[20px] ml-[20px]"
              onClick={handleOnMobileMenuClick}
            >
              <MobileIconMenu />
            </Button>
          )}
          <Outlet />
        </div>
      </div>
    </LayoutContainer>
  );
}

export default Layout;
