import { useTranslation } from 'react-i18next';
import { IllusBadgeVide } from '../../../../app/assets/icons/auth';
import { ButtonPrimary } from '../../../common/components/buttons';
import { AuthTitle } from '../AuthTitle';
import { AuthMainContainer } from '../cards';

export const EditPasswordSuccess = () => {
  const { t } = useTranslation();
  return (
    <AuthMainContainer>
      <AuthTitle>{t('front.auth.passwordEdit.success.header')}</AuthTitle>
      <IllusBadgeVide className="mt-[46px]" />
      <p className="text-base2 mt-[14px]">
        {t('front.auth.passwordEdit.success.information1')}
      </p>
      <p className="text-base2">
        {t('front.auth.passwordEdit.success.information2')}
      </p>
      <ButtonPrimary to="/v2/login" className="mt-[30px]">
        {t('front.auth.passwordEdit.success.button.title')}
      </ButtonPrimary>
    </AuthMainContainer>
  );
};
