import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import SepaTransaction from '../../../../../app/spraypaint/sepaTransactions/SepaTransaction';

export const useGetValidatedSepaTransaction = (
  args: any[],
  scope: (baseScope: Scope<SepaTransaction>) => Scope<SepaTransaction>,
  id: string,
) => {
  const data = useQuery({
    queryKey: ['completed-sepa-transaction', ...args],
    queryFn: () =>
      scope(SepaTransaction.scope())
        .includes(['legal_entity'])
        .where({
          status: ['validated', 'orphaned', 'duplicate'],
          reconciliable: true,
          sepa_file_id: id,
        })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const validatedSepaTransactions = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, validatedSepaTransactions, totalCount };
};

export const useGetPendingSepaTransaction = (
  args: any[],
  scope: (baseScope: Scope<SepaTransaction>) => Scope<SepaTransaction>,
  id: string,
) => {
  const data = useQuery({
    queryKey: ['pending-sepa-transaction', ...args],
    queryFn: () =>
      scope(SepaTransaction.scope())
        .includes(['legal_entity'])
        .where({
          status: 'pending_validation',
          reconciliable: true,
          sepa_file_id: id,
        })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const pendingSepaTransaction = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, pendingSepaTransaction, totalCount };
};
