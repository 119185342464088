import { ReactElement } from 'react';
import classNames from 'classnames';
import { HypertextLink } from '../buttons';

export type LineTextProps = {
  value: string | ReactElement | number | null | undefined;
  label: string;
  onClick?: any;
  style?: {
    containerClassName?: string;
    labelClassName?: string;
    valueClassName?: string;
  };
};

export const LineText = ({
  value = '-',
  label,
  onClick,
  style,
}: LineTextProps) => {
  const { containerClassName, labelClassName, valueClassName } = style ?? {};

  const actualContainerClassName = classNames(
    'my-2 pl-2 pr-5 flex justify-between items-center',
    containerClassName,
  );

  const actuallabelClassName = classNames(
    'text-color-dark-content font-cabin-bold w-1/3',
    labelClassName,
  );

  const actualValueClassName = classNames(
    'text-color-pone-dark w-2/3',
    valueClassName,
  );
  return (
    <div className={actualContainerClassName}>
      <span className={actuallabelClassName}>{label}</span>
      {onClick && typeof value === 'string' ? (
        <HypertextLink className={actualValueClassName} onClick={onClick}>
          {value ?? '-'}
        </HypertextLink>
      ) : (
        <span className={actualValueClassName}>{value ?? '-'}</span>
      )}
    </div>
  );
};
