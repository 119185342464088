import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { Invoice } from '@/app/spraypaint';
import { ModalState } from '@/modules/admin/invoices/types/ModalState';
import {
  ActionCell,
  EmployeeLinkCell,
} from '@/modules/admin/invoices/components/ProvisionalInvoicesColComponents';
import { useTranslation } from 'react-i18next';

const ch = createColumnHelper<ModelRecord<Invoice>>();

interface ProvisionalInvoicesColumnsProps {
  isLoading: boolean;
  dissociateInvoice: (id: string | undefined) => Promise<void>;
  setPaymentModalProvisionalInvoice: (
    value:
      | ((prevState: ModalState | undefined) => ModalState | undefined)
      | ModalState
      | undefined,
  ) => void;
}

export const getProvisionalInvoicesColumns = ({
  isLoading,
  dissociateInvoice,
  setPaymentModalProvisionalInvoice,
}: ProvisionalInvoicesColumnsProps) => {
  const { t } = useTranslation();
  return [
    ch.accessor('id', {
      header: 'ID',
    }),
    ch.accessor((i) => i.employee, {
      id: 'employee_name',
      header: t('front.invoices.cards.provisional_invoices.colHeader.employee'),
      cell: EmployeeLinkCell,
    }),
    ch.accessor('totalAmount', {
      header: t(
        'front.invoices.cards.provisional_invoices.colHeader.totalAmount',
      ),
    }),
    ch.accessor('assignedCorporateHtAmount', {
      header: t(
        'front.invoices.cards.provisional_invoices.colHeader.assignedCorporateHtAmount',
      ),
    }),
    ch.accessor('status', { header: 'Status' }),
    ch.accessor('actions', {
      header: 'Action',
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ row: { original } }) => (
        <ActionCell
          original={original}
          isLoading={isLoading}
          dissociateInvoice={dissociateInvoice}
          setPaymentModalProvisionalInvoice={setPaymentModalProvisionalInvoice}
        />
      ),
    }),
  ];
};
