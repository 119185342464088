import type { FunctionComponent } from 'react';
import { MouseEventHandler, useCallback, useMemo } from 'react';
import Table, {
  ColumnDefs,
  TableProps,
} from '@/modules/common/components/table/Table';
import { DisplayColumnDef, Row, RowData } from '@tanstack/react-table';
import { Control, FieldValues, UseFormRegisterReturn } from 'react-hook-form';
import { ButtonRadio } from '@/modules/common/components/inputs/ButtonRadio';
import { FieldPath } from 'react-hook-form/dist/types/path';

export type ValueExtractor<TData extends RowData> = (
  originalRow: TData,
) => string | number | undefined;

export type TextExtractor<TData extends RowData> = (
  originalRow: TData,
) => string | undefined;

export type RadioTableProps<
  TData extends RowData,
  TFieldValues extends FieldValues,
> = Omit<
  TableProps<TData>,
  'enableRowSelection' | 'onRowClick' | 'navigateOnClick'
> & {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  valueExtractor: ValueExtractor<TData>;
  idExtractor?: TextExtractor<TData>;
};

function prefixId(
  fieldName: string,
  id: string | undefined,
): string | undefined {
  return id ? `radio-${fieldName}-${id}` : undefined;
}

function buildDefaultIdExtractor<TData extends RowData>(
  valueExtractor: ValueExtractor<TData>,
): TextExtractor<TData> {
  return (originalRow) => valueExtractor(originalRow)?.toString();
}

function buildRadioCell<
  TData extends RowData,
  TFieldValues extends FieldValues,
>(
  control: Control<TFieldValues>,
  fieldName: FieldPath<TFieldValues>,
  valueExtractor: ValueExtractor<TData>,
  idExtractor: TextExtractor<TData>,
): FunctionComponent<{ row: Row<TData> }> {
  return ({ row }) => {
    const controlProps: UseFormRegisterReturn<string> =
      control.register(fieldName);

    const props: UseFormRegisterReturn<string> = {
      ...controlProps,
    };

    return (
      <ButtonRadio
        inputClassName="fill-color-pone-orange w-[20px] h-[20px] ml-[10px]"
        key={idExtractor(row.original)}
        {...props}
        id={prefixId(fieldName, idExtractor(row.original))}
        value={valueExtractor(row.original)}
      />
    );
  };
}

const checkRowInput: MouseEventHandler<HTMLTableRowElement> = (event) => {
  // ignore if clicked on the input
  if (event.target instanceof HTMLInputElement) {
    return;
  }
  event.preventDefault();
  const row = event.currentTarget;
  const radioInput = row.querySelector<HTMLInputElement>('input[type="radio"]');

  if (radioInput) {
    radioInput.click();
  } else {
    console.error('Could not find radio input within row');
  }
};

export function RadioTable<
  TData extends RowData,
  TFieldValues extends FieldValues,
>({
  control,
  name,
  valueExtractor,
  idExtractor = buildDefaultIdExtractor(valueExtractor),
  columns,
  ...tableProps
}: RadioTableProps<TData, TFieldValues>): ReturnType<FunctionComponent> {
  const RadioCell = useCallback(
    buildRadioCell(control, name, valueExtractor, idExtractor),
    [control, name, valueExtractor, idExtractor],
  );

  const newColumns: ColumnDefs<TData> = useMemo(
    () => [
      {
        id: 'radioColumn',
        size: 38,
        meta: {
          fixedWidth: true,
          noBorder: true,
        },
        cell: RadioCell,
      } as DisplayColumnDef<TData>,
      ...columns,
    ],
    [columns],
  );

  return (
    <Table
      {...tableProps}
      columns={newColumns}
      onRowClick={() => checkRowInput}
    />
  );
}
