import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  title: string;
  clasName?: string;
  innerComponent: ReactNode;
};

export const CraInfoText = ({ title, innerComponent, clasName }: Props) => {
  const customClassName = classNames('flex flex-col', clasName);
  return (
    <div className={customClassName}>
      <p className="text-sm2 text-color-dark-content">{title}</p>
      <div className="text-xl">{innerComponent}</div>
    </div>
  );
};
