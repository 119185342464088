import {
  ChangeEvent,
  KeyboardEventHandler,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { useGetNumberedList } from '@/modules/common/hooks/useGetNumberedList';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../../../../common/components/buttons';
import { TextInput } from '../../../../common/components/inputs';
import Status from '../../../../common/components/statuses/Status';
import { MoneyType } from '../../../../common/utils/type.utils';
import {
  Invoice,
  FactorClientPaymentTransaction,
  Numbered,
  FactorCandidate,
} from '../../../../../app/spraypaint';

export const ManualReconciliationForm = ({
  handleAddition,
  className,
  transaction,
}: {
  handleAddition: (
    candidateToMove: FactorCandidate['candidate'],
    transaction: FactorClientPaymentTransaction | undefined,
  ) => () => void;
  className?: string;
  transaction: FactorClientPaymentTransaction;
}) => {
  const { t } = useTranslation();
  const [number, setNumber] = useState<string>();
  const [inputValue, setInputValue] = useState('');

  const { resources, isFetching } = useGetNumberedList(number, ['Invoice']);

  const customClassName = classNames(
    className,
    'w-[600px] flex flex-col mt-[40px]',
  );

  const handleOnInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    [setInputValue],
  );

  const handleSearch = () => {
    setNumber(inputValue);
  };

  const handleOnKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        const target = event.target as HTMLInputElement;
        setNumber(target.value);
      }
    },
    [setNumber],
  );

  const hasResult = resources.length > 0;

  const displayInvoice = (result: Invoice) => (
    <div className="flex-col">
      <p className="font-cabin-bold">
        {t(`front.bankAssociation.associationCard.title.${result.jsonapiType}`)}
      </p>
      <p>
        {t('front.manualReconciliation.card.employeeName')}
        <span> {result.employee.fullName} </span>
      </p>
      <p>
        {t('front.manualReconciliation.card.ttc')}
        <span>
          <AmountWithCurrency amount={result.totalAmountWithVat as MoneyType} />
        </span>
      </p>
      {result.status === 'partially_paid' && (
        <p>
          {t('front.manualReconciliation.card.remainingAmount')}
          <span>
            <AmountWithCurrency amount={result.remainingAmount} />
          </span>
        </p>
      )}
      <p>
        {t('front.manualReconciliation.card.dueDate')}
        <span>{dayjs(result.dueDate).format('L')} </span>
      </p>
      <p className="flex">
        {t('front.manualReconciliation.card.status')}
        <Status statusType={result.status} className="ml-2" />
      </p>
      <p>
        {t('front.manualReconciliation.card.clientName')}
        <span>{result.client?.name} </span>
      </p>
      {result.debtorType !== 'Client' && (
        <p>
          {t('front.manualReconciliation.card.debtorName', {
            debtorType: result.debtorType,
          })}
          <span> {result.debtor?.name}</span>
        </p>
      )}
    </div>
  );

  /* eslint-disable-next-line @typescript-eslint/ban-types */ // because of Function usage
  const displayMethodForNumberedMap: Record<string, Function> = {
    Invoice: displayInvoice,
  };

  const displayAction = (result: Invoice) => {
    const href = result.employeePagePath;
    return (
      <div className="flex justify-around">
        <ButtonSecondary
          disabled={!hasResult}
          href={href as string}
          openInNewTab
          className="my-[20px] w-[200px]"
        >
          {t('front.manualReconciliation.selectedInvoice.btn.show')}
        </ButtonSecondary>
        <ButtonPrimary
          onClick={handleAddition(result, transaction)}
          disabled={!hasResult}
          className="my-[20px] w-[200px]"
        >
          {t(
            'front.manualReconciliation.selectedInvoice.btn.label',
          ).toUpperCase()}
        </ButtonPrimary>
      </div>
    );
  };

  const displayResult = (resource: Numbered) => (
    <>
      {displayMethodForNumberedMap[resource.numberedType](resource.numbered)}
      {displayAction(resource.numbered as Invoice)}
    </>
  );

  return (
    <div className={customClassName}>
      <p className="text-color-medium-grey text-xl">
        {t('front.bankAssociation.show.matchingButton.title')}
      </p>
      <div className="flex items-center mt-[40px]">
        <TextInput
          placeholder={
            t(
              'front.bankAssociation.show.button.findByNumber.placeholder',
            ) as string
          }
          onChange={handleOnInputChange}
          onKeyDown={handleOnKeyUp}
          value={inputValue}
        />
        <ButtonPrimary
          isLoading={isFetching}
          className="ml-[10px]"
          onClick={handleSearch}
        >
          {t('front.bankAssociation.show.button.find.label')}
        </ButtonPrimary>
      </div>
      {!hasResult && <p>{t('front.bankAssociation.show.empty')}</p>}
      {hasResult &&
        resources.map((resource: Numbered) => displayResult(resource))}
    </div>
  );
};
