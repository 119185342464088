import { ReactNode } from 'react';
import classNames from 'classnames';
import { BtnTypeProps, Button, ButtonProps } from './Button';
import { LoaderIcon } from '../../../../app/assets/icons/inputs';
import { IconType } from '../../utils/type.utils';

export type ButtonPrimaryProps = {
  children: string | ReactNode;
  Icon?: IconType;
  iconClassName?: string;
  textStyle?: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  type?: BtnTypeProps;
} & ButtonProps;

export function ButtonPrimary({
  children,
  Icon,
  iconClassName,
  isLoading = false,
  className,
  disabled = false,
  textStyle,
  type,
  ...props
}: ButtonPrimaryProps) {
  const customClassName = classNames(
    'flex items-center justify-center h-[50px]',
    {
      'shadow-md bg-color-pone-orange hover:bg-color-dark-orange hover:border-color-dark-orange shadow-btn':
        !disabled || isLoading,
      'bg-color-disabled': disabled && !isLoading,
    },
    {
      'fill-color-medium-grey': disabled,
      'fill-white': !disabled,
    },
    {
      'text-white': !disabled,
      'text-color-disabled-text': disabled,
    },
    className,
  );
  const customIconClassName = classNames(iconClassName);
  const customTextStyle = classNames('uppercase text-sm3', textStyle);

  return (
    <Button
      className={customClassName}
      disabled={disabled}
      isLoading={isLoading}
      type={type}
      {...props}
    >
      {isLoading ? (
        <LoaderIcon
          className="fill-white animate-spin"
          width={20}
          height={20}
        />
      ) : (
        <>
          {Icon && (
            <span className="mr-2">
              <Icon height={16} width={16} className={customIconClassName} />
            </span>
          )}
          <p className={customTextStyle}>{children}</p>
        </>
      )}
    </Button>
  );
}
