import type { FunctionComponent } from 'react';
import {
  FormWizardButtons,
  useFormWizardContext,
} from '@/modules/common/components/wizard';
import { useController, useForm } from 'react-hook-form';
import { pick } from 'lodash';
import { MainCard } from '@/modules/common/components/mainCard';
import { createColumnHelper } from '@tanstack/react-table';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useFrameworkContractFindQuery } from '@/modules/admin/registered_clients/modules/framework_contracts/functions/frameworkContractQuery';
import { queryOpts } from '@/modules/admin/registered_clients/modules/framework_contracts/pages/FrameworkContractShow';
import MultiSelectTable from '@/modules/common/components/inputs/components/MultiSelectTable';
import { Invoice, ModelRecord } from '@/app/spraypaint';
import { consolidationWizardContext } from '../constants';
import { ConsolidationFormState } from '../types';

type InvoicesFormValues = Pick<ConsolidationFormState, 'invoiceIds'>;

function extractDefaultValues(
  currentFormValues: ConsolidationFormState,
): InvoicesFormValues {
  return pick(currentFormValues, ['invoiceIds']);
}

const columnHelper = createColumnHelper<ModelRecord<Invoice>>();

const columns = [
  columnHelper.accessor('id', { header: 'ID' }),
  columnHelper.accessor((originalRow) => originalRow?.employee?.fullName, {
    header: 'Salarié',
  }),
  columnHelper.accessor('number', { header: 'Number' }),
  columnHelper.accessor('date', { header: 'Date' }),
];

export const ConsolidationFormInvoices: FunctionComponent = () => {
  const { frameworkContractId } = useParams();
  const { record } = useFrameworkContractFindQuery(
    frameworkContractId,
    queryOpts,
    {
      staleTime: 30000,
    },
  );

  const { currentFormValues, onSubmit } = useFormWizardContext(
    consolidationWizardContext,
  );

  const { handleSubmit, control } = useForm<InvoicesFormValues>({
    defaultValues: extractDefaultValues(currentFormValues),
  });

  const {
    field: { onChange, value },
  } = useController({
    control,
    name: 'invoiceIds',
    defaultValue: [],
    rules: { required: true },
  });

  const [scope, scopeKey, props] = useBackendTable<Invoice>();
  const queryResult = useQuery({
    queryKey: ['provisional_invoices', scopeKey],
    queryFn: () =>
      scope(
        Invoice.where({
          commercialContractFrameworkContractId: frameworkContractId,
          consolidation_pending: true,
        })
          .includes('employee')
          .select({ employee: ['id', 'full_name'] }),
      ).all(),
    enabled: !!frameworkContractId,
  });

  if (!record) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MainCard header="Selection des factures">
        <MultiSelectTable
          onChange={onChange}
          value={value}
          queryResult={queryResult}
          reactTableProps={props}
          columns={columns}
        />
      </MainCard>
      <FormWizardButtons wizardContext={consolidationWizardContext} />
    </form>
  );
};
