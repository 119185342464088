// api/CapGeminiCorporateInvoiceCreation.ts
import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class CapGeminiCorporateInvoiceCreation extends ApplicationRecord {
  static jsonapiType = 'capgemini-corporate_invoice_creations';

  // Attributes
  @Attr() date!: string;
  @Attr() consolidationInvoiceImportId!: number;
  @Attr({ persist: false }) invoiceId!: number;

  // Extra-attributes

  // Relationships
}

export default CapGeminiCorporateInvoiceCreation;
