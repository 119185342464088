import { RoutesMounter } from '@/types';
import { featureFlagModuleRoutes } from '@/app/navigation/functions';
import ActivityAccountShow from './pages/ActivityAccountShow';

export const mountActivityAccountRoutes: RoutesMounter<unknown> =
  featureFlagModuleRoutes(
    'v2_redirect_activity_account',
    ({ current_employee }) => `/employees/${current_employee}/activity_account`,
    ({ path, tools: { t } }) => [
      {
        path,
        children: [{ index: true, Component: ActivityAccountShow }],
        handle: {
          crumb: () => t('front.invoices.crumbs.index'),
        },
      },
    ],
  );
