import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import {
  StrictEqualFilter,
  getStrictEqualFilter,
} from '@/modules/common/components/table/filter/StrictEqualFilter';
import RegisteredClient from '@/app/spraypaint/RegisteredClient';

const columnHelper = createColumnHelper<ModelRecord<RegisteredClient>>();

const registeredClientsIndexColumns = () => {
  const { t } = useTranslation();

  return [
    columnHelper.accessor('id', {
      header: 'ID',
      filterFn: getStrictEqualFilter(),
      meta: {
        filter: {
          Component: StrictEqualFilter,
        },
      },
    }),
    columnHelper.accessor('name', {
      header: t('activerecord.attributes.registered_client.name') as string,
    }),
    columnHelper.accessor('activity', {
      header: t(
        'activerecord.attributes.registered_client.activity_domain',
      ) as string,
    }),
    columnHelper.accessor('partner', {
      header: t('activerecord.attributes.registered_client.partner') as string,
      filterFn: getStrictEqualFilter(),
      cell: ({ getValue }) => String(t(`default.${getValue()}`)),
    }),
    columnHelper.accessor('activityDomainId', {
      header: t(
        'activerecord.attributes.registered_client.activity_domain',
      ) as string,
      cell: ({ row: { original } }) => original?.activityDomain?.name,
    }),
    columnHelper.accessor(
      (original) =>
        t('views.registered_client.clients_employee_count', {
          count: original?.clients?.length,
        }),
      {
        id: 'clients_count',
        header: t('activerecord.attributes.client.employee') as string,
      },
    ),
    columnHelper.accessor((original) => original?.agents?.length, {
      id: 'agents',
      header: t('views.registered_client.access') as string,
    }),
  ];
};

export default registeredClientsIndexColumns;
