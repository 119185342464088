import classNames from 'classnames';
import { Button, ButtonProps } from './Button';
import { DownloadIcon } from '../../../../app/assets/icons/buttons';
import { LoaderIcon } from '../../../../app/assets/icons/inputs';
import { IconType } from '../../utils/type.utils';

export type IconButtonSecondaryProps = {
  Icon: IconType;
  iconClassName?: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  iconSize?: { height: number; width: number };
} & ButtonProps;

export function IconButtonSecondary({
  Icon = DownloadIcon,
  iconClassName,
  className,
  disabled,
  isLoading,
  iconSize = { height: 20, width: 20 },
  ...props
}: IconButtonSecondaryProps) {
  const customClassName = classNames(
    {
      'fill-color-medium-grey hover:fill-color-pone-orange active:fill-color-dark-orange':
        !disabled,
      'fill-color-medium-grey-transparent': disabled,
    },
    className,
  );

  const customIconClassName = classNames(iconClassName);
  const { height, width } = iconSize;

  return (
    <Button className={customClassName} disabled={disabled} custom {...props}>
      {isLoading ? (
        <LoaderIcon height={height} width={width} />
      ) : (
        <Icon
          data-testid="secondary-icon"
          height={height}
          width={width}
          className={customIconClassName}
        />
      )}
    </Button>
  );
}
