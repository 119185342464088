import { useNavigate, useParams } from 'react-router-dom';
import download from 'js-file-download';
import { Breadcrumbs } from '@/modules/navigation/components/Breadcrumbs';
import { MainCard } from '@/modules/common/components/mainCard';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import Transfer from '@/app/spraypaint/factor/FactorTransfer';
import { AttributeLine } from '@/modules/common/components/AttributeLine';
import { dateToString } from '@/app/utils/dateUtils';
import Table from '@/modules/common/components/table/Table';
import FactorTransferred from '@/app/spraypaint/factor/FactorTransferred';
import { onRowClickNavigate } from '@/app/navigation/functions';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { MouseEvent } from 'react';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { MoneyType } from '@/modules/common/utils/type.utils';
import useFactorTransfer from '../hooks/useFactorTransfer';
import { factorTransferredColumns } from '../columns/factorTransferredColumns';

const FactorTransfer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { factorTransferreds, isLoading, factorTransfer } = useFactorTransfer({
    id: String(id),
    includes: ['transferreds.transferable'],
  });

  if (!factorTransfer) return null;

  const downloadCsv = async () => {
    const transfer = await Transfer.selectExtra(['csv']).find(String(id));
    download(
      transfer?.data?.csv,
      `factor-transfer-${id}-${dateToString(factorTransfer.updatedAt)}.csv`,
    );
  };

  const handleNavigate =
    (raw: ModelRecord<FactorTransferred>) => (event: MouseEvent) => {
      const path = raw?.transferable?.employeePagePath;

      onRowClickNavigate({
        id: raw?.transferable?.id,
        event,
        windowNavigationHref: path,
        href: path,
        navigate,
      });
    };

  return (
    <PageLayout
      Title={<>Remise {factorTransfer?.id}</>}
      Buttons={
        <div className="flex">
          <ButtonPrimary onClick={downloadCsv}>Export</ButtonPrimary>
          <ButtonPrimary
            className="ml-2"
            href={`/v2/admin/factor_transfers/${factorTransfer.id}/add`}
          >
            Ajouter
          </ButtonPrimary>
          <ButtonPrimary
            className="ml-2"
            href={`/v2/admin/factor_transfers/${factorTransfer.id}/remove`}
          >
            Retirer
          </ButtonPrimary>
        </div>
      }
    >
      <Breadcrumbs />
      <MainCard
        header="Informations"
        isLoading={isLoading}
        className="mt-[20px] mx-5 md:w-[50%]"
      >
        <AttributeLine
          id="created_at"
          label="Créée le"
          value={dayjs(factorTransfer.createdAt).format('L')}
        />
        <AttributeLine
          id="updated_at"
          label="Mise à jour le"
          value={dayjs(factorTransfer.updatedAt).format('L')}
        />
        <AttributeLine
          id="total_invoice_amount"
          label={t(
            'activerecord.attributes.factor.transfer.total_invoice_amount',
          )}
          value={
            <AmountWithCurrency
              amount={factorTransfer.totalInvoiceAmount as MoneyType}
            />
          }
        />
        <AttributeLine
          id="total_credit_amount"
          label={t(
            'activerecord.attributes.factor.transfer.total_credit_amount',
          )}
          value={
            <AmountWithCurrency
              amount={factorTransfer.totalCreditAmount as MoneyType}
            />
          }
        />
        <AttributeLine
          id="total_count"
          label="Nombre d'éléments"
          value={factorTransferreds?.length || 0}
        />
      </MainCard>
      <Table
        data={factorTransferreds || []}
        columns={factorTransferredColumns}
        title="Contenu de la remise"
        emptyTableErrorMsg="react_table.no_data_text"
        isLoading={isLoading}
        onRowClick={handleNavigate}
      />
    </PageLayout>
  );
};

export default FactorTransfer;
