import { CellContext } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import EmployeePayslip from '@spraypaint/EmployeePayslip';
import { IconButtonSecondary } from '@/modules/common/components/buttons';
import { IcnFichePaie, IcnVisibility } from '@/app/assets/icons/payslip';
import { EditIcon } from '@/app/assets/icons/buttons';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { TbListDetails } from 'react-icons/tb';

export const ActionsCell = ({
  cell,
}: CellContext<ModelRecord<EmployeePayslip>, unknown>) => {
  const { employeeId, craId, status, employeePayslipShowPagePath } =
    cell.row.original;

  return (
    <div className="flex justify-center items-center h-[100%]">
      <IconButtonSecondary
        className="mx-2"
        Icon={IcnVisibility}
        href={employeePayslipShowPagePath as string}
      />
      {status === 'submitted' && (
        <IconButtonSecondary
          className="mx-2"
          Icon={EditIcon}
          href={`/employees/${employeeId}/cras/${craId}/edit`}
        />
      )}
      <IconButtonSecondary
        className="mx-2"
        Icon={IcnFichePaie}
        onClick={() => console.debug('display pop up')}
      />

      <IoDocumentTextOutline className="ml-[5px]" />
      <TbListDetails className="ml-[5px]" />
    </div>
  );
};
