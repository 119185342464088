import { useTranslation } from 'react-i18next';
import { IcnBtnSalaire } from '../../../app/assets/icons/payslip';
import { ButtonPrimary } from '../../common/components/buttons';

type Props = {
  funnelStarted?: boolean;
  onClick: () => void;
  isLoading?: boolean;
};

export const PayslipIndexHeader = ({
  funnelStarted,
  onClick,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="flex md:justify-between px-[20px] mt-[20px] md:mt-[44px]">
      <p className="big-title">{t('front.payslip.index.header.title')}</p>
      {!funnelStarted && (
        <ButtonPrimary
          onClick={onClick}
          className="w-[200px]"
          isLoading={isLoading}
          Icon={IcnBtnSalaire}
        >
          {t('front.payslip.index.header.button.title')}
        </ButtonPrimary>
      )}
    </div>
  );
};
