import OneSignal from 'react-onesignal';
import getCurrentPriumEnv from '../hooks/getCurrentPriumEnv';
import { noDefault } from '../utils/typescriptUtils';

const currentPriumEnv = getCurrentPriumEnv();
let appId: string | undefined;
switch (currentPriumEnv) {
  case 'development':
    appId = process.env.ONE_SIGNAL_APP_ID_DEV;
    break;
  case 'staging':
    appId = process.env.ONE_SIGNAL_APP_ID_STAGING;
    break;
  case 'production':
    appId = process.env.ONE_SIGNAL_APP_ID_PROD;
    break;
  default:
    noDefault(currentPriumEnv);
}

console.log(OneSignal);

OneSignal?.Debug?.setLogLevel('debug');

if (appId) {
  OneSignal.init({
    appId,
    notifyButton: {
      enable: true,
    },
    serviceWorkerParam: { scope: '/push/onesignal/' },
    serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
    // path: '/push/onesignal/OneSignalSDKWorker.js',
    requiresUserPrivacyConsent: false,
    // autoRegister: true,
    autoResubscribe: true,
    allowLocalhostAsSecureOrigin: true,
  }).then(() => {
    console.debug('OneSignal loaded', OneSignal);
  });
} else {
  console.debug(
    'No OneSignal APP_ID defined for this environment, OneSignal library is not initialized',
  );
}
