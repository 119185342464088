import { ReactElement } from 'react';
import { t } from 'i18next';
import {
  Invoice,
  SepaImport,
  SubcontractorBill,
  ContribPeeDeposit,
  ContribPercoDeposit,
} from '../../../../app/spraypaint';
import { Button, ButtonOnClickHandler } from '../buttons';
import { SepaImportCard } from './SepaImportCard';
import { SubcontractorBillCard } from './SubcontractorBillCard';
import { InvoiceCard } from './InvoiceCard';
import { ContribDepositCard } from './ContribDepositCard';

export const AssociationCard = ({
  candidate,
  RightComponent,
  onClick,
}: {
  candidate:
    | Invoice
    | SubcontractorBill
    | SepaImport
    | ContribPeeDeposit
    | ContribPercoDeposit;
  RightComponent: ReactElement;
  onClick: ButtonOnClickHandler;
}) => (
  <Button
    custom
    onClick={onClick}
    className="p-[20px] hover:cursor-pointer my-[5px] rounded shadow-card "
  >
    <p className="font-cabin-bold">
      {t(
        `front.bankAssociation.associationCard.title.${candidate.jsonapiType}`,
      )}
    </p>
    <div className="flex items-center justify-between">
      {candidate instanceof Invoice && <InvoiceCard candidate={candidate} />}
      {(candidate instanceof ContribPeeDeposit ||
        candidate instanceof ContribPercoDeposit) && (
        <ContribDepositCard candidate={candidate} />
      )}
      {candidate instanceof SepaImport && (
        <SepaImportCard candidate={candidate} />
      )}
      {candidate instanceof SubcontractorBill && (
        <SubcontractorBillCard candidate={candidate} />
      )}
      {RightComponent}
    </div>
  </Button>
);
