import { ModelRecord } from 'spraypaint/lib-esm/model';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import AccountingJournalExport from '@spraypaint/accounting/AccountingJournalExport';
import dayjs from 'dayjs';
import { dynT } from '@/app/translation/utils/dynT';
import {
  getMultiSelectFilterFn,
  MultiSelectFilter,
} from '@/modules/common/components/table/filter/MultiSelectFilter';
import { withLegalEntitiesOptions } from '@/modules/common/components/table/filter/hoc/withLegalEntitiesOptions';
import {
  getSelectFilterFn,
  SelectFilter,
} from '@/modules/common/components/table/filter/SelectFilter';
import { getStrictEqualFilter } from '../../../common/components/table/filter/StrictEqualFilter';

const ch = createColumnHelper<ModelRecord<AccountingJournalExport>>();

export const accountingJournalExportsColumns = [
  ch.accessor('id', {
    header: 'ID',
    enableColumnFilter: false,
    enableSorting: false,
    size: 40,
  }),
  ch.accessor('imported', {
    filterFn: getSelectFilterFn(),
    header: dynT('activerecord.attributes.accounting/journal_export.imported', {
      ns: 'rails',
    }),
    cell: ({ getValue }) => (
      <div className="pl-7">{getValue() ? '✅' : '❌'}</div>
    ),
    size: 100,
    meta: {
      filter: {
        Component: SelectFilter,
        options: {
          options: [
            {
              label: 'Importés',
              value: 'true',
            },
            {
              label: 'Non importés',
              value: 'false',
            },
          ],
        },
      },
    },
  }),
  ch.accessor(
    (original) => (original.date ? dayjs(original.date).format('L') : ''),
    {
      id: 'date',
      filterFn: getStrictEqualFilter(),
      header: dynT('default.date'),
    },
  ),
  ch.accessor((original) => original.legalEntity?.name, {
    header: dynT('views.legal_entity.label'),
    filterFn: getMultiSelectFilterFn(),
    id: 'legal_entity_id',
    meta: {
      filter: {
        Component: withLegalEntitiesOptions(MultiSelectFilter),
      },
    },
  }),
  ch.accessor('exportType', {
    // Use this once corporate invoices are merged
    header: dynT(
      'activerecord.attributes.accounting/journal_export.export_type',
      { ns: 'rails' },
    ),
    cell: dynT(
      ({
        getValue,
      }: CellContext<ModelRecord<AccountingJournalExport>, unknown>) =>
        `activerecord.enums.accounting/journal_export.export_type.${getValue()}`,
      { ns: 'rails' },
    ),
    filterFn: getMultiSelectFilterFn(),
    meta: {
      filter: {
        Component: MultiSelectFilter,
        options: {
          options: [
            {
              label: 'Ventes',
              value: 'sales',
            },
            {
              label: 'Factures sous-traitant',
              value: 'subcontractor_bills',
            },
          ],
        },
      },
    },
  }),
  ch.accessor((original) => dayjs(original.createdAt).format('L H:mm'), {
    id: 'created_at',
    filterFn: getStrictEqualFilter(),
    header: dynT('default.created_at'),
  }),
  ch.accessor('assignationCsvLinesCount', {
    header: dynT(
      'activerecord.attributes.accounting/journal_export.assignation_csv_lines_count',
      { ns: 'rails' },
    ),
    cell: ({ getValue }) => {
      const value = getValue();
      return value;
    },
  }),
  ch.accessor(
    (original) =>
      original.generatedAt ? dayjs(original.generatedAt).format('L H:mm') : '',
    {
      id: 'generated_at',
      filterFn: getStrictEqualFilter(),
      header: dynT('front.accounting_journal_exports.generated_at'),
    },
  ),
];
