import { ModelRecord, Invoice } from '@/app/spraypaint';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import MultiSelectTable from '@/modules/common/components/inputs/components/MultiSelectTable';
import { MainCard } from '@/modules/common/components/mainCard';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { Toaster } from '@/modules/common/components/toasts';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { compact } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PageLayout } from '@/modules/common/components/PageLayout';

const columnHelper = createColumnHelper<ModelRecord<Invoice>>();
const columns = [
  columnHelper.accessor('id', { header: 'ID' }),
  columnHelper.accessor((originalRow) => originalRow?.employee?.fullName, {
    header: 'Salarié',
  }),
  columnHelper.accessor('number', { header: 'Number' }),
  columnHelper.accessor('date', { header: 'Date' }),
];

const AddProvisionalInvoice = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ids, setIds] = useState<Array<Invoice['id']>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [scope, scopeKey, props] = useBackendTable<Invoice>();
  const queryResult = useQuery({
    queryKey: ['add_provisional_invoices', scopeKey],
    queryFn: () =>
      scope(
        Invoice.where({
          corporate_invoice_id: null,
          invoice_type: 'provisional',
        })
          .includes('employee')
          .select({ employee: ['id', 'full_name'] }),
      ).all(),
    enabled: true,
  });

  const onSubmit = async () => {
    setIsLoading(true);
    const invoice = new Invoice({ id });
    invoice.isPersisted = true;

    ids.forEach((newInvoiceId) => {
      const newInvoice = new Invoice({ id: newInvoiceId });
      newInvoice.isPersisted = true;
      invoice.provisionalInvoices.push(newInvoice);
    });

    const success = await invoice.save({ with: 'provisionalInvoices' });
    if (success) {
      toast.success(
        <Toaster
          textMsg={t('views.shared.change_applied')}
          toastType="success"
        />,
      );
      setIsLoading(false);
      navigate(`/v2/admin/invoices/${invoice.id}`);
    } else {
      const apiErrors = [];
      if (Object.keys(invoice.errors).length > 0)
        apiErrors.push(invoice.errors);
      compact(apiErrors).forEach((e) => {
        toast.error(<Toaster textMsg={String(e)} toastType="error" />);
      });
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      Title={t('front.invoices.add_provisional_invoice.header.title')}
    >
      <MainCard header="Selection des factures">
        <MultiSelectTable
          onChange={setIds}
          value={ids}
          queryResult={queryResult}
          reactTableProps={props}
          columns={columns}
        />
        <ButtonPrimary
          className="mt-[10px]"
          onClick={onSubmit}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t('default.button.add')}
        </ButtonPrimary>
      </MainCard>
    </PageLayout>
  );
};

export default AddProvisionalInvoice;
