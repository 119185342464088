import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import './app/translation/i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import { RouterProvider } from 'react-router-dom';
import { useProfiler } from '@/app/hooks/useProfiler';
import {
  EnvBanner,
  EnvBannerContext,
  useEnvBannerProvider,
} from '@/app/components/EnvBanner';
import { router } from './app/navigation/Routes';
import store from './app/store/store';
import './app/spraypaint/index';
import './app/mixpanel/mixpanel';
import './app/config/dayjs';

const queryClient = new QueryClient();

const App = () => {
  useProfiler();

  const envBannerContextState = useEnvBannerProvider();

  return (
    <Provider store={store}>
      {' '}
      <QueryClientProvider client={queryClient}>
        {' '}
        <DndProvider backend={HTML5Backend}>
          <EnvBannerContext.Provider value={envBannerContextState}>
            <EnvBanner />{' '}
            <ToastContainer
              hideProgressBar
              closeButton={false}
              icon={false}
              autoClose={2000}
              className="w-[300px]"
            />{' '}
            <RouterProvider router={router(queryClient)} />{' '}
            <ReactQueryDevtools initialIsOpen={false} />{' '}
          </EnvBannerContext.Provider>
        </DndProvider>{' '}
      </QueryClientProvider>{' '}
    </Provider>
  );
};

export default App;
