import { ModelRecord } from 'spraypaint/lib-esm/model';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import AccountingCode from '@/app/spraypaint/AccountingCode';
import dayjs from 'dayjs';
import { withLegalEntitiesOptions } from '@/modules/common/components/table/filter/hoc/withLegalEntitiesOptions';
import {
  getMultiSelectFilterFn,
  MultiSelectFilter,
} from '@/modules/common/components/table/filter/MultiSelectFilter';

const columnHelper = createColumnHelper<ModelRecord<AccountingCode>>();

const accountingCodesColumns = () => {
  const { t } = useTranslation();

  return [
    columnHelper.accessor('id', {
      header: 'ID',
    }),
    columnHelper.accessor((original) => dayjs(original.createdAt).format('L'), {
      header: t('default.created_at'),
      id: 'created_at',
    }),
    columnHelper.accessor((original) => original.employeeFullName, {
      header: t('activerecord.attributes.user.full_name') as string,
      id: 'employee_full_name',
    }),
    columnHelper.accessor('employee.legalEntity', {
      header: t('views.legal_entity.label') as string,
      id: 'legal_entity_id',
      filterFn: getMultiSelectFilterFn(),
      enableSorting: false,
      meta: {
        filter: {
          Component: withLegalEntitiesOptions(MultiSelectFilter),
        },
      },
      cell: (val) => {
        const value = val.getValue()?.name;
        return <div className="pl-7 pr-3 text-left">{value}</div>;
      },
    }),
    columnHelper.accessor((original) => original.accountingType, {
      header: 'Type',
      id: 'accounting_type',
    }),
    columnHelper.accessor((original) => original.code, {
      header: 'Code',
    }),
  ];
};

export default accountingCodesColumns;
