import {
  OnboardingFunnelStepMappingType,
  StepComponentsType,
} from '@/modules/welcome/types/OnboardingStepsType';
import {
  OBCitizenshipStep,
  OBFinalStep,
  OBIdentityStep,
  OBPortageOfferStep,
  OBReviewStep,
} from '@/modules/welcome/components';

/**
 * Mapping of onboarding funnel steps to their respective string values.
 * @type {OnboardingFunnelStepMappingType}
 */
export const OnboardingFunnelStepMapping: OnboardingFunnelStepMappingType = {
  identity_step: 'front.welcome.onboarding.steps.identityStep',
  citizenship_step: 'front.welcome.onboarding.steps.citizenshipStep',
  portage_offer_step: 'front.welcome.onboarding.steps.portageOfferStep',
  review_step: 'front.welcome.onboarding.steps.reviewStep',
  final_step: 'front.welcome.onboarding.steps.finalStep',
};

/**
 * Function to get the step name from the mapping of step name to trad.
 * @remarks
 * 0 = identity_step, 1 = citizenship_step, 2 = portage_offer_step, 3 = review_step, 4 = final_step
 * @param index - the index to get the current step name
 * @returns {string} Step name
 */
export const StepNameBackToFront = (index: 0 | 1 | 2 | 3 | 4): string =>
  Object.keys(OnboardingFunnelStepMapping)[index];

/**
 * Mapping of onboarding funnel steps to their respective components.
 * @type {StepComponentsType}
 */
export const stepsComponents: StepComponentsType = {
  identity_step: OBIdentityStep,
  citizenship_step: OBCitizenshipStep,
  portage_offer_step: OBPortageOfferStep,
  review_step: OBReviewStep,
  final_step: OBFinalStep,
};
