import classNames from 'classnames';

type Props = {
  itemCount: number | undefined;
  className?: string;
};

// CONTAINER COMPOSANT NEEDS TO BE RELATIVE

export const AbsoluteShowNumber = ({ itemCount, className }: Props) => {
  const containerClassName = classNames(
    className,
    'rounded-full h-5 w-5 flex justify-center items-center absolute top-0 -right-2',
    { 'bg-color-pone-dark': !className },
  );
  if (itemCount === 0 || !itemCount) return null;
  return (
    <div className={containerClassName}>
      <p className="text-white text-sm">{itemCount > 9 ? '9+' : itemCount}</p>
    </div>
  );
};
