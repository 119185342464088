import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentEmployeeId } from '../../../app/hooks/useCurrentEmployeeId';
import { PayslipFunnelContextChangeMessage } from '../../../app/spraypaint';

type Params = {
  callBack?: () => void;
  errorCallback?: () => void;
};

export const useDeleteEventContextMessage = ({
  callBack,
  errorCallback,
}: Params) => {
  const employeeId = useCurrentEmployeeId();
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...others } = useMutation({
    mutationFn: async (id: string) => {
      const payslipFunnelContextChange = new PayslipFunnelContextChangeMessage({
        id,
      });
      const success = await payslipFunnelContextChange.destroy();
      if (!success) {
        throw new Error('Deletion of event message failed');
      }
    },
    onSuccess: () => {
      if (callBack) callBack();
      queryClient.invalidateQueries(['employee-payslip-funnel', employeeId]);
    },
    onError: () => {
      if (errorCallback) errorCallback();
      queryClient.invalidateQueries(['employee-payslip-funnel', employeeId]);
    },
  });
  return { mutate, isLoading, ...others };
};
