import { useEffect, useRef } from 'react';

// Save and return value from previous render
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    if (value) {
      ref.current = value;
    }
  });
  return ref.current;
}
