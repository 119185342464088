import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';
import InputMask from 'react-input-mask';

export interface MaskedTextInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  isDisabled?: boolean;
  placeholder?: string;
  name?: string;
  className?: string;
  mask?: string;
  maskPlaceholder?: string;
}

export const MaskedTextInput = ({
  value,
  onChange,
  isDisabled = false,
  name,
  className,
  maskPlaceholder,
  mask,
  ...props
}: MaskedTextInputProps) => {
  const sanitizedValue = value == null ? '' : value;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const customClassName = classNames(
    'h-[32px] rounded-md pl-[13px]',
    className,
  );

  // Common props form all types
  const commonProps = {
    readOnly: isDisabled, // put it before `...props`, so it can be overwritten
    ...props,
    value: sanitizedValue,
    onChange,
  };
  const safeMask = mask || '';

  return (
    <InputMask
      {...commonProps}
      maskPlaceholder={maskPlaceholder}
      className={customClassName}
      mask={safeMask}
      data-testid="input-mask"
    />
  );
};
