import { isEmpty } from 'lodash';
import { AddPrefixToObject } from './type.utils';

type Address = {
  Detail: string;
  Number: string;
  Road: string;
  Country: string;
  City: string;
  Zipcode: string;
  CountryCode: string;
};

export function addressDisplay<TPrefix extends string>({
  record,
  prefix,
}: {
  record: AddPrefixToObject<Address, TPrefix> | undefined;
  prefix: TPrefix;
}) {
  if (!record) return '-';

  const countryCodeDisplay = record[`${prefix}CountryCode`]
    ? `(${record[`${prefix}CountryCode`]})`
    : '';

  return `${
    isEmpty(record[`${prefix}Detail`]) ? '' : `${record[`${prefix}Detail`]}, `
  } ${record[`${prefix}Number`]} ${record[`${prefix}Road`]}, ${
    record[`${prefix}Zipcode`]
  } ${record[`${prefix}City`]}, ${
    record[`${prefix}Country`]
  } ${countryCodeDisplay}`;
}
