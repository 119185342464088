import { ModelRecord } from 'spraypaint/lib-esm/model';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Client } from '@/app/spraypaint';
import {
  StrictEqualFilter,
  getStrictEqualFilter,
} from '@/modules/common/components/table/filter/StrictEqualFilter';

const columnHelper = createColumnHelper<ModelRecord<Client>>();

const registeredClientClientsColumns = () => {
  const { t } = useTranslation();

  return [
    columnHelper.accessor('id', {
      header: 'ID',
      filterFn: getStrictEqualFilter(),
      meta: {
        filter: {
          Component: StrictEqualFilter,
        },
      },
    }),
    columnHelper.accessor('owner.user.fullName', {
      header: t('activerecord.attributes.user.full_name') as string,
    }),
    columnHelper.accessor((original) => original.owner?.portageOffer?.name, {
      header: t('activerecord.attributes.portage_offer.name') as string,
    }),
    columnHelper.accessor('active', {
      header: t('views.user.active') as string,
      cell: ({ getValue }) => String(t(`default.${getValue()}`)),
    }),
  ];
};

export default registeredClientClientsColumns;
