// api/Sepa::MatchingScore.ts
import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type SepaTransaction from './SepaTransaction';
import Invoice from '../Invoice';
import type { MatchingScoreStatusEnum } from '../types/matchingStatusTypes';

@Model()
class SepaMatchingScore extends ApplicationRecord {
  static jsonapiType = 'sepa-matching_scores';

  // Attributes
  @Attr({ persist: false }) sepaTransactionId!: number;
  @Attr({ persist: false }) totalScore!: number;
  @Attr({ persist: false }) scores!: unknown;
  @Attr({ persist: false }) paidAmount!: unknown;
  @Attr({ persist: false }) status!: MatchingScoreStatusEnum;

  // Relationships
  @BelongsTo('sepa-transactions') sepaTransactions!: SepaTransaction;
  @HasMany('invoices') invoices!: Array<Invoice>;
}

export default SepaMatchingScore;
