import { Row } from '@tanstack/react-table';

import { ToggleOpenIcon } from '../../fragments/toggle/ToggleOpenIcon';
import { ToggleCloseIcon } from '../../fragments/toggle/ToggleCloseIcon';

export type Props<TData> = {
  row: Row<TData>;
};

export function ExpandToggleIcon<TData>({ row }: Props<TData>) {
  if (!row.getCanExpand()) return null;

  if (row.getIsExpanded()) {
    return <ToggleCloseIcon />;
  }
  return <ToggleOpenIcon />;
}
