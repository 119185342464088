import { PriumLogo } from '@/app/assets/logos';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingFunnelWidget } from '@/modules/welcome/components/OnboardingFunnelWidget';
import { supportedLanguages } from '@/app/translation/constants/supportedLanguages';
import { LanguageSwitch } from '@/app/translation/components';
import { SimulationsWidget } from '@/modules/welcome/components/SimulationsWidget';

export const WelcomePage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto">
      <div className="float-right mr-[72px]">
        <LanguageSwitch languages={supportedLanguages} className="" />
      </div>
      <PriumLogo className="w-[400px] max-w-full mt-8 m-auto" />
      <div className="m-auto w-fit text-lg">{t('front.welcome.tagline')}</div>
      <OnboardingFunnelWidget />
      <SimulationsWidget />
    </div>
  );
};
