import OneSignal from 'react-onesignal';
import NotificationsInbox from '../../../app/spraypaint/notification/NotificationsInbox';

export function registerWithOneSignal({
  inbox,
}: {
  inbox: NotificationsInbox;
}) {
  const externalUserId = inbox.oneSignalExternalId;
  const externalUserIdAuthHash = inbox.oneSignalExternalIdAuthHash;

  if (!externalUserId || !externalUserIdAuthHash) {
    if (!externalUserId) {
      console.debug(
        'Missing oneSignalExternalId attribute, did you select it ?',
      );
    }
    if (!externalUserIdAuthHash) {
      console.debug(
        'Missing externalUserIdAuthHash attribute, did you select it ?',
      );
    }
    return;
  }

  console.debug(
    'Registering externalUserId',
    externalUserId,
    externalUserIdAuthHash,
  );

  OneSignal.login(externalUserId) // , externalUserIdAuthHash)
    .then(() => {
      console.debug('ExternalUserId registered');
    })
    .catch((error) => {
      console.error('Error while registering externalUserId', error);
    });
}
