// api/ActivityAccount.ts
import { Model, Attr, HasMany } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type ActivityAccountLine from './ActivityAccountLine';
import type ActivityAccountLineGroup from './ActivityAccountLineGroup';
import type { MoneyType } from '../../../modules/common/utils/type.utils';

type ProvisionType = {
  details: {
    eoc_provisions: MoneyType;
    financial_provisions: MoneyType;
    paid_holidays_provisions: MoneyType;
  };
  total: MoneyType;
};

@Model()
class ActivityAccount extends ApplicationRecord {
  static jsonapiType = 'activity_accounts';

  // Attributes
  @Attr({ persist: false }) ownerType!: string;
  @Attr({ persist: false }) ownerId!: number;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) currencyIsoCode!: string;

  // EXRA ATTRIBUTES

  @Attr({ persist: false }) balance!: MoneyType;
  @Attr({ persist: false }) totalBalance!: MoneyType;
  @Attr({ persist: false }) provisions!: ProvisionType;

  // Relationships
  // Polymorphic relation owner : TBD
  @HasMany('activity_account_lines')
  activityAccountLines!: Array<ActivityAccountLine>;
  @HasMany('activity_account_line_groups')
  activityAccountLineGroups!: Array<ActivityAccountLineGroup>;
}

export default ActivityAccount;
