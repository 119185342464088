import { ReactElement, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  FilterDownIcon,
  FilterUpIcon,
} from '../../../app/assets/icons/buttons';
import { IconType, MoneyType } from '../../common/utils/type.utils';
import { CustomTooltip } from '../../common/components/tooltip/CustomTooltip';
import { DisplayedLineValue } from './DisplayLineValue';
import { getNumberColor } from '../../common/utils/maths.utils';

export type LineMainCardTextProps = {
  label: string;
  value: MoneyType | number;
  Icon?: IconType;
  displayAll?: boolean;
  children?: ReactNode;
  lineToDisplay?: boolean;
  displayAmountBigger?: boolean;
  tooltipId?: string;
  tooltipMsgComp?: string | Array<string> | ReactElement;
  customLabelClass?: string;
};

export const LineMainCardText = ({
  label,
  value,
  Icon,
  displayAll,
  children,
  lineToDisplay = false,
  displayAmountBigger = false,
  tooltipId = '',
  tooltipMsgComp,
  customLabelClass = '',
}: LineMainCardTextProps) => {
  const [display, setDisplay] = useState<boolean>(false);

  useEffect(() => {
    if (displayAll !== undefined) setDisplay(displayAll);
  }, [displayAll]);

  const customClass = classNames(
    'flex items-center',
    lineToDisplay ? 'hover:cursor-pointer' : '',
  );
  const displayedLabel = () => (
    <span className="flex items-center">
      <span
        className={customClass}
        onClick={() => setDisplay(!display)}
        role="none"
      >
        {lineToDisplay &&
          (display ? (
            <FilterDownIcon height={5} width={10} className="mr-2" />
          ) : (
            <FilterUpIcon height={5} width={10} className="mr-2  rotate-90" />
          ))}
        <span className={customLabelClass}>{label}</span>
      </span>
      {Icon && (
        <span id={tooltipId}>
          <Icon className="ml-1 h-6 w-6 fill-color-disabled-text hover:fill-color-dark-orange" />
        </span>
      )}
      {tooltipMsgComp && (
        <CustomTooltip
          key={`key-${tooltipId}`}
          tooltipMsgComp={tooltipMsgComp}
          tooltipId={tooltipId}
        />
      )}
    </span>
  );

  let customTextClass = getNumberColor(value);
  if (displayAmountBigger) {
    customTextClass += ' text-xl1 md:text-xl4';
  } else {
    customTextClass += ' text-base4 md:text-xl1';
  }

  return (
    <>
      <div className="my-2 pl-2 pr-5 flex justify-between md:items-center">
        <span className="text-color-dark-content w-[60%]">
          {displayedLabel()}
        </span>
        <span className="text-color-pone-dark">
          <DisplayedLineValue
            value={value}
            customTextClass={customTextClass}
            customCurrencyClass="ml-1 md:top-[-10px] md:text-base2"
            isSpecificCard={displayAmountBigger}
          />
        </span>
      </div>

      {display ? children : null}
    </>
  );
};
