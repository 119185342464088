import { RoutesMounter } from '@/types';
import {
  featureFlagModuleRoutes,
  index,
  member,
  redirectTo,
} from '@/app/navigation/functions';
import { OnBoardingFunnel } from '@/modules/welcome/pages/OnBoardingFunnel';
import { SimulationShow } from '@/modules/welcome/pages/SimulationShow';
import { SimulationNew } from '@/modules/welcome/pages/SimulationNew';
import {
  buildAuthenticatedLoader,
  onlyProspectFilter,
} from '@/app/navigation/loaders/buildAuthenticatedLoader';
import { WelcomePage } from '@/modules/welcome/pages/welcomePage';

export const mountWelcomeRoutes: RoutesMounter<unknown> =
  featureFlagModuleRoutes(
    'two_steps_auth',
    () => '/',
    ({ path, tools }) => {
      const { t } = tools;

      return [
        member({
          path,
          loader: buildAuthenticatedLoader(tools, onlyProspectFilter),
          handle: { crumb: () => t('front.welcome.title') },
          children: [
            index({
              Component: WelcomePage,
            }),
            member({
              path: 'onboarding',
              Component: OnBoardingFunnel,
            }),
            member({
              path: 'simulations',
              handle: { crumb: () => 'Simulation' },
              children: [
                index({ loader: redirectTo(() => '..') }),
                { path: ':id', Component: SimulationShow },
                { path: 'new', Component: SimulationNew },
              ],
            }),
          ],
        }),
      ];
    },
  );
