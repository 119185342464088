import classNames from 'classnames';

type Props = {
  absolute?: boolean;
  className?: string;
};

export const ExpensePastille = ({ absolute = true, className }: Props) => {
  const customClassName = classNames(
    'w-[11px] h-[11px] rounded-full bg-color-pone-orange',
    {
      'absolute top-[5px] right-[5px] z-50': absolute,
    },
    className,
  );
  return <div className={customClassName} />;
};
