import { Context, createContext, useCallback, useContext } from 'react';
import { useSelector } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { keyToEvent } from '@/modules/common/components/wizard/functions/keyToEvent';
import { CustomStateMachine, WizardPage } from '../types';

export type FormWizardContextType<
  TFormData extends FieldValues,
  TKeys extends string,
> = {
  pages: WizardPage<TKeys>[];
  interpret?: InterpreterFrom<CustomStateMachine<TFormData, TKeys>>;
};

export function createWizardContext<
  TFormData extends FieldValues,
  TKeys extends string,
>(): Context<FormWizardContextType<TFormData, TKeys>> {
  return createContext<FormWizardContextType<TFormData, TKeys>>({
    pages: [],
    interpret: undefined,
  });
}

export function useFormWizardContext<
  TFormData extends FieldValues = FieldValues,
  TKeys extends string = string,
>(wizardContext: Context<FormWizardContextType<TFormData, TKeys>>) {
  const { pages, interpret } = useContext(wizardContext);

  if (!interpret) {
    throw new Error('No interpret found in context');
  }

  const hasButton = useSelector(interpret, (state) => ({
    next: state.nextEvents.includes('NEXT'),
    previous: state.nextEvents.includes('PREVIOUS'),
    submit: state.nextEvents.includes('SUBMIT'),
  }));

  const currentFormValues = useSelector(interpret, (state) => state.context);

  const onSubmit: SubmitHandler<Partial<TFormData>> = useCallback(
    (data) => {
      if (hasButton.submit) {
        interpret?.send('SUBMIT', { payload: data });
      }
      if (hasButton.next) {
        interpret?.send('NEXT', { payload: data });
      }
    },
    [interpret, hasButton.next, hasButton.submit],
  );

  const goBack = useCallback(() => {
    if (hasButton.previous) {
      interpret?.send('PREVIOUS');
    }
  }, [interpret, hasButton.previous]);

  const currentPage = useSelector(interpret, (state) =>
    pages.find((page) => page.key === state.value),
  );

  if (!currentPage) {
    throw new Error('No currentPage');
  }

  const goToPage = useCallback(
    (page: WizardPage<TKeys>) => {
      interpret?.send(keyToEvent(page.key));
    },
    [interpret],
  );

  return {
    currentFormValues,
    hasButton,
    onSubmit,
    goBack,
    currentPage,
    pages,
    goToPage,
    machineState: interpret.getSnapshot(),
  };
}
