import { SepaFile } from '@/app/spraypaint';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { TypedLoaderFunction } from '@/types';
import { RecordProxy } from 'spraypaint/lib-esm/proxies';

export function sepaFileQuery(id: string) {
  // params a envoyer a usequery
  return {
    queryKey: ['sepa-files', id],
    queryFn: async () =>
      SepaFile.selectExtra(['last_export_ids'])
        .stats({
          total:
            'credit_amount,remaining_credit_amount,debit_amount,remaining_debit_amount',
        })
        .find(id),
  };
}

export function loader(
  queryClient: QueryClient,
): TypedLoaderFunction<RecordProxy<SepaFile>> {
  return async ({ params }) => {
    const { id } = params;

    // if id is undefined, or is not a number-string
    if (!id || Number.isNaN(Number(id)))
      // Allow Response to be thrown here because this is an expected
      // behavior from React Router
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new Response('Not found', { status: 404, statusText: 'Not Found' });

    const query = sepaFileQuery(id as string);

    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
}

export function useSepaFile(id: string) {
  return useQuery(sepaFileQuery(id));
}
