import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import { Invoice } from '@/app/spraypaint';

const useRegisteredClientInvoices = (
  registeredClientId: number,
  scope: (baseScope: Scope<Invoice>) => Scope<Invoice>,
  randomProps: unknown,
) => {
  const data = useQuery({
    queryKey: ['registered-client-invoices', randomProps],
    queryFn: () => scope(Invoice.where({ registeredClientId }).scope()).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const invoices = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, invoices, totalCount };
};

export default useRegisteredClientInvoices;
