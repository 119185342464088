import classNames from 'classnames';
import { Button, ButtonProps } from './Button';
import { ChevronRightIcon } from '../../../../app/assets/icons/buttons';

export type ButtonPaginationProps = {
  disabled?: boolean;
  className?: string;
  prev?: boolean;
  label?: string;
  labelCustomClass?: string;
} & ButtonProps;

export function ButtonPagination({
  disabled,
  onClick,
  className,
  prev,
  label,
  labelCustomClass,
}: ButtonPaginationProps) {
  const customClassName = classNames(
    'flex flex-col justify-center items-center rounded-full',
    'border-[1px] border-color-medium-grey',
    'fill-color-pone-orange',
    {
      'h-9 w-9': !label,
      'hover:bg-color-pone-orange active:bg-color-dark-orange hover:border-color-pone-orange hover:fill-white active:border-color-dark-orange text-color-medium-grey hover:text-white':
        !disabled,
      'bg-color-disabled fill-color-transparent-pone-orange border-color-medium-grey-transparent':
        disabled,
    },
    className,
  );

  const iconClassName = classNames('w-[24px] h-[12px]', {
    'rotate-180 mr-[2px]': prev,
  });

  const labelClassName = classNames(
    {
      'flex items-center align-middle py-3': label,
      'pr-3 pl-1': label && prev,
      'pr-1 pl-3': label && !prev,
    },
    labelCustomClass,
  );

  const renderLabel = () => (
    <div className={labelClassName}>
      {prev && <ChevronRightIcon className={iconClassName} />}
      {label}
      {!prev && <ChevronRightIcon className={iconClassName} />}
    </div>
  );

  return (
    <Button
      className={customClassName}
      onClick={onClick}
      disabled={disabled}
      custom
    >
      {label && renderLabel()}
      {!label && !prev && <ChevronRightIcon className={iconClassName} />}
      {!label && prev && renderLabel()}
    </Button>
  );
}
