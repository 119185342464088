import dayjs from 'dayjs';

export const isDateString = (val: string) => {
  const date = Date.parse(val);
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(date);
};

export const getDateFromPeriod = (period: { month: number; year: number }) =>
  dayjs(new Date(Number(period.year), Number(period.month) - 1, 1)).format(
    'MM/YYYY',
  );

export const getCorrectMonthNumber = (month: number): string => {
  if (month < 10) return `${0}${month}`;
  return month.toString();
};

export const dateToString = (date: Date | string) => {
  const actualDate = new Date(date);
  return `${actualDate.getFullYear()}-${
    actualDate.getMonth() + 1
  }-${actualDate.getDate()}`;
};

export const formatDate = (date: Date | string) => {
  const actualDate = new Date(date);
  return `${actualDate.getDate()}/${
    actualDate.getMonth() + 1
  }/${actualDate.getFullYear()}`;
};
