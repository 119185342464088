import { FunctionComponent, useCallback } from 'react';
import {
  CapGeminiConsolidationInvoiceImport,
  CapGeminiCorporateInvoiceCreation,
} from '@/app/spraypaint';
import {
  CustomModal,
  CustomModalProps,
} from '@/modules/common/components/fragments/CustomModal';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { ApiValidationError } from '@spraypaint/ApiValidationError';
import { toast } from 'react-toastify';
import { Button, ButtonPrimary } from '@/modules/common/components/buttons';
import { CloseIcon } from '@/app/assets/icons/buttons';
import { ControlledDateInput } from '@/modules/common/components/inputs/controlledInput/ControlledDateInput';
import CapGeminiTundraInvoiceCreation from '@/app/spraypaint/capgemini/CapGeminiTundraInvoiceCreation';
import { useHandleCloseModal } from '@/modules/admin/shared/hooks/useInvoiceHandleCloseModal';

export type FormState = {
  date?: Date | string | undefined | null;
};

export interface TundraInvoiceCreationModalProps {
  consolidationInvoiceImport: CapGeminiConsolidationInvoiceImport;
  modalProps: CustomModalProps;
  afterSubmit?:
    | ((record: CapGeminiCorporateInvoiceCreation) => void)
    | undefined;
}

export const TundraInvoiceCreationModal: FunctionComponent<
  TundraInvoiceCreationModalProps
> = ({ consolidationInvoiceImport, modalProps, afterSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormState>({
    defaultValues: {
      date: null,
    },
  });

  const { mutateAsync } = useMutation({
    mutationKey: ['createTundraInvoice', consolidationInvoiceImport.id],
    mutationFn: async (data: FormState) => {
      const service = new CapGeminiTundraInvoiceCreation({
        consolidationInvoiceImportId: consolidationInvoiceImport.id,
        date: data.date,
      });
      return service.saveOrThrow();
    },
    onSuccess: (record) => {
      if (afterSubmit) afterSubmit(record);
    },
    onError(error) {
      if (
        ApiValidationError.isInstance(error, CapGeminiTundraInvoiceCreation)
      ) {
        Object.keys(error.errors).forEach((e) => {
          toast.error(error.errors[e]?.fullMessage);
        });
      }
    },
  });

  const handleCloseModal = useHandleCloseModal(isSubmitting, modalProps);

  const onSubmit = useCallback(
    handleSubmit((data) => mutateAsync(data)),
    [handleSubmit, mutateAsync],
  );
  return (
    <CustomModal {...modalProps} onRequestClose={handleCloseModal}>
      <div className="flex flex-row w-[950px] bg-color-light-grey py-[25px]">
        <div className="flex-auto ml-[25px] text-xl2">
          Créer la facture Tundra
        </div>
        <Button
          onClick={handleCloseModal}
          custom
          className=" flex- mr-[25px] justify-self-end"
        >
          <CloseIcon />
        </Button>
      </div>
      <form onSubmit={onSubmit} className="p-5">
        <div className="flex">
          <div className="flex-row">
            <ControlledDateInput
              control={control}
              label="Date"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore // Thing's broken
              name="date"
              stringMode
            />
          </div>
        </div>

        <ButtonPrimary
          className="m-2"
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Submit
        </ButtonPrimary>
      </form>
    </CustomModal>
  );
};
