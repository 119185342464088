import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonPagination, ButtonSecondary } from '@common/components/buttons';
import { StepperLine } from './StepperLine';
import useBreakpoints from '../../../app/responsive/useBreakPoints';
import { StepperIcon } from './StepperIcon';
import { StepperProps } from './types/StepperTypes';
import { useStepData } from './hooks/useStepData';

export const Stepper = ({
  steps,
  currentStep,
  onClick,
  className,
  vertical,
  isLoading,
  readonly,
}: StepperProps) => {
  const { t } = useTranslation();
  const { isXs } = useBreakpoints();
  const [displayAllSteps, setDisplayAllSteps] = useState(false);
  const stepData = useStepData(
    steps,
    currentStep,
    vertical,
    onClick,
    isXs,
    setDisplayAllSteps,
  );

  const containerClassName = classNames(
    'flex',
    { 'flex-col': vertical, 'justify-items-center': isXs },
    className,
  );

  const currentStepData = stepData[currentStep];
  const btnStepLabel = t('front.payslip.tdc.stepper.button.label', {
    stepIndex: currentStep + 1,
    stepName: steps[currentStep],
  });

  const prevStepIndex = currentStep - 1;
  const btnPrevStepLabel = t('front.payslip.tdc.stepper.button.label', {
    stepIndex: currentStep,
    stepName: steps[prevStepIndex],
  });

  return (
    <div className={containerClassName}>
      {isXs && !readonly && (
        <div className="flex flex-col text-base2 justify-around items-center mb-5">
          {currentStep > 0 && (
            <ButtonPagination
              type="button"
              onClick={onClick && onClick(prevStepIndex)}
              prev
              label={btnPrevStepLabel}
              labelCustomClass="uppercase"
            />
          )}
          <ButtonSecondary
            type="button"
            className="mt-2"
            onClick={
              currentStepData?.onCurrentStepClick &&
              currentStepData.onCurrentStepClick(currentStepData?.stepIndex)
            }
          >
            {btnStepLabel}
          </ButtonSecondary>
        </div>
      )}
      {(!isXs || displayAllSteps || readonly) &&
        stepData.map((step, index) => {
          const {
            isFirst,
            isLast,
            hasBeenDone,
            isCurrent,
            stepContainerClassName,
            iconStepClassName,
            labelClassName,
          } = step;

          return (
            <div key={steps[index]} className={stepContainerClassName}>
              <StepperIcon
                hasBeenDone={hasBeenDone}
                stepLabel={steps[index]}
                index={index}
                onClick={onClick}
                isCurrent={isCurrent}
                isFirst={isFirst}
                vertical={vertical}
                className={iconStepClassName}
                labelClassName={labelClassName}
                readonly={readonly as boolean}
                isLoading={isLoading}
              />
              {!isLast && (
                <StepperLine
                  className="h-10 flex align-middle"
                  optionProps={{
                    isXs,
                    hasBeenDone,
                    vertical,
                    isCurrent,
                  }}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};
