/* eslint-disable radix */
import { Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ButtonPagination } from '../../buttons';
import { Select, SelectValueType } from '../../inputs/Select';

const defaultAvailablePageSizes = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '50', label: '50' },
];

type Props<T> = {
  table: Table<T>;

  totalCount?: number;
  availablePageSizes?: SelectValueType[];
};

export function TablePaginationFooter<T>({
  table,
  totalCount,
  availablePageSizes = defaultAvailablePageSizes,
}: Props<T>) {
  const { t } = useTranslation();
  const { data } = table.options;
  if (!data || data.length === 0) {
    return null;
  }

  const handleOnSelectChange = (value: any) => {
    table.setPageSize(parseInt(value.value));
  };

  const paginationState = table.getState().pagination;
  const page = paginationState.pageIndex + 1;
  const perPage = paginationState.pageSize;

  const firstIndex = Math.round((page - 1) * perPage);

  const theoreticalLastIndex = Math.round(page * perPage);
  const lastIndex = totalCount
    ? Math.min(theoreticalLastIndex, totalCount)
    : theoreticalLastIndex;

  return (
    <div className="flex items-center justify-between mt-[35px]">
      <div className="flex items-center ml-[77px]">
        <p className="font-bold mr-[30px]">
          {/* {page} sur {table.getPageCount()} */}
          {firstIndex + 1}-{lastIndex}
          {totalCount && ` sur ${totalCount}`}
        </p>
        <ButtonPagination
          prev
          onClick={table.previousPage}
          disabled={!table.getCanPreviousPage()}
          className="mr-[9px]"
        />
        <ButtonPagination
          disabled={!table.getCanNextPage()}
          onClick={table.nextPage}
        />
      </div>
      <Select
        placeholder={perPage.toString()}
        className="mr-[162px]"
        label={t('front.payslip.index.table.pagination.footer') as string}
        options={availablePageSizes}
        value={{ value: perPage.toString(), label: perPage.toString() }}
        onChange={handleOnSelectChange}
        isClearable={false}
      />
    </div>
  );
}
