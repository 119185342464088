/* eslint-disable testing-library/render-result-naming-convention */
import { Cell, flexRender } from '@tanstack/react-table';
import dayjs from 'dayjs';
import React, { ReactElement } from 'react';
import { AmountWithCurrency } from '../../fragments/AmountText';
import { formatNumber } from '../../../utils/maths.utils';
import Status, { StatusType } from '../../statuses/Status';
import { isMoneyType } from '../../../utils/type.utils';

type TableCellProps<TData, TValue> = {
  cell: Cell<TData, TValue>;
  isHoveringRow?: boolean;
};

export function TableCell<TData, TValue>({
  cell,
  isHoveringRow,
}: TableCellProps<TData, TValue>): ReactElement {
  const value = cell.getValue();
  const columnId = cell.column.id;
  const cellContentComponent = cell.column.columnDef.cell;
  const cellContent = flexRender(cellContentComponent, {
    ...cell.getContext(),
    isHoveringRow,
  });

  if (
    (columnId === 'actions' || columnId === 'custom') &&
    React.isValidElement(cellContent)
  )
    return cellContent;

  if (columnId.split('_').includes('status') || columnId === 'status') {
    return (
      <div className="flex justify-center h-[100%] items-center">
        <Status statusType={value as StatusType} />
      </div>
    );
  }

  const ColumnIds = [
    'income_id',
    'revenue_id',
    'id',
    'missing',
    'in_progress',
    'total',
    'submitted',
    'validated',
    'closed',
    'less_than_24h',
    'between_24_and_48h',
    'between_48_and_72h',
    'more_than_72h',
    'autovalidated',
  ];

  if (typeof value === 'number') {
    if (ColumnIds.includes(columnId)) {
      return (
        <div className="flex pr-[26px] h-[100%] items-center justify-end">
          {value}
        </div>
      );
    }
    return (
      <div className="flex pr-[26px] h-[100%] items-center justify-end">
        {formatNumber(value)}
      </div>
    );
  }

  if (dayjs(value as string, 'YYYY-MM-DD', true).isValid()) {
    return (
      <div className="flex h-[100%] pl-[20px] font-cabin-regular items-center">
        {dayjs(value as string).format('L')}
      </div>
    );
  }

  if (typeof value === 'string')
    return (
      <div className="flex pl-7 pr-3 text-left py-2 h-[100%] items-center">
        {cellContent}
      </div>
    );

  if (isMoneyType(value))
    return (
      <div className="flex pl-7 pr-3 h-[100%] items-center justify-end">
        <AmountWithCurrency
          amount={value?.amount}
          currencyIsoCode={value?.currency}
        />
      </div>
    );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{cellContent}</>;
}
