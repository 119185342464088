import { LoaderFunctionArgs, Params, redirect } from 'react-router-dom';

export type UrlBuilder = (params: Params) => string;

type RedirectLoaderFunction = (args: LoaderFunctionArgs) => Response;

type RedirectToOpts = {
  keepSearch?: boolean;
  permanent?: boolean;
};

export default function redirectTo(
  builder: UrlBuilder | string,
  opts: RedirectToOpts = { keepSearch: false, permanent: false },
): RedirectLoaderFunction {
  return ({ params, request }) => {
    const statusCode = opts.permanent ? 301 : 302;
    let url = typeof builder === 'string' ? builder : builder(params);
    const originalUrl = new URL(request.url);
    const searchTerm = originalUrl.searchParams;

    // Append query params to the new url if searchTerm is not empty
    if (opts.keepSearch && searchTerm.toString()) {
      url += `?${searchTerm.toString()}`;
    }

    // If url start with /v2, use redirect, else use window.location
    if (url.startsWith('/v2') || !url.startsWith('/')) {
      return redirect(url, statusCode);
    }

    document.location.replace(url);
    return redirect(url, statusCode); // This is just to make TS happy, it will never be reached
  };
}
