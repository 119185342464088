import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from '@/app/assets/icons/buttons';
import { IconButtonSecondary } from '@/modules/common/components/buttons';
import { useMemo } from 'react';
import { MultipartOperationOperation } from '../../../../app/spraypaint';
import { MainCard } from '../../../common/components/mainCard';
import { LineText } from '../../../common/components/fragments/LineText';
import PartsTable from '../components/PartsTable';

function MultipartOperationShow(): JSX.Element {
  const { t } = useTranslation();
  const { id: operationId } = useParams();

  const { data, isLoading } = useQuery(['operation', operationId], () =>
    MultipartOperationOperation.selectExtra(['reportTxt']).find(
      operationId as string,
    ),
  );

  const reportBlob = useMemo(
    () =>
      data?.data?.reportTxt
        ? new Blob([data?.data?.reportTxt], { type: 'text/plain' })
        : undefined,
    [data?.data?.reportTxt],
  );

  if (!operationId) {
    return <div>Operation not found</div>;
  }

  const { data: operation } = data || {};

  return (
    <div className="my-[50px] grid grid-cols-1 2xl:grid-cols-5 grid-flow-row">
      <MainCard
        header={t('front.multipart_operations-operation.titles.operation')}
        isLoading={isLoading}
        className="mt-[20px] ml-5 col-span-2"
      >
        <LineText
          label={t('front.multipart_operations-operation.fields.name')}
          value={operation?.name}
        />
        <LineText
          label={t('front.multipart_operations-operation.fields.status')}
          value={operation?.status}
        />
      </MainCard>
      <MainCard
        header={{
          title: t('front.multipart_operations-operation.titles.report'),
          actionButtons: reportBlob && (
            <IconButtonSecondary
              Icon={DownloadIcon}
              href={URL.createObjectURL(reportBlob)}
              download={`${operation?.name} - ${operation?.createdAt} - report.txt`}
            />
          ),
        }}
        isLoading={isLoading}
        className="mt-[20px] ml-5 col-span-3"
      >
        <pre className="overflow-x-auto break-words whitespace-pre-wrap max-h-[650px]">
          {operation?.reportTxt}
        </pre>
      </MainCard>
      <MainCard
        header={t('front.multipart_operations-operation.titles.parts')}
        isLoading={isLoading}
        className="mt-[20px] ml-5 col-span-5"
      >
        <PartsTable operationId={operationId} />
      </MainCard>
    </div>
  );
}

export default MultipartOperationShow;
