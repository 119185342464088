import { useTranslation } from 'react-i18next';
import { PeriodType } from './type.utils';

export const getLastDayOfMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const getFirstDayOfMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth(), 1);

export const getMonthName = (
  year: number,
  monthNumber: number,
  monthFormatOption: 'long' | 'short' = 'long',
): string => {
  const { i18n } = useTranslation();
  const date = new Date(year, monthNumber - 1);
  return date.toLocaleString(i18n.language, {
    month: monthFormatOption,
  });
};

// To avoid time zone problems when comparing dates
export const convertDateToUTC = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );

export const getMonthPreposition = (month: number): string => {
  const { language } = useTranslation().i18n;

  let monthPrep = '';
  switch (language) {
    case 'fr':
      monthPrep = [4, 8, 10].includes(month) ? "d'" : 'de ';
      break;
    default:
      return '';
  }
  return monthPrep;
};

export const periodToMonthYear = (period: PeriodType): string => {
  const { language } = useTranslation().i18n;
  const date = new Date();
  date.setMonth(period && period.month - 1);
  return date.toLocaleString(language, { month: 'long' });
};

export const getPrepMonthName = (period: PeriodType): string => {
  const monthName = periodToMonthYear(period);

  return `${getMonthPreposition(period && period.month)}${monthName}`;
};

export const monthsNumberToInclude = (
  period: PeriodType,
  month: string,
  t: any,
) =>
  [4, 8, 10].includes(period?.month)
    ? t('front.payslip.tdc.title1', {
        currentMonth: month,
      })
    : t('front.payslip.tdc.title2', {
        currentMonth: month,
      });

export const getActualPayslipPeriod = (): PeriodType => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (month === 1 && day < 15) {
    return { month: 12, year: year - 1 };
  }

  if (day < 15) {
    return { month: month - 1, year };
  }

  return { month, year };
};
