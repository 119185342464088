import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import useAuthClient, { AuthErrorResponse } from './useAuthClient';

export type ResetPasswordVariables = {
  // The reset password token sent in the mail
  key: string;

  // The new password
  password: string;
  password_confirm: string;
};

export type ResetPasswordData = {
  success: string;
};

export const useResetPassword = (
  opts: UseMutationOptions<
    AxiosResponse<ResetPasswordData>,
    AxiosError<AuthErrorResponse>,
    ResetPasswordVariables
  > = {},
) => {
  const authClient = useAuthClient();
  return useMutation({
    mutationFn: (data) =>
      authClient.post<ResetPasswordData>('/reset-password', data),
    ...opts,
  });
};
