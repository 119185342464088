import classNames from 'classnames';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { MoneyType } from '../../../common/utils/type.utils';

export const AmountEntry = ({
  label,
  value,
  customClassName,
}: {
  label?: string;
  value: MoneyType;
  customClassName?: string;
}) => {
  const customSpanClass = classNames(
    'text-color-dark-content',
    customClassName || 'text-xl1',
  );

  return (
    <div className="my-2 pr-5 flex w-[250px] flex-col items-left mr-20">
      <p className="text-color-dark-content text-sm2">{label}</p>
      <AmountWithCurrency
        amount={value}
        spanClass={customSpanClass}
        currencyClass="top-[-10px] text-base2 ml-1"
      />
    </div>
  );
};
