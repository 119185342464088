type ClearButtonProps = {
  onClick: () => void;
  customClassName?: string;
};

export function ClearButton({ onClick, customClassName }: ClearButtonProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`absolute ${customClassName}`}
    >
      x
    </button>
  );
}
