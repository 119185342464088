import { useTranslation } from 'react-i18next';
import { Table, flexRender, HeaderContext } from '@tanstack/react-table';

type Props<TData> = {
  table: Pick<
    Table<TData>,
    | 'getAllColumns'
    | 'getIsAllColumnsVisible'
    | 'getToggleAllColumnsVisibilityHandler'
    | 'getAllLeafColumns'
  >;
};

export function ColDisplayOption<TData>({ table }: Props<TData>) {
  const { t } = useTranslation();
  const isAllColumnsVisible = table.getIsAllColumnsVisible();
  return (
    <div>
      <div className="px-2 border-b border-black bg-color-light-grey">
        <label className="flex py-2" htmlFor="toggle_all">
          <input
            data-testid="toggle_all"
            id="toggle_all"
            type="checkbox"
            checked={isAllColumnsVisible}
            onChange={table.getToggleAllColumnsVisibilityHandler()}
          />
          <p className="ml-3">
            {t(
              `front.table.columns.${
                isAllColumnsVisible ? 'hideAll' : 'showAll'
              }`,
            )}
          </p>
        </label>
      </div>
      <div className="flex py-6 px-2">
        <p className="mr-3">{t('front.table.columns.displayChoice')}</p>
        {table.getAllLeafColumns().map((column) => {
          const ColumnHeaderComponent = column.columnDef.header;

          return (
            <div key={column.id} className="px-2">
              <label className="flex" htmlFor={column.id}>
                <input
                  id={column.id}
                  type="checkbox"
                  checked={column.getIsVisible()}
                  onChange={column.getToggleVisibilityHandler()}
                />
                <p className="ml-2">
                  {flexRender(ColumnHeaderComponent, {
                    table,
                    column,
                  } as HeaderContext<TData, unknown>)}
                </p>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
