import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';
import type {
  CurrencyIsoCode,
  DocumentLanguageEnum,
  VatCgiSlugEnum,
} from '@/modules/common/utils/type.utils';
import type { CommercialContract, InvoiceDraftLine } from '@spraypaint/index';
import ApplicationRecord from './ApplicationRecord';

export const STATUSES = ['in_progress', 'submitted'] as const;
export type StatusEnum = typeof STATUSES[number];

@Model()
class InvoiceDraft extends ApplicationRecord {
  static jsonapiType = 'invoice_drafts';

  // Attributes
  @Attr() status!: StatusEnum;
  @Attr() language!: DocumentLanguageEnum;
  @Attr() currencyIsoCode!: CurrencyIsoCode;
  @Attr({ persist: false }) totalAmountCents!: number;
  @Attr({ persist: false }) totalAmountWithVatCents!: number;
  @Attr() date!: string;
  @Attr() label!: string;
  @Attr() externalReference!: string;
  @Attr() vatCgiSlug!: VatCgiSlugEnum;
  @Attr() commercialContractId!: number;
  @Attr({ persist: false }) purchaseOrderId!: number;
  @Attr({ persist: false }) debtorType!: string;
  @Attr({ persist: false }) debtorId!: number;
  @Attr({ persist: false }) originType!: string;
  @Attr({ persist: false }) originId!: number;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;

  // Extra-attributes

  // Relationships
  @BelongsTo('commercial_contracts') commercialContract!: CommercialContract;
  @HasMany('invoice_draft_lines') invoiceDraftLines!: Array<InvoiceDraftLine>;
}

export default InvoiceDraft;
