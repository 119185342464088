import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainCard } from '@/modules/common/components/mainCard';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { useInvoice } from '@/modules/invoices/functions/invoice_query';
import { InvoiceLinesCard } from '@/modules/admin/invoices/components/InvoiceLinesCard';
import { AttributeLine } from '@/modules/common/components/AttributeLine';
import { LinkLine } from '@/modules/common/components/LinkLine';
import { ProvisionalInvoicesCard } from '@/modules/admin/invoices/components/ProvisionalInvoicesCard';
import {
  InvoiceDownLoadButton,
  InvoiceEditButton,
  InvoiceEmployeeViewButton,
} from '@/modules/admin/invoices/components/InvoiceActionsButton';
import { CommentWidget } from '@/modules/common/components/comments/CommentWidget';
import { CopyButton } from '@/modules/common/components/buttons/utils/CopyButton';
import Status from '@/modules/common/components/statuses/Status';
import { TransitionsHeaderButtons } from '@/modules/layout/components/TransitionsHeaderButtons';
import { Invoice } from '@/app/spraypaint';
import { AssignedAmountsCard } from '@/modules/admin/invoices/components/AssignedAmountsCard';
import { InvoicePaymentsCard } from '@/modules/admin/invoices/components/InvoicePaymentsCard';
import InvoiceSkeleton from '../components/InvoiceSkeleton';

export const AdminInvoiceShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data, refetch } = useInvoice(id as string);

  const invoice = data?.data;
  if (!invoice) {
    return <InvoiceSkeleton />;
  }

  const { commercialContract, debtor, bankAccount, externalReference } =
    invoice;
  const { client } = commercialContract ?? {};

  return (
    <PageLayout
      Title={t('front.invoices.show.header.title', {
        number: invoice.number,
      })}
      Status={<Status statusType={invoice.status} className="py-1" />}
      Buttons={
        <div className="flex">
          <InvoiceDownLoadButton invoice={invoice} />
          <InvoiceEmployeeViewButton invoice={invoice} />
          <InvoiceEditButton />
          <TransitionsHeaderButtons record={invoice} refresh={refetch} />
        </div>
      }
    >
      <CommentWidget commentableType="Invoice" commentableId={invoice?.id} />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <MainCard header="Client">
          <AttributeLine
            id="external_reference"
            label={t('activerecord.attributes.invoice.external_reference', {
              ns: 'rails',
            })}
            value={
              externalReference && (
                <>
                  {externalReference}
                  <CopyButton value={externalReference} />
                </>
              )
            }
          />
          <LinkLine
            id="corporateInvoice"
            label={t('activerecord.attributes.invoice.corporate_invoice', {
              ns: 'rails',
            })}
            target={
              new Invoice({
                id: invoice.corporateInvoiceId,
                number: 'Facture Corporate',
              })
            }
            hideEmpty
          />
          <LinkLine
            id="client"
            label={t('activerecord.attributes.invoice.client')}
            target={client}
            hideEmpty
          />
          <LinkLine
            id="commercial-contract"
            label={t('activerecord.attributes.invoice.commercial_contract', {
              ns: 'rails',
            })}
            target={commercialContract}
            hideEmpty
          />
          <LinkLine
            id="debtor"
            label={t('activerecord.attributes.invoice.debtor', {
              ns: 'rails',
            })}
            target={debtor}
            seeMore={t('front.invoices.seeMore.debtor')}
          />
          <AttributeLine
            id="date"
            label={t('activerecord.attributes.invoice.date', {
              ns: 'rails',
            })}
            value={invoice?.date?.toString()}
          />
          <AttributeLine
            id="payment_time"
            label={t(
              'activerecord.attributes.commercial_contract.payment_time',
              {
                ns: 'rails',
              },
            )}
            value={
              commercialContract?.paymentTime &&
              t(
                `activerecord.enums.commercial_contract.payment_time_enum.${commercialContract?.paymentTime}`,
              )
            }
          />
          <AttributeLine
            id="due_date"
            label={t('activerecord.attributes.invoice.due_date', {
              ns: 'rails',
            })}
            value={invoice?.dueDate}
          />
          <AttributeLine
            id="sending_type"
            label={t(
              'activerecord.attributes.commercial_contract.sending_type',
              {
                ns: 'rails',
              },
            )}
            value={
              commercialContract?.sendingType &&
              t(
                `activerecord.enums.commercial_contract.sending_type_enum.${commercialContract?.sendingType}`,
              )
            }
          />
          <AttributeLine
            id="payment_method"
            label={t(
              'activerecord.attributes.commercial_contract.payment_method',
              {
                ns: 'rails',
              },
            )}
            value={
              commercialContract?.paymentMethod &&
              t(
                `activerecord.enums.commercial_contract.payment_methods.${commercialContract?.paymentMethod}`,
              )
            }
          />
          <AttributeLine
            id="bank_account"
            label={t('activerecord.attributes.invoice.bank_account', {
              ns: 'rails',
            })}
            value={
              bankAccount && (
                <>
                  {bankAccount.accountHolder}
                  <br />
                  {bankAccount.iban} <CopyButton value={externalReference} />
                </>
              )
            }
          />
        </MainCard>
        <MainCard header={t('front.invoices.cards.admin.title')}>
          GESTION
        </MainCard>
        <InvoicePaymentsCard invoice={invoice} />
        <AssignedAmountsCard invoice={invoice} />
      </div>
      <div className="mt-5 grid grid-cols-1 gap-6">
        <InvoiceLinesCard invoice={invoice} />
      </div>
      <div className="mt-5 grid grid-cols-1 gap-6">
        <ProvisionalInvoicesCard invoice={invoice} />
      </div>
    </PageLayout>
  );
};
