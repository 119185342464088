import { Trans, useTranslation } from 'react-i18next';
import { MainCard } from '../../../common/components/mainCard';
import { HelpOutlineIcon } from '../../../../app/assets/icons/inputs';
import { LineMainCardText } from '../LineMainCardText';
import useBreakpoints from '../../../../app/responsive/useBreakPoints';

export type SalaryCostCardProps = {
  previousBalance: number;
  payslipCost?: number;
  incomingBalance?: number;
  isPayslipFunnel?: boolean;
};

export const SalaryCostCard = ({
  isPayslipFunnel,
  previousBalance,
  payslipCost,
  incomingBalance,
}: SalaryCostCardProps) => {
  const { isXs } = useBreakpoints();
  const { t } = useTranslation();

  return (
    <MainCard
      className="h-[258px] mt-[22px] md:mt-0"
      header={t('front.payslip.show.salaryCreationCard.title')}
    >
      {isPayslipFunnel && (
        <LineMainCardText
          label={t('front.payslip.show.salaryCreationCard.previousBalance')}
          value={previousBalance}
          displayAmountBigger={!isXs}
        />
      )}
      <LineMainCardText
        label={t('front.payslip.show.salaryCreationCard.payslipCost')}
        value={payslipCost || 0}
        Icon={HelpOutlineIcon}
        tooltipId="payslipCostTooltip"
        tooltipMsgComp={
          <Trans i18nKey="front.payslip.show.salaryCreationCard.tooltip" />
        }
        displayAmountBigger={!isXs}
      />
      {isPayslipFunnel && (
        <LineMainCardText
          label={t('front.payslip.show.salaryCreationCard.incomingBalance')}
          value={incomingBalance || 0}
          displayAmountBigger={!isXs}
        />
      )}
    </MainCard>
  );
};
