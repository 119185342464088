// api/IncomeSummary.ts
import { Model, Attr } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class IncomeSummary extends ApplicationRecord {
  static jsonapiType = 'income_summaries';

  // Attributes
  @Attr({ persist: false }) incomeId!: number;
  @Attr({ persist: false }) incomeType!: string;
  @Attr({ persist: false }) ownerId!: number;
  @Attr({ persist: false }) ownerType!: string;
  @Attr({ persist: false }) date!: string;
  @Attr({ persist: false }) number!: string;
  @Attr({ persist: false }) externalReference!: string;
  @Attr({ persist: false }) clientName!: string;
  @Attr({ persist: false }) paidAmount!: MoneyType;
  @Attr({ persist: false }) sendDate!: string;
  @Attr({ persist: false }) status!: string;
  @Attr({ persist: false }) dueDate!: string;
  @Attr({ persist: false }) lastPaymentDate!: string;

  // Relationships
  // Polymorphic relation owner : TBD
  // Polymorphic relation income : TBD
}

export default IncomeSummary;
