import { RowData, Table } from '@tanstack/react-table';
import { LoaderIcon } from '../../../../../app/assets/icons/inputs';

export const LoadingTableLayout = <TData extends RowData>({
  table,
}: {
  table: Pick<Table<TData>, 'getAllColumns'>;
}) => (
  <tbody className="">
    <tr className="bg-color-light-grey ">
      <td
        colSpan={table.getAllColumns().length}
        // className="bg-color-light-grey justify-between pt-[32px] pb-[19px]"
      >
        <div className="flex flex-col items-center justify-center text-italic text-color-medium-grey text-base2 pt-[78px] pb-[106px]">
          <LoaderIcon
            data-testid="loader-icon"
            className="w-[77px] h-[80px] animate-spin fill-color-pone-orange"
          />
        </div>
      </td>
    </tr>
  </tbody>
);
