import { QueryClient, useMutation } from '@tanstack/react-query';
import { NotificationsMessage } from '../../../app/spraypaint';

export const useReadNotification = () => {
  const queryClient = new QueryClient();
  const data = useMutation({
    mutationFn: async (id: string) => {
      const newNotificationMessage = new NotificationsMessage({
        id,
      });
      newNotificationMessage.isPersisted = true;
      newNotificationMessage.status = 'read';
      return newNotificationMessage.save();
    },
    onSuccess: () => {
      console.log('here');
      queryClient.invalidateQueries(['get-notifications-messages']);
      queryClient.invalidateQueries(['get-unread-notifications-messages']);
    },
  });
  return { ...data };
};
