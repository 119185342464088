import { type FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainCard } from '@/modules/common/components/mainCard';
import { Invoice } from '@/app/spraypaint';
import Table from '@/modules/common/components/table/Table';
import { useNavigate } from 'react-router-dom';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { toast } from 'react-toastify';
import { Toaster } from '@/modules/common/components/toasts';
import { useProvisionalInvoices } from '@/modules/admin/invoices/hooks/useProvisionalInvoices';
import { ModalState } from '@/modules/admin/invoices/types/ModalState';
import { getProvisionalInvoicesColumns } from '@/modules/admin/invoices/columns/provisionalInvoicesColumns';
import { ProvisionalInvoicePaymentModal } from './ProvisionalInvoicePaymentModal';

export interface ProvisionalInvoicesCardProps {
  invoice: Invoice;
}

export const ProvisionalInvoicesCard: FunctionComponent<
  ProvisionalInvoicesCardProps
> = ({ invoice }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, reactTableProps, refetch } = useProvisionalInvoices({
    invoiceId: invoice.id,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [paymentModalProvisionalInvoice, setPaymentModalProvisionalInvoice] =
    useState<ModalState | undefined>(undefined);

  if (invoice.invoiceType !== 'corporate') {
    return null;
  }

  const dissociateInvoice = async (id: string | undefined) => {
    setIsLoading(true);
    const inv = new Invoice({ id });
    inv.isPersisted = true;
    inv.corporateInvoiceId = null;
    const success = await inv.save();

    if (success) {
      toast.success(
        <Toaster
          textMsg={t('views.shared.change_applied')}
          toastType="success"
        />,
      );
      await refetch();
      setIsLoading(false);
    }
  };

  const columns = getProvisionalInvoicesColumns({
    isLoading,
    dissociateInvoice,
    setPaymentModalProvisionalInvoice,
  });

  const totalCount = data?.meta.stats.total.count;
  const invoices = data?.data ?? [];
  return (
    <MainCard
      header={{
        title: t('front.invoices.cards.provisional_invoices.title'),
        actionButtons: (
          <ButtonPrimary
            onClick={() =>
              navigate(
                `/v2/admin/invoices/${invoice.id}/add_provisional_invoice`,
              )
            }
            className="ml-3"
          >
            {t('default.button.add')}
          </ButtonPrimary>
        ),
      }}
    >
      <ProvisionalInvoicePaymentModal
        modalProps={{
          isOpen: paymentModalProvisionalInvoice !== undefined,
          onRequestClose: () => setPaymentModalProvisionalInvoice(undefined),
        }}
        corporateInvoiceId={invoice.id}
        provisionalInvoiceId={paymentModalProvisionalInvoice?.id}
        amount={paymentModalProvisionalInvoice?.remainingAmount}
        afterSubmit={() => setPaymentModalProvisionalInvoice(undefined)}
      />
      <Table
        data={invoices}
        columns={columns}
        onRowClick={{
          navigate: (i) => i.adminPagePath,
        }}
        reactTableProps={reactTableProps}
        totalCount={totalCount}
      />
    </MainCard>
  );
};
