// api/PayslipFunnels::ActivityAccountLine.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from '../ApplicationRecord';
import PayslipFunnel from './PayslipFunnel';

@Model()
class PayslipFunnelsActivityAccountLine extends ApplicationRecord {
  static jsonapiType = 'payslip_funnels-activity_account_lines';

  // Attributes
  @Attr({ persist: false }) payslipFunnelId!: number;
  @Attr({ persist: false }) date!: string;
  @Attr({ persist: false }) label!: string;
  @Attr({ persist: false }) value!: MoneyType;
  @Attr({ persist: false }) accountableInfo!: {
    [number: number]: string;
  };
  @Attr({ persist: false }) classification!: string;

  // Relationships
  @BelongsTo(PayslipFunnel) payslipFunnel!: PayslipFunnel[];
}

export default PayslipFunnelsActivityAccountLine;
