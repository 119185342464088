import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TutorialOnIcon } from '../../../../app/assets/icons/tutorial';
import { ButtonPrimary, Switch } from '../../../common/components/buttons';
import { useToggleTutorial } from '../../../widgets/hooks/ToggleTutorial';

export const CommonTutorial = ({
  Component,
  onClick,
  Icon,
}: {
  Component: ReactNode;
  onClick: any;
  Icon: any;
}) => {
  const { isTutorialOn, toggleAllTutorial } = useToggleTutorial();
  const { t } = useTranslation();
  return (
    <div className="mt-[37px]">
      <div className="flex justify-end mr-[30px] items-center">
        <TutorialOnIcon
          height={20}
          width={30}
          className="mr-[9px] fill-color-pone-orange"
        />
        <p className="mr-[20px] uppercase">
          {t('front.payslip.tdc.commonTutorial.title')}
        </p>
        <Switch
          checked={isTutorialOn}
          onChange={toggleAllTutorial()}
          className="mr-[30px]"
        />
      </div>
      <div className="flex ml-[94px] mt-[46px]">
        <div>{Icon}</div>
        <div className="flex-col ml-[46px] mr-[113px]">
          {Component}
          <ButtonPrimary onClick={onClick} className="w-[131px] mt-[32px]">
            {t('front.payslip.tdc.commonTutorial.button.title')}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};
