/* eslint-disable radix */
import { ComponentProps } from 'react';
import { FilterFn, RowData } from '@tanstack/react-table';
import { MaskedTextInput } from '../../inputs/MaskedTextInput';
import { FilterComponentProps, PriumFilterFn } from './types';
import { TextFilter } from './TextFilter';
import { TextInput } from '../../inputs';

export type PeriodFilterOptions = Partial<
  ComponentProps<typeof MaskedTextInput>
>;

export type TextInputOptions = Partial<ComponentProps<typeof TextInput>>;

export function getBooleanFilter<TData extends RowData>(): FilterFn<TData> {
  const filter: PriumFilterFn<TData> = (
    row,
    columnId,
    filterValue: unknown,
  ) => {
    const value = row.getValue(columnId);

    if (value === null) return false;

    return value === filterValue;
  };

  filter.backendFilterFn = (columnId, value: string) => {
    const cleanedValue = value.trim().toLocaleLowerCase();
    if (!['oui', 'yes', 'true', 'non', 'no', 'false'].includes(cleanedValue))
      return {};

    const columnName = columnId.split('_')[0];
    const boolean = ['oui', 'yes', 'true'].includes(cleanedValue);

    return {
      [columnName]: { eq: boolean },
    };
  };

  filter.resolveFilterValue = (value) => value;

  return filter;
}

const inputProps = {
  placeholder: 'Oui / Non',
  inputCustomClass: 'no-arrow w-[133px]',
  type: 'string',
};

export function BooleanFilter<TData, TValue extends string>({
  options,
  ...props
}: FilterComponentProps<TData, TValue, PeriodFilterOptions>) {
  const optionsWithDefaults = {
    ...inputProps,
    ...options,
  };

  return <TextFilter {...props} options={optionsWithDefaults} />;
}
