import type { ReactNode } from 'react';
import { Breadcrumbs } from '@/modules/navigation/components/Breadcrumbs';
import { Renderable } from '@/types';
import flexRender from '@/app/utils/flexRender';

export interface PageLayoutProps {
  Title: Renderable<unknown>;
  Buttons?: Renderable<unknown>;
  Status?: Renderable<unknown>;
  children?: ReactNode | ReactNode[];
}

export function PageLayout({
  Title,
  Buttons,
  Status,
  children,
}: PageLayoutProps): JSX.Element {
  return (
    <div className="my-[50px] mx-5">
      <div className="flex md:justify-between px-[20px] mt-[20px] md:mt-[44px]">
        {Title === null ? null : (
          <p className="big-title mb-2">{flexRender(Title, {})}</p>
        )}
        {Status === null ? null : (
          <p className="big-title">{flexRender(Status, {})}</p>
        )}
        {Buttons === null ? null : (
          <p className="big-title">{flexRender(Buttons, {})}</p>
        )}
      </div>
      <Breadcrumbs />
      <div className="mt-5">{children}</div>
    </div>
  );
}
