import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import { LinkPropsBuilder } from '@/types';
import ApplicationRecord from './ApplicationRecord';
import type Invoice from './Invoice';
import type LegalEntity from './LegalEntity';
import type RegisteredClient from './RegisteredClient';

@Model()
class FrameworkContract extends ApplicationRecord {
  static jsonapiType = 'framework_contracts';

  constructor(...args: ConstructorParameters<typeof ApplicationRecord>) {
    super(...args);
    this.linkProps = this.linkProps.bind(this);
  }

  // Attributes
  @Attr() legalEntityId!: number;
  @Attr() registeredClientId!: number;
  @Attr() name!: string;
  @Attr() operatingUnitName!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;

  // Extra-attributes

  // Relationships
  @BelongsTo('legal_entities') legalEntity!: LegalEntity;
  @BelongsTo('registered_clients') registeredClient!: RegisteredClient;
  @HasMany('invoices') provisional_invoices!: Invoice[];

  linkProps: LinkPropsBuilder = ({ pathBuilder }) => ({
    children: this.name,
    to: pathBuilder(
      `/registered_clients/${this.registeredClientId}/framework_contracts/${this.id}`,
    ),
  });
}

export default FrameworkContract;
