import { useTranslation } from 'react-i18next';
import { PayslipDetailCard, CraShow, TimeEntry } from '../../components';

export type CraStepDetailsProps = {
  craStep: any;
  activityReport: any;
  displayAll: boolean;
};

export const CraStepDetails = ({
  craStep,
  activityReport,
  displayAll,
}: CraStepDetailsProps) => {
  const { t } = useTranslation();

  const details = (
    <div className="mt-16 mb-10 bg-color-transparent-medium-grey py-10 pl-[100px]">
      <CraShow activityReportSlots={activityReport?.activityReportSlots} />
    </div>
  );

  return (
    <PayslipDetailCard
      user={craStep?.completed_by_name}
      date={craStep?.completed_at}
      title={t('views.payslip.cra')}
      displayAll={displayAll}
      allDetail={details}
    >
      <div className="flex flex-wrap">
        <TimeEntry
          label={t('front.payslip.tdc.cra.daysWorked')}
          days={craStep.worked_days}
          hours={craStep.worked_hours}
        />
        <TimeEntry
          label={t('front.payslip.tdc.cra.teleworkingDays')}
          days={craStep.teleworked_days}
          hours={craStep.teleworked_hours}
        />
        {craStep.holidays_hours > 0 && craStep.holidays_hours !== null && (
          <TimeEntry
            label={t('front.payslip.tdc.cra.holidays')}
            days={craStep.holidays_days}
            hours={craStep.holidays_hours}
          />
        )}
        {craStep.absence_days > 0 && craStep.absence_days !== null && (
          <TimeEntry
            label={t('front.payslip.tdc.cra.absenceDays')}
            days={craStep.absence_days}
            hours={craStep.absence_hours}
          />
        )}
        {craStep.sickness_leave_days > 0 &&
          craStep.sickness_leave_days !== null && (
            <TimeEntry
              label={t('front.payslip.tdc.buttoncra.sick').toUpperCase()}
              days={craStep.sickness_leave_days}
              hours={craStep.sickness_absence_hours}
            />
          )}
        {craStep.parental_absence_days > 0 &&
          craStep.parental_absence_days !== null && (
            <TimeEntry
              label={t(
                'front.payslip.tdc.buttoncra.parentalAbsence',
              ).toUpperCase()}
              days={craStep.parental_absence_days}
              hours={craStep.parental_absence_hours}
            />
          )}
      </div>{' '}
    </PayslipDetailCard>
  );
};
