import { FunctionComponent } from 'react';
import { MainCard } from '@common/components/mainCard';
import { ButtonPrimary } from '@common/components/buttons';
import FinalStep from '@spraypaint/onBoarding/FinalStep';
import { Controller, useForm } from 'react-hook-form';
import { PhoneNumberInput } from '@common/components/inputs';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FinalStepFormState,
  FinalStepType,
} from '@/modules/welcome/types/OBFinalStepType';
import { useValidation } from '@/app/form/ValidationForm';
import { useCurrentUser } from '@/modules/authentification/hooks';
import useInvalidateQueryChannel from '@/app/channels/invalidateQueryChannel';

export const OBFinalStep: FunctionComponent<FinalStepType> = ({
  onboardingFunnel,
  saveStep,
}) => {
  const { t } = useTranslation();
  const { finalStep } = onboardingFunnel;
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cellphone: finalStep.cellphone,
    },
  });
  const Validation = useValidation();

  const { user } = useCurrentUser();

  useInvalidateQueryChannel(
    {
      resource_id: user?.id,
      resource_type: 'user',
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      received: (data: any) => {
        console.log('data finalstep', data);
        queryClient.invalidateQueries(['current-onboarding-funnel']);
      },
    },
    [finalStep.id, user],
  );

  const onSubmit = (data: FinalStepFormState) => {
    saveStep({
      data,
      stepToSave: new FinalStep({
        id: finalStep.id,
      }),
    });
  };

  return (
    <MainCard
      header={t('front.welcome.onboarding.steps.finalStep')}
      className="mt-[20px] mx-5 md:w-[800px]"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex">
          <div className="flex-col">
            <Controller
              control={control}
              defaultValue=""
              rules={{ ...Validation.anyRequired }}
              render={({ field: { onChange, value } }) => (
                <PhoneNumberInput
                  label={
                    t('activerecord.attributes.contact.cellphone') as string
                  }
                  errorText={errors?.cellphone}
                  disabled={!!finalStep.cellphone}
                  className="mt-6"
                  onChange={onChange}
                  required
                  value={value}
                />
              )}
              name="cellphone"
            />
          </div>
        </div>
        {!finalStep.cellphone && (
          <ButtonPrimary
            className="m-2"
            type="submit"
            disabled={!finalStep.portageAgreementGenerated}
          >
            {t('default.confirm')}
          </ButtonPrimary>
        )}
      </form>
      {finalStep.signatureProcedureUrl && (
        <div className="flex">
          <Link
            className="mt-10"
            target="_blank"
            to={finalStep.signatureProcedureUrl}
          >
            {t('front.welcome.onboarding.steps.finalStep.signPortageOffer')}
          </Link>
        </div>
      )}
    </MainCard>
  );
};
