import FactorTransfer from '@/app/spraypaint/factor/FactorTransfer';
import { useQuery } from '@tanstack/react-query';

const useFactorTransfer = ({
  id,
  includes = [],
}: {
  id: string;
  includes?: Array<string>;
}) => {
  const data = useQuery({
    queryKey: ['factor-transfer', id, includes],
    queryFn: () => FactorTransfer.includes(includes).find(id),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const factorTransfer = data?.data?.data;
  const factorTransferreds = factorTransfer?.transferreds;

  return { ...data, factorTransfer, factorTransferreds };
};

export default useFactorTransfer;
