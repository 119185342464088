import { useBackendTable } from '@/modules/common/components/table/hooks';
import Table from '@/modules/common/components/table/Table';
import { MainCard } from '@/modules/common/components/mainCard';
import { CheckBox } from '@/modules/common/components/inputs';
import { Controller, useForm } from 'react-hook-form';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { ControlledSelect } from '@/modules/common/components/inputs/controlledInput/ControlledSelect';
import useLegalEntitiesSelectOptions from '@/modules/common/legalEntity/useLegalEntitiesSelectOptions';
import EmployeePayslip from '@/app/spraypaint/EmployeePayslip';
import { useTranslation } from 'react-i18next';
import {
  getActualPayslipPeriod,
  getMonthName,
} from '@/modules/common/utils/date.utils';
import {
  managerStatColumns,
  requestAgeStatColumns,
  statusStatColumns,
} from '@/modules/admin/payslip/columns/PayslipAdminStatColumns';
import {
  usePayslips,
  usePayslipStats,
  useYearsSelectOptions,
} from '@/modules/admin/payslip/functions';
import payslipAdminColumns from '../columns/PayslipAdminIndexColumns';
import {
  dateFilterOptions,
  monthsFilterOptions,
  statusFilterOptions,
} from '../constants/PayslipIndexSelectsConstants';
import { FilterFormInputs } from '../types/FormTypes';

export function PayslipAdminIndex(): JSX.Element {
  const { month, year } = getActualPayslipPeriod();
  const { t } = useTranslation();

  const statTitle = t('front.employeePayslip.dashboard.statistic.header', {
    month: getMonthName(year, month),
    year: year.toString(),
  });

  const defaultValues = {
    // we need to set them at false because the checkbox goes from undefined => false
    stc: undefined,
    arbitration: undefined,
    newPayslip: undefined,
    urgentTransfer: undefined,
    missing: undefined,
    status: '',
    month: month.toString(),
    year: year.toString(),
  };
  const { watch, control, reset, getValues } = useForm<FilterFormInputs>({
    defaultValues,
  });
  const watchAllField = watch();

  // watch((data, { name, type }) => console.debug({ data, name, type }));

  const areFiltersDisabled =
    !getValues('arbitration') &&
    !getValues('urgentTransfer') &&
    !getValues('missing') &&
    !getValues('newPayslip') &&
    !getValues('stc') &&
    !getValues('requestAge') &&
    !getValues('legalEntity') &&
    !getValues('year') &&
    !getValues('status') &&
    !getValues('month');

  // const navigate = useNavigate();

  const [scope, scopeKey, props] = useBackendTable<EmployeePayslip>({
    initialSorting: [{ id: 'createdAt', desc: false }],
    debounceDelay: 200,
  });

  const {
    isLoading: statLoader,
    managerStats,
    requestAgeStats,
    statusStats,
  } = usePayslipStats({
    year,
    month,
  });

  // const onRowClick =
  //   (raw: ModelRecord<EmployeePayslip> | undefined) => (event: MouseEvent) => {
  //     onRowClickNavigate({
  //       id: raw?.employeePayslipShowPagePath,
  //       event,
  //       windowNavigationHref: raw?.employeePayslipShowPagePath,
  //       navigate,
  //     });
  //   };
  const handleResetFilter = () => {
    reset();
  };

  const { isLoading, totalCount, payslips } = usePayslips({
    scope,
    scopeKey,
    watchAllField,
  });

  const yearFilterOptions = useYearsSelectOptions();

  return (
    <div className="mb-[100px]">
      <div className="mx-[20px]">
        <p className="big-title mb-2">{t('views.payslip.index_admin')}</p>
        <MainCard>
          <p className="text-xl text-color-medium-grey my-2">{statTitle}</p>
          <Table
            collapsable
            initialIsCollapsed
            isLoading={statLoader}
            title="Status stat"
            data={statusStats}
            columns={statusStatColumns}
            disabledPagination
          />
          <Table
            collapsable
            initialIsCollapsed
            data={requestAgeStats}
            isLoading={statLoader}
            title="Ancienneté de la demande statistiques"
            className="mt-[20px]"
            columns={requestAgeStatColumns}
            disabledPagination
          />
          <Table
            collapsable
            initialIsCollapsed
            data={managerStats}
            isLoading={statLoader}
            title="Manager statistiques *WIP*"
            className="mt-[20px]"
            columns={managerStatColumns}
            disabledPagination
          />
        </MainCard>
        <div className="flex mb-[20px] mt-[20px]">
          <MainCard>
            <p className="font-cabin-bold">
              Ne fonctionne que si les deux filtres ont été rempli
            </p>
            <ControlledSelect
              name="year"
              control={control}
              className="mt-[10px]"
              placeholder="Année"
              options={yearFilterOptions}
            />
            <ControlledSelect
              name="month"
              control={control}
              placeholder="Mois"
              className="mt-[10px]"
              options={monthsFilterOptions()}
            />
          </MainCard>
          <MainCard childrenClassName="flex" className="ml-[20px]">
            <div className="flex flex-col flex-2">
              <div className="flex items-center">
                <ControlledSelect
                  name="legalEntity"
                  control={control}
                  labelClassName="flex-1"
                  className="ml-[10px]"
                  label="Entité"
                  isMulti
                  options={useLegalEntitiesSelectOptions()}
                />
              </div>
              <div className="flex items-center mt-[10px]">
                <ControlledSelect
                  name="status"
                  isMulti
                  control={control}
                  labelClassName="flex-1"
                  className="ml-[10px]"
                  label="Statut"
                  options={statusFilterOptions()}
                />
              </div>
              <div className="flex items-center mt-[10px]">
                <ControlledSelect
                  labelClassName="flex-1"
                  label="Ancienneté de la demande"
                  className="ml-[10px]"
                  name="requestAge"
                  control={control}
                  options={dateFilterOptions}
                />
              </div>
            </div>
            <div className="flex flex-1 flex-col ml-[80px]">
              <Controller
                name="stc"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckBox
                    onChange={(e) => {
                      onChange(e.target.checked || undefined);
                    }}
                    checked={!!value}
                    label="STC"
                    id="stc"
                  />
                )}
              />
              <Controller
                name="newPayslip"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckBox
                    onChange={(e) => {
                      onChange(e.target.checked || undefined);
                    }}
                    checked={!!value}
                    label="Nouveau"
                    id="nouveau"
                    className="mt-[20px]"
                  />
                )}
              />
              <Controller
                name="missing"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckBox
                    onChange={(e) => {
                      onChange(e.target.checked || undefined);
                    }}
                    checked={!!value}
                    label="Absent"
                    id="missing"
                    className="mt-[20px]"
                  />
                )}
              />
              <Controller
                name="urgentTransfer"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckBox
                    onChange={(e) => {
                      onChange(e.target.checked || undefined);
                    }}
                    checked={!!value}
                    label="Virement urgent"
                    id="transfert"
                    className="mt-[20px]"
                  />
                )}
              />
              <Controller
                name="arbitration"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckBox
                    onChange={(e) => {
                      onChange(e.target.checked || undefined);
                    }}
                    checked={!!value}
                    label="Arbitrage"
                    id="arbitrage"
                    className="mt-[20px]"
                  />
                )}
              />
            </div>
            <div className="flex items-center ml-[40px]">
              <ButtonPrimary
                disabled={areFiltersDisabled}
                onClick={handleResetFilter}
              >
                {t('front.employeePayslip.dashboard.button.resetFilter')}
              </ButtonPrimary>
            </div>
          </MainCard>
        </div>
      </div>
      <Table
        displayToggleColumns
        isLoading={isLoading}
        totalCount={totalCount}
        data={payslips || []}
        reactTableProps={props}
        columns={payslipAdminColumns}
        availablePageSizes={[
          { value: '20', label: '20' },
          { value: '50', label: '50' },
          { value: '100', label: '100' },
        ]}
      />
    </div>
  );
}
