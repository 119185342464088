import { FunctionComponent, useCallback } from 'react';
import {
  CapGeminiCorporateInvoiceCreation,
  ClientPayment,
} from '@/app/spraypaint';
import {
  CustomModal,
  CustomModalProps,
} from '@/modules/common/components/fragments/CustomModal';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiValidationError } from '@spraypaint/ApiValidationError';
import { toast } from 'react-toastify';
import { Button, ButtonPrimary } from '@/modules/common/components/buttons';
import { CloseIcon } from '@/app/assets/icons/buttons';
import { ControlledDateInput } from '@/modules/common/components/inputs/controlledInput/ControlledDateInput';
import { FormState } from '@/modules/admin/capgemini/components/CoporateInvoiceCreationModal';
import { MoneyType } from '@/modules/common/utils/type.utils';
import { useHandleCloseModal } from '@/modules/admin/shared/hooks/useInvoiceHandleCloseModal';

export interface ProvisionalInvoicePaymentModalProps {
  provisionalInvoiceId: string | undefined;
  corporateInvoiceId: string | undefined;
  modalProps: CustomModalProps;
  amount: MoneyType | undefined;
  afterSubmit?: ((record: ClientPayment) => void) | undefined;
}

export const ProvisionalInvoicePaymentModal: FunctionComponent<
  ProvisionalInvoicePaymentModalProps
> = ({
  provisionalInvoiceId,
  corporateInvoiceId,
  modalProps,
  amount,
  afterSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormState>({
    defaultValues: {
      date: null,
    },
  });
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationKey: [
      'createProvisionalInvoicePayment',
      corporateInvoiceId,
      provisionalInvoiceId,
    ],
    mutationFn: async (data: FormState) => {
      const payment = new ClientPayment({
        sourceableType: 'Invoice',
        sourceableId: corporateInvoiceId,
        invoiceId: provisionalInvoiceId,
        date: data.date,
        amount,
      });
      return payment.saveOrThrow();
    },
    onSuccess: (record) => {
      queryClient.invalidateQueries([
        'provisional-invoices',
        corporateInvoiceId,
      ]);
      queryClient.invalidateQueries(['assigned-amounts', corporateInvoiceId]);
      if (afterSubmit) afterSubmit(record);
    },
    onError(error) {
      if (
        ApiValidationError.isInstance(error, CapGeminiCorporateInvoiceCreation)
      ) {
        Object.keys(error.errors).forEach((e) => {
          toast.error(error.errors[e]?.fullMessage);
        });
      }
    },
  });

  const handleCloseModal = useHandleCloseModal(isSubmitting, modalProps);

  const onSubmit = useCallback(
    handleSubmit((data) => mutateAsync(data)),
    [handleSubmit, mutateAsync],
  );
  return (
    <CustomModal {...modalProps} onRequestClose={handleCloseModal}>
      <div className="flex flex-row w-[950px] bg-color-light-grey py-[25px]">
        <div className="flex-auto ml-[25px] text-xl2">
          Créer la facture corporate
        </div>
        <Button
          onClick={handleCloseModal}
          custom
          className=" flex- mr-[25px] justify-self-end"
        >
          <CloseIcon />
        </Button>
      </div>
      <form onSubmit={onSubmit} className="p-5">
        <div className="flex">
          <div className="flex-row">
            <ControlledDateInput
              control={control}
              label="Date"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore // Thing's broken
              name="date"
              stringMode
            />
          </div>
        </div>

        <ButtonPrimary
          className="m-2"
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Submit
        </ButtonPrimary>
      </form>
    </CustomModal>
  );
};
