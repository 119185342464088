import { useTranslation } from 'react-i18next';
import { IlluMailSent } from '../../../../app/assets/icons/auth';

type Props = {
  email?: string;
};

export const ForgotPasswordSuccess = ({ email }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <IlluMailSent
        data-testid="forgotpassword-success"
        className="mt-[50px]"
      />
      <p className="text-base2 font-cabin-bold mt-[24px]">
        {t('front.auth.forgotPassword.success.information.1')}
        <span className="text-color-blue">{email}</span>
      </p>
      <p className="text-base2 font-cabin-bold mt-[19px]">
        {t('front.auth.forgotPassword.success.information.2')}
      </p>
      <p className="text-base2 mt-[19px]">
        {t('front.auth.forgotPassword.success.information.3')}
      </p>
    </>
  );
};
