// api/Invoice.ts
import { Attr, BelongsTo, Model } from 'spraypaint';
import type { MoneyType, PeriodType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';
import type Employee from './Employee';

export type StatusEnum =
  | 'in_progress'
  | 'submitted'
  | 'validated'
  | 'rejected'
  | 'signature_procedure_started';
export type MethodOfPaymentEnum = 'wire' | 'cheque';
export type DocumentGenerationStatusEnum =
  | 'pending'
  | 'started'
  | 'canceled'
  | 'generated'
  | 'error';
export type ManagementEnum = 'free' | 'managed';
export type NetIncomeEnum =
  | 'less_than_1500'
  | 'from_1500_to_2500'
  | 'from_2500_to_5000'
  | 'from_5000_to_10000'
  | 'from_10000_to_20000'
  | 'from_20000_to_40000'
  | 'more_than_40000';
export type AssetsWithoutRealEstateEnum =
  | 'less_than_10000'
  | 'from_10000_to_50000'
  | 'from_50000_to_100000'
  | 'from_100000_to_500000'
  | 'from_500000_to_1000000'
  | 'from_1000000_to_5000000'
  | 'more_than_5000000';

@Model()
class ContribPercoDeposit extends ApplicationRecord {
  static jsonapiType = 'contrib_perco_deposits';

  // Attributes
  @Attr() employeeId!: number;
  @Attr() amount!: MoneyType;
  @Attr() remainingAmount!: MoneyType;
  @Attr() deposited!: boolean;
  @Attr() comment!: string;
  @Attr() methodOfPayment!: MethodOfPaymentEnum;
  @Attr() status!: StatusEnum;
  @Attr() createdAt!: string;
  @Attr() updatedAt!: string;
  @Attr() documentGenerated_at!: string;
  @Attr() documentGenerated_id!: string;
  @Attr() documentGeneration_id!: string;
  @Attr() documentGeneration_status!: DocumentGenerationStatusEnum;
  @Attr() frenchFiscalResident!: boolean;
  @Attr() netIncomeBracket!: NetIncomeEnum;
  @Attr() assetsWithoutRealEstate!: AssetsWithoutRealEstateEnum;
  @Attr() deductibleWaiver!: boolean;
  @Attr() management!: ManagementEnum;
  @Attr() expectedRetirementAge!: number;
  @Attr() period!: PeriodType;
  @Attr() number!: string;

  // Relationships
  @BelongsTo('employee') employee!: Employee;

  get employeePagePath(): string | undefined {
    const { id, employeeId } = this;

    if (!employeeId || !id) {
      return undefined;
    }

    return `/employees/${employeeId}/contrib_perco_deposits/${id}`;
  }

  // Use a method to comply with the expected interface
  // eslint-disable-next-line class-methods-use-this
  get associableType(): string {
    return 'ContribPercoDeposit';
  }

  get currencyIsoCode(): string | undefined {
    const { amount } = this;
    return amount.currency;
  }
}

export default ContribPercoDeposit;
