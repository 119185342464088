import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import IncomeSummary from '../../../../app/spraypaint/payslipFunnel/IncomeSummary';

const caColumnHelper = createColumnHelper<ModelRecord<any>>();
const incomeColumnHelper = createColumnHelper<ModelRecord<IncomeSummary>>();

const mapExpenseTypeToString = {
  'Expenses::CommonExpense': 'front.expense.enum.common',
  'Expenses::StandardExpense': 'front.expense.enum.standardExpense',
  'Expenses::LunchExpense': 'front.expense.enum.lunchExpense',
  'Expenses::InternationalExpense': 'front.expense.enum.internationalExpense',
  'Expenses::MealVoucher': 'front.expense.enum.mealVoucher',
  'Expenses::MealVoucherExpense': 'front.expense.enum.mealVoucherExpense',
  'Expenses::MileageExpense': 'front.expense.enum.mileageExpense',
  'Expenses::TeleworkingExpense': 'front.expense.enum.teleworkExpense',
  'Expenses::GreenMobilityExpense': 'front.expense.enum.greenMobilityExpense',
};

function getNatureName(key: any, expensesReferentialType: any) {
  const { t } = useTranslation();
  let name = '';
  if (key === 'default') {
    name = t(
      'views.expense.expense_type_enum.' +
        `Expenses::${expensesReferentialType}Expense`,
    );
  }
  if (expensesReferentialType === 'StandardExpense') {
    name = t(`activerecord.attributes.standard_expense.standard_types.${key}`);
  }
  if (expensesReferentialType === 'TeleworkingExpense') {
    name = t(`activerecord.enums.teleworking_expense.nature_type.${key}`);
  }

  return name;
}

export const InvoiceDetailsColumns = () => {
  const { t } = useTranslation();
  return [
    caColumnHelper.accessor('revenue_id', {
      header: 'ID',
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info: any) => (
        <div className="text-center">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    caColumnHelper.accessor('date', {
      header: t('front.payslip.tdc.invoice.table.columns.date') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    caColumnHelper.accessor('number', {
      header: t('front.payslip.tdc.invoice.table.columns.number') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),

    caColumnHelper.accessor('client_name', {
      header: t('front.payslip.tdc.invoice.table.columns.clientName') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    caColumnHelper.accessor('total_amount', {
      header: t(
        'front.payslip.tdc.invoice.table.columns.totalAmount',
      ) as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];
};

export const RevenueDetailsColumns = () => {
  const { t } = useTranslation();
  return [
    incomeColumnHelper.accessor('income_id', {
      header: 'ID',
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info: any) => (
        <div className="text-center">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    incomeColumnHelper.accessor('date', {
      header: t('front.payslip.tdc.invoice.table.columns.date') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    incomeColumnHelper.accessor('number', {
      header: t('front.payslip.tdc.invoice.table.columns.number') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),

    incomeColumnHelper.accessor('client_name', {
      header: t('front.payslip.tdc.invoice.table.columns.clientName') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    incomeColumnHelper.accessor('paid_amount', {
      header: t(
        'front.payslip.tdc.invoice.table.columns.totalAmount',
      ) as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];
};

export const ExpensesToReimburseColumns = () => {
  const { t } = useTranslation();
  return [
    incomeColumnHelper.accessor('id', {
      header: t('front.payslip.details.expense.table.id') as string,
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info: any) => (
        <div className="text-center">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    incomeColumnHelper.accessor('date', {
      header: t('front.payslip.details.expense.table.date') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    incomeColumnHelper.accessor('type', {
      header: t('front.payslip.details.expense.table.type') as string,
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info: any) => (
        <div className="text-center">
          <p>
            {t(
              mapExpenseTypeToString[
                info.getValue() as unknown as keyof typeof mapExpenseTypeToString
              ],
            )}
          </p>
        </div>
      ),
    }),

    incomeColumnHelper.accessor('nature', {
      header: t('front.payslip.details.expense.table.nature') as string,
      cell: (info: any) => {
        const expensesReferentialType = info.row
          .getValue('type')
          .split('::')[1];

        return (
          <div className="text-center">
            <p>{getNatureName(info.getValue(), expensesReferentialType)}</p>
          </div>
        );
      },
      enableSorting: false,
      enableColumnFilter: false,
    }),
    incomeColumnHelper.accessor('amount', {
      header: t('front.payslip.details.expense.table.amount') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];
};

export const RejectedExpensesColumns = () => {
  const { t } = useTranslation();
  return [
    incomeColumnHelper.accessor('id', {
      header: t('front.payslip.details.expense.table.id') as string,
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info: any) => (
        <div className="text-center">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    incomeColumnHelper.accessor('date', {
      header: t('front.payslip.details.expense.table.date') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
    incomeColumnHelper.accessor('type', {
      header: t('front.payslip.details.expense.table.type') as string,
      enableSorting: false,
      cell: (info: any) => (
        <div className="text-center">
          <p>
            {t(
              mapExpenseTypeToString[
                info.getValue() as unknown as keyof typeof mapExpenseTypeToString
              ],
            )}
          </p>
        </div>
      ),
      enableColumnFilter: false,
    }),

    incomeColumnHelper.accessor('nature', {
      header: t('front.payslip.details.expense.table.nature') as string,
      cell: (info: any) => {
        const expensesReferentialType = info.row
          .getValue('type')
          .split('::')[1];

        return (
          <div className="text-center">
            <p>{getNatureName(info.getValue(), expensesReferentialType)}</p>
          </div>
        );
      },
      enableSorting: false,
      enableColumnFilter: false,
    }),
    incomeColumnHelper.accessor('amount', {
      header: t('front.payslip.details.expense.table.amount') as string,
      enableSorting: false,
      enableColumnFilter: false,
    }),
  ];
};
