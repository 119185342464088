import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';
import { ReactElement } from 'react';

export type CustomTooltipType = {
  tooltipId: string;
  tooltipMsgComp?: string | Array<string> | ReactElement | null;
  position?: 'top' | 'right' | 'bottom' | 'left';
  customClassName?: string;
  tooltipTxtCustomClass?: string;
};

export const CustomTooltip = ({
  tooltipId,
  tooltipMsgComp,
  position = 'bottom',
  customClassName,
  tooltipTxtCustomClass,
}: CustomTooltipType) => {
  const customTooltipClassName = classNames(
    'rounded bg-color-light-grey text-color-dark-content text-text-tutorial w-[300px] p-[20px] shadow-tooltip z-50 opacity-100',
    customClassName,
  );

  const itemsCustomClass = classNames('text-left', tooltipTxtCustomClass);

  if (!tooltipMsgComp) return null;

  const items = Array.isArray(tooltipMsgComp)
    ? tooltipMsgComp
        .map((str, index) => ({ key: index, value: str }))
        .map((item) => (
          <div key={item.key} className="text-text-tutorial">
            {item.value}
          </div>
        ))
    : tooltipMsgComp;

  return (
    <ReactTooltip
      anchorSelect={`#${tooltipId}`}
      place={position}
      className={customTooltipClassName}
      noArrow
    >
      <div className={itemsCustomClass}>{items}</div>
    </ReactTooltip>
  );
};
