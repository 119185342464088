import { useTranslation } from 'react-i18next';
import {
  getMonthName,
  getMonthPreposition,
} from '../../common/utils/date.utils';
import type { PeriodType } from '../../common/utils/type.utils';

type Props = {
  period: PeriodType;
};
const PayslipPeriodStringified = ({ period }: Props) => {
  const { t } = useTranslation();

  return (
    <p className="ml-[19px] mb-[10px] text-xl text-color-medium-grey">
      {t('front.payslip.index.header.payslipStarted.header')}
      {getMonthPreposition(period?.month)}
      <span className="text-color-pone-dark">
        {`${getMonthName(period?.year, period?.month)} ${period?.year}`}
      </span>
      <span>{t('front.payslip.index.header.payslipStarted.header2')}</span>
    </p>
  );
};

export default PayslipPeriodStringified;
