import { CellContext } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { Invoice } from '@/app/spraypaint';
import { ButtonPrimary, InternalLink } from '@common/components/buttons';

import { ModalState } from '@/modules/admin/invoices/types/ModalState';
import { useTranslation } from 'react-i18next';

export const EmployeeLinkCell = ({
  cell,
}: CellContext<ModelRecord<Invoice>, unknown>) => {
  const { employee } = cell.row.original;
  if (!employee) {
    return null;
  }
  return (
    <InternalLink to={`/employees/${employee.id}`}>
      {employee.fullName}
    </InternalLink>
  );
};

interface ActionCellProps {
  original: ModelRecord<Invoice>;
  isLoading: boolean;
  dissociateInvoice: (id: string | undefined) => void;
  setPaymentModalProvisionalInvoice: (invoice: ModalState) => void;
}

export const ActionCell = ({
  original,
  isLoading,
  dissociateInvoice,
  setPaymentModalProvisionalInvoice,
}: ActionCellProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex">
      <ButtonPrimary
        onClick={(e) => {
          e.stopPropagation();
          dissociateInvoice(original.id);
        }}
        disabled={isLoading}
        isLoading={isLoading}
        className="mr-3"
      >
        {t('default.button.delete')}
      </ButtonPrimary>
      {(original.status === 'validated' ||
        original.status === 'partially_paid') && (
        <ButtonPrimary
          onClick={(e) => {
            e.stopPropagation();
            setPaymentModalProvisionalInvoice({
              id: original.id,
              remainingAmount: original.remainingAmount,
            });
          }}
          disabled={isLoading}
        >
          {t('default.button.settle')}
        </ButtonPrimary>
      )}
    </div>
  );
};
