import { useMutation, useQueryClient } from '@tanstack/react-query';
import Transition from '../../../app/spraypaint/Transition';

export type OnSuccessTransitionType = {
  onboardingFunnelId: number;
  transitionName: string;
  fromStep?: string;
  toStep?: string;
};

export const useOnboardingFunnelTransition = ({
  onboardingFunnelId,
  callBack,
  errorCallback,
}: {
  onboardingFunnelId: number;
  errorMsg?: string;
  callBack?: (info: OnSuccessTransitionType) => void;
  errorCallback?: () => void;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...others } = useMutation({
    mutationFn: async ({
      transitionName,
      fromStep,
      toStep,
    }: {
      transitionName: string;
      fromStep?: string;
      toStep?: string;
    }) => {
      const transition = new Transition({
        resourceId: onboardingFunnelId,
        resourceType: 'OnboardingFunnel',
        transition_name: transitionName,
      });
      const success = await transition.save();
      if (!success) {
        throw new Error('Transition failed');
      }
      return { onboardingFunnelId, transitionName, fromStep, toStep };
    },
    onSuccess: (info: OnSuccessTransitionType) => {
      if (callBack) callBack(info);
      queryClient.invalidateQueries(['current-onboarding-funnel']);
    },
    onError: () => {
      if (errorCallback) errorCallback();
      queryClient.invalidateQueries(['current-onboarding-funnel']);
    },
  });
  return { mutate, isLoading, ...others };
};
