import { useTranslation } from 'react-i18next';
import { PerkType } from '../types/PerkType';
import { FreelanceStack } from '../../../app/assets/perks';
import { useEmployeeFreelanceStackUrl } from '../../authentification/hooks';

export const employeePerksItems = (): Array<PerkType> => {
  const { t } = useTranslation();
  const { freelanceStackUrl } = useEmployeeFreelanceStackUrl();

  return [
    {
      title: t('front.perk.card.freelanceStack.title'),
      description: t('front.perk.card.freelanceStack.description'),
      url: freelanceStackUrl,
      image: FreelanceStack,
    },
  ];
};
