import type { FunctionComponent } from 'react';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { useTranslation } from 'react-i18next';
import { MainCard } from '@/modules/common/components/mainCard';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { RecordProxy } from 'spraypaint/lib-esm/proxies';
import RegisteredClient from '@spraypaint/RegisteredClient';
import { useForm } from 'react-hook-form';
import { TextInput } from '@/modules/common/components/inputs';
import { ControlledSelect } from '@/modules/common/components/inputs/controlledInput/ControlledSelect';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { FrameworkContract } from '@/app/spraypaint';
import useLegalEntitiesSelectOptions from '@/modules/common/legalEntity/useLegalEntitiesSelectOptions';

export type FrameworkContractFormValues = {
  name?: string;
  operatingUnitName?: string;
  legalEntityId?: string | number;
  registeredClientId?: string | number;
};

function FrameworkContractForm({
  onSubmit,
  registeredClient,
}: {
  registeredClient: RecordProxy<RegisteredClient> | undefined;
  onSubmit: (data: FrameworkContractFormValues) => Promise<unknown>;
}) {
  const { register, handleSubmit, control } =
    useForm<FrameworkContractFormValues>({
      defaultValues: {
        registeredClientId: registeredClient?.data?.id,
      },
    });
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnSubmit = async (data: FrameworkContractFormValues) => {
    setIsLoading(true);
    try {
      await onSubmit(data);
    } finally {
      setIsLoading(false);
    }
  };

  const legalEntityOptions = useLegalEntitiesSelectOptions();

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <TextInput
        {...register('name', { required: true })}
        className="mt-[33px]"
        label={t('activerecord.attributes.framework_contract.name', {
          ns: 'rails',
        })}
        required
      />

      <ControlledSelect
        name="legalEntityId"
        control={control}
        label={t('activerecord.attributes.framework_contract.legal_entity', {
          ns: 'rails',
        })}
        required
        options={legalEntityOptions}
      />
      <TextInput
        {...register('operatingUnitName', { required: false })}
        className="mt-[33px]"
        label={t(
          'activerecord.attributes.framework_contract.operating_unit_name',
          {
            ns: 'rails',
          },
        )}
      />
      <ButtonPrimary isLoading={isLoading} className="mt-[51px]" type="submit">
        {t('front.defaults.form.submit')}
      </ButtonPrimary>
    </form>
  );
}

export const FrameworkContractNew: FunctionComponent = () => {
  const registeredClient = useRouteLoaderData('registered_client_root') as
    | RecordProxy<RegisteredClient>
    | undefined;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync } = useMutation({
    mutationFn: (data: FrameworkContractFormValues) => {
      const fm = new FrameworkContract(data);
      return fm.saveOrThrow();
    },
    onSuccess: (data) => {
      navigate(`../${data.id}`);
    },
  });

  return (
    <PageLayout Title={t('front.framework_contract.new.header.title')}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <MainCard header="Créer un accès">
          <FrameworkContractForm
            registeredClient={registeredClient}
            onSubmit={mutateAsync}
          />
        </MainCard>
      </div>
    </PageLayout>
  );
};
