import {
  convertDateToUTC,
  getLastDayOfMonth,
} from '../../../common/utils/date.utils';

export function getDatesRange(date: Date) {
  const currentDate = new Date(date);
  const currentMonth = currentDate.getMonth();
  const lastDayOfMonth = getLastDayOfMonth(currentDate);
  const currentYear = currentDate.getFullYear();

  const firstMonday = new Date(currentYear, currentMonth, 1);
  while (firstMonday.getDay() !== 1) {
    firstMonday.setDate(firstMonday.getDate() - 1);
  }

  const dates = [];
  const current = firstMonday;

  while (current.getTime() <= lastDayOfMonth.getTime()) {
    dates.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }

  if (dates[dates.length - 1].getDay() !== 0) {
    while (current.getDay() !== 1) {
      dates.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
  }

  return dates;
}

export const getIsDateInRange = (date: Date, rangeDates?: [string, string]) => {
  if (!rangeDates) return true;

  const dateRangeFirstDate = convertDateToUTC(new Date(rangeDates[0]));
  const dateRangeLastDate = convertDateToUTC(new Date(rangeDates[1]));

  if (
    date.getTime() <= dateRangeLastDate.getTime() &&
    date.getTime() >= dateRangeFirstDate.getTime()
  )
    return true;

  return false;
};
