import type { FunctionComponent } from 'react';
import { useCurrentOnboardingFunnel } from '@/modules/welcome/hooks/useCurrentOnboardingFunnel';
import {
  buildSkeletonStep,
  Step,
  TimelineStepper,
} from '@common/components/TimelineStepper';
import { FaFileSignature, FaWpforms } from 'react-icons/fa';
import { MdOutlineRocketLaunch } from 'react-icons/md';
import { ButtonPrimary } from '@common/components/buttons';
import { useTranslation } from 'react-i18next';

const loadingSteps: Step[] = ['form', 'signature', 'launch'].map(
  buildSkeletonStep,
);

const WidgetContent: FunctionComponent = () => {
  const { t } = useTranslation();
  const { onboardingFunnel, isLoading } = useCurrentOnboardingFunnel();

  if (isLoading || !onboardingFunnel) {
    return <TimelineStepper steps={loadingSteps} />;
  }

  const formCompleted = onboardingFunnel.status === 'final_step';
  const formStarted = onboardingFunnel.status !== 'identity_step';

  const buttonText = formStarted
    ? t('front.welcome.onboarding.widget.button.resume')
    : t('front.welcome.onboarding.widget.button.start');

  const steps: Step[] = [
    {
      key: 'form',
      title: t('front.welcome.onboarding.widget.form.title'),
      description: t('front.welcome.onboarding.widget.form.description'),
      Icon: FaWpforms,
      status: formCompleted ? 'done' : 'current',
    },
    {
      key: 'signature',
      title: t('front.welcome.onboarding.widget.signature.title'),
      description: t('front.welcome.onboarding.widget.signature.description'),
      Icon: FaFileSignature,
      status: formCompleted ? 'current' : 'upcoming',
    },
    {
      key: 'launch',
      title: t('front.welcome.onboarding.widget.launch.title'),
      description: t('front.welcome.onboarding.widget.launch.description'),
      Icon: MdOutlineRocketLaunch,
      status: 'upcoming',
    },
  ];

  return (
    <>
      <TimelineStepper steps={steps} />
      <ButtonPrimary to="./onboarding">{buttonText}</ButtonPrimary>
    </>
  );
};

export const OnboardingFunnelWidget: FunctionComponent = () => (
  <div className="md:shadow-card md:w-[680px] pt-[19px] mt-12 p-8 bg-white mx-auto">
    <WidgetContent />
  </div>
);
