import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDateFromPeriod } from '@/app/utils/dateUtils';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import { onRowClickNavigate } from '@/app/navigation/functions/onRowClickNavigate';
import useBreakpoints from '../../../app/responsive/useBreakPoints';
import Payslip from '../../../app/spraypaint/Payslip';
import PayslipFunnel from '../../../app/spraypaint/payslipFunnel/PayslipFunnel';
import { useCurrentEmployeePayslipFunnel } from '../../authentification/hooks';
import { useBackendTable } from '../../common/components/table/hooks';
import Table from '../../common/components/table/Table';
import { Toaster } from '../../common/components/toasts';
import {
  FinishedFunnelCard,
  PayslipIndexHeader,
  PayslipIndexCards,
} from '../components';
import { payslipColumns } from './PayslipColumns';
import { MobileTableRow } from '../../common/components/table/mobile/MobileTableRow';

export const PayslipIndex = () => {
  const { isXs } = useBreakpoints();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const currentEmployee = useCurrentEmployeeId();
  const {
    isLoading: employeeDataLoading,
    currentPayslipFunnel,
    nextFunnelEmploymentContractId,
    nextPayslipFunnelPeriod,
    nextFunnelAutoStartDate,
  } = useCurrentEmployeePayslipFunnel();

  const [scope, scopeKey, props] = useBackendTable<Payslip>();

  const PayslipColumns = payslipColumns();

  const handleOnRowClick = (raw: any) => (event: any) => {
    onRowClickNavigate({
      id: raw?.id,
      event,
      windowNavigationHref: `payslips/${raw?.id}`,
      navigate,
    });
  };

  const { data: payslipsData, isLoading } = useQuery(
    ['payslips', scopeKey],
    () =>
      scope(
        Payslip.select([
          'employee_id',
          'cra_id',
          'period',
          'workingHours',
          'grossSalary',
          'netSalaryReal',
          'expenses',
          'status',
        ]).scope(),
      )
        .where({ employee_id: currentEmployee })
        .order({ period: 'desc', id: 'desc' })
        .selectExtra(['file_url', 'file_name'])
        .all(),
    {
      refetchInterval: 300000, // Refetch every 5 minutes
    },
  );

  const { mutate: createPayslipFunnel, isLoading: mutationLoader } =
    useMutation({
      mutationFn: (payslipFunnel: any) => payslipFunnel.save(),
      onSuccess: () => navigate('create'),
      onError: () =>
        toast.error(
          <Toaster
            textMsg={t('front.payslip.tdc.invoice.creation.error')}
            toastType="error"
          />,
        ),
    });

  const handleMutation = () => {
    const payslipFunnel = new PayslipFunnel({
      employmentContractId: nextFunnelEmploymentContractId,
      period: nextPayslipFunnelPeriod,
    });

    createPayslipFunnel(payslipFunnel);
  };

  const totalCount = payslipsData && payslipsData.meta.stats.total.count;

  return (
    <div>
      {!employeeDataLoading && (
        <PayslipIndexHeader
          isLoading={mutationLoader}
          onClick={handleMutation}
          funnelStarted={
            (currentPayslipFunnel || !nextPayslipFunnelPeriod) as boolean
          }
        />
      )}
      <PayslipIndexCards
        currentPayslipFunnel={currentPayslipFunnel}
        nextPayslipFunnelPeriod={nextPayslipFunnelPeriod}
        nextFunnelAutoStartDate={nextFunnelAutoStartDate as string}
      />
      {currentEmployee && (
        <FinishedFunnelCard currentEmployeeId={Number(currentEmployee)} />
      )}
      {!isXs ? (
        <div className="pb-[200px]">
          <Table
            emptyTableErrorMsg="front.payslip.index.table.emptyMsgError"
            onRowClick={handleOnRowClick}
            className="mt-[26px]"
            title={t('front.payslip.index.table.title') as string}
            isLoading={isLoading}
            totalCount={totalCount}
            data={payslipsData?.data || []}
            reactTableProps={props}
            columns={PayslipColumns}
          />
        </div>
      ) : (
        <div>
          <div className="p-[20px] shadow-table">
            <p className="text-xl text-color-medium-grey">
              {t('front.payslip.index.table.title')}
            </p>
          </div>
          {payslipsData?.data.map((payslip, index) => (
            <MobileTableRow
              index={index}
              date={getDateFromPeriod({
                month: payslip.period?.month as number,
                year: payslip.period?.year as number,
              })}
              amount={payslip.netSalaryReal}
              link={`${payslip?.id}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};
