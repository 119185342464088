/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChangeEventHandler, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  ErrorIcon,
  LoaderIcon,
  AddIcon,
} from '../../../../app/assets/icons/inputs';
import {
  ButtonOnClickHandler,
  ButtonSecondaryMinus,
  IconButtonDelete,
} from '../buttons';
import { Error } from './components/Error';
import { CheckIcon } from '../../../../app/assets/icons/buttons';
import { AcceptedFileExtensionType } from '../../types/AcceptedFileExtension';

export type UploadDocInputProps = {
  className?: string;
  errorText?: string;
  isValidate?: boolean;
  isLoading?: boolean;
  setFileName: (value: string) => void;
  fileName?: string | undefined;
  label?: string;
  handleUploadFile: ButtonOnClickHandler;
  handleChangeFile: (file: File) => void;
  acceptedFile?: AcceptedFileExtensionType | AcceptedFileExtensionType[];
};

export const UploadDocInput = ({
  className,
  errorText,
  isLoading,
  isValidate,
  label = '',
  fileName = '',
  setFileName,
  handleUploadFile,
  handleChangeFile,
  acceptedFile,
}: UploadDocInputProps) => {
  const { t } = useTranslation();
  const customClassName = classNames(
    'z-[-1] w-[0.1px] h-[0.1px] opacity-0 overflow-hidden absolute',
    className,
  );
  const defaultLabel = t('front.upload.button.defaultLabel');

  const notNormalState = isValidate || isLoading || errorText || fileName;
  const hasIconInside = errorText || isLoading || isValidate;
  const shouldDisplayAddButton = fileName && !errorText && !isLoading;
  const shouldDisplayDeleteButton = fileName;

  const containerClassName = classNames('flex flex-col', className);

  const customLabelClassName = classNames(
    'cursor-pointer w-[356px] h-[36px] border-[1px] border-color-medium-light rounded py-2.5 pl-[17px] text-base hover:bg-color-light-grey',
    'relative',
    'whitespace-nowrap',
    'overflow-hidden',
    'pr-[20px]',
    {
      'text-color-medium-grey': !fileName,
      'text-black': fileName,
      'w-[280ox]': notNormalState,
      'border-color-success': isValidate,
      'bg-color-light-grey': isLoading,
      'border-color-failure': errorText,
    },
  );

  const iconButtonDeleteClassName = classNames({
    'ml-[12px]': !errorText,
    'ml-[52px]': errorText,
  });

  const innerParagraphClassName = classNames('w-full overflow-hidden', {
    'w-[210px]': hasIconInside,
  });

  const insideIconClassName = 'absolute top-1.5 right-3.5 h-[24px] w-[24px]';

  const inputRef = useRef(document.createElement('input'));
  const handleOnClick = () => {
    inputRef.current.click();
  };

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    handleChangeFile(fileObj);

    // 👇️ reset file input
    event.target.value = '';
    setFileName(fileObj.name);
  };

  const handleDeleteFile = () => {
    setFileName('');
  };

  const acceptedFileArray = Array.isArray(acceptedFile)
    ? acceptedFile
    : [acceptedFile];

  return (
    <div className={containerClassName} data-testid="upload-doc-input">
      <div className="flex w-[463px]">
        <input
          onChange={handleFileChange}
          type="file"
          name="file"
          id="file"
          className={customClassName}
          ref={inputRef}
          data-testid="file-input"
          accept={acceptedFileArray.join(',')}
        />
        <label
          htmlFor="file"
          className={customLabelClassName}
          data-testid="add-file-button"
        >
          <p className={innerParagraphClassName}>
            {fileName === '' ? label || defaultLabel : fileName}
          </p>
          {errorText && (
            <ErrorIcon
              data-testid="error-icon"
              className={insideIconClassName}
            />
          )}
          {isLoading && (
            <LoaderIcon
              data-testid="loader-icon"
              className={`${insideIconClassName} animate-spin`}
            />
          )}
          {isValidate && (
            <CheckIcon
              data-testid="check-icon"
              className={`${insideIconClassName} fill-color-success`}
            />
          )}
        </label>
        <ButtonSecondaryMinus onClick={handleOnClick} className="ml-[15px]">
          {fileName
            ? t('front.upload.button.file.change')
            : t('front.upload.button.file')}
        </ButtonSecondaryMinus>
        {shouldDisplayAddButton && (
          <button
            type="button"
            className="ml-[15px]"
            onClick={handleUploadFile}
          >
            <AddIcon data-testid="check-icon" className="h-[24px] w-[24px]" />
          </button>
        )}
        {shouldDisplayDeleteButton && (
          <IconButtonDelete
            className={iconButtonDeleteClassName}
            onClick={handleDeleteFile}
          />
        )}
      </div>
      {errorText && <Error>{errorText}</Error>}
    </div>
  );
};

UploadDocInput.defaultProps = {};
