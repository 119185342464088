import { useQuery } from '@tanstack/react-query';
import Comment, { CommentableType } from '@spraypaint/Comment';

type ParamsType = {
  commentableType: CommentableType;
  commentableId?: string;
};

export const useGetComments = ({
  commentableId,
  commentableType,
}: ParamsType) => {
  const data = useQuery({
    queryKey: ['get-comments', commentableId, commentableType],
    enabled: !!commentableId,
    queryFn: () =>
      Comment.includes(['user'])
        .where({
          commentable_id: commentableId,
          commentable_type: commentableType,
        })
        .order({ createdAt: 'desc' })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
  const comments = data?.data?.data;

  return { comments, ...data };
};
