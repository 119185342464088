import classNames from 'classnames';
import useBreakpoints from '../../../../app/responsive/useBreakPoints';

type StatusComponentProps = {
  icon: string;
  alt: string;
  statusClass: string;
  textStyle: string;
  title: string;
};

function StatusComponent({
  icon,
  alt,
  statusClass,
  textStyle,
  title,
}: StatusComponentProps) {
  const { isXs } = useBreakpoints();
  const customClassName = classNames('flex', { 'items-center': isXs });
  return (
    <div className={customClassName}>
      <img
        src={icon}
        alt={alt}
        className="block sm:hidden h-6 w-6 md:h-4 md:w-4"
      />
      <div className={statusClass}>
        <p className={textStyle}>{title}</p>
      </div>
    </div>
  );
}

export default StatusComponent;
