/* eslint-disable import/no-cycle */
// api/ActivityReport.ts
import { Model, Attr, HasMany, BelongsTo } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import PayslipFunnelCraStep from '../payslipFunnel/PayslipFunnelCraStep';
import ActivityReportSlot from './ActivityReportSlot';

@Model()
class ActivityReport extends ApplicationRecord {
  static jsonapiType = 'activity_reports';

  // Attributes
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;

  // Relationships
  @HasMany('activity_report_slots') activityReportSlots!: ActivityReportSlot[];
  @BelongsTo('payslip_funnel_steps-cra_steps')
  craStep!: PayslipFunnelCraStep[];
}

export default ActivityReport;
