import { useCallback, useState } from 'react';
import { OnChangeFn, PaginationState } from '@tanstack/react-table';

export function useControlledPagination({ initialPageSize = 10 }) {
  const [paginationState, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSize,
  });

  // Call this when filters or sorting change to return to page 0
  const resetPagination = useCallback(() => {
    setPaginationState((old) => ({ ...old, pageIndex: 0 }));
  }, [setPaginationState]);

  const onPaginationChange: OnChangeFn<PaginationState> = useCallback(
    (stateUpdater) => {
      setPaginationState(stateUpdater);
    },
    [setPaginationState],
  );

  return { paginationState, onPaginationChange, resetPagination };
}
