import { RoutesMounter } from '@/types';
import { index, member } from '@/app/navigation/functions';
import ReconciliationIndex from './pages/ReconciliationIndex';
import ClientPaymentFileIndex from './pages/ClientPaymentFileIndex';
import ClientPaymentFileShow from './pages/ClientPaymentFileShow';
import ClientPaymentTransactionShow from './pages/ClientPaymentTransactionShow';
import ClientPaymentTransactionAssignation from './pages/ClientPaymentTransactionAssignation';

export const mountFactorReconciliationRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { t },
}) => [
  member({
    path,
    handle: { crumb: () => t('reconciliation.crumbs.main') },
    children: [
      index({ Component: ReconciliationIndex }),
      member({
        path: 'client_payment_files',
        handle: {
          crumb: () => t('reconciliation.crumbs.client_payment_files'),
        },
        children: [
          index({ Component: ClientPaymentFileIndex }),
          member({
            path: ':id',
            handle: {
              crumb: ({ data }) =>
                t('reconciliation.crumbs.client_payment_file_show', {
                  id: data?.id,
                }),
            },
            children: [index({ Component: ClientPaymentFileShow })],
          }),
        ],
      }),
      member({
        path: 'transactions',
        handle: {
          crumb: () => t('reconciliation.crumbs.transactions'),
        },
        children: [
          index({ Component: ReconciliationIndex }),
          member({
            path: ':id',
            handle: {
              crumb: ({ data }) =>
                t('reconciliation.crumbs.transaction_show', {
                  transactionId: data?.transactionId,
                }),
            },
            children: [
              index({ Component: ClientPaymentTransactionShow }),
              {
                path: 'assignation',
                Component: ClientPaymentTransactionAssignation,
                handle: {
                  crumb: () =>
                    t('reconciliation.crumbs.transaction_assignation'),
                },
              },
            ],
          }),
        ],
      }),
    ],
  }),
];
