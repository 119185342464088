import { useQuery } from '@tanstack/react-query';
import PayslipFunnel from '../../../../app/spraypaint/payslipFunnel/PayslipFunnel';

export type CraMaxDaysInfoType = {
  paidHolidays?: number;
  workingTime?: number;
  maxHolidays?: number;
  maxWorkingDays?: number;
};

export const useGetRangeDates = (payslipFunnelId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ['rangeDates', payslipFunnelId],
    retry: 0,
    queryFn: () =>
      PayslipFunnel.select(['date_range_first_date', 'date_range_last_date'])
        .selectExtra(['date_range_first_date', 'date_range_last_date'])
        .find(payslipFunnelId),
  });

  const dateRangeLastDate = data?.data.dateRangeLastDate;
  const dateRangeFirstDate = data?.data.dateRangeFirstDate;

  return {
    data,
    isLoading,
    dateRangeLastDate,
    dateRangeFirstDate,
  };
};
