import { useQuery } from '@tanstack/react-query';
import ActivityAccount from '../../../../app/spraypaint/activityAccount/ActivityAccount';

const useEmployeeActivityAccount = (employeeId: number | undefined) => {
  const data = useQuery({
    queryKey: ['activity-account-employee', employeeId],
    queryFn: () =>
      ActivityAccount.selectExtra(['balance', 'total_balance', 'provisions'])
        .where({ ownerId: employeeId, ownerType: 'Employee' })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const activityAccount = data?.data?.data[0];
  const balance = data?.data?.data[0].balance;
  const provisions = data?.data?.data[0].provisions;
  const totalBalance = data?.data?.data[0].totalBalance;

  return {
    ...data,
    activityAccount,
    totalBalance,
    balance,
    provisions,
  };
};

export default useEmployeeActivityAccount;
