// api/CapGeminiConsolidationInvoiceItemImport.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import type { ImportStatusEnum } from './CapGeminiConsolidationInvoiceImport';
import ApplicationRecord from '../ApplicationRecord';
import type CapGeminiConsolidationImport from './CapGeminiConsolidationImport';
import Invoice from '../Invoice';
import type { StatusEnum as InvoiceStatusEnum } from '../Invoice';
import type CommercialContract from '../CommercialContract';

@Model()
class CapGeminiConsolidationInvoiceItemImport extends ApplicationRecord {
  static jsonapiType = 'capgemini-consolidation_invoice_item_imports';

  // Attributes
  @Attr({ persist: false }) importId!: string;
  @Attr({ persist: false }) consolidationInvoiceId!: string;
  @Attr({ persist: false }) externalReference!: string;
  @Attr({ persist: false }) currency!: string;
  @Attr({ persist: false }) workOrderId!: string;
  @Attr({ persist: false }) workerId!: string;
  @Attr({ persist: false }) subcontractorName!: string;
  @Attr({ persist: false }) date!: string;
  @Attr({ persist: false }) daysCount!: number;
  @Attr({ persist: false }) totalAmount!: MoneyType;
  @Attr({ persist: false }) dailyRate!: number;
  @Attr({ persist: false }) timesheetId!: string;
  @Attr({ persist: false }) timesheetStartDate!: string;
  @Attr({ persist: false }) timesheetEndDate!: string;
  @Attr({ persist: false }) expenseRef!: string;
  @Attr({ persist: false }) expenseName!: string;
  @Attr({ persist: false }) expenseAmount!: MoneyType;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) commercialContractId!: string;
  @Attr({ persist: false }) invoiceId!: string;
  @Attr({ persist: false }) invoiceStatus!: InvoiceStatusEnum;
  @Attr({ persist: false }) corporateInvoiceId!: string;
  @Attr({ persist: false }) expectedInvoiceAmount!: MoneyType;
  @Attr({ persist: false }) invoiceTotalAmount!: MoneyType;
  @Attr({ persist: false }) invoiceDate!: string;
  @Attr({ persist: false }) portageFeeRate!: number;
  @Attr({ persist: false }) tundraFeeRate!: number;
  @Attr({ persist: false }) subcontractorDailyRate!: MoneyType;
  @Attr({ persist: false }) contractDailyRate!: MoneyType;
  @Attr({ persist: false }) theoricalFeeRate!: number;

  // Extra-attributes
  @Attr() importStatus!: ImportStatusEnum;
  @Attr() importErrors!: string[];
  @Attr() importWarnings!: string[];

  // Relationships
  @BelongsTo('capgemini-consolidation_imports')
  import!: CapGeminiConsolidationImport;
  @BelongsTo('invoices') invoice!: Invoice;
  @BelongsTo('commercial_contracts') commercialContract!: CommercialContract;
}

export default CapGeminiConsolidationInvoiceItemImport;
