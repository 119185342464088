import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';
import { useToggleMiniTutorial } from '../../widgets/hooks/ToggleTutorial';
import { HeaderIconButton } from '../../layout/components/HeaderIconButton';
import { TutorialOnIcon } from '../../../app/assets/icons/tutorial';
import { CommonMiniTutorial } from '../components/tutorials/miniTutorials/CommonMiniTutorial';
import {
  ButtonFunnelValidation,
  ButtonSecondary,
} from '../../common/components/buttons';
import { usePayslipFunnelTransition } from '../hooks/usePayslipFunnelTransition';
import { getPrepMonthName } from '../../common/utils/date.utils';
import {
  CraRecapCard,
  FeesAndChargesCard,
  LeftToPayCard,
  SalaryCard,
  SalaryCostCard,
  CraModal,
} from '../../payslip/components';
import { useIsAPayslipFunnel } from '../hooks/useIsAPayslipFunnel';
import { useFinalSummaryStep } from './hooks/useFinalSummaryStep';
import { PeriodType } from '../../common/utils/type.utils';
import { LoaderPage } from '../../../app/navigation/components/LoaderPage';
import { ConfirmationModal } from './components/ConfirmationModal';
import { useCurrentEmployeeId } from '../../../app/hooks/useCurrentEmployeeId';
import PrintableLine from '../../payslip/types/PrintableLine';
import { useGetCraActivityReportSlot } from './hooks/useGetCraActivityReportSlot';
import { Toaster } from '../../common/components/toasts';
import useBreakpoints from '../../../app/responsive/useBreakPoints';
import { tutorialContainerCustomClass } from '../constantes/tailwindStyle';
import StepMessages from '../components/StepMessages';

const FinalSummaryMiniTutorialOff = ({ onClick }: { onClick: any }) => (
  <HeaderIconButton Icon={TutorialOnIcon} onClick={onClick} />
);

type Props = {
  payslipFunnelId: number;
  currentPayslipFunnelMonth: string;
};

export const FinalSummaryStep = ({
  payslipFunnelId,
  currentPayslipFunnelMonth,
}: Props) => {
  const { isXs } = useBreakpoints();
  const { t } = useTranslation();
  const employeeId = useCurrentEmployeeId();

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isCraModalOpen, setIsCraModalOpen] = useState(false);
  const [isSecondaryButtonLoading, setIsSecondaryButtonLoading] =
    useState(false);
  const [isValidationButtonLoading, setIsValidationButtonLoading] =
    useState(false);
  const isPayslipFunnel = useIsAPayslipFunnel();

  const {
    isLoading,
    payslipPreview,
    hoursDistribution,
    period = { month: 1, year: null },
    salaryStrategy,
    finalSummaryStep,
    stc,
    dateRangeLastDate,
  } = useFinalSummaryStep(payslipFunnelId);

  const { activityReportSlots } = useGetCraActivityReportSlot(payslipFunnelId);

  const handleSetIsOpen = () => {
    setIsOpen((prev) => !prev);
    setIsValidationButtonLoading((prev) => !prev);
  };
  const handleSetIsCraModalOpen = () => {
    setIsCraModalOpen((prev) => !prev);
  };
  const {
    mutate: mutateFunnelTransition,
    isLoading: isTransitionLoading,
    isError,
    isSuccess,
  } = usePayslipFunnelTransition({
    payslipFunnelId,
    errorCallback: () => {
      toast.error(
        <Toaster
          textMsg={t('front.payslip.tdc.intermediateSummary.api.errorMsg')}
          toastType="error"
        />,
      );
    },
  });

  useEffect(() => {
    if (isError) handleSetIsOpen();
  }, [isError, isSuccess]);

  const handleMixpanelFinaleSummaryStepTracking = () => {
    mixpanel.track('TDS: Salary Step Manual Reset', {
      employee_id: employeeId,
      payslipFunnelId,
      from_step: 'final_summary_step',
      to_step: 'salary_step',
      salary_strategy: salaryStrategy,
    });
  };

  const handleValidateFinalSummaryStep = () => {
    mutateFunnelTransition(
      { transitionName: 'complete_final_summary_step' },
      {
        onSuccess: () => {
          setIsValidationButtonLoading(false);
          navigate(`/v2/employees/${employeeId}/payslips`);
          handleSetIsOpen();
        },
      },
    );
  };

  const handleChangePayslip = () => {
    setIsSecondaryButtonLoading(true);
    mutateFunnelTransition(
      { transitionName: 'back_to_salary_step' },
      {
        onSettled: () => {
          setIsSecondaryButtonLoading(false);
        },
        onSuccess: () => {
          handleMixpanelFinaleSummaryStepTracking();
        },
      },
    );
  };

  const { toggleMiniTutorial, isMiniTutorialOn } = useToggleMiniTutorial();

  const title = `${t('views.payslip.model_name')} ${getPrepMonthName(
    period as PeriodType,
  )} ${period?.year || ''}`;

  const buttonDisplayClass = classNames(
    'flex flex-col md:flex-row ml-5 md:ml-0 mt-2 md:mt-0',
    {
      'md:flex-col xl:flex-row': isMiniTutorialOn,
    },
  );

  const cardsContainerCustomClass = classNames(
    'flex flex-col md:flex-row mt-[22px]',
    isXs ? 'justify-between' : 'grid grid-cols-2 gap-x-12 items-center',
  );

  return isLoading ? (
    <LoaderPage />
  ) : (
    <div>
      <ConfirmationModal
        currentPayslipFunnelMonth={currentPayslipFunnelMonth}
        isLoading={isTransitionLoading}
        handleValidateSalaryClick={handleValidateFinalSummaryStep}
        handleSetCloseModal={handleSetIsOpen}
        stc={!!stc}
        dateRangeLastDate={dateRangeLastDate}
        isOpen={isOpen}
      />
      <CraModal
        handleSetCloseModal={handleSetIsCraModalOpen}
        isOpen={isCraModalOpen}
        activityReportSlots={activityReportSlots}
      />
      <div className={tutorialContainerCustomClass}>
        {isMiniTutorialOn ? (
          <CommonMiniTutorial
            Component={
              <p>{t('front.payslip.tdc.finalSummary.miniTutorial')}</p>
            }
            onClick={toggleMiniTutorial}
            customClassName="mr-5"
          />
        ) : (
          <FinalSummaryMiniTutorialOff onClick={toggleMiniTutorial} />
        )}
        <StepMessages step={finalSummaryStep} />
        <div className={buttonDisplayClass}>
          <ButtonSecondary
            className="w-[210px] mr-3"
            onClick={handleChangePayslip}
            isLoading={isSecondaryButtonLoading}
          >
            {t('front.payslip.tdc.finalSummary.btn.change')}
          </ButtonSecondary>
          <ButtonFunnelValidation
            className="w-[210px] mt-3 md:mt-0"
            onClick={handleSetIsOpen}
            isLoading={isValidationButtonLoading}
          />
        </div>
      </div>

      <div className="ml-5 mr-10 mt-[44px]">
        <div className="flex justify-between">
          <p className="text-xxl text-color-medium-grey">{title}</p>
        </div>

        <div className={cardsContainerCustomClass}>
          <LeftToPayCard
            isPayslipFunnel={isPayslipFunnel}
            period={period as PeriodType}
            scheduledTransferDate={payslipPreview?.expectedPaymentDate}
            leftToPay={{
              amount: payslipPreview?.leftToPayReal as number,
              currency: 'EUR',
            }}
            payslipAlertsList={payslipPreview?.payslipAlertsList}
          />

          <SalaryCostCard
            isPayslipFunnel={isPayslipFunnel}
            previousBalance={payslipPreview?.previousBalance ?? 0}
            payslipCost={payslipPreview?.payslipCost ?? 0}
            incomingBalance={payslipPreview?.incomingBalance}
          />
        </div>
        <div className="grid grid-cols-1 mt-[22px]">
          <CraRecapCard
            handleModal={handleSetIsCraModalOpen}
            hoursDistribution={hoursDistribution}
          />
        </div>
        <div className="flex flex-col md:flex-row mt-[22px] md:grid md:grid-cols-1">
          <div className="flex flex-col md:flex-row shadow-[0_0_20px_0_rgba(0,0,0,0.11)] rounded md:grid md:grid-cols-2 md:gap-x-0">
            <div className="pt-5 pb-10">
              <FeesAndChargesCard
                activityFees={payslipPreview?.activityFees as number}
                activityFeesList={
                  payslipPreview?.activityFeesList as PrintableLine[]
                }
                provisionAdjustments={
                  payslipPreview?.provisionAdjustments as number
                }
                provisionAdjustmentsList={
                  payslipPreview?.provisionAdjustmentsList as PrintableLine[]
                }
                contributionsListSum={
                  payslipPreview?.contributionsListSum as number
                }
                contributionsList={
                  payslipPreview?.contributionsList as PrintableLine[]
                }
              />
            </div>
            <div className="bg-color-transparent-medium-grey pt-5 pb-10">
              <SalaryCard
                grossSalary={payslipPreview?.grossSalary as number}
                netBeforeTax={payslipPreview?.leftToPayRealBeforeTax as number}
                leftToPayReal={payslipPreview?.leftToPayReal as number}
                grossSalaryList={
                  payslipPreview?.grossSalaryList as PrintableLine[]
                }
                netBeforeTaxList={
                  payslipPreview?.leftToPayRealBeforeTaxList as PrintableLine[]
                }
                leftToPayRealList={
                  payslipPreview?.leftToPayRealList as PrintableLine[]
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
