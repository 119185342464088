import { Attr, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class FactorAuthorizationFile extends ApplicationRecord {
  static jsonapiType = 'factor-authorization_files';

  @Attr() fileSignedId!: string;
  @Attr() filename!: string;
  @Attr() createdAt!: string;
  @Attr() generatedAt!: string;
}

export default FactorAuthorizationFile;
