import type { FunctionComponent } from 'react';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { MainCard } from '@/modules/common/components/mainCard';
import { ToDo } from '@/modules/common/components/ToDo';
import { FindQueryOpts } from '@/app/utils/findQueryBuilder';
import { useCapGeminiImportFindQuery } from '@/modules/admin/capgemini/functions/capGeminiImportQuery';
import { useParams } from 'react-router-dom';
import { ConsolidationInvoiceCard } from '@/modules/admin/capgemini/components/ConsolidationInvoiceCard';
import {
  CapGeminiConsolidationImport,
  CapGeminiConsolidationInvoiceImport,
} from '@/app/spraypaint';

export const queryOpts: FindQueryOpts = {
  includes: [
    'consolidation_invoice_imports.invoice',
    'consolidation_invoice_imports.tundraInvoice',
    'consolidation_invoice_imports.frameworkContract',
  ],
  selectExtra: {
    [CapGeminiConsolidationImport.jsonapiType]: ['file_url'],
    [CapGeminiConsolidationInvoiceImport.jsonapiType]: [
      'import_status',
      'import_errors',
      'import_warnings',
      'tundra_invoice_total_amount',
    ],
  },
};

export const CapGeminiImportShow: FunctionComponent = () => {
  const { capGeminiImportId } = useParams();
  const { record } = useCapGeminiImportFindQuery(capGeminiImportId, queryOpts, {
    staleTime: 30000,
  });

  if (!record) return null;

  return (
    <PageLayout Title={`Record ${record?.id}`}>
      <MainCard header="Info">
        <ToDo name="Detail import" />
      </MainCard>
      {(record.consolidationInvoiceImports ?? []).map(
        (consolidationInvoiceImport) => (
          <ConsolidationInvoiceCard
            key={consolidationInvoiceImport.id}
            consolidationInvoiceImport={consolidationInvoiceImport}
          />
        ),
      )}
    </PageLayout>
  );
};
