/* eslint-disable no-param-reassign */
import { useQuery } from '@tanstack/react-query';
import { Numbered } from '../../../app/spraypaint';

export const useGetNumberedList = (
  number?: string,
  numberedType?: Array<string>,
) => {
  const data = useQuery({
    queryKey: ['get-numbered-list', number],
    enabled: !!number,
    queryFn: () =>
      Numbered.includes([
        'numbered.subcontractor',
        'numbered.debtor',
        'numbered.employee',
        'numbered.client',
      ])
        .where({ number, numberedType })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const resources = data?.data?.data || [];

  return { ...data, resources };
};
