import classNames from 'classnames';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { CommercialAgent, Manager } from '@/app/spraypaint';
import { LanguageSwitch } from '@/app/translation/components';
import { supportedLanguages } from '@/app/translation/constants/supportedLanguages';
import { selectUserRole } from '@/app/store/selectors/selectUserId';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import { Toaster } from '@common/components/toasts';
import { useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { EnvBannerContext } from '@/app/components/EnvBanner';
import { ButtonAdvisor } from './components/ButtonAdvisor';
import { HeaderIconButton } from './components/HeaderIconButton';
import { AvatarIcon } from '../../app/assets/icons/buttons';
import {
  useCurrentEmployee,
  useCurrentEmployeePayslipFunnel,
  useLogout,
} from '../authentification/hooks';
import { TutorialOnIcon } from '../../app/assets/icons/tutorial';
import { PayslipFunnelWidget } from '../widgets';
import { useToggleTutorial } from '../widgets/hooks/ToggleTutorial';
import { DropDownAdvisorInfos, DropDownProfile } from './components';
import { EntityLogo } from './components/EntityLogo';
import { useCurrentPayslip } from '../payslip/hooks/useCurrentPayslip';
import PayslipFunnel from '../../app/spraypaint/payslipFunnel/PayslipFunnel';
import { RoleType } from '../authentification/types/RoleType';
import { HeaderIconNotification } from './components/HeaderIconNotification';

type HeaderProps = {
  logAsAdmin?: boolean;
};

// const setExternalId = async (userId) => OneSignal.setExternalUserId(userId);

function Header({ logAsAdmin }: HeaderProps) {
  const primaryRole = selectUserRole() as RoleType;
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data, isLoading } = useCurrentEmployee();

  const { currentPayslipFunnel = undefined } =
    logAsAdmin === undefined
      ? {}
      : (useCurrentEmployeePayslipFunnel() as {
          currentPayslipFunnel: PayslipFunnel;
        });
  const currentEmployeeId = useCurrentEmployeeId();
  const { data: currentPayslipData } = useCurrentPayslip(
    Number(currentEmployeeId),
  );
  const currentPayslip = currentPayslipData?.data;
  const employeeAdvisorData = data?.data;

  const legalEntitySlug = employeeAdvisorData?.legalEntity?.slug;
  const advisor =
    employeeAdvisorData?.manager || employeeAdvisorData?.commercialAgent;
  const duty = advisor?.civility
    ? t(`front.header.advisorCard.civility.enum.${advisor?.civility}`)
    : t('front.header.advisorCard.civility.enum.M.');

  const { toggleAllTutorial, isTutorialOn } = useToggleTutorial();
  const displayToastError = (err: AxiosError) => {
    let textMsg = 'An error happened';
    if (err.response?.status === 400) textMsg = 'An error happened';
    toast.error(<Toaster textMsg={textMsg} toastType="error" />);
  };
  const { mutate } = useLogout({
    onSuccess: async () => {
      queryClient.clear();
    },
    onError: displayToastError,
  });
  const { hidden: hiddenEnvHeader } = useContext(EnvBannerContext);

  const customHeaderClassNames = classNames(
    'flex justify-between w-full sticky z-[35] bg-white py-4 px-5',
    {
      'top-10': !hiddenEnvHeader,
      'top-0': hiddenEnvHeader,
    },
  );

  const tutorialImageClassName = classNames('w-[23px] h-[16px]', {
    'fill-color-medium-grey': !isTutorialOn,
  });

  const validatedPayslip =
    !currentPayslip?.paymentDate &&
    !['in_progress', 'submitted'].includes(currentPayslip?.status ?? '');

  const getCorrectWidgetStatus = () => {
    if (['in_progress', 'submitted'].includes(currentPayslip?.status ?? '')) {
      return 'done';
    }
    return 'validated';
  };

  return (
    <div className={customHeaderClassNames}>
      <div className="flex items-center">
        <EntityLogo slug={legalEntitySlug} className="w-[139] h-[36px]" />
        {/*  <HeaderIconButton
          notificationsNumber={9}
          Icon={MailIcon}
          className="ml-9 mr-9"
        /> */}

        <HeaderIconNotification />
        <HeaderIconButton
          onClick={toggleAllTutorial()}
          Icon={TutorialOnIcon}
          style={{
            iconClassname: tutorialImageClassName,
            buttonClassname: 'ml-[40px]',
          }}
        />
      </div>
      {currentPayslipFunnel && <PayslipFunnelWidget />}
      {currentPayslip && !validatedPayslip && (
        <PayslipFunnelWidget status={getCorrectWidgetStatus()} />
      )}
      <div className="flex items-center">
        {(logAsAdmin || primaryRole === 'employee') && (
          <ButtonAdvisor
            name={advisor?.firstName as string}
            duty={duty}
            DropDownComponent={
              <DropDownAdvisorInfos
                advisor={advisor as Manager | CommercialAgent}
              />
            }
            isLoading={isLoading}
          />
        )}
        {(logAsAdmin || logAsAdmin === undefined) && (
          <LanguageSwitch
            className="mr-[72px]"
            languages={supportedLanguages}
          />
        )}
        <HeaderIconButton
          testID="profile-button"
          Icon={AvatarIcon}
          style={{
            buttonClassname: 'mr-[20px]',
          }}
          DropDownComponent={DropDownProfile}
          dropDownComponentProps={{ handleSignOut: () => mutate() }}
        />
      </div>
    </div>
  );
}

export default Header;
