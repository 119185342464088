import { Attr, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class FactorClientPaymentAssociation extends ApplicationRecord {
  static jsonapiType = 'factor-client_payment_associations';

  @Attr({ persist: false }) associableId!: number;
  @Attr({ persist: false }) associableType!: string;
  @Attr({ persist: false }) transactionableId!: number;
  @Attr({ persist: false }) transactionableType!: string;
  @Attr({ persist: false }) matchingCandidateId!: number;
}

export default FactorClientPaymentAssociation;
