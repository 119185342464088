import type { FunctionComponent } from 'react';
import { PasswordInput, TextInput } from '@common/components/inputs';
import { ButtonPrimary, HypertextLink } from '@common/components/buttons';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLogin } from '@/modules/authentification/hooks';
import { useValidation } from '@/app/form/ValidationForm';
import { InnerLoginFormProps } from '@/modules/authentification/components/types/InnerLoginFormProps';
import { useQueryErrorHandler } from '@/modules/authentification/hooks/useQueryErrorHandler';

type FormValue = {
  email: string;
  password: string;
};

export const OneStepLoginForm: FunctionComponent<InnerLoginFormProps> = ({
  redirectAfterLogin,
  onForgetPassword,
}) => {
  const { t } = useTranslation();
  const Validation = useValidation();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    register,
  } = useForm<FormValue>();

  const onQueryError = useQueryErrorHandler({ setError });

  const { mutate, isLoading } = useLogin({
    onSuccess: () => redirectAfterLogin(),
    onError: onQueryError,
  });

  const onSubmit = (data: FormValue) => {
    mutate({ login: data.email, password: data.password });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center md:items-start">
        <TextInput
          testID="login-input-email"
          label={t('front.auth.signIn.form.email.label') as string}
          placeholder={t('front.auth.signIn.form.email.placeholder') as string}
          className="mt-[33px]"
          {...register('email', Validation.email)}
          errorText={errors?.email}
        />{' '}
        <PasswordInput
          testID="login-input-password"
          label={t('front.auth.signIn.form.password.label') as string}
          placeholder={
            t('front.auth.signIn.form.password.placeholder') as string
          }
          className="mt-[33px]"
          {...register('password', Validation.password)}
          errorText={errors?.password}
        />
      </div>
      <div className="flex items-center justify-center md:justify-start mt-[33px]">
        <ButtonPrimary
          testID="login-button-submit"
          isLoading={isLoading}
          type="submit"
        >
          {t('front.auth.signIn.form.submit.title')}
        </ButtonPrimary>{' '}
        <HypertextLink
          testID="login-forgotpassword"
          onClick={() => onForgetPassword(getValues('email'))}
          className="ml-[30px]"
        >
          {t('front.auth.signIn.form.forgotPassword')}
        </HypertextLink>
      </div>
    </form>
  );
};
