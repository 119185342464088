import { useQuery } from '@tanstack/react-query';
import PayslipFunnel from '../../../../app/spraypaint/payslipFunnel/PayslipFunnel';

export const useGetIntermediateSummaryData = (payslipFunnelId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ['intermediateSummaryData', payslipFunnelId],
    cacheTime: 0,
    retry: 0,
    queryFn: () =>
      PayslipFunnel.includes(['intermediate_summary_step'])
        .selectExtra(['messages_i18n_keys'])
        .find(payslipFunnelId),
  });
  const intermediateSummaryStepData = data?.data.intermediateSummaryStep;
  return { data, isLoading, intermediateSummaryStepData };
};
