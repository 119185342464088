import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import MultipartOperationPart from '../../../../app/spraypaint/MultipartOperationPart';
import { useBackendTable } from '../../../common/components/table/hooks';

import Table from '../../../common/components/table/Table';
import { DownloadButton } from '../../../common/components/buttons';
import { DownloadIcon } from '../../../../app/assets/icons/buttons';

const columnHelper = createColumnHelper<ModelRecord<MultipartOperationPart>>();
const columns = [
  columnHelper.accessor('name', {
    header: () => {
      const { t } = useTranslation();
      return t('front.multipart_operations-part.fields.name');
    },
  }),
  columnHelper.accessor('status', {
    id: 'customStatus',
    header: () => {
      const { t } = useTranslation();
      return t('front.multipart_operations-part.fields.status');
    },
    cell: ({ cell }) => {
      const value = cell.getValue();

      const className = classNames('w-[10px] h-[10px] rounded-full mr-[10px]', {
        'bg-green-500': value === 'success',
        'bg-red-500': value === 'error',
        'bg-yellow-500': value === 'running',
        'bg-blue-500': value === 'scheduled' || value === 'created',
      });

      return (
        <div className="flex justify-center">
          <div className={className} />
          {value}
        </div>
      );
    },
  }),
  columnHelper.display({
    header: 'Actions',
    id: 'actions',
    cell: ({ row: { original } }) => (
      <DownloadButton
        customClass="mx-4"
        Icon={DownloadIcon}
        file={{
          name: `${original.name}.log`,
          url: original.logFileUrl as string,
        }}
      />
    ),
  }),
];

export interface PartsTableProps {
  operationId: string | number;
}

function PartsTable({ operationId }: PartsTableProps): JSX.Element {
  const [scope, scopeKey, props] = useBackendTable<MultipartOperationPart>();

  const { data, isLoading } = useQuery(['parts', scopeKey, operationId], () =>
    scope(MultipartOperationPart.scope())
      .where({ operationId })
      .selectExtra(['log_file_url'])
      .all(),
  );

  return (
    <Table
      isLoading={isLoading}
      totalCount={data?.meta?.stats?.total?.count}
      data={data?.data || []}
      reactTableProps={props}
      columns={columns}
    />
  );
}

export default PartsTable;
