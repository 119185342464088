import Employee from '@spraypaint/Employee';

export type PayslipFollowUpPayloadType = {
  submittedFollowUp: string;
  employeeId: string;
};

export const updatePayslipFollowUp = async ({
  employeeId,
  submittedFollowUp,
}: PayslipFollowUpPayloadType) => {
  const employee = new Employee({
    id: employeeId,
  });
  employee.isPersisted = true;
  employee.followUp = submittedFollowUp;

  await employee.saveOrThrow();
};
