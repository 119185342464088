import { SpraypaintBase } from 'spraypaint';
import { RowData, SortingState, TableOptions } from '@tanstack/react-table';
import { flow } from 'lodash';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { GraphitiScoper } from '@spraypaint/types';
import { useMemo } from 'react';
import { Tools, useControlledTable } from './useControlledTable';
import { useBackendSorting } from './useBackendSorting';
import { useBackendPagination } from './useBackendPagination';
import { useBackendFiltering } from './useBackendFiltering';

const defaultDebounceDelay = 500;

function getRowId<TData extends SpraypaintBase & RowData>(
  original: ModelRecord<TData>,
  index: number,
) {
  return original.id ?? original.temp_id ?? `i${index}`;
}

/*
  Use this to handle filtering, sorting and pagination in backend

  @usage
    const [scope, scopeKey, reactTableProps] = useBackendTable<CommercialContract>()
*/
export function useBackendTable<TData extends SpraypaintBase & RowData>({
  initialPageSize = 10,
  initialSorting = [],
  debounceDelay = defaultDebounceDelay,
}: {
  initialPageSize?: number;
  initialSorting?: SortingState;
  paramKey?: string;
  debounceDelay?: number;
} = {}): [
  scope: GraphitiScoper<TData>,
  scopeKey: Array<string>,
  reactTableProps: Partial<TableOptions<ModelRecord<TData>>>,
  tools: Tools,
] {
  const [
    { pagination, sorting, columnFilters },
    table,
    reactTablePropsBackend,
    tools,
  ] = useControlledTable<ModelRecord<TData>>({
    initialPageSize,
    initialSorting,
  });

  const [paginate, paginationKey] = useBackendPagination<TData>(pagination);
  const [sort, sortingKey] = useBackendSorting<TData>(sorting);
  const [filter, filteringKey] = useBackendFiltering<TData>(
    columnFilters,
    table,
    debounceDelay,
  );

  const reactTableProps = useMemo(
    () => ({
      ...reactTablePropsBackend,
      getRowId,
    }),
    [reactTablePropsBackend],
  );

  return [
    flow([filter, sort, paginate]),
    [sortingKey, paginationKey, filteringKey],
    {
      ...reactTableProps,
      getRowId,
    },
    tools,
  ];
}
