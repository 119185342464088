import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { MainCard } from '@/modules/common/components/mainCard';
import { Toaster } from '@/modules/common/components/toasts';
import useUser from '../hooks/useUser';
import UserForm, { UserFormValues } from '../components/UserForm';

const UserEdit = () => {
  const { id } = useParams();
  const { user } = useUser(String(id));
  const { t } = useTranslation();

  if (!user) return null;

  const onSubmit = async (data: UserFormValues) => {
    user.firstName = data.firstName;
    user.lastName = data.lastName;
    user.email = data.email;
    const success = await user.save();
    if (success) {
      toast.success(
        <Toaster
          textMsg={t('views.shared.change_applied')}
          toastType="success"
        />,
      );
    }
  };

  return (
    <MainCard
      header="Modifier un utilisateur"
      className="mt-[20px] mx-5 md:w-[50%]"
    >
      <UserForm user={user} onSubmit={onSubmit} />
    </MainCard>
  );
};
export default UserEdit;
