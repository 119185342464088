import PayslipSimulation from '@/app/spraypaint/PayslipSimulation';

export type PayslipSimulationPayloadType = {
  expertiseId: string;
  portageOfferId: string;
  simulationOptionsType: string;
  workingTimeCount: string;
  monthDuration: string;
  comment: string;
  rateOfPay?: number;
  workingTimeUnit?: string;
  monthlyExpenses: number;
  healthInsurancePackage: string;
  securitis: string;
  employmentType: string;
  employmentStatus: string;
  legalEntityId: number | undefined | null;
  contentType: string;
  email: string | undefined;
  civility: string;
  firstName: string;
  lastName: string;
  optimizationName?: string;
  optimizationValue?: number;
};

export const useCreatePayslipSimulation = async (
  payload: PayslipSimulationPayloadType,
): Promise<PayslipSimulation> => {
  const payslipSimulation = new PayslipSimulation(payload);
  await payslipSimulation.saveOrThrow();
  return payslipSimulation;
};
