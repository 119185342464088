// api/ActivityAccountLineGroup.ts
import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from '../ApplicationRecord';
import type ActivityAccountLine from './ActivityAccountLine';
import type ActivityAccount from './ActivityAccount';

@Model()
class ActivityAccountLineGroup extends ApplicationRecord {
  static jsonapiType = 'activity_account_line_groups';

  // Attributes
  @Attr() activityAccountId!: number;

  @Attr() date!: string;
  @Attr() value!: MoneyType;
  @Attr() label!: string;

  // Relationships
  @HasMany('activity_account_lines')
  activityAccountLines!: Array<ActivityAccountLine>;
  @BelongsTo('activity_accounts') activityAccount!: ActivityAccount;
}

export default ActivityAccountLineGroup;
