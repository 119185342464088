import { useTranslation } from 'react-i18next';

const useEmploymentStatusOptions = () => {
  const { t } = useTranslation();

  const employmentStatusOptions = [
    {
      label: t('views.payslip_simulation.employment_status.non-cadre'),
      value: 'non-cadre',
    },
    {
      label: t('views.payslip_simulation.employment_status.cadre'),
      value: 'cadre',
    },
  ];

  return { employmentStatusOptions };
};
export default useEmploymentStatusOptions;
