import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { ConstValues } from '@/types';
import useAuthClient, { AuthErrorResponseWithReason } from './useAuthClient';

export type VerifyAccountResendVariables = {
  // The user's email address
  login: string;
};

export type VerifyAccountResendData = {
  success: string;
};

export const VERIFY_ACCOUNT_RESEND_REASON_CODES = {
  NO_MATCHING_LOGIN: 'no_matching_login',
} as const;

export type VerifyAccountResendReasonCode = ConstValues<
  typeof VERIFY_ACCOUNT_RESEND_REASON_CODES
>;

export type VerifyAccountResendRequestError =
  AuthErrorResponseWithReason<VerifyAccountResendReasonCode>;

export const useVerifyAccountResend = (
  opts: UseMutationOptions<
    AxiosResponse<VerifyAccountResendData>,
    AxiosError<VerifyAccountResendRequestError>,
    VerifyAccountResendVariables
  > = {},
) => {
  const authClient = useAuthClient();
  return useMutation({
    ...opts,
    mutationFn: (data) =>
      authClient.post<VerifyAccountResendData>('/verify-account-resend', data),
  });
};
