import { createColumnHelper, CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import dayjs from 'dayjs';
import SepaFile from '../../../../../app/spraypaint/sepaTransactions/SepaFile';
import {
  PeriodFilter,
  getPeriodFilterFn,
  TextFilter,
} from '../../../../common/components/table/filter';
import {
  EqualFilter,
  getEqualFilter,
} from '../../../../common/components/table/filter/EqualFilter';

const columnHelper = createColumnHelper<ModelRecord<SepaFile>>();

export const bankTransferSepaFilesColumns = () => {
  const { t } = useTranslation();

  const PointedMovementCell = ({
    cell,
  }: CellContext<ModelRecord<SepaFile>, unknown>) => {
    const { validatedTransactions, totalTransactions } = cell.row.original;

    return (
      <div className="flex justify-center items-center h-[100%]">
        {validatedTransactions} / {totalTransactions}
      </div>
    );
  };

  return [
    columnHelper.accessor('createdAt', {
      header: t('front.bankTransfer.index.table.importDate') as string,
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
      cell: (info) => (
        <div className="text-left">
          <p>{dayjs(info.getValue()).format('L - H:mm')}</p>
        </div>
      ),
    }),
    columnHelper.accessor('generatedAt', {
      header: t('front.bankTransfer.index.table.generatedAt') as string,
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    columnHelper.accessor('filename', {
      header: t('front.bankTransfer.index.table.filename') as string,
      meta: {
        filter: {
          Component: TextFilter,
        },
      },
      cell: (info) => (
        <div className="text-left">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    columnHelper.accessor('customFileType', {
      id: 'fileType',
      header: t('front.bankTransfer.index.table.fileType') as string,
      meta: {
        filter: {
          Component: TextFilter,
        },
      },
      cell: ({ cell }: any) => {
        const { fileType, imported } = cell.row.original;

        return (
          <div className="flex justify-center items-center h-[100%]">
            {fileType}
            {fileType === 'camt53' && (imported ? ' - ✅' : ' - ❌')}
          </div>
        );
      },
    }),
    columnHelper.accessor('customCompletionRate', {
      id: 'completionRate',
      header: t('front.bankTransfer.index.table.completionRate') as string,
      filterFn: getEqualFilter(),
      meta: {
        filter: {
          Component: EqualFilter,
        },
      },
      cell: (cell) => {
        const { completionRate } = cell.row.original;

        return (
          <div className="text-center">
            <p>{completionRate || 0} %</p>
          </div>
        );
      },
    }),
    columnHelper.accessor('custom', {
      header: t('front.bankTransfer.index.table.total') as string,
      enableColumnFilter: false,
      cell: PointedMovementCell,
      enableSorting: false,
    }),
  ];
};
