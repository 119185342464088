import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { IlluSalaireGris } from '../../../app/assets/icons/payslip';
import { PeriodType } from '../../common/utils/type.utils';

export type NoPayslipStartedProps = {
  nextPayslipFunnelPeriod: PeriodType;
  nextFunnelAutoStartDate: string | Date;
};

export const NoPayslipStarted = ({
  nextPayslipFunnelPeriod,
  nextFunnelAutoStartDate,
}: NoPayslipStartedProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center pl-[35px] mt-[25px]">
      <IlluSalaireGris height={93} width={93} />
      <p className="relative right-[15px]">
        <p>
          {t('front.payslip.index.header.emptyPayslip.information1', {
            month: dayjs(
              new Date(
                nextPayslipFunnelPeriod.year,
                nextPayslipFunnelPeriod.month - 1,
                1,
              ),
            ).format('MMMM YYYY'),
          })}
          <span className="text-color-pone-orange">
            {' '}
            {dayjs(nextFunnelAutoStartDate).format('DD/MM/YYYY')}.
          </span>
        </p>
        <p>{t('front.payslip.index.header.emptyPayslip.information2')}</p>
      </p>
    </div>
  );
};
