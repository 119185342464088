// api/Subcontractor.ts
import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';
import SubcontractorBill from '@spraypaint/SubcontractorBill';
import ApplicationRecord from './ApplicationRecord';
import Employee from './Employee';

export type NationalityEnum =
  | 'france'
  | 'eu'
  | 'outside_eu'
  | 'reunion_guadeloupe_martinique';

export type StatusEnum = 'in_progress' | 'submitted' | 'validated' | 'rejected';

@Model()
class Subcontractor extends ApplicationRecord {
  static jsonapiType = 'subcontractors';

  // Attributes
  @Attr() name!: string;
  @Attr() contactName!: number;
  @Attr() contactEmail!: string;
  @Attr() contactPhone!: string;
  @Attr() employeeId!: string;
  @Attr() status!: StatusEnum;
  @Attr() siren!: string;
  @Attr() securityNumber!: string;
  @Attr() refusalReason!: string;
  @Attr() accountNumber!: string;
  @Attr() nationality!: NationalityEnum;
  @Attr() isActive!: boolean;
  @Attr() address!: string;
  @Attr() addressNumber!: string;
  @Attr() addressRoad!: string;
  @Attr() addressCity!: string;
  @Attr() addressCountry!: string;
  @Attr() addressCountryCode!: string;
  @Attr() addressZipcode!: string;
  @Attr() coverageCertificateEndDateValidity!: Date;
  @Attr() publicDeptCertificateEndDateValidity!: Date;
  @Attr() kbisDocEndDateValidity!: Date;

  @BelongsTo('employees') employee!: Employee;
  @HasMany('subcontractor_bills') subcontractorBills!: Array<SubcontractorBill>;
}

export default Subcontractor;
