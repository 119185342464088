import { AttributeLine } from '@common/components/AttributeLine';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import type { PortageOffer } from '@/app/spraypaint';
import { EntityLogo } from '@/modules/layout/components/EntityLogo';
import { LegalEntitySlugType } from '@spraypaint/LegalEntity';

const PortageOfferInfo: FC<{
  offer: PortageOffer;
  legalEntitySlug: LegalEntitySlugType | undefined;
}> = ({ offer, legalEntitySlug }) => {
  const { t } = useTranslation();
  return (
    <div>
      <EntityLogo slug={legalEntitySlug} className="h-[36px] mb-5" />
      <AttributeLine
        id="name"
        label={t('activerecord.attributes.portage_offer.name')}
        value={offer.name}
      />
      <AttributeLine
        id="standardRate"
        label={t('activerecord.attributes.portage_offer.standard_rate')}
        value={`${offer.standardRate ?? '0'} %`}
      />
      <AttributeLine
        id="managementFeesCeiling"
        label={t(
          'activerecord.attributes.portage_offer.management_fees_ceiling',
        )}
        value={`${offer.managementFeesCeiling ?? '0'} %`}
      />
      <AttributeLine
        id="totalCashAdvanceRateNotTransferDbt"
        label={t(
          'activerecord.attributes.portage_offer.total_cash_advance_rate_not_transfer_dbt',
        )}
        value={`${offer.totalCashAdvanceRateNotTransferDbt ?? '0'} %`}
      />
      <AttributeLine
        id="sponsorshipBonus"
        label={t('activerecord.attributes.portage_offer.sponsorship_bonus')}
        value={`${offer.sponsorshipBonus ?? '0'} %`}
      />
      <AttributeLine
        id="mediumNotationTotalCashAdvanceRate"
        label={t(
          'activerecord.attributes.portage_offer.medium_notation_total_cash_advance_rate',
        )}
        value={`${offer.mediumNotationTotalCashAdvanceRate ?? '0'} %`}
      />
    </div>
  );
};

export default PortageOfferInfo;
