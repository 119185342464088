import {
  Control,
  Controller,
  FieldPathValue,
  FieldPathByValue,
  FieldValues,
  FieldError,
} from 'react-hook-form';
import { Error } from '@common/components/inputs/components/Error';
import { DateInput, DateInputProps } from '../DateInput';

type Props<
  TFieldValues extends FieldValues,
  // Following type check ensure that name is a valid FieldPath (ie, an existing field)
  // and that this field is of type Date | undefined
  TPath extends FieldPathByValue<TFieldValues, Date | string | undefined>,
> = {
  control: Control<TFieldValues>;
  defaultValue?: FieldPathValue<TFieldValues, TPath>;
  name: TPath;
  errorText?: FieldError | string | undefined;
} & Omit<DateInputProps, 'value' | 'onChange'>;

export const ControlledDateInput = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, Date | string | undefined>,
>({
  control,
  defaultValue,
  name,
  errorText,
  ...props
}: Props<TFieldValues, TPath>) => {
  const errorMsg =
    typeof errorText === 'string' ? errorText : errorText?.message;
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value } }) => (
        <div>
          <DateInput
            {...props}
            value={value}
            onChange={onChange}
            className={errorText ? 'border-color-failure' : ''}
          />
          {errorText && <Error>{String(errorMsg)}</Error>}
        </div>
      )}
    />
  );
};
