import { PageLayout } from '@/modules/common/components/PageLayout';
import { MainCard } from '@/modules/common/components/mainCard';
import Skeleton from 'react-loading-skeleton';

const InvoiceSkeleton = () => (
  <PageLayout Title={<Skeleton count={1} height={16} width={300} />}>
    <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-6">
      <MainCard header={<Skeleton count={1} height={16} width={300} />}>
        <Skeleton count={5} height={16} width={300} />
      </MainCard>
      <MainCard header={<Skeleton count={1} height={16} width={300} />}>
        <Skeleton count={5} height={16} width={300} />
      </MainCard>
    </div>
  </PageLayout>
);

export default InvoiceSkeleton;
