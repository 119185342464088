import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import {
  StrategyDescription,
  MaximumSalaryStrategy,
  MaximumSalaryStrategyDescription,
  SalaryStrategy,
} from '../../../../app/spraypaint/types/salaryStrategyTypes';
import { MoneyType } from '../../../common/utils/type.utils';
import { PayslipChoiceCard } from './PayslipChoiceCard';
import { SwitchSelection } from '../../../common/components/buttons';
import { useSetDefaultOrLast } from '../hooks/useSetDefaultOrLast';
import { containerChoiceCard } from '../../constantes/tailwindStyle';

interface MaxAmountCardProps {
  onStrategyChange: (strategy: MaximumSalaryStrategy) => void;
  currentStrategy: SalaryStrategy | undefined;
  availableStrategiesDescription: StrategyDescription[];
}

export const MaxAmountCard = ({
  onStrategyChange,
  currentStrategy,
  availableStrategiesDescription,
}: MaxAmountCardProps) => {
  const { t } = useTranslation();

  const { handleClick, defaultOrLast } = useSetDefaultOrLast(
    {
      name: 'maximum_salary',
      options: {
        use_financial_reserve: false,
      },
    },
    currentStrategy,
    onStrategyChange,
  );

  const toggleUseFinancialReserve = useCallback(
    (currentUseFinancialReserve: boolean): boolean =>
      !currentUseFinancialReserve,
    [],
  );

  const maxGrossSalaryDescription = availableStrategiesDescription.find(
    (description) => description.name === 'maximum_salary',
  ) as MaximumSalaryStrategyDescription | undefined;

  if (!maxGrossSalaryDescription) return null;

  const maxGrossSalary = maxGrossSalaryDescription.variables.value.gross;
  const maxGrossSalaryWithFinancialReserve =
    maxGrossSalaryDescription.variables.value_with_financial_reserve.gross;

  const isActive = currentStrategy?.name === 'maximum_salary';

  const handleSwitchChange = () => {
    if (!isActive) return;

    onStrategyChange({
      name: 'maximum_salary',
      options: {
        ...currentStrategy.options,
        use_financial_reserve: toggleUseFinancialReserve(
          currentStrategy.options.use_financial_reserve,
        ),
      },
    });
  };

  const switchOptions = [
    {
      label: t('default.no').toUpperCase(),
      value: false,
    },
    {
      label: t('default.yes').toUpperCase(),
      value: true,
    },
  ];

  let displayValue: MoneyType;
  if (
    isActive
      ? currentStrategy.options.use_financial_reserve
      : defaultOrLast.options.use_financial_reserve
  ) {
    displayValue = maxGrossSalaryWithFinancialReserve;
  } else {
    displayValue = maxGrossSalary;
  }

  const allowReserve =
    maxGrossSalaryDescription.variables.financial_reserve_status ===
    'available';

  return (
    <div className={containerChoiceCard}>
      <PayslipChoiceCard
        strategyName="maximum_salary"
        value={displayValue}
        isActive={isActive}
        onClick={handleClick}
        tooltip
      />
      {isActive && allowReserve && (
        <div className="flex flex-col w-[272px] h-[150px] py-[30px] mt-2.5 shadow-card">
          <p className="text-center text-base2 text-color-pone-dark mb-6">
            {t('front.payslip.tdc.salaryChoice.maximum_salary.subCard.title')}
          </p>
          <div className="flex justify-center">
            <SwitchSelection
              options={switchOptions}
              onChange={handleSwitchChange}
              value={isActive && currentStrategy.options.use_financial_reserve}
            />
          </div>
        </div>
      )}
    </div>
  );
};
