import { ActionFunctionMap, assign, createMachine, LowInfer } from 'xstate';
import { isPayloadEvent } from '@/modules/common/components/wizard/functions/isPayloadEvent';
import {
  CustomStateNode,
  CustomStates,
  Event,
  Schema,
  WizardPage,
  WizardPageCollection,
} from '../types';
import { keyToEvent } from './keyToEvent';

// Return an array of WizardPage from a WizardPageCollection
//   WizardPageCollection elements may not contain the key property
//   so we need to override it with the key of the object
export function pageCollectiontoPages<TKeys extends string>(
  pages: WizardPageCollection<TKeys>,
): WizardPage<TKeys>[] {
  return Object.entries(pages).map(([key, page]) => ({
    ...(page as WizardPage<TKeys>),
    key: key as TKeys,
  }));
}

// Reduce a list of pages to a state machine config
// Each page will have a state node with a transition 'NEXT' to the next
// page and a transition 'PREVIOUS' to the previous page
// They also have a GO_TO_${KEY} transition for each previous pages
// Last page has a SUBMIT transition instead of a NEXT transition
function pagesToStateReducer<TFormData, TKeys extends string>(
  states: CustomStates<TFormData, TKeys>,
  currentPage: WizardPage<TKeys>,
  currentIndex: number,
  pages: WizardPage<TKeys>[],
): CustomStates<TFormData, TKeys> {
  const isLastPage = currentIndex === pages.length - 1;
  const isFirstPage = currentIndex === 0;

  const nextPage = pages[currentIndex + 1];
  const previousPage = pages[currentIndex - 1];

  const on: CustomStateNode<TFormData, TKeys>['on'] = {};

  if (isLastPage) {
    on.SUBMIT = {
      target: 'sending',
      actions: ['updateContext', 'submitForm'], // defined bellow in initWizardMachine
    };
  } else {
    on.NEXT = {
      target: nextPage.key,
      actions: ['updateContext'], // defined bellow in initWizardMachine
    };
  }

  if (!isFirstPage) {
    on.PREVIOUS = {
      target: previousPage.key,
      actions: ['updateContext'], // defined bellow in initWizardMachine
    };
  }

  // Create GO_TO_${KEY} transitions for each previous pages
  const pastPages = pages.slice(0, currentIndex);
  pastPages.forEach((page) => {
    const { key } = page;
    const eventName = keyToEvent(key);
    // @ts-expect-error TS2322 eventName is not recognised as a valid event
    on[eventName] = { target: key };
  });

  return {
    ...states,
    [currentPage.key]: { on },
  };
}

// Create a state machine from a list of pages
export function initWizardMachine<TFormData, TKeys extends string>(
  id: string,
  initialContext: LowInfer<TFormData>,
  pagesCollection: WizardPageCollection<TKeys>,
  onSubmit: (data: TFormData) => void | Promise<void>,
) {
  const pages = pageCollectiontoPages(pagesCollection);
  const states = pages.reduce(
    pagesToStateReducer,
    {} as CustomStates<TFormData, TKeys>,
  );

  // Define actions listed in the state machine config
  // @see pagesToStateReducer
  const actions: ActionFunctionMap<TFormData, Event<TFormData, TKeys>> = {
    // Append the payload to the context
    updateContext: assign((context, event) => {
      if (isPayloadEvent(event)) {
        return {
          ...context,
          ...event.payload,
        };
      }

      return context;
    }),
    // // Trigger the onSubmit callback with the final context
    // submitForm: async (context) => {
    //   await onSubmit(context);
    // },
  };

  const asyncOnSubmit = async (context: TFormData) => {
    await onSubmit(context);
  };

  return createMachine<
    TFormData,
    Event<TFormData, TKeys>,
    Schema<TFormData, TKeys>
  >(
    {
      predictableActionArguments: true,
      id,
      context: initialContext,
      initial: pages[0].key,
      states: {
        ...states,
        sending: {
          invoke: {
            src: asyncOnSubmit,
            onDone: {
              target: 'done',
            },
            onError: {
              target: pages[pages.length - 1].key,
            },
          },
        },
        done: {
          type: 'final',
        },
      },
    },
    { actions },
  );
}
