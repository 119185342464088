import {
  ButtonPrimary,
  ButtonSecondary,
} from '@/modules/common/components/buttons';
import { Context } from 'react';
import { FieldValues } from 'react-hook-form';
import {
  FormWizardContextType,
  useFormWizardContext,
} from '../functions/useFormWizardContext';

export type FormWizardButonsProps<
  TFormData extends FieldValues,
  TKeys extends string,
> = {
  wizardContext: Context<FormWizardContextType<TFormData, TKeys>>;
};

export function FormWizardButtons<
  TFormData extends FieldValues,
  TKeys extends string,
>({ wizardContext }: FormWizardButonsProps<TFormData, TKeys>) {
  const { hasButton, goBack } = useFormWizardContext(wizardContext);

  return (
    <div className="flex">
      {hasButton.previous && (
        <ButtonSecondary className="m-2" onClick={goBack}>
          Previous
        </ButtonSecondary>
      )}
      {hasButton.next && (
        <ButtonPrimary className="m-2" type="submit">
          Next
        </ButtonPrimary>
      )}
      {hasButton.submit && (
        <ButtonPrimary className="m-2" type="submit">
          Submit
        </ButtonPrimary>
      )}
    </div>
  );
}
