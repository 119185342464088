import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { Line } from '../../fragments';
import { IconType } from '../../../utils/type.utils';

export type HeaderMainCardTextProps = {
  title: ReactNode;
  actionButtons?: ReactNode;
  Icon?: IconType;
  headerClassNames?: string;
  textClassNames?: string;
  handleOnClick?: (value: boolean) => void;
  displayAll?: boolean;
  displayCollapseButton?: boolean;
};

export const HeaderMainCardText = ({
  title,
  Icon,
  actionButtons = null,
  handleOnClick,
  displayAll = false,
  displayCollapseButton = false,
  headerClassNames,
  textClassNames,
}: HeaderMainCardTextProps) => {
  const { t } = useTranslation();
  const customClassNames =
    'hover:cursor-pointer text-sm3 text-color-pone-dark font-cabin-bold';

  const customHeaderClassNames = classNames(
    'pt-5',
    { 'pb-5': displayAll },
    headerClassNames,
  );

  const customTextClassNames = classNames(
    'text-color-medium-grey text-xl',
    textClassNames,
  );

  return (
    <div className={customHeaderClassNames}>
      <div className="px-5 pb-2 flex justify-between">
        <p className={customTextClassNames}>{title}</p>
        {actionButtons}
        {Icon && <Icon data-testid="maincard-icon" />}
        {displayCollapseButton &&
          (displayAll ? (
            <div
              className={customClassNames}
              onClick={() => handleOnClick && handleOnClick(false)}
              role="none"
            >
              {t('front.mainCard.header.closeAll').toUpperCase()}
            </div>
          ) : (
            <div
              className={customClassNames}
              onClick={() => handleOnClick && handleOnClick(true)}
              role="none"
            >
              {t('front.mainCard.header.openAll').toUpperCase()}
            </div>
          ))}
      </div>
      {displayAll && <Line />}
    </div>
  );
};
