import Skeleton from 'react-loading-skeleton';

export const SkeletonTutorial = () => (
  <div className="flex ml-[94px] mt-[90px]">
    <Skeleton count={1} height={203} width={178} />
    <div className="flex flex-col ml-[46px] mr-[113px]">
      <Skeleton count={1} height={28} width={900} />
      <Skeleton count={1} height={72} width={900} />
      <Skeleton count={1} height={28} width={150} className="my-[46px]" />
      <Skeleton count={1} height={48} width={900} />
      <Skeleton count={1} height={50} width={131} />
    </div>
  </div>
);
