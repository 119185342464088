import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import mixpanel from 'mixpanel-browser';
import useAuthClient, { AuthErrorResponse } from './useAuthClient';

export type LoginVariables = {
  // The user's email address
  login: string;

  // User's password
  password: string;
};

export type LoginData = {
  success: string;
  redirect: string;
};

export const useLogin = (
  opts: UseMutationOptions<
    AxiosResponse<LoginData>,
    AxiosError<AuthErrorResponse>,
    LoginVariables
  > = {},
) => {
  const authClient = useAuthClient();
  return useMutation({
    ...opts,
    mutationFn: (data) => authClient.post<LoginData>('/login', data),
    onSuccess: (...args) => {
      mixpanel.track('Sign In', { loginMethod: 'cookie' });
      if (opts.onSuccess) {
        opts.onSuccess(...args);
      }
    },
  });
};
