import classNames from 'classnames';
import { iconError24px } from '../../../../app/assets/icons/statuses';
import {
  cancellationExpenseProps,
  closedProps,
  doneMatchingProps,
  failureProps,
  orphanedProps,
  duplicateProps,
  paidProps,
  partiallyPaidProps,
  rejectedProps,
  runningMatchingProps,
  submittedProps,
  unknownProps,
  validatedProps,
  waitingMatchingProps,
  waitingProps,
  missingProps,
  stcProps,
  newProps,
  signatureStartedProps,
  draftProps,
  irrecoverableProps,
} from '../constants/statusConstants';
import StatusComponent from './StatusComponent';

export type StatusType =
  | 'closed'
  | 'failure'
  | 'orphaned'
  | 'rejected'
  | 'validated'
  | 'in_progress'
  | 'draft'
  | 'irrecoverable'
  | 'submitted'
  | 'cancellationExpense'
  | 'partially_paid'
  | 'paid'
  | 'waiting'
  | 'running'
  | 'done'
  | 'duplicate'
  | 'missing'
  | 'stc'
  | 'new'
  | 'missing'
  | 'signature_procedure_started'
  | 'initiated'
  | 'ready'
  | 'sent';

export type Props = {
  className?: string;
  textStyle?: string;
  waitingForAction?: boolean;
  statusType: StatusType;
  title?: string;
  alt?: string;
  icon?: string;
};

function Status({
  className,
  textStyle,
  waitingForAction,
  statusType,
  title,
  alt,
  icon,
}: Props) {
  const statusMapper = {
    closed: closedProps(),
    failure: failureProps(),
    rejected: rejectedProps(),
    orphaned: orphanedProps(),
    duplicate: duplicateProps(),
    validated: validatedProps(),
    sent: validatedProps(),
    paid: paidProps(),
    in_progress: waitingProps(waitingForAction),
    ready: waitingProps(waitingForAction),
    draft: draftProps(waitingForAction),
    partially_paid: partiallyPaidProps(),
    submitted: submittedProps(),
    irrecoverable: irrecoverableProps(),
    cancellationExpense: cancellationExpenseProps(),
    waiting: waitingMatchingProps(),
    running: runningMatchingProps(),
    done: doneMatchingProps(),
    missing: missingProps(),
    stc: stcProps(),
    new: newProps(),
    initiated: newProps(),
    signature_procedure_started: signatureStartedProps(),
    unknown: unknownProps(statusType),
  };

  let customStatus;
  if (statusMapper[statusType] === undefined) customStatus = 'unknown' as const;
  else customStatus = statusType;

  const statusClass = classNames(
    'rounded-[100px] text-center pr-3 pl-3 hidden sm:block lg:block',
    statusMapper[customStatus]?.className?.containerStyle,
    className,
  );
  const customTextStyle = `${statusMapper[customStatus].className.textStyle} ${textStyle}`;

  const customIcon = icon || statusMapper[customStatus].icon;
  const customAlt = alt || statusMapper[customStatus].alt;
  const customTitle = title || statusMapper[customStatus].title;

  if (customStatus === 'in_progress') {
    return (
      <div className="flex items-center">
        {waitingForAction && (
          <img src={iconError24px} alt="error" className="hidden sm:block" />
        )}
        <StatusComponent
          icon={customIcon}
          alt={customAlt}
          textStyle={customTextStyle}
          statusClass={statusClass}
          title={customTitle}
        />
      </div>
    );
  }

  return (
    <StatusComponent
      icon={customIcon}
      alt={customAlt}
      textStyle={customTextStyle}
      statusClass={statusClass}
      title={customTitle}
    />
  );
}

export default Status;
