/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import { SepaMatchingCandidate } from '../../../../../app/spraypaint';

export const useGetMatchingScore = ({
  args,
  scope,
  idTransaction,
  onSuccess,
}: {
  args?: any;
  idTransaction: any;
  onSuccess?: any;
  scope: (
    baseScope: Scope<SepaMatchingCandidate>,
  ) => Scope<SepaMatchingCandidate>;
}) => {
  const data = useQuery({
    queryKey: ['matching-score', args],
    queryFn: () =>
      scope(
        SepaMatchingCandidate.includes(
          'candidate,invoice.client,invoice.debtor,subcontractor_bill.subcontractor,contrib_pee_deposit.employee,contrib_perco_deposit.employee',
        )
          .where({
            sepa_transaction_id: idTransaction,
            upToDate: true,
          })
          .order('reason')
          .scope(),
      ).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onSuccess: (response: any) => {
      onSuccess(
        response?.data?.map(
          (candidate: SepaMatchingCandidate) => candidate.candidate,
        ) || [],
      );
    },
  });

  const totalCount = data?.data?.meta.stats.total.count;
  const candidates =
    data?.data?.data?.map(
      (candidate: SepaMatchingCandidate) => candidate.candidate,
    ) || [];

  return { ...data, candidates, totalCount };
};
