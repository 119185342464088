import Modal, { Props } from 'react-modal';
import useBreakpoints from '../../../../app/responsive/useBreakPoints';

const desktopModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    zIndex: '100',
  },
  overlay: {
    zIndex: '100',
  },
};

const responsiveModalStyle = {
  ...desktopModalStyle,
  content: {
    ...desktopModalStyle.content,
    left: '20%',
    marginRight: '-10%',
    transform: 'translate(-35%, -30%)',
  },
};

export type CustomModalProps = Props;

export const CustomModal = ({ children, ...others }: CustomModalProps) => {
  const { isXs } = useBreakpoints();
  const modalStyle = isXs ? responsiveModalStyle : desktopModalStyle;

  return (
    <Modal style={modalStyle} {...others} ariaHideApp={false}>
      {children}
    </Modal>
  );
};
