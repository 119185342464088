// api/PayslipFunnelSteps::FinalSummaryStep.ts
import { Model, Attr, HasOne } from 'spraypaint';
import type { SalaryStrategy } from '../types/salaryStrategyTypes';
import PayslipFunnelPayslipPreview from './PayslipFunnelPayslipPreview';
import PayslipFunnelBaseStep from './PayslipFunnelBaseStep';

@Model()
class PayslipFunnelFinalSummaryStep extends PayslipFunnelBaseStep {
  static jsonapiType = 'payslip_funnel_steps-final_summary_steps';

  // Attributes
  @Attr({ persist: false }) mayComplete!: boolean;
  @Attr({ persist: false }) completed!: boolean;
  @Attr({ persist: false }) hoursDistribution!: any;

  @Attr() salaryStrategy!: SalaryStrategy;

  // Relationships
  @HasOne(PayslipFunnelPayslipPreview)
  payslipPreview!: PayslipFunnelPayslipPreview;
}

export default PayslipFunnelFinalSummaryStep;
