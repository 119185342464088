import { selectUserId } from '../../app/store/selectors/selectUserId';
import useFeatureFlag from './useFeatureFlag';

/**
 * @param {string} featureName
 * @param {string, number} userId
 * @return  {[boolean, object]}
 */
export default function useUserFeatureFlag(
  featureName: string,
  userId: number | string | null = null,
) {
  const currentUserId = selectUserId();

  return useFeatureFlag(featureName, `User/${userId || currentUserId}`);
}
