import { useMutation } from '@tanstack/react-query';
import { SepaTransactionAssignation } from '../../../../../app/spraypaint';

export type PayloadType = {
  associableIds: Array<number>;
  associableType: string;
  sepaTransactionId: number;
};

export const useValidateMatching = () => {
  const data = useMutation({
    mutationFn: async (payload: PayloadType) => {
      const sepaTransactionFile = new SepaTransactionAssignation(payload);
      const result = await sepaTransactionFile.save();
      if (!result) {
        const { errors } = sepaTransactionFile;
        return errors;
      }
      return result;
    },
  });
  return { ...data };
};
