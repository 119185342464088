import { RowData, Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { IlluTableauVide } from '../../../../../app/assets/icons/table';

export const EmptyTableLayout = <TData extends RowData>({
  table,
  emptyTableErrorMsg = 'front.table.defaultErrorMsg',
  withImageErrorMsg = true,
}: {
  table: Pick<Table<TData>, 'getAllColumns'>;
  emptyTableErrorMsg?: string; // will be translated inside component, just need I18n key
  withImageErrorMsg?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <tbody className="">
      <tr className="bg-color-light-grey ">
        <td colSpan={table.getAllColumns().length}>
          <div className="bg-color-light-grey justify-between pt-[32px] pb-[19px] w-[354px] mx-auto">
            <p className="text-bold w-[231px] text-sm2 ml-[123px]">
              {t(emptyTableErrorMsg)}
            </p>
            {withImageErrorMsg && (
              <IlluTableauVide data-testid="illu" width={354} height={259} />
            )}
            <div className="w-[354px]" />
          </div>
        </td>
      </tr>
    </tbody>
  );
};
