import { MoneyType } from '../../../modules/common/utils/type.utils';

export type MinimumSalaryStrategy = {
  name: 'minimum_salary';
  options: Record<string, never>;
};

export type MinimumSalaryStrategyDescription = {
  name: 'minimum_salary';
  enabled: boolean;
  variables: {
    value: {
      net: MoneyType;
      gross: MoneyType;
    };
  };
};

export type StcSalaryStrategy = {
  name: 'stc_salary';
  options: Record<string, never>;
};

export type StcSalaryStrategyDescription = {
  name: 'stc_salary';
  enabled: boolean;
  variables: {
    value: {
      net: MoneyType;
      gross: MoneyType;
    };
  };
};

export type MaximumSalaryStrategy = {
  name: 'maximum_salary';
  options: {
    use_financial_reserve: boolean;
  };
};

export type MaximumSalaryStrategyDescription = {
  name: 'maximum_salary';
  enabled: boolean;
  variables: {
    financial_reserve_status: 'not_available' | 'available' | 'forced';
    available_financial_reserve: MoneyType;
    value: {
      net: MoneyType;
      gross: MoneyType;
    };
    value_with_financial_reserve: {
      net: MoneyType;
      gross: MoneyType;
    };
  };
};

export type TargetType = 'gross' | 'net';

export type CustomSalaryStrategy = {
  name: 'custom_salary';
  options: {
    value: MoneyType;
    target: TargetType;
  };
};

export type CustomSalaryStrategyDescription = {
  name: 'custom_salary';
  enabled: boolean;
  variables: {
    minimum: {
      net: MoneyType;
      gross: MoneyType;
    };
    maximum: {
      net: MoneyType;
      gross: MoneyType;
    };
  };
};

export type CustomStcSalaryStrategy = {
  name: 'custom_stc_salary';
  options: {
    value: MoneyType;
    target: TargetType;
  };
};

export type CustomStcSalaryStrategyDescription = {
  name: 'custom_stc_salary';
  enabled: boolean;
  variables: {
    minimum: {
      net: MoneyType;
      gross: MoneyType;
    };
    maximum: {
      net: MoneyType;
      gross: MoneyType;
    };
  };
};

export type SalaryStrategy =
  | MinimumSalaryStrategy
  | MaximumSalaryStrategy
  | CustomSalaryStrategy
  | StcSalaryStrategy
  | CustomStcSalaryStrategy;

export type ChoiceType = SalaryStrategy['name'];

export type StrategyDescription =
  | MinimumSalaryStrategyDescription
  | MaximumSalaryStrategyDescription
  | CustomSalaryStrategyDescription
  | StcSalaryStrategyDescription
  | CustomStcSalaryStrategyDescription;

export function findDescription<T extends StrategyDescription>(
  name: T['name'],
  descriptions: StrategyDescription[],
): T | undefined {
  return descriptions.find((d) => d.name === name) as T | undefined;
}

export function toggleTarget(currentTarget: TargetType): TargetType {
  return currentTarget === 'gross' ? 'net' : 'gross';
}
