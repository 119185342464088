import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';
import { MainCard } from '@common/components/mainCard';
import { ButtonPrimary } from '@common/components/buttons';
import { VideoTutorialType } from '../types/VideoTutorialType';

export const VideoTutorialCard = ({
  title,
  subtitle,
  description,
  url,
  image,
}: VideoTutorialType) => {
  const { t } = useTranslation();

  const trackEvent = (eventName: string) => {
    console.debug(`${eventName} for video ${title}`);

    mixpanel.track(eventName, {
      videoClicked: title,
    });
  };

  const handleImageClick = () => {
    trackEvent('Tutorials: Image clicked');
  };

  const handleButtonClick = () => {
    trackEvent('Tutorials: Button clicked');
  };

  return (
    <MainCard header={title}>
      <div className="flex-col justify-between">
        <div className="md:h-32 mb-3">
          <p className="mx-3 text-lg font-bold">{subtitle}</p>
          <p className="mx-3 mt-3">{description}</p>
        </div>
        <div className="flex justify-center">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={url}
            onClick={handleImageClick}
          >
            <img
              src={image}
              alt={title}
              width="700"
              className="hover:opacity-75"
            />
          </a>
        </div>
        <div className="mt-5 flex justify-end">
          <ButtonPrimary href={url} onClick={handleButtonClick} openInNewTab>
            {t('front.videoTutorial.card.button.label')}
          </ButtonPrimary>
        </div>
      </div>
    </MainCard>
  );
};
