import { Attr, HasOne, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type Invoice from '../Invoice';
import FactorClientPaymentTransaction from './FactorClientPaymentTransaction';

@Model()
class FactorCandidate extends ApplicationRecord {
  static jsonapiType = 'factor-candidates';

  @Attr({ persist: false }) candidateId!: number;
  @Attr({ persist: false }) candidateType!: string;
  @Attr({ persist: false }) transactionableId!: number;
  @Attr({ persist: false }) transactionableType!: string;
  @Attr({ persist: false }) matchingCandidateId!: number;
  @Attr({ persist: false }) metadata!: unknown;
  @Attr({ persist: false }) upToDate!: boolean;

  @HasOne({ name: 'candidate' }) candidate!: Invoice;
  @HasOne({ name: 'tranactionable' })
  tranactionable!: Array<FactorClientPaymentTransaction>;
}

export default FactorCandidate;
