import { useTranslation } from 'react-i18next';
import { MenuType } from '@/modules/layout/types/MenuType';
import { RoleType } from '../../authentification/types/RoleType';
import { TdbIcon } from '../../../app/assets/icons/menu';

export const constantSupportMenu = (
  roles: Array<RoleType>,
): Array<MenuType> => {
  const { t } = useTranslation();
  const display = roles?.includes('support');

  if (!display) return [];

  return [
    {
      type: 'separator' as const,
      title: t('front.menu.separator.support'),
      displaySeparator: display,
    },
    {
      title: t('front.menu.supports'),
      toV1: 'supports',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.offices'),
      toV1: 'offices',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.payslip_computation_results'),
      toV1: 'computation_results',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.bank_accounts'),
      toV1: 'bank_accounts',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.factors'),
      toV1: 'factors',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.operations'),
      to: 'operations',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      type: 'separator' as const,
      title: t('front.menu.separator.referentials'),
      displaySeparator: display,
    },
    {
      title: t('front.menu.ref_lunch_expenses'),
      toV1: 'lunch_expenses_rates',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.ref_int_expenses'),
      toV1: 'international_expenses_rates',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.expenses_meal_vouchers'),
      toV1: 'referentials?name=meal_voucher',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.expenses_all_referentials'),
      toV1: 'expenses_referentials',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.jobs'),
      toV1: 'expertises',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.contribution_pee_annual_max_referential'),
      toV1: 'contrib_pee_annual_max_referentials',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.contribution_perco_annual_max_referential'),
      toV1: 'contrib_perco_annual_max_referentials',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.contribution_investment_vehicle'),
      toV1: 'contrib_investment_vehicles',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.contribution_document'),
      toV1: 'contrib_documents',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.entities_documents'),
      toV1: 'entity_documents',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.payslip_comp_referential'),
      toV1: 'payslip_comp_referentials',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.prospect_sources'),
      toV1: 'prospect_sources',
      icon: TdbIcon,
      canBeDisplay: display,
    },
    {
      title: t('front.menu.fraud_watch_lists'),
      toV1: 'fraud_watch_lists',
      icon: TdbIcon,
      canBeDisplay: display,
      customClass: 'mb-10',
    },
  ];
};
