import { useQuery } from '@tanstack/react-query';
import PayslipSimulation from '@/app/spraypaint/PayslipSimulation';

export const useGetPayslipSimulation = (id: string) => {
  const data = useQuery({
    queryKey: ['payslip-simulations'],
    queryFn: () =>
      PayslipSimulation.includes(['portage_offer', 'manager'])
        .selectExtra(['payslip_computation'])
        .find(id),
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  const payslipSimulation = data.data?.data;
  return { ...data, payslipSimulation };
};
