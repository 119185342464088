import type ApplicationRecord from './ApplicationRecord';

export class ApiValidationError<T extends ApplicationRecord> extends Error {
  record: T;

  constructor(record: T) {
    super("The record's attributes are invalid");
    this.record = record;
    this.name = 'ApiValidationError';
  }

  get errors() {
    return this.record.errors;
  }

  static isInstance<TRecord extends ApplicationRecord>(
    error: unknown,
    recordClass: new (...args: any[]) => TRecord,
  ): error is ApiValidationError<TRecord> {
    return (
      error instanceof ApiValidationError && error.record instanceof recordClass
    );
  }
}
