import { Attr, BelongsTo, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type FactorAuthorization from './FactorAuthorization';

@Model()
class FactorOutstandingInvoice extends ApplicationRecord {
  static jsonapiType = 'factor-outstanding_invoices';

  @Attr({ persist: false }) invoiceId: number;
  @Attr({ persist: false }) factorAuthorizationId: number;
  @Attr({ persist: false }) buyerCode: number;
  @Attr({ persist: false }) clientCode: number;
  @Attr({ persist: false }) invoiceNumber: number;
  @Attr({ persist: false }) invoiceDate: number;
  @Attr({ persist: false }) invoiceDueDate: number;
  @Attr({ persist: false }) invoiceAmount: number;
  @Attr({ persist: false }) paidAmount: number;
  @Attr({ persist: false }) remainingAmount: number;
  @Attr({ persist: false }) outstandingReceivable: number;
  @Attr({ persist: false }) status: number;

  @BelongsTo('factor-authorizations') authorization!: FactorAuthorization;

  get adminShowPagePath(): string | undefined {
    const { invoiceId } = this;

    if (!invoiceId) {
      return undefined;
    }

    return `/v2/admin/invoices/${invoiceId}`;
  }
}

export default FactorOutstandingInvoice;
