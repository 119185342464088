import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { LinkV1V2Props } from '../../../layout/types/MenuType';
import useEmployeeFeatureFlag from '../../../feature_flag/useEmployeeFeatureFlag';
import useBreakpoints from '../../../../app/responsive/useBreakPoints';
import { IconType } from '../../utils/type.utils';

export type ButtonMenuProps = {
  children: string;
  Icon: IconType;
  textStyle?: string;
  iconStyle?: string;
  className?: string;
  disabled?: boolean;
  displayText: boolean;
  isSelected?: boolean;
  notificationsNumber?: number;
} & LinkV1V2Props;

function NotificationMenu({
  notificationsNumber,
}: {
  notificationsNumber: number;
}) {
  return (
    <div className="h-5 w-5 bg-color-pone-orange flex items-center justify-center text-color-pone-dark text-sm rounded-full">
      {notificationsNumber > 9 ? '9+' : notificationsNumber}
    </div>
  );
}

export function ButtonMenu({
  children,
  Icon,
  className = '',
  disabled,
  textStyle,
  displayText,
  iconStyle,
  to = '',
  toV1,
  moduleName,
  isSelected,
  notificationsNumber,
}: ButtonMenuProps) {
  const { isXs } = useBreakpoints();
  const customClassName = classNames(
    'flex h-[45px] pt-[15px] pb-[15px] pl-[22px] pr-[8px] rounded-none items-center justify-between',
    {
      'active:opacity-20 fill-white hover:fill-color-pone-orange text-white hover:text-color-pone-orange':
        !disabled,
      'fill-color-medium-grey': disabled,
      'bg-color-transparent-pone-dark': isSelected,
      'bg-color-pone-dark': !isSelected,
      'w-screen md:w-auto': !displayText,
      'w-60': displayText && !isXs,
    },
    className,
  );
  const customImagesName = classNames('w-5 h-5', iconStyle);
  const customTextStyle = classNames(
    'ml-[8px] text-base text-bold',
    {
      '': !disabled,
      'text-color-medium-grey': disabled,
      'self-center': !displayText,
    },
    textStyle,
  );

  const renderMain = () => (
    <>
      <div className="flex items-center">
        {Icon && <Icon className={customImagesName} />}
        {displayText && <p className={customTextStyle}>{children}</p>}
      </div>
      {notificationsNumber && displayText && (
        <NotificationMenu notificationsNumber={notificationsNumber} />
      )}
    </>
  );

  let isLink;

  if (moduleName) {
    const [isActif] = useEmployeeFeatureFlag(`v2_redirect_${moduleName}`);
    isLink = isActif;
  } else if (to) {
    isLink = true;
  } else if (toV1) {
    isLink = false;
  }

  if (isLink) {
    return (
      <Link to={to} className={customClassName} role="link">
        {renderMain()}
      </Link>
    );
  }
  return (
    <a className={customClassName} href={`/${toV1}`}>
      {renderMain()}
    </a>
  );
}
