import MultipartOperationIndex from './views/MultipartOperationIndex';
import MultipartOperationShow from './views/MultipartOperationShow';
import { RoutesMounter } from '../../../types';

export const mountMultipartOperationRoutes: RoutesMounter = ({ path }) => [
  {
    path,
    children: [
      { index: true, element: <MultipartOperationIndex /> },
      { path: ':id', element: <MultipartOperationShow /> },
    ],
  },
];
