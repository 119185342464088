import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import AccountingJournalExport from '@spraypaint/accounting/AccountingJournalExport';

const useAccountingJournalExports = (
  scope: (
    baseScope: Scope<AccountingJournalExport>,
  ) => Scope<AccountingJournalExport>,
  randomProps: unknown,
) => {
  const data = useQuery({
    queryKey: ['accounting-journal-exports', randomProps],
    queryFn: () =>
      scope(AccountingJournalExport.scope())
        .includes(['legalEntity'])
        .where({ exportType: { not_eq: 'bank_statements' } })
        .order({ id: 'desc' })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const accountingJournalExport = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, accountingJournalExport, totalCount };
};

export const useAccountingJournalExportsByIds = ({
  ids,
}: {
  ids: number[];
}) => {
  const data = useQuery({
    queryKey: ['accounting-journal-exports-ids', ids],
    queryFn: () => AccountingJournalExport.scope().where({ id: ids }).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const accountingJournalExports = data?.data?.data;

  return { ...data, accountingJournalExports };
};

export default useAccountingJournalExports;
