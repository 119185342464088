import FactorTransfer from '@/app/spraypaint/factor/FactorTransfer';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { useTranslation } from 'react-i18next';
import Table from '@/modules/common/components/table/Table';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import useFactorTransfers from '../hooks/useFactorTransfers';
import { factorTransfersColumns } from '../columns/factorTransfersColumns';

const FactorTransfers = () => {
  const { t } = useTranslation();
  const [scope, scopeKey, props] = useBackendTable<FactorTransfer>();
  const { factorTransfers, isLoading, totalCount } = useFactorTransfers(
    scope,
    scopeKey,
  );

  return (
    <PageLayout
      Title={t('front.menu.factor_transfers.rebates')}
      Buttons={
        <ButtonPrimary to="new">
          {t('front.menu.factor_transfers.new_rebate')}
        </ButtonPrimary>
      }
    >
      <Table
        emptyTableErrorMsg="react_table.no_data_text"
        className="mt-[26px]"
        isLoading={isLoading}
        withImageErrorMsg={false}
        totalCount={totalCount}
        data={factorTransfers || []}
        reactTableProps={props}
        onRowClick={{
          navigate: (rowData) => rowData.id,
        }}
        columns={factorTransfersColumns}
      />
    </PageLayout>
  );
};

export default FactorTransfers;
