import classNames from 'classnames';
import { ReactNode } from 'react';
import { CollapseButton } from './CollapseButton';

/* eslint-disable arrow-body-style */
export type Props = {
  title?: string;
  rightComponent?: ReactNode;
  additionalFilters?: ReactNode;
  isCollapsed: boolean;
  collapsable?: boolean;
  onIsCollapsedChange: (newState: boolean) => void;
};

export const HeaderTitle = ({
  title,
  rightComponent,
  additionalFilters,
  isCollapsed,
  collapsable = false,
  onIsCollapsedChange,
}: Props) => {
  const iconClassName = classNames(
    'fill-color-pone-dark h-[20px] w-[10px] mb-[2px]',
  );

  const headerContainerClassName = classNames(
    'flex w-full justify-between items-center h-[50px] pl-[48px]',
    {
      collapsable: 'border-[1px] border-color-light-grey',
    },
  );

  return (
    <div className={headerContainerClassName}>
      <p className="text-base3 text-color-medium-grey text-color-medium-grey">
        {title}
      </p>
      {additionalFilters}
      <div className="flex h-full items-center">
        {rightComponent}
        {collapsable && (
          <CollapseButton
            onIsCollapsedChange={onIsCollapsedChange}
            isCollapsed={isCollapsed}
            className={iconClassName}
          />
        )}
      </div>
    </div>
  );
};
