import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import FactorOutstandingInvoice from '@/app/spraypaint/factor/FactorOutstandingInvoice';

export const useGetOutstandingInvoices = (
  args: Array<string>,
  scope: (
    baseScope: Scope<FactorOutstandingInvoice>,
  ) => Scope<FactorOutstandingInvoice>,
) => {
  const data = useQuery({
    queryKey: ['factor-outstanding_invoices', ...args],
    queryFn: () =>
      scope(
        FactorOutstandingInvoice.includes([
          'authorization.registered_client',
        ]).scope(),
      )
        .where({ status: 'validated' })
        .all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const outstandingInvoices = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, outstandingInvoices, totalCount };
};
