import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FunctionComponent, ReactNode } from 'react';
import { useCurrentUser } from '@/modules/authentification/hooks';
import { setUser } from '../../store/userStore';
import { LoaderPage } from './LoaderPage';

export const ProtectedRoute: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const { isError, isSuccess, isLoading, userData } = useCurrentUser();
  const dispatch = useDispatch();

  if (isLoading) return <LoaderPage />;
  if (isError) {
    // user is not authenticated
    // redirect to login page with a parameter  `next` to the url to redirect to the page
    // the user was trying to access, including query parameters and fragment

    const escapedCurrentLocation = encodeURIComponent(
      window.location.pathname + window.location.search + window.location.hash,
    );

    return <Navigate to={`/v2/login?next=${escapedCurrentLocation}`} />;
  }
  if (isSuccess && userData) {
    const user = userData.data;

    const userToStore = {
      civility: user.civility,
      email: user.email,
      employees: user.employees,
      firstName: user.firstName,
      id: user.id || '',
      lastName: user.lastName,
      primaryRole: user.primaryRole,
      roles: user.roles,
    };
    dispatch(setUser(userToStore));

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return null;
};
