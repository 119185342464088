import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import Transition from '../../../app/spraypaint/Transition';

export type OnSuccessTransitionType = {
  payslipFunnelId: number;
  transitionName: string;
  fromStep?: string;
  toStep?: string;
};

export const usePayslipFunnelTransition = ({
  payslipFunnelId,
  /* errorMsg = 'front.payslip.tdc.transition.error', */
  callBack,
  errorCallback,
}: {
  payslipFunnelId: number;
  errorMsg?: string;
  callBack?: (info: OnSuccessTransitionType) => void;
  errorCallback?: () => void;
}) => {
  const employeeId = useCurrentEmployeeId();
  const queryClient = useQueryClient();
  const { mutate, isLoading, ...others } = useMutation({
    mutationFn: async ({
      transitionName,
      fromStep,
      toStep,
    }: {
      fromStep?: string;
      toStep?: string;
      transitionName: string;
    }) => {
      const transition = new Transition({
        resourceId: payslipFunnelId,
        resourceType: 'PayslipFunnel',
        transition_name: transitionName,
      });
      const success = await transition.save();
      if (!success) {
        throw new Error('Transition failed');
      }
      return { payslipFunnelId, transitionName, fromStep, toStep };
    },
    onSuccess: (info: OnSuccessTransitionType) => {
      if (callBack) callBack(info);
      queryClient.invalidateQueries(['employee-payslip-funnel', employeeId]);
    },
    onError: () => {
      if (errorCallback) errorCallback();
      queryClient.invalidateQueries(['employee-payslip-funnel', employeeId]);
    },
  });
  return { mutate, isLoading, ...others };
};
