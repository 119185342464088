import { NavigateFunction } from 'react-router-dom';
import { MouseEvent } from 'react';

type ParamsType = {
  event: MouseEvent;
  id?: string;
  href?: string;
  windowNavigationHref: string | undefined;
  navigate: NavigateFunction;
  newTab?: boolean;
};

// td elements in a table need to be children to a tr parents, that is why we need to use a function
// instead of directly using a Link component inside our Table
// CF: https://github.com/TanStack/table/discussions/2336

export const onRowClickNavigate = ({
  event,
  id,
  href,
  windowNavigationHref,
  navigate,
  newTab,
}: ParamsType) => {
  if (event.metaKey || event.ctrlKey || newTab) {
    const win = window.open(href || windowNavigationHref, '_blank');
    win?.focus();
  } else if (href) {
    window.location.href = href;
  } else if (id) {
    navigate(id);
  }
};
