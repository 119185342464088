// api/PayslipFunnelSteps::SalaryStep.ts
import { Attr, Model } from 'spraypaint';
import type { MoneyType } from '../../../modules/common/utils/type.utils';
import type { SalaryStrategy } from '../types/salaryStrategyTypes';
import CompletionErrorType from '../../../modules/payslip_funnel/types/CompletionErrorType';
import { StrategyDescription } from '../types/salaryStrategyTypes';
import PayslipFunnelBaseStep from './PayslipFunnelBaseStep';

@Model()
class PayslipFunnelSalaryStep extends PayslipFunnelBaseStep {
  static jsonapiType = 'payslip_funnel_steps-salary_steps';

  // Attributes
  @Attr({ persist: false }) mayComplete!: boolean;
  @Attr({ persist: false }) completed!: boolean;
  @Attr({ persist: false }) minGrossSalary!: MoneyType;
  @Attr({ persist: false }) maxGrossSalary!: MoneyType;
  @Attr({ persist: false }) minNetSalary!: MoneyType;
  @Attr({ persist: false }) maxNetSalary!: MoneyType;
  @Attr({ persist: false }) maxGrossSalaryWithFinancialReserve!: MoneyType;
  @Attr({ persist: false }) maxNetSalaryWithFinancialReserve!: MoneyType;
  @Attr({ persist: false }) completionErrors!: Array<CompletionErrorType>;

  @Attr({ persist: false })
  availableStrategiesDescription: StrategyDescription[];
  @Attr()
  salaryStrategy!: SalaryStrategy;

  // Relationships
}

export default PayslipFunnelSalaryStep;
