import classNames from 'classnames';
import { ReportProblemIc } from '../../../../app/assets/icons/others';

export type AlertProps = {
  text: string;
  alertType: 'error' | 'warn';
  className?: string;
};

export const Alert = ({ text, alertType, className }: AlertProps) => {
  const isErrorType = alertType === 'error';
  const isWarnType = alertType === 'warn';

  const customClassName = classNames(
    'items-center flex flex-col text-center px-[28px]',
    {
      'bg-color-bg-warning  text-color-warning fill-color-warning text-lg md:text-base py-[17px]':
        isWarnType,
      'md:flex-row md:h-[60px] md:w-[525px]': isWarnType,
      'md:flex-row md:h-[40px] md:w-[525px] bg-color-bg-failure fill-color-alert-failure text-base2 md:text-sm2 py-[11px] py-[20px] md:py-[0px]':
        isErrorType,
    },
    className,
  );

  const customIconClassName = classNames({
    'md:ml-[35px] h-[24px] w-[24px]': isWarnType,
    'h-[24px] w-[24px] md:h-[16px] md:w-[16px]': isErrorType,
  });

  const customTextClassName = classNames('md:ml-[16px] mt-[12px] md:mt-[0px]');

  return (
    <div className={customClassName} role="alert">
      <ReportProblemIc className={customIconClassName} />
      <p className={customTextClassName}>{text}</p>
    </div>
  );
};
