import { CloseIcon } from '@/app/assets/icons/buttons';
import { LoaderIcon } from '@/app/assets/icons/inputs';
import {
  Button,
  ButtonPrimary,
  ButtonSecondary,
} from '@/modules/common/components/buttons';
import { CustomModal } from '@/modules/common/components/fragments/CustomModal';
import { TextInput } from '@/modules/common/components/inputs';

export const CompletedValidationModal = ({
  isOpen,
  isLoading,
  handleSetCloseModal,
  handleOnClick,
  handleFill,
}: {
  isOpen: boolean;
  isLoading: boolean;
  handleSetCloseModal: any;
  handleOnClick: any;
  handleFill: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <CustomModal isOpen={isOpen} onRequestClose={handleSetCloseModal}>
    <div className="flex flex-col w-[950px] bg-color-light-grey py-[25px]">
      {isLoading ? (
        <div className="flex justify-center items-center">
          <LoaderIcon height={20} width={20} className="animate-spin" />
        </div>
      ) : (
        <>
          <Button
            onClick={handleSetCloseModal}
            custom
            className="self-end mr-[25px]"
          >
            <CloseIcon />
          </Button>
          <p className="text-xl4 text-center mb-[25px]">
            Motif de l&apos;abandon :
          </p>
          <TextInput
            textarea
            placeholder="Motif de l'abandon"
            className="items-center"
            inputContainerClassName="w-[100%] justify-center"
            inputCustomClass="w-[80%]"
            onChange={handleFill}
          />
          <div className="flex justify-center mt-[20px]">
            <ButtonSecondary onClick={handleSetCloseModal}>
              Annuler
            </ButtonSecondary>
            <ButtonPrimary className="ml-[20px]" onClick={handleOnClick}>
              Confimer
            </ButtonPrimary>
          </div>
        </>
      )}
    </div>
  </CustomModal>
);
