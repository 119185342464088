// api/Invoice.ts
import { Attr, BelongsTo, Model } from 'spraypaint';
import type { MoneyType, PeriodType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';
import type Subcontractor from './Subcontractor';

export type StatusEnum = 'in_progress' | 'submitted' | 'paid';

@Model()
class SubcontractorBill extends ApplicationRecord {
  static jsonapiType = 'subcontractor_bills';

  // Attributes
  @Attr() invoiceId!: number;
  @Attr() subcontractorId!: number;
  @Attr() date!: string;
  @Attr() dueDate!: string;
  @Attr() paymentDate!: string;
  @Attr() vat!: string;
  @Attr() htAmount!: MoneyType;
  @Attr() ttcAmount!: MoneyType;
  @Attr() remainingAmount!: MoneyType;
  @Attr() number!: string;
  @Attr() status!: StatusEnum;
  @Attr() createdAt!: string;
  @Attr() updatedAt!: string;
  @Attr() payslipDate!: PeriodType;
  @Attr() externalReference!: string;

  // Relationships
  @BelongsTo('subcontractor') subcontractor!: Subcontractor;

  get employeePagePath(): string | undefined {
    const { id } = this;
    const { employeeId } = this.subcontractor;

    if (!employeeId || !id) {
      return undefined;
    }

    return `/employees/${employeeId}/subcontractor_bills/${id}`;
  }

  // Use a method to comply with the expected interface
  // eslint-disable-next-line class-methods-use-this
  get associableType(): string {
    return 'SubcontractorBill';
  }

  get currencyIsoCode(): string | undefined {
    const { ttcAmount } = this;
    return ttcAmount.currency;
  }
}

export default SubcontractorBill;
