import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Line } from '../../../common/components/fragments';
import { RevenueSummary } from '../../../../app/spraypaint';
import IncomeSummary from '../../../../app/spraypaint/payslipFunnel/IncomeSummary';
import { MainMobileInvoicesStepType } from '../types/MainInvoicesStepType';
import { MobileTableRow } from '../../../common/components/table/mobile/MobileTableRow';
import Status, { StatusType } from '../../../common/components/statuses/Status';

export const MainMobileInvoicesStep = ({
  warningAmountsCard,
  invoiceCardBlock,
  currentDisplayCa,
  revenueSummaryTableData,
  incomeSummaryTableData,
}: MainMobileInvoicesStepType) => {
  const { t } = useTranslation();

  const getCustomLabel = (invoice: RevenueSummary | IncomeSummary) => (
    <div className="flex flex-row justify-between">
      <p className="text-color-pone-dark2 w-[40%] text-left">
        {invoice.clientName || '-'}
      </p>
      <p className="text-color-pone-dark font-cabin-bold self-center">
        {invoice.number || '-'}
      </p>
      <Status statusType={invoice.status as StatusType} />
    </div>
  );

  const getAdditionalInfo = (invoice: RevenueSummary | IncomeSummary) => {
    const formattedDate = (date: string) =>
      date ? dayjs(date).format('L') : '-';

    return (
      <div className="flex flex-col justify-between">
        <p className="text-sm1">
          {t(
            'front.payslip.tdc.invoice.table.columns.externalReference.mobileView',
          )}
          {invoice.externalReference || '-'}
        </p>
        <p className="text-sm1">
          {t('front.payslip.tdc.invoice.table.columns.sendDate.mobileView')}
          {formattedDate(invoice.sendDate)}
        </p>
        <p className="text-sm1">
          {t('front.payslip.tdc.invoice.table.columns.dueDate.mobileView')}
          {formattedDate(invoice.dueDate)}
        </p>
        {invoice instanceof IncomeSummary && (
          <p className="text-sm1">
            {t(
              'front.payslip.tdc.invoice.table.columns.dateOfPayment.mobileView',
            )}
            {formattedDate(invoice.lastPaymentDate)}
          </p>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col justify-center mt-5 mx-5">
      {warningAmountsCard}
      <div className="flex flex-col">
        {invoiceCardBlock}
        <Line className="my-3" />

        {currentDisplayCa
          ? revenueSummaryTableData?.data.map(
              (invoice: RevenueSummary, index: number) => (
                <MobileTableRow
                  index={index}
                  amount={invoice.totalAmount}
                  date={invoice.date}
                  label={getCustomLabel(invoice)}
                  additionalInfo={getAdditionalInfo(invoice)}
                  customClass="-mx-5"
                />
              ),
            )
          : incomeSummaryTableData?.data.map(
              (invoice: IncomeSummary, index: number) => (
                <MobileTableRow
                  index={index}
                  amount={invoice.paidAmount.amount}
                  date={invoice.date}
                  label={getCustomLabel(invoice)}
                  additionalInfo={getAdditionalInfo(invoice)}
                  customClass="-mx-5"
                />
              ),
            )}
      </div>
    </div>
  );
};
