import { Model, Attr, HasMany } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import type MultipartOperationsPart from './MultipartOperationPart';

@Model()
class MultipartOperationOperation extends ApplicationRecord {
  static jsonapiType = 'multipart_operation-operations';

  // Attributes
  @Attr({ persist: false }) name!: string;
  @Attr({ persist: false }) status!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;

  @Attr({ persist: false }) reportTxt!: string;

  // Relationships
  @HasMany('multipart_operation-parts') parts!: Array<MultipartOperationsPart>;
}

export default MultipartOperationOperation;
