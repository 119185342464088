import { FunctionComponent } from 'react';
import { MainCard } from '@common/components/mainCard';
import { ButtonPrimary, ButtonSecondary } from '@common/components/buttons';
import { AttributeLine } from '@common/components/AttributeLine';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@/app/utils/dateUtils';
import { useCountry } from '@common/country/useCountries';
import { useExpertise } from '@common/expertise/useExpertises';
import { usePortageOffer } from '@/modules/welcome/hooks/usePortageOffers';
import { ReviewStepType } from '@/modules/welcome/types/OBReviewStep';

export const OBReviewStep: FunctionComponent<ReviewStepType> = ({
  onboardingFunnel,
  performTransition,
}) => {
  const { t } = useTranslation();
  const { reviewStep } = onboardingFunnel;
  const { countryName: birthCountryName } = useCountry(
    String(reviewStep.birthCountryId),
  );
  const { nationality } = useCountry(String(reviewStep.countryId));
  const { expertiseName, expertiseLegalEntityId } = useExpertise(
    String(reviewStep.expertiseId),
  );
  const { portageOfferName } = usePortageOffer(
    String(reviewStep.portageOfferId),
    expertiseLegalEntityId,
  );

  return (
    <MainCard
      header={t('front.welcome.onboarding.steps.reviewStep')}
      className="mt-[20px] mx-5 md:w-[800px] flex flex-col flex-1"
    >
      <AttributeLine
        id="birthdate"
        label={t('activerecord.attributes.user.birth_date')}
        value={formatDate(reviewStep.birthdate)}
      />
      <AttributeLine
        id="civility"
        label={t('activerecord.attributes.user.civility')}
        value={reviewStep.civility}
      />
      <AttributeLine
        id="firstName"
        label={t('activerecord.attributes.user.first_name')}
        value={reviewStep.firstName}
      />
      <AttributeLine
        id="lastName"
        label={t('activerecord.attributes.user.last_name')}
        value={reviewStep.lastName}
      />
      <AttributeLine
        id="birthCountryId"
        label={t('activerecord.attributes.user.birth_country')}
        value={birthCountryName}
      />
      <AttributeLine
        id="countryId"
        label={t('activerecord.attributes.user.nationality')}
        value={nationality}
      />
      <AttributeLine
        id="addressNumber"
        label={t('default.address_number')}
        value={reviewStep.addressNumber}
      />
      <AttributeLine
        id="addressRoad"
        label={t('default.street')}
        value={reviewStep.addressRoad}
      />
      <AttributeLine
        id="addressCity"
        label={t('default.city')}
        value={reviewStep.addressCity}
      />
      <AttributeLine
        id="addressZipcode"
        label={t('default.zipcode')}
        value={reviewStep.addressZipcode}
      />
      <AttributeLine
        id="addressCountry"
        label={t('default.country')}
        value={reviewStep.addressCountry}
      />
      <AttributeLine
        id="addressCountryCode"
        label={t('default.country_code')}
        value={reviewStep.addressCountryCode}
      />
      <AttributeLine
        id="expertiseId"
        label={t('front.welcome.simulations.new.expertise')}
        value={expertiseName}
      />
      <AttributeLine
        id="portageOfferId"
        label={t('activerecord.models.portage_offer')}
        value={portageOfferName}
      />
      <div className="flex justify-end ml-[20px] mt-[20px]">
        <ButtonSecondary
          className="m-2"
          onClick={() =>
            performTransition({ transitionName: 'back_to_portage_offer_step' })
          }
        >
          {t('default.button.back')}
        </ButtonSecondary>
        <ButtonPrimary
          className="m-2"
          onClick={() =>
            performTransition({ transitionName: 'complete_review_step' })
          }
        >
          {t('default.confirm')}
        </ButtonPrimary>
      </div>
    </MainCard>
  );
};
