import { useTranslation } from 'react-i18next';

const useHealthInsurancePackages = () => {
  const { t } = useTranslation();

  const healthInsurancePackages = [
    { label: t('views.health_insurance_package.none'), value: 'none' },
    { label: t('views.health_insurance_package.isolated'), value: 'isolated' },
    { label: t('views.health_insurance_package.duo'), value: 'duo' },
    { label: t('views.health_insurance_package.family'), value: 'family' },
    { label: t('views.health_insurance_package.one'), value: 'one' },
  ];

  return { healthInsurancePackages };
};
export default useHealthInsurancePackages;
