import { FunctionComponent, memo } from 'react';
import { MainCard } from '@common/components/mainCard';
import { useForm } from 'react-hook-form';
import { ButtonPrimary, ButtonSecondary } from '@common/components/buttons';
import PortageOfferStep from '@spraypaint/onBoarding/PortageOfferStep';
import { useTranslation } from 'react-i18next';
import { ControlledDateInput } from '@common/components/inputs/controlledInput/ControlledDateInput';
import {
  PortageOfferStepFormState,
  PortageOfferStepType,
} from '@/modules/welcome/types/OBPortageOfferStepType';
import { useValidation } from '@/app/form/ValidationForm';
import { PortageOfferFields } from './portage_offer_step/PortageOfferFields';

/**
 * OBPortageOfferStep Component
 *
 * This component is responsible for rendering the Portage Offer Step in the onboarding process.
 * It is wrapped with React.memo to optimize performance by preventing unnecessary re-renders.
 *
 * @component
 * @param {PortageOfferStepType} props - The properties that define the component's behavior and display.
 * @returns {JSX.Element} - A form for the Portage Offer Step in the onboarding process.
 */
const OBPortageOfferStep: FunctionComponent<PortageOfferStepType> = memo(
  ({ onboardingFunnel, performTransition, saveThenTransition }) => {
    const { t } = useTranslation();
    const { portageOfferStep } = onboardingFunnel;

    // Define initial form values
    const defaultValues: PortageOfferStepFormState = {
      expertiseId: '' || String(portageOfferStep.expertiseId),
      portageOfferId: String(portageOfferStep.portageOfferId),
      expectedSignatureDate: portageOfferStep.expectedSignatureDate,
    };

    const Validation = useValidation();
    // Set up form handling with react-hook-form
    const form = useForm<PortageOfferStepFormState>({ defaultValues });
    const {
      register,
      formState: { isSubmitting },
      handleSubmit,
      control,
    } = form;

    return (
      <MainCard
        header={t('front.welcome.onboarding.steps.portageOfferStep')}
        className="mt-[20px] mx-5 md:w-[800px]"
      >
        <form
          onSubmit={handleSubmit((data) =>
            saveThenTransition({
              data,
              transitionName: 'complete_portage_offer_step',
              stepToSave: new PortageOfferStep({
                id: portageOfferStep.id,
              }),
            }),
          )}
        >
          <div className="flex-1">
            <div className="flex-col">
              <PortageOfferFields form={form} />
              <ControlledDateInput
                required
                className="m-3"
                control={control}
                {...register('expectedSignatureDate', Validation.anyRequired)}
                label={t(
                  'front.welcome.onboarding.steps.portageOfferStep.expectedSignatureDate',
                )}
              />
            </div>
          </div>
          <div className="flex justify-end ml-[20px] mt-[20px]">
            <ButtonSecondary
              className="m-2"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={() =>
                performTransition({
                  transitionName: 'back_to_citizenship_step',
                })
              }
            >
              {t('default.button.back')}
            </ButtonSecondary>
            <ButtonPrimary
              className="m-2"
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              {t('default.submit')}
            </ButtonPrimary>
          </div>
        </form>
      </MainCard>
    );
  },
);

export { OBPortageOfferStep };
