import { Attr, Model } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class FactorTransferable extends ApplicationRecord {
  static jsonapiType = 'factor-transferables';

  // Attributes
  @Attr() number!: string;
  @Attr() clientName!: string;
  @Attr() date!: Date;
  @Attr() euroHtAmount!: MoneyType;
  @Attr() euroTtcAmount!: MoneyType;
  @Attr() factoringContractId!: number;
}

export default FactorTransferable;
