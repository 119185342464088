import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  EditIcon,
  FilterDownIcon,
  FilterUpIcon,
} from '@/app/assets/icons/buttons';
import {
  ButtonOnClickHandler,
  IconButtonSecondary,
} from '@/modules/common/components/buttons';
import { PayslipFollowUpModal } from '@/modules/admin/payslip/components/PayslipFollowUpModal';
import { CellContext } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import EmployeePayslip from '@spraypaint/EmployeePayslip';

export const FollowUp = ({
  cell,
}: CellContext<ModelRecord<EmployeePayslip>, unknown>) => {
  const { t } = useTranslation();

  const { followUp, employeeId } = cell.row.original;

  const [showAllLines, setShowAllLines] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const lines = followUp?.split('\n');
  const handleOpenModal: ButtonOnClickHandler = () => {
    setIsModalOpen((prev) => !prev);
  };

  const toggleLines: ButtonOnClickHandler = (event) => {
    setShowAllLines((prevShowAllLines) => !prevShowAllLines);
    event.stopPropagation();
  };

  const renderParagraphs = (paragraphs: string[] | undefined) =>
    paragraphs?.map((line, index) => (
      <p className="text-left" id={index.toString()}>
        {line}
      </p>
    ));

  const showHideButton = () => (
    <button className="z-50" type="button" onClick={toggleLines}>
      {showAllLines ? (
        <div className="flex mb-2">
          <p className="pr-2 text-color-pone-orange">
            {t('front.employeePayslip.dashboard.cell.followUp.hideLines')}
          </p>
          <FilterDownIcon
            data-testid="filter-up-icon"
            className="fill-color-pone-orange h-[20px] w-[10px] mb-[2px]"
          />
        </div>
      ) : (
        <div className="flex">
          <p className="pr-2">
            {t('front.employeePayslip.dashboard.cell.followUp.showLines')}
          </p>
          <FilterUpIcon
            data-testid="filter-down-icon"
            className="fill-color-pone-dark h-[20px] w-[10px] mb-[2px] rotate-90"
          />
        </div>
      )}
    </button>
  );

  return (
    <div id={employeeId?.toString()} className="mx-4 my-2">
      <IconButtonSecondary
        Icon={EditIcon}
        onClick={handleOpenModal}
        className="mr-3"
        iconSize={{ height: 15, width: 15 }}
      />
      <PayslipFollowUpModal
        employeeId={String(employeeId)}
        followUp={followUp}
        displayModal={isModalOpen}
        handleSetCloseModal={handleOpenModal}
      />

      {lines && lines.length >= 3 ? showHideButton() : renderParagraphs(lines)}
      {showAllLines ? renderParagraphs(lines) : null}
    </div>
  );
};
