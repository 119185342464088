// api/Client.ts
import { Attr, BelongsTo, Model } from 'spraypaint';
import { LinkPropsBuilder } from '@/types';
import ApplicationRecord from './ApplicationRecord';
import type Employee from './Employee';

@Model()
class Client extends ApplicationRecord {
  static jsonapiType = 'clients';

  constructor(...args: ConstructorParameters<typeof ApplicationRecord>) {
    super(...args);
    this.linkProps = this.linkProps.bind(this);
  }

  // Attributes
  @Attr() ownerType!: string;
  @Attr() ownerId!: number;
  @Attr() methodOfPayment!: string;
  @Attr() clientType!: string;
  @Attr() name!: string;
  @Attr() civility!: string;
  @Attr() legalForm!: string;
  @Attr() siren!: string;
  @Attr() vat!: string;
  @Attr() address!: string;
  @Attr() representedStatus!: string;
  @Attr() contactFirstName!: string;
  @Attr() contactEmail!: string;
  @Attr() contactPhone!: string;
  @Attr() invoiceAddress!: string;
  @Attr() memo!: string;
  @Attr() accountNumber!: string;
  @Attr() status!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr() addressNumber!: string;
  @Attr() addressRoad!: string;
  @Attr() addressCity!: string;
  @Attr() addressCountry!: string;
  @Attr() addressCountryCode!: string;
  @Attr() addressZipcode!: string;
  @Attr() invoiceAddressNumber!: string;
  @Attr() invoiceAddressRoad!: string;
  @Attr() invoiceAddressCity!: string;
  @Attr() invoiceAddressCountry!: string;
  @Attr() invoiceAddressCountryCode!: string;
  @Attr() invoiceAddressZipcode!: string;
  @Attr() internationalClient!: boolean;
  @Attr() registeredCapital!: number;
  @Attr() apeCode!: string;
  @Attr() activity!: string;
  @Attr() registrationDate!: string;
  @Attr() invoiceEmails!: Array<string>;
  @Attr() invoicePhone!: string;
  @Attr() securitisRate!: string;
  @Attr() contactGender!: string;
  @Attr() contactLastName!: string;
  @Attr() contactFunction!: string;
  @Attr() economicArea!: string;
  @Attr() nic!: string;
  @Attr() atradiusGuarantee!: boolean;
  @Attr() atradiusAmount!: string;
  @Attr() tCardNumber!: string;
  @Attr() escrowAccount!: string;
  @Attr() financialGuarantee!: string;
  @Attr() factofranceRibConfirmed!: boolean;
  @Attr() forbidEmployeeInvoicing!: boolean;
  @Attr() trustPilotStatus!: string;
  @Attr() trustPilotSentDate!: string;
  @Attr() active!: boolean;

  @BelongsTo({ name: 'owner' }) owner!: Employee;

  linkProps: LinkPropsBuilder = ({ pathBuilder }) => ({
    children: this.name,
    to: pathBuilder(`/clients/${this.id}`),
  });
}

export default Client;
