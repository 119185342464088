import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import dayjs from 'dayjs';

export const TextAuditLine = ({
  className,
  date,
  user,
}: {
  className?: string;
  date: string;
  user: string;
}) => {
  const { t } = useTranslation();
  const customClassName = classNames(
    'font-cabin-italic text-color-medium-grey text-base2',
    className,
  );
  return (
    <p className={customClassName}>
      {t('front.auditLine.text', {
        date: dayjs(date).format('L'),
        hour: dayjs(date).format('HH:mm'),
        user,
        interpolation: { escapeValue: false },
      })}
    </p>
  );
};
