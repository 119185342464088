import { ButtonPrimary } from '@/modules/common/components/buttons';
import { useTranslation } from 'react-i18next';
import { Toaster } from '@/modules/common/components/toasts';
import { toast } from 'react-toastify';
import AccountingJournalExport, {
  EXPORT_TYPES,
} from '@spraypaint/accounting/AccountingJournalExport';
import useLegalEntitiesSelectOptions from '@/modules/common/legalEntity/useLegalEntitiesSelectOptions';
import { useForm } from 'react-hook-form';
import { ControlledSelect } from '@/modules/common/components/inputs/controlledInput/ControlledSelect';
import { ControlledDateInput } from '@/modules/common/components/inputs/controlledInput/ControlledDateInput';
import { useMutation } from '@tanstack/react-query';

type ExportFormState = {
  legalEntityId?: AccountingJournalExport['legalEntityId'] | undefined;
  date?: Date | undefined;
  exportType?: AccountingJournalExport['exportType'] | undefined;
};

const AccountingJournalExportNew = ({
  refetchIndex,
}: {
  refetchIndex: () => void;
}) => {
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm<ExportFormState>();
  const legalEntityOptions = useLegalEntitiesSelectOptions();

  const exportTypeOptions = EXPORT_TYPES.filter(
    (type) => type !== 'bank_statements',
  ).map((type) => ({
    value: type,
    label: t(
      `activerecord.enums.accounting/journal_export.export_type.${type}`,
      { ns: 'rails', defaultValue: type },
    ),
  }));

  const { mutate } = useMutation(
    async (payload: ExportFormState) => {
      const { date, ...others } = payload;
      const journalExport = new AccountingJournalExport({
        ...others,
        date: date?.toISOString()?.substring(0, 10),
      });
      return journalExport.saveOrThrow();
    },
    {
      onSuccess: () => {
        toast.success(<Toaster textMsg="Export créé" toastType="success" />);
        refetchIndex();
      },
      onError: () => {
        toast.error(<Toaster textMsg="Échec" toastType="error" />);
      },
    },
  );

  return (
    <form onSubmit={handleSubmit((data) => mutate(data))}>
      <ControlledSelect
        required
        control={control}
        name="legalEntityId"
        options={legalEntityOptions}
        placeholder={t('views.legal_entity.label')}
        defaultValue={undefined}
      />
      <ControlledDateInput
        placeholder={t('front.activityAccount.table.date')}
        control={control}
        defaultValue={undefined}
        name="date"
        required
      />
      <ControlledSelect
        required
        control={control}
        name="exportType"
        options={exportTypeOptions}
        placeholder={t(
          'activerecord.attributes.accounting/journal_export.export_type',
          { ns: 'rails', defaultValue: 'export_type' },
        )}
        defaultValue={undefined}
      />
      <ButtonPrimary
        className="mt-[10px]"
        type="submit"
        // onClick={createAccountingJournalExport}
      >
        {t('default.status_info.new')}
      </ButtonPrimary>
    </form>
  );
};

export default AccountingJournalExportNew;
