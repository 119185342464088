import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MouseEvent } from 'react';
import { onRowClickNavigate } from '@/app/navigation/functions';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import AccountingCode from '@/app/spraypaint/AccountingCode';
import Table from '../../../common/components/table/Table';
import { useBackendTable } from '../../../common/components/table/hooks';
import useAccountingCodes from '../hooks/useAccountingCodes';
import accountingCodesColumns from '../functions/accountingCodesColumns';

const AccountingCodesIndex = () => {
  const navigate = useNavigate();
  const [scope, accountingScopeKey, props] = useBackendTable<AccountingCode>();
  const { accountingCodes, isLoading, totalCount } = useAccountingCodes(
    scope,
    accountingScopeKey,
  );

  const { t } = useTranslation();

  const onRowClick =
    (raw: ModelRecord<AccountingCode> | undefined) => (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw?.accountingCodeSourcePagePath,
        href: raw?.accountingCodeSourcePagePath,
        event,
        windowNavigationHref: raw?.accountingCodeSourcePagePath,
        navigate,
      });
    };

  const accountingCodesColumn = accountingCodesColumns();

  return (
    <div className="mt-[50px]">
      <div className="flex justify-between px-[20px]">
        <p className="big-title">{t('front.menu.accounting_codes')}</p>
      </div>
      <Table
        emptyTableErrorMsg="react_table.no_data_text"
        className="mt-[26px]"
        isLoading={isLoading}
        onRowClick={onRowClick}
        withImageErrorMsg={false}
        reactTableProps={props}
        totalCount={totalCount}
        data={accountingCodes || []}
        columns={accountingCodesColumn}
      />
    </div>
  );
};

export default AccountingCodesIndex;
