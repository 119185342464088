import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosResponse, AxiosError } from 'axios';
import useAuthClient, { AuthErrorResponse } from './useAuthClient';

export type ResetPasswordRequestVariables = {
  // The user's email address
  login: string;
};

export type ResetPasswordRequestData = {
  success: string;
};

export const useResetPasswordRequest = (
  opts: UseMutationOptions<
    AxiosResponse<ResetPasswordRequestData>,
    AxiosError<AuthErrorResponse>,
    ResetPasswordRequestVariables
  > = {},
) => {
  const authClient = useAuthClient();
  return useMutation({
    mutationFn: (data) =>
      authClient.post<ResetPasswordRequestData>(
        '/reset-password-request',
        data,
      ),
    ...opts,
  });
};
