/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Item } from '@common/components/inputs/types/AddressFieldsType';
import { InputActionMeta } from 'react-select/dist/declarations/src/types';
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from 'react-hook-form';
import { TextInput } from './TextInput';
import { CreateSelect } from './CreateSelect';
import { Label } from './components';

export function defaultFieldsNameFor<TName extends string>(
  name: TName,
): {
  address: TName;
  addressNumber: `${TName}Number`;
  addressRoad: `${TName}Road`;
  addressCity: `${TName}City`;
  addressZipcode: `${TName}Zipcode`;
  addressCountry: `${TName}Country`;
  addressCountryCode: `${TName}CountryCode`;
} {
  return {
    address: name,
    addressNumber: `${name}Number`,
    addressRoad: `${name}Road`,
    addressCity: `${name}City`,
    addressZipcode: `${name}Zipcode`,
    addressCountry: `${name}Country`,
    addressCountryCode: `${name}CountryCode`,
  };
}

type AddressFieldsProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TNumberName extends FieldPath<TFieldValues>,
  TRoadName extends FieldPath<TFieldValues>,
  TCityName extends FieldPath<TFieldValues>,
  TZipcodeName extends FieldPath<TFieldValues>,
  TCountryName extends FieldPath<TFieldValues>,
  TCountryCodeName extends FieldPath<TFieldValues>,
> = {
  fieldsName: {
    address: TName;
    addressNumber: TNumberName;
    addressRoad: TRoadName;
    addressCity: TCityName;
    addressZipcode: TZipcodeName;
    addressCountry: TCountryName;
    addressCountryCode: TCountryCodeName;
  };
  label?: string;
  required?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  control: Control<TFieldValues>;
};

const responseToOptions = (items: Item[]) =>
  items.map((item: Item) => ({
    value: item.id || '',
    label: item.address?.label || '',
  }));

export function AddressFields<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TNumberName extends FieldPath<TFieldValues>,
  TRoadName extends FieldPath<TFieldValues>,
  TCityName extends FieldPath<TFieldValues>,
  TZipcodeName extends FieldPath<TFieldValues>,
  TCountryName extends FieldPath<TFieldValues>,
  TCountryCodeName extends FieldPath<TFieldValues>,
>({
  fieldsName,
  control,
  label,
  required,
  labelClassName,
  inputClassName,
}: AddressFieldsProps<
  TFieldValues,
  TName,
  TNumberName,
  TRoadName,
  TCityName,
  TZipcodeName,
  TCountryName,
  TCountryCodeName
>) {
  const { t } = useTranslation();
  const [results, setResults] = useState<Item[]>([]);
  const [inputValue, setInputValue] = useState('');

  const { field: addressField } = useController({
    control,
    name: fieldsName.address,
  });
  const { field: numberField } = useController({
    control,
    name: fieldsName.addressNumber,
  });
  const { field: roadField } = useController({
    control,
    name: fieldsName.addressRoad,
  });
  const { field: cityField } = useController({
    control,
    name: fieldsName.addressCity,
  });
  const { field: zipcodeField } = useController({
    control,
    name: fieldsName.addressZipcode,
  });
  const { field: countryField } = useController({
    control,
    name: fieldsName.addressCountry,
  });
  const { field: countryCodeField } = useController({
    control,
    name: fieldsName.addressCountryCode,
  });

  const debouncedSearch = useCallback(
    _.debounce(async (query) => {
      if (query.length > 0) {
        const apiKey = process.env.HERE_AUTODISCOVER_CODE;
        try {
          const response = await axios.get(
            `https://geocode.search.hereapi.com/v1/geocode?q=${query}&types=address&limit=10&apiKey=${apiKey}`,
            { withCredentials: false },
          );
          setResults(response.data.items);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    }, 500), // délai de 500ms
    [],
  );

  const handleInput = (value: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(value);
      addressField.onChange(value);
      debouncedSearch(value);
    }
  };

  const handleChange = (e: { value: string; label: string }) => {
    const item = results.find((i: Item) => i.id === e.value);

    if (item && item.address) {
      addressField.onChange(item.address.label || '');
      numberField.onChange(item.address.houseNumber || '');
      roadField.onChange(item.address.street || '');
      cityField.onChange(item.address.city || '');
      zipcodeField.onChange(item.address.postalCode || '');
      countryField.onChange(item.address.countryName || '');
      countryCodeField.onChange(
        item.address.countryCode?.substring(0, 2) || '',
      );
    }
  };

  const randomId = useMemo(
    () => `input-text-${Math.random().toString(36).substr(2, 9)}`,
    [handleChange],
  );

  return (
    <div>
      <div className="flex flex-col m-3 mb-5">
        {label && (
          <Label
            htmlFor={randomId}
            required={required}
            className={classNames('mb-0', labelClassName)}
          >
            {label}
          </Label>
        )}
        <CreateSelect
          id={randomId}
          onInputChange={handleInput}
          inputValue={inputValue}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={handleChange}
          isClearable
          isSearchable
          options={responseToOptions(results)}
          noOptionsMessage={() => ''}
          formatCreateLabel={(inpValue: string) => inpValue}
          placeholder=""
          required={required}
          escapeClearsValue={false}
          className={classNames(inputClassName)}
        />
      </div>
      <TextInput
        className={classNames('m-3 mb-5', inputClassName)}
        required={required}
        {...numberField}
        label={t('default.address_number')}
      />
      <TextInput
        className={classNames('m-3 mb-5', inputClassName)}
        required={required}
        {...roadField}
        label={t('default.street')}
      />
      <TextInput
        className={classNames('m-3 mb-5', inputClassName)}
        required={required}
        {...cityField}
        label={t('default.city')}
      />
      <TextInput
        className={classNames('m-3 mb-5', inputClassName)}
        required={required}
        {...zipcodeField}
        label={t('default.zipcode')}
      />
      <TextInput
        className={classNames('m-3 mb-5', inputClassName)}
        required={required}
        {...countryField}
        label={t('default.country')}
      />
      <TextInput
        className={classNames('m-3 mb-5', inputClassName)}
        required={required}
        {...countryCodeField}
        label={t('default.country_code')}
      />
    </div>
  );
}
