import { ReactNode } from 'react';
import classNames from 'classnames';
import { Button } from '../../../../common/components/buttons';
import { TutorialOnIcon } from '../../../../../app/assets/icons/tutorial';
import useBreakpoints from '../../../../../app/responsive/useBreakPoints';

export const CommonMiniTutorial = ({
  Component,
  onClick,
  customClassName,
}: {
  Component: ReactNode;
  onClick: any;
  customClassName?: string;
}) => {
  const { isXs } = useBreakpoints();
  const customClass = classNames(
    'bg-color-transparent-medium-grey fill-color-medium-grey flex py-[20px] pr-[40px] pl-5 rounded-lg mt-3 mx-3 md:mt-0 md:mx-0',
    { 'items-start ': isXs },
    customClassName,
  );

  return (
    <div className={customClass}>
      <Button custom onClick={onClick}>
        <TutorialOnIcon height={20} width={29} />
      </Button>
      <div className="ml-[17px] text-sm2 md:w-[687px]">{Component}</div>
    </div>
  );
};
