import classNames from 'classnames';
import { Button, ButtonProps } from './Button';
import { DeleteIcon } from '../../../../app/assets/icons/buttons';
import { LoaderIcon } from '../../../../app/assets/icons/inputs';
import { IconType } from '../../utils/type.utils';

export type IconButtonDeleteProps = {
  Icon?: IconType;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
} & ButtonProps;

export function IconButtonDelete({
  Icon = DeleteIcon,
  className,
  disabled,
  isLoading,
  ...props
}: IconButtonDeleteProps) {
  const customClassName = classNames(
    {
      'fill-color-failure hover:fill-color-txt-failure': !disabled,
      'fill-color-medium-grey': disabled,
    },
    className,
  );

  return (
    <Button className={customClassName} disabled={disabled} custom {...props}>
      {isLoading ? (
        <LoaderIcon className="animate-spin" width={20} height={20} />
      ) : (
        <Icon data-testid="delete-icon" className="h-6 w-6" />
      )}
    </Button>
  );
}
