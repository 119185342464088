import classNames from 'classnames';
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';

export type ButtonRadioProps = {
  disabled?: boolean;
  inputClassName?: string;
  containerClassName?: string;
  labelClassName?: string;
  label?: string | ReactNode;
  required?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const ButtonRadio = forwardRef<HTMLInputElement, ButtonRadioProps>(
  (
    {
      disabled,
      inputClassName,
      containerClassName,
      labelClassName,
      label,
      ...props
    }: ButtonRadioProps,
    ref,
  ) => {
    const containerClass = classNames('flex items-center', containerClassName);
    const customClassName = classNames(
      'accent-color-pone-orange hover:outline-color-pone-orange',
      inputClassName,
    );
    const labelClass = classNames('text-sm2 ml-5', labelClassName);

    return (
      <div className={containerClass}>
        <input
          ref={ref}
          disabled={disabled}
          type="radio"
          className={customClassName}
          {...props}
        />
        {label && (
          <label htmlFor={props.id} className={labelClass}>
            {label}
          </label>
        )}
      </div>
    );
  },
);

ButtonRadio.defaultProps = {
  disabled: false,
};
