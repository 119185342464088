// api/CommercialAgent.ts
import { Model, Attr, HasOne } from 'spraypaint';
import Office from './Office';
import ApplicationRecord from './ApplicationRecord';

@Model()
class CommercialAgent extends ApplicationRecord {
  static jsonapiType = 'commercial_agents';

  // Attributes
  @Attr({ persist: false }) email!: string;
  @Attr({ persist: false }) civility!: string;
  @Attr({ persist: false }) firstName!: string;
  @Attr({ persist: false }) lastName!: string;
  @Attr({ persist: false }) landlinePhone!: string;

  // Relationships
  @HasOne('offices') office!: Office;
}

export default CommercialAgent;
