import { SubcontractorBill } from '@/app/spraypaint';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import Status from '@/modules/common/components/statuses/Status';

export const SubcontractorBillCard = ({
  candidate,
}: {
  candidate: SubcontractorBill;
}) => (
  <>
    <div className="flex flex-col">
      <div>
        <p>
          <span className="font-cabin-bold">Numéro : </span>
          {candidate.number}
        </p>
      </div>
      <div>
        <p>
          <span className="font-cabin-bold">Sous-traitant : </span>
          {candidate.subcontractor.name}
        </p>
      </div>
    </div>
    <div className="flex flex-col">
      <p className="flex justify-center">
        <span className="font-cabin-bold">Statut : </span>
        <Status statusType={candidate.status} className="ml-2" />
      </p>
      <p>
        <span className="font-cabin-bold">Montant TTC : </span>
        <AmountWithCurrency amount={candidate.ttcAmount} />
      </p>
    </div>
  </>
);
