import { FC } from 'react';
import AccountingJournalExport from '@/app/spraypaint/accounting/AccountingJournalExport';
import type { PersistedSpraypaintRecord } from 'spraypaint';
import type { SepaFile } from '@/app/spraypaint';

type ImportType = AccountingJournalExport | SepaFile;

export const ImportedInput: FC<{
  record: PersistedSpraypaintRecord<ImportType> | undefined;
  onToggle: (newState: boolean) => void;
}> = ({ record, onToggle }) => {
  if (!record) {
    return null;
  }

  return (
    <div className="group">
      {record?.imported ? '✅' : '❌'}

      <button
        className="hidden group-hover:inline m-2 text-color-blue"
        type="button"
        onClick={() => {
          onToggle(!record?.imported);
        }}
      >
        Changer ...
      </button>
    </div>
  );
};
