import ReactSelect, {
  MultiValue,
  SingleValue,
  Props as ReactSelectProps,
  DropdownIndicatorProps,
  ValueContainerProps,
} from 'react-select';
import classNames from 'classnames';
import { SelectArrows } from '../../../common/components/inputs/components/SelectArrows';
import { otherType } from '../types/otherTypes';
import { CraCategorieType } from '../../../../app/spraypaint/payslipFunnel/PayslipFunnelCraStep';

export type SelectValueType = { value: CraCategorieType; label: string };

export type FSelect<T> = MultiValue<T> | SingleValue<T>;

export type SelectProps = {
  label?: string;
  className?: string;
  selectedType: any;
  isDisabled?: boolean;
} & ReactSelectProps<SelectValueType, false>;

const DropdownIndicator = ({ selectProps }: DropdownIndicatorProps<any>) => (
  <SelectArrows
    isOpen={selectProps.menuIsOpen}
    isLoading={selectProps.isLoading}
  />
);

const ValueContainer = ({ children }: ValueContainerProps<any>) => {
  const fillerClassName = classNames(
    'w-[52px] h-[52px] rounded-l-[14px] bg-color-cra-grey',
  );

  const placeholderClassName = classNames(
    'overflow-hidden whitespace-nowrap text-ellipsis text-sm text-color-pone-dark font-bold uppercase pl-[16px] flex justify-between w-full',
  );

  const customDivClassName = classNames(
    'w-[170px] flex items-center  justify-between',
  );

  return (
    <div className={customDivClassName}>
      <div className={fillerClassName} />
      <div className={placeholderClassName}>{children}</div>
    </div>
  );
};

export const SelectCra = ({
  isDisabled,
  className,
  options,
  value,
  placeholder,
  selectedType,
  isLoading,
  label,
  isSearchable = false,
  ...props
}: SelectProps) => {
  const customClassName = classNames(
    'flex items-center h-[56px] rounded-2xl border-2 bg-color-transparent-medium-grey',
    {
      'border-color-pone-orange':
        otherType().some((type) => type.value === selectedType) && value,
      'border-color-medium-light': !otherType().some(
        (type) => type.value === selectedType,
      ),
    },
    className,
  );

  const menuContainerClassName =
    'flex flex-col overflow-y-scroll bg-white shadow-table z-[100]';

  const controlClassName = 'flex justify-between items-center w-full';
  const optionClassName =
    'py-[11px] hover:bg-color-light-grey text-left pl-[11px]';

  return (
    <div className={customClassName}>
      {label && (
        <p className="text-color-pone-dark text-base2 mr-[22px]">{label}</p>
      )}
      <ReactSelect
        classNames={{
          menuList: () => menuContainerClassName,
          control: () => controlClassName,
          option: () => optionClassName,
        }}
        components={{
          DropdownIndicator,
          ValueContainer,
        }}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        unstyled
        placeholder={placeholder}
        value={value}
        options={options}
        {...props}
      />
    </div>
  );
};
