import { Attr, BelongsTo, Model } from 'spraypaint';
import Employee from '@spraypaint/Employee';
import ApplicationRecord from './ApplicationRecord';

@Model()
class AccountingCode extends ApplicationRecord {
  static jsonapiType = 'accounting_codes';

  // Attributes
  @Attr() employeeFullName!: string;
  @Attr() createdAt!: string;
  @Attr() employeeId!: number;
  @Attr() accountingType!: string;
  @Attr() accountingId!: number;
  @Attr() code!: string;

  @BelongsTo('employees') employee!: Employee;

  get accountingCodeSourcePagePath(): string | undefined {
    const { employeeId, accountingType, accountingId } = this;
    if (!employeeId || !accountingType || !accountingId) return undefined;

    const employeeBasePath = this.employee.employeeV1BasePath;

    if (accountingType === 'Employee') {
      return employeeBasePath;
    }

    const accountingTypeSlug = `${accountingType?.toLowerCase()}s`;

    return `${employeeBasePath}/${accountingTypeSlug}/${accountingId}`;
  }
}

export default AccountingCode;
