export type MenuSeparatorProps = {
  children: string;
  displayText: boolean;
};

export function MenuSeparator({ displayText, children }: MenuSeparatorProps) {
  return (
    <div className="flex mb-[18px] mt-[32px] h-[25px] items-center">
      <div className="h-[3px] w-[25px] self-center bg-color-pone-orange" />
      <p className="text-color-pone-orange ml-[14px] italic text-base">
        {displayText ? children : ''}
      </p>
    </div>
  );
}
