// api/PayslipFunnelSteps::InvoicesStep.ts
import { Model, Attr, HasMany } from 'spraypaint';
import CompletionErrorType from '@/modules/payslip_funnel/types/CompletionErrorType';
import type { MoneyType } from '../../../modules/common/utils/type.utils';
import PayslipFunnelBaseStep from './PayslipFunnelBaseStep';
import type Invoice from '../Invoice';

@Model()
class PayslipFunnelInvoicesStep extends PayslipFunnelBaseStep {
  static jsonapiType = 'payslip_funnel_steps-invoices_steps';

  // Attributes
  @Attr({ persist: false }) computeActivityAccountBalance!: MoneyType;
  @Attr({ persist: false }) computeOverdueSecuritisAmount!: MoneyType;
  @Attr({ persist: false }) computeOverdueSecuritisCount!: number;
  @Attr({ persist: false }) balanceConsideredDate!: Date;
  @Attr({ persist: false }) completionErrors!: Array<CompletionErrorType>;

  // Relationships
  @HasMany('invoices') invoicesToBeDefunded!: Invoice[];
}

export default PayslipFunnelInvoicesStep;
