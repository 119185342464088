import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/modules/common/components/buttons';
import { AmountText } from '@/modules/common/components/fragments/AmountText';
import { ButtonRadio } from '@common/components/inputs';
import { MoneyType } from '@common/utils/type.utils';
import { TooltipComponent } from '@common/components/fragments/TooltipComponent';
import { HelpOutlineIcon } from '../../../../app/assets/icons/inputs';

export interface CommonPayslipChoiceCardProps {
  value: MoneyType;
  strategyName: string;
  isActive: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  tooltip: boolean;
}

export const PayslipChoiceCard = ({
  strategyName,
  value,
  onClick,
  isActive,
  tooltip = false,
}: CommonPayslipChoiceCardProps) => {
  const { t } = useTranslation();

  const customClassName = classNames(
    'flex items-center justify-between ' +
      'w-[350px] h-[80px] px-[25px] py-[17px] rounded-[12px] ' +
      'fill-color-medium-grey hover:fill-color-pone-orange',
    {
      'bg-color-pastel-orange border-color-pone-orange border-[2px]': isActive,
      'bg-color-transparent-medium-grey border-color-medium-light border-[1px] hover:shadow-md hover:shadow-opacity-50 hover:shadow-payslipFunnelCard ease-in-out':
        !isActive,
    },
  );

  const customTextStyle = classNames(
    'w-[212px] h-[28px] text-left text-color-dark-content text-base2 font-cabin-regular ',
  );

  return (
    <Button
      onClick={isActive ? () => {} : onClick}
      className={customClassName}
      custom
      data-testid="button-"
    >
      <ButtonRadio
        inputClassName="h-4 w-4 mr-[18px]"
        checked={isActive}
        readOnly
      />
      <div>
        <p className={customTextStyle}>
          {t(`front.payslip.tdc.salaryChoice.${strategyName}`)}
        </p>
        <div className={`flex ${customTextStyle}`}>
          (
          <AmountText
            className="text-base2"
            currencyPositionClass="top-[-5px]"
            amount={value?.amount}
            currencyIsoCode={value?.currency}
          />
          )
        </div>
      </div>

      {tooltip && (
        <TooltipComponent
          Icon={HelpOutlineIcon}
          tooltipMsgComp={
            <Trans
              i18nKey={`front.payslip.tdc.salaryChoice.${strategyName}.tooltipMsg`}
            />
          }
          tooltipId={strategyName}
        />
      )}
    </Button>
  );
};
