import { LegalEntitySlugType } from '../../../app/spraypaint/LegalEntity';
import {
  CityLogo,
  CityImmoLogo,
  ExpertsLogo,
  FormationLogo,
  MTLogo,
  PartnersLogo,
  PortageLogo,
  PriumLogo,
} from '../../../app/assets/logos';

export function EntityLogo({
  slug,
  className,
}: {
  slug?: LegalEntitySlugType;
  className?: string;
}) {
  switch (slug) {
    case 'prium_portage':
      return <PortageLogo data-testid="prium-portage" className={className} />;
    case 'prium_partners':
      return <PartnersLogo data-testid="prium-portage" className={className} />;
    case 'prium_formation':
      return (
        <FormationLogo data-testid="prium-formation" className={className} />
      );
    case 'prium_city':
      return <CityLogo data-testid="prium-city" className={className} />;
    case 'prium_city_immo':
      return (
        <CityImmoLogo data-testid="prium-city_immo" className={className} />
      );
    case 'prium_experts':
      return <ExpertsLogo data-testid="prium-experts" className={className} />;
    case 'prium_mt':
      return <MTLogo data-testid="prium-mt" className={className} />;
    default:
      return <PriumLogo data-testid="prium-logo" className={className} />;
  }
}
