import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import AccountingCode from '@/app/spraypaint/AccountingCode';

const useAccountingCodes = (
  scope: (baseScope: Scope<AccountingCode>) => Scope<AccountingCode>,
  scopeKey: string[],
) => {
  const data = useQuery({
    queryKey: ['accounting-codes', scopeKey],
    queryFn: () =>
      scope(
        AccountingCode.includes([{ employee: ['legal_entity'] }]).scope(),
      ).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const accountingCodes = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, accountingCodes, totalCount };
};

export default useAccountingCodes;
