import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { IcnBtnSalaire } from '../../../app/assets/icons/payslip';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../../common/components/buttons';
import { Line } from '../../common/components/fragments';
import { Stepper } from '../../navigation/components';
import PayslipPeriodStringified from '../../payslip_funnel/components/PayslipPeriodStringified';
import { useCurrentPayslip } from '../hooks/useCurrentPayslip';

export type Props = {
  currentEmployeeId: number;
};

export const FinishedFunnelCard = ({ currentEmployeeId }: Props) => {
  const { t } = useTranslation();
  const { data: currentPayslipData } = useCurrentPayslip(
    Number(currentEmployeeId),
  );
  const currentPayslip = currentPayslipData?.data;

  if (!currentPayslip) return null;

  const stepValidationByPrium = ['in_progress', 'submitted'].includes(
    currentPayslip.status,
  );

  const stepPaymentByPrium = currentPayslip.paymentDate;

  const step1 = t('front.payslip.index.finishTds.step1');

  const step2 = currentPayslip.paymentDate
    ? t('front.payslip.index.finishTds.step2done', {
        date: dayjs(currentPayslip.paymentDate).format('L'),
      })
    : t('front.payslip.index.finishTds.step2') +
      (!stepPaymentByPrium && !stepValidationByPrium
        ? t('front.payslip.index.finishTds.step2date', {
            date: dayjs(currentPayslip.expectedPaymentDate).format('L'),
          })
        : '');

  const step3 =
    t('front.payslip.index.finishTds.step3') +
    (stepPaymentByPrium
      ? t('front.payslip.index.finishTds.step3date', {
          date: dayjs(currentPayslip.documentAvailabilityDate).format('L'),
        })
      : '');

  const steps = [step1, step2, step3];

  let currentStep;
  if (stepValidationByPrium) {
    currentStep = 0;
  } else if (stepPaymentByPrium) {
    currentStep = 2;
  } else {
    currentStep = 1;
  }

  return (
    <div className="shadow-card ml-[30px] max-w-[1070px] flex mt-[22px]">
      <div className="pt-[19px] flex-1 pr-[20px]">
        <PayslipPeriodStringified period={currentPayslip.period} />
        <Line rounded />
        <div className="mt-[30px] pl-[20px] pb-[30px]">
          <p className="font-cabin-italic text-color-medium-grey">
            {t('front.payslip.index.finishTds.title')}
            <span className="text-color-pone-dark">
              {dayjs(currentPayslip.createdAt).format('LLL')}
            </span>
          </p>
          <div className="flex mt-[20px]">
            <ButtonPrimary
              className="w-[190px]"
              to={`${currentPayslip?.id}`}
              Icon={IcnBtnSalaire}
            >
              {t('front.payslip.index.finishTds.button.currentPayslip')}
            </ButtonPrimary>
            <ButtonSecondary
              to={`${currentPayslip?.id}/details`}
              className="ml-[20px] w-[190px]"
            >
              {t('front.payslip.index.finishTds.button.details')}
            </ButtonSecondary>
          </div>
        </div>
      </div>
      <div className="flex-1 bg-color-light-grey pt-[22px] pl-[100px] pr-[40px]">
        <p>{t('front.payslip.index.finishTds.advancement')}</p>
        <Stepper
          readonly
          vertical
          steps={steps}
          currentStep={currentStep}
          className="mt-[10px]"
        />
      </div>
    </div>
  );
};
