import type { FunctionComponent } from 'react';
import { Context, PropsWithChildren, useContext } from 'react';
import { FieldValues } from 'react-hook-form';
import { MainCard } from '@/modules/common/components/mainCard';
import { LineText } from '@/modules/common/components/fragments/LineText';
import { FormWizardContextType } from '../functions/useFormWizardContext';

export interface FormWizardDebuggerProps<
  TFormData extends FieldValues,
  TKeys extends string,
> {
  wizardContext: Context<FormWizardContextType<TFormData, TKeys>>;
}

const Container: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <MainCard className="mt-10 opacity-60" header="Form Wizard Debugger">
    {children}
  </MainCard>
);

export function FormWizardDebugger<
  TFormData extends FieldValues,
  TKeys extends string,
>({ wizardContext }: FormWizardDebuggerProps<TFormData, TKeys>) {
  const { interpret } = useContext(wizardContext);

  if (!interpret) return <Container> NO INTERPRET </Container>;

  const interpretState = interpret.getSnapshot();

  const currentContext = interpretState.context;
  const currentState = interpretState.value;

  return (
    <Container>
      <LineText label="Current state" value={String(currentState)} />
      <LineText
        label="Current context"
        value={<pre>{JSON.stringify(currentContext, null, 2)}</pre>}
      />
      <LineText
        label="Availabe Events"
        value={String(interpretState.nextEvents)}
      />
    </Container>
  );
}
