import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { PayslipAgenda } from '../PayslipAgenda';
import { IcnFichePaie } from '../../../../app/assets/icons/payslip';
import { ReportProblemIc } from '../../../../app/assets/icons/others';
import {
  DownloadButton,
  IconButtonSecondary,
} from '../../../common/components/buttons';
import {
  FileType,
  MoneyType,
  PeriodType,
} from '../../../common/utils/type.utils';
import { ReactComponent as LaunchIcon } from '../../../../app/assets/icons/SVG/ic_launch_24px.svg';
import { DisplayWarning, PrintableAlertLine } from '../DisplayWarning';

export type LeftToPayCardProps = {
  period?: PeriodType;
  scheduledTransferDate?: string;
  paymentDate?: string;
  errorText?: FieldError | undefined;
  payslipAlertsList?: Array<PrintableAlertLine>;
  leftToPay: MoneyType;
  employeeId?: number;
  isStrategyCardDisplay?: boolean;
  isPayslipFunnel?: boolean;
  employmentContractId?: number;
  file?: FileType;
};

export const LeftToPayCard = ({
  period,
  scheduledTransferDate = '',
  paymentDate = '',
  leftToPay = { amount: 0, currency: 'EUR' },
  errorText,
  employeeId,
  isStrategyCardDisplay = false,
  isPayslipFunnel,
  payslipAlertsList = [],
  employmentContractId,
  file,
}: LeftToPayCardProps) => {
  const { t } = useTranslation();

  const rowFirstCol = classNames(
    'col-start-1 flex items-center',
    isStrategyCardDisplay ? 'col-span-3' : 'col-span-4',
  );

  const rowSecondCol = classNames(
    'ml-2 md:ml-0',
    isStrategyCardDisplay
      ? 'col-start-4 ml-3 col-span-7'
      : 'col-start-5 flex items-center col-span-8',
  );

  const handleOnClick = (id: number) => {
    window.open(
      `/employees/${employeeId}/employment_contracts/${id}`,
      '_blank',
    );
  };

  const dateLineDisplay = () => {
    let displayText;
    let displayDate;

    if (paymentDate) {
      displayText = t('front.payslip.show.leftToPayCard.paymentTransfer');
      displayDate = dayjs(paymentDate).format('L');
    } else if (scheduledTransferDate) {
      displayText = t('front.payslip.show.leftToPayCard.scheduledTransfer');
      displayDate = dayjs(scheduledTransferDate).format('L');
    } else {
      displayText = t('front.payslip.show.leftToPayCard.scheduledTransfer');
      displayDate = '-';
    }

    return (
      <div className="grid grid-cols-12 gap-2 mb-5">
        <div className={rowFirstCol}>
          <div className="text-sm2 text-color-dark-content text-center">
            {displayText}
          </div>
        </div>
        <div className={rowSecondCol}>
          <div className="text-sm2 text-color-dark-content text-left">
            {displayDate}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="shadow-card rounded">
      <div className="pl-[21px] pt-[50px] pr-[50px] pb-[20px]">
        <div className="grid grid-cols-12 gap-2 mb-5">
          <div className={rowFirstCol}>
            <PayslipAgenda period={period} />
          </div>
          <div className={rowSecondCol}>
            <div className="h-[87px]">
              <div className="text-xl3 text-color-medium-grey">
                {t('front.payslip.show.leftToPayCard.remainsToPay')}
              </div>
              <div className="text-xxl text-color-pone-dark">
                <AmountWithCurrency
                  amount={leftToPay.amount}
                  currencyIsoCode={leftToPay.currency}
                  spanClass="text-xl4"
                  currencyClass="top-[-10px] text-base2 ml-1"
                />
              </div>
            </div>
          </div>
        </div>

        {dateLineDisplay()}

        {employmentContractId && (
          <div className="grid grid-cols-12 gap-2 mb-5">
            <div className={rowFirstCol}>
              <div className="text-sm2 text-color-dark-content text-center">
                {t('front.payslip.show.leftToPayCard.employmentContract')}
              </div>
            </div>
            <div className={rowSecondCol}>
              <IconButtonSecondary
                Icon={LaunchIcon}
                onClick={() => handleOnClick(employmentContractId)}
              />
            </div>
          </div>
        )}

        <div className="grid grid-cols-12 gap-2 mb-5">
          <div className={rowFirstCol}>
            <div className="text-sm2 text-color-dark-content text-center">
              {t('front.payslip.show.leftToPayCard.payslip')}
            </div>
          </div>
          <div className={rowSecondCol}>
            {isPayslipFunnel || file?.url === null ? (
              <>-</>
            ) : (
              <DownloadButton Icon={IcnFichePaie} file={file as FileType} />
            )}
          </div>
        </div>
      </div>
      {errorText && (
        <div className="h-[62px] bg-color-bg-warning flex items-center justify-center">
          <div className="text-center text-color-warning text-base-2 flex align-middle">
            <ReportProblemIc className="fill-color-warning mr-5" />
            {errorText?.message}
          </div>
        </div>
      )}
      <DisplayWarning list={payslipAlertsList} />
    </div>
  );
};
