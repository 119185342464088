/* eslint-disable radix */
import { ComponentProps } from 'react';
import { FilterFn, RowData } from '@tanstack/react-table';
import { MaskedTextInput } from '../../inputs/MaskedTextInput';
import { FilterComponentProps, PriumFilterFn } from './types';
import { TextFilter } from './TextFilter';
import { TextInput } from '../../inputs';

export type PeriodFilterOptions = Partial<
  ComponentProps<typeof MaskedTextInput>
>;

export type TextInputOptions = Partial<ComponentProps<typeof TextInput>>;

export function getStrictEqualFilter<TData extends RowData>(): FilterFn<TData> {
  const filter: PriumFilterFn<TData> = (
    row,
    columnId,
    filterValue: unknown,
  ) => {
    const value = row.getValue(columnId);

    if (value == null) return false;

    return value === filterValue;
  };

  filter.backendFilterFn = (columnId, value: string) => ({
    [columnId]: value,
  });

  filter.resolveFilterValue = (value) => value;

  return filter;
}

const inputProps = {
  placeholder: 'Enter a number',
  inputCustomClass: 'no-arrow w-[133px]',
  type: 'number',
};

export function StrictEqualFilter<TData, TValue extends string>({
  options,
  ...props
}: FilterComponentProps<TData, TValue, PeriodFilterOptions>) {
  const optionsWithDefaults = {
    ...inputProps,
    ...options,
  };

  return <TextFilter {...props} options={optionsWithDefaults} />;
}
