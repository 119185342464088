export const useYearsSelectOptions = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  let currentPayslipYear = 2019; // first payslip year

  let options: Array<{ label: string; value: string }> = [];
  do {
    options = [
      ...options,
      {
        label: currentPayslipYear.toString(),
        value: currentPayslipYear.toString(),
      },
    ];
    currentPayslipYear += 1;
  } while (currentPayslipYear <= currentYear);
  return options;
};
