import { RoutesMounter } from '@/types';
import { index, member } from '@/app/navigation/functions';
import OutstandingInvoicesIndex from './pages/OutstandingInvoicesIndex';

export const mountFactorOutstandingInvoicesRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { t },
}) => [
  member({
    path,
    handle: { crumb: () => t('front.factor.outstanding_invoice.index.title') },
    children: [index({ index: true, Component: OutstandingInvoicesIndex })],
  }),
];
