import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TooltipComponent } from '@/modules/common/components/fragments/TooltipComponent';
import { selectUserRoles } from '@/app/store/selectors/selectUserId';
import { ActivityAccountModal } from '@/modules/employee/activityAccount/components/ActivityAccountModal';
import { MoneyType } from '@/modules/common/utils/type.utils';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import {
  AmountWithCurrency,
  AmountText,
} from '@/modules/common/components/fragments/AmountText';
import ActivityAccountLineGroup from '@/app/spraypaint/activityAccount/ActivityAccountLineGroup';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import ActivityAccountLine from '@/app/spraypaint/activityAccount/ActivityAccountLine';
import Table from '../../../common/components/table/Table';
import { activityAccountColumns } from '../columns/activityAccountColumns';
import { useBackendTable } from '../../../common/components/table/hooks';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../../../common/components/buttons';
import useEmployeeActivityAccountLinesGroups from '../hooks/useEmployeeActivityAccountLinesGroups';
import useEmployeeActivityAccount from '../hooks/useEmployeeActivityAccount';

const ActivityAccountShow = () => {
  const employeeId = Number(useCurrentEmployeeId());
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activityAccountScope, activityAccountScopeKey, activityAccountProps] =
    useBackendTable<ActivityAccountLineGroup | ActivityAccountLine>();

  const { activityAccount, totalBalance, provisions } =
    useEmployeeActivityAccount(employeeId);

  const { isLoading, activityAccountLinesGroups, totalCount } =
    useEmployeeActivityAccountLinesGroups(
      activityAccount?.id,
      activityAccountScope,
      activityAccountScopeKey,
    );

  const [fetchKey, setFetchKey] = useState(0);
  const refreshData = () => {
    setFetchKey(fetchKey + 1);
  };
  const columns = activityAccountColumns();

  const handleOpenModal = () => setIsModalOpen((prev) => !prev);
  const handleExport = async () => {
    // todo
  };

  const roles = selectUserRoles();

  const handleGetSubRows = (
    row: ModelRecord<ActivityAccountLineGroup | ActivityAccountLine>,
  ) => {
    if (row instanceof ActivityAccountLineGroup)
      if (row.activityAccountLines && row.activityAccountLines?.length > 1)
        return row.activityAccountLines;
    return undefined;
  };

  return (
    <div className="mb-[80px]">
      <p className="text-xxl text-color-medium-grey ml-[20px]">
        {t('front.activityAccount.title')}
      </p>
      <div className="flex text-xl my-5">
        <AmountText
          className="mx-2"
          inline
          amount={totalBalance as MoneyType}
          label={t('front.activityAccount.balance')}
        />
        <TooltipComponent
          tooltipId={String('my-solde')}
          tooltipMsgComp={t('front.activityAccount.tooltip.date', {
            date: new Date().toLocaleDateString(),
          })}
        />
        <AmountText
          className="mx-2"
          amount={provisions?.total as MoneyType}
          label={t('front.activityAccount.provisions')}
          inline
        />
        <TooltipComponent
          tooltipId={String('my-provision')}
          tooltipMsgComp={
            <div>
              <p>
                {t('front.activityAccount.tooltip.eoc_provisions')}
                <AmountWithCurrency
                  amount={provisions?.details.eoc_provisions?.amount ?? 0}
                  currencyIsoCode={provisions?.details.eoc_provisions?.currency}
                />
              </p>
              <p>
                {t('front.activityAccount.tooltip.paid_holidays_provisions')}
                <AmountWithCurrency
                  spanClass="ml-[10px]"
                  amount={
                    provisions?.details.paid_holidays_provisions?.amount ?? 0
                  }
                  currencyIsoCode={
                    provisions?.details.paid_holidays_provisions?.currency
                  }
                />
              </p>
              <p>
                {t('front.activityAccount.tooltip.financial_provisions')}
                <AmountWithCurrency
                  spanClass="ml-[10px]"
                  amount={provisions?.details.financial_provisions?.amount ?? 0}
                  currencyIsoCode={
                    provisions?.details.financial_provisions?.currency
                  }
                />
              </p>
            </div>
          }
        />
      </div>
      <div className="ml-[20px] mt-[20px]">
        {roles.includes('admin') && (
          <ButtonPrimary className="mt-[10px]" onClick={handleOpenModal}>
            {t('front.activityAccount.charge')}
          </ButtonPrimary>
        )}
        <ButtonSecondary className="mt-[10px]" onClick={handleExport}>
          {t('front.activityAccount.export')}
        </ButtonSecondary>
      </div>
      <ActivityAccountModal
        activityAccountId={activityAccount?.id}
        displayModal={isModalOpen}
        handleSetCloseModal={handleOpenModal}
        refreshData={refreshData}
      />

      <Table
        emptyTableErrorMsg="react_table.no_data_text"
        className="mt-[26px] "
        withImageErrorMsg={false}
        getSubRows={handleGetSubRows}
        isLoading={isLoading}
        totalCount={totalCount}
        data={activityAccountLinesGroups || []}
        reactTableProps={activityAccountProps}
        columns={columns}
      />
    </div>
  );
};

export default ActivityAccountShow;
