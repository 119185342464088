import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PrintableLine from '../../types/PrintableLine';
import {
  PayslipElementLine,
  PayslipElementLineType,
} from '../PayslipElementLine';
import { HeaderMainCardText } from '../../../common/components/mainCard/components';
import { LineMainCardText } from '../LineMainCardText';

export type FeesAndChargesCardType = {
  expenses: Array<PayslipElementLineType>;
};

export const FeesAndChargesCard = ({
  activityFees = 0,
  activityFeesList = [],
  provisionAdjustments = 0,
  provisionAdjustmentsList = [],
  contributionsListSum = 0,
  contributionsList = [],
}: {
  activityFees?: number;
  activityFeesList: Array<PrintableLine>;
  provisionAdjustments?: number;
  provisionAdjustmentsList: Array<PrintableLine>;
  contributionsListSum?: number;
  contributionsList: Array<PrintableLine>;
}) => {
  const { t } = useTranslation();
  const [displayAll, setDisplayAll] = useState(false);

  return (
    <>
      <HeaderMainCardText
        title={t('front.payslip.show.feesAndChargesCard.title')}
        handleOnClick={setDisplayAll}
        displayAll={displayAll}
        displayCollapseButton
      />
      <div className="pl-3 md:pl-5">
        <LineMainCardText
          label={t('front.payslip.show.feesAndChargesCard.activityFees')}
          value={activityFees}
          displayAll={displayAll}
          lineToDisplay
        >
          {activityFeesList.map((line) => (
            <PayslipElementLine key={line.slug} printableLine={line} />
          ))}
        </LineMainCardText>
        <LineMainCardText
          label={t(
            'front.payslip.show.feesAndChargesCard.provisionsAdjustments',
          )}
          value={{
            amount: provisionAdjustments,
            currency: 'EUR',
          }}
          displayAll={displayAll}
          lineToDisplay
        >
          {provisionAdjustmentsList.map((line) => (
            <PayslipElementLine key={line.slug} printableLine={line} />
          ))}
        </LineMainCardText>
        <LineMainCardText
          label={t('front.payslip.show.feesAndChargesCard.socialCharges')}
          value={{
            amount: contributionsListSum,
            currency: 'EUR',
          }}
          displayAll={displayAll}
          lineToDisplay
        >
          {contributionsList.map((line) => (
            <PayslipElementLine key={line.slug} printableLine={line} />
          ))}
        </LineMainCardText>
      </div>
    </>
  );
};
