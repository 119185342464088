import { IconButtonSecondary } from './IconButtonSecondary';
import { FileType, IconType } from '../../utils/type.utils';
import { handleDownloadFile } from './utils/DownloadFunction';

interface Props {
  file: FileType;
  Icon: IconType;
  customClass?: string;
}

export const DownloadButton = ({
  file,
  Icon,
  customClass = '',
}: Props): React.ReactElement => {
  const handleDownload = (event: React.MouseEvent<HTMLElement>): void => {
    handleDownloadFile({ event, file });
  };

  return (
    <IconButtonSecondary
      Icon={Icon}
      onClick={handleDownload}
      className={customClass}
    />
  );
};
