export type PayslipFunnelStepMappingType = {
  invoices_step: string;
  cra_step: string;
  expenses_step: string;
  intermediate_summary_step: string;
  salary_step: string;
  final_summary_step: string;
};

export type PayslipFunnelMixPanelMappingType = {
  invoices_step: string;
  cra_step: string;
  expenses_step: string;
  intermediate_summary_step: string;
  salary_step: string;
};

export const PayslipFunnelStepMapping: PayslipFunnelStepMappingType = {
  invoices_step: 'front.payslip.tdc.stepper.invoices_step',
  cra_step: 'front.payslip.tdc.stepper.cra_step',
  expenses_step: 'front.payslip.tdc.stepper.expenses_step',
  intermediate_summary_step:
    'front.payslip.tdc.stepper.intermediate_summary_step',
  salary_step: 'front.payslip.tdc.stepper.salary_step',
  final_summary_step: 'front.payslip.tdc.stepper.final_summary_step',
};

/**
 * Get step name from mapping step name to trad.
 * @remarks
 * 0 = invoice_step, 1 = cra_step, 2 = expenses_step, 3 = intermediate_summary_step, 4 = salary_step, 5 = final_summary_step
 * @param index - the index to get the current step name
 * @returns Step name
 */
export const StepNameBackToFront = (index: 0 | 1 | 2 | 3 | 4 | 5 | 6) =>
  Object.keys(PayslipFunnelStepMapping)[index];

export const mapStepNameToMixPanelEventName: PayslipFunnelMixPanelMappingType =
  {
    invoices_step: 'TDS: Invoices Step Manual Reset',
    cra_step: 'TDS: Cra Step Manual Reset',
    expenses_step: 'TDS: Expenses Step Manual Reset',
    intermediate_summary_step: 'TDS: Intermediate Summary Step Manual Reset',
    salary_step: 'TDS: Salary Step Manual Reset',
  };
