import { RoutesMounter } from '@/types';
import { index, member } from '@/app/navigation/functions';
import AccountingCodesIndex from './pages/AccountingCodesIndex';

export const mountAccountingCodesRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { t },
}) => [
  member({
    path,
    handle: { crumb: () => t('front.bankTransfer.crumbs.index') },
    children: [index({ index: true, Component: AccountingCodesIndex })],
  }),
];
