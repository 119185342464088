import { useQuery } from '@tanstack/react-query';
import PayslipFunnel from '../../../../app/spraypaint/payslipFunnel/PayslipFunnel';

export const useFinalSummaryStep = (payslipFunnelId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ['finalSummaryStep', payslipFunnelId],
    cacheTime: 0,
    retry: 0,
    queryFn: () =>
      PayslipFunnel.includes({
        finalSummaryStep: 'payslipPreview',
      })
        .selectExtra(['messages_i18n_keys', 'stc', 'date_range_last_date'])
        .find(payslipFunnelId),
  });
  const stc = data?.data?.stc;
  const dateRangeLastDate = data?.data?.dateRangeLastDate;
  const finalSummaryStep = data?.data?.finalSummaryStep;

  const payslipPreview = finalSummaryStep?.payslipPreview;

  const hoursDistribution = finalSummaryStep?.hoursDistribution;
  const period = data?.data?.period;
  const salaryStrategy = finalSummaryStep?.salaryStrategy?.name;

  return {
    data,
    isLoading,
    finalSummaryStep,
    payslipPreview,
    hoursDistribution,
    salaryStrategy,
    period,
    stc,
    dateRangeLastDate,
  };
};
