import axios from 'axios';
import { useTranslation } from 'react-i18next';

const useAuthClient = () => {
  const { i18n } = useTranslation();
  return axios.create({
    baseURL: '/api/auth',
    withCredentials: true,
    headers: {
      'Accept-Language': i18n.language,
      'Content-Type': 'application/json',
    },
  });
};

export type AuthErrorResponse = {
  error: string;
  field_error?: [string, string];
};

export type AuthErrorResponseWithReason<TReasonCode> = {
  reason_code?: TReasonCode;
  reason?: string;
  error: string;
  field_error?: [string, string];
};

export default useAuthClient;
