import { BelongsTo, HasOne, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import LegalEntity from '../LegalEntity';
import BankAccount from '../BankAccount';

@Model()
class FactoringContract extends ApplicationRecord {
  static jsonapiType = 'factor-factoring_contracts';

  @BelongsTo('legal_entities') legalEntity!: LegalEntity;
  @HasOne('bank_accounts') bankAccount!: BankAccount;
}

export default FactoringContract;
