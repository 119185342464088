import { TooltipComponent } from '@/modules/common/components/fragments/TooltipComponent';
import { HelpOutlineIcon } from '@/app/assets/icons/inputs';
import { FC, ReactNode } from 'react';

export type AttributeLineProps = {
  id: string;
  label: string;
  value: ReactNode;
  seeMore?: string | null;
  hideEmpty?: boolean;
};

export const AttributeLine: FC<AttributeLineProps> = ({
  id,
  label,
  value,
  seeMore,
  hideEmpty,
}) => {
  if (hideEmpty && !value) return null;
  return (
    <div>
      <div className="md:flex" id={`attribute-line-${id}`}>
        <div className="md:w-1/2">
          <span className="text-color-pone-dark">{label}</span>
          {seeMore && (
            <TooltipComponent
              Icon={HelpOutlineIcon}
              tooltipMsgComp={seeMore}
              tooltipId={String(`tooltip-attribute-line-${id}`)}
              iconClassName="ml-2 md:ml-[10px] inline-block"
            />
          )}
        </div>
        <div className="md:w-1/2">
          <span className="block text-color-dark-content">{value ?? '-'}</span>
        </div>
      </div>
    </div>
  );
};
