// api/RevenueSummary.ts
import { Model, Attr } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';

@Model()
class RevenueSummary extends ApplicationRecord {
  static jsonapiType = 'revenue_summaries';

  // Attributes
  @Attr({ persist: false }) revenueId!: number;
  @Attr({ persist: false }) revenueType!: string;
  @Attr({ persist: false }) date!: string;
  @Attr({ persist: false }) number!: string;
  @Attr({ persist: false }) status!: string;
  @Attr({ persist: false }) sendDate!: string;
  @Attr({ persist: false }) totalAmount!: number;
  @Attr({ persist: false }) paidAmount!: number;
  @Attr({ persist: false }) remainingAmount!: number;
  @Attr({ persist: false }) ownerId!: number;
  @Attr({ persist: false }) ownerType!: string;
  @Attr({ persist: false }) dueDate!: string;
  @Attr({ persist: false }) dateOfPayment!: string;
  @Attr({ persist: false }) clientName!: string;
  @Attr({ persist: false }) externalReference!: string;

  // Relationships
  // Polymorphic relation owner : TBD
  // Polymorphic relation revenue : TBD
}

export default RevenueSummary;
