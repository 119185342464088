/* eslint-disable import/no-cycle */
// api/PayslipFunnelContextChangeMessage.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import PayslipFunnel from './PayslipFunnel';

@Model()
class PayslipFunnelContextChangeMessage extends ApplicationRecord {
  static jsonapiType = 'payslip_funnel_context_change_messages';

  // Attributes
  @Attr({ persist: false }) i18nKey!: string;
  @Attr({ persist: false }) targetStep!: string;
  @Attr({ persist: false }) messageType!: string;
  @Attr({ persist: false }) localizedMessage!: string;
  @Attr({ persist: false }) payslipFunnelId!: number;
  @Attr({ persist: false }) createdAt!: string;

  @BelongsTo('payslip_funnels') payslipFunnel!: PayslipFunnel;
}

export default PayslipFunnelContextChangeMessage;
