import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { useNavigate } from 'react-router-dom';
import { IllusTabEmpty } from '../assets/icons/others';

export const PageNotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center relative screen-height-minus-header w-full">
      <IllusTabEmpty className="relative inset-0 z-1 max-w-[75%] max-h-[90%] opacity-50" />

      <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-white p-8 rounded-lg shadow-md max-w-2xl w-full z-100">
        <h1 className="text-2xl font-bold mb-2 text-color-pone-dark">
          {t('front.error_page.page_not_found')}
        </h1>
        <p className="mb-4 text-color-dark-content">
          {t('front.error_page.check_address')}
        </p>
        <ButtonPrimary
          onClick={() => {
            navigate(-1);
          }}
        >
          {t('front.error_page.back')}
        </ButtonPrimary>
      </div>
    </div>
  );
};
