import PrintableLine from '../types/PrintableLine';
import { DisplayedLineValue } from './DisplayLineValue';

export type PayslipElementLineType = {
  printableLine: PrintableLine;
};

export const PayslipElementLine = ({ printableLine }: PayslipElementLineType) =>
  printableLine.value ? (
    <div className="mb-3 ml-8 pr-5 md:px-5 flex justify-between items-center text-sm2 text-color-medium-grey">
      <p className="w-[60%] md:w-[80%]">{printableLine.view_name}</p>
      <span>
        <DisplayedLineValue
          value={printableLine.value}
          customTextClass="text-sm2"
          customCurrencyClass="top-0 text-sm2 ml-1"
        />
      </span>
    </div>
  ) : null;
