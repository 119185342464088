import { QueryClient, useQuery } from '@tanstack/react-query';
import RegisteredClient from '@/app/spraypaint/RegisteredClient';
import { TypedLoaderFunction } from '@/types';
import { RecordProxy } from 'spraypaint/lib-esm/proxies';

export function registeredClientQuery(id: number | string) {
  return {
    queryKey: ['registered-client', id],
    queryFn: () =>
      RegisteredClient.includes(['activity_domain'])
        .selectExtra(['overdue_amount_euro'])
        .find(id),
  };
}

export function loader(
  queryClient: QueryClient,
): TypedLoaderFunction<RecordProxy<RegisteredClient>> {
  return async ({ params }) => {
    const { id } = params;

    // if id is undefined, or is not a number-string
    if (!id || Number.isNaN(Number(id)))
      // Allow Response to be thrown here because this is an expected
      // behavior from React Router
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new Response('Not found', { status: 404, statusText: 'Not Found' });

    const query = registeredClientQuery(id);
    return queryClient.ensureQueryData(query);
  };
}

const useRegisteredClient = (id: number | string) => {
  const data = useQuery({
    queryKey: ['registered-client', id],
    queryFn: () =>
      RegisteredClient.includes(['activity_domain'])
        .selectExtra(['overdue_amount_euro'])
        .find(id),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const registeredClient = data?.data?.data;

  return { ...data, registeredClient };
};

export default useRegisteredClient;
