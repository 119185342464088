import { RoutesMounter } from '@/types';
import { index, member } from '@/app/navigation/functions';
import BankTransferIndex from './BankTransferIndex';
import BankTransferShow from './BankTransferShow';
import { loader as sepaFileLoader } from './functions/sepa_file_query';

export const mountBankTransferRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { t, queryClient },
}) => [
  member({
    path,
    handle: { crumb: () => t('front.bankTransfer.crumbs.index') },
    children: [
      index({ index: true, Component: BankTransferIndex }),
      member({
        path: ':id',
        handle: { crumb: ({ data }) => data.data.filename },
        loader: sepaFileLoader(queryClient),
        Component: BankTransferShow,
      }),
    ],
  }),
];
