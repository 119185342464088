import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { UserState } from '../userStore';

export const selectUserId = (): string =>
  useSelector((state: RootState): string => state.user.id);

export const selectUserRole = (): string =>
  useSelector((state: RootState): string => state.user.primaryRole);

export const selectUserRoles = (): Array<string> =>
  useSelector((state: RootState): Array<string> => state.user.roles);

export const selectUserState = (): UserState =>
  useSelector((state: RootState): UserState => state.user);
