import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useState } from 'react';
import {
  StrategyCard,
  StrategyCardProps,
  CraRecapCard,
  FeesAndChargesCard,
  LeftToPayCard,
  SalaryCard,
  SalaryCostCard,
} from '@/modules/payslip/components';
import { selectUserRole } from '@/app/store/selectors/selectUserId';
import { getPrepMonthName } from '../../common/utils/date.utils';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../../common/components/buttons';
import { usePayslip } from '../hooks/usePayslip';
import { useIsAPayslipFunnel } from '../../payslip_funnel/hooks/useIsAPayslipFunnel';
import { Breadcrumbs } from '../../navigation/components/Breadcrumbs';
import { MoneyType, PeriodType } from '../../common/utils/type.utils';
import { CraModal } from '../components/CraModal';

const mockedStrategyCard: StrategyCardProps = {
  chosenStrategy: {
    name: 'minimum_salary',
    options: {
      value: {
        amount: 3_056.09,
        currency: 'EUR',
      } as MoneyType,
      target: 'gross',
    },
  },
  additionalAllowance: 500,
  financialReserve: 1000,
};

export const PayslipShow = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal((prev) => !prev);

  const strategyCardDisplayed = false;
  const isPayslipFunnel = useIsAPayslipFunnel();
  const primaryRole = selectUserRole();
  const renderSupportButton = ['support'].includes(primaryRole);

  const {
    payslip,
    period,
    grossSalaryList,
    leftToPayRealBeforeTaxList,
    leftToPayRealBeforeTax,
    leftToPayReal,
    leftToPayRealList,
    activityFeesList,
    provisionAdjustmentsList,
    contributionsList,
    payslipAlertsList,
    file,
    previousEmployeePayslipId,
    nextEmployeePayslipId,
    isLoading,
    fromPayslipFunnel,
    activityReport,
  } = usePayslip(id as string);

  const customClass = classNames(
    'grid gap-x-12 flex items-center mt-[22px]',
    'grid-cols-1',
    strategyCardDisplayed ? 'md:grid-cols-3' : 'md:grid-cols-2',
  );

  return (
    <div className="ml-5 mr-10 mt-[44px]">
      <CraModal
        handleSetCloseModal={handleOpenModal}
        isOpen={openModal}
        activityReportSlots={activityReport?.activityReportSlots}
      />
      <div className="flex justify-between">
        <p className="text-xxl text-color-medium-grey">
          {t('views.payslip.model_name')}{' '}
          {`${getPrepMonthName(period as PeriodType)} ${period?.year}`}
        </p>
        <div className="flex justify-between">
          {fromPayslipFunnel && !isLoading && renderSupportButton && (
            <ButtonPrimary to="download_json" className="mr-3">
              {t('front.breadcrumbs.payslips.download_json')}
            </ButtonPrimary>
          )}
          {fromPayslipFunnel && !isLoading && (
            <ButtonPrimary to="details" className="w-36">
              {t('front.payslip.show.buttonTitle.seeDetail')}
            </ButtonPrimary>
          )}
        </div>
      </div>
      <Breadcrumbs />
      <div className={customClass}>
        <LeftToPayCard
          isStrategyCardDisplay={strategyCardDisplayed}
          period={period}
          employmentContractId={payslip?.employmentContractId}
          employeeId={payslip?.employeeId}
          scheduledTransferDate={payslip?.expectedPaymentDate}
          paymentDate={payslip?.paymentDate}
          leftToPay={{ amount: leftToPayReal, currency: 'EUR' }}
          file={{ name: file?.name, url: file?.url }}
          payslipAlertsList={payslipAlertsList}
        />

        <SalaryCostCard
          isPayslipFunnel={isPayslipFunnel}
          previousBalance={payslip?.previousBalance ?? 0}
          payslipCost={payslip?.payslipCost}
          incomingBalance={payslip?.incomingBalance}
        />
        {strategyCardDisplayed && (
          <StrategyCard
            chosenStrategy={mockedStrategyCard.chosenStrategy}
            additionalAllowance={mockedStrategyCard.additionalAllowance}
            financialReserve={mockedStrategyCard.financialReserve}
          />
        )}
      </div>
      <div className="grid grid-cols-1 mt-[22px]">
        <CraRecapCard
          hoursDistribution={payslip?.hoursDistribution}
          handleModal={handleOpenModal}
        />
      </div>
      <div className="grid grid-cols-1 flex mt-[22px]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-0 flex shadow-[0_0_20px_0_rgba(0,0,0,0.11)] rounded">
          <div className="pt-5 pb-10">
            <FeesAndChargesCard
              activityFees={payslip?.activityFees}
              activityFeesList={activityFeesList}
              provisionAdjustments={payslip?.provisionAdjustments}
              provisionAdjustmentsList={provisionAdjustmentsList}
              contributionsListSum={payslip?.contributionsListSum}
              contributionsList={contributionsList}
            />
          </div>
          <div className="bg-color-transparent-medium-grey pt-5 pb-10">
            <SalaryCard
              grossSalary={payslip?.grossSalary}
              netBeforeTax={leftToPayRealBeforeTax}
              leftToPayReal={payslip?.leftToPayReal}
              grossSalaryList={grossSalaryList}
              netBeforeTaxList={leftToPayRealBeforeTaxList}
              leftToPayRealList={leftToPayRealList}
            />
          </div>
        </div>
        <div className="grid lg:grid-cols-4 m-10 flex grid-cols-2 rounded">
          <div className="lg:col-start-2 col-start-1">
            {previousEmployeePayslipId && (
              <ButtonSecondary
                to={`/v2/employees/${payslip?.employeeId}/payslips/${previousEmployeePayslipId}`}
                className="mx-3"
              >
                {t('front.payslip.show.previous_payslips')}
              </ButtonSecondary>
            )}
          </div>
          <div className="lg:col-start-3 col-start-2">
            {nextEmployeePayslipId && (
              <ButtonSecondary
                to={`/v2/employees/${payslip?.employeeId}/payslips/${nextEmployeePayslipId}`}
              >
                {t('front.payslip.show.next_payslips')}
              </ButtonSecondary>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
