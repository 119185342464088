import { useTranslation } from 'react-i18next';

const useCivilities = () => {
  const { t } = useTranslation();

  const civilities = [
    { label: t('front.auth.signUp.form.civility.label.mr'), value: 'M.' },
    { label: t('front.auth.signUp.form.civility.label.mme'), value: 'Mme' },
  ];

  return { civilities };
};
export default useCivilities;
