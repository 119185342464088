/* eslint-disable import/no-cycle */
import { Attr, HasMany, HasOne, Model } from 'spraypaint';
import FrameworkContract from '@spraypaint/FrameworkContract';
import ApplicationRecord from './ApplicationRecord';
import Employee from './Employee';
import BankAccount from './BankAccount';

export type LegalEntitySlugType =
  | 'prium_portage'
  | 'prium_partners'
  | 'prium_formation'
  | 'prium_city'
  | 'prium_city_immo'
  | 'prium_experts'
  | 'prium_mt';

@Model()
class LegalEntity extends ApplicationRecord {
  static jsonapiType = 'legal_entities';

  @Attr() name!: string;
  @Attr() slug!: LegalEntitySlugType;

  @HasMany('employees') employees: Employee[];
  @HasOne('bank_accounts') bankAccount!: BankAccount;
  @HasMany('framework_contracts') frameworkContracts!: Array<FrameworkContract>;
}

export default LegalEntity;
