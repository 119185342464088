import { Attr, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class PortageOfferStep extends ApplicationRecord {
  // Attributes
  static jsonapiType = 'onboarding_funnel_steps-portage_offer_steps';
  static endpoint = '/onboarding_funnel_steps/portage_offer_steps';

  // Attributes
  @Attr() expertiseId: number;
  @Attr() portageOfferId: number;
  @Attr() expectedSignatureDate: string;
}
export default PortageOfferStep;
