import { RoutesMounter } from '@/types';
import { index, member } from '@/app/navigation/functions';
import { PayslipAdminIndex } from './pages';

export const mountPayslipAdminRoutes: RoutesMounter<unknown> = ({ path }) => [
  member({
    path,
    handle: { crumb: () => 'Payslip admin index' },
    children: [index({ index: true, Component: PayslipAdminIndex })],
  }),
];
