import { useTranslation } from 'react-i18next';
import {
  archiveStatusIcon,
  failureStatusIcon,
  iconError24px,
  successStatusIcon,
  waitingActionStatusIcon,
  waitingStatusIcon,
} from '../../../../app/assets/icons/statuses';

export const closedProps = () => {
  const { t } = useTranslation();
  return {
    icon: archiveStatusIcon,
    alt: 'archiveIcon',
    title: t('front.statusComponent.closed'),
    className: {
      textStyle: 'text-color-medium-grey',
      containerStyle: 'bg-color-medium-light',
    },
  };
};
export const failureProps = () => {
  const { t } = useTranslation();
  return {
    icon: failureStatusIcon,
    alt: 'failureIcon',
    title: t('front.statusComponent.rejected'),
    className: {
      textStyle: 'text-color-failure',
      containerStyle: 'bg-color-bg-failure',
    },
  };
};

export const irrecoverableProps = () => {
  const { t } = useTranslation();
  return {
    icon: failureStatusIcon,
    alt: 'failureIcon',
    title: t('front.statusComponent.irrecoverable'),
    className: {
      textStyle: 'text-color-failure',
      containerStyle: 'bg-color-bg-failure',
    },
  };
};

export const rejectedProps = () => {
  const { t } = useTranslation();
  return {
    icon: failureStatusIcon,
    alt: 'rejectedIcon',
    title: t('front.statusComponent.rejected'),
    className: {
      textStyle: 'text-color-failure',
      containerStyle: 'bg-color-bg-failure',
    },
  };
};

export const orphanedProps = () => ({
  icon: failureStatusIcon,
  alt: 'rejectedIcon',
  title: 'Abandoné',
  className: {
    textStyle: 'text-color-failure',
    containerStyle: 'bg-color-bg-failure',
  },
});

export const duplicateProps = () => ({
  icon: successStatusIcon,
  alt: 'successIcon',
  title: 'Doublon',
  className: {
    textStyle: 'text-color-success',
    containerStyle: 'bg-color-bg-success',
  },
});

export const validatedProps = () => {
  const { t } = useTranslation();
  return {
    icon: successStatusIcon,
    alt: 'successIcon',
    title: t('front.statusComponent.validated'),
    className: {
      textStyle: 'text-color-success',
      containerStyle: 'bg-color-bg-success',
    },
  };
};

export const paidProps = () => {
  const { t } = useTranslation();
  return {
    icon: successStatusIcon,
    alt: 'successIcon',
    title: t('front.statusComponent.paid'),
    className: {
      textStyle: 'text-color-success',
      containerStyle: 'bg-color-bg-success',
    },
  };
};

export const cancellationExpenseProps = () => {
  const { t } = useTranslation();
  return {
    alt: 'successIcon',
    icon: successStatusIcon,
    title: t('front.statusComponent.cancellationExpense'),
    className: {
      textStyle: 'text-white',
      containerStyle: 'bg-color-blue',
    },
  };
};

export const waitingProps = (waitingForAction?: boolean) => {
  const { t } = useTranslation();
  return {
    icon: waitingForAction ? waitingActionStatusIcon : waitingStatusIcon,
    alt: 'waitingIcon',
    title: waitingForAction
      ? t('front.statusComponent.waitingWithActions')
      : t('front.statusComponent.waiting'),
    className: {
      textStyle: 'text-color-warning',
      containerStyle: 'bg-color-bg-warning',
    },
  };
};

export const signatureStartedProps = () => {
  const { t } = useTranslation();
  return {
    icon: waitingActionStatusIcon,
    alt: 'waitingIcon',
    title: t('front.statusComponent.signatureStarted'),
    className: {
      textStyle: 'text-color-warning',
      containerStyle: 'bg-color-bg-warning',
    },
  };
};

export const draftProps = (waitingForAction?: boolean) => {
  const { t } = useTranslation();
  return {
    icon: waitingForAction ? waitingActionStatusIcon : waitingStatusIcon,
    alt: 'waitingIcon',
    title: t('front.statusComponent.draft'),
    className: {
      textStyle: 'text-color-warning',
      containerStyle: 'bg-color-bg-warning',
    },
  };
};

export const submittedProps = () => {
  const { t } = useTranslation();
  return {
    icon: waitingStatusIcon,
    alt: 'waitingIcon',
    title: t('front.statusComponent.submitted'),
    className: {
      textStyle: 'text-color-warning',
      containerStyle: 'bg-color-bg-warning',
    },
  };
};

export const partiallyPaidProps = () => {
  const { t } = useTranslation();
  return {
    icon: waitingStatusIcon,
    alt: 'waitingIcon',
    title: t('front.statusComponent.partiallyPaid'),
    className: {
      textStyle: 'text-color-warning',
      containerStyle: 'bg-color-bg-warning',
    },
  };
};

export const waitingMatchingProps = () => {
  const { t } = useTranslation();
  return {
    icon: waitingStatusIcon,
    alt: 'waitingIcon',
    title: t('front.sepa.matchingStatus.enum.waiting'),
    className: {
      textStyle: 'text-color-failure',
      containerStyle: 'bg-color-bg-failure',
    },
  };
};

export const runningMatchingProps = () => {
  const { t } = useTranslation();
  return {
    icon: waitingStatusIcon,
    alt: 'runningIcon',
    title: t('front.sepa.matchingStatus.enum.running'),
    className: {
      textStyle: 'text-color-warning',
      containerStyle: 'bg-color-bg-warning',
    },
  };
};

export const doneMatchingProps = () => {
  const { t } = useTranslation();
  return {
    icon: successStatusIcon,
    alt: 'doneIcon',
    title: t('front.sepa.matchingStatus.enum.done'),
    className: {
      textStyle: 'text-color-success',
      containerStyle: 'bg-color-bg-success',
    },
  };
};

export const missingProps = () => {
  const { t } = useTranslation();
  return {
    icon: waitingActionStatusIcon,
    alt: 'waitingActionIcon',
    title: t('views.payslip.statuses.missing'),
    className: {
      textStyle: 'text-color-dark-content',
      containerStyle: 'bg-color-medium-grey',
    },
  };
};

export const stcProps = () => {
  const { t } = useTranslation();
  return {
    icon: iconError24px,
    alt: 'stc',
    title: t('views.payslip.statuses.stc'),
    className: {
      textStyle: 'text-color-failure',
      containerStyle: 'bg-color-bg-failure',
    },
  };
};

export const newProps = () => {
  const { t } = useTranslation();
  return {
    icon: successStatusIcon,
    alt: 'new',
    title: t('views.payslip.statuses.new'),
    className: {
      textStyle: 'text-color-success',
      containerStyle: 'bg-color-bg-success',
    },
  };
};

// made to make easier to notice missing mapping between front end and back end
// this design should not go in production

export const unknownProps = (statusType: string) => ({
  icon: waitingStatusIcon,
  alt: 'waitingIcon',
  title: `Status inconnu - ${statusType}`,
  className: {
    textStyle: 'text-color-pastel-orange',
    containerStyle: 'bg-color-blue',
  },
});
