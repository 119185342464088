import { FunctionComponent, SVGProps } from 'react';

export const PAYSLIP_STATUSES = [
  'in_progress',
  'submitted',
  'validated',
  'archived',
  'closed',
] as const;
export type PayslipStatusEnum = typeof PAYSLIP_STATUSES[number];

export const EXPENSE_STATUSES = [
  'in_progress',
  'submitted',
  'validated',
  'paid',
  'rejected',
] as const;
export type ExpenseStatusEnum = typeof EXPENSE_STATUSES[number];

export type FileType = {
  name: string;
  url: string;
};

export type IconType = FunctionComponent<
  SVGProps<SVGSVGElement> & { title?: string | undefined }
>;

export const DOCUMENT_LANGUAGES = ['fr', 'en'] as const;
export type DocumentLanguageEnum = typeof DOCUMENT_LANGUAGES[number];

export const VAT_CGI_SLUGS = [
  'vat_20',
  'vat_8_5',
  'vat_0_cgi_283_ce',
  'vat_0_cgi_283_8',
  'vat_0_cgi_261',
  'vat_0_cgi_293_b',
  'vat_0_cgi_261_c',
] as const;
export type VatCgiSlugEnum = typeof VAT_CGI_SLUGS[number];

export const CURRENCY_ISO_CODES = [
  'EUR',
  'USD',
  'GBP',
  'CAD',
  'JPY',
  'HKD',
  'AUD',
  'CHF',
  'SGD',
  'SEK',
  'NOK',
  'NZD',
  'MXN',
  'DKK',
  'PLN',
  'ZAR',
] as const;
export type CurrencyIsoCode = typeof CURRENCY_ISO_CODES[number];

export type MoneyType = {
  amount: number;
  currency?: CurrencyIsoCode;
};

// Check if value is typeof MoneyType
export function isMoneyType(val: unknown): val is MoneyType {
  if (typeof val !== 'object' || val === null) {
    return false;
  }

  const obj = val as { [key: string]: unknown };

  // Check if amount property exists and is a number
  if (
    !Object.prototype.hasOwnProperty.call(obj, 'amount') ||
    typeof obj.amount !== 'number'
  ) {
    return false;
  }

  // Check if currency property exists
  return (
    Object.prototype.hasOwnProperty.call(obj, 'currency') &&
    typeof obj.currency === 'string'
  );
}

export type MoneyAndCurrency =
  | {
      amount: MoneyType;
      currencyIsoCode?: never;
    }
  | {
      amount: number;
      currencyIsoCode?: CurrencyIsoCode;
    };

export type PeriodType = {
  month: number;
  year: number;
};

export type HoursDistribution = {
  worked_days: number;
  worked_hours: number;
  holidays_days: number;
  holidays_hours: number;
  absence_days: number;
  absence_hours: number;
  overtime_hours: number;
  holidays_days_remaining: number;
};

type AddPrefix<TKey, TPrefix extends string> = TKey extends string
  ? `${TPrefix}${TKey}`
  : never;

type RemovePrefix<
  TPrefixedKey,
  TPrefix extends string,
> = TPrefixedKey extends AddPrefix<infer TKey, TPrefix> ? TKey : '';

type PrefixedValue<
  TObject extends object,
  TPrefixedKey extends string,
  TPrefix extends string,
> = TObject extends { [K in RemovePrefix<TPrefixedKey, TPrefix>]: infer TValue }
  ? TValue
  : never;

// https://stackoverflow.com/questions/57510388/define-prefix-for-object-keys-using-types-in-typescript/68487744#68487744
export type AddPrefixToObject<
  TObject extends object,
  TPrefix extends string,
> = {
  [K in AddPrefix<keyof TObject, TPrefix>]: PrefixedValue<TObject, K, TPrefix>;
};
