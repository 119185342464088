// Ensure that the user is authenticated before accessing this routes
import { RoutesMounterArgs } from '@/types';
import User from '@spraypaint/User';
import mixpanel from 'mixpanel-browser';
import { redirect } from 'react-router-dom';
import { ensureCurrentUserQueryData } from '@/modules/authentification/hooks';
import { isEmpty } from 'lodash';

const isProspect = (user: User) =>
  !isEmpty(user?.onboardingFunnel) &&
  user.onboardingFunnel.status !== 'completed';

export const onlyProspectFilter = (user: User) => {
  if (isProspect(user)) {
    return user;
  }
  window.location.href = '/';
  return redirect('/');
};
export const onlyAdminFilter = (user: User) => {
  if (user.primaryRole === 'admin' || user.primaryRole === 'support') {
    return user;
  }
  window.location.href = '/';
  return redirect('/');
};

export const expectProspectFilter = (user: User) => {
  if (!isProspect(user)) {
    return user;
  }
  window.location.href = '/';
  return redirect('/');
};

export const buildAuthenticatedLoader =
  (
    { queryClient, i18n }: RoutesMounterArgs['tools'],
    filter: (user: User) => unknown = () => undefined,
  ) =>
  async () => {
    try {
      const userProxy = await ensureCurrentUserQueryData({ queryClient, i18n });

      const user = userProxy?.data;
      if (!user) return null;

      const languageFromStorageUnparsed =
        localStorage.getItem('languageLocale');

      const languageFromStorage =
        languageFromStorageUnparsed &&
        JSON.parse(languageFromStorageUnparsed)?.state?.selectedLanguage;

      const prefLanguage = user.preferredLanguage;
      if (
        prefLanguage &&
        user.primaryRole === 'employee' &&
        (languageFromStorage === null || languageFromStorage === undefined) &&
        i18n.language !== prefLanguage
      ) {
        // noinspection ES6MissingAwait
        i18n.changeLanguage(prefLanguage);
      }

      // Don't worry, this only fire a request to mixpanel
      // if the user id changed since last call
      mixpanel.identify(user.id);

      return filter(user) ?? user;
    } catch (error: unknown) {
      if (
        typeof error === 'object' &&
        error !== null &&
        'response' in error &&
        typeof error.response === 'object' &&
        error.response !== null &&
        'status' in error.response &&
        error.response.status === 401
      ) {
        const escapedCurrentLocation = encodeURIComponent(
          window.location.pathname +
            window.location.search +
            window.location.hash,
        );

        return redirect(`/v2/login?next=${escapedCurrentLocation}`);
      }

      throw error;
    }
  };
