import { FactorClientPaymentFile } from '../../../../../app/spraypaint';

export const refreshFileMatching = async (payload: {
  clientPaymentFileId: string | undefined;
}): Promise<void> => {
  const file = await FactorClientPaymentFile.find(
    payload?.clientPaymentFileId as string,
  );
  try {
    await file.data.fetchRefreshMatching();
  } catch {
    throw new Error('Factor client payment file refresh matching error');
  }
};

export const refreshTransactionMatching = async (payload: {
  clientPaymentTransactionId: string | undefined;
}): Promise<void> => {
  const transaction = await FactorClientPaymentFile.find(
    payload?.clientPaymentTransactionId as string,
  );

  try {
    await transaction.data.fetchRefreshMatching();
  } catch {
    throw new Error('Factor Client payment transaction refresh matching error');
  }
};
