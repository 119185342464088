import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { Control, useForm, useWatch } from 'react-hook-form';
import { TextInput } from '@/modules/common/components/inputs';
import { pick } from 'lodash';
import { FormWizardButtons } from '@/modules/common/components/wizard/components/FormWizardButtons';
import { useFormWizardContext } from '@/modules/common/components/wizard';
import { useQuery } from '@tanstack/react-query';
import { CommercialContract } from '@/app/spraypaint';
import { useCurrentEmployee } from '@/modules/authentification/hooks';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { ColumnDefs } from '@/modules/common/components/table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { useDebounce } from 'usehooks-ts';
import { RadioTable } from '@/modules/common/components/inputs/RadioTable';
import { MainCard } from '@/modules/common/components/mainCard';
import { FormState } from '../../types';
import { invoiceDraftWizardContext } from '../../constants';

export interface CommercialContractRadioTableProps {
  control: Control<ContractFormValues>;
  resetCommercialContractId: () => void;
}

const columnHelper = createColumnHelper<ModelRecord<CommercialContract>>();

function buildColumns(): ColumnDefs<ModelRecord<CommercialContract>> {
  return [
    columnHelper.accessor('title', {}),
    columnHelper.accessor((originalRow) => originalRow.client?.name, {
      id: 'client',
      header: 'Client',
      enableColumnFilter: false,
    }),
    columnHelper.accessor('startDate', {}),
    columnHelper.accessor('endDate', {}),
  ];
}

export const CommercialContractRadioTable: FunctionComponent<
  CommercialContractRadioTableProps
> = ({ control, resetCommercialContractId }) => {
  const date = useWatch({ name: 'date', control });
  const { employee } = useCurrentEmployee();

  const debouncedDate = useDebounce(date, 500);

  const [scope, scopeKey, reactTableProps, { resetPagination }] =
    useBackendTable<CommercialContract>();

  useEffect(resetPagination, [debouncedDate]);
  useEffect(
    resetCommercialContractId,

    [debouncedDate],
  );

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [
      'CommercialContractRadioTable',
      debouncedDate,
      employee,
      scopeKey,
    ],
    enabled: !!debouncedDate && !!employee,
    queryFn: () =>
      scope(
        CommercialContract.where({
          active: { on: debouncedDate },
          ownerId: employee?.id,
          ownerType: 'Employee',
        }).includes('client'),
      ).all(),
  });

  const columns = useMemo(() => buildColumns(), []);

  const currentValue = useWatch({ name: 'commercialContractId', control });

  if (isError) return <div>Error: {JSON.stringify(error)}</div>;

  return (
    <>
      currentValue: {currentValue}
      <RadioTable
        reactTableProps={reactTableProps}
        data={data?.data || []}
        control={control}
        name="commercialContractId"
        valueExtractor={(row) => row.id}
        columns={columns}
        isLoading={isLoading}
        totalCount={data?.meta?.stats.total.count}
      />
    </>
  );
};

type ContractFormValues = Pick<FormState, 'date' | 'commercialContractId'>;

function extractDefaultValues(
  currentFormValues: FormState,
): ContractFormValues {
  return pick(currentFormValues, ['date', 'commercialContractId']);
}

export const ContractForm: FunctionComponent = () => {
  const { currentFormValues, onSubmit } = useFormWizardContext(
    invoiceDraftWizardContext,
  );

  const { handleSubmit, register, control, resetField } =
    useForm<ContractFormValues>({
      defaultValues: extractDefaultValues(currentFormValues),
    });

  const resetCommercialContractId = useCallback(
    () => resetField('commercialContractId'),
    [resetField],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        required
        {...register('date')}
        label="Date"
        placeholder="Date"
        className="mt-6"
      />
      <MainCard header="Choix du contrat">
        <CommercialContractRadioTable
          control={control}
          resetCommercialContractId={resetCommercialContractId}
        />
      </MainCard>

      <FormWizardButtons wizardContext={invoiceDraftWizardContext} />
    </form>
  );
};
