import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useToggleMiniTutorial,
  useToggleTutorial,
} from '../../widgets/hooks/ToggleTutorial';
import { PayslipChoiceTutorial } from '../components/tutorials/PayslipChoiceTutorial';
import { usePayslipFunnelTransition } from '../hooks/usePayslipFunnelTransition';
import { CommonMiniTutorial } from '../components/tutorials/miniTutorials/CommonMiniTutorial';
import { HeaderIconButton } from '../../layout/components/HeaderIconButton';
import { TutorialOnIcon } from '../../../app/assets/icons/tutorial';
import { SalaireIcon } from '../../../app/assets/icons/menu';
import { ButtonPrimary } from '../../common/components/buttons';
import { useSalaryStep, useSelectSalaryStrategy } from './hooks/useSalaryStep';
import type { SalaryStrategy } from '../../../app/spraypaint/types/salaryStrategyTypes';
import { ConventionalMinimumCard } from './components/ConventionalMinimumCard';
import { CustomStcChoiceCard } from './components/CustomStcChoiceCard';
import { StcChoiceCard } from './components/StcChoiceCard';
import { MaxAmountCard } from './components/MaxAmountCard';
import { CustomChoiceCard } from './components/CustomChoiceCard';
import { CompletionErrorType } from '../../common/types/CompletionError';
import { Alert } from '../../common/components/toasts';
import { tutorialContainerCustomClass } from '../constantes/tailwindStyle';
import StepMessages from '../components/StepMessages';

const SalaryStepMiniTutorialOff = ({ onClick }: { onClick: any }) => (
  <HeaderIconButton Icon={TutorialOnIcon} onClick={onClick} />
);

type SalaryStepProps = {
  payslipFunnelId: number;
};

export const SalaryStep = ({ payslipFunnelId }: SalaryStepProps) => {
  const { t } = useTranslation();
  const [strategyChoice, setStrategyChoice] = useState<
    SalaryStrategy | undefined
  >(undefined);

  const {
    data: salaryStep,
    isFetched: salaryStepIsFetched,
    completionErrors,
  } = useSalaryStep(payslipFunnelId, {
    onSuccess: (data) => {
      if (strategyChoice) return;
      setStrategyChoice(data.salaryStrategy);
    },
  });

  const { toggleMiniTutorial, isMiniTutorialOn } = useToggleMiniTutorial();
  const { isTutorialOn, toggleAllTutorial } = useToggleTutorial();
  const [didUserValidateTutorial, setUserValidateTutorial] =
    useState(isTutorialOn);
  const [errorComp, setErrorComp] = useState(false);

  const { mutate: validateSalaryStep, isLoading: mutationLoader } =
    usePayslipFunnelTransition({ payslipFunnelId });

  const onSuccessCallback = () =>
    validateSalaryStep({
      transitionName: 'complete_salary_step',
    });
  const { mutate } = useSelectSalaryStrategy(onSuccessCallback);

  const handleOnClick = () => {
    setUserValidateTutorial(false);
    toggleAllTutorial();
  };

  if (didUserValidateTutorial) {
    return <PayslipChoiceTutorial onClick={handleOnClick} />;
  }

  if (!salaryStepIsFetched || !salaryStep) {
    return null;
  }

  const handleValidateSalaryStep = () => {
    if (!strategyChoice) return; // we can't validate without a strategy
    salaryStep.salaryStrategy = strategyChoice;
    mutate({ salaryStep });
  };

  const handleSetError = (val: boolean) => setErrorComp(val);

  const isDisabled =
    strategyChoice == null ||
    (['custom_stc_salary', 'custom_salary'].includes(strategyChoice.name) &&
      errorComp);

  const { availableStrategiesDescription } = salaryStep;

  return (
    <div className="w-full">
      <div className={tutorialContainerCustomClass}>
        {isMiniTutorialOn ? (
          <CommonMiniTutorial
            Component={
              <p>{t('front.payslip.tdc.salaryChoice.miniTutorial')} </p>
            }
            onClick={toggleMiniTutorial}
          />
        ) : (
          <SalaryStepMiniTutorialOff onClick={toggleMiniTutorial} />
        )}
        <StepMessages step={salaryStep} />
        <ButtonPrimary
          className="w-[210px] ml-3 md:ml-0 mt-2 md:mt-0"
          onClick={handleValidateSalaryStep}
          isLoading={mutationLoader}
          disabled={isDisabled}
        >
          {t('front.payslip.tdc.salaryChoice.button.title')}
        </ButtonPrimary>
      </div>
      {completionErrors?.map((error: CompletionErrorType) => (
        <Alert
          className="my-[10px] ml-[30px]"
          alertType="error"
          text={error.localized_message}
        />
      ))}

      <div className="ml-[30px] mt-[40px]">
        <div className="relative h-[81px] mb-2">
          <div className="w-[81px] h-[81px] rounded-full bg-color-transparent-medium-grey flex justify-center items-center">
            <SalaireIcon width={60} height={60} className="fill-white" />
          </div>
          <div className="absolute bottom-[10px] left-[70px] text-color-medium-grey text-base3">
            {t('front.payslip.tdc.salaryChoice.title')}
          </div>
        </div>

        <div>
          <form className="flex flex-col md:flex-row justify-center md:justify-around md:mr-[50px]">
            <StcChoiceCard
              currentStrategy={strategyChoice}
              onStrategyChange={setStrategyChoice}
              availableStrategiesDescription={availableStrategiesDescription}
            />
            <CustomStcChoiceCard
              currentStrategy={strategyChoice}
              onStrategyChange={setStrategyChoice}
              availableStrategiesDescription={availableStrategiesDescription}
              handleSetError={handleSetError}
            />
            <ConventionalMinimumCard
              currentStrategy={strategyChoice}
              onStrategyChange={setStrategyChoice}
              availableStrategiesDescription={availableStrategiesDescription}
            />
            <MaxAmountCard
              currentStrategy={strategyChoice}
              onStrategyChange={setStrategyChoice}
              availableStrategiesDescription={availableStrategiesDescription}
            />
            <CustomChoiceCard
              currentStrategy={strategyChoice}
              onStrategyChange={setStrategyChoice}
              availableStrategiesDescription={availableStrategiesDescription}
              handleSetError={handleSetError}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
