import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { AmountText } from '../../../common/components/fragments/AmountText';
import { Button } from '../../../common/components/buttons';
import { BtnText } from '../../components/BtnText';
import { MoneyAndCurrency } from '../../../common/utils/type.utils';

export type PayslipFunnelMinusCardProps = {
  title: string;
  isActive: boolean;
  isLoading?: boolean;
  btnText?: string;
  className?: string;
  onClick?: () => void;
  isError?: boolean;
} & MoneyAndCurrency;

export const PayslipFunnelMinusCard = ({
  title,
  amount,
  currencyIsoCode,
  isLoading,
  btnText,
  onClick,
  isActive,
  className,
  isError,
}: PayslipFunnelMinusCardProps) => {
  const customClassName = classNames(
    'group flex flex-col items-center justify-between ' +
      'md:w-[305px] md:h-[120px] px-[35px] py-2 md:py-5 rounded-[12px] ' +
      'fill-black hover:fill-color-pone-orange',
    {
      'bg-color-pastel-orange border-color-pone-orange border-[2px]': isActive,
      'bg-color-bg-warning border-color-red border-[2px]': isError,
      'bg-color-light-grey border-[1px] border-color-medium-light': !isActive,
      'hover:shadow-md hover:shadow-opacity-50 hover:shadow-payslipFunnelCard ease-in-out':
        !isActive && onClick,
      'cursor-default': !onClick,
    },
    className,
  );

  const customTextStyle = classNames('text-color-dark-content uppercase pb-2');

  return (
    <Button
      onClick={onClick}
      className={customClassName}
      custom
      data-testid="button"
    >
      <>
        {isLoading ? (
          <Skeleton count={1} height={16} width={280} />
        ) : (
          <p className={`${customTextStyle} text-sm2 font-cabin-regular`}>
            {title}
          </p>
        )}
        <span className="flex flex-row items-baseline md:items-center md:flex-col">
          {isLoading ? (
            <Skeleton count={1} height={20} width={140} />
          ) : (
            <p
              className={`${customTextStyle} text-xl font-cabin-bold mr-3 md:mr-0`}
            >
              {typeof amount === 'number' ? (
                <AmountText amount={amount} currencyIsoCode={currencyIsoCode} />
              ) : (
                <AmountText amount={amount} />
              )}
            </p>
          )}
          {isLoading ? (
            <Skeleton count={1} height={16} width={100} />
          ) : (
            btnText && (
              <BtnText
                visible={isActive}
                title={btnText}
                customBtnTextStyle="text-color-pone-dark text-sm2 font-cabin-bold pr-1 group-hover:text-color-pone-orange"
                customActiveIconStyle="w-1.5 h-1.5"
              />
            )
          )}
        </span>
      </>
    </Button>
  );
};
