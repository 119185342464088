import { useCallback } from 'react';
import { CustomModalProps } from '@/modules/common/components/fragments/CustomModal';

export const useHandleCloseModal = (
  isSubmitting: boolean,
  modalProps: CustomModalProps,
) =>
  useCallback(
    (...args: Parameters<NonNullable<typeof modalProps.onRequestClose>>) => {
      if (isSubmitting || !modalProps.onRequestClose) return;
      modalProps.onRequestClose(...args);
    },
    [modalProps.onRequestClose, isSubmitting],
  );
