import { Scope, SpraypaintBase } from 'spraypaint';
import { SortingState } from '@tanstack/react-table';
import { useCallback } from 'react';
import { GraphitiScoper } from '../../../../../app/spraypaint/types';

export function useBackendSorting<TData extends SpraypaintBase>(
  sorting: SortingState,
): [scoper: GraphitiScoper<TData>, key: any] {
  const applySorting: GraphitiScoper<TData> = useCallback(
    (baseScope) =>
      sorting.reduce<Scope<TData>>(
        (scope, columnSort) =>
          scope.order({ [columnSort.id]: columnSort.desc ? 'desc' : 'asc' }),
        baseScope,
      ),
    [sorting],
  );

  return [applySorting, sorting];
}
