import { useTranslation } from 'react-i18next';
import {
  ClientAccountScreenshot,
  PortageAgreementScreenshot,
  CommercialContractScreenshot,
  EmploymentContractScreenshot,
  DocumentsScreenshot,
  ExpensesScreenshot,
  MileageExpensesScreenshot,
  InternationalExpensesScreenshot,
  TeleworkingExpensesScreenshot,
  ClientInvoiceScreenshot,
  ParticularInvoiceScreenshot,
  SubcontractorAccountScreenshot,
  SubcontractorInvoiceScreenshot,
  AdvancePaymentScreenshot,
  SalaryScreenshot,
} from '../../../app/assets/videoTutorials';
import { VideoTutorialType } from '../types/VideoTutorialType';

export const videoTutorialItems = (): Array<VideoTutorialType> => {
  const { t } = useTranslation();

  return [
    {
      title: t('front.videoTutorial.card.clientAccount.title'),
      subtitle: t('front.videoTutorial.card.clientAccount.subtitle'),
      description: t('front.videoTutorial.card.clientAccount.description'),
      url: 'https://vimeo.com/759452117',
      image: ClientAccountScreenshot,
    },
    {
      title: t('front.videoTutorial.card.portageAgreement.title'),
      subtitle: t('front.videoTutorial.card.portageAgreement.subtitle'),
      description: t('front.videoTutorial.card.portageAgreement.description'),
      url: 'https://vimeo.com/759528179',
      image: PortageAgreementScreenshot,
    },
    {
      title: t('front.videoTutorial.card.commercialContract.title'),
      subtitle: t('front.videoTutorial.card.commercialContract.subtitle'),
      description: t('front.videoTutorial.card.commercialContract.description'),
      url: 'https://vimeo.com/759485714',
      image: CommercialContractScreenshot,
    },
    {
      title: t('front.videoTutorial.card.employmentContract.title'),
      subtitle: t('front.videoTutorial.card.employmentContract.subtitle'),
      description: t('front.videoTutorial.card.employmentContract.description'),
      url: 'https://vimeo.com/759485882',
      image: EmploymentContractScreenshot,
    },
    {
      title: t('front.videoTutorial.card.documents.title'),
      subtitle: null,
      description: t('front.videoTutorial.card.documents.description'),
      url: 'https://vimeo.com/759485917',
      image: DocumentsScreenshot,
    },
    {
      title: t('front.videoTutorial.card.expenses.title'),
      subtitle: t('front.videoTutorial.card.expenses.subtitle'),
      description: t('front.videoTutorial.card.expenses.description'),
      url: 'https://vimeo.com/759485943',
      image: ExpensesScreenshot,
    },
    {
      title: t('front.videoTutorial.card.mileageExpenses.title'),
      subtitle: t('front.videoTutorial.card.mileageExpenses.subtitle'),
      description: t('front.videoTutorial.card.mileageExpenses.description'),
      url: 'https://vimeo.com/759485963',
      image: MileageExpensesScreenshot,
    },
    {
      title: t('front.videoTutorial.card.internationalExpenses.title'),
      subtitle: t('front.videoTutorial.card.internationalExpenses.subtitle'),
      description: t(
        'front.videoTutorial.card.internationalExpenses.description',
      ),
      url: 'https://vimeo.com/759485987',
      image: InternationalExpensesScreenshot,
    },
    {
      title: t('front.videoTutorial.card.teleworkingExpenses.title'),
      subtitle: t('front.videoTutorial.card.teleworkingExpenses.subtitle'),
      description: t(
        'front.videoTutorial.card.teleworkingExpenses.description',
      ),
      url: 'https://vimeo.com/759486007',
      image: TeleworkingExpensesScreenshot,
    },
    {
      title: t('front.videoTutorial.card.clientInvoice.title'),
      subtitle: t('front.videoTutorial.card.clientInvoice.subtitle'),
      description: t('front.videoTutorial.card.clientInvoice.description'),
      url: 'https://vimeo.com/759486036',
      image: ClientInvoiceScreenshot,
    },
    {
      title: t('front.videoTutorial.card.particularInvoice.title'),
      subtitle: t('front.videoTutorial.card.particularInvoice.subtitle'),
      description: t('front.videoTutorial.card.particularInvoice.description'),
      url: 'https://vimeo.com/759486057',
      image: ParticularInvoiceScreenshot,
    },
    {
      title: t('front.videoTutorial.card.subcontractorAccount.title'),
      subtitle: t('front.videoTutorial.card.subcontractorAccount.subtitle'),
      description: t(
        'front.videoTutorial.card.subcontractorAccount.description',
      ),
      url: 'https://vimeo.com/759486079',
      image: SubcontractorAccountScreenshot,
    },
    {
      title: t('front.videoTutorial.card.subcontractorInvoice.title'),
      subtitle: t('front.videoTutorial.card.subcontractorInvoice.subtitle'),
      description: t(
        'front.videoTutorial.card.subcontractorInvoice.description',
      ),
      url: 'https://vimeo.com/759486103',
      image: SubcontractorInvoiceScreenshot,
    },
    {
      title: t('front.videoTutorial.card.advancePayment.title'),
      subtitle: t('front.videoTutorial.card.advancePayment.subtitle'),
      description: t('front.videoTutorial.card.advancePayment.description'),
      url: 'https://vimeo.com/759486117',
      image: AdvancePaymentScreenshot,
    },
    {
      title: t('front.videoTutorial.card.salary.title'),
      subtitle: t('front.videoTutorial.card.salary.subtitle'),
      description: t('front.videoTutorial.card.salary.description'),
      url: 'https://vimeo.com/762651467',
      image: SalaryScreenshot,
    },
  ];
};
