// api/v2/PayslipFunnel.ts
import { Attr, HasMany, HasOne, Model } from 'spraypaint';
import { Transition } from '@/app/spraypaint';
import ReviewStep from '@spraypaint/onBoarding/ReviewStep';
import IdentityStep from '@spraypaint/onBoarding/IdentityStep';
import CitizenshipStep from '@spraypaint/onBoarding/CitizenshipStep';
import PortageOfferStep from '@spraypaint/onBoarding/PortageOfferStep';
import FinalStep from '@spraypaint/onBoarding/FinalStep';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class OnboardingFunnel extends ApplicationRecord {
  static jsonapiType = 'onboarding_funnels';

  // Attributes
  @Attr({ persist: false }) status: string;

  // Relationships
  @HasMany(Transition) transitions: Transition[];
  @HasOne(IdentityStep) identityStep: IdentityStep;
  @HasOne(CitizenshipStep) citizenshipStep: CitizenshipStep;
  @HasOne(PortageOfferStep) portageOfferStep: PortageOfferStep;
  @HasOne(ReviewStep) reviewStep: ReviewStep;
  @HasOne(FinalStep) finalStep: FinalStep;
}

export default OnboardingFunnel;
