import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import BreakdownLine from '@spraypaint/BreakdownLine';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { MoneyType } from '@/modules/common/utils/type.utils';
import {
  EqualFilter,
  getEqualFilter,
} from '../../../../common/components/table/filter/EqualFilter';
import {
  SupInfFilter,
  getSupInfFilter,
} from '../../../../common/components/table/filter/SupInfFilter';

const columnHelper = createColumnHelper<ModelRecord<BreakdownLine>>();

const invoicesBankTransactionColumns = [
  columnHelper.accessor('invoice.number', {
    header: 'Numero de facture',
    filterFn: getEqualFilter(),
    meta: {
      filter: {
        Component: EqualFilter,
      },
    },
  }),
  columnHelper.accessor('invoice.totalAmountWithVat', {
    header: 'Montant total (TVA)',
    filterFn: getSupInfFilter(),
    meta: {
      filter: {
        Component: SupInfFilter,
      },
    },
    cell: (info) => (
      <div className="text-center">
        <AmountWithCurrency amount={info.getValue()} />
      </div>
    ),
  }),
  columnHelper.accessor('amount', {
    header: 'Montant pointé',
    filterFn: getSupInfFilter(),
    meta: {
      filter: {
        Component: SupInfFilter,
      },
    },
    cell: (info) => (
      <div className="text-center">
        <AmountWithCurrency amount={info.getValue() as MoneyType} />
      </div>
    ),
  }),
  columnHelper.accessor('invoice.client.name', {
    header: 'Nom du client',
    filterFn: getEqualFilter(),
  }),
  columnHelper.accessor('invoice.status', {
    header: 'Status' as string,
    enableColumnFilter: false,
  }),
];

export default invoicesBankTransactionColumns;
