import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactElement, useState } from 'react';
import dayjs from 'dayjs';
import { AmountText } from '../../fragments/AmountText';
import { Button } from '../../buttons';

type MobileTableRowProps = {
  index: number;
  date: string;
  amount: number;
  link?: string;
  label?: string | ReactElement;
  additionalInfo?: ReactElement;
  customClass?: string;
};

export const MobileTableRow = ({
  index,
  date,
  amount,
  link = '',
  label,
  additionalInfo,
  customClass = '',
}: MobileTableRowProps) => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const containerClassName = classNames(
    'flex flex-col justify-between py-2',
    {
      'bg-color-disabled': index % 2 !== 0,
    },
    customClass,
  );

  const handleClick = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  return link ? (
    <Link to={link} className={containerClassName}>
      <div className="flex flex-col">
        <p>{date}</p>
        <AmountText amount={amount} />
      </div>
    </Link>
  ) : (
    <div className={containerClassName}>
      <Button onClick={handleClick} custom>
        <div className="flex flex-col px-3">
          {date && <p className="flex text-sm1">{dayjs(date).format('L')}</p>}
          {label && label}
          {amount && (
            <div className="flex">
              <AmountText amount={amount} />
            </div>
          )}
        </div>
      </Button>
      {showMoreInfo && additionalInfo && (
        <div className="flex pl-8 pt-3">{additionalInfo}</div>
      )}
    </div>
  );
};
