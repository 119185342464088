import { FunctionComponent, useMemo } from 'react';
import { ApplicationRecord, Transition } from '@/app/spraypaint';
import { useTranslation } from 'react-i18next';
import { DisplayValue } from '@spraypaint/Transition';
import {
  ButtonOnClickHandler,
  ButtonReject,
  ButtonSecondary,
  ButtonValidate,
} from '@/modules/common/components/buttons';
import { noDefault } from '@/app/utils/typescriptUtils';
import { useMutation } from '@tanstack/react-query';
import { snakeCase } from 'lodash';

function groupByDisplay(
  transitions: Transition[],
): Record<DisplayValue, Transition[]> {
  return transitions.reduce((acc, transition) => {
    acc[transition.display] = [...(acc[transition.display] ?? []), transition];
    return acc;
  }, {} as Record<DisplayValue, Transition[]>);
}

const TransitionButtonComponent = ({
  display,
  innerProps,
}: {
  display: DisplayValue;
  innerProps: {
    onClick: ButtonOnClickHandler;
    children: React.ReactNode;
    isLoading: boolean;
  };
}) => {
  switch (display) {
    case 'primary': {
      return (
        <ButtonValidate className="ml-3" Icon={() => null} {...innerProps} />
      );
    }
    case 'danger': {
      return (
        <ButtonReject className="ml-3" Icon={() => null} {...innerProps} />
      );
    }
    case 'secondary': {
      return <ButtonSecondary className="ml-3" {...innerProps} />;
    }
    default: {
      noDefault(display);
      return null;
    }
  }
};

const TransitionButton = ({
  transition,
  refresh,
  trans,
}: {
  transition: Transition;
  refresh: () => void;
  trans?: (key: string) => string | undefined;
}) => {
  const { mutateAsync, isLoading } = useMutation({
    mutationKey: [
      'transition',
      transition.id,
      transition.resourceType,
      transition.resourceId,
    ],
    mutationFn: () => transition.triggerTransition(),
  });

  const { t } = useTranslation();

  const defaultTransKey = `activerecord.transitions.${snakeCase(
    transition.resourceType,
  )}.${transition.transitionName}`;
  const translation =
    (trans && trans(transition.transitionName)) ??
    t(defaultTransKey, { ns: 'rails' });

  return (
    <TransitionButtonComponent
      display={transition.display}
      innerProps={{
        onClick: async () => {
          await mutateAsync();
          return refresh();
        },
        children: translation,
        isLoading,
      }}
    />
  );
};

export interface TransitionsHeaderButtonsProps {
  record: ApplicationRecord & {
    transitions: Array<Transition> | null | undefined;
  };
  refresh: () => void;
  trans?: (key: string) => string | undefined;
}

export const TransitionsHeaderButtons: FunctionComponent<
  TransitionsHeaderButtonsProps
> = ({ record, refresh, trans }) => {
  const { transitions } = record;

  if (!transitions) {
    return null;
  }

  const [primaries, danger, secondaries] = useMemo(() => {
    const groupedTransitions = groupByDisplay(transitions);
    const p = groupedTransitions.primary ?? [];
    const d = groupedTransitions.danger ?? [];
    const s = groupedTransitions.secondary ?? [];
    return [p, d, s];
  }, [
    transitions
      .map((tr) => tr.id)
      .sort()
      .join('-'),
  ]);

  return (
    <div className="flex">
      {primaries.map((tr) => (
        <TransitionButton
          key={tr.id}
          transition={tr}
          refresh={refresh}
          trans={trans}
        />
      ))}
      {danger.map((tr) => (
        <TransitionButton
          key={tr.id}
          transition={tr}
          refresh={refresh}
          trans={trans}
        />
      ))}
      {secondaries.map((tr) => (
        <TransitionButton
          key={tr.id}
          transition={tr}
          refresh={refresh}
          trans={trans}
        />
      ))}
    </div>
  );
};
