import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import { Client } from '@/app/spraypaint';

const useRegisteredClientClients = (
  registeredClientId: number,
  scope: (baseScope: Scope<Client>) => Scope<Client>,
  randomProps: unknown,
) => {
  const data = useQuery({
    queryKey: ['registered-client-clients', randomProps],
    queryFn: () =>
      scope(
        Client.where({ registeredClientId })
          .includes(['owner.user', 'owner.portage_offer'])
          .scope(),
      ).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const clients = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, clients, totalCount };
};

export default useRegisteredClientClients;
