import SwitchSelector from 'react-switch-selector';
import classNames from 'classnames';
import { OptionType } from 'react-switch-selector/dist/SwitchSelector.props';

export interface SwitchSelectionProps<ValueType> {
  onChange: (newValue: ValueType | unknown) => void;
  options: Array<OptionType<ValueType>>;
  value?: ValueType;
  disabled?: boolean;
  className?: string;
  fontColor?: string;
  selectedFontColor?: string;
  backgroundColor?: string;
  selectedBackgroundColor?: string;
}

export function SwitchSelection<ValueType>({
  onChange,
  value,
  options,
  disabled = false,
  className,
  fontColor = '#A2A2A2', // color-medium-grey
  selectedFontColor = '#FFFFFF', // white
  backgroundColor = '#F7F7F7', // color-light-grey
  selectedBackgroundColor = '#EF6C35', // color-pone-orange
}: SwitchSelectionProps<ValueType>) {
  const containerClass = classNames('h-[30px] w-[117px]', className);

  let selectedIndex: number | undefined;
  selectedIndex = options.findIndex((option) => option.value === value);
  selectedIndex = selectedIndex === -1 ? undefined : selectedIndex;

  return (
    <div className={containerClass}>
      <SwitchSelector
        onChange={onChange}
        options={options}
        forcedSelectedIndex={selectedIndex}
        fontColor={fontColor}
        selectedFontColor={selectedFontColor}
        backgroundColor={backgroundColor}
        selectedBackgroundColor={selectedBackgroundColor}
        wrapperBorderRadius={0}
        optionBorderRadius={0}
        selectionIndicatorMargin={0}
        disabled={disabled}
      />
    </div>
  );
}
