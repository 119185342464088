import dayjs from 'dayjs';
import ActivityReportSlot from '../../../../app/spraypaint/activityReport/ActivityReportSlot';

export type DateWithActivityReportType =
  | Array<{
      date: Date;
      pm: ActivityReportSlot;
      am: ActivityReportSlot;
    }>
  | undefined;

export type IndexedSlotsType =
  | {
      [date: string]: {
        am: ActivityReportSlot;
        pm: ActivityReportSlot;
      };
    }
  | undefined;

type ReturnType = {
  indexedSlots: IndexedSlotsType;
  dateWithActivityReportSlots: DateWithActivityReportType;
};

export const getActivityReportSlots = (
  dates: Date[],
  activityReportSlots?: ActivityReportSlot[],
): ReturnType => {
  if (activityReportSlots) {
    const indexedSlots = activityReportSlots.reduce(
      (acc: any, item) => ({
        ...acc,
        [item.date]: {
          ...acc[item.date],
          [item.dayPart]: item,
        },
      }),
      {},
    );
    return {
      indexedSlots,
      dateWithActivityReportSlots: dates.map((date) => ({
        date,
        ...indexedSlots[dayjs(date).format('YYYY-MM-DD')],
      })),
    };
  }
  return {
    indexedSlots: undefined,
    dateWithActivityReportSlots: undefined,
  };
};
