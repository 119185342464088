import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { Invoice } from '@/app/spraypaint';
import {
  StrictEqualFilter,
  getStrictEqualFilter,
} from '@/modules/common/components/table/filter/StrictEqualFilter';

const columnHelper = createColumnHelper<ModelRecord<Invoice>>();

const registeredClientInvoicesColumns = () => {
  const { t } = useTranslation();

  return [
    columnHelper.accessor('id', {
      header: 'ID',
      filterFn: getStrictEqualFilter(),
      meta: {
        filter: {
          Component: StrictEqualFilter,
        },
      },
    }),
    columnHelper.accessor('number', {
      header: t('activerecord.attributes.invoice.number') as string,
    }),
    columnHelper.accessor('date', {
      header: t('activerecord.attributes.invoice.date') as string,
    }),
    columnHelper.accessor('dueDate', {
      header: t('activerecord.attributes.invoice.due_date') as string,
    }),
  ];
};

export default registeredClientInvoicesColumns;
