import { LegalEntity } from '@/app/spraypaint';
import { useQuery } from '@tanstack/react-query';

const useLegalEntities = () => {
  const data = useQuery({
    queryKey: ['legal-entities'],
    queryFn: () => LegalEntity.all(),
    refetchOnWindowFocus: false,
  });
  const legalEntities = data?.data?.data || [];

  return { legalEntities, ...data };
};

export default useLegalEntities;
