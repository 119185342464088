import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserState = {
  civility: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  primaryRole: string;
  roles: Array<string>;
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    civility: '',
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    primaryRole: '',
    roles: [''],
  },
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => action.payload,
  },
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
