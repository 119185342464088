import type { FunctionComponent } from 'react';
import { BreakdownLine, Invoice } from '@/app/spraypaint';
import { MainCard } from '@/modules/common/components/mainCard';
import {
  CreateCreditButton,
  PayInvoiceButton,
} from '@/modules/admin/invoices/components/InvoiceActionsButton';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import Table from '@/modules/common/components/table/Table';
import { MoneyType } from '@/modules/common/utils/type.utils';
import { InternalLink } from '@/modules/common/components/buttons';
import { ModelRecord } from 'spraypaint/lib-esm/model';

export interface InvoicePaymentsCardProps {
  invoice: Invoice;
}

function hasStatusWithPayments(invoice: Invoice): boolean {
  return ['paid', 'irrecoverable', 'partially_paid', 'validated'].includes(
    invoice?.status,
  );
}

export interface BreakdownLineWithRemainingAmount
  extends ModelRecord<BreakdownLine> {
  remainingAmount: MoneyType;
}

type ReducedBreakdownLineState = {
  decoratedLines: Array<BreakdownLineWithRemainingAmount>;
  remainingAmount: MoneyType;
};

export function breakdownLineReducer(
  prev: ReducedBreakdownLineState,
  line: ModelRecord<BreakdownLine>,
): ReducedBreakdownLineState {
  const { amount } = line;
  if (!amount) throw new Error('Amount is required');

  const remainingAmount = {
    amount: prev.remainingAmount.amount - amount.amount,
    currency: amount.currency,
  };
  const newDecoratedLine = {
    ...line,
    clonedRemainingAmount: { ...remainingAmount },
  } as unknown as BreakdownLineWithRemainingAmount;

  return {
    decoratedLines: [...prev.decoratedLines, newDecoratedLine],
    remainingAmount,
  };
}

function decorateBreakdownLines(
  breakdownLines: Array<ModelRecord<BreakdownLine>>,
  invoiceAmount: MoneyType,
): Array<BreakdownLineWithRemainingAmount> {
  const reducedState = breakdownLines.reduce(breakdownLineReducer, {
    decoratedLines: [],
    remainingAmount: invoiceAmount,
  });

  return reducedState.decoratedLines;
}

const ch = createColumnHelper<ModelRecord<BreakdownLine>>();
const columns = [
  ch.accessor('clientPayment.date', {
    header: 'Date de paiement',
    enableColumnFilter: false,
  }),
  ch.accessor('clientPayment.createdAtDate', {
    header: 'Date de création',
    cell: ({ getValue }) => getValue()?.toLocaleDateString(),
    enableColumnFilter: false,
  }),
  ch.accessor('amount', {
    header: 'Montant',
    enableColumnFilter: false,
  }),
  ch.accessor('remainingAmount', {
    header: 'Restant',
    enableColumnFilter: false,
  }),
  ch.accessor('clientPayment.sourceableType', {
    header: 'Source',
    enableColumnFilter: false,
    cell({ getValue, row: { original } }) {
      const sourceableType = getValue();
      switch (sourceableType) {
        case 'Invoice':
          return (
            <InternalLink
              to={`/v2/admin/invoices/${original.clientPayment?.sourceableId}`}
            >
              Facture
            </InternalLink>
          );
        case 'Credit':
          return (
            <InternalLink
              to={`/v2/admin/credits/${original.clientPayment?.sourceableId}`}
            >
              Avoir
            </InternalLink>
          );
        default:
          return sourceableType;
      }
    },
  }),
];

export const InvoicePaymentsCard: FunctionComponent<
  InvoicePaymentsCardProps
> = ({ invoice }) => {
  const [scope, scopeKey, reactTableProps] = useBackendTable<BreakdownLine>();

  const { data } = useQuery({
    queryKey: ['breakdownLines', invoice.id, scopeKey],
    queryFn: async () =>
      scope(
        BreakdownLine.where({ invoiceId: invoice.id })
          .includes('clientPayment')
          .order({ id: 'asc' })
          .scope(),
      ).all(),
  });

  if (!hasStatusWithPayments(invoice)) {
    return null;
  }

  if (!data) {
    return null;
  }
  const totalCount = data.meta?.stats?.total?.count;
  const lines = decorateBreakdownLines(data.data, invoice.totalAmountWithVat);

  return (
    <MainCard
      header={{
        title: 'Paiements',
        actionButtons: (
          <div className="flex flex-row ">
            <CreateCreditButton invoice={invoice} />
            <PayInvoiceButton invoice={invoice} />
          </div>
        ),
      }}
    >
      <Table
        data={lines}
        columns={columns}
        reactTableProps={{ ...reactTableProps, enableSorting: false }}
        tableStyle="compact"
        totalCount={totalCount}
        withImageErrorMsg={false}
        emptyTableErrorMsg="Aucun paiement"
      />
    </MainCard>
  );
};
