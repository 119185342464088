import { useQuery } from '@tanstack/react-query';
import Expense from '../../../../app/spraypaint/Expense';
import { PeriodType } from '../../../common/utils/type.utils';

const useRejectedExpenses = ({
  employeeId,
  payslipDate,
}: {
  employeeId: number | string | undefined;
  payslipDate: PeriodType;
}) =>
  useQuery({
    queryKey: [`rejected-expenses-${employeeId}-${payslipDate}`],
    queryFn: () =>
      Expense.where({
        employee_id: employeeId,
        status: 'rejected',
        payslipDate: {
          eq: payslipDate,
        },
      })
        .select(['id', 'date', 'ht_amount', 'refusal_reason'])
        .selectExtra(['translated_nature_or_type'])
        .stats({ total: ['ht_amount', 'count'] })
        .per(100) // I won't code a pagination for this, but you can
        .all(),
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
export default useRejectedExpenses;
