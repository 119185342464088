import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { SubcontractorBill } from '@/app/spraypaint';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import {
  SupInfFilter,
  getSupInfFilter,
} from '@/modules/common/components/table/filter/SupInfFilter';
import { MoneyType } from '@/modules/common/utils/type.utils';
import {
  PeriodFilter,
  TextFilter,
  getPeriodFilterFn,
} from '@/modules/common/components/table/filter';

const columnHelper = createColumnHelper<ModelRecord<SubcontractorBill>>();

const subcontractorBillsIndexColumns = () => {
  const { t } = useTranslation();

  return [
    columnHelper.accessor('date', {
      header: t('activerecord.attributes.subcontractor_bill.date'),
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    columnHelper.accessor('number', {
      header: t('activerecord.attributes.subcontractor_bill.number'),
      meta: {
        filter: {
          Component: TextFilter,
        },
      },
    }),
    columnHelper.accessor('externalReference', {
      header: t(
        'activerecord.attributes.subcontractor_bill.external_reference',
      ),
      meta: {
        filter: {
          Component: TextFilter,
        },
      },
    }),
    columnHelper.accessor('subcontractor.name', {
      enableSorting: false,
      header: t('activerecord.attributes.subcontractor_bill.subcontractor'),
      meta: {
        filter: {
          Component: TextFilter,
        },
      },
    }),
    columnHelper.accessor('dueDate', {
      header: t('activerecord.attributes.subcontractor_bill.due_date'),
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    columnHelper.accessor('paymentDate', {
      header: t('activerecord.attributes.subcontractor_bill.payment_date'),
      filterFn: getPeriodFilterFn(),
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    columnHelper.accessor('subcontractor.accountNumber', {
      enableSorting: false,
      header: 'Code tiers',
      meta: {
        filter: {
          Component: TextFilter,
        },
      },
    }),
    columnHelper.accessor('htAmount', {
      header: t('activerecord.attributes.subcontractor_bill.ht_amount'),
      filterFn: getSupInfFilter(),
      meta: {
        filter: {
          Component: SupInfFilter,
        },
      },
      cell: (info) => (
        <div className="text-center">
          <AmountWithCurrency amount={info.getValue() as MoneyType} />
        </div>
      ),
    }),
    columnHelper.accessor('ttcAmount', {
      header: t('activerecord.attributes.subcontractor_bill.ttc_amount'),
      filterFn: getSupInfFilter(),
      meta: {
        filter: {
          Component: SupInfFilter,
        },
      },
      cell: (info) => (
        <div className="text-center">
          <AmountWithCurrency amount={info.getValue() as MoneyType} />
        </div>
      ),
    }),
    columnHelper.accessor(
      (o) => (o.status === 'in_progress' ? 'draft' : o.status),
      {
        id: 'status',
        header: t('default.status'),
        enableColumnFilter: false,
      },
    ),
  ];
};

export default subcontractorBillsIndexColumns;
