import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';
import { ReportProblemIc } from '../../../app/assets/icons/others';
import { formatNumberWithCurrency } from '../../common/utils/maths.utils';

export type Props = {
  amount: number;
  number: number;
  href: string | null;
  isLoading?: boolean;
  information?: string;
  numberText: string;
  title: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const WarningAmountsCard = ({
  amount,
  number,
  href,
  isLoading = false,
  numberText,
  title,
  information,
  className,
  onClick,
}: Props) => {
  const { t } = useTranslation();
  const hyperTextClassName = classNames(
    'text-sm2 hover:underline text-color-blue active:text-color-light-blue',
  );
  const customClassName = classNames('flex md:h-[74px] md:ml-2', className);
  if (isLoading || number === 0 || number === undefined) return null;

  const renderInformation = () => {
    const informationElement = (
      <p className="text-sm2 font-bold md:w-[107px] md:ml-[20px]">
        {t(information || '')}
      </p>
    );
    const arrowIcon = <p className="mx-[10px]">›</p>;

    if (onClick) {
      return (
        <button
          onClick={onClick}
          type="button"
          className="flex hover:text-color-pone-orange"
        >
          {informationElement}
          {arrowIcon}
        </button>
      );
    }

    return (
      <>
        {informationElement}
        {arrowIcon}
      </>
    );
  };
  return (
    <div className={customClassName}>
      <div className="flex items-center pl-[14px] pr-[11px] py-[25px] bg-color-bg-warning border-r-2 border-color-warning rounded-l-lg">
        <ReportProblemIc
          height={24}
          width={24}
          className="fill-color-warning"
        />
        {information && renderInformation()}
      </div>
      <div className="flex flex-col ml-3 p-2 md:p-0">
        <p className="text-sm1 md:text-base text-color-warning uppercase">
          {t(title)}
        </p>
        <p>{formatNumberWithCurrency(amount, '€')}</p>
        {href ? (
          <a className={hyperTextClassName} href={href}>
            {t(numberText, { number })} ›
          </a>
        ) : (
          <span>{t(numberText, { number })}</span>
        )}
      </div>
    </div>
  );
};
