import { useQuery } from '@tanstack/react-query';
import type { BreakdownLine } from '@/app/spraypaint';
import { GraphitiScoper } from '@spraypaint/types';
import SepaTransaction from '../../../../../app/spraypaint/sepaTransactions/SepaTransaction';

export const useGetBankReconciliationInformationScope = (
  id: string,
  {
    scope,
    args,
  }: { scope: GraphitiScoper<SepaTransaction>; args: Array<string> },
) => {
  const data = useQuery({
    queryKey: ['get-bank-reconciliation-invoices', id, ...args],
    queryFn: () =>
      scope(SepaTransaction.scope())
        .includes(['client_payment.breakdown_lines.invoice.client'])
        .where({ reconciliable: true })
        .find(id),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });
  const breakDownLines: BreakdownLine[] | undefined =
    data?.data?.data?.clientPayment?.breakdownLines;
  const totalCount = data?.data?.meta.stats.total.count;

  return { breakDownLines, totalCount, ...data };
};
