import { UseMutateFunction } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import mixpanel, { Dict } from 'mixpanel-browser';
import { useEffect } from 'react';
import { ReportProblemIc } from '../../../../app/assets/icons/others';
import { PayslipFunnelContextChangeMessage } from '../../../../app/spraypaint';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../../../common/components/buttons';
import { CustomModal } from '../../../common/components/fragments/CustomModal';
import { PayslipFunnelStepMapping } from '../../../payslip/types/PayslipIndex';
import { useCurrentEmployeeId } from '../../../../app/hooks/useCurrentEmployeeId';

type Props = {
  isOpen: boolean;
  onGoBackClick: (transitionName: string) => void;
  contextChangeMessages: PayslipFunnelContextChangeMessage;
  onCancelClick: UseMutateFunction<void, unknown, string, unknown>;
  isCancelLoading: boolean;
  isGoBackLoading: boolean;
};

export const EventModal = ({
  isOpen,
  contextChangeMessages,
  onCancelClick,
  isGoBackLoading,
  isCancelLoading,
  onGoBackClick,
}: Props) => {
  const { t } = useTranslation();
  const currentEmployee = useCurrentEmployeeId();

  const handleMixPanelEventTdsTracking =
    (name: string, extramParams?: Dict) => () => {
      mixpanel.track(name, {
        employee_id: currentEmployee,
        payslip_funnel_id: contextChangeMessages?.payslipFunnelId,
        i18n_key: contextChangeMessages?.i18nKey,
        message_id: contextChangeMessages?.id,
        message_created_at: contextChangeMessages?.createdAt,
        contextChangeMessages: contextChangeMessages?.targetStep,
        ...extramParams,
      });
    };

  useEffect(() => {
    if (isOpen)
      handleMixPanelEventTdsTracking('TDS: Message Displayed', {
        message_type: contextChangeMessages?.messageType,
      })();
  }, [isOpen]);

  const isInformationType =
    contextChangeMessages?.messageType === 'information';

  return (
    <CustomModal isOpen={isOpen}>
      <div className="flex flex-col w-[950px] whitespace-pre-line">
        <div className="flex h-[62px] justify-center items-center bg-color-bg-warning">
          <div className="flex items-center">
            <ReportProblemIc
              className="mr-[10px] fill-color-warning"
              height={23}
              width={23}
            />
            <p className="text-color-warning text-base2">
              {t('front.payslip.tdc.eventModal.title', {
                step: t(
                  PayslipFunnelStepMapping[
                    contextChangeMessages?.targetStep as keyof typeof PayslipFunnelStepMapping
                  ],
                ),
              })}
            </p>
          </div>
        </div>
        <div className="ml-[43px] mr-[127px] mt-[38px] text-sm2">
          <p>{t('front.payslip.tdc.eventModal.body')}</p>

          <br />
          <p>
            {t('front.payslip.tdc.eventModal.event', {
              event: contextChangeMessages?.localizedMessage,
            })}
          </p>
        </div>

        <div className="flex justify-center mt-[60px] mb-[30px]">
          {isInformationType && (
            <ButtonSecondary
              onClick={() => {
                handleMixPanelEventTdsTracking(
                  'TDS: Information Message Followed',
                );
                onCancelClick(contextChangeMessages?.id as string);
              }}
              isLoading={isCancelLoading}
            >
              {t('front.payslip.tdc.eventModal.secondaryButton')}
            </ButtonSecondary>
          )}
          <div className="mx-[30px]" />
          <ButtonPrimary
            isLoading={isInformationType ? isGoBackLoading : isCancelLoading}
            onClick={() => {
              handleMixPanelEventTdsTracking(
                'TDS: Information Message Discarded',
              );
              if (isInformationType)
                onGoBackClick(contextChangeMessages?.targetStep as string);
              else onCancelClick(contextChangeMessages?.id as string);
            }}
          >
            {isInformationType
              ? t('front.payslip.tdc.eventModal.primarybutton', {
                  step: t(
                    PayslipFunnelStepMapping[
                      contextChangeMessages?.targetStep as keyof typeof PayslipFunnelStepMapping
                    ],
                  ),
                })
              : 'Ok'}
          </ButtonPrimary>
        </div>
      </div>
    </CustomModal>
  );
};
