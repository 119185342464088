import classNames from 'classnames';
import { StepCheckIcon } from '../../../app/assets/icons/buttons';
import { StepperIconProps } from './types/StepperTypes';
import useBreakpoints from '../../../app/responsive/useBreakPoints';

export const StepperIcon = ({
  hasBeenDone,
  stepLabel,
  index,
  onClick,
  isFirst,
  isCurrent,
  vertical,
  className,
  labelClassName,
  readonly,
  isLoading,
}: StepperIconProps) => {
  const { isXs } = useBreakpoints();
  const customClassName = classNames(
    'rounded-full h-[24px] w-[24px] flex items-center justify-center border-[1px]',
    {
      'mr-[10px]': isFirst && !vertical,
      'mx-[10px]': !vertical && !isFirst,
    },
    className,
  );
  const stepCheckIconClassName = classNames(
    'h-[24px] w-[24px] fill-color-pone-orange',
    {
      'mr-[10px]': !vertical && isFirst,
      'mx-[10px]': !vertical && !isFirst,
    },
  );
  const customStepLabelClassName = classNames(
    'text-base2',
    {
      'mr-[10px]': !vertical,
      'ml-[8px]': vertical,
    },
    labelClassName,
  );

  const isClickable = readonly || hasBeenDone || (isXs && isCurrent);

  const buttonClassName = classNames('flex items-center', {
    'cursor-default': !isClickable,
    'justify-center': isXs,
  });

  return (
    <button
      key={stepLabel}
      type="button"
      disabled={isLoading}
      onClick={onClick && isClickable ? onClick(index) : undefined}
      className={buttonClassName}
    >
      {hasBeenDone ? (
        <StepCheckIcon key={stepLabel} className={stepCheckIconClassName} />
      ) : (
        <div key={stepLabel} className={customClassName}>
          {index + 1}
        </div>
      )}
      <p className={customStepLabelClassName}>{stepLabel}</p>
    </button>
  );
};
