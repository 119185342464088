import { useTranslation } from 'react-i18next';

export const DayOfTheWeek = () => {
  const { t } = useTranslation();
  return [
    t('front.payslip.tdc.cra.calendar.title.monday'),
    t('front.payslip.tdc.cra.calendar.title.tuesday'),
    t('front.payslip.tdc.cra.calendar.title.wednesday'),
    t('front.payslip.tdc.cra.calendar.title.thursday'),
    t('front.payslip.tdc.cra.calendar.title.friday'),
    t('front.payslip.tdc.cra.calendar.title.saturday'),
    t('front.payslip.tdc.cra.calendar.title.sunday'),
  ];
};
