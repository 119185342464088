import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonOnClickHandler,
} from '../../../common/components/buttons';

type Props = {
  onClick?: ButtonOnClickHandler;
  display?: boolean;
  isLast?: boolean;
};

export const VerticalLine = ({ onClick, display, isLast }: Props) => {
  const buttonCustomClassName = classNames(
    'h-[24px] w-[24px] rounded-full  text-white flex items-center justify-center',
    {
      'bg-color-medium-grey': display,
      'bg-color-pone-orange': !display,
    },
  );
  const { t } = useTranslation();
  if (!onClick)
    return (
      <div className="relative flex flex-col items-center">
        <div
          className="h-[60px] w-[1px] bg-color-medium-grey"
          role="presentation"
          aria-labelledby="line"
        />
        {isLast && <i className="arrow down absolute bottom-[1px]" />}
      </div>
    );
  return (
    <div className="flex flex-col items-center relative">
      <div
        className="h-[30px] w-[1px] bg-color-medium-grey"
        role="presentation"
        aria-labelledby="line"
      />
      <div className="flex items-center relative">
        <Button custom onClick={onClick} className={buttonCustomClassName}>
          <p className="mb-[4px]  text-xl4">{display ? '-' : '+'}</p>
        </Button>
        <p className="text-color-pone-dark text-sm2 font-cabin-bold absolute left-[30px]">
          {t('front.payslip.tdc.intermediateSummary.summaryCard.details')}
        </p>
      </div>
      <div className="h-[30px] w-[1px] bg-color-medium-grey" />
      {!display && <i className="arrow down absolute bottom-[1px]" />}
    </div>
  );
};
