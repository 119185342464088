import { useQuery } from '@tanstack/react-query';
import { ActivityReport } from '../../../../app/spraypaint';

export const useGetCraActivityReportSlot = (payslipFunnelId: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ['craActivityReportSlot', payslipFunnelId],
    cacheTime: 0,
    retry: 0,
    queryFn: () =>
      ActivityReport.includes(['activityReportSlots'])
        .where({
          payslipFunnelId,
        })
        .all(),
  });
  const activityReportSlots = data?.data[0]?.activityReportSlots;

  return {
    data,
    isLoading,
    activityReportSlots,
  };
};
