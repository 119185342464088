// Ensure that the user is not authenticated and redirect it to `next` or
// its homepage if already logged-in
import { RoutesMounterArgs } from '@/types';
import User from '@spraypaint/User';
import { computeAfterLoginRedirect } from '@/modules/authentification/hooks/useRedirectAfterLogin';
import { redirect } from 'react-router-dom';

export const buildUnauthenticatedLoader =
  ({ queryClient }: RoutesMounterArgs['tools']) =>
  async () => {
    try {
      const userProxy = await queryClient.ensureQueryData({
        queryKey: ['user-id'],
        queryFn: () => User.find('current'),
        retry: 0,
        cacheTime: 0,
      });

      const user = userProxy?.data;

      if (user) {
        const dest = computeAfterLoginRedirect();
        if (dest.startsWith('/v2')) {
          return redirect(dest);
        }

        window.location.href = dest;
        return null;
      }

      return null;
    } catch (error: unknown) {
      if (
        typeof error === 'object' &&
        error !== null &&
        'response' in error &&
        typeof error.response === 'object' &&
        error.response !== null &&
        'status' in error.response &&
        error.response.status === 401
      ) {
        return null;
      }

      throw error;
    }
  };
