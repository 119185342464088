import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { HypertextLink } from '../../common/components/buttons';
import { Button } from '../../common/components/buttons/Button';
import { NotificationList } from '../components/NotificationList';

import {
  NotificationsInbox,
  NotificationsMessage,
} from '../../../app/spraypaint';
import { toggleOneSignalPrompt } from '../functions/toggleOneSignalPrompt';
import {
  useGetNotificationsMessages,
  useGetUnreadNotificationsMessages,
  usePatchAllReadNotifications,
} from '../hooks/useGetNotificationsInbox';

type Props = {
  notificationsInboxData?: NotificationsInbox;
};

export const NotificationDropDown: FC<Props> = ({ notificationsInboxData }) => {
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const { t } = useTranslation();
  const { mutate } = usePatchAllReadNotifications({
    inboxId: notificationsInboxData?.id,
  });

  const notificationsQuery = useGetNotificationsMessages({
    inboxId: notificationsInboxData?.id as string,
  });
  const unReadNotifications = useGetUnreadNotificationsMessages({
    inboxId: notificationsInboxData?.id as string,
  });

  const unreadNotificationsLoader = unReadNotifications.isLoading;
  const notifications = notificationsQuery.data;
  const notificationsLoader = notificationsQuery.isLoading;
  const isFetchingAllNotificationNextPage =
    notificationsQuery.isFetchingNextPage;
  const isFetchingAllUnreadNotificationNextPage =
    unReadNotifications.isFetchingNextPage;

  const unreadNotifications = unReadNotifications.data;
  const hasAllNotificationsNextPage = notificationsQuery.hasNextPage;
  const { fetchNextPage } = notificationsQuery;
  const hasUnreadNotificationsNextPage = unReadNotifications.hasNextPage;

  const fetchUnreadNotificationsNextPage = unReadNotifications.fetchNextPage;

  const handleDisplaAllNotifications = () => setShowAllNotifications(true);
  const handleShowUnreadNotifications = () => setShowAllNotifications(false);

  const handleOnReadNotifications = () => {
    mutate();
  };

  const allNotificationsButtonClassName = classNames('flex-1 h-[50px]', {
    'bg-color-light-grey text-color-black border-b-[2px] border-b-color-pone-orange':
      showAllNotifications,
  });

  const allUnreadNotificationsButtonClassName = classNames('flex-1 h-[50px]', {
    'bg-color-light-grey text-color-black border-b-[2px] border-b-color-pone-orange':
      !showAllNotifications,
  });

  useEffect(() => {
    toggleOneSignalPrompt();
  }, []);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;
    if (bottom) {
      if (
        showAllNotifications &&
        !isFetchingAllNotificationNextPage &&
        hasAllNotificationsNextPage
      ) {
        fetchNextPage();
      } else if (
        !showAllNotifications &&
        hasUnreadNotificationsNextPage &&
        !isFetchingAllUnreadNotificationNextPage
      ) {
        fetchUnreadNotificationsNextPage();
      }
    }
  };

  return (
    <div
      onScroll={handleScroll}
      className="w-[350px] h-[600px] bg-white absolute z-50 bottom-[-590px] left-[35px] shadow-card2 overflow-y-scroll"
    >
      <div className="flex justify-between h-[40px] px-[5px]">
        <p className="text-color-medium-grey text-xl self-center">
          {t('front.notifications.dropdown.title')}
        </p>
        <HypertextLink
          onClick={handleOnReadNotifications}
          className="self-center"
        >
          {t('front.notifications.dropdown.buttons.allRead')}
        </HypertextLink>
      </div>
      <div className="flex">
        <Button
          onClick={handleShowUnreadNotifications}
          custom
          className={allUnreadNotificationsButtonClassName}
        >
          {t('front.notifications.dropdown.show.unread')}
        </Button>
        <Button
          onClick={handleDisplaAllNotifications}
          custom
          className={allNotificationsButtonClassName}
        >
          {t('front.notifications.dropdown.show.all')}
        </Button>
      </div>
      <NotificationList
        unreadNotificationsLoader={unreadNotificationsLoader}
        notifications={notifications as Array<NotificationsMessage>}
        notificationsLoader={notificationsLoader}
        isFetchingAllNotificationNextPage={isFetchingAllNotificationNextPage}
        showAllNotifications={showAllNotifications}
        isFetchingAllUnreadNotificationNextPage={
          isFetchingAllUnreadNotificationNextPage
        }
        unreadNotifications={unreadNotifications as Array<NotificationsMessage>}
      />
    </div>
  );
};
