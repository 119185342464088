import { Invoice } from '@/app/spraypaint';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import Status from '@/modules/common/components/statuses/Status';

export const InvoiceCard = ({ candidate }: { candidate: Invoice }) => (
  <>
    <div className="flex flex-col">
      <div>
        <p>
          <span className="font-cabin-bold">Numéro : </span>
          {candidate.number}
        </p>
      </div>
      <p>
        <span className="font-cabin-bold">Client : </span>
        {candidate.clientId} - {candidate.client?.name}
      </p>
      {candidate.debtorType !== 'Client' && (
        <p>
          <span className="font-cabin-bold">
            Débiteur ({candidate.debtorType}) :{' '}
          </span>
          {candidate.debtorId} - {candidate.debtor?.name}
        </p>
      )}
    </div>
    <div className="flex flex-col">
      <p className="flex justify-center">
        <span className="font-cabin-bold">Statut : </span>
        <Status statusType={candidate.status} className="ml-2" />
      </p>
      <p>
        <span className="font-cabin-bold">Montant TTC : </span>
        <AmountWithCurrency amount={candidate.totalAmountWithVat} />
        {candidate.totalAmount.currency !== 'EUR' && (
          <>
            {' '}
            (
            <AmountWithCurrency
              amount={candidate.cacheExchangeValueEuroTotalAmountWithVat}
            />
            )
          </>
        )}
      </p>

      {candidate.status === 'partially_paid' && (
        <p>
          <span className="font-cabin-bold">Reste à payer : </span>
          <AmountWithCurrency amount={candidate.remainingAmount} />
          {candidate.totalAmount.currency !== 'EUR' && (
            <>
              {' '}
              (
              <AmountWithCurrency amount={candidate.cacheRemainingAmountEuro} />
              )
            </>
          )}
        </p>
      )}
    </div>
  </>
);
