import type { FunctionComponent } from 'react';
import { FaCopy } from 'react-icons/fa6';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

export interface CopyButtonProps {
  value: string | undefined | null;
}

// Copy the value in clipboard on click
// Display a tooltip on hover
// Change tooltip to copied on click
// Use FaCopy icon and useCallback for performance
export const CopyButton: FunctionComponent<CopyButtonProps> = ({ value }) => {
  const copy = useCallback(async () => {
    if (value === undefined || value === null) return;
    await navigator.clipboard.writeText(value);
    toast.success('Copié dans le presse-papier');
  }, [value]);

  if (value === undefined || value === null) return null;

  return (
    <button
      type="button"
      className="ml-1 opacity-80 hover:opacity-100"
      onClick={copy}
      title="Copier dans le presse-papier"
    >
      <FaCopy />
    </button>
  );
};
