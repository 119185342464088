// Import react-circular-progressbar module and styles
import classNames from 'classnames';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export type StepCounterProps = {
  currentStep: number;
  totalStep: number;
  className?: string;
};

export const StepCounter = ({
  currentStep,
  totalStep,
  className,
}: StepCounterProps) => {
  const containerClassName = classNames('w-[85px] h-[85px]', className);
  return (
    <div className={containerClassName}>
      <CircularProgressbarWithChildren
        className="z-30"
        value={(currentStep * 100) / totalStep}
        styles={buildStyles({
          pathColor: '#EF6C35',
          trailColor: '#E9E9E9',
        })}
        strokeWidth={5}
      >
        <div className="bg-color-dark-green flex w-[79px] h-[79px] rounded-full items-center justify-center">
          <p className="text-white flex whitespace-pre-wrap">
            <span className="text-huge">{currentStep}</span>
            <span className="text-lg mt-[8px] ml-[5px]">/{totalStep}</span>
          </p>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
};
