// api/Opco.ts
import { Model, Attr } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';

@Model()
class Opco extends ApplicationRecord {
  static jsonapiType = 'opcos';

  // Attributes
  @Attr({ persist: false }) name!: string;
  @Attr({ persist: false }) sendingType!: string;
  @Attr({ persist: false }) invoiceEmails!: Array<string>;
  @Attr({ persist: false }) siren!: string;
  @Attr({ persist: false }) vat!: string;
  @Attr({ persist: false }) accountNumber!: string;

  // Relationships
}

export default Opco;
