import dayjs from 'dayjs';
import { createColumnHelper } from '@tanstack/react-table';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import FactorTransfer from '@/app/spraypaint/factor/FactorTransfer';
import { dynT } from '@/app/translation/utils/dynT';

const columnHelper = createColumnHelper<ModelRecord<FactorTransfer>>();

export const factorTransfersColumns = [
  columnHelper.accessor('id', {
    header: 'ID',
  }),
  columnHelper.accessor('factoringContract.legalEntity.name', {
    header: dynT('activerecord.models.legal_entity'),
  }),
  columnHelper.accessor('factoringContract.bankAccount.accountHolder', {
    header: dynT('activerecord.models.bank_account'),
  }),
  columnHelper.accessor('totalInvoiceAmount', {
    header: dynT(
      'activerecord.attributes.factor.transfer.total_invoice_amount',
    ),
  }),
  columnHelper.accessor('totalCreditAmount', {
    header: dynT('activerecord.attributes.factor.transfer.total_credit_amount'),
  }),
  columnHelper.accessor('customTransferredsCount', {
    header: dynT('activerecord.attributes.factor.transfer.transferreds_count'),
    cell: (cell) => {
      const { original } = cell.row;
      return (
        <div className="text-center">
          <p>{Math.trunc(original.transferredsCount || 0)}</p>
        </div>
      );
    },
    id: 'transferredsCount',
  }),
  columnHelper.accessor('createdAt', {
    header: dynT('activerecord.attributes.factor.transfer.created_at'),
    cell: (info) => (
      <div className="text-left">
        <p>{dayjs(info.getValue()).format('L - H:mm')}</p>
      </div>
    ),
  }),
];
