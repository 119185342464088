import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCurrentEmployeeId } from '../../../../app/hooks/useCurrentEmployeeId';
import Invoice from '../../../../app/spraypaint/Invoice';
import IncomeSummary from '../../../../app/spraypaint/payslipFunnel/IncomeSummary';
import RevenueSummary from '../../../../app/spraypaint/RevenueSummary';
import { useBackendTable } from '../../../common/components/table/hooks';
import PayslipFunnelInvoicesStep from '../../../../app/spraypaint/payslipFunnel/PayslipFunnelInvoicesStep';

export const useInvoicesStep = ({
  payslipFunnelId,
  extraFields,
  fields = ['id', 'completionErrors'],
}: {
  payslipFunnelId: number;
  extraFields: string[];
  fields?: string[];
}) => {
  const { data, refetch } = useQuery({
    queryKey: ['invoicesStep', payslipFunnelId],
    cacheTime: 0,
    retry: 0,
    queryFn: () =>
      PayslipFunnelInvoicesStep.where({
        payslip_funnel_id: payslipFunnelId,
      })
        .includes(['invoices_to_be_defunded'])
        .selectExtra(extraFields)
        .select(fields)
        .per(1)
        .all()
        .then((res) => res.data[0]),
  });
  return { invoicesStep: data, refetch };
};

export const useRevenueSummaryData = (
  display: string,
  payslipFunnelId: number,
) => {
  const currentEmployee = useCurrentEmployeeId();
  const date = dayjs(undefined).format('YYYY-MM-DD');

  const { data: invoicesStepId } = useQuery({
    queryKey: ['invoicesStepId', payslipFunnelId],
    cacheTime: 0,
    retry: 0,
    queryFn: () =>
      PayslipFunnelInvoicesStep.where({
        payslip_funnel_id: payslipFunnelId,
      })
        .all()
        .then((res) => {
          const invoicesStep = res.data[0];
          return invoicesStep?.id;
        }),
  });

  const [scope, scopeKey, revenueSummaryProps] =
    useBackendTable<RevenueSummary>();
  const [incomeScope, incomeScopeKey, incomeProps] =
    useBackendTable<IncomeSummary>();
  // request to get stats for TOTAL CA
  const { data: caStat, isLoading: caStatLoader } = useQuery({
    queryKey: ['CAStats', currentEmployee, invoicesStepId],
    cacheTime: 0,
    retry: 0,
    enabled: !!invoicesStepId,
    queryFn: () =>
      RevenueSummary.stats({
        total: 'total_amount_euro',
      })
        .where({
          only_validated: true,
          owner_id: currentEmployee,
          owner_type: 'Employee',
          invoices_step_id: invoicesStepId,
        })
        .all(),
  });
  // request to get stats for TOTAL DES REVENUS ENCAISSE
  const { data: revenueStat, isLoading: revenueStatLoader } = useQuery({
    queryKey: ['revenueSummaryStats', currentEmployee, invoicesStepId],
    cacheTime: 0,
    retry: 0,
    enabled: !!invoicesStepId,
    queryFn: () =>
      IncomeSummary.stats({
        total: 'paid_amount_euro',
      })
        .where({
          owner_id: currentEmployee,
          owner_type: 'Employee',
          invoices_step_id: invoicesStepId,
        })
        .all(),
  });
  // request to get data for overdue paiments
  const { data: overduePaymentStat, isLoading: overduePaymentLoader } =
    useQuery({
      queryKey: ['OverduePaymentStats', invoicesStepId],
      cacheTime: 0,
      retry: 0,
      enabled: !!invoicesStepId,

      queryFn: () =>
        Invoice.stats({
          total: 'count,cache_remaining_amount_euro',
        })
          .where({
            securitis: false,
            employee_id: currentEmployee,
            cache_remaining_amount_euro: {
              gt: 0,
            },
            due_date: {
              lt: date,
            },
            status: { not_eq: 'irrecoverable' },
          })
          .all(),
    });
  // request to get data for TOTAL CA table
  const { data: revenueSummaryTableData, isLoading: revenueSummaryLoader } =
    useQuery({
      queryKey: [
        'revenueSummaryTable',
        scopeKey,
        display,
        currentEmployee,
        invoicesStepId,
      ],
      enabled: !!invoicesStepId,
      queryFn: () =>
        scope(
          RevenueSummary.select([
            'date',
            'number',
            'externalReference',
            'clientName',
            'totalAmount',
            'sendDate',
            'status',
          ]).scope(),
        )
          .where({
            only_validated: true,
            owner_id: currentEmployee,
            owner_type: 'Employee',
            invoices_step_id: invoicesStepId,
          })
          .all(),
    });
  // request to get data for TOTAL DES REVENUS ENCAISSE table
  const { data: incomeSummaryTableData, isLoading: tableIncomeLoading } =
    useQuery({
      queryKey: [
        'incomeSummaryTable',
        incomeScopeKey,
        display,
        currentEmployee,
        invoicesStepId,
      ],
      enabled: !!invoicesStepId,
      queryFn: () =>
        incomeScope(
          IncomeSummary.select([
            'date',
            'number',
            'externalReference',
            'clientName',
            'paidAmount',
            'sendDate',
            'dueDate',
            'status',
            'lastPaymentDate',
          ]).scope(),
        )
          .where({
            owner_id: currentEmployee,
            owner_type: 'Employee',
            invoices_step_id: invoicesStepId,
          })
          .all(),
    });

  return {
    caStat,
    caStatLoader,
    revenueStat,
    revenueStatLoader,
    overduePaymentStat,
    overduePaymentLoader,
    revenueSummaryTableData,
    revenueSummaryLoader,
    incomeSummaryTableData,
    tableIncomeLoading,
    revenueSummaryProps,
    incomeProps,
  };
};
