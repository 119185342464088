import { Attr, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class CitizenshipStep extends ApplicationRecord {
  // Attributes
  static jsonapiType = 'onboarding_funnel_steps-citizenship_steps';
  static endpoint = '/onboarding_funnel_steps/citizenship_steps';

  // Attributes
  @Attr() countryId: number;
  @Attr() addressNumber: string;
  @Attr() addressRoad: string;
  @Attr() addressCity: string;
  @Attr() addressZipcode: string;
  @Attr() addressCountry: string;
  @Attr() addressCountryCode: string;
  @Attr() address: string;
}
export default CitizenshipStep;
