import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainCard } from '@/modules/common/components/mainCard';
import { PageLayout } from '@/modules/common/components/PageLayout';
import { TextInput } from '@/modules/common/components/inputs';
import { useForm } from 'react-hook-form';
import { ControlledSelect } from '@/modules/common/components/inputs/controlledInput/ControlledSelect';
import { ButtonPrimary } from '@/modules/common/components/buttons';
import { ControlledNumberInput } from '@/modules/common/components/inputs/controlledInput/ControlledNumberInput';
import { useMutation } from '@tanstack/react-query';
import { useCurrentUser } from '@/modules/authentification/hooks';
import useCivilities from '@/modules/common/hooks/useCivilities';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Toaster } from '@/modules/common/components/toasts';
import { PortageOfferFields } from '@/modules/welcome/components/portage_offer_step/PortageOfferFields';
import useHealthInsurancePackages from '../hooks/useHealthInsurancePackages';
import useSecuritisOptions from '../hooks/useSecuritisOptions';
import useEmploymentTypes from '../hooks/useEmploymentTypes';
import useEmploymentStatusOptions from '../hooks/useEmploymentStatusOptions';
import useOptimizationOptions from '../hooks/useOptimizationOptions';
import useWorkingTimeUnitOptions from '../hooks/useWorkingTimeUnitOptions';
import {
  PayslipSimulationPayloadType,
  useCreatePayslipSimulation,
} from '../hooks/useCreatePayslipSimulation';

export const SimulationNew: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useForm();
  const {
    register,
    formState: { isSubmitting },
    handleSubmit,
    control,
    getValues,
  } = form;

  const [simulationOptionsType, setSimulationOptionsType] = useState<
    string[] | string
  >();

  const { mutate } = useMutation(useCreatePayslipSimulation);

  const { civilities } = useCivilities();
  const { healthInsurancePackages } = useHealthInsurancePackages();
  const { securitisOptions } = useSecuritisOptions();
  const { employmentTypes } = useEmploymentTypes();
  const { employmentStatusOptions } = useEmploymentStatusOptions();
  const { optimizationOptions } = useOptimizationOptions();
  const { workingTimeUnitsOptions } = useWorkingTimeUnitOptions();
  const { user } = useCurrentUser();

  const fields = () => (
    <>
      {simulationOptionsType === 'optimization_value_type' && (
        <>
          <ControlledSelect
            name="optimization_name"
            control={control}
            className="mb-8"
            label={t(
              'activerecord.attributes.payslip_simulation.optimization_name',
            )}
            options={optimizationOptions}
            placeholder=""
            required
            labelClassName="mr-[24px]"
          />
          <ControlledNumberInput
            fullWidth
            rules={{ required: true }}
            control={control}
            name="optimization_value"
            label={t(
              'activerecord.attributes.payslip_simulation.optimization_value',
            )}
            className="mb-8"
          />
        </>
      )}
      {simulationOptionsType === 'monthly_revenue_type' && (
        <>
          <ControlledNumberInput
            fullWidth
            rules={{ required: true }}
            control={control}
            name="rate_of_pay"
            label={t('activerecord.attributes.payslip_simulation.rate_of_pay')}
            className="mb-8"
          />
          <ControlledSelect
            label={t(
              'activerecord.attributes.payslip_simulation.working_time_unit',
            )}
            required
            control={control}
            name="working_time_unit"
            className="mb-8"
            options={workingTimeUnitsOptions}
            labelClassName="mr-[24px]"
          />
        </>
      )}
      <TextInput
        required
        {...register('workingTimeCount')}
        label={t(
          `activerecord.attributes.payslip_simulation.working_time_count${
            simulationOptionsType === 'optimization_value_type'
              ? '_optimization'
              : ''
          }`,
        )}
        className="mb-8"
        fullWidth
      />
      <TextInput
        required
        {...register('monthDuration')}
        label={t('activerecord.attributes.payslip_simulation.month_duration')}
        className="mb-8"
        fullWidth
      />
      <ControlledNumberInput
        label={t('activerecord.attributes.payslip_simulation.monthly_expenses')}
        rules={{ required: true }}
        control={control}
        name="monthly_expenses"
        fullWidth
        className="mb-8"
      />
      <ControlledSelect
        name="health_insurance_package"
        control={control}
        className="mb-8"
        label={t(
          'activerecord.attributes.payslip_simulation.health_insurance_package',
        )}
        options={healthInsurancePackages}
        placeholder=""
        required
        labelClassName="mr-[24px]"
      />
      <ControlledSelect
        name="securitis"
        control={control}
        className="mb-8"
        label={t('activerecord.attributes.payslip_simulation.securitis')}
        options={securitisOptions}
        placeholder=""
        required
        labelClassName="mr-[24px]"
      />
      <ControlledSelect
        name="employment_type"
        control={control}
        className="mb-8"
        label={t('activerecord.attributes.payslip_simulation.employment_type')}
        options={employmentTypes}
        placeholder=""
        required
        labelClassName="mr-[24px]"
      />
      <ControlledSelect
        name="employment_status"
        control={control}
        className="mb-8"
        label={t(
          'activerecord.attributes.payslip_simulation.employment_status',
        )}
        options={employmentStatusOptions}
        placeholder=""
        required
        labelClassName="mr-[24px]"
      />
      <TextInput
        {...register('comment')}
        label={t('activerecord.attributes.payslip_simulation.comment')}
        className="mb-8"
        textarea
        fullWidth
      />
    </>
  );

  const missingUserInfo = () =>
    user && !(user.civility && user.lastName && user.firstName);

  const contactFields = () => (
    <>
      <ControlledSelect
        name="civility"
        control={control}
        className="mb-8"
        label={t('activerecord.attributes.payslip_simulation.civility')}
        options={civilities}
        required
        placeholder=""
        labelClassName="mr-[24px]"
      />
      <TextInput
        required
        {...register('firstName')}
        label={t('activerecord.attributes.payslip_simulation.first_name')}
        className="mb-8"
        value={user?.firstName}
        fullWidth
      />
      <TextInput
        required
        {...register('lastName')}
        label={t('activerecord.attributes.payslip_simulation.last_name')}
        className="mb-8"
        value={user?.lastName}
        fullWidth
      />
    </>
  );

  return (
    <PageLayout Title={t('front.welcome.simulations.new.title')}>
      <MainCard header={t('front.welcome.simulations.show.parameters')}>
        <form
          onSubmit={handleSubmit((data) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const payload: PayslipSimulationPayloadType = {
              contentType: 'payslip_simulations',
              email: user?.email,
              civility: data.civility || user?.civility,
              firstName: data.firstName || user?.firstName,
              lastName: data.lastName || user?.lastName,
              ...data,
            };

            mutate(payload, {
              onSuccess: (d) => navigate(`/v2/welcome/simulations/${d.id}`),
              onError: (error) =>
                toast.error(
                  <Toaster textMsg={error as string} toastType="error" />,
                ),
            });
          })}
        >
          <div className="flex mx-5">
            <div className="flex-col">
              {missingUserInfo() && contactFields()}
              <PortageOfferFields form={form} />
              {getValues('portageOfferId') && (
                <ControlledSelect
                  name="simulation_options_type"
                  control={control}
                  className="mb-8"
                  label={t(
                    'activerecord.attributes.payslip_simulation.simulation_options_type',
                  )}
                  options={[
                    {
                      label: t('views.payslip_simulation.monthly_revenue_type'),
                      value: 'monthly_revenue_type',
                    },
                    {
                      label: t(
                        'views.payslip_simulation.optimization_value_type',
                      ),
                      value: 'optimization_value_type',
                    },
                  ]}
                  placeholder=""
                  onChange={(value) => setSimulationOptionsType(value)}
                  labelClassName="mr-[24px]"
                />
              )}
              {simulationOptionsType && fields()}
            </div>
          </div>
          <ButtonPrimary
            className="m-2"
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            Submit
          </ButtonPrimary>
        </form>
      </MainCard>
    </PageLayout>
  );
};
