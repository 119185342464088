// api/Sepa::TransactionAssignation.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type FactorClientPaymentTransaction from './FactorClientPaymentTransaction';
import type Invoice from '../Invoice';

@Model()
class FactorTransactionAssociation extends ApplicationRecord {
  static jsonapiType = 'factor-transaction_associations';

  // Attributes
  @Attr() sepaTransactionId!: number;
  @Attr() associableId!: number;
  @Attr() associableType!: string;

  // Relationships
  @BelongsTo({ name: 'transactionable' })
  transactionable!: FactorClientPaymentTransaction;
  @BelongsTo({ name: 'associable' }) associable!: Invoice;
}

export default FactorTransactionAssociation;
