import { FilterUpIcon } from '../../../../../app/assets/icons/buttons';

export const ToggleCloseIcon = () => (
  <FilterUpIcon
    data-testid="filter-up-icon"
    className="rotate-180"
    height={20}
    width={10}
  />
);
