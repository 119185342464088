import { FactorClientPaymentTransaction, ModelRecord } from '@/app/spraypaint';
import { CompletedValidationModal } from '@/modules/admin/accounting/bankAssociation/components/CompletedValidationModal';
import { useBankReconciliationTransition } from '@/modules/admin/accounting/hooks/useBankReconciliationTransition';
import { useUserManagerRole } from '@/modules/authentification/hooks';
import {
  ButtonOnClickHandler,
  ButtonPrimary,
} from '@/modules/common/components/buttons';
import {
  DateFilter,
  getDateFilterFn,
} from '@/modules/common/components/table/filter/DateFilter';
import {
  SupInfFilter,
  getSupInfFilter,
} from '@/modules/common/components/table/filter/SupInfFilter';
import { TableHeaderLabelTooltiped } from '@/modules/common/components/tooltip/TableHeaderAmountTooltip';
import { useQueryClient } from '@tanstack/react-query';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const columnHelper =
  createColumnHelper<ModelRecord<FactorClientPaymentTransaction>>();

export const commonColumns = () => {
  const { t } = useTranslation();

  return [
    columnHelper.accessor('bookingDate', {
      header: t(
        'front.factor.reconciliation.clientPaymentTransaction.bookingDate',
      ) as string,
      filterFn: getDateFilterFn(),
      meta: {
        filter: {
          Component: DateFilter,
        },
      },
      cell: (info) => (
        <div className="text-center">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    columnHelper.accessor('label', {
      // filter working
      header: t(
        'front.factor.reconciliation.clientPaymentTransaction.label',
      ) as string,
      cell: (info) => (
        <div className="text-left">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    columnHelper.accessor('issuerName', {
      // filter working
      header: t(
        'front.factor.reconciliation.clientPaymentTransaction.issuerBank',
      ) as string,
      cell: (info) => (
        <div className="text-left">
          <p>{info.getValue()}</p>
        </div>
      ),
    }),
    columnHelper.accessor('amount', {
      header: () => (
        <TableHeaderLabelTooltiped
          label={t(
            'front.factor.reconciliation.clientPaymentTransaction.amount',
          )}
          msg={t('front.table.header.amount.tooltipMsg')}
        />
      ),
      filterFn: getSupInfFilter(),
      meta: {
        filter: {
          Component: SupInfFilter,
        },
      },
    }),
  ];
};

export const pendingFactorClientPaymentTransactionColumns = (id?: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const isOnlyManager = useUserManagerRole();

  const ActionsCell = ({
    cell,
  }: CellContext<ModelRecord<FactorClientPaymentTransaction>, unknown>) => {
    const data = cell.row.original;

    const handleOnSuccess = () => {
      queryClient.invalidateQueries(['sepa-files', id]);
    };
    const { mutate, isLoading } =
      useBankReconciliationTransition(handleOnSuccess);

    const [comment, setComment] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOnClick: ButtonOnClickHandler = (event) => {
      event.stopPropagation();
      event.preventDefault();
      mutate({
        resourceId: data.id,
        transitionName: 'orphan',
        transitionParams: { comment },
      });
    };

    const handleFill = (e: React.ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    };

    const handleSetIsModalOpen: ButtonOnClickHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setIsModalOpen((prev) => !prev);
    };

    return (
      <div className="flex justify-center items-center h-[100%]">
        <CompletedValidationModal
          handleSetCloseModal={handleSetIsModalOpen}
          isOpen={isModalOpen}
          handleOnClick={handleOnClick}
          isLoading={isLoading}
          handleFill={handleFill}
        />
        <ButtonPrimary
          isLoading={isLoading}
          onClick={handleSetIsModalOpen}
          disabled={isOnlyManager}
        >
          {t('default.button.renounce')}
        </ButtonPrimary>
      </div>
    );
  };

  return [
    ...commonColumns(),
    columnHelper.accessor('matchingCandidatesCount', {
      header: t(
        'front.factor.reconciliation.clientPaymentTransaction.matchingCandidates',
      ) as string,
      filterFn: getSupInfFilter(),
      meta: {
        filter: {
          Component: SupInfFilter,
        },
      },
    }),
    columnHelper.accessor('actions', {
      header: t('default.actions'),
      enableColumnFilter: false,
      enableSorting: false,
      cell: ActionsCell,
    }),
  ];
};

export const validatedFactorClientPaymentTransactionColumns = (id?: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const isOnlyManager = useUserManagerRole();

  const ActionsCell = ({
    cell,
  }: CellContext<ModelRecord<FactorClientPaymentTransaction>, unknown>) => {
    const data = cell.row.original;
    const handleOnSuccess = () => {
      queryClient.invalidateQueries(['sepa-files', id]);
    };
    const { mutate } = useBankReconciliationTransition(handleOnSuccess);

    const handleOnClick: ButtonOnClickHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
      mutate({
        resourceId: data.id,
        transitionName:
          data.status === 'orphaned' ? 'back_to_pending_validation' : 'cancel',
      });
    };

    return (
      <div className="flex justify-center items-center h-[100%]">
        {data.status !== 'duplicate' && (
          <ButtonPrimary onClick={handleOnClick} disabled={isOnlyManager}>
            {t('default.button.cancel')}
          </ButtonPrimary>
        )}
      </div>
    );
  };

  return [
    ...commonColumns(),
    columnHelper.accessor('status', {
      header: t('default.status'),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('actions', {
      header: t('default.actions'),
      enableColumnFilter: false,
      enableSorting: false,
      cell: ActionsCell,
    }),
  ];
};
