import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class InvoiceConsolidation extends ApplicationRecord {
  static jsonapiType = 'invoicing-invoice_consolidations';

  // Attributes
  @Attr() invoiceIds!: Array<number>;
  @Attr() frameworkContractId!: number;
  @Attr() date!: string;
  @Attr({ persist: false }) invoiceId!: number;

  // Extra-attributes

  // Relationships
}

export default InvoiceConsolidation;
