import { useQuery } from '@tanstack/react-query';
import Expertise from '@/app/spraypaint/Expertise';

export const useExpertises = () => {
  const { data } = useQuery(['expertises'], () => Expertise.per(500).all(), {
    staleTime: 1000 * 60 * 30,
    cacheTime: 0,
  });

  const expertises = data?.data || [];

  const expertisesOptions = expertises.map((expertise: Expertise) => ({
    label: expertise.name,
    value: String(expertise.id),
  }));

  return {
    expertises,
    expertisesOptions,
  };
};

export const useExpertise = (id: string) => {
  const { expertises } = useExpertises();

  const expertise = expertises.find((e) => String(e.id) === id);

  const expertiseName = expertise ? expertise.name : 'Unknown';
  const expertiseLegalEntityId = expertise ? expertise.legalEntityId : null;
  return { expertiseName, expertiseLegalEntityId };
};
