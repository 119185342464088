import useLegalEntities from './useLegalEntities';

const useLegalEntitiesSelectOptions = () => {
  const { legalEntities } = useLegalEntities();
  if (legalEntities && legalEntities.length > 0) {
    return legalEntities.map((entity) => ({
      value: entity.id,
      label: entity.name,
    }));
  }
  return [];
};

export default useLegalEntitiesSelectOptions;
