import ActivityAccountLine from '@/app/spraypaint/activityAccount/ActivityAccountLine';
import ActivityAccountLineGroup from '@/app/spraypaint/activityAccount/ActivityAccountLineGroup';
import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';

const useEmployeeActivityAccountLinesGroups = (
  activityAccountId: string | undefined,
  scope: (
    baseScope: Scope<ActivityAccountLineGroup | ActivityAccountLine>,
  ) => Scope<ActivityAccountLineGroup | ActivityAccountLine>,
  args: Array<unknown>,
) => {
  const response = useQuery({
    queryKey: ['activity-account-lines-group', activityAccountId, ...args],
    queryFn: () =>
      scope(
        ActivityAccountLineGroup.where({
          activity_account_id: activityAccountId,
        })
          .includes('activity_account_lines')
          .order({ date: 'desc' })
          .scope(),
      ).all(),
    refetchOnWindowFocus: false,
    enabled: !!activityAccountId,
    cacheTime: 0,
  });

  const activityAccountLinesGroups = response?.data?.data;
  const totalCount = response?.data?.meta.stats.total.count;

  return { ...response, activityAccountLinesGroups, totalCount };
};

export default useEmployeeActivityAccountLinesGroups;
