import { RoutesMounter } from '@/types';
import {
  featureFlagModuleRoutes,
  index,
  member,
  redirectTo,
} from '@/app/navigation/functions';
import { InvoicesEdit, InvoicesIndex, InvoicesNewDraft } from './pages';
import { loader as invoiceLoader } from './functions/invoice_query';

export const mountInvoicesRoutes: RoutesMounter<unknown> =
  featureFlagModuleRoutes(
    'v2_redirect_invoices',
    ({ current_employee }) => `/employees/${current_employee}/invoices`,
    ({ path, tools: { t, queryClient } }) => [
      member({
        path,
        handle: {
          crumb: () => t('front.invoices.crumbs.index'),
        },
        children: [
          index({
            index: true,
            Component: InvoicesIndex,
            handle: {
              v1: ({ current_employee }) =>
                `/employees/${current_employee}/invoices`,
            },
          }),
          member({ path: 'new', loader: redirectTo('../drafts/new') }),
          member({
            path: 'drafts/new',
            Component: InvoicesNewDraft,
            handle: {
              crumb: () => t('front.invoices.crumbs.new'),
              v1: ({ current_employee }) =>
                `/employees/${current_employee}/invoices/new`,
            },
          }),
          member({
            path: ':id',
            handle: {
              crumb: ({ data }) =>
                t('front.invoices.crumbs.show', { number: data.data.number }),
              v1: ({ current_employee, id }) =>
                `/employees/${current_employee}/invoices/${id}`,
            },
            loader: invoiceLoader(queryClient),
            children: [
              index({ Component: InvoicesEdit }),
              member({
                path: 'edit',
                Component: InvoicesEdit,
                handle: {
                  crumb: () => t('front.invoices.crumbs.edit'),
                  v1: ({ id }) => `/invoices/${id}/edit`,
                },
              }),
            ],
          }),
        ],
      }),
    ],
  );

export { handleSubmit } from '@/modules/invoices/functions/submitNewInvoiceDraft';
