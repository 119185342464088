export const HOST_PRODUCTION = 'extranet.prium-portage.com';
export const HOST_STAGING = 'extranet-staging.prium-portage.com';

export type PriumEnv = 'development' | 'staging' | 'production';

export default function getCurrentPriumEnv(): PriumEnv {
  const { host } = window.location;

  switch (host) {
    case HOST_STAGING:
      return 'staging';
    case HOST_PRODUCTION:
      return 'production';
    default:
      return 'development';
  }
}
