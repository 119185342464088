import { MoneyType } from '@/modules/common/utils/type.utils';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

export function formatMoney(
  money: MoneyType | undefined,
  locale: string,
): string | undefined {
  if (!money) {
    return undefined;
  }
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: money.currency || 'EUR', // Default to 'USD' or any preferred currency
  });

  return formatter.format(money.amount);
}

export function useFormatMoney() {
  const {
    i18n: { language: locale },
  } = useTranslation();

  return useCallback(
    (money: Parameters<typeof formatMoney>[0]) => formatMoney(money, locale),
    [locale],
  );
}
