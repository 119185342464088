import { FunctionComponent, useId } from 'react';
import { CapGeminiConsolidationInvoiceImport } from '@/app/spraypaint';
import classNames from 'classnames';
import { TooltipComponent } from '@/modules/common/components/fragments/TooltipComponent';
import { IconBaseProps } from 'react-icons';
import {
  FaCheck,
  FaPen,
  FaTriangleExclamation,
  FaXmark,
} from 'react-icons/fa6';

const STATUS_CONFIG = {
  ok: {
    Icon: (arg: IconBaseProps) => (
      <>
        OK
        <FaCheck {...arg} />{' '}
      </>
    ),
    iconClassName: '',
    className: 'text-color-success',
  },
  warning: {
    Icon: (arg: IconBaseProps) => (
      <>
        Avertissement
        <FaTriangleExclamation {...arg} />{' '}
      </>
    ),
    iconClassName: '',
    className: 'text-color-warning',
  },
  error: {
    Icon: (arg: IconBaseProps) => (
      <>
        Erreur
        <FaXmark {...arg} />{' '}
      </>
    ),
    iconClassName: '',
    className: 'text-color-failure',
  },
  pending: {
    Icon: (arg: IconBaseProps) => (
      <>
        Pret
        <FaPen {...arg} />{' '}
      </>
    ),
    iconClassName: '',
    className: 'text-color-blue',
  },
};

export const StatusWidget: FunctionComponent<{
  status: CapGeminiConsolidationInvoiceImport['importStatus'] | undefined;
  errors: CapGeminiConsolidationInvoiceImport['importErrors'] | undefined;
  warnings: CapGeminiConsolidationInvoiceImport['importWarnings'] | undefined;
}> = ({ status, errors, warnings }) => {
  if (!status || !errors || !warnings) return null;

  const id = useId().replaceAll(':', '');

  const config = STATUS_CONFIG[status] || STATUS_CONFIG.error;

  const iconClassName = classNames(config.iconClassName, 'ml-1 inline-block');

  const contentArray = [];
  if (errors.length > 0) {
    contentArray.push(
      <>
        <p className="text-color-failure">
          <b>Erreurs :</b>
        </p>
        <ul className="list-disc list-inside">
          {errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </>,
    );
  }
  if (warnings.length > 0) {
    contentArray.push(
      <>
        <p className="text-color-warning">
          <b>Avertissements :</b>
        </p>
        <ul className="list-disc list-inside">
          {warnings.map((warning) => (
            <li key={warning}>{warning}</li>
          ))}
        </ul>
      </>,
    );
  }

  // contentArray is an array, so there is multiple elements
  // eslint-disable-next-line react/jsx-no-useless-fragment
  const content = contentArray.length > 0 ? <>{contentArray}</> : null;

  return (
    <div className={config.className}>
      <TooltipComponent
        Icon={config.Icon}
        tooltipMsgComp={content}
        tooltipId={id}
        tooltipPosition="right"
        iconClassName={iconClassName}
      />
    </div>
  );
};
