import { onRowClickNavigate } from '@/app/navigation/functions';
import { FactorClientPaymentTransaction, ModelRecord } from '@/app/spraypaint';
import Table from '@/modules/common/components/table/Table';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useGetAllCompletedBankReconciliationTransaction,
  useGetAllPendingBankReconciliationTransaction,
} from '../hooks/useGetAllFactorClientPaymentTransaction';
import {
  pendingFactorClientPaymentTransactionColumns,
  validatedFactorClientPaymentTransactionColumns,
} from '../columns/factorClientPaymentTransactionsColumns';

const ReconciliationIndex = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [scope, scopeKey, props] =
    useBackendTable<FactorClientPaymentTransaction>({
      initialPageSize: 30,
      initialSorting: [{ id: 'id', desc: false }],
    });
  const [
    completedFactorClientPaymentScope,
    completedFactorClientPaymentScopeKey,
    completedFactorClientPaymentProps,
  ] = useBackendTable<FactorClientPaymentTransaction>();
  const {
    completedFactorClientPaymentTransactions,
    isLoading: getAllCompletedFactorClientPaymentLoader,
    totalCount: completedFactorClientPaymentTotalCount,
  } = useGetAllCompletedBankReconciliationTransaction(
    completedFactorClientPaymentScopeKey,
    completedFactorClientPaymentScope,
  );
  const {
    pendingFactorClientPaymentTransactions,
    isLoading: getAllPendingFactorClientPaymentsLoader,
    totalCount: pendingFactorClientPaymentsTotalCount,
  } = useGetAllPendingBankReconciliationTransaction(scopeKey, scope);

  const handleNavigateAssociationPage =
    (raw: ModelRecord<FactorClientPaymentTransaction>) =>
    (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminAssignationPagePath,
        event,
        windowNavigationHref: raw?.adminAssignationPagePath,
        navigate,
      });
    };

  const handleNavigateShowPage =
    (raw: ModelRecord<FactorClientPaymentTransaction>) =>
    (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminShowPagePath,
        event,
        windowNavigationHref: raw.adminShowPagePath,
        navigate,
      });
    };

  return (
    <div className="mt-[50px]">
      <div className="flex justify-between px-[20px]">
        <p className="big-title">
          {t('front.bankReconciliation.index.header.title')}
        </p>
      </div>
      <Table
        title={
          t(
            'front.bankReconciliation.table.header.pendingTransaction',
          ) as string
        }
        emptyTableErrorMsg="react_table.no_data_text"
        onRowClick={handleNavigateAssociationPage}
        className="mt-[26px]"
        isLoading={getAllPendingFactorClientPaymentsLoader}
        withImageErrorMsg={false}
        totalCount={pendingFactorClientPaymentsTotalCount}
        data={pendingFactorClientPaymentTransactions || []}
        reactTableProps={props}
        columns={pendingFactorClientPaymentTransactionColumns()}
      />
      <Table
        title={
          t(
            'front.bankReconciliation.table.header.completedTransaction',
          ) as string
        }
        onRowClick={handleNavigateShowPage}
        emptyTableErrorMsg="react_table.no_data_text"
        className="mt-[26px]"
        withImageErrorMsg={false}
        isLoading={getAllCompletedFactorClientPaymentLoader}
        totalCount={completedFactorClientPaymentTotalCount}
        data={completedFactorClientPaymentTransactions || []}
        reactTableProps={completedFactorClientPaymentProps}
        columns={validatedFactorClientPaymentTransactionColumns()}
      />
    </div>
  );
};

export default ReconciliationIndex;
