import { Mixin } from '@rails/actioncable';
import { useChannelSubscription } from './functions/useChannelSubscription';

export default function useInvalidateQueryChannel(
  params: { resource_id: string | undefined; resource_type: string },
  mixin: Mixin,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: Array<any>,
) {
  useChannelSubscription('InvalidateQueryChannel', params, mixin, deps);
}
