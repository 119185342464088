import { useTranslation } from 'react-i18next';
import {
  Await,
  createSearchParams,
  useLoaderData,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { FC, FunctionComponent, Suspense } from 'react';

import { LoaderIcon } from '@/app/assets/icons/inputs';
import { OneStepLoginForm } from '@/modules/authentification/components/OneStepLoginForm';
import { TwoStepsLoginForm } from '@/modules/authentification/components/TwoStepsLoginForm';

import { InnerLoginFormProps } from '@/modules/authentification/components/types/InnerLoginFormProps';
import { AuthHeader, AuthTitle, AuthFooter } from '../components';
import { AuthMainContainer } from '../components/cards';

import { LoginIcon } from '../../../app/assets/icons/auth';

import { useRedirectAfterLogin } from '../hooks/useRedirectAfterLogin';

export type InnerLoginFormName = 'TwoStepsLoginForm' | 'OneStepLoginForm';

const componentMap: Record<InnerLoginFormName, FC<InnerLoginFormProps>> = {
  TwoStepsLoginForm,
  OneStepLoginForm,
};

// If fromRegister is true, return a card saying "This account already exists, please login"
export const FromRegisterCard: FunctionComponent<{
  fromRegister: boolean | undefined;
}> = ({ fromRegister }) => {
  const { t } = useTranslation();

  if (!fromRegister) {
    return null;
  }

  return (
    <div className="mt-[33px]">
      {t('front.auth.signIn.account_already_exists')}
    </div>
  );
};

export const LoginIndex = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loaderData: any = useLoaderData();

  const redirectAfterLogin = useRedirectAfterLogin();

  const location = useLocation();
  const { fromRegister } = location.state || {};

  const handleNavigateSignUp = () => navigate('/v2/signup');
  const handleNavigateForgotPassword: InnerLoginFormProps['onForgetPassword'] =
    (email: string) =>
      navigate({
        pathname: '/v2/auth/forgot_password',
        search: createSearchParams({ email }).toString(),
      });

  return (
    <div
      data-testid="login-index-container"
      className="h-screen w-full flex justify-between"
    >
      <div className="flex flex-col justify-between flex-1">
        <div>
          <AuthHeader />
          <AuthMainContainer>
            <AuthTitle>{t('front.auth.signIn.form.header')}</AuthTitle>
            <Suspense
              fallback={
                <LoaderIcon
                  data-testid="loader-icon"
                  className="w-[50px] h-[50px] m-12  animate-spin fill-color-pone-orange"
                />
              }
            >
              <Await
                resolve={loaderData.innerComponentName}
                errorElement="Error"
              >
                {(innerComponentName: InnerLoginFormName) => {
                  const InnerComponent = componentMap[innerComponentName];
                  return (
                    <>
                      <FromRegisterCard fromRegister={fromRegister} />
                      <InnerComponent
                        onForgetPassword={handleNavigateForgotPassword}
                        redirectAfterLogin={redirectAfterLogin}
                      />
                    </>
                  );
                }}
              </Await>
            </Suspense>
          </AuthMainContainer>
        </div>
        <AuthFooter
          onClick={handleNavigateSignUp}
          headerTitle={t('front.auth.signIn.footer.header') as string}
          buttonTitle={t('front.auth.signIn.footer.button.title') as string}
        />
      </div>
      <LoginIcon className="hidden md:block h-full" />
    </div>
  );
};
