import { MouseEventHandler } from 'react';
import { ChevronRightIcon } from '../../../../../app/assets/icons/buttons';

const displayReadableMonthDate = (monthDate: {
  toLocaleString: (
    arg0: string,
    arg1: { month: string; year: string },
  ) => string;
}) => {
  const monthString = monthDate.toLocaleString('fr-FR', {
    month: 'long',
    year: 'numeric',
  });
  return monthString.charAt(0).toUpperCase() + monthString.slice(1);
};

export function getCustomHeader() {
  return ({
    monthDate,
    decreaseMonth,
    increaseMonth,
  }: {
    monthDate: object;
    decreaseMonth: MouseEventHandler<HTMLButtonElement>;
    increaseMonth: MouseEventHandler<HTMLButtonElement>;
  }) => (
    <div className="flex items-center justify-between h-[30px] bg-color-medium-grey-transparent">
      <button onClick={decreaseMonth} type="button">
        <ChevronRightIcon className="w-[24px] h-[12px] rotate-180 mr-[2px]" />
      </button>
      <span className="react-datepicker__current-month">
        {displayReadableMonthDate(monthDate)}
      </span>
      <button onClick={increaseMonth} type="button">
        <ChevronRightIcon className="w-[24px] h-[12px]" />
      </button>
    </div>
  );
}

export const defaultPlaceholder = `${new Date().getDate()}/${
  new Date().getMonth() + 1
}/${new Date().getFullYear()}`;
