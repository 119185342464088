import { SepaTransaction } from '../../../../../app/spraypaint';

export const useSendLabelFormatReminder = async (
  transaction: SepaTransaction | undefined,
): Promise<void> => {
  try {
    await transaction?.fetchSendLabelFormatReminder();
  } catch {
    throw new Error('Sepa Transaction reminder error');
  }
};
