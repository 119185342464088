import { useTranslation } from 'react-i18next';
import { formatDate } from '@/app/utils/dateUtils';
import { CloseIcon } from '../../../../app/assets/icons/buttons';
import { IlluSuccessPayslip } from '../../../../app/assets/icons/payslipFunnel';
import {
  Button,
  ButtonPrimary,
  ButtonSecondary,
} from '../../../common/components/buttons';
import { Line } from '../../../common/components/fragments';
import { CustomModal } from '../../../common/components/fragments/CustomModal';

type Props = {
  isOpen: boolean;
  currentPayslipFunnelMonth: string;
  isLoading: boolean;
  handleSetCloseModal: () => void;
  handleValidateSalaryClick: () => void;
  stc: boolean;
  dateRangeLastDate: undefined | string;
};

export const ConfirmationModal = ({
  isOpen,
  isLoading,
  handleSetCloseModal,
  handleValidateSalaryClick,
  currentPayslipFunnelMonth,
  stc,
  dateRangeLastDate,
}: Props) => {
  const { t } = useTranslation();

  return (
    <CustomModal isOpen={isOpen} onRequestClose={handleSetCloseModal}>
      <div className="flex flex-col w-64 md:w-[950px]">
        <Button
          onClick={handleSetCloseModal}
          custom
          className="self-end mt-[25px] mr-[25px]"
        >
          <CloseIcon />
        </Button>
        <p className="text-color-medium-grey text-xl ml-[21px]">
          {t('front.payslip.tdc.modalConfirmation.title', {
            month: currentPayslipFunnelMonth,
          })}
        </p>
        <Line className="mt-[10px]" />
        <IlluSuccessPayslip className="self-center mt-[30px] mb-[20px] fill-color-pone-orange" />
        <p className="mx-10 md:ml-[43px] md:mr-[127px]">
          {t('front.payslip.tdc.modalConfirmation.body')}
        </p>
        {stc && dateRangeLastDate && (
          <p className="mx-10 md:ml-[43px] md:mr-[127px] mt-[20px]">
            {t('front.payslip.tdc.modalConfirmation.body_stc', {
              contract_end_date: formatDate(dateRangeLastDate),
            })}
          </p>
        )}
        <p className="mx-10 md:ml-[43px] md:mr-[127px] mt-[20px]">
          {t('front.payslip.tdc.modalConfirmation.body_ps')}
        </p>
        <div className="flex flex-col md:flex-row justify-center my-[60px] px-6 md:px-0">
          <ButtonSecondary onClick={handleSetCloseModal}>
            {t('front.payslip.tdc.modalConfirmation.secondarybutton')}
          </ButtonSecondary>
          <div className="my-2 md:my-0 md:mx-[30px]" />
          <ButtonPrimary
            isLoading={isLoading}
            onClick={handleValidateSalaryClick}
          >
            {t('front.payslip.tdc.modalConfirmation.primarybutton')}
          </ButtonPrimary>
        </div>
      </div>
    </CustomModal>
  );
};
