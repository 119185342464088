import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import { NumberInput, NumberInputProps } from '../NumberInput';

type Props = {
  control: Control<FieldValues> | undefined;
  name: string;
  defaultValue?: number;
  rules?: RegisterOptions;
} & Omit<NumberInputProps, 'onChange'>;

export const ControlledNumberInput = ({
  control,
  name,
  defaultValue,
  rules,
  fullWidth,
  ...props
}: Props) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field: { onChange, value } }) => (
      <NumberInput
        {...props}
        value={value}
        onChange={(newValue) => onChange(newValue)}
        colDisplay
        fullWidth={fullWidth}
      />
    )}
  />
);
