import { useTranslation } from 'react-i18next';
import { SwitchSelection } from '../../common/components/buttons';
import { NumberInput } from '../../common/components/inputs';
import { MoneyType } from '../../common/utils/type.utils';

export interface PayslipCustomChoiceMinusCardProps {
  maxGrossSalary: MoneyType;
  minGrossSalary: MoneyType;
  maxNetSalary: MoneyType;
  minNetSalary: MoneyType;
  intervalMinText?: string;
  intervalMaxText?: string;
  inputValue: number;
  onChangeInput: (value: number) => void;
  handleSwitchChange: any;
  switchOptions: any;
  selectedOption: any;
  handleSetError: (value: boolean) => void;
}

export const PayslipCustomChoiceMinusCard = ({
  minGrossSalary,
  maxGrossSalary,
  minNetSalary,
  maxNetSalary,
  intervalMinText,
  intervalMaxText,
  inputValue,
  onChangeInput,
  handleSwitchChange,
  switchOptions,
  selectedOption,
  handleSetError,
}: PayslipCustomChoiceMinusCardProps) => {
  const grossMinAmount = minGrossSalary?.amount;
  const grossMaxAmount = maxGrossSalary?.amount;
  const netMinAmount = minNetSalary?.amount;
  const netMaxAmount = maxNetSalary?.amount;

  const { t } = useTranslation();
  const placeholder = t(
    'front.payslip.tdc.salaryChoice.custom_salary.subCard.placeholder',
  );

  const computedIntervalMin =
    selectedOption === 'gross' ? grossMinAmount : netMinAmount;
  const computedIntervalMax =
    selectedOption === 'gross' ? grossMaxAmount : netMaxAmount;

  const isDisabled = computedIntervalMin === 0 && computedIntervalMax === 0;

  return (
    <div className="flex flex-col justify-between w-[272px] h-[265px] py-[30px] mt-2.5 shadow-card">
      <p className="text-center text-base2 text-color-pone-dark">
        {t('front.payslip.tdc.salaryChoice.custom_salary.subCard.title')}
      </p>
      <div className="flex justify-center">
        <SwitchSelection
          options={switchOptions}
          onChange={handleSwitchChange}
          value={selectedOption}
        />
      </div>
      <NumberInput
        intervalMin={computedIntervalMin}
        intervalMinText={intervalMinText}
        placeholder={placeholder}
        intervalMax={computedIntervalMax}
        intervalMaxText={intervalMaxText}
        currencyIsoCode={maxGrossSalary?.currency}
        colDisplay
        onChange={onChangeInput}
        value={inputValue}
        disabled={isDisabled}
        handleSetError={handleSetError}
      />
    </div>
  );
};
