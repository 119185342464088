import { useTranslation } from 'react-i18next';
import { HypertextLink } from '../../../../common/components/buttons';

export const MiniTutorialInvoice = ({ onClick }: { onClick: any }) => {
  const { t } = useTranslation();
  return (
    <p className="text-color-dark-content">
      {t('front.payslip.tdc.invoice.miniTutorial.text')}{' '}
      <HypertextLink onClick={onClick} className="mt-[1px]">
        {t('front.payslip.tdc.invoice.miniTutorial.hypertext')}
      </HypertextLink>
    </p>
  );
};
