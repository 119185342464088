import { FetchQueryOptions, QueryClient } from '@tanstack/react-query';
import { Override } from '@/types';

export type FetchQueryOptionsWithQueryKey<T> = Override<
  FetchQueryOptions<T>,
  Required<Pick<FetchQueryOptions<T>, 'queryKey'>>
>;

export function fetchFromQueryClient<T>(
  queryClient: QueryClient,
  query: FetchQueryOptionsWithQueryKey<T>,
): Promise<T> {
  const cachedData = queryClient.getQueryData<T>(query.queryKey);
  if (cachedData) {
    return Promise.resolve(cachedData);
  }
  return queryClient.fetchQuery(query);
}
