import classNames from 'classnames';
import { AmountText } from '../../../common/components/fragments/AmountText';
import { LoaderIcon } from '../../../../app/assets/icons/inputs';
import { DaysBlock } from '../../components/DaysBlock';
import { CurrencyIsoCode, MoneyType } from '../../../common/utils/type.utils';

export interface IntermediateSummaryCardProps {
  craTitle: string;
  craMonth: string;
  workedDaysLabel: string;
  workedDays: number;
  daysOffLabel?: string;
  daysOff?: number;
  invoiceTitle?: string;
  invoicesTotalAmount: MoneyType;
  expenseTitle?: string;
  totalExpensesToReimburse: MoneyType;
  currencyIsoCode?: CurrencyIsoCode;
  supText?: string;
  isLoading?: boolean;
}

export const IntermediateSummaryCard = ({
  craTitle,
  craMonth,
  workedDays,
  workedDaysLabel,
  daysOffLabel,
  daysOff,
  invoiceTitle,
  invoicesTotalAmount,
  expenseTitle,
  totalExpensesToReimburse,
  currencyIsoCode,
  supText,
  isLoading,
}: IntermediateSummaryCardProps) => {
  const customClassName = classNames(
    'flex justify-around h-[238px] ' +
      'px-[20px] py-[20px] rounded-[12px] ' +
      'border-[1px] border-color-medium-light bg-color-light-grey md:w-[472px]',
    { 'items-center': isLoading },
  );

  const amountCustomClass = classNames(
    'text-cabin-bold mt-0.5 text-lg md:text-xl1',
  );

  return (
    <div className={customClassName}>
      {isLoading ? (
        <LoaderIcon
          className="animate-spin fill-color-pone-orange"
          height={40}
          width={40}
        />
      ) : (
        <>
          <div className="w-1/2 flex flex-col justify-around items-center border-r-[1px] opacity-100 border-solid border-color-medium-light">
            <AmountText
              label={invoiceTitle}
              amount={invoicesTotalAmount?.amount}
              currencyIsoCode={currencyIsoCode}
              supText={supText}
              className={amountCustomClass}
              currencyPositionClass="top-[-8px]"
            />
            <div className="h-0 border-b-[1px] opacity-100 border-solid border-color-medium-light w-36 md:w-52" />
            <AmountText
              label={expenseTitle}
              amount={totalExpensesToReimburse?.amount}
              currencyIsoCode={currencyIsoCode}
              className={amountCustomClass}
              currencyPositionClass="top-[-8px]"
            />
          </div>
          <div className="w-1/2 flex flex-col justify-around items-center">
            <div className="w-[158px] text-sm2 text-center text-color-dark-content px-2">
              <p>{craTitle}</p> {craMonth.toUpperCase()}
            </div>
            <DaysBlock label={workedDaysLabel} value={workedDays} />
            <DaysBlock label={daysOffLabel} value={daysOff} />
          </div>
        </>
      )}
    </div>
  );
};
