import { Attr, Model } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';

@Model()
class Expertise extends ApplicationRecord {
  static jsonapiType = 'expertises';

  // Attributes
  @Attr({ persist: false }) createdAt: string;
  @Attr({ persist: false }) updatedAt: string;
  @Attr({ persist: false }) name: string;
  @Attr({ persist: false }) legalEntityId: number;
}

export default Expertise;
