import { Blob } from '@/app/spraypaint';
import {
  blobToDataUrl,
  uploadBlob,
} from '@/modules/common/components/inputs/functions/commonUploadFileFunctions';
import { Toaster } from '@/modules/common/components/toasts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { UploadDocInput } from '@/modules/common/components/inputs';
import Table from '@/modules/common/components/table/Table';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import FactorAuthorization from '@/app/spraypaint/factor/FactorAuthorization';
import { createAuthorizationFile } from '../hooks/useCreateAuthorizationFile';
import { useGetAuthorizations } from '../hooks/useGetAuthorizations';
import { useGetLatestAuthorizationFile } from '../hooks/useGetLatestAuthorizationFile';
import { authorizationColumns } from '../columns/authorizationColumns';
import AuthorizationsFileCard from '../components/AuthorizationFileCard';

const AuthorizationsIndex = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [filename, setFilename] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

  const { mutate: mutateBlob, isLoading: blobLoader } = useMutation(uploadBlob);
  const { mutate: mutateAuthorizationFile } = useMutation(
    createAuthorizationFile,
  );

  const [scope, scopeKey, props] = useBackendTable<FactorAuthorization>({
    initialSorting: [{ id: 'registered_client.name', desc: true }],
  });
  const { authorizations, isLoading, totalCount } = useGetAuthorizations(
    scopeKey,
    scope,
  );
  const { latestAuthorizationFile } = useGetLatestAuthorizationFile();

  const handleUploadFile = async () => {
    if (!selectedFile) return;
    const bytes = await blobToDataUrl(selectedFile);

    const payload = {
      filename,
      contentType: 'sepa-file',
      content: { bytes },
    };

    mutateBlob(payload, {
      onSuccess: (data: Blob) => {
        const blobPayload = {
          fileSignedId: data.id,
        };
        mutateAuthorizationFile(blobPayload, {
          onSuccess: () => {
            queryClient.invalidateQueries(['factor-authorizations']);
            queryClient.invalidateQueries(['factor-latest_authorization_file']);
            toast.success(
              <Toaster
                textMsg={t(
                  'front.factor.authorization.index.toats.upload_file.success',
                )}
                toastType="success"
              />,
            );
          },
          onError: () => {
            toast.error(
              <Toaster
                textMsg={t(
                  'front.factor.authorization.index.toats.upload_file.error',
                )}
                toastType="error"
              />,
            );
          },
        });
        setFilename('');
      },
      onError: () => {
        const errorText = `ERROR during import of ${selectedFile.name}`;
        toast.error(<Toaster textMsg={errorText} toastType="error" />);
      },
    });
  };

  const handleChangeFile = (file: File) => {
    setSelectedFile(file);
  };

  return (
    <div className="mb-[100px]">
      <div className="ml-[20px]">
        <div className="flex justify-between pr-[20px] mb-[20px]">
          <p className="big-title ">
            {t('front.factor.authorization.index.title')}
          </p>
          <UploadDocInput
            isLoading={blobLoader}
            handleChangeFile={handleChangeFile}
            fileName={filename}
            setFileName={setFilename}
            handleUploadFile={handleUploadFile}
            label={t('front.factor.authorization.index.upload_file.label')}
            acceptedFile={['.txt']}
          />
          <div />
        </div>
        {latestAuthorizationFile && (
          <AuthorizationsFileCard authorizationFile={latestAuthorizationFile} />
        )}
        <Table
          // onRowClick={handleOnRowClick}
          className="mt-[26px]"
          isLoading={isLoading}
          totalCount={totalCount}
          data={authorizations || []}
          reactTableProps={props}
          columns={authorizationColumns}
        />
      </div>
    </div>
  );
};

export default AuthorizationsIndex;
