/* eslint-disable import/no-cycle */
// api/Credit.ts
import { Model, Attr, BelongsTo, HasMany } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import Invoice from './Invoice';
import Transition from './Transition';

@Model()
class Credit extends ApplicationRecord {
  static jsonapiType = 'credits';

  // Attributes
  @Attr({ persist: false }) employeeId!: number;
  @Attr({ persist: false }) invoiceId!: number;
  @Attr({ persist: false }) number!: string;
  @Attr() memo!: string;
  @Attr({ persist: false }) date!: string;
  @Attr({ persist: false }) sendDate!: string;
  @Attr({ persist: false }) status!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) clientId!: number;
  @Attr({ persist: false }) vatCgiSlug!: string;
  @Attr({ persist: false }) documentLanguage!: string;
  @Attr({ persist: false }) payslipDate!: string;
  @Attr({ persist: false }) currencyIsoCode!: string;
  @Attr({ persist: false }) emailSent!: boolean;

  // Relationships
  @BelongsTo(Invoice) invoice!: Invoice[];
  @HasMany(Transition) transitions!: Transition[];

  get employeePagePath(): string | undefined {
    const { employeeId, id } = this;

    if (!employeeId || !id) {
      return undefined;
    }

    return `/employees/${employeeId}/credits/${id}`;
  }
}

export default Credit;
