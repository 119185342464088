import { useTranslation } from 'react-i18next';
import Table from '../../../../common/components/table/Table';
import { RejectedExpensesColumns } from '../../table/payslipDetailsColumns';

export type RejectedExpensesTableProps = {
  expensesStep: any;
  isLoading: boolean;
};

export const RejectedExpensesTable = ({
  isLoading,
  expensesStep,
}: RejectedExpensesTableProps) => {
  const { t } = useTranslation();
  const rejectedExpensesColumns = RejectedExpensesColumns();

  return (
    <Table
      emptyTableErrorMsg="front.payslip.tdc.details.emptyTable"
      withImageErrorMsg={false}
      title={
        t(
          'front.payslip.details.expense.rejectedExpenses.table.title',
        ) as string
      }
      disabledPagination
      isCollapsed={false}
      isLoading={isLoading}
      data={expensesStep?.rejected_expenses}
      columns={rejectedExpensesColumns}
    />
  );
};
