import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { CraCategorieType } from '../../../../app/spraypaint/payslipFunnel/PayslipFunnelCraStep';
import { mapTypeToText } from './constants/mapTypeToText.constant';
import { ButtonCra } from './fragments/ButtonCra';
import { ExpensePastille } from './fragments/ExpensePastille';
import { SelectCra, SelectValueType } from './SelectCra';

type Props =
  | {
      selectedType: CraCategorieType | undefined;
      onSelectedTypeChange: (type: CraCategorieType | undefined) => void;
      availableSlotTypes?: Array<string>;
      expensesDates: Array<string>;
    }
  | {
      selectedType: CraCategorieType | undefined;
      onSelectedTypeChange?: undefined;
      availableSlotTypes?: Array<string>;
      expensesDates: Array<string>;
    };

const generateOptions = (
  availableSlotTypes?: Array<string>,
): SelectValueType[] => {
  const { t } = useTranslation();
  if (!availableSlotTypes) return [];
  return availableSlotTypes
    .filter(
      (unfilteredType) =>
        unfilteredType !== 'working_days' &&
        unfilteredType !== 'paid_holidays' &&
        unfilteredType !== 'blank' &&
        unfilteredType !== 'telework',
    )
    .reduce(
      (acc: any, item) => [
        ...acc,
        {
          value: item,
          label: t(mapTypeToText[item]),
        },
      ],
      [],
    );
};

export const LeftSectionCra = ({
  onSelectedTypeChange,
  selectedType,
  availableSlotTypes,
  expensesDates,
}: Props) => {
  const { t } = useTranslation();

  const options = generateOptions(availableSlotTypes);

  const handleSelectedButton = onSelectedTypeChange;

  const handleSelectedSelect: (value: SelectValueType | null) => void =
    useCallback(
      (option: SelectValueType | null) => {
        if (option && onSelectedTypeChange) {
          onSelectedTypeChange(option.value);
        } else if (onSelectedTypeChange) {
          onSelectedTypeChange(undefined);
        }
      },
      [onSelectedTypeChange],
    );

  const selectedOption = options.find(
    (option) => option.value === selectedType,
  );

  if (!availableSlotTypes) return null;

  return (
    <div className="flex flex-col ml-[37px] mt-[30px]">
      {availableSlotTypes.includes('working_days') && (
        <ButtonCra
          disabled={!handleSelectedButton}
          type="working_days"
          onClick={handleSelectedButton}
          isSelected={selectedType === 'working_days'}
        />
      )}
      {availableSlotTypes.includes('telework') && (
        <ButtonCra
          disabled={!handleSelectedButton}
          className="mt-[20px]"
          type="telework"
          onClick={handleSelectedButton}
          isSelected={selectedType === 'telework'}
        />
      )}
      {availableSlotTypes.includes('paid_holidays') && (
        <ButtonCra
          disabled={!handleSelectedButton}
          className="mt-[20px]"
          type="paid_holidays"
          onClick={handleSelectedButton}
          isSelected={selectedType === 'paid_holidays'}
        />
      )}
      <SelectCra
        onChange={handleSelectedButton ? handleSelectedSelect : undefined}
        value={selectedOption}
        selectedType={selectedType}
        options={options}
        className="mt-[20px]"
        placeholder={t('views.cra.other')}
      />

      {expensesDates && expensesDates?.length > 0 && (
        <div className="flex self-end items-center mt-[30px]">
          <ExpensePastille className="mr-[7px]" absolute={false} />
          <p>{t('views.dashboard.expenses')}</p>
        </div>
      )}
    </div>
  );
};
