import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class CapGeminiProvisionalInvoiceCreation extends ApplicationRecord {
  static jsonapiType = 'capgemini-provisional_invoice_creations';

  // Attributes
  @Attr() consolidationInvoiceItemImportId!: string;
  @Attr({ persist: false }) invoiceId!: string;

  // Extra-attributes

  // Relationships
}

export default CapGeminiProvisionalInvoiceCreation;
