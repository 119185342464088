import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import { MainCard } from '../../../../common/components/mainCard';
import { LineText } from '../../../../common/components/fragments/LineText';
import { FactorClientPaymentTransaction } from '../../../../../app/spraypaint';
import Status, {
  StatusType,
} from '../../../../common/components/statuses/Status';
import { MoneyType } from '../../../../common/utils/type.utils';

type Props = {
  factorClientPaymentTransaction: FactorClientPaymentTransaction | undefined;
  entityName: string | undefined;
};

export const FactorClientPaymentInformationCard = ({
  factorClientPaymentTransaction,
  entityName,
}: Props) => {
  const { t } = useTranslation();
  return (
    <MainCard
      header={t('front.mainCard.header.defaultTitle')}
      className="mr-5 md:w-[800px]"
    >
      <LineText
        label={t('front.manualReconciliation.selectedInvoice.label.dueDate')}
        value={
          dayjs(factorClientPaymentTransaction?.bookingDate as string).format(
            'L',
          ) || '-'
        }
      />
      <LineText
        label={t('front.bankAssociation.show.card.debtorName')}
        value={factorClientPaymentTransaction?.issuerName || '-'}
      />
      <LineText
        label="IBAN du débiteur :"
        value={factorClientPaymentTransaction?.issuerIban || '-'}
      />
      <LineText
        label={t('front.bankAssociation.show.card.label.label')}
        value={factorClientPaymentTransaction?.label || '-'}
      />
      <LineText
        label={t('views.legal_entity.label')}
        value={entityName || '-'}
      />
      <LineText
        label={t('front.bankAssociation.show.card.label.amount')}
        value={
          <AmountWithCurrency
            amount={factorClientPaymentTransaction?.amount as MoneyType}
          />
        }
      />
      <LineText
        label={t('front.bankAssociation.show.card.label.amountEuro')}
        value={
          <AmountWithCurrency
            amount={factorClientPaymentTransaction?.amountEuro as MoneyType}
          />
        }
      />
      {factorClientPaymentTransaction?.status === 'pending_validation' && (
        <LineText
          label={t('front.sepa.show.card.label.status')}
          value={
            <Status
              statusType={
                factorClientPaymentTransaction?.matchingStatus as StatusType
              }
            />
          }
        />
      )}
    </MainCard>
  );
};
