import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { CommentableType } from '@/app/spraypaint/Comment';
import { FaMessage } from 'react-icons/fa6';
import { Button } from '../buttons';
import { CommentFormValue, CommentList } from './CommentList';
import { useAddComment } from './hooks/useAddComment';
import { useGetComments } from './hooks/useGetComments';
import { AbsoluteShowNumber } from '../fragments/AbsoluteShowNumber';

type Props = {
  commentableId?: string;
  commentableType: CommentableType;
};

export const CommentWidget = ({ commentableId, commentableType }: Props) => {
  const queryClient = useQueryClient();
  const callBack = useCallback(
    () => queryClient.invalidateQueries(['get-comments']),
    [queryClient],
  );
  const { mutate: addComment, isLoading, isSuccess } = useAddComment(callBack);
  const handleAddComment = (commentFormData: CommentFormValue) => {
    const { content } = commentFormData;
    addComment({
      commentableId,
      content,
      commentableType,
    });
  };

  const { comments } = useGetComments({
    commentableType,
    commentableId,
  });
  const [isOpen, setIsOpen] = useState(false);

  const handleSetIsOpen = () => setIsOpen((prev) => !prev);
  return (
    <div className="fixed z-[100] top-[350px] right-[20px] flex">
      <AbsoluteShowNumber
        itemCount={comments?.length}
        className="bg-color-pone-orange"
      />
      {isOpen && (
        <CommentList
          comments={comments}
          onSubmit={handleAddComment}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      )}
      <Button
        custom
        onClick={handleSetIsOpen}
        className="bg-white h-[50px] w-[50px] rounded-full border-[1px] border-color-medium-grey bg-color-pone-dark flex justify-center items-center"
      >
        <div className="text-xxl">
          <FaMessage color="white" className="h-[22px]" />
        </div>
      </Button>
    </div>
  );
};
