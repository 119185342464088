import { useCallback, useState } from 'react';
import { ColumnFiltersState, Table, Updater } from '@tanstack/react-table';

// Add the table to the context state, this way we can have some context when
// evaluating filters
export interface ContextedColumnFiltersState<TData> extends ColumnFiltersState {
  table?: Table<TData>;
}

export type ContextedColumnFilterUpdater<TData> = (
  updaterOrValue: Updater<ContextedColumnFiltersState<TData>>,
  table?: Table<TData>,
) => void;

export function useControlledColumnFilters<TData>({
  resetPagination,
}: {
  resetPagination: () => void;
}) {
  const [{ columnFiltersState, table }, setFiltersState] = useState<{
    columnFiltersState: ColumnFiltersState;
    table?: Table<TData>;
  }>({ table: undefined, columnFiltersState: [] });

  // This is the function that can be used to update the column filters state.
  // If the provided updater is a function, it will be used to update the
  // column filters state. Otherwise, the provided value will be used to
  // directly update the state. In either case, the pagination will be reset.
  const onColumnFiltersChange: ContextedColumnFilterUpdater<TData> =
    useCallback(
      (updater, newTable) => {
        setFiltersState(({ columnFiltersState: prevFiltersState }) => {
          // Evaluate new filter state
          let newFilterState: ColumnFiltersState;
          if (typeof updater === 'function') {
            newFilterState = updater(prevFiltersState);
          } else {
            newFilterState = updater;
          }

          return {
            columnFiltersState: newFilterState,
            table: newTable,
          };
        });
        resetPagination();
      },
      [setFiltersState],
    );

  return { columnFiltersState, table, onColumnFiltersChange };
}
