import { RoutesMounter } from '@/types';
import { index, member } from '@/app/navigation/functions';
import BankReconciliationIndex from './BankReconcilationIndex';
import { BankAssociationShow } from '../bankAssociationShow/BankAssociationShow';
import {
  loader as sepaTransactionLoader,
  sepaTransactionAssignationLoader,
} from './functions/sepa_transaction_query';
import { BankAssociation } from '../bankAssociation/BankAssociation';

export const mountBankReconciliationRoutes: RoutesMounter<unknown> = ({
  path,
  tools: { t, queryClient },
}) => [
  member({
    path,
    handle: { crumb: () => t('front.bankTransfer.crumbs.index') },
    children: [
      index({ index: true, Component: BankReconciliationIndex }),
      member({
        path: ':id',
        handle: { crumb: ({ data }) => data.data.label },
        loader: sepaTransactionLoader(queryClient),
        children: [
          index({ Component: BankAssociationShow }),
          member({
            path: 'assignation',
            Component: BankAssociation,
            loader: sepaTransactionAssignationLoader(queryClient),
          }),
        ],
      }),
    ],
  }),
];
