import { useTranslation } from 'react-i18next';

export const useValidation = () => {
  const { t } = useTranslation();
  return {
    email: {
      required: { value: true, message: t('front.form.email.required') },
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: t('front.form.email.pattern'),
      },
    },
    password: {
      required: { value: true, message: t('front.form.password.required') },
    },
    anyRequired: {
      required: { value: true, message: t('front.form.field.required') },
    },
  };
};
