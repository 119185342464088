import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FunctionComponent } from 'react';
import { useInvoice } from '../functions/invoice_query';
import { PageLayout } from '../../common/components/PageLayout';

export interface InvoicesEditProps {}

export const InvoicesEdit: FunctionComponent<InvoicesEditProps> = () => {
  const { id } = useParams();
  const { data } = useInvoice(id as string);

  const invoice = data?.data;
  if (!invoice) {
    return <div>loading...</div>;
  }

  const { t } = useTranslation();

  return (
    <PageLayout
      Title={t('front.invoices.edit.header.title', { number: invoice.number })}
    >
      Edit Content
    </PageLayout>
  );
};
