import dayjs from 'dayjs';
import { MoneyType } from './type.utils';

export function isOdd(num: any) {
  return num % 2;
}

export const formatNumber = (num: number) => {
  if (!num) return 0;
  const formattedNumber = (Math.round((num + Number.EPSILON) * 100) / 100)
    .toFixed(2)
    .toString();

  if (formattedNumber === 'NaN' || Number.isNaN(formattedNumber)) return 0;

  return dayjs.locale() === 'fr'
    ? formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')
    : formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNumberWithCurrency = (num: number, currencySymbol: string) =>
  `${formatNumber(num)} ${currencySymbol}`;

export const getNumberColor = (val: number | MoneyType) => {
  if (val === null || val === undefined) return '';
  const amount = typeof val === 'object' && 'amount' in val ? val.amount : val;
  return amount < 0 ? 'text-color-border-failure' : '';
};

export function totalMiniLines(lines: Array<any>): number {
  return lines.reduce((acc, line) => {
    // if has a value with key amount
    if (line.value && line.value.amount) {
      return acc + line.value.amount;
    }

    // if has a value which is a number
    if (line.value && typeof line.value === 'number') {
      return acc + line.value;
    }

    // ignore other lines
    return acc;
  }, 0);
}

export function randomNum(): number {
  return Math.floor(Math.random() * 100);
}
