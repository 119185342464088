import type { FunctionComponent } from 'react';
import {
  CapGeminiConsolidationInvoiceImport,
  CapGeminiConsolidationInvoiceItemImport,
  CapGeminiCorporateInvoiceCreation,
  CommercialContract,
  ModelRecord,
} from '@/app/spraypaint';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import Table, {
  RowSubcomponent,
} from '@/modules/common/components/table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFormatMoney } from '@/app/utils/moneyUtils';
import { PlusIcon } from '@/app/assets/icons/buttons';
import {
  ButtonOnClickHandler,
  ButtonTernary,
  InternalLink,
} from '@/modules/common/components/buttons';
import { useTranslation } from 'react-i18next';
import { ReportProblemIc } from '@/app/assets/icons/others';
import { CopyButton } from '@/modules/common/components/buttons/utils/CopyButton';
import { MoneyType } from '@/modules/common/utils/type.utils';
import { dynT } from '@/modules/common/components/dynT';
import { adminPathBuilder } from '@/modules/common/components/LinkLine';

import { StatusWidget } from '@/modules/admin/capgemini/components/StatusWidget';
import CapGeminiProvisionalInvoiceCreation from '@spraypaint/capgemini/CapGeminiProvisionalInvoiceCreation';
import { ApiValidationError } from '@spraypaint/ApiValidationError';
import { toast } from 'react-toastify';
import {
  LineText,
  LineTextProps,
} from '@/modules/common/components/fragments/LineText';
import Status from '@/modules/common/components/statuses/Status';

export interface ConsolidationInvoiceItemsProps {
  consolidationInvoiceImport: CapGeminiConsolidationInvoiceImport;
}

const copyable = (value: string | null | undefined) =>
  value ? (
    <>
      {value}
      <CopyButton value={value} />
    </>
  ) : (
    '-'
  );

const columnHelper =
  createColumnHelper<ModelRecord<CapGeminiConsolidationInvoiceItemImport>>();

const columns = [
  columnHelper.accessor('id', { header: 'ID' }),
  columnHelper.accessor('externalReference', {
    header: dynT(
      'activerecord.attributes.capgemini/consolidation_invoice_item_import.external_reference',
      { ns: 'rails' },
    ),
    cell: ({ getValue }) => {
      const value = getValue();
      if (!value) return '-';

      return (
        <>
          {value}
          <CopyButton value={value} />
        </>
      );
    },
  }),
  columnHelper.accessor('subcontractorName', {
    header: dynT(
      'activerecord.attributes.capgemini/consolidation_invoice_item_import.subcontractor_name',
      { ns: 'rails' },
    ),
  }),
  columnHelper.accessor('commercialContract', {
    header: dynT(
      'activerecord.attributes.capgemini/consolidation_invoice_item_import.commercial_contract',
      { ns: 'rails' },
    ),
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ getValue, row: { original } }) => {
      const commercialContract = getValue() as CommercialContract | undefined;
      return commercialContract ? (
        <InternalLink href={commercialContract.employeePagePath}>
          {commercialContract.codeName}
        </InternalLink>
      ) : (
        <div className="fill-color-failure text-color-failure">
          <ReportProblemIc className="inline-block" /> Pas de contrat commercial
          référence{' '}
          <b>
            {original.workOrderId}
            <CopyButton value={original.workOrderId} />
          </b>
        </div>
      );
    },
  }),
  columnHelper.accessor('date', {
    header: dynT(
      'activerecord.attributes.capgemini/consolidation_invoice_item_import.date',
      { ns: 'rails' },
    ),
  }),
  columnHelper.accessor('totalAmount', {
    header: dynT(
      'activerecord.attributes.capgemini/consolidation_invoice_item_import.total_amount',
      { ns: 'rails' },
    ),
    enableColumnFilter: false,
    cell: ({ getValue }) => {
      const formatMoney = useFormatMoney();
      const value = getValue();
      if (value === undefined) return null;
      return formatMoney(value as MoneyType);
    },
  }),
  columnHelper.accessor('importStatus', {
    header: dynT(
      'activerecord.attributes.capgemini/consolidation_invoice_item_import.import_status',
      { ns: 'rails' },
    ),
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row: { original } }) => (
      <StatusWidget
        status={original.importStatus}
        errors={original.importErrors}
        warnings={original.importWarnings}
      />
    ),
  }),
  columnHelper.accessor('invoiceStatus', {
    header: dynT(
      'activerecord.attributes.capgemini/consolidation_invoice_item_import.invoice_status',
      { ns: 'rails' },
    ),
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ row: { original } }) =>
      original.invoiceStatus ? (
        <Status statusType={original.invoiceStatus} className="my-1" />
      ) : (
        '-'
      ),
  }),
  columnHelper.accessor('invoice', {
    header: dynT(
      'activerecord.attributes.capgemini/consolidation_invoice_item_import.provisional_invoice',
      { ns: 'rails' },
    ),
    enableSorting: false,
    enableColumnFilter: false,
    cell: ({ getValue, row: { original } }) => {
      const invoice = getValue();
      const { t } = useTranslation();
      const queryClient = useQueryClient();

      const { mutate, isLoading } = useMutation({
        mutationKey: ['createProvisionalInvoice', original.id],
        mutationFn: async () => {
          const service = new CapGeminiProvisionalInvoiceCreation({
            consolidationInvoiceItemImportId: original.id,
          });

          return service.saveOrThrow();
        },
        onSuccess: () =>
          queryClient.invalidateQueries({
            queryKey: [
              'consolidationInvoiceItems',
              String(original.consolidationInvoiceId),
            ],
          }),
        onError(error) {
          if (
            ApiValidationError.isInstance(
              error,
              CapGeminiCorporateInvoiceCreation,
            )
          ) {
            Object.keys(error.errors).forEach((e) => {
              toast.error(error.errors[e]?.fullMessage);
            });
          }
        },
      });

      // eslint-disable-next-line no-nested-ternary
      return invoice ? (
        <InternalLink
          {...invoice.linkProps({
            pathBuilder: adminPathBuilder,
          })}
        />
      ) : original.totalAmount?.amount ? (
        <ButtonTernary
          Icon={PlusIcon}
          className="!pr-0 !pt-0 !pb-0 !pl-0"
          disabled={
            !original.commercialContract || !original.corporateInvoiceId
          }
          onClick={mutate as unknown as ButtonOnClickHandler}
          isLoading={isLoading}
        >
          {t('front.capgemini.imports.button.create_provisional_invoice')}
        </ButtonTernary>
      ) : null;
    },
  }),
];

const lineStyle: LineTextProps['style'] = {
  valueClassName: 'text-right text-slate-400',
  labelClassName: 'text-slate-600',
  containerClassName: 'w-5/6',
};
const Subcomponent: RowSubcomponent<
  ModelRecord<CapGeminiConsolidationInvoiceItemImport>
> = ({ original }) => {
  const formatMoney = useFormatMoney();

  return (
    <div className="grid grid-cols-2 xl:grid-cols-4">
      <LineText
        label="externalReference"
        value={copyable(original.externalReference)}
        style={lineStyle}
      />
      <LineText
        label="currency"
        value={copyable(original.currency)}
        style={lineStyle}
      />
      <LineText
        label="workOrderId"
        value={copyable(original.workOrderId)}
        style={lineStyle}
      />
      <LineText
        label="workerId"
        value={copyable(original.workerId)}
        style={lineStyle}
      />
      <LineText
        label="subcontractorName"
        value={copyable(original.subcontractorName)}
        style={lineStyle}
      />
      <LineText
        label="date"
        value={copyable(original.date)}
        style={lineStyle}
      />
      <LineText
        label="daysCount"
        value={copyable(String(original.daysCount ?? '-'))}
        style={lineStyle}
      />
      <LineText
        label="totalAmount"
        value={copyable(formatMoney(original.totalAmount))}
        style={lineStyle}
      />
      <LineText
        label="dailyRate"
        value={copyable(String(original.dailyRate ?? '-'))}
        style={lineStyle}
      />
      <LineText
        label="timesheetId"
        value={copyable(original.timesheetId)}
        style={lineStyle}
      />
      <LineText
        label="timesheetStartDate"
        value={copyable(original.timesheetStartDate)}
        style={lineStyle}
      />
      <LineText
        label="timesheetEndDate"
        value={copyable(original.timesheetEndDate)}
        style={lineStyle}
      />
      <LineText
        label="expenseRef"
        value={copyable(original.expenseRef)}
        style={lineStyle}
      />
      <LineText
        label="expenseName"
        value={copyable(original.expenseName)}
        style={lineStyle}
      />
      <LineText
        label="expenseAmount"
        value={copyable(formatMoney(original.expenseAmount))}
        style={lineStyle}
      />
      <LineText
        label="commercialContractId"
        value={copyable(original.commercialContractId)}
        style={lineStyle}
      />
      <LineText
        label="invoiceId"
        value={copyable(original.invoiceId)}
        style={lineStyle}
      />
      <LineText
        label="corporateInvoiceId"
        value={copyable(original.corporateInvoiceId)}
        style={lineStyle}
      />
      <LineText
        label="subcontractorDailyRate"
        value={copyable(formatMoney(original.subcontractorDailyRate))}
        style={lineStyle}
      />
      <LineText
        label="expectedInvoiceAmount"
        value={copyable(formatMoney(original.expectedInvoiceAmount))}
        style={lineStyle}
      />
      <LineText
        label="invoiceTotalAmount"
        value={copyable(formatMoney(original.invoiceTotalAmount))}
        style={lineStyle}
      />
      <LineText
        label="invoiceDate"
        value={copyable(original.invoiceDate)}
        style={lineStyle}
      />
      <LineText
        label="portageFeeRate"
        value={copyable(`${String(original.portageFeeRate)} %`)}
        style={lineStyle}
      />
      <LineText
        label="tundraFeeRate"
        value={copyable(`${String(original.tundraFeeRate)} %`)}
        style={lineStyle}
      />
      <LineText
        label="contractDailyRate"
        value={copyable(formatMoney(original.contractDailyRate))}
        style={lineStyle}
      />
      <LineText
        label="theoricalFeeRate"
        value={copyable(`${String(original.theoricalFeeRate)} %`)}
        style={lineStyle}
      />
    </div>
  );
};

export const ConsolidationInvoiceItems: FunctionComponent<
  ConsolidationInvoiceItemsProps
> = ({ consolidationInvoiceImport }) => {
  const [scope, scopeKey, reactTableProps] =
    useBackendTable<CapGeminiConsolidationInvoiceItemImport>({
      initialPageSize: 50,
    });

  const { data, isLoading } = useQuery({
    queryKey: [
      'consolidationInvoiceItems',
      consolidationInvoiceImport.id,
      scopeKey,
    ],
    queryFn: () =>
      scope(
        CapGeminiConsolidationInvoiceItemImport.includes([
          'commercial_contract',
          'invoice',
        ])
          .select({
            commercial_contracts: ['id', 'title', 'codeName', 'employeeId'],
            invoices: ['id', 'number'],
          })
          .where({
            consolidationInvoiceId: consolidationInvoiceImport.id,
          })
          .selectExtra({
            [CapGeminiConsolidationInvoiceItemImport.jsonapiType]: [
              'import_status',
              'import_errors',
              'import_warnings',
            ],
          }),
      ).all(),
    refetchOnWindowFocus: false,
  });

  const totalCount = data?.meta.stats.total.count;

  return (
    <Table
      data={data?.data ?? []}
      isLoading={isLoading}
      columns={columns}
      reactTableProps={reactTableProps}
      tableStyle="compact"
      totalCount={totalCount}
      RowSubcomponent={Subcomponent}
      onRowClick="expand"
    />
  );
};
