// api/BankAccount.ts
import { Model, Attr } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';

@Model()
class BankAccount extends ApplicationRecord {
  static jsonapiType = 'bank_accounts';

  // Attributes
  @Attr({ persist: false }) accountHolder!: string;
  @Attr({ persist: false }) iban!: string;
  @Attr({ persist: false }) rib!: string;
  @Attr({ persist: false }) bic!: string;
  @Attr({ persist: false }) domiciliation!: string;
  @Attr({ persist: false }) address!: string;
  @Attr({ persist: false }) createdAt!: string;

  // Relationships
}

export default BankAccount;
