import { useQuery } from '@tanstack/react-query';
import { PortageOffer } from '@/app/spraypaint';
import { CollectionProxy } from 'spraypaint/lib-esm/proxies';

export const usePortageOffers = ({
  legalEntityId,
  onSuccess,
}: {
  legalEntityId: number | null | undefined;
  onSuccess?: (data: CollectionProxy<PortageOffer>) => void;
}) => {
  const { data } = useQuery(
    ['portage-offers', legalEntityId],
    () =>
      PortageOffer.per(500)
        .where({ legal_entity_id: legalEntityId, publicly_available: true })
        .all(),
    {
      staleTime: 1000 * 60 * 30,
      cacheTime: 0,
      enabled: !!legalEntityId,
      onSuccess,
    },
  );

  const portageOffers = data?.data || [];

  const portageOffersOptions = portageOffers.map((o: PortageOffer) => ({
    label: o.name,
    value: String(o.id),
    offer: o,
  }));

  return {
    portageOffers,
    portageOffersOptions,
  };
};

export const usePortageOffer = (
  id: string,
  legalEntityId: number | null | undefined,
) => {
  const { portageOffers } = usePortageOffers({ legalEntityId });
  const portageOffer = portageOffers.find((offer) => String(offer.id) === id);
  const portageOfferName = portageOffer?.name;
  return { portageOfferName };
};
