import { Attr, BelongsTo, Model } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';
import type InvoiceDraft from './InvoiceDraft';

@Model()
class InvoiceDraftLine extends ApplicationRecord {
  static jsonapiType = 'invoice_draft_lines';

  // Attributes
  @Attr() invoiceDraftId!: number;
  @Attr() label!: string;
  @Attr() quantity!: number;
  @Attr() unitPrice!: MoneyType;
  @Attr({ persist: false }) totalPrice!: unknown;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;

  // Extra-attributes

  // Relationships
  @BelongsTo('invoice_drafts') invoiceDraft!: InvoiceDraft;
}

export default InvoiceDraftLine;
