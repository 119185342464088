import { IlluTableauVide } from '../../../../../../app/assets/icons/table';

export const EmptyDataMobileLayout = ({
  emptyDataErrorMsg = 'front.table.defaultErrorMsg',
}: {
  emptyDataErrorMsg: string;
}) => (
  <div className="bg-color-light-grey justify-between">
    <p className="text-bold w-[280px] text-sm2 text-center pt-3 mb-3">
      {emptyDataErrorMsg}
    </p>
    <IlluTableauVide data-testid="illu" width={280} height={204} />
    <div className="w-[354px]" />
  </div>
);
