import { useTranslation } from 'react-i18next';

const useEmploymentTypes = () => {
  const { t } = useTranslation();

  const employmentTypes = [
    { label: t('views.payslip_simulation.employment_type.CDI'), value: 'CDI' },
    { label: t('views.payslip_simulation.employment_type.CDD'), value: 'CDD' },
  ];

  return { employmentTypes };
};
export default useEmploymentTypes;
