import { useForm } from 'react-hook-form';
import { User } from '@/app/spraypaint';
import { TextInput } from '@/modules/common/components/inputs';
import { useTranslation } from 'react-i18next';
import { ButtonPrimary } from '@/modules/common/components/buttons';

export type UserFormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

const UserForm = ({
  user,
  onSubmit,
}: {
  user: User;
  onSubmit: (data: UserFormValues) => Promise<void>;
}) => {
  const { handleSubmit, register } = useForm<UserFormValues>({
    defaultValues: user,
  });

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        {...register('lastName')}
        label={t('activerecord.attributes.user.last_name') as string}
        className="mt-[33px]"
      />
      <TextInput
        {...register('firstName')}
        label={t('activerecord.attributes.user.first_name') as string}
        className="mt-[33px]"
      />
      <TextInput
        {...register('email')}
        label={t('activerecord.attributes.user.email') as string}
        className="mt-[33px]"
      />
      <ButtonPrimary isLoading={false} className="mt-[51px]" type="submit">
        {t('front.auth.passwordEdit.form.submit')}
      </ButtonPrimary>
    </form>
  );
};
export default UserForm;
