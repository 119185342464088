// api/Invoice.ts
import { Attr, BelongsTo, Model } from 'spraypaint';
import type { MoneyType, PeriodType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';
import LegalEntity from './LegalEntity';

export type StatusEnum = 'in_progress' | 'paid';

@Model()
class SepaImport extends ApplicationRecord {
  static jsonapiType = 'sepa_imports';

  // Attributes
  @Attr({ persist: false }) legalEntityId!: number;
  @Attr({ persist: false }) payslipPeriod!: PeriodType;
  @Attr({ persist: false }) totalEntries!: number;
  @Attr({ persist: false }) reference!: string;
  @Attr({ persist: false }) totalAmount!: MoneyType;
  @Attr({ persist: false }) remainingAmount!: MoneyType;
  @Attr({ persist: false }) paymentDate!: string;
  @Attr({ persist: false }) payslipIds!: Array<number>;
  @Attr({ persist: false }) status!: StatusEnum;
  @Attr({ persist: false }) createdAt!: string;

  // Relationships
  @BelongsTo('legal_entities') subcontractor!: LegalEntity;

  get adminPagePath(): string | undefined {
    const { id } = this;

    if (!id) {
      return undefined;
    }

    return `/sepa_imports/${id}`;
  }

  // Use a method to comply with the expected interface
  // eslint-disable-next-line class-methods-use-this
  get associableType(): string {
    return 'SepaImport';
  }

  get currencyIsoCode(): string | undefined {
    const { totalAmount } = this;
    return totalAmount.currency;
  }
}

export default SepaImport;
