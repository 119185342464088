import { SepaFile } from '../../../../../app/spraypaint';

export const createSepaFile = async (payload: {
  sourceFileSignedId: string | undefined;
}): Promise<SepaFile> => {
  const file = new SepaFile(payload);
  try {
    await file.save();
  } catch {
    throw new Error('Sepa file creation error');
  }
  return file;
};
