import { ReactNode } from 'react';

type Props = {
  children: string | ReactNode;
};

export const AuthMainContainer = ({ children }: Props) => (
  <div className="flex flex-col md:ml-[111px] mt-[20px] md:mt-[50px] md:pb-[23px]">
    {children}
  </div>
);
