import {
  FilterDownIcon,
  FilterUpIcon,
} from '../../../../../app/assets/icons/buttons';

type Props = {
  onIsCollapsedChange: (newState: boolean) => void;
  isCollapsed: boolean;
  className: string;
};

export const CollapseButton = ({
  className,
  isCollapsed,
  onIsCollapsedChange,
}: Props) => (
  <button
    type="button"
    onClick={() => onIsCollapsedChange(!isCollapsed)}
    className="w-[50px] flex justify-center border-l-2 h-full items-center border-color-light-grey"
  >
    {isCollapsed ? (
      <FilterUpIcon
        data-testid="filter-down-icon"
        className={`${className} rotate-90`}
      />
    ) : (
      <FilterDownIcon data-testid="filter-up-icon" className={className} />
    )}
  </button>
);
