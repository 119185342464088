import { useQuery } from '@tanstack/react-query';
import { Scope } from 'spraypaint';
import FactorTransfer from '@/app/spraypaint/factor/FactorTransfer';

const useFactorTransfers = (
  scope: (baseScope: Scope<FactorTransfer>) => Scope<FactorTransfer>,
  scopeKey: unknown,
) => {
  const data = useQuery({
    queryKey: ['factor-transfers', scopeKey],
    queryFn: () =>
      scope(
        FactorTransfer.includes([
          'factoring_contract.legal_entity',
          'factoring_contract.bank_account',
        ]).scope(),
      ).all(),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  });

  const factorTransfers = data?.data?.data;
  const totalCount = data?.data?.meta.stats.total.count;

  return { ...data, factorTransfers, totalCount };
};

export default useFactorTransfers;
