import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { Button, ButtonProps } from './Button';
import { LoaderIcon } from '../../../../app/assets/icons/inputs';
import { IconType } from '../../utils/type.utils';

export type ButtonTernaryProps = {
  children: string;
  className?: string;
  disabled?: boolean;
  Icon?: IconType;
  iconClassName?: string;
} & ButtonProps;

export const ButtonTernary: FunctionComponent<ButtonTernaryProps> = ({
  children,
  className,
  disabled,
  Icon,
  iconClassName,
  isLoading,
  ...props
}) => {
  const customClassName = classNames(
    'flex items-center justify-center pt-2.5 pb-2.5',
    {
      'fill-color-disabled-text': disabled,
      'fill-color-pone-orange hover:fill-color-pone-orange active:fill-color-dark-orange':
        !disabled,
    },
    {
      'text-color-disabled-text': disabled,
      'text-color-pone-dark hover:text-color-pone-orange active:text-color-dark-orange':
        !disabled,
    },
    className,
  );

  const customIconClassName = classNames('w-6 h-6', iconClassName);

  const customTextStyle = classNames('uppercase text-sm2 font-bold');

  return (
    <Button className={customClassName} disabled={disabled} {...props}>
      {isLoading ? (
        <LoaderIcon
          className="animate-spin fill-color-pone-dark"
          data-testid="loading-spinner"
          height={20}
          width={20}
        />
      ) : (
        <>
          {Icon && (
            <div className="mr-2">
              <Icon className={customIconClassName} />
            </div>
          )}
          <p className={customTextStyle}>{children}</p>
        </>
      )}
    </Button>
  );
};
