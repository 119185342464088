import { Attr, Model } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class FinalStep extends ApplicationRecord {
  // Attributes
  static jsonapiType = 'onboarding_funnel_steps-final_steps';
  static endpoint = '/onboarding_funnel_steps/final_steps';

  // Attributes
  @Attr({ persist: false }) signatureProcedureUrl: string;
  @Attr() cellphone: string;
  @Attr({ persist: false }) portageAgreementGenerated: boolean;
}
export default FinalStep;
