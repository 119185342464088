import FactorTransferable from '@/app/spraypaint/factor/FactorTransferable';
import { GraphitiScoper } from '@/app/spraypaint/types';
import { useQuery } from '@tanstack/react-query';

const useFactorTransferables = ({
  factoringContractId,
  startDate = '',
  endDate = '',
  scopeKey,
  scope,
}: {
  factoringContractId: number | undefined;
  startDate: string;
  endDate: string;
  scopeKey: Array<string>;
  scope: GraphitiScoper<FactorTransferable>;
}) => {
  const data = useQuery({
    queryKey: [
      'factor_transferables',
      scopeKey,
      factoringContractId,
      startDate,
      endDate,
    ],
    queryFn: () =>
      scope(FactorTransferable.per(10))
        .where({ factoringContractId, date: { gte: startDate, lte: endDate } })
        .all(),
    enabled: !!factoringContractId,
    refetchOnWindowFocus: false,
  });

  return data;
};

export default useFactorTransferables;
