import { FunctionComponent } from 'react';
import { useCurrentEmployeeId } from '@/app/hooks/useCurrentEmployeeId';
import { useCurrentEmployee } from '@/modules/authentification/hooks';
import { LinkPropsBuilder, PathBuilder } from '@/types';
import { InternalLink } from '@/modules/common/components/buttons';
import {
  AttributeLine,
  AttributeLineProps,
} from '@/modules/common/components/AttributeLine';
import { ApplicationRecord } from '@/app/spraypaint';

export type LinkLineProps = {
  target: ApplicationRecord | null | undefined;
} & Omit<AttributeLineProps, 'value'>;

export const adminPathBuilder: PathBuilder = (path) => `/v2/admin${path}`;

export const LinkLine: FunctionComponent<LinkLineProps> = ({
  target,
  ...props
}) => {
  const employeeId = useCurrentEmployeeId();
  const { employeePath } = useCurrentEmployee();

  const pathBuilder = employeeId ? employeePath : adminPathBuilder;
  let value = null;
  // if respond to linkProps
  if (target) {
    if ('linkProps' in target && typeof target.linkProps === 'function') {
      const linkProps = target.linkProps as LinkPropsBuilder;
      value = <InternalLink {...linkProps({ pathBuilder })} />;
    } else if ('name' in target) {
      value = target.name as string;
    } else if ('title' in target) {
      value = target.title as string;
    }
  }

  return <AttributeLine {...props} value={value} />;
};
