// api/CapGeminiTundraInvoiceCreation.ts
import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class CapGeminiTundraInvoiceCreation extends ApplicationRecord {
  static jsonapiType = 'capgemini-tundra_invoice_creations';

  // Attributes
  @Attr() date!: string;
  @Attr() consolidationInvoiceImportId!: number;
  @Attr({ persist: false }) invoiceId!: number;

  // Extra-attributes

  // Relationships
}

export default CapGeminiTundraInvoiceCreation;
