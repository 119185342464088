import classNames from 'classnames';
import { StepperLineProps } from './types/StepperTypes';

export const StepperLine = ({ className, optionProps }: StepperLineProps) => {
  const customClassName = classNames(
    {
      'flex-1 grow': !optionProps.vertical,
      'bg-color-pone-orange h-[1px]':
        optionProps.hasBeenDone && !optionProps.vertical,
      'bg-color-medium-grey h-[1px]':
        !optionProps.hasBeenDone && !optionProps.vertical,
    },
    className,
  );

  const verticalLineClassName = classNames(
    {
      'w-[2px] h-[24px] bg-color-pone-orange ml-[11px] my-[3px]':
        optionProps.vertical && optionProps.hasBeenDone,
      'w-[2px] h-[24px] bg-color-medium-grey ml-[11px] my-[3px]':
        optionProps.vertical && !optionProps.hasBeenDone,
    },
    className,
  );

  if (optionProps.vertical) return <div className={verticalLineClassName} />;

  return <div className={customClassName} />;
};
