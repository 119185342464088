import { Table } from '@tanstack/react-table';
import { HeaderCell } from './HeaderCell';

type Props<TData> = {
  table: Table<TData>;
};

export function HeaderRow<TData>({ table }: Props<TData>) {
  return (
    <thead className="relative">
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id} className="bg-color-medium-light h-10">
          {headerGroup.headers.map((header) => (
            <th key={header.id} className="h-full">
              <HeaderCell header={header} />
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
}
