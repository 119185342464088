import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

@Model()
class SecuritisToggler extends ApplicationRecord {
  static jsonapiType = 'invoicing-securitis_togglers';

  @Attr() invoiceIds!: Array<number>;
}

export default SecuritisToggler;
