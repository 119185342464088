import { Row, RowData } from '@tanstack/react-table';
import { MouseEventHandler, useCallback } from 'react';
import { RowClickCallback } from '@/modules/common/components/table/types';
import { useHref, useNavigate } from 'react-router-dom';

interface IWithId {
  id: string | number;
}

function hasId(obj: unknown): obj is IWithId {
  return (
    (obj as IWithId).id !== undefined &&
    (typeof (obj as IWithId).id === 'string' ||
      typeof (obj as IWithId).id === 'number')
  );
}

export type OnRowClickType<TData extends RowData> =
  | 'none'
  | 'expand'
  | 'navigate'
  | { navigate: ((rowData: TData) => string | undefined) | true }
  | RowClickCallback<TData>;

export type NormalizedOnRowClickType =
  | { action: 'none' }
  | { action: 'navigate'; to: string }
  | { action: 'callback'; callback: MouseEventHandler<HTMLTableRowElement> };

function extractToFromNavigateOnClick<TData>(
  rowData: TData,
  navigateOnClick: ((rowData: TData) => string | undefined) | true,
): string | undefined {
  if (typeof navigateOnClick === 'function') {
    return navigateOnClick(rowData);
  }
  if (navigateOnClick && hasId(rowData)) {
    return `./${rowData.id}`;
  }

  return '.';
}

type LimitedMouseEvent = Pick<
  MouseEvent,
  'button' | 'metaKey' | 'altKey' | 'ctrlKey' | 'shiftKey'
>;

function isModifiedEvent(event: LimitedMouseEvent) {
  return (
    event.metaKey ||
    event.altKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.button !== 0
  );
}

export function isWatchedButton(
  event: React.MouseEvent<HTMLTableRowElement>,
): boolean {
  console.log({ button: event.button });
  return event.button === 0 || event.button === 1;
}

export function isIgnoredTarget(
  event: React.MouseEvent<HTMLTableRowElement>,
): boolean {
  let target = event.target as HTMLElement;
  // Traverse up the DOM tree until you find the clicked element or reach the row
  while (target && target !== event.currentTarget) {
    // Check if the target or any of its parents is a button or an anchor
    if (target.tagName === 'BUTTON' || target.tagName === 'A') {
      // If so, return and do not execute the rest of the handler
      return true;
    }
    target = target.parentElement as HTMLElement;
  }
  return false;
}

export function shouldProcessLinkClick(
  event: React.MouseEvent<HTMLTableRowElement>,
) {
  return isWatchedButton(event) && !isIgnoredTarget(event);
}

export function useOnRowClickTypeNormalizer<TData extends RowData>(
  OnRowClick: OnRowClickType<TData> | undefined,
): (row: Row<TData>) => NormalizedOnRowClickType {
  return useCallback(
    (row: Row<TData>) => {
      const { original } = row;
      switch (OnRowClick) {
        case 'none':
        case undefined:
          return { action: 'none' };
        case 'expand':
          return {
            action: 'callback',
            callback: () => {
              row.toggleExpanded();
            },
          };
        case 'navigate':
          return {
            action: 'navigate',
            to: extractToFromNavigateOnClick(original, true) ?? '.',
          };
        default:
          break;
      }

      if (typeof OnRowClick === 'function') {
        return { action: 'callback', callback: OnRowClick(original) };
      }

      if (typeof OnRowClick === 'object') {
        const navigateTo = extractToFromNavigateOnClick(
          original,
          OnRowClick.navigate,
        );

        if (navigateTo) {
          return { action: 'navigate', to: navigateTo };
        }
      }

      return { action: 'none' };
    },
    [OnRowClick],
  );
}

function isTextSelected(): boolean {
  return !!window.getSelection()?.toString();
}

export function useRowClickHandler(
  normalizedOnRowClick: NormalizedOnRowClickType,
): MouseEventHandler<HTMLTableRowElement> | undefined {
  const to =
    normalizedOnRowClick.action === 'navigate' ? normalizedOnRowClick.to : '.';
  const toHref = useHref(to);
  const navigate = useNavigate();

  if (normalizedOnRowClick.action === 'none') return undefined;

  return (event) => {
    // If text is selected, do not execute the rest of the handler
    if (isTextSelected()) return;
    if (!shouldProcessLinkClick(event)) return;

    // All event handlers bellow this should prevent default
    event.preventDefault();
    event.stopPropagation();

    if (normalizedOnRowClick.action === 'callback') {
      normalizedOnRowClick.callback(event);
    }

    if (normalizedOnRowClick.action === 'navigate') {
      if (isModifiedEvent(event)) {
        // build a <a> tag and trigger a click on it with the same modifiers
        const link = document.createElement('a');
        link.href = toHref;
        const clickEvent = new MouseEvent('click', {
          // Set the modifier keys
          ctrlKey: event.ctrlKey,
          shiftKey: event.shiftKey,
          metaKey: event.metaKey,
          altKey: event.altKey,
          button: event.button,
        });
        link.dispatchEvent(clickEvent);
        // cleanup
        link.remove();
      } else {
        navigate(to);
      }
    }
  };
}
