import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { FileType } from '@/modules/common/utils/type.utils';
import { IcnFichePaie, IcnVisibility } from '../../../app/assets/icons/payslip';
import Payslip from '../../../app/spraypaint/Payslip';
import { getDateFromPeriod } from '../../../app/utils/dateUtils';
import {
  DownloadButton,
  IconButtonSecondary,
} from '../../common/components/buttons';
import {
  getPeriodFilterFn,
  PeriodFilter,
} from '../../common/components/table/filter';
import {
  EqualFilter,
  getEqualFilter,
} from '../../common/components/table/filter/EqualFilter';
import { selectUserRole } from '../../../app/store/selectors/selectUserId';
import { EditIcon } from '../../../app/assets/icons/buttons';

const columnHelper = createColumnHelper<ModelRecord<Payslip>>();

export const payslipColumns = () => {
  const { t } = useTranslation();
  const primaryRole = selectUserRole();

  const ActionsCell = ({
    cell,
  }: CellContext<ModelRecord<Payslip>, unknown>) => {
    const { employeeId, id, craId, fileName, fileUrl, status } =
      cell.row.original;

    return (
      <div className="flex justify-center items-center h-[100%]">
        {primaryRole !== 'employee' && (
          <IconButtonSecondary
            className="mx-4"
            Icon={IcnVisibility}
            href={`/employees/${employeeId}/payslips/${id}/admin`}
          />
        )}
        {primaryRole !== 'employee' && status === 'submitted' && (
          <IconButtonSecondary
            className="mx-4"
            Icon={EditIcon}
            href={`/employees/${employeeId}/cras/${craId}/edit`}
          />
        )}
        {fileUrl && (
          <DownloadButton
            customClass="mx-4"
            Icon={IcnFichePaie}
            file={{ name: fileName, url: fileUrl } as FileType}
          />
        )}
      </div>
    );
  };

  return [
    columnHelper.accessor('period', {
      header: t('front.payslip.index.table.columns.period') as string,
      filterFn: getPeriodFilterFn(),
      cell: (info) => {
        const text = getDateFromPeriod({
          month: info.getValue()?.month as number,
          year: info.getValue()?.year as number,
        });

        return (
          <div className="h-[100%] flex justify-center items-center">
            <p>{text}</p>
          </div>
        );
      },
      meta: {
        filter: {
          Component: PeriodFilter,
        },
      },
    }),
    columnHelper.accessor('workingHours', {
      header: t('front.payslip.index.table.columns.workingHours') as string,
      filterFn: getEqualFilter(),
      meta: {
        filter: {
          Component: EqualFilter,
        },
      },
    }),
    columnHelper.accessor('grossSalary', {
      header: t('front.payslip.index.table.columns.grossSalary') as string,
      filterFn: getEqualFilter(),
      meta: {
        filter: {
          Component: EqualFilter,
        },
      },
    }),
    columnHelper.accessor('netSalaryReal', {
      header: t('front.payslip.index.table.columns.netSalaryReal') as string,
      filterFn: getEqualFilter(),
      meta: {
        filter: {
          Component: EqualFilter,
        },
      },
    }),
    columnHelper.accessor('expenses', {
      header: t('front.payslip.index.table.columns.expenses') as string,

      filterFn: getEqualFilter(),
      meta: {
        filter: {
          Component: EqualFilter,
        },
      },
    }),
    columnHelper.accessor('status', {
      header: t('default.status'),
      enableSorting: false,
      enableColumnFilter: false,
    }),

    columnHelper.accessor('actions', {
      header: t('default.actions'),
      enableColumnFilter: false,
      enableSorting: false,
      cell: ActionsCell,
    }),
  ];
};
