import { Attr, HasMany, HasOne, Model } from 'spraypaint';
import { RoleType } from '@/modules/authentification/types/RoleType';
import { Employee } from '@spraypaint/index';
import OnboardingFunnel from '@spraypaint/onBoarding/OnboardingFunnel';
import ApplicationRecord from './ApplicationRecord';
import Prospect from './Prospect';

@Model()
class User extends ApplicationRecord {
  static jsonapiType = 'users';

  // Attributes
  @Attr() email!: string;
  @Attr() civility!: string;
  @Attr() firstName!: string;
  @Attr() lastName!: string;
  @Attr({ persist: false }) primaryRole!: string;
  @Attr({ persist: false }) preferredLanguage!: string;
  @Attr({ persist: false }) createdAt!: string;
  @Attr({ persist: false }) updatedAt!: string;
  @Attr({ persist: false }) roles!: Array<RoleType>;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  // Relationships
  @HasOne(OnboardingFunnel) onboardingFunnel!: OnboardingFunnel;
  @HasOne(Prospect) prospect!: Prospect;
  @HasMany('employees') employees!: Array<Employee>;
}

export default User;
