import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useValidation } from '@/app/form/ValidationForm';
import { useForm } from 'react-hook-form';
import { useLogin } from '@/modules/authentification/hooks';
import { PasswordInput } from '@common/components/inputs';
import { ButtonPrimary, HypertextLink } from '@common/components/buttons';
import { FilledEmailForm } from '@/modules/authentification/components/FilledEmailForm';
import { useQueryErrorHandler } from '@/modules/authentification/hooks/useQueryErrorHandler';
import {
  PasswordFormProps,
  PasswordFormValue,
} from '@/modules/authentification/types/passwordFormTypes';

export const PasswordForm: FunctionComponent<PasswordFormProps> = ({
  email,
  onForgetPassword,
  navigateToEmailForm,
  redirectAfterLogin,
}) => {
  const { t } = useTranslation();
  const Validation = useValidation();

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    setError,
  } = useForm<PasswordFormValue>({ defaultValues: { email } });

  const onQueryError = useQueryErrorHandler({ setError });

  const { mutateAsync } = useLogin({
    mutationKey: ['login', email],
    onSuccess: ({ data: { redirect } }) => {
      redirectAfterLogin(redirect);
    },
    onError: onQueryError,
  });

  const onSubmit = async (data: PasswordFormValue) => {
    await mutateAsync({ login: email, password: data.password });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col items-center md:items-start">
        <FilledEmailForm
          email={email}
          onGoBack={navigateToEmailForm}
          errorText={errors?.email}
        />
        <PasswordInput
          testID="login-input-password"
          label={t('front.auth.signIn.form.password.label') as string}
          placeholder={
            t('front.auth.signIn.form.password.placeholder') as string
          }
          className="mt-[33px]"
          {...register('password', Validation.password)}
          errorText={errors?.password}
        />
        <HypertextLink
          testID="login-link-forgot-password"
          onClick={() => onForgetPassword(email)}
          className="ml-[10px]"
        >
          {t('front.auth.signIn.form.forgotPassword')}
        </HypertextLink>
      </div>
      <div className="flex items-center justify-center md:justify-start mt-[33px]">
        <ButtonPrimary
          testID="login-button-submit"
          type="submit"
          isLoading={isSubmitting}
        >
          {t('front.auth.signIn.form.next.title')}
        </ButtonPrimary>{' '}
      </div>
    </form>
  );
};
