import { AxiosError } from 'axios';
import { ErrorResponse } from '@/modules/authentification/types/ApiResponse';
import { toast } from 'react-toastify';
import { Toaster } from '@common/components/toasts';
import { useTranslation } from 'react-i18next';
import { UseFormSetError } from 'react-hook-form';

import { PasswordFormValue } from '@/modules/authentification/types/passwordFormTypes';

type UseQueryErrorHandlerProps = {
  setError: UseFormSetError<PasswordFormValue>;
};

export const useQueryErrorHandler = ({
  setError,
}: UseQueryErrorHandlerProps) => {
  const { t } = useTranslation();

  return (error: AxiosError<ErrorResponse>) => {
    const errorPassword = error.response?.data?.field_error?.[0] === 'password';
    const errorEmail = error.response?.data?.field_error?.[0] === 'login';

    if (error.response?.status === 502)
      toast.error(
        <Toaster
          textMsg={t('front.auth.signIn.toastMsg.502')}
          toastType="error"
        />,
      );

    if (errorPassword)
      setError('password', {
        type: 'custom',
        message: error.response?.data?.field_error?.[1],
      });
    else if (errorEmail)
      setError('email', {
        type: 'custom',
        message: error.response?.data?.field_error?.[1],
      });
  };
};
