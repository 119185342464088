// api/Expense.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import ApplicationRecord from './ApplicationRecord';
import Employee from './Employee';
import type {
  MoneyType,
  ExpenseStatusEnum,
} from '../../modules/common/utils/type.utils';

@Model()
class Expense extends ApplicationRecord {
  static jsonapiType = 'expenses-expenses';
  static endpoint = '/expenses/expenses';

  @Attr() date!: string;
  @Attr() status!: ExpenseStatusEnum;
  @Attr() description!: string;
  @Attr() memo!: string;
  @Attr() expenseDetailType!: string;
  @Attr() expenseDetailId!: number;
  @Attr() employeeId!: number;
  @Attr() createdAt!: string;
  @Attr() updatedAt!: string;
  @Attr() payslipDate!: string;
  @Attr() authorizedDuplication!: boolean;
  @Attr() address!: string;
  @Attr() commercialContractId!: number;
  @Attr() refusalReason!: string;
  @Attr() canceledExpenseId!: number;
  @Attr() cancellationReason!: string;
  @Attr() currencyIsoCode!: string;
  @Attr() htAmount!: MoneyType;
  @Attr() ttcAmount!: MoneyType;
  @Attr() deductedVat!: MoneyType;
  @Attr() refundAmount!: MoneyType;
  @Attr() refundAmountHt!: MoneyType;
  @Attr() companyVehiclesTaxAmount!: string;
  @Attr() translatedNatureOrType: string;

  // Relationships
  @BelongsTo(Employee) employee!: Employee;
}

export default Expense;
