import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CommentWidget } from '@/modules/common/components/comments/CommentWidget';
import { toast } from 'react-toastify';
import { ModelRecord } from 'spraypaint/lib-esm/model';
import { MouseEvent } from 'react';
import { useUserManagerRole } from '@/modules/authentification/hooks';
import { ImportedInput } from '@/modules/common/components/inputs';
import Table from '../../../common/components/table/Table';
import { useBackendTable } from '../../../common/components/table/hooks';
import { useSepaFile } from './functions/sepa_file_query';
import SepaTransaction from '../../../../app/spraypaint/sepaTransactions/SepaTransaction';
import {
  useGetValidatedSepaTransaction,
  useGetPendingSepaTransaction,
} from './hooks/useGetSepaTransaction';
import {
  awaitingBankTransferColumns,
  validatedBankTransferColumns,
} from './columns/BankTransferColumns';
import { MainCard } from '../../../common/components/mainCard';
import { LineText } from '../../../common/components/fragments/LineText';
import { onRowClickNavigate } from '../../../../app/navigation/functions/onRowClickNavigate';
import { Breadcrumbs } from '../../../navigation/components/Breadcrumbs';
import { handleDownloadFile } from '../../../common/components/buttons/utils/DownloadFunction';
import {
  ButtonOnClickHandler,
  ButtonPrimary,
  ButtonSecondary,
} from '../../../common/components/buttons';
import { refreshFileMatching } from './hooks/useRefreshMatching';
import { Toaster } from '../../../common/components/toasts';
import Status, { StatusType } from '../../../common/components/statuses/Status';
import { MoneyType } from '../../../common/utils/type.utils';
import AccountingJournalExportFromSepaFile from '../../accounting_journal_exports/components/AccountingJournalExportFromSepaFile';
import { useDeleteSepaFile } from './hooks/useDeleteSepaFile';

const BankTransferShow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const isOnlyManager = useUserManagerRole();

  const { data: sepaFileData } = useSepaFile(id as string);
  const stats = sepaFileData?.meta.stats;
  const sepaFile = sepaFileData?.data;

  const [scope, scopeKey, props] = useBackendTable<SepaTransaction>({
    initialSorting: [{ id: 'updated_at', desc: true }],
  });
  const [
    pendingSepaTransactionScope,
    pendingSepaTransactionScopeKey,
    pendingSepaTransactionProps,
  ] = useBackendTable<SepaTransaction>({
    initialPageSize: 30,
    initialSorting: [{ id: 'id', desc: false }],
  });
  const {
    validatedSepaTransactions,
    isLoading: validatedSepaTransactionsLoader,
    totalCount: validatedSepaTransactionsTotalCount,
  } = useGetValidatedSepaTransaction(scopeKey, scope, id as string);
  const {
    pendingSepaTransaction,
    isLoading: pendingSepaTransactionLoader,
    totalCount: pendingSepaTransactionTotalCount,
  } = useGetPendingSepaTransaction(
    pendingSepaTransactionScopeKey,
    pendingSepaTransactionScope,
    id as string,
  );

  const { mutate: deleteSepaFileMutate } = useDeleteSepaFile({
    callBack: () => {
      navigate('/v2/admin/bank_transfer');
    },
  });
  const handleDelateSepaFile = () => {
    deleteSepaFileMutate(sepaFile?.id || '');
  };

  const creditAmount: MoneyType = {
    amount: stats?.total?.credit_amount?.cents,
    currency: stats?.total?.credit_amount?.currency_iso,
  };
  const remainingCreditAmount: MoneyType = {
    amount: stats?.total?.remaining_credit_amount?.cents,
    currency: stats?.total?.remaining_credit_amount?.currency_iso,
  };

  const debitAmount: MoneyType = {
    amount: stats?.total?.debit_amount?.cents,
    currency: stats?.total?.debit_amount?.currency_iso,
  };
  const remainingDebitAmount: MoneyType = {
    amount: stats?.total?.remaining_debit_amount?.cents,
    currency: stats?.total?.remaining_debit_amount?.currency_iso,
  };

  const handleNavigateAssociationPage =
    (raw: ModelRecord<SepaTransaction>) => (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminAssignationShowPagePath,
        event,
        windowNavigationHref: raw.adminAssignationShowPagePath,
        navigate,
      });
    };

  const handleNavigateAssociationShowPage =
    (raw: ModelRecord<SepaTransaction>) => (event: MouseEvent) => {
      onRowClickNavigate({
        id: raw.adminReconciliationShowPagePath,
        event,
        windowNavigationHref: raw.adminReconciliationShowPagePath,
        navigate,
      });
    };

  const handleDownload: ButtonOnClickHandler = (event) => {
    handleDownloadFile({
      event,
      file: {
        name: sepaFile?.filename as string,
        url: sepaFile?.link as string,
      },
    });
  };

  const { mutate } = useMutation(refreshFileMatching);

  const { mutate: toggle } = useMutation({
    mutationKey: ['sepaFile', sepaFile?.id],
    mutationFn: async (newState: boolean) => {
      if (!sepaFile) return undefined;
      const exp = sepaFile.dup();
      exp.imported = newState;
      return exp.saveOrThrow();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['sepa-files', id]);
    },
  });

  const handleRefreshFileMatching = async () => {
    const payload = { sepaFileId: sepaFile?.id };

    mutate(payload, {
      onSuccess: () => {
        const successMsg = t('front.refreshMatching.toastMsg.success');
        toast.success(<Toaster textMsg={successMsg} toastType="success" />);
        queryClient.invalidateQueries(['sepa-files', sepaFile?.id]);
      },
      onError: () => {
        const errorText = t('front.refreshMatching.toastMsg.error.file', {
          fileName: sepaFile?.filename,
        });
        toast.error(<Toaster textMsg={errorText} toastType="error" />);
      },
    });
  };

  return (
    <div className="mb-[50px]">
      <p className="big-title ml-5">{t('front.bankTransfer.show.title')}</p>
      <div className="ml-5">
        <Breadcrumbs />
        <div className="flex justify-end mr-5">
          {sepaFile && sepaFile.totalTransactions === 0 && (
            <ButtonSecondary
              disabled={isOnlyManager}
              onClick={handleDelateSepaFile}
              className="mr-5"
            >
              {t('front.button.label.destroy')}
            </ButtonSecondary>
          )}
          <ButtonPrimary
            disabled={sepaFile?.matchingStatus === 'running' || isOnlyManager}
            onClick={handleRefreshFileMatching}
          >
            {t('front.button.label.refreshMatching')}
          </ButtonPrimary>
        </div>
      </div>
      <CommentWidget
        commentableType="Sepa::File"
        commentableId={sepaFile?.id}
      />
      <div className="flex justify-between">
        <MainCard
          header={t('front.mainCard.header.defaultTitle')}
          className="mt-[20px] mx-5 md:w-[800px]"
        >
          <LineText
            label={t('front.sepa.show.card.label.status')}
            value={
              <Status statusType={sepaFile?.matchingStatus as StatusType} />
            }
          />
          <LineText
            label={t('front.sepa.show.card.label.createdAt')}
            value={dayjs(sepaFile?.createdAt as string).format('L')}
          />
          <LineText
            onClick={handleDownload}
            label={t('front.sepa.show.card.label.filename')}
            value={sepaFile?.filename || '-'}
          />
          <LineText
            label={t('front.sepa.show.card.label.fileType')}
            value={sepaFile?.fileType || '-'}
          />
          <LineText
            label={t('front.sepa.show.card.label.completionRate')}
            value={`${sepaFile?.completionRate?.toString()} %` || '-'}
          />
          <LineText
            label={t('front.sepa.show.card.label.validatedTransactions')}
            value={`${sepaFile?.validatedTransactions || 0}/${
              sepaFile?.totalTransactions || 0
            }`}
          />
          {sepaFile?.fileType === 'camt53' && (
            <LineText
              label={t(
                'activerecord.attributes.accounting/journal_export.imported',
                {
                  ns: 'rails',
                },
              )}
              value={<ImportedInput record={sepaFile} onToggle={toggle} />}
            />
          )}
          {stats && (
            <LineText
              label={t('front.sepa.show.card.label.totalCredit')}
              value={`${creditAmount.amount / 100.0} ${creditAmount.currency}`}
            />
          )}
          {stats && (
            <LineText
              label={t('front.sepa.show.card.label.remainingCredit')}
              value={`${remainingCreditAmount.amount / 100.0} ${
                remainingCreditAmount.currency
              }`}
            />
          )}
          {stats && (
            <LineText
              label={t('front.sepa.show.card.label.totalDebit')}
              value={`${debitAmount.amount / 100.0} ${debitAmount.currency}`}
            />
          )}

          {stats && (
            <LineText
              label={t('front.sepa.show.card.label.remainingDebit')}
              value={`${remainingDebitAmount.amount / 100.0} ${
                remainingDebitAmount.currency
              }`}
            />
          )}
        </MainCard>
      </div>
      <MainCard header="Export CSV" className="mt-[20px] mx-5 md:w-[800px]">
        {sepaFile?.fileType === 'camt53' && (
          <AccountingJournalExportFromSepaFile sepaFile={sepaFile} />
        )}
      </MainCard>
      <Table
        title={t('front.sepa.show.pendingSepa.table.title') as string}
        onRowClick={handleNavigateAssociationPage}
        className="mt-[26px]"
        withImageErrorMsg={false}
        emptyTableErrorMsg="react_table.no_data_text"
        isLoading={pendingSepaTransactionLoader}
        totalCount={pendingSepaTransactionTotalCount}
        data={pendingSepaTransaction || []}
        reactTableProps={pendingSepaTransactionProps}
        columns={awaitingBankTransferColumns(id as string)}
      />
      <Table
        onRowClick={handleNavigateAssociationShowPage}
        title={t('front.sepa.show.validatedSepa.table.title') as string}
        withImageErrorMsg={false}
        emptyTableErrorMsg="react_table.no_data_text"
        className="mt-[26px]"
        isLoading={validatedSepaTransactionsLoader}
        totalCount={validatedSepaTransactionsTotalCount}
        data={validatedSepaTransactions || []}
        reactTableProps={props}
        columns={validatedBankTransferColumns(id as string)}
      />
    </div>
  );
};

export default BankTransferShow;
