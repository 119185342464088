import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { TOptions } from 'i18next';

export function dynT(key: string, opts: TOptions = {}): FunctionComponent {
  return () => {
    const { t } = useTranslation();
    return <>{t(key, opts)}</>;
  };
}
