import { ControlledSelect } from '@common/components/inputs/controlledInput/ControlledSelect';
import { useExpertises } from '@common/expertise/useExpertises';
import useLegalEntities from '@common/legalEntity/useLegalEntities';
import { useTranslation } from 'react-i18next';
import { ButtonRadio } from '@common/components/inputs';
import { UseFormReturn } from 'react-hook-form';
import PortageOfferInfo from './PortageOfferInfo';
import { usePortageOffers } from '../../hooks/usePortageOffers';

export const PortageOfferFields = ({
  form,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
}) => {
  const { t } = useTranslation();
  const { expertises, expertisesOptions } = useExpertises();
  const { legalEntities } = useLegalEntities();

  const { watch, control, setValue, getValues } = form;

  const expertise = expertises.find(
    (e) => e.id === (watch('expertiseId') as unknown as string),
  );

  const legalEntityId = expertise?.legalEntityId || undefined;
  setValue('legalEntityId', legalEntityId);

  const legalEntitySlug =
    legalEntityId === undefined
      ? undefined
      : legalEntities.find((le) => le.id === String(legalEntityId))?.slug;

  const { portageOffersOptions } = usePortageOffers({
    legalEntityId,
    onSuccess: (data) => {
      const portageOffers = data.data;
      if (portageOffers.length === 1) {
        setValue('portageOfferId', portageOffers[0].id as string);
        return;
      }

      if (!portageOffers.find((o) => o.id === getValues('portageOfferId'))) {
        setValue('portageOfferId', '');
      }
    },
  });

  const portageOfferId = watch('portageOfferId');

  const btnRadioCustomClass = (offerId: string) =>
    `m-3 p-2 border border-color-pone-orange shadow-[0_0_20px_0_rgba(0,0,0,0.11)] rounded ${
      portageOfferId === offerId
        ? 'bg-color-transparent-pone-orange'
        : 'hover:bg-color-transparent-light-pone-orange'
    }`;

  return (
    <>
      <ControlledSelect
        name="expertiseId"
        required
        displayCol
        className="m-3"
        control={control}
        label={t('front.welcome.simulations.new.expertise')}
        options={expertisesOptions}
        placeholder={t(
          'front.welcome.onboarding.steps.portageOfferStep.selectExpertisePlaceholder',
        )}
        isSearchable
        isClearable
      />
      {legalEntityId &&
        portageOffersOptions.map(({ offer }) => (
          <ButtonRadio
            containerClassName={btnRadioCustomClass(String(offer.id))}
            key={offer.id}
            id={offer.id}
            label={
              <PortageOfferInfo
                offer={offer}
                legalEntitySlug={legalEntitySlug}
              />
            }
            labelClassName="ml-3 w-full"
            name="portageOfferId"
            value={offer.id}
            checked={portageOfferId === offer.id}
            onChange={(e) => {
              setValue('portageOfferId', e.target.value);
            }}
          />
        ))}
    </>
  );
};
