import { FactorClientPaymentFile } from '../../../../../app/spraypaint';

export const createFactorClientPaymentFile = async (payload: {
  sourceFileSignedId: string | undefined;
}): Promise<FactorClientPaymentFile> => {
  const file = new FactorClientPaymentFile(payload);
  try {
    await file.save();
  } catch {
    throw new Error('File creation error');
  }
  return file;
};
