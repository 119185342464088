import {
  AcompteIcon,
  Advantage,
  CaIcon,
  ClientIcon,
  CommandeIcon,
  ContratIcon,
  Contributions,
  FraisIcon,
  SalaireIcon,
  SubcontractorsIcon,
  TdbIcon,
  VideoTuto,
} from '../../../app/assets/icons/menu';

export const mapCategorytoIcon = {
  dashboard: TdbIcon,
  activity_account: CaIcon,
  clients: ClientIcon,
  invoices: CommandeIcon,
  expenses: FraisIcon,
  advance_payments: AcompteIcon,
  payslips: SalaireIcon,
  my_documents: ContratIcon,
  subcontractors: SubcontractorsIcon,
  subcontractor_bills: CommandeIcon,
  intern_subcontractors: SubcontractorsIcon,
  contributions: Contributions,
  my_perks: Advantage,
  video_tutorials: VideoTuto,
};

export const getIconCategory = (category: string) => {
  const IconAfterMapping =
    mapCategorytoIcon[category as keyof typeof mapCategorytoIcon];
  if (IconAfterMapping === undefined)
    return <ClientIcon height={20} width={20} />;
  return <IconAfterMapping height={20} width={20} />;
};
