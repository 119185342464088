import { CommercialAgent, Manager } from '@/app/spraypaint';
import { ConseillerIcon } from '../../../app/assets/icons/buttons';
import {
  EmailIcon,
  PhoneIcon,
  PlaceIcon,
  TimeIcon,
} from '../../../app/assets/icons/contact';

export type DropDownAdvisorInfosProps = {
  advisor: Manager | CommercialAgent;
};

export const DropDownAdvisorInfos = ({
  advisor,
}: DropDownAdvisorInfosProps) => {
  const { office } = advisor;

  return (
    <div className="absolute top-[75px] right-[200px] z-10 w-[278px] shadow-card flex flex-col">
      <div className="px-3 bg-color-transparent-medium-grey">
        <div className="py-3 flex justify-between">
          <div className="text-left">
            <p className="pt-1 text-base text-color-pone-dark2">
              {advisor.firstName}
            </p>
            <p className="pt-1 text-color-pone-orange text-base2 font-cabin-bold">
              {advisor.lastName}
            </p>
          </div>
          <ConseillerIcon className="w-8 h-8" />
        </div>
      </div>

      <div className="py-4 bg-white">
        <div className="flex pl-3 align-middle">
          <EmailIcon className="w-5 h-5" />
          <p className="text-sm1 ml-3 text-color-pone-dark2">
            <a href={`mailto:${advisor.email}?subject=contact`}>
              {advisor.email}
            </a>
          </p>
        </div>

        <div className="flex pl-3 mt-2 align-middle">
          <PhoneIcon className="w-5 h-5" />
          <p className="text-sm1 ml-3 text-color-pone-dark2">
            {advisor.landlinePhone}
          </p>
        </div>

        <div className="flex pl-3 mt-2 align-middle">
          <PlaceIcon className="w-5 h-5" />
          <div className="ml-3 text-left">
            <p className="text-base font-cabin-bold text-color-pone-dark2">
              {office.name}
            </p>
            <p className="text-sm1 text-color-pone-dark2">
              {office.addressNumber} {office.addressRoad}
            </p>
            <p className="text-sm1 text-color-pone-dark2">
              {office.addressZipcode} {office.addressCity}
            </p>
            <p className="text-sm1 text-color-pone-dark2">
              {office.addressCountry}
            </p>
          </div>
        </div>

        <div className="flex pl-3 mt-2 align-middle">
          <TimeIcon className="w-5 h-5" />
          <p className="text-sm1 ml-3 font-cabin-bold text-color-pone-orange">
            {office.openingDays}
          </p>
        </div>
      </div>
    </div>
  );
};
