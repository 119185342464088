interface DaysBlockProps {
  label?: string;
  value?: number;
}

export const DaysBlock = ({ label, value }: DaysBlockProps) => (
  <div className="w-[164px] text-center text-cabin-bold">
    <div className="text-lg md:text-xl2">{value}</div>
    <div className="text-base2 md:text-base4">{label}</div>
  </div>
);
