import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FactorClientPaymentTransaction,
  Invoice,
  ModelRecord,
} from '@/app/spraypaint';
import {
  ButtonPrimary,
  ButtonSecondary,
  IconButton,
} from '@/modules/common/components/buttons';
import { CommentWidget } from '@/modules/common/components/comments/CommentWidget';
import { Breadcrumbs } from '@/modules/navigation/components/Breadcrumbs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCreateTransition } from '@/modules/common/hooks/useTransition';
import { useUserManagerRole } from '@/modules/authentification/hooks';
import { Alert, Toaster } from '@/modules/common/components/toasts';
import { CompletedValidationModal } from '@/modules/admin/accounting/bankAssociation/components/CompletedValidationModal';
import { AmountWithCurrency } from '@/modules/common/components/fragments/AmountText';
import Table from '@/modules/common/components/table/Table';
import { MoveLeftRightIcon } from '@/app/assets/icons/payslipFunnel';
import { MoneyType } from '@/modules/common/utils/type.utils';
import { PlusIcon } from '@/app/assets/icons/buttons';
import { useBackendTable } from '@/modules/common/components/table/hooks';
import classNames from 'classnames';
import { LoaderPage } from '@/app/navigation/components/LoaderPage';
import FactorCandidate from '@/app/spraypaint/factor/FactorCandidate';
import { AssociationCard } from '@/modules/common/components/matchingCandidatesCards/AssociationCard';
import { onRowClickNavigate } from '@/app/navigation/functions';
import FactorTransferItem from '@spraypaint/factor/FactorTransferItem';
import { useGetMatchingCandidates } from '../hooks/useGetMatchingCandidates';
import { FactorClientPaymentInformationCard } from '../components/FactorClientPaymentInformationCard';
import { ManualReconciliationForm } from '../components/ManualReconciliationForm';
import { usePayableInvoices } from '../hooks/usePayableInvoices';
import { invoicesListColumns } from '../columns/InvoicesListColumns';
import { PayloadType, useValidateMatching } from '../hooks/useValidateMatching';
import { refreshTransactionMatching } from '../hooks/useRefreshMatching';
import { useGetFactorClientPaymentTransaction } from '../hooks/useGetFactorClientPaymentTransaction';

const ClientPaymentTransactionShow = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isOnlyManager = useUserManagerRole();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [matchingScoreList, setMatchingScoreList] = useState<Array<Invoice>>(
    [],
  );
  const [candidateList, setCandidateList] = useState<
    Array<Invoice | FactorTransferItem>
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState<Array<string>>([]);

  const {
    factorClientPaymentTransaction,
    legalEntity,
    isLoading: factorClientPaymentTransactionLoader,
  } = useGetFactorClientPaymentTransaction(id as string);

  const {
    mutate: refreshTransactionMatchingMutation,
    isLoading: refreshTransactionMatchingLoader,
  } = useMutation(refreshTransactionMatching);

  const {
    mutate: transactionValidationMutation,
    isLoading: transactionValidationLoader,
    data,
  } = useValidateMatching();
  useEffect(() => {
    if (data === true) {
      toast.success(
        <Toaster
          textMsg="Rapprochement bancaire correctement effectué"
          toastType="success"
        />,
      );
      navigate(`/v2/admin/factor_reconciliation/transactions/${id}`);
    } else if (typeof data === 'object') {
      const errorsBack: string[] = Object.values(data).reduce<string[]>(
        (acc, error) => {
          const message = error?.fullMessage;
          return message ? [...acc, message] : acc;
        },
        [],
      );
      setErrors(errorsBack);
    }
  }, [data]);

  const [bestMatchingCandidatesScope, bestMatchingCandidatesScopeKey] =
    useBackendTable<FactorCandidate>();
  const { candidates: bestMatchingCandidates } = useGetMatchingCandidates({
    scope: bestMatchingCandidatesScope,
    scopeKey: [bestMatchingCandidatesScopeKey, id],
    transactionId: id,
    onSuccess: setCandidateList,
  });

  const [invoiceScope, invoiceScopeKey, invoiceTransactionProps] =
    useBackendTable<Invoice>({ initialPageSize: 10 });
  const {
    invoices,
    totalCount,
    isLoading: fetchInvoicesLoader,
  } = usePayableInvoices(legalEntity?.id || '', {
    scope: invoiceScope,
    scopeKey: invoiceScopeKey,
  });

  const handleOrphaningSuccess = () => {
    toast.success(
      <Toaster
        textMsg="Rapprochement bancaire abandonné"
        toastType="success"
      />,
    );
    queryClient.invalidateQueries([
      'pending-factor-client_payment_transactions',
      'completed-factor-client_payment_transactions',
      'factor-client_payment_files',
    ]);
    navigate(`/v2/admin/factor_reconciliation/transactions/${id}`);
  };
  const { mutate: mutateOrphaned, isLoading: isOrphanActionLoading } =
    useCreateTransition(handleOrphaningSuccess);

  const handleRefreshTransactionMatching = async () => {
    const payload = { clientPaymentTransactionId: id };

    refreshTransactionMatchingMutation(payload, {
      onSuccess: () => {
        const successMsg = t('front.refreshMatching.toastMsg.success');
        toast.success(<Toaster textMsg={successMsg} toastType="success" />);
        queryClient.invalidateQueries([
          'factor-client_payment_transactions',
          id,
        ]);
      },
      onError: () => {
        const errorText = t(
          'front.refreshMatching.toastMsg.error.transaction',
          {
            transactionId: id,
          },
        );
        toast.error(<Toaster textMsg={errorText} toastType="error" />);
      },
    });
  };

  const handleOnModalOpen = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleOnModalSubmit = () => {
    mutateOrphaned({
      resourceId: id,
      transitionName: 'orphan',
      transitionParams: { comment },
      resourceType: 'Factor::ClientPaymentTransaction',
    });
  };

  const handleOnModalFill = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleValidateMatching = () => {
    if (
      matchingScoreList.every(
        (item) => item.associableType === matchingScoreList[0].associableType,
      )
    ) {
      const payload: PayloadType = {
        associableIds: matchingScoreList.map((imatch) => Number(imatch.id)),
        associableType: matchingScoreList[0].associableType,
        transactionId: Number(id),
        transactionType: 'Factor::ClientPaymentTransaction',
      };
      transactionValidationMutation(payload);
    } else {
      toast.error(
        <Toaster
          textMsg={t('front.bankAssociation.errors.differentType')}
          toastType="error"
        />,
      );
    }
  };

  const handleAddCandidate =
    (
      candidateToMove: Invoice,
      transaction: FactorClientPaymentTransaction | undefined,
    ) =>
    () => {
      // All added items should have the same currency
      if (candidateToMove.currencyIsoCode !== transaction?.amount?.currency)
        return toast.error(
          <Toaster
            textMsg={t('front.bankAssociation.errors.differentCurrency')}
            toastType="error"
          />,
        );

      // Do not add the same item many times with manual form
      if (
        matchingScoreList.filter(
          (matchingScore) => matchingScore.id === candidateToMove.id,
        ).length > 0
      )
        return matchingScoreList;

      const newArray = candidateList.filter(
        (candidate) => candidateToMove.id !== candidate.id,
      );
      setCandidateList(newArray);
      return setMatchingScoreList([candidateToMove, ...matchingScoreList]);
    };

  const handleRemoveCandidate = (candidate: Invoice) => () => {
    const newSelectedCandidateList = matchingScoreList.filter(
      (matchingScore) => candidate.id !== matchingScore.id,
    );
    const existOnBestCandidateList = !!bestMatchingCandidates.find(
      (bestCandidate: Invoice) => bestCandidate.id === candidate.id,
    );
    if (existOnBestCandidateList) {
      setCandidateList([candidate, ...candidateList]);
    }
    setMatchingScoreList(newSelectedCandidateList);
  };

  const handleNavigate = (raw: ModelRecord<Invoice>) => (event: MouseEvent) => {
    onRowClickNavigate({
      id: raw.employeePagePath,
      event,
      windowNavigationHref: raw.employeePagePath,
      href: raw.employeePagePath,
      navigate,
    });
  };

  const totalAdded = Number(
    matchingScoreList
      .reduce((acc, obj) => acc + (obj?.remainingAmount?.amount ?? 0), 0)
      .toFixed(2),
  );

  const disabledReconciliationBtn =
    isOnlyManager ||
    matchingScoreList.length === 0 ||
    factorClientPaymentTransaction?.status !== 'pending_validation' ||
    totalAdded < (factorClientPaymentTransaction?.amount?.amount ?? 0);

  const totalAddedTextClassName = classNames(
    {
      'text-color-txt-failure':
        totalAdded < (factorClientPaymentTransaction?.amount?.amount ?? 0),
      'text-color-success':
        totalAdded === factorClientPaymentTransaction?.amount?.amount,
      'text-color-warning':
        totalAdded > (factorClientPaymentTransaction?.amount?.amount ?? 0),
    },
    'font-bold',
  );

  const invoicesColumns = invoicesListColumns({
    handleAddCandidate,
    handleRemoveCandidate,
    matchingScoreList,
    transaction:
      factorClientPaymentTransaction as FactorClientPaymentTransaction,
    disabledForManager: isOnlyManager,
  });

  if (factorClientPaymentTransactionLoader) return <LoaderPage />;

  return (
    <div className="mb-[100px]">
      <div className="ml-[20px]">
        <div className="flex justify-between pr-[20px]">
          <p className="big-title">
            {t(
              'front.factor.reconciliation.clientPaymentTransaction.show.title',
            )}
          </p>
          <div className="flex justify-end">
            <ButtonSecondary
              disabled={
                !factorClientPaymentTransaction?.previousPendingTransactionId
              }
              className="mr-5"
              to={`/v2/admin/factor_reconciliation/transactions/${factorClientPaymentTransaction?.previousPendingTransactionId}/assignation`}
            >
              {t('front.button.label.previousMatching')}
            </ButtonSecondary>
            <ButtonSecondary
              disabled={
                !factorClientPaymentTransaction?.nextPendingTransactionId
              }
              className="mr-5"
              to={`/v2/admin/factor_reconciliation/transactions/${factorClientPaymentTransaction?.nextPendingTransactionId}/assignation`}
            >
              {t('front.button.label.nextMatching')}
            </ButtonSecondary>
          </div>
        </div>

        <Breadcrumbs />
      </div>
      <CommentWidget
        commentableType="Factor::ClientPaymentTransaction"
        commentableId={factorClientPaymentTransaction?.id}
      />
      <div className="flex justify-end mr-[20px] mt-[20px]">
        <div className="flex  mr-[20px]">
          <ButtonSecondary
            className="mr-5"
            isLoading={refreshTransactionMatchingLoader}
            disabled={
              factorClientPaymentTransaction?.matchingStatus === 'running' ||
              isOnlyManager
            }
            onClick={handleRefreshTransactionMatching}
          >
            {t('front.button.label.refreshMatching')}
          </ButtonSecondary>
          <CompletedValidationModal
            handleSetCloseModal={handleOnModalOpen}
            isOpen={isModalOpen}
            handleOnClick={handleOnModalSubmit}
            isLoading={isOrphanActionLoading}
            handleFill={handleOnModalFill}
          />
          <ButtonSecondary
            className="mr-5"
            onClick={handleOnModalOpen}
            disabled={isOnlyManager}
          >
            {t('front.button.label.orphanTransaction')}
          </ButtonSecondary>
          <ButtonPrimary
            isLoading={transactionValidationLoader}
            disabled={disabledReconciliationBtn}
            onClick={handleValidateMatching}
          >
            {t('front.bankAssociation.show.button.reconciliation.label')}
          </ButtonPrimary>
        </div>
      </div>
      {errors.length > 0 && (
        <div className="mt-[20px] flex-col">
          {errors.map((error) => (
            <Alert alertType="error" text={error} />
          ))}
        </div>
      )}
      {!factorClientPaymentTransactionLoader && (
        <div className="flex justify-between ml-[20px] mt-[20px]">
          <FactorClientPaymentInformationCard
            factorClientPaymentTransaction={factorClientPaymentTransaction}
            entityName={legalEntity?.name as string}
          />
          {isOnlyManager
            ? null
            : factorClientPaymentTransaction && (
                <ManualReconciliationForm
                  className="mr-[20px]"
                  handleAddition={handleAddCandidate}
                  transaction={factorClientPaymentTransaction}
                />
              )}
        </div>
      )}

      <div className="flex flex-col mt-[20px] mr-[40px] text-xl text-right">
        <p>
          {t('front.bankAssociation.show.totalToPoint')}
          <AmountWithCurrency
            amount={factorClientPaymentTransaction?.amount as MoneyType}
          />
        </p>
        <p className={totalAddedTextClassName}>
          {t('front.bankAssociation.show.totalInvoicesAdded')}
          <AmountWithCurrency
            amount={totalAdded}
            currencyIsoCode={factorClientPaymentTransaction?.amount.currency}
          />
        </p>
      </div>

      <div className="flex">
        <div className="flex flex-col  w-[45%] rounded shadow-table p-[10px] mt-[20px]">
          <p className="text-center text-xxl">
            {t('front.bankAssociation.show.bestMatches').toUpperCase()}
          </p>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {candidateList.map((candidate: any) => (
            <AssociationCard
              key={candidate.id}
              onClick={handleAddCandidate(
                candidate,
                factorClientPaymentTransaction,
              )}
              RightComponent={
                <IconButton Icon={PlusIcon} className="ml-[30x] flex" />
              }
              candidate={candidate}
            />
          ))}
        </div>
        <MoveLeftRightIcon className="self-center mx-[20px]" />
        <div className="flex flex-col w-[45%] rounded shadow-table px-[50px] py-[10px] mt-[20px]">
          <p className="text-center text-xxl">
            {t('front.bankAssociation.show.pointedInvoices').toUpperCase()}
          </p>
          {matchingScoreList?.map((matchingScore) => (
            <AssociationCard
              key={matchingScore.id}
              onClick={handleRemoveCandidate(matchingScore)}
              RightComponent={
                <p className="font-cabin-bold text-xl w-[40px]">-</p>
              }
              candidate={matchingScore}
            />
          ))}
        </div>
      </div>
      <Table
        collapsable
        title="Liste des factures"
        withImageErrorMsg={false}
        emptyTableErrorMsg="react_table.no_data_text"
        className="mt-[26px]"
        isLoading={fetchInvoicesLoader}
        totalCount={totalCount}
        data={invoices || []}
        reactTableProps={invoiceTransactionProps}
        columns={invoicesColumns}
        onRowClick={handleNavigate}
      />
    </div>
  );
};

export default ClientPaymentTransactionShow;
