// api/Sepa::File.ts
import { Model, Attr, BelongsTo, HasOne } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';
import type Invoice from '../Invoice';
import type SubcontractorBill from '../SubcontractorBill';
import type SepaImport from '../SepaImport';
import type SepaTransaction from './SepaTransaction';

@Model()
class SepaMatchingCandidate extends ApplicationRecord {
  static jsonapiType = 'sepa-matching-candidates';

  // Attributes
  @Attr({ persist: false }) metadata!: unknown;
  @Attr({ persist: false }) upToDate!: boolean;

  // Relationships
  @HasOne({ name: 'candidate' }) candidate!:
    | Invoice
    | SubcontractorBill
    | SepaImport;

  @BelongsTo('sepa-transactions') sepaTransactions!: Array<SepaTransaction>;
}

export default SepaMatchingCandidate;
