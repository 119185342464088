// api/BreakdownLine.ts
import { Model, Attr, BelongsTo } from 'spraypaint';
import type { MoneyType } from '@/modules/common/utils/type.utils';
import ApplicationRecord from './ApplicationRecord';
import type Invoice from './Invoice';
import type ClientPayment from './ClientPayment';

@Model()
class BreakdownLine extends ApplicationRecord {
  static jsonapiType = 'breakdown_lines';

  // Attributes
  @Attr({ persist: false }) clientPaymentId!: number;
  @Attr({ persist: false }) invoiceId!: number;
  @Attr({ persist: false }) amount!: MoneyType;
  @Attr({ persist: false }) exchangeValueEuroAmount!: MoneyType;

  // Relationships
  @BelongsTo('invoices') invoice!: Invoice;
  @BelongsTo('client_payments') clientPayment!: ClientPayment;

  get employeePagePath(): string | undefined {
    return this.invoice?.employeePagePath;
  }

  get adminPagePath(): string | undefined {
    return this.invoice?.adminPagePath;
  }
}

export default BreakdownLine;
