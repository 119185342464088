/* eslint-disable import/no-cycle */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';
import mixpanel from 'mixpanel-browser';
import { toast } from 'react-toastify';
import { Alert, Toaster } from '@/modules/common/components/toasts';
import { CustomModal } from '@/modules/common/components/fragments/CustomModal';
import SecuritisToggler from '@/app/spraypaint/invoicing/SecuritisToggler';
import Table from '@/modules/common/components/table/Table';
import { HeaderMainCardText } from '@/modules/common/components/mainCard/components';
import { ButtonPrimary } from '../../common/components/buttons';
import { formatNumberWithCurrency } from '../../common/utils/maths.utils';
import { InvoiceTutorial } from '../components/tutorials';
import { CommonMiniTutorial } from '../components/tutorials/miniTutorials/CommonMiniTutorial';
import { MiniTutorialInvoice } from '../components/tutorials/miniTutorials/MiniTutorialInvoice';
import { WarningAmountsCard } from '../components/WarningAmountsCard';
import {
  useRevenueSummaryData,
  useInvoicesStep,
} from './hooks/InvoicesStepQueryHooks';
import {
  CAColumns,
  RevenueColumns,
  ToDefundColumns,
} from './constants/InvoiceColumns';
import {
  useToggleTutorial,
  useToggleMiniTutorial,
} from '../../widgets/hooks/ToggleTutorial';
import { usePayslipFunnelTransition } from '../hooks/usePayslipFunnelTransition';
import { Line } from '../../common/components/fragments';
import { useCurrentEmployeeId } from '../../../app/hooks/useCurrentEmployeeId';
import { MinitutorialOff } from '../components/tutorials/components/MinitutorialOff';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from '../../common/utils/date.utils';
import { PeriodType } from '../../common/utils/type.utils';
import { StepNameBackToFront } from '../../payslip/types/PayslipIndex';
import { tutorialContainerCustomClass } from '../constantes/tailwindStyle';
import useBreakpoints from '../../../app/responsive/useBreakPoints';
import { MainWebInvoicesStep } from './components/MainWebInvoicesStep';
import { MainMobileInvoicesStep } from './components/MainMobileInvoicesStep';
import { EmptyData } from '../../payslip/components';
import { LoaderPage } from '../../../app/navigation/components/LoaderPage';
import { PayslipFunnelMinusCard } from './components/PayslipFunnelMinusCard';
import StepMessages from '../components/StepMessages';
import CompletionErrorType from '../types/CompletionErrorType';

type Props = {
  payslipFunnelId: number;
  currentPayslipFunnelPeriod: PeriodType;
};

export const InvoicesStep = ({
  payslipFunnelId,
  currentPayslipFunnelPeriod,
}: Props) => {
  const { isXs } = useBreakpoints();
  const { t } = useTranslation();
  const [display, setDisplay] = useState<string>('CA');
  const employeeId = useCurrentEmployeeId();

  const periodDate = new Date(
    currentPayslipFunnelPeriod?.year,
    currentPayslipFunnelPeriod.month - 1,
  );

  const { mutate: validateStepInvoice, isLoading: mutationLoader } =
    usePayslipFunnelTransition({ payslipFunnelId });
  const { isTutorialOn, toggleAllTutorial } = useToggleTutorial();

  const [didUserValidateTutorial, setUserValidateTutorial] =
    useState(isTutorialOn);

  const { toggleMiniTutorial, isMiniTutorialOn } = useToggleMiniTutorial();
  const {
    caStat,
    caStatLoader,
    revenueStat,
    revenueStatLoader,
    overduePaymentStat,
    overduePaymentLoader,
    revenueSummaryTableData,
    revenueSummaryLoader,
    revenueSummaryProps,
    incomeProps,
    incomeSummaryTableData,
    tableIncomeLoading,
  } = useRevenueSummaryData(display, payslipFunnelId);

  const handleOnClick = () => {
    setUserValidateTutorial(false);
    toggleAllTutorial();
  };

  const handleNavigateToInvoicePage = () => {
    // ref: https://github.com/microsoft/TypeScript/issues/48949#issuecomment-1117142997
    const win: Window = window;
    win.location = `/employees/${employeeId}/invoices`;
  };

  const totalRevenue = Number(
    revenueStat?.meta?.stats?.total?.paid_amount_euro,
  );
  const totalCA = Number(caStat?.meta?.stats?.total?.total_amount_euro);
  const globalLoader =
    caStatLoader ||
    revenueStatLoader ||
    tableIncomeLoading ||
    revenueSummaryLoader;

  const currentDisplayCa = display === 'CA';

  const handleMixpanelTrackingInvoicesStep = (name: string) => {
    mixpanel.track(name, {
      employee_id: employeeId,
      payslip_funnel_id: payslipFunnelId,
      total_revenue: totalCA,
      total_incomes: totalRevenue,
    });
  };

  const handleTrackRedirectInvoiceScreen = () => {
    handleMixpanelTrackingInvoicesStep('TDS: Add Invoice');
  };

  const handleDisplayCA = () => {
    handleMixpanelTrackingInvoicesStep('TDS: Income Table Displayed');
    setDisplay('CA');
  };
  const handleDisplayRevenue = () => {
    handleMixpanelTrackingInvoicesStep('TDS: Revenue Table Displayed');
    setDisplay('revenue');
  };

  const displayCurrentTotal = () => {
    if (currentDisplayCa) {
      if (caStatLoader) return <Skeleton count={1} height={16} width={70} />;
      return formatNumberWithCurrency(totalCA, '€');
    }
    if (revenueStatLoader) return <Skeleton count={1} height={16} width={70} />;
    return formatNumberWithCurrency(totalRevenue, '€');
  };

  const handleValidateInvoiceStep = () =>
    validateStepInvoice({
      transitionName: `complete_${StepNameBackToFront(0)}`,
    });
  const isEmpty = false && totalRevenue === 0 && totalCA === 0;

  const caColumns = CAColumns();
  const revenueColumns = RevenueColumns();
  const toDefundColumns = ToDefundColumns();

  const warningAmountsCard = (
    <WarningAmountsCard
      className="mb-3 md:mb-0 md:mr-[140px]"
      amount={Number(
        overduePaymentStat?.meta?.stats?.total?.cache_remaining_amount_euro,
      )}
      numberText="front.payslip.tdc.invoice.latePayment.invoicesNumber"
      title="front.payslip.tdc.invoice.latePayment.title"
      number={overduePaymentStat?.meta?.stats?.total?.count}
      href={`/employees/${employeeId}/invoices`}
      isLoading={overduePaymentLoader}
    />
  );

  const { invoicesStep, refetch } = useInvoicesStep({
    payslipFunnelId: Number(payslipFunnelId),
    extraFields: [
      'computeActivityAccountBalance',
      'balanceConsideredDate',
      'messages_i18n_keys',
      'computeOverdueSecuritisAmount',
      'computeOverdueSecuritisCount',
    ],
  });

  const [
    isUnpaidSecuritisInvoicesModalOpen,
    setIsUnpaidSecuritisInvoicesModalOpen,
  ] = useState(false);

  const [defundIsLoading, setDefundIsLoading] = useState(false);

  const defundInvoices = async () => {
    setDefundIsLoading(true);
    const service = new SecuritisToggler();
    service.invoiceIds = (invoicesStep?.invoicesToBeDefunded ?? []).map((i) =>
      Number(i.id),
    );

    const success = await service.save();
    if (success) {
      toast.success(
        <Toaster
          textMsg={t('views.shared.change_applied')}
          toastType="success"
        />,
      );
      refetch();
      setDefundIsLoading(false);
      setIsUnpaidSecuritisInvoicesModalOpen(false);
    } else {
      toast.error(
        <Toaster
          textMsg={t('front.error_page.error_occured')}
          toastType="error"
        />,
      );
      setDefundIsLoading(false);
    }
  };

  const invoicesDefundTotal = (invoicesStep?.invoicesToBeDefunded || []).reduce(
    (a, b) => a + (b.totalAmount?.amount ?? 0),
    0,
  );

  if (didUserValidateTutorial) {
    return <InvoiceTutorial onClick={handleOnClick} />;
  }

  const stcUnpaidSecuritisInvoicesBlock = () =>
    invoicesStep &&
    (invoicesStep.invoicesToBeDefunded || [])[0] && (
      <>
        <PayslipFunnelMinusCard
          isLoading={revenueStatLoader}
          className="mt-3 md:ml-[22px] md:mt-0"
          onClick={() => setIsUnpaidSecuritisInvoicesModalOpen(true)}
          isActive={!currentDisplayCa}
          title={t('views.invoice.unpaid', {
            ns: 'rails',
          })}
          amount={invoicesDefundTotal}
          currencyIsoCode="EUR"
          btnText={
            t('front.payslip.tdc.invoice.table.revenue.display') as string
          }
          isError
        />
        <CustomModal
          isOpen={isUnpaidSecuritisInvoicesModalOpen}
          onRequestClose={() => setIsUnpaidSecuritisInvoicesModalOpen(false)}
        >
          <div className="w-[1200px] h-[700px] m-3">
            <HeaderMainCardText
              title={t('views.invoice.invoices_to_defund', {
                ns: 'rails',
              })}
              actionButtons={
                <ButtonPrimary
                  className="w-[210px] ml-5 mt-2"
                  onClick={defundInvoices}
                  isLoading={defundIsLoading}
                  disabled={defundIsLoading}
                >
                  {t('views.invoice.defund', {
                    ns: 'rails',
                  })}
                </ButtonPrimary>
              }
            />
            <Line />
            <p className="mt-2">
              {t('views.invoice.stc_unpaid_securitis_info', {
                ns: 'rails',
              })}
            </p>
            <Table
              className="mt-[32px]"
              title="Factures en avance de trésorerie, non-payées"
              tableInformations={
                <span>
                  {'Total à définancer : '}
                  <span className="text-color-pone-orange font-cabin-bold">
                    {formatNumberWithCurrency(invoicesDefundTotal, '€')}
                  </span>
                </span>
              }
              totalCount={invoicesStep.invoicesToBeDefunded.length}
              isCollapsed={false}
              isLoading={false}
              data={invoicesStep.invoicesToBeDefunded}
              columns={toDefundColumns}
            />
          </div>
        </CustomModal>
      </>
    );

  const invoiceCardBlock = (
    <div className="flex flex-col md:flex-row">
      <PayslipFunnelMinusCard
        isLoading={caStatLoader}
        onClick={handleDisplayCA}
        isActive={currentDisplayCa}
        title={t('front.payslip.tdc.invoice.table.ca.title')}
        amount={totalCA || 0}
        currencyIsoCode="EUR"
        btnText={t('front.payslip.tdc.invoice.table.ca.display') as string}
      />
      <PayslipFunnelMinusCard
        isLoading={revenueStatLoader}
        className="mt-3 md:ml-[22px] md:mt-0"
        onClick={handleDisplayRevenue}
        isActive={!currentDisplayCa}
        title={t('front.payslip.tdc.invoice.table.revenue.title')}
        amount={totalRevenue || 0}
        currencyIsoCode="EUR"
        btnText={t('front.payslip.tdc.invoice.table.revenue.display') as string}
      />
      {stcUnpaidSecuritisInvoicesBlock()}

      <PayslipFunnelMinusCard
        isLoading={revenueStatLoader}
        className="mt-3 md:ml-[22px] md:mt-0"
        isActive={false}
        title={t('front.payslip.tdc.invoice.table.balance_at.title', {
          date: dayjs(
            new Date(invoicesStep?.balanceConsideredDate || new Date()),
          ).format('Do MMMM'),
        })}
        amount={
          invoicesStep?.computeActivityAccountBalance || {
            amount: 0,
            currency: 'EUR',
          }
        }
      />
    </div>
  );

  const renderMain = () => {
    if (globalLoader) {
      return <LoaderPage className="h-[250px] md:h-[500px]" />;
    }

    if (isEmpty)
      return (
        <div className="flex justify-between items-center ml-5 md:ml-[30px] my-5">
          <EmptyData
            titleKey="front.payslip.index.emptyPayslip.title"
            buttonSecondaryKey="front.payslip.index.emptyPayslip.buttonSecondary"
            buttonPrimaryKey="front.payslip.index.emptyPayslip.buttonPrimary"
            handleValidateStep={handleValidateInvoiceStep}
            amount={0}
            onClick={handleTrackRedirectInvoiceScreen}
            isLoading={mutationLoader}
            redirectToUrl={`/employees/${employeeId}/invoices`}
          />
          {warningAmountsCard}
        </div>
      );

    const MainInvoicesStep = isXs
      ? MainMobileInvoicesStep
      : MainWebInvoicesStep;

    return (
      <MainInvoicesStep
        invoiceCardBlock={invoiceCardBlock}
        warningAmountsCard={warningAmountsCard}
        currentDisplayCa={currentDisplayCa}
        displayCurrentTotal={displayCurrentTotal}
        revenueSummaryTableData={revenueSummaryTableData}
        incomeSummaryTableData={incomeSummaryTableData}
        revenueSummaryLoader={revenueSummaryLoader}
        revenueSummaryProps={revenueSummaryProps}
        caColumns={caColumns}
        tableIncomeLoading={tableIncomeLoading}
        incomeProps={incomeProps}
        revenueColumns={revenueColumns}
      />
    );
  };

  return (
    <div>
      <div className={tutorialContainerCustomClass}>
        {isMiniTutorialOn ? (
          <CommonMiniTutorial
            Component={
              <MiniTutorialInvoice onClick={handleNavigateToInvoicePage} />
            }
            onClick={toggleMiniTutorial}
          />
        ) : (
          <MinitutorialOff onClick={toggleMiniTutorial} />
        )}
        {invoicesStep && <StepMessages step={invoicesStep} />}
        {!isEmpty && !globalLoader ? (
          <ButtonPrimary
            className="w-[210px] ml-5 md:ml-0 mt-2 md:mt-0"
            onClick={handleValidateInvoiceStep}
            isLoading={mutationLoader}
          >
            {t('front.payslip.tdc.invoice.button.title')}
          </ButtonPrimary>
        ) : null}
      </div>
      {(invoicesStep?.completionErrors ?? []).map(
        (error: CompletionErrorType) => (
          <Alert
            className="my-[10px] ml-[30px]"
            alertType="error"
            text={error.localized_message}
          />
        ),
      )}
      <div className="ml-5 md:ml-[30px] mt-[40px]">
        <Line />
        <p className="mt-[11px]">
          {t('front.payslip.tdc.invoice.period', {
            firstDate: dayjs(getFirstDayOfMonth(periodDate)).format('Do MMMM'),
            lastDate: dayjs(getLastDayOfMonth(periodDate)).format('Do MMMM'),
          })}
        </p>
      </div>

      {(invoicesStep?.computeOverdueSecuritisCount || 0) > 0 && (
        <Alert
          className="my-[10px] ml-[30px]"
          alertType="warn"
          text={t('front.payslip.tdc.invoice.latePayment.overdueSecuritis', {
            count: invoicesStep?.computeOverdueSecuritisCount,
            amount: formatNumberWithCurrency(
              invoicesStep?.computeOverdueSecuritisAmount?.amount || 0,
              '€',
            ),
          })}
        />
      )}
      {renderMain()}
    </div>
  );
};
